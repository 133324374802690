import gql from 'graphql-tag';
import { FULL_ADDRESS_BOOK_ITEM } from '../query';

export const CONNECT_SHIPPING_TO_BILLING_ADDRESS = gql`
  mutation ConnectShippingToBillingAddress($projectNumber: String!) {
    connectShippingToBillingAddress(where: { projectNumber: $projectNumber }) {
      id
      shippingAddress {
        ...FullAddressBookItem
      }
    }
  }
  ${FULL_ADDRESS_BOOK_ITEM}
`;

export const DISCONNECT_SHIPPING_ADDRESS = gql`
  mutation DisconnectShippingAddress($projectNumber: String!) {
    updateAddressBook(where: { projectNumber: $projectNumber }, data: { shippingAddress: null }) {
      id
      shippingAddress {
        id
      }
    }
  }
`;

export const CREATE_EMPTY_ADDRESS_BOOK_ITEM = gql`
  mutation CreateEmptyAddressBookItem(
    $where: AddressBookItemWhereUniqueInput
    $projectNumber: String!
    $type: UpsertAddressBookItemType!
  ) {
    upsertAddressBookItem(where: $where, projectNumber: $projectNumber, data: {}, type: $type) {
      ...FullAddressBookItem
    }
  }
  ${FULL_ADDRESS_BOOK_ITEM}
`;
