import React, { useCallback, useMemo } from 'react';
import { toDay } from '../../../../utils/durations';
import { Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { useQuery } from 'react-apollo';
import {
  GetAccountingSubsidiaries,
  GetAccountingSubsidiariesVariables,
} from '../../AccountingLogger/types/GetAccountingSubsidiaries';
import {
  GET_ACCOUNTING_ACTIVITY_TYPES,
  GET_ACCOUNTING_COLLECTIVE_ACCOUNTS,
  GET_ACCOUNTING_EMPLOYEES,
  GET_ACCOUNTING_MACHINES,
  GET_ACCOUNTING_MATERIALS,
  GET_ACCOUNTING_MISSIONS,
  GET_ACCOUNTING_SUBSIDIARIES,
  GET_ACCOUNTING_VEHICLES,
} from '../../AccountingLogger/AccountingLogger.queries';
import {
  GetAccountingEmployees,
  GetAccountingEmployeesVariables,
} from '../../AccountingLogger/types/GetAccountingEmployees';
import {
  GetAccountingMissions,
  GetAccountingMissionsVariables,
} from '../../AccountingLogger/types/GetAccountingMissions';
import {
  GetAccountingMaterials,
  GetAccountingMaterialsVariables,
} from '../../AccountingLogger/types/GetAccountingMaterials';
import {
  GetAccountingVehicles,
  GetAccountingVehiclesVariables,
} from '../../AccountingLogger/types/GetAccountingVehicles';
import {
  GetAccountingMachines,
  GetAccountingMachinesVariables,
} from '../../AccountingLogger/types/GetAccountingMachines';
import { GetAccountingActivityTypes } from '../../AccountingLogger/types/GetAccountingActivityTypes';
import AppProgress from '../../../../components/Page/AppProgress';
import AppErrorMessage from '../../../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../../utils/errorPrefixRemover';
import { GetAccountingLogJournal_accountingItemsWithStats_accountingItems } from '../../AccountingLogJournal/types/GetAccountingLogJournal';
import { GetLoggedInUser } from '../../../types/GetLoggedInUser';
import { GET_LOGGEDIN_USER } from '../../../Login';
import { someState } from '../../utils/someState';
import { GetAccountingCollectiveAccounts } from '../../AccountingLogger/types/GetAccountingCollectiveAccounts';
import { orEmpty } from '../../AccountingLogger/utils/orEmpty';
import { IManualAccountingFormValues } from './DataEntryForm';
import { DataEntryFormContainer } from './DataEntryFormContainer';
import { mapFormToAccountingItem } from './utils/mapFormToAccountingItem';
import { mapAccountingItemToForm } from './utils/mapAccountingItemToForm';

const initialValues: IManualAccountingFormValues = {
  parent: '',
  activityDate: '',
  activityType: '',
  creditedTo: '',
  chargedTo: '',
  amount: 0,
  comment: '',
  // adding a space here fixes a rendering glitch in the label of the fields
  startTime: ' ',
  endTime: ' ',
  pricePerUnit: 0,
};

const today = toDay(new Date());
const initialValuesWithToday = { ...initialValues, activityDate: today };

interface IDataEntryProps {
  onAdd: (item: Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems>) => void;
  disabled?: boolean;
  editItem?: Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems>;
  parent?: GetAccountingLogJournal_accountingItemsWithStats_accountingItems['id'];
}

/**
 * The data entry section of manual accounting
 * @param onAdd function triggered with the accounting item to be added/edited
 * @param editItem an optional item that is the basis for editing
 * @param disabled is the section disabled?
 * @constructor
 */
export const DataEntry: React.FC<IDataEntryProps> = ({
  onAdd,
  editItem,
  parent,
  disabled: propDisabled = false,
}) => {
  const loggedInUser = useQuery<GetLoggedInUser>(GET_LOGGEDIN_USER);
  const subsidiaries = useQuery<GetAccountingSubsidiaries, GetAccountingSubsidiariesVariables>(
    GET_ACCOUNTING_SUBSIDIARIES,
  );
  const employees = useQuery<GetAccountingEmployees, GetAccountingEmployeesVariables>(
    GET_ACCOUNTING_EMPLOYEES,
  );
  const missions = useQuery<GetAccountingMissions, GetAccountingMissionsVariables>(
    GET_ACCOUNTING_MISSIONS,
  );
  const materials = useQuery<GetAccountingMaterials, GetAccountingMaterialsVariables>(
    GET_ACCOUNTING_MATERIALS,
  );
  const vehicles = useQuery<GetAccountingVehicles, GetAccountingVehiclesVariables>(
    GET_ACCOUNTING_VEHICLES,
  );
  const machines = useQuery<GetAccountingMachines, GetAccountingMachinesVariables>(
    GET_ACCOUNTING_MACHINES,
  );
  const collectiveAccounts = useQuery<GetAccountingCollectiveAccounts>(
    GET_ACCOUNTING_COLLECTIVE_ACCOUNTS,
  );
  const activityTypes = useQuery<GetAccountingActivityTypes>(GET_ACCOUNTING_ACTIVITY_TYPES);
  const [loading, error] = someState(
    loggedInUser,
    subsidiaries,
    employees,
    missions,
    materials,
    vehicles,
    machines,
    collectiveAccounts,
    activityTypes,
  );

  const employeeArray = orEmpty(employees.data?.employees);
  const missionArray = orEmpty(missions.data?.missions);
  const materialArray = orEmpty(materials.data?.activeMaterialCatalog?.materials);
  const vehicleArray = orEmpty(vehicles.data?.vehicles);
  const machineArray = orEmpty(machines.data?.machines);
  const collectiveAccountArray = orEmpty(collectiveAccounts.data?.collectiveAccounts);
  const activityTypeArray = orEmpty(activityTypes.data?.activityTypes);

  const disabled = propDisabled || loading || error;

  const user = loggedInUser.data?.auth?.name ?? '';
  const onSubmit = useCallback(
    (
      values: IManualAccountingFormValues,
      formikHelpers: FormikHelpers<IManualAccountingFormValues>,
    ) => {
      if (disabled) {
        return;
      }
      const accountingItem = mapFormToAccountingItem(
        editItem?.id,
        user,
        employeeArray,
        missionArray,
        materialArray,
        vehicleArray,
        machineArray,
        collectiveAccountArray,
        activityTypeArray,
        values,
      );
      onAdd(accountingItem);
      formikHelpers.resetForm();
    },
    [
      disabled,
      onAdd,
      employeeArray,
      missionArray,
      materialArray,
      vehicleArray,
      machineArray,
      collectiveAccountArray,
      activityTypeArray,
      user,
      editItem,
    ],
  );

  const initialValuesWithTodayParentOrEdit = useMemo(() => {
    if (editItem == null) {
      return parent != null ? { ...initialValuesWithToday, parent } : initialValuesWithToday;
    }
    return mapAccountingItemToForm(activityTypeArray, editItem);
  }, [activityTypeArray, editItem, parent]);

  // for some reason the validation on the amount field is not run, so do it here globally for formik
  const validate = useCallback((values) => {
    let amountError;
    const num = Number(values.amount);
    if (isNaN(num)) {
      amountError = 'Menge ist keine Zahl!';
    }
    if (num === 0) {
      amountError = 'Menge darf nicht 0 sein!';
    }
    if (amountError) {
      return { amount: amountError };
    }
    return undefined;
  }, []);

  return (
    <>
      {loading ? <AppProgress /> : null}

      {subsidiaries.error && (
        <AppErrorMessage message={errorPrefixRemover(subsidiaries.error.message)} />
      )}
      {employees.error && <AppErrorMessage message={errorPrefixRemover(employees.error.message)} />}
      {missions.error && <AppErrorMessage message={errorPrefixRemover(missions.error.message)} />}
      {materials.error && <AppErrorMessage message={errorPrefixRemover(materials.error.message)} />}
      {vehicles.error && <AppErrorMessage message={errorPrefixRemover(vehicles.error.message)} />}
      {machines.error && <AppErrorMessage message={errorPrefixRemover(machines.error.message)} />}
      {collectiveAccounts.error && (
        <AppErrorMessage message={errorPrefixRemover(collectiveAccounts.error.message)} />
      )}
      {activityTypes.error && (
        <AppErrorMessage message={errorPrefixRemover(activityTypes.error.message)} />
      )}
      <Formik<IManualAccountingFormValues>
        enableReinitialize
        initialValues={initialValuesWithTodayParentOrEdit}
        onSubmit={onSubmit}
        validateOnBlur
        validate={validate}
      >
        <DataEntryFormContainer
          employees={employeeArray}
          missions={missionArray}
          materials={materialArray}
          vehicles={vehicleArray}
          machines={machineArray}
          collectiveAccounts={collectiveAccountArray}
          activityTypes={activityTypeArray}
          disabled={disabled}
          edit={editItem != null}
        />
      </Formik>
    </>
  );
};
