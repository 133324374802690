import React, { useReducer } from 'react';
import { omit, isString } from 'lodash';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  ListItemText,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { Delete as DeleteIcon } from '@material-ui/icons';

enum FilterActionType {
  ADD_FILTER,
  SET_FILTER,
  DELETE_FILTER,
}

type FilterKey<Filters> = (keyof Filters & string) | '';

type IFilterAction<Filters extends { [P in FilterKey<Filters>]: IFilterConfig } = any> =
  | {
      type: FilterActionType.ADD_FILTER;
      payload: { key: FilterKey<Filters>; filter: IFilterConfig };
    }
  | { type: FilterActionType.SET_FILTER; payload: { key: FilterKey<Filters>; value: string } }
  | { type: FilterActionType.DELETE_FILTER; payload: { key: FilterKey<Filters> } };

interface IState<Filters extends { [P in keyof Filters]: IFilterConfig } = any> {
  selectedFilter: FilterKey<Filters>;
  addedFilters: { [P in keyof Filters]: IFilter };
}

function filterReducer<Filters extends { [P in FilterKey<Filters>]: IFilterConfig } = any>(
  state: IState<Filters>,
  action: IFilterAction,
): IState<Filters> {
  switch (action.type) {
    case FilterActionType.ADD_FILTER:
      return {
        selectedFilter: action.payload.key as FilterKey<Filters>,
        addedFilters: {
          ...state.addedFilters,
          // we can set the empty string but don't add it to the filters
          ...(action.payload.key !== '' && {
            [action.payload.key]: { ...action.payload.filter, value: '' },
          }),
        },
      };
    case FilterActionType.SET_FILTER:
      return {
        ...state,
        addedFilters: {
          ...state.addedFilters,
          [action.payload.key]: {
            ...state.addedFilters[action.payload.key as FilterKey<Filters>],
            value: action.payload.value,
          },
        },
      };
    case FilterActionType.DELETE_FILTER:
      return {
        ...state,
        addedFilters: omit(state.addedFilters, [action.payload.key]),
      };
    default:
      return state;
  }
}

export function useFilterReducer() {
  return useReducer<React.Reducer<IState, IFilterAction>>(filterReducer, {
    selectedFilter: '',
    addedFilters: {},
  });
}

type IFilterType = 'string' | 'array' | 'boolean' | 'number' | 'enum';

interface IFilter {
  label: string;
  type: IFilterType;
  value: string;
}

export type IFilterConfig = Pick<IFilter, 'label' | 'type'>;

interface IProps<Filters extends { [P in FilterKey<Filters>]: IFilterConfig }> {
  filters: Filters;
  selectedFilter: string;
  addedFilters: { [P in FilterKey<Filters>]: IFilter };
  dispatch: React.Dispatch<IFilterAction<Filters>>;
}

const FilterWrapper = styled('div')({});

const FilterList = styled(List)({
  maxWidth: 400,
});

const FilterFormControl = styled(FormControl)({
  minWidth: 120,
});

export function Filter<Filters extends { [P in FilterKey<Filters>]: IFilterConfig }>({
  filters,
  selectedFilter,
  addedFilters,
  dispatch,
}: IProps<Filters>) {
  return (
    <FilterWrapper>
      <FilterFormControl>
        <InputLabel>Filter</InputLabel>
        <Select
          value={selectedFilter}
          onChange={(e) => {
            if (isString(e.target.value)) {
              const key = e.target.value as FilterKey<Filters>;
              dispatch({
                type: FilterActionType.ADD_FILTER,
                payload: { key, filter: filters[key] },
              });
            }
          }}
        >
          <MenuItem value="" />
          {Object.entries(filters).map(([key, filter]) => (
            <MenuItem
              key={key}
              value={key}
              disabled={addedFilters[key as FilterKey<Filters>] !== undefined}
            >
              {filter.label}
            </MenuItem>
          ))}
        </Select>
      </FilterFormControl>
      <FilterList dense>
        {Object.entries(addedFilters).map(([key, filter]) => (
          <ListItem key={key}>
            <ListItemText>{filter.label}</ListItemText>
            {filter.type === 'string' && (
              <TextField
                type={filter.type}
                value={filter.value}
                onChange={(e) =>
                  dispatch({
                    type: FilterActionType.SET_FILTER,
                    payload: { key: key as FilterKey<Filters>, value: e.target.value },
                  })
                }
              />
            )}
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() =>
                  dispatch({
                    type: FilterActionType.DELETE_FILTER,
                    payload: { key: key as FilterKey<Filters> },
                  })
                }
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </FilterList>
    </FilterWrapper>
  );
}
