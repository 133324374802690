export interface ITimeEntry {
  activityType: string;
  mission: string;
  timeFrom: string;
  timeTo: string;
  comment: string;
}

export const emptyPerson = () => '';

export interface IImpersonalEntry {
  activityType: string;
  name: string;
  amount: number;
  comment: string;
}

export interface IAccountLogggerFormValues {
  isFinal: boolean;
  isCheck: boolean;
  date: string;
  chief: string;
  employees: string[];
  timeEntries: ITimeEntry[];
  individualTimeEntries: Array<{
    person: string;
    entries: ITimeEntry[];
  }>;
  impersonal: Array<{
    mission: string;
    date: string;
    entries: IImpersonalEntry[];
  }>;
}

export const emptyTimeEntry = () => ({
  activityType: '',
  comment: '',
  mission: '',
  timeFrom: '',
  timeTo: '',
});

export const emptyIndividualTimeEntry = () => ({
  person: emptyPerson(),
  entries: [emptyTimeEntry()],
});

export const emptyImpersonalEntry = () => ({
  activityType: '',
  name: '',
  amount: 0,
  comment: '',
});

export const emptyImpersonal = (date: string) => ({
  mission: '',
  date,
  entries: [emptyImpersonalEntry()],
});

export const emptyInitialValues = (
  date: string = new Date().toISOString().split('T')[0],
): IAccountLogggerFormValues => ({
  date,
  isFinal: false,
  isCheck: false,
  chief: '',
  employees: ['', '', '', '', ''],
  individualTimeEntries: [],
  timeEntries: [emptyTimeEntry()],
  impersonal: [emptyImpersonal(date)],
});
