import { useQuery } from 'react-apollo';
import { SELECTED_SUBSIDIARY_QUERY } from '../pages/Missions/queries';
import { Subsidiary } from '../pages/Missions/types/Subsidiary';

export const useSelectedSubsidiaryId = () => {
  const { data, loading, error } = useQuery<Subsidiary>(SELECTED_SUBSIDIARY_QUERY);

  if (!data || loading) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  return data.selectedSubsidiary;
};
