import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EmployeeForm from '../../components/Employee/EmployeeForm';
import AppErrorMessage from '../../components/Page/AppErrorMessage';
import AppProgress from '../../components/Page/AppProgress';
import PaperWrapper from '../../components/PageWrapper';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import { GET_EMPLOYEE, GET_EMPLOYEES, UPDATE_EMPLOYEE_MUTATION } from './employee.queries';
import { GetEmployee, GetEmployeeVariables } from './types/GetEmployee';
import { UpdateEmployee, UpdateEmployeeVariables } from './types/UpdateEmployee';

const EditEmployeePage: React.FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [submitError, setSubmitError] = useState<string>('');

  const { data, loading, error } = useQuery<GetEmployee, GetEmployeeVariables>(GET_EMPLOYEE, {
    variables: {
      where: { id },
    },
  });

  const [updateEmployee] = useMutation<UpdateEmployee, UpdateEmployeeVariables>(
    UPDATE_EMPLOYEE_MUTATION,
    {
      refetchQueries: [
        ...(!isEmpty(location.state.variables)
          ? [{ query: GET_EMPLOYEES, variables: location.state.variables }]
          : []),
        { query: GET_EMPLOYEE, variables: { where: { id } } },
      ],
    },
  );

  if (!data) {
    return null;
  }

  if (loading) {
    return <AppProgress />;
  }

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <PaperWrapper header={{ title: 'Mitarbeiter editieren', onClose: () => history.goBack() }}>
      {submitError && <AppErrorMessage message={errorPrefixRemover(submitError)} />}
      <EmployeeForm
        initialValues={data.employee}
        onSubmit={async (data, setSubmitting) => {
          try {
            await updateEmployee({ variables: { where: { id }, data } });
            setSubmitting(false);
            history.goBack();
          } catch (e: any) {
            console.log(e);
            setSubmitting(false);
            setSubmitError(e.message);
          }
        }}
      />
    </PaperWrapper>
  );
};

export default EditEmployeePage;
