import React, { useCallback, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';
import { IDataTableRow, ITableData } from '../types';
import { showColumn, IDispatchTable, ActionType } from '..';
import createPreventAll from '../../../utils/createPreventEventDefault';
import { makeStyles } from '@material-ui/styles';
import { useMutation, useQuery } from 'react-apollo';
import SaveIcon from '@material-ui/icons/Save';
import { ListItemIcon, Divider, Theme } from '@material-ui/core';
import {
  UPDATE_COLUMNS_SETTING_MUTATION,
  GET_CURRENT_COLUMNS_SETTING,
  SET_CURRENT_COLUMNS_SETTING,
  GET_COLUMNS_SETTINGS,
} from '../../ColumnsSettings/clumnsSettings.queries';
import {
  UpdateColumnsSetting,
  UpdateColumnsSettingVariables,
} from '../../ColumnsSettings/types/UpdateColumnsSetting';
import {
  SetCoulmnsSettingVariables,
  SetCoulmnsSetting,
} from '../../ColumnsSettings/types/SetCoulmnsSetting';
import { CurentColumnsSetting } from '../../ColumnsSettings/types/CurentColumnsSetting';
import { getOperationName } from 'apollo-link';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { swapArrayValues } from '../../../utils/swapArrayValues/swapArrayValues';
import { difference, negate } from 'lodash';
import { isEqual } from 'lodash/fp';

interface IProps {
  tableData: ITableData;
  dispatch: IDispatchTable;
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  listSubHeader: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  currentSetting: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  saveButton: {
    paddingLeft: theme.spacing(3.25),
  },
}));

const ColumnsPopup: React.FC<IProps> = ({ tableData, dispatch }) => {
  const classes = useStyles();

  const { data } = useQuery<CurentColumnsSetting, null>(GET_CURRENT_COLUMNS_SETTING);

  const levels = tableData.options.levels;
  const innerLevel = levels[levels.length - 1];

  const [clientColumns, setClientColumns] = useState(() => {
    const nonShownColumns = innerLevel.columns.flatMap(({ id, alwaysVisible, fixedPosition }) =>
      alwaysVisible && fixedPosition ? [id] : [],
    );

    return {
      visible: difference(tableData.shownColumnIds, nonShownColumns),
      hidden: difference(
        innerLevel.columns.map(({ id }) => id),
        [...tableData.shownColumnIds, ...nonShownColumns],
      ),
    };
  });

  const [setCurrentColumnSetting] = useMutation<SetCoulmnsSetting, SetCoulmnsSettingVariables>(
    SET_CURRENT_COLUMNS_SETTING,
  );

  const [updateColumnsSetting] = useMutation<UpdateColumnsSetting, UpdateColumnsSettingVariables>(
    UPDATE_COLUMNS_SETTING_MUTATION,
    {
      refetchQueries: [getOperationName(GET_COLUMNS_SETTINGS) || ''],
      onCompleted(data) {
        // save changes to session storage
        setCurrentColumnSetting({
          variables: {
            columnsSetting: {
              ...data.updateColumnsSetting,
              __typename: 'CurrentColumnsSetting',
            },
          },
        });
      },
    },
  );

  const storeSetting = async () => {
    if (!tableData.options.tableName || !data || !data.currentColumnsSetting) {
      return;
    }

    // save changes to the data base
    await updateColumnsSetting({
      variables: {
        where: { id: data.currentColumnsSetting.id },
        data: {
          tableSetting: {
            tableName: tableData.options.tableName,
            columns: clientColumns.visible,
          },
        },
      },
    });
  };

  const onToggle = useCallback(
    (column: Pick<IDataTableRow, 'id'>) => {
      return createPreventAll(() => {
        dispatch({
          type: ActionType.TOGGLE_COLUMN,
          payload: { columnId: column.id },
        });

        const isHide = tableData.shownColumnIds.includes(column.id);
        if (isHide) {
          setClientColumns((v) => ({
            visible: v.visible.filter(negate(isEqual(column.id))),
            // move hidden column to the end of the hidden columns
            hidden: [...v.hidden, column.id],
          }));
          return;
        }

        setClientColumns((v) => ({
          // move added column to the end of the visible columns
          visible: [...v.visible, column.id],
          hidden: v.hidden.filter(negate(isEqual(column.id))),
        }));
      });
    },
    [tableData.shownColumnIds, dispatch],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const newClientColumns = swapArrayValues(
        clientColumns.visible,
        result.source.index,
        result.destination.index,
      );

      // columns that are always visible and have a fixed position need to be readded here at the
      // correct position since they get filtered out in the initialState fn of clientColumns
      const payload = innerLevel.columns
        .filter(({ alwaysVisible, fixedPosition }) => alwaysVisible && fixedPosition)
        .reduce(
          (acc, column) => [
            ...acc.slice(0, column.fixedPosition),
            column.id,
            ...acc.slice(column.fixedPosition),
          ],
          // new object identity
          [...newClientColumns],
        );

      dispatch({
        type: ActionType.SET_SHOWN_COLUMN_IDS,
        payload,
      });

      setClientColumns((v) => ({ hidden: v.hidden, visible: newClientColumns }));
    },
    [clientColumns, dispatch, innerLevel.columns],
  );

  return (
    <Paper>
      <List
        subheader={
          <ListSubheader className={classes.listSubHeader}>Angezeigte Spalten</ListSubheader>
        }
        className={classes.list}
      >
        <ListItem
          button
          onClick={storeSetting}
          disabled={!data?.currentColumnsSetting?.name || !tableData.options.tableName}
          className={classes.saveButton}
        >
          <ListItemIcon>
            <SaveIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              data?.currentColumnsSetting?.name ? (
                <>
                  in{' '}
                  <span className={classes.currentSetting}>{data.currentColumnsSetting.name}</span>{' '}
                  speichern
                </>
              ) : (
                'speichern'
              )
            }
          />
        </ListItem>

        <Divider />

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" ignoreContainerClipping>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {[...clientColumns.visible, ...clientColumns.hidden]
                  .map((id) => innerLevel.columns.find((v) => v.id === id)!)
                  .map((column, i, columns) => {
                    const isFirstHiddenColumn =
                      !showColumn(tableData.shownColumnIds, column) &&
                      i > 0 &&
                      showColumn(tableData.shownColumnIds, columns[i - 1]);

                    const showDivider = isFirstHiddenColumn && !snapshot.isDraggingOver;

                    return (
                      <>
                        {showDivider && <Divider />}
                        <Draggable
                          draggableId={column.id}
                          key={column.id}
                          index={i}
                          isDragDisabled={!showColumn(tableData.shownColumnIds, column)}
                        >
                          {(provided) => {
                            return (
                              <div
                                key={column.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{ position: 'static', ...provided.draggableProps.style }}
                              >
                                <ListItem
                                  key={column.id}
                                  dense
                                  button
                                  onClick={onToggle(column)}
                                  disabled={column.alwaysVisible}
                                >
                                  <Checkbox
                                    checked={showColumn(tableData.shownColumnIds, column)}
                                    color="default"
                                    disableRipple
                                  />
                                  <ListItemText primary={column.label} />
                                </ListItem>
                              </div>
                            );
                          }}
                        </Draggable>
                      </>
                    );
                  })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
    </Paper>
  );
};

export default ColumnsPopup;
