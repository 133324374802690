import ApolloClient from 'apollo-client';
import { removeTableTypeFromId } from '../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { GET_DATA_TABLE, ALL_TABLE_TYPES } from '../../../services/graphql-client';
import {
  GetDataTable,
  GetDataTableVariables,
  GetDataTable_dataTable_rows,
} from '../../../services/types/GetDataTable';
import { TableType, SortDirection } from '../../../types/graphql';
import { deepCopy } from '../../../utils/deepCopy';
import { IDataTableRow } from '../../DataTable/types';
import { IContainerSortMapping } from './dialog';
import { GET_CONTAINER_SORT_MAPPING } from './query';
import {
  GetContainerSortMapping,
  GetContainerSortMappingVariables,
} from './types/GetContainerSortMapping';

/**
 * sorts the dataTable for the given tableType according to the sortedRowIds
 *
 * @param sortedRowIds the rows in sorted order **without** tableType
 */
export const sortDataTableRowsInCache = (
  sortedRowIds: string[],
  client: ApolloClient<any>,
  tableType: TableType,
  mutateRowData?: (data: IDataTableRow['data'], idx: number) => IDataTableRow['data'],
): void => {
  const dataTable = deepCopy(
    client.readQuery<GetDataTable, GetDataTableVariables>({
      query: GET_DATA_TABLE,
      variables: {
        id: tableType,
      },
    }),
  );

  if (!dataTable?.dataTable) {
    throw new Error();
  }

  const sortedDataTableRows = sortedRowIds.reduce<GetDataTable_dataTable_rows[]>(
    (sortedRows, id, idx) => {
      const row = dataTable.dataTable!.rows.find((row) => removeTableTypeFromId(row.id) === id);
      if (row) {
        const mutatedRowData = mutateRowData?.(JSON.parse(row.data), idx);
        sortedRows.push({
          ...row,
          data: mutatedRowData ? JSON.stringify(mutatedRowData) : row.data,
        });
      }

      return sortedRows;
    },
    [],
  );

  client.writeQuery<GetDataTable, GetDataTableVariables>({
    query: GET_DATA_TABLE,
    variables: { id: tableType },
    data: {
      dataTable: {
        __typename: 'DataTable',
        id: tableType,
        rows: sortedDataTableRows,
      },
    },
  });
};

/**
 * used to persist given container sort-mapping in cache
 * needed to persist container sort-mapping, else it would fallback to default
 * because of tab change (component unmount)
 */
export const persistContainerSortMapping = (
  mapping: IContainerSortMapping,
  projectNumber: string,
  tableType: TableType,
  client: ApolloClient<any>,
): void => {
  client.writeQuery<GetContainerSortMapping, GetContainerSortMappingVariables>({
    query: GET_CONTAINER_SORT_MAPPING,
    variables: {
      projectNumber,
      tableType,
    },
    data: {
      containerSortMapping: {
        __typename: 'ContainerSortMapping',
        ...mapping,
        direction: SortDirection[mapping.direction],
      },
    },
  });
};

/**
 * removes the container sort mappings that belong to a project
 * to be used upon leaving current project so no leftover data stays in cache
 * if not used, it will leave to container sort-mapping and rows in dataTable to be out of sync
 */
export const removeContainerSortMappingInProject = (
  projectNumber: string,
  client: ApolloClient<any>,
): void => {
  ALL_TABLE_TYPES.forEach((tableType) => {
    client.writeQuery<GetContainerSortMapping, GetContainerSortMappingVariables>({
      query: GET_CONTAINER_SORT_MAPPING,
      variables: {
        projectNumber,
        tableType,
      },
      data: {
        containerSortMapping: null,
      },
    });
  });
};
