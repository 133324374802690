import React from 'react';
import { useMutation } from 'react-apollo';
import { MATERIAL_CATALOG_CREATE_MUTATION } from './MaterialCatalogCreateDetails.queries';
import {
  CreateMaterialCatalog,
  CreateMaterialCatalogVariables,
} from './types/CreateMaterialCatalog';
import { DialogProps } from '@material-ui/core/Dialog';
import DialogCloseable from '../Dialog/DialogCloseable';
import { MaterialCatalogCreateForm } from './MaterialCatalogCreateForm';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import AppErrorMessage from '../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import { REFETCH_MATERIALS_QUERIES } from '../../pages/Materials/Materials.queries';

type Props = Pick<DialogProps, 'open'> & { onClose: (...args: any[]) => void };

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
  },
}));

export const MaterialCatalogCreateDetails: React.FC<Props> = ({ open, onClose }) => {
  const [createMaterialCatalog, { error }] = useMutation<
    CreateMaterialCatalog,
    CreateMaterialCatalogVariables
  >(MATERIAL_CATALOG_CREATE_MUTATION, {
    refetchQueries: REFETCH_MATERIALS_QUERIES,
  });

  const classes = useStyles();

  return (
    <>
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <DialogCloseable
        open={open}
        onClose={onClose}
        title="Material-Katalog importieren"
        contentClassName={classes.dialogContent}
      >
        <MaterialCatalogCreateForm
          onSubmit={(values) => createMaterialCatalog({ variables: values }).then(onClose).catch()}
        />
      </DialogCloseable>
    </>
  );
};
