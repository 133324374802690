import React from 'react';
import { IconButton, Theme, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useCurrentProject } from './useCurrentProject';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2, 0, 5, 0),
  },
  root: {
    fontWeight: 'bold',
    height: '100%',
    width: '100%',
  },
  iconBtnWrapper: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0, 1, 0, 2),
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: 'rgba(71, 168, 245, 0.3)',
  },
  label: {
    fontSize: theme.typography.h4.fontSize,
  },
  currentProject: {
    lineHeight: '1.55',
  },
  currentProjectProjectName: {
    fontSize: theme.typography.body1.fontSize,
  },
}));

export const Header: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const currentProject = useCurrentProject();

  const onClick = () => history.push('/mobile');

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item container xs={3} justify="center">
        <div className={classes.iconBtnWrapper}>
          <IconButton
            color="primary"
            classes={{ root: classes.root, label: classes.label }}
            onClick={() => onClick()}
          >
            M
          </IconButton>
        </div>
      </Grid>
      <Grid item xs={9}>
        {currentProject && (
          <>
            <Typography
              variant="body2"
              className={`${classes.currentProjectProjectName} ${classes.currentProject}`}
            >
              {currentProject.projectName}
            </Typography>
            <Typography variant="body2" className={classes.currentProject}>
              {currentProject.projectNumber}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};
