import { GetBillOfQuantityOfferConditions_billOfQuantity_offerConditions } from './types/GetBillOfQuantityOfferConditions';
import * as Yup from 'yup';

const key =
  (keyStr: string) =>
  (element: Readonly<GetBillOfQuantityOfferConditions_billOfQuantity_offerConditions>) =>
    `${keyStr}-${element.billOfQuantityId}-${element.offerConditionId}`;

/**
 * Get the form value key for the `active` field of an assignment
 * @params element offer condition assignment
 * @returns key of the form `active-...`
 */
export const activeKey = key('active');

/**
 * Get the form value key for the `text` field of an assignment
 * @params element offer condition assignment
 * @returns key of the form `text-...`
 */
export const textKey = key('text');

/**
 * Check whether an offer condition assignment is part of a form value set.
 *
 * @param values the form values of the structure as specified in `toFormikValues`
 * @returns whether the assignment is part of the value set, i.e. whether their `active` key OR `text` key is part
 *   of the values
 *
 * @see toFormikValues
 */
export const valuesContains =
  <T extends Record<string, string | boolean>>(values: T) =>
  (element: Readonly<GetBillOfQuantityOfferConditions_billOfQuantity_offerConditions>): boolean =>
    activeKey(element) in values || textKey(element) in values;

/**
 * Convert offer condition assignments to intialValues and a validation schema for formik
 * the keys of the objects map to names for the respective fields, i.e. `active-...` for the checkbox and `text-...`
 * for the (potentially overridden) text content.
 *
 * @param items the assignments to convert
 * @returns the initialValues and a validation schema
 */
export const toFormikValues = (
  items: ReadonlyArray<Readonly<GetBillOfQuantityOfferConditions_billOfQuantity_offerConditions>>,
): [
  Record<string, string | boolean>,
  Yup.ObjectSchema<Yup.Shape<any, Record<string, string | boolean>>>,
] => {
  const initialValues = items.reduce((acc: Record<string, string | boolean>, element) => {
    acc[activeKey(element)] = element.active;
    const maybeOverrideText = element.overrideText ?? '';
    acc[textKey(element)] = maybeOverrideText !== '' ? maybeOverrideText : element.text;
    return acc;
  }, {});

  const validationSchema = Yup.object().shape<typeof initialValues>(
    items.reduce((acc: Record<string, Yup.Schema<string | boolean>>, element) => {
      acc[activeKey(element)] = Yup.boolean();
      acc[textKey(element)] = Yup.string();
      return acc;
    }, {}),
  );
  return [initialValues, validationSchema];
};

/**
 * Create a key prop to reinitialize a form
 * @param items the assignments to base the key on
 * @returns a key prop to reinitialize a form
 */
export const reinitializeKey = (
  items: ReadonlyArray<Readonly<GetBillOfQuantityOfferConditions_billOfQuantity_offerConditions>>,
) => JSON.stringify(items.map(key('id')).sort());
