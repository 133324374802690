import React from 'react';
import DataTableToolbar from './DataTableToolbar';
import Rows from './Rows';
import ColumnsButton from './DataTableToolbar/ColumnsButton';
import { ITableContext } from '.';

interface IProps {
  toolbarProps?: { actions: React.ReactNode };
  context: ITableContext;
}

const DataTableBody: React.FC<IProps> = React.memo(({ toolbarProps, context, children }) => {
  const { tableData, dispatch } = context;
  return (
    <>
      {!tableData.options.isDropDown && !tableData.options.hideInterface && (
        <DataTableToolbar
          actions={
            <>
              {!tableData.options.hideShowAdditionalColumnsBtn && (
                <ColumnsButton context={context} dispatch={dispatch} />
              )}
              {toolbarProps && toolbarProps.actions}
            </>
          }
          context={context}
        />
      )}
      {children}
      <Rows row={tableData.pageRows} context={context} level={0} />
    </>
  );
});

export default DataTableBody;
