import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import { FastField as Field, Form, Formik } from 'formik';
import { Grid, MenuItem, Theme, Typography } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { MOBILE_PROJECT_DETAILS_QUERY } from './ProjectDetails.queries';
import {
  MobileProjectDetails as TMobileProjectDetails,
  MobileProjectDetailsVariables,
} from './types/MobileProjectDetails';
import { makeStyles } from '@material-ui/styles';
import FormikTextField from '../../../components/Form/FormikTextField';
import {
  CatalogField,
  ParentProjectField,
  PersonResponsibleField,
} from '../../../components/ProjectDetailsForm/ProjectDetailsForm.common';
import { formatDate } from '../../../utils/format/date';
import ColorCoder from '../../../components/ColorCoder';
import SubsidiarySelect from '../../../pages/Projects/TabDetails/SubsidiarySelect';
import FormikCheckboxWithLabel from '../../../components/Form/FormikCheckboxWithLabel';
import { MobileCancelSaveButtons } from '../../components/CancelSaveButtons';
import { getOperationName } from 'apollo-link';
import {
  UpdateProject,
  UpdateProjectVariables,
} from '../../../pages/Projects/TabDetails/types/UpdateProject';
import {
  mapProjectDetailsFormValues,
  ProjectStatus,
} from '../../../components/ProjectDetailsForm/ProjectDetailsForm';
import { UPDATE_PROJECT } from '../../../pages/Projects/TabDetails';
import { HoveringButton } from '../../components/HoveringButton';
import HistorizedField from '../../../components/Form/HistorizedField';
import FormikSelect from '../../../components/Form/FormikSelect';

interface IParams {
  projectNumber: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  boqButton: {
    borderRadius: '50px',
    fontSize: theme.typography.h6.fontSize,
    padding: theme.spacing(2, 4),
  },
  boqButtonContainer: {
    position: 'fixed',
    bottom: theme.spacing(2.5),
    left: '0',
    textAlign: 'center',
    width: '100%',
  },
  publishedDetails: {
    fontSize: theme.typography.subtitle2.fontSize,
    marginLeft: theme.spacing(1),
  },
}));

const FORM_XS_BREAKPOINT = 9;

export const MobileProjectDetails: React.FC = () => {
  const { projectNumber } = useParams<IParams>();

  const history = useHistory();
  const { url } = useRouteMatch();

  const { data, loading, error } = useQuery<TMobileProjectDetails, MobileProjectDetailsVariables>(
    MOBILE_PROJECT_DETAILS_QUERY,
    { variables: { projectNumber } },
  );

  const [updateProject, { error: mutationError }] = useMutation<
    UpdateProject,
    UpdateProjectVariables
  >(UPDATE_PROJECT, { refetchQueries: [getOperationName(MOBILE_PROJECT_DETAILS_QUERY) || ''] });

  const classes = useStyles();

  if (mutationError) {
    console.log(mutationError);
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  if (!data || loading || !data.project) {
    return null;
  }

  const catalog = (data.project.catalog && data.project.catalog.id) || '';

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: '20px' }}>
        Projektdetails
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        display="block"
        className={classes.publishedDetails}
      >
        Eröffnet
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary" className={classes.publishedDetails}>
            von:
          </Typography>
          <Typography variant="caption" className={classes.publishedDetails}>
            {data.project.publisher.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary" className={classes.publishedDetails}>
            am:
          </Typography>
          <Typography variant="caption" className={classes.publishedDetails}>
            {formatDate(data.project.createdAt)}
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ marginTop: '6px' }}>
          <ColorCoder colorCodings={data.project.colorCodings} />
        </Grid>
      </Grid>
      <Formik
        enableReinitialize
        onSubmit={(data, { setSubmitting }) => {
          updateProject({
            variables: { projectNumber, data: mapProjectDetailsFormValues(data) },
          });
          setSubmitting(false);
        }}
        initialValues={{
          ...data.project,
          catalog,
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <SubsidiarySelect />
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <PersonResponsibleField />
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <Field
                  fullWidth
                  name="projectNumber"
                  label="Projektnummer"
                  component={FormikTextField}
                />
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <Field
                  fullWidth
                  name="projectName"
                  label="Projektbezeichnung"
                  component={FormikTextField}
                />
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <ParentProjectField />
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <HistorizedField
                  history={data.project!.statusHistory.map((historyItem) => ({
                    id: historyItem.id,
                    date: historyItem.createdAt!,
                    value:
                      ProjectStatus[historyItem.status as keyof typeof ProjectStatus].toString(),
                    user: historyItem.setBy!,
                  }))}
                  name="status"
                  label="Status"
                  component={FormikSelect}
                >
                  ]
                  {Object.entries(ProjectStatus).map(([key, val], index) => (
                    <MenuItem key={index} value={key}>
                      {val}
                    </MenuItem>
                  ))}
                </HistorizedField>
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <Field
                  name="isSpecialProject"
                  Label={{ label: 'Spezialprojekt' }}
                  component={FormikCheckboxWithLabel}
                />
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <CatalogField />
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <Field
                  fullWidth
                  name="staffTariff"
                  label="Mitarbeiter-Tarif"
                  component={FormikTextField}
                />
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <Field
                  fullWidth
                  name="commentToBranchLeader"
                  label="Kommentar an FL"
                  component={FormikTextField}
                />
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <Field
                  fullWidth
                  name="commentToBackoffice"
                  label="Kommentar an BO"
                  component={FormikTextField}
                />
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <Field
                  fullWidth
                  name="commentToExecutives"
                  label="Kommentar an GF"
                  component={FormikTextField}
                />
              </Grid>
              <Grid item xs={FORM_XS_BREAKPOINT}>
                <Field
                  fullWidth
                  name="customerInput"
                  label="Input von Kunde"
                  component={FormikTextField}
                />
              </Grid>
              <Grid
                item
                container
                justify="space-around"
                xs={12}
                style={{ marginBottom: '115px', marginTop: '20px' }}
              >
                <MobileCancelSaveButtons isDisabled={isSubmitting || !dirty} />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <HoveringButton
        variant="contained"
        color="primary"
        onClick={() => history.push(`${url}/leistungsverzeichnis`)}
      >
        <ListAltIcon fontSize="large" style={{ marginRight: '8px' }} />
        LV AUSWÄHLEN
      </HoveringButton>
    </>
  );
};
