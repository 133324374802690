import { Button, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FastField as Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import { useBoqEntityType } from '../../../hooks/useBoqEntityType';
import { useProjectNumber } from '../../../hooks/useProjectNumber';
import { PAGINATION_HELPERS } from '../../../utils/paginationHelpers';
import DialogCloseable from '../../Dialog/DialogCloseable';
import { GetLocation_location } from '../../Location/LocationDetails/types/GetLocation';
import {
  compositeLocationId,
  parseCompositeLocationId,
} from '../BllOfQuantityTable/utils/compositeLocationId';
import { getTableType, isBoqTableType } from '../BllOfQuantityTable/utils/paginationHelper';
import { executeForPopulatedBoqTableTypes } from '../BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { buildBoqTableRowId } from '../BllOfQuantityTable/utils/paginationHelper/mapper';
import { COPY_LOCATION_MUTATION } from './CopyLocationDialog.queries';
import { LocationSelect } from './LocationSelect';
import { CopyLocation, CopyLocationVariables } from './types/CopyLocation';

export interface ICopyLocationDialogProps {
  locationCompositeId: string;
  billOfQuantityId: string;
  onClose: () => void;
  /**
   * Called when a location was successfully copied
   * @param id the newly created location's id
   */
  onCopied?: (id: GetLocation_location['id']) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    padding: theme.spacing(3),
  },
}));

export const CopyLocationDialog: React.FC<ICopyLocationDialogProps> = ({
  locationCompositeId,
  billOfQuantityId,
  onClose,
  onCopied,
}) => {
  const projectNumber = useProjectNumber();
  const client = useApolloClient();
  const boqEntityType = useBoqEntityType();

  const locationId = parseCompositeLocationId(locationCompositeId).locationId;
  const tableType = getTableType(locationCompositeId);

  const addCopiedLocationInCache = useCallback(
    async (data: CopyLocation) => {
      if (!projectNumber) {
        return;
      }

      executeForPopulatedBoqTableTypes(
        client,
        projectNumber,
        (tableType) => {
          return PAGINATION_HELPERS[projectNumber][tableType]?.addLocationToCache(
            data.copyLocation,
          );
        },
        boqEntityType,
      );
    },
    [projectNumber, client, boqEntityType],
  );

  const [copyLocation, { error }] = useMutation<CopyLocation, CopyLocationVariables>(
    COPY_LOCATION_MUTATION,
  );

  const classes = useStyles();

  if (error) {
    console.log(error);
  }

  const onSubmit = useCallback(
    async (data: any) => {
      if (!tableType || !isBoqTableType(tableType)) {
        return;
      }

      const { data: copyLocationData } = await copyLocation({
        variables: {
          targetId: data.targetLocationId,
          sourceId: locationId,
          targetBillOfQuantityId: billOfQuantityId,
        },
      });
      if (!copyLocationData?.copyLocation) {
        return;
      }
      addCopiedLocationInCache(copyLocationData);

      const compositeId = compositeLocationId({
        billOfQuantityId,
        locationId: copyLocationData.copyLocation.id,
        parentLocationId: data.targetLocationId,
      });
      const tableRowId = buildBoqTableRowId(tableType)(compositeId);

      onCopied?.(tableRowId);
      onClose();
    },
    [
      copyLocation,
      locationId,
      billOfQuantityId,
      onClose,
      onCopied,
      tableType,
      addCopiedLocationInCache,
    ],
  );

  return (
    <DialogCloseable
      open={!!locationId}
      onClose={onClose}
      title="Örtlichkeit kopieren"
      contentClassName={classes.dialogContainer}
    >
      <Formik initialValues={{ targetLocationId: '' }} onSubmit={onSubmit}>
        {({ isSubmitting, dirty, setValues }) => (
          <Form>
            <Grid container justify="center" spacing={4}>
              <Grid item xs={12}>
                <Field
                  setValues={setValues}
                  selectFirst
                  name="targetLocationId"
                  label="Ziel"
                  sourceLocationId={locationId}
                  billOfQuantityId={billOfQuantityId}
                  component={LocationSelect}
                />
              </Grid>
              <Grid container item justify="center" xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  size="large"
                  variant="contained"
                  disabled={isSubmitting || !dirty}
                >
                  Kopieren
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogCloseable>
  );
};
