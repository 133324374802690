export const PROJECT_COMPUTED_FIELDS = [
  'personResponsible.name',
  'publisher.name',
  'colorCodings',
  'subsidiary.name',
  'statusHistory',
  'documents',
  'parentId',
  'parent',
  'allChildren',
  'allParents',
  'shippingAddress.',
  'projectAddres',
  'billingAddres',
  'hasFiles',
  'bills',
  'missions',
  'billOfQuantities',
  'sum',
  'offerSum',
  'projectAddress.street',
  'projectAddress.mailbox',
  'projectAddress.postCode',
  'projectAddress.country',
  'shippingAddress.street',
  'shippingAddress.mailbox',
  'hippingAddress.postCode',
  'shippingAddress.city',
  'shippingAddress.country',
  'catalog.name',
];
