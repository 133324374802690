import gql from 'graphql-tag';

export const GET_BOUNDARIES_QUERY = gql`
  query GetBoundaries($month: Int!, $year: Int!, $subsidiary: ID, $excludeStatus: [PStatus!]!) {
    boundaries(
      where: { month: $month, year: $year, subsidiary: $subsidiary, excludeStatus: $excludeStatus }
    ) {
      id
      name
      subsidiaryBoundaries
      boundaryProjects {
        id
        name
        status
        projectNumber
        subsidiaryName

        invoicedPriceMonth
        finishedPriceMonth

        finishedPriceTotal
        invoicedPriceTotal

        boundaries
        boundariesPreviousMonth
      }
    }
  }
`;
