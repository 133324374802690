import { removeTableTypeFromId } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';

declare const _compositeLocationId: unique symbol;

export type CompositeLocationId = string & {
  [_compositeLocationId]: true;
};

export const isCompositeLocationId = (value: any): value is CompositeLocationId => {
  if (typeof value !== 'string') {
    return false;
  }

  // ignore table type because it does not affect `parseCompositeLocationId`
  const stringWithoutTableType = removeTableTypeFromId(value);
  const parts: string[] = stringWithoutTableType.split('-');
  return parts.length === 3 && parts.every((part) => part.length);
};

export function assertCompositeLocationId(value: any): asserts value is CompositeLocationId {
  if (!isCompositeLocationId(value)) {
    throw new TypeError(`value is not a composite location id ${JSON.stringify(value)}`);
  }
}

export interface ICompositeLocationParts {
  billOfQuantityId: string;
  parentLocationId: string;
  locationId: string;
}
export const compositeLocationId = ({
  billOfQuantityId,
  parentLocationId,
  locationId,
}: ICompositeLocationParts): CompositeLocationId =>
  `${billOfQuantityId}-${parentLocationId}-${locationId}` as CompositeLocationId;

export const parseCompositeLocationId = (
  compositeLocationId: CompositeLocationId | string,
): ICompositeLocationParts => {
  assertCompositeLocationId(compositeLocationId);
  const [billOfQuantityId, parentLocationId, locationId] = compositeLocationId.split('-');
  return { billOfQuantityId, parentLocationId, locationId };
};
