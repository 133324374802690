import { MutationUpdaterFn } from 'apollo-client';
import { omit } from 'lodash';
import React, { useCallback } from 'react';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import { useBoqEntityType } from '../../../hooks/useBoqEntityType';
import { useProjectNumber } from '../../../hooks/useProjectNumber';
import { removeTableTypeFromId } from '../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import {
  SIMPLE_DATA_TABLE_ROW_FRAGMENT,
  UPDATE_DATA_TABLE_ROW,
} from '../../../services/graphql-client';
import { simpleDataTableRowFields } from '../../../services/types/simpleDataTableRowFields';
import {
  UpdateDataTableRow,
  UpdateDataTableRowVariables,
} from '../../../services/types/UpdateDataTableRow';
import { getChangedFormValues } from '../../../utils/form/getChanged';
import {
  compositeLocationId,
  parseCompositeLocationId,
} from '../../BillOfQuantity/BllOfQuantityTable/utils/compositeLocationId';
import { executeForPopulatedBoqTableTypes } from '../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { buildBoqTableRowId } from '../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/mapper';
import DialogCloseable from '../../Dialog/DialogCloseable';
import { LocationForm } from '../LocationForm';
import { GET_LOCATION, UPDATE_LOCATION } from './LocationDetails.queries';
import { GetLocation, GetLocationVariables } from './types/GetLocation';
import { UpdateLocation, UpdateLocationVariables } from './types/UpdateLocation';

interface IProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

export const LocationDetails: React.FC<IProps> = ({ id, open, onClose }) => {
  const projectNumber = useProjectNumber();
  const client = useApolloClient();
  const normalizedId = removeTableTypeFromId(id);
  const locationId = parseCompositeLocationId(normalizedId).locationId;
  const boqEntityType = useBoqEntityType();

  const { data, loading, error } = useQuery<GetLocation, GetLocationVariables>(GET_LOCATION, {
    variables: { where: { id: locationId } },
  });

  const updateLocationInCache = useCallback<MutationUpdaterFn<UpdateLocation>>(
    async (cache, { data }) => {
      if (!data?.updateLocation || !projectNumber) {
        return;
      }

      const { updateLocation: updatedLocation } = data;

      const compositeId = compositeLocationId({
        locationId: updatedLocation.id,
        billOfQuantityId: updatedLocation.billOfQuantity.id,
        parentLocationId: updatedLocation.parentLocation!.id,
      });

      const dataTableRow = cache.readFragment<simpleDataTableRowFields>({
        fragment: SIMPLE_DATA_TABLE_ROW_FRAGMENT,
        id: `DataTableRow:${id}`,
      });

      if (!dataTableRow) {
        return;
      }

      await executeForPopulatedBoqTableTypes(
        client,
        projectNumber,
        async (tableType) => {
          const tableRowId = buildBoqTableRowId(tableType)(compositeId);

          await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
            query: UPDATE_DATA_TABLE_ROW,
            variables: {
              where: { id: tableRowId, tableType },
              data: {
                data: JSON.stringify({
                  ...JSON.parse(dataTableRow.data),
                  ...omit(updatedLocation, 'billOfQuantity', 'parentLocation'),
                }),
              },
            },
          });
        },
        boqEntityType,
      );
    },
    [client, projectNumber, id, boqEntityType],
  );

  const [updateLocation] = useMutation<UpdateLocation, UpdateLocationVariables>(UPDATE_LOCATION, {
    update: updateLocationInCache,
  });

  if (loading || !data) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  const initialValues = data.location;

  return (
    <DialogCloseable open={open} onClose={onClose} title="Örtlichkeit editieren">
      <LocationForm
        initialValues={initialValues}
        onSubmit={async (values) => {
          await updateLocation({
            variables: {
              where: { id: locationId },
              data: getChangedFormValues({ initialValues, values, ignoreKeys: ['documents'] }),
            },
          });

          if (onClose) {
            onClose();
          }
        }}
      />
    </DialogCloseable>
  );
};
