import { makeStyles } from '@material-ui/styles';

export const useTabStyles = makeStyles({
  rootIcon: {
    fontSize: 16,
  },
  scrollButtons: {
    paddingBottom: '10px',
  },
  tabRoot: {
    paddingBottom: '10px',
    minWidth: '140px',
  },
});

// In our design the tab icons are left from the tab label.
// Unfortunately Material-Design only supports icons above the tab label.
// So we have to fix the margins of the icons.
// CSS margins doesn't work as expected here, so I modify the viewBox.
// See also https://github.com/mui-org/material-ui/issues/11653
export const tabIconViewBox = '0 -3 24 24';
