import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Grid, Tooltip } from '@material-ui/core';
import { GridJustification } from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  base: {
    margin: 2,
    marginRight: -7,
    color: '#fff',
    width: '16px',
    height: '16px',
    '&:last-child': {
      marginRight: 2,
    },
  },
}));

const backgroundImages = {
  ROSE: 'linear-gradient(to bottom, #fac2d9, #f48fb1)',
  LIGHT_BLUE: 'linear-gradient(to bottom, #45c7fa, #2196f3)',
  GREEN: 'linear-gradient(to bottom, #83d888, #4caf50)',
  GREY: 'linear-gradient(to bottom, #e0e0e0, #bdbdbd)',
  VIOLET: 'linear-gradient(to bottom, #cb4fd8, #9c27b0)',
  DARK_BLUE: 'linear-gradient(to bottom, #7389db, #3f51b5)',
  BROWN: 'linear-gradient(to bottom, #b18e7e, #795548)',
  YELLOW: 'linear-gradient(to bottom, #ffc900, #ff9800)',
  LIGHT_YELLOW: 'linear-gradient(to bottom, #ffee65, #fdd835)',
  RED: 'linear-gradient(to bottom, #fa7866, #f44336)',
  BLACK: 'linear-gradient(to bottom, #7c7c7c, #000000)',
  DARK_YELLOW: 'linear-gradient(to bottom, #d7d200, #a8a400)',
};

export enum SimpleMaterialColor {
  ROSE = '#f48fb1',
  LIGHT_BLUE = '#2196f3',
  GREEN = '#4caf50',
  GREY = '#bdbdbd',
  VIOLET = '#9c27b0',
  DARK_BLUE = '#3f51b5',
  BROWN = '#795548',
  YELLOW = '#ff9800',
  LIGHT_YELLOW = '#fdd835',
  RED = '#f44336',
  BLACK = '#000000',
  DARK_YELLOW = '#d7d200',
}

export enum Color {
  ROSE = 'M66 Signalin',
  LIGHT_BLUE = 'KSP',
  GREEN = 'KP/A',
  GREY = 'KP/S',
  YELLOW = 'Spielfelder',
  DARK_BLUE = 'HM',
  BROWN = 'Demarkieren',
  VIOLET = 'Signalisation',
  LIGHT_YELLOW = 'Folien',
  RED = 'Flächenbeschichtung',
  BLACK = 'TK - Taktil',
  DARK_YELLOW = 'Primer',
}

interface IColorCoderProps {
  colorCodings: Color[];
  justify?: GridJustification;
}

const ColorCoder: React.FunctionComponent<IColorCoderProps> = ({ colorCodings, justify }) => {
  const classes = useStyles();
  return (
    <Grid container justify={justify || 'center'} alignItems="center">
      {colorCodings.map((color: any) => (
        <Tooltip key={color} title={Color[color as keyof typeof Color]}>
          <Avatar
            style={{
              backgroundImage: backgroundImages[color as keyof typeof backgroundImages],
            }}
            className={classes.base}
          />
        </Tooltip>
      ))}
    </Grid>
  );
};

export default ColorCoder;
