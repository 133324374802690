import React, { useCallback, useEffect, useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { omit } from 'lodash/fp';
import { useCatalogs } from '../../../../pages/Catalogs/fetch';

/**
 * Shows a catalog select element that selects the first element by default if any and if the value is not controlled.
 */
const CatalogSelect: React.FunctionComponent<SelectProps & { prefill?: boolean }> = (props) => {
  const { catalogs, loading } = useCatalogs();
  const [value, setValue] = useState('');

  const propsOnChange = props.onChange;
  // set the currently selected value in onchange
  const onChange = useCallback(
    (evt, child) => {
      setValue(evt.target.value as string);
      propsOnChange?.(evt, child);
    },
    [propsOnChange, setValue],
  );

  // in case of a controlled input we disable initial selection
  const isControlled = (props.value ?? '') !== '';

  // set the initially selected value once it's loaded
  useEffect(() => {
    if (!isControlled && props.prefill) {
      setValue(catalogs[0]?.id ?? '');
    }
  }, [isControlled, catalogs, props.prefill]);

  if (loading) {
    return null;
  }

  return (
    <Select
      {...omit(['prefill'], props)}
      // when the value is controlled use the passed in props, otherwise use the select first mechanism
      value={isControlled ? props.value : value}
      onChange={isControlled ? props.onChange : onChange}
    >
      <MenuItem value="" />
      {catalogs.map((catalog: any) => (
        <MenuItem key={catalog.id} value={catalog.id}>
          {catalog.useName}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CatalogSelect;
