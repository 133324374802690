import React, { useState, useCallback, useMemo } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import { DELETE_MISSION_MUTATION } from './DeleteMissionAction.queries';
import { MutationUpdaterFn, PureQueryOptions } from 'apollo-client';
import { Tooltip, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import AppErrorMessage from '../../../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../../utils/errorPrefixRemover';
import ConfirmationDialog from '../../../ConfirmationDialog';
import { DeleteMission, DeleteMissionVariables } from './types/DeleteMission';
import { REMOVE_DATA_TABLE_ROW } from '../../../../services/graphql-client';
import {
  RemoveDataTableRow,
  RemoveDataTableRowVariables,
} from '../../../../services/types/RemoveDataTableRow';
import { executeForMissionTableTypes } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/executeForMissionTypes';
import { buildTableId } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { useProjectNumber } from '../../../../hooks/useProjectNumber';
import { MISSION_STRUCTURE_QUERY } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/queries';
import {
  MissionStructureQuery,
  MissionStructureQueryVariables,
} from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/types/MissionStructureQuery';
import { executeForPopulatedBoqTableTypes } from '../../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { forceDataTableRefetch } from '../../../../utils/paginationHelpers/forceDataTableRefetch';

interface IProps {
  missionId: number;
  canBeDeleted?: boolean;
  refetchQueries?: PureQueryOptions[];
}

const CONFIRMATION_DIALOG_MESSAGE =
  'Möchtest du den Einsatz und die geplanten Positionen aus dem Einsatz löschen?';
const CONFIRMATION_DIALOG_TITLE = 'Einsatz löschen';

export const DeleteMissionAction: React.FC<IProps> = ({ missionId, canBeDeleted }) => {
  const client = useApolloClient();
  const [showDialog, setShowDialog] = useState(false);
  const projectNumber = useProjectNumber();

  /** removes the deleted mission from the query that fetches the structure */
  const deleteMissionInStructureData = useCallback(
    (deletedMissionId: number) => {
      if (!projectNumber) {
        return;
      }

      const structureData = client.readQuery<MissionStructureQuery, MissionStructureQueryVariables>(
        {
          query: MISSION_STRUCTURE_QUERY,
          variables: { projectNumber },
        },
      );
      if (!structureData) {
        return;
      }

      structureData.missions = structureData.missions.filter(
        (mission) => mission.id !== deletedMissionId,
      );
      client.writeQuery<MissionStructureQuery, MissionStructureQueryVariables>({
        query: MISSION_STRUCTURE_QUERY,
        data: structureData,
        variables: { projectNumber },
      });
    },
    [client, projectNumber],
  );

  /**
   * forces billOfQuantity dataTables to refetch
   * performance-tradeoff should be fine
   * because deleting a mission does not happen very frequently
   */
  const refetchDependantBillOfQuantities = useCallback(() => {
    if (!projectNumber) {
      throw new Error();
    }

    executeForPopulatedBoqTableTypes(client, projectNumber, (tableType) => {
      forceDataTableRefetch(tableType, client);
    });
  }, [client, projectNumber]);

  const deleteMissionInCache = useCallback<MutationUpdaterFn<DeleteMission>>(
    (_, { data }) => {
      if (!data) {
        return;
      }

      executeForMissionTableTypes((tableType) =>
        client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
          query: REMOVE_DATA_TABLE_ROW,
          variables: {
            where: {
              id: buildTableId(tableType)(data.deleteMission.id.toString()),
              tableType,
            },
          },
        }),
      );
      client.cache.writeData({ id: `Mission:${data.deleteMission.id}`, data: null });

      deleteMissionInStructureData(data.deleteMission.id);
      refetchDependantBillOfQuantities();
    },
    [client, deleteMissionInStructureData, refetchDependantBillOfQuantities],
  );

  const [deleteMission, { loading, error }] = useMutation<DeleteMission, DeleteMissionVariables>(
    DELETE_MISSION_MUTATION,
    {
      update: deleteMissionInCache,
    },
  );

  const handleClick = useCallback(() => setShowDialog(true), [setShowDialog]);

  const handleOnConfirm = useCallback(
    () => deleteMission({ variables: { where: { id: missionId } } }),
    [deleteMission, missionId],
  );

  const handleOnClose = useCallback(() => setShowDialog(false), [setShowDialog]);

  const tooltipTitle = useMemo(
    () => (canBeDeleted ? 'Löschen' : 'Positionen wurden schon ausgemessen'),
    [canBeDeleted],
  );

  return (
    <>
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <ConfirmationDialog
        open={showDialog}
        title={CONFIRMATION_DIALOG_TITLE}
        message={CONFIRMATION_DIALOG_MESSAGE}
        onConfirm={handleOnConfirm}
        onClose={handleOnClose}
      />
      <Tooltip title={tooltipTitle}>
        <span>
          <IconButton
            aria-label={tooltipTitle}
            onClick={handleClick}
            disabled={loading || !canBeDeleted}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
