import { IconButton, RootRef, Tooltip } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ApolloClient from 'apollo-client';
import { Field, Formik, FormikHelpers } from 'formik';
import { find, last } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';
import * as Yup from 'yup';
import { ContainerSortMissionAction } from '../../../components/Actions/ContainerSort/Mission';
import { CopyMissionAction } from '../../../components/Actions/Copy/Mission';
import { DeleteMissionAction } from '../../../components/Actions/Delete/Mission';
import { DeleteMissionItemAction } from '../../../components/Actions/Delete/MissionItem';
import { executeForPopulatedBoqTableTypes } from '../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import ColorCoder from '../../../components/ColorCoder';
import { DataTableBody, DataTableHotKeysWrapper } from '../../../components/DataTable';
import {
  IDataTableColumn,
  IDataTableOptions,
  IDataTableRow,
} from '../../../components/DataTable/types';
import FormikCheckbox from '../../../components/Form/FormikCheckboxWithLabel';
import FormikTextField from '../../../components/Form/FormikTextField';
import InlineEditingFormikForm from '../../../components/Form/InlineEditingFormikForm';
import { default as ItemDetails } from '../../../components/Item/ItemDetails';
import useBlockedMissionClassName from '../../../components/Mission/useMissionBlockedStyles';
import AppProgress from '../../../components/Page/AppProgress';
import { Search, useSearchState } from '../../../components/Search/Search';
import { Color, TableType } from '../../../types/graphql';
import { roundToTwoDecimalPlaces } from '../../../utils/calculator';
import { createSwissCurrencyFormatter } from '../../../utils/createCurrencyFormatter';
import { formatDate } from '../../../utils/format/date';
import { formatVolume } from '../../../utils/format/volume';
import { PAGINATION_HELPERS } from '../../../utils/paginationHelpers';
import { getAllInnerTableRows } from '../../../utils/paginationHelpers/getAllInnerTableRows';
import { setAllRowsVisible } from '../../../utils/paginationHelpers/setAllRowsVisible';
import { preventNonNumericInput } from '../../../utils/preventNonNumericInput';
import { useMissionItemEdit } from '../TabMissions/MissionSelector/missionItemEdit';
import {
  buildMissionLocationId,
  useMissionData,
} from '../TabMissions/MissionSelector/paginationHelpers';
import { getPopulatedMissionTableTypes } from '../TabMissions/MissionSelector/paginationHelpers/getPopulatedTableTypes';
import {
  buildTableId,
  removeTableTypeFromId,
} from '../TabMissions/MissionSelector/paginationHelpers/mapper';
import { ProjectMissionMeasures_project_missions } from './types/ProjectMissionMeasures';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const missionColumns: IDataTableColumn[] = [
  {
    id: 'id',
    label: 'Laufnummer',
    width: 100,
  },
  {
    id: 'name',
    label: 'Einsatz',
    width: 100,
  },
  {
    id: 'status',
    label: 'Status',
    width: 80,
  },
  {
    id: 'netPlanSum',
    label: 'Plan Summe Netto',
    width: 120,
    render: formatCurrency,
  },
  {
    id: 'grossPlanSum',
    label: 'Plan Summe Brutto',
    render: formatCurrency,
    width: 100,
  },
  {
    id: 'netFinishedSum',
    label: 'Ausmass Summe Netto',
    width: 140,
    render: formatCurrency,
  },
  {
    id: 'grossFinishedSum',
    label: 'Ausmass Summe Brutto',
    render: formatCurrency,
    width: 140,
  },
  {
    id: 'executionDate',
    label: 'Ausführungsdatum',
    render: (date: string | null) => date && formatDate(date),
  },
  {
    id: 'colorCodings',
    label: '',
    width: 40,
    render: (colorCodings: Color[]) => colorCodings && <ColorCoder colorCodings={colorCodings} />,
  },
];
const locationColumns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Örtlichkeit',
  },
];

const getInitialValues = (items: any[]): { [x: string]: boolean | number } =>
  items.reduce((init, item) => {
    return {
      ...init,
      [item.id]: {
        finishedVolume: item.finishedVolume || 0,
        isFinished: item.isFinished || false,
        comment: item.comment,
        customerComment: item.customerComment,
        openVolume: item.openVolume,
      },
    };
  }, {});

interface IHandleUpdateItemParams {
  rowId: string;
  values: any;
  formikBag: Pick<FormikHelpers<any>, 'setSubmitting' | 'setFieldError'> & { dirty: boolean };
  skipCheck?: boolean;
  rowToFocus?: string;
}

interface IProps {
  projectNumber: string;
  onChange: (mission: any | undefined) => void;
  onInputChange: (item: any, id: string) => Promise<any> | undefined;
}

const validationSchema = Yup.object().shape({
  openVolume: Yup.number().when('isFinished', {
    is: false,
    then: Yup.number()
      .min(0, 'Die offene Menge darf nicht kleiner als 0 sein')
      .required('Pflichtfeld!'),
  }),
});

/**
 * refetches the container in bill and mission view where mission-item resides
 * to reflect updated mission-item
 */
const refetchMissionContainerInOtherTableTypes = (
  flatItemRowToEdit: any,
  projectNumber: string,
  client: ApolloClient<any>,
) => {
  const parentContainerId = buildMissionLocationId(flatItemRowToEdit.mission.id, {
    id: flatItemRowToEdit.location.id,
    parentLocation: flatItemRowToEdit.location.parentLocation,
  });

  const populatedMissionTableTypes = getPopulatedMissionTableTypes(client);
  const tableTypesWithoutMeasurement = populatedMissionTableTypes.filter(
    (tableType) => tableType !== TableType.MEASUREMENT,
  );
  tableTypesWithoutMeasurement.forEach((tableType) => {
    const containerId = buildTableId(tableType)(parentContainerId);
    const missionId = parseInt(containerId.split('-')[0], 10);

    PAGINATION_HELPERS[projectNumber][tableType].refetchItemsInContainer([{ containerId }]);
    PAGINATION_HELPERS[projectNumber][tableType].updateComputedFields({
      id: missionId,
      project: { projectNumber },
    });
  });
};

const MissionSelector: React.FC<IProps> = ({ projectNumber, onChange, onInputChange }) => {
  const getBlockedClassName = useBlockedMissionClassName();

  const [showFinishedMissionItems, setShowFinishedMissionItems] = React.useState<boolean>(true);
  const [editItemRow, setEditItemRow] = React.useState<null | IDataTableRow['data']>(null);

  const searchState = useSearchState();
  const client = useApolloClient();

  const hideFullyInvoiced = useMemo(
    () => (showFinishedMissionItems ? undefined : false),
    [showFinishedMissionItems],
  );

  const {
    mappedRows,
    flattenedContainerRows,
    onLoadMore,
    isOnLoadMoreDisabled,
    isLevelToggleButtonVisible,
    updateMissionItem,
    onSearchItems,
    onToggleItems: onToggleShowFinishedItems,
    loading,
    additionalPositions,
    refetchAdditionalPositions,
  } = useMissionData(
    projectNumber,
    TableType.MEASUREMENT,
    showFinishedMissionItems,
    hideFullyInvoiced,
  );

  const [activeRow, setActiveRow] = useState<string>('');
  const [focusedRow, setFocusedRow] = useState<string>('');

  const focusedRowRef = React.useRef<any>(null);

  const focusRow = useCallback(
    (rowId: string) => {
      setFocusedRow(rowId);

      const input = focusedRowRef.current?.querySelector('input');
      input?.focus();
      input?.select();
    },
    [setFocusedRow],
  );

  const { flatItems, tableData, initialValues } = useMemo(() => {
    const mappedRowsWithAdditionalPositions = mappedRows.map((row) => {
      const missionId = row.data.id;
      const additional = additionalPositions?.[missionId]?.['defaultLocation'];

      return {
        ...row,
        containerRows: row.containerRows?.map((containerRow) => {
          const addPos = additionalPositions?.[missionId]?.[containerRow.data.id];
          return {
            ...containerRow,
            data: { ...containerRow.data, additionalPositions: addPos, refetchAdditionalPositions },
          };
        }),
        data: { ...row.data, additionalPositions: additional, refetchAdditionalPositions },
      };
    });

    const flatItems = mappedRows.flatMap<any & { id: string }>((mappedRow) =>
      getAllInnerTableRows(mappedRow).map((innerRow) => ({
        ...innerRow.data,
        id: innerRow.id,
      })),
    );

    return {
      flatItems,
      tableData: mappedRowsWithAdditionalPositions,
      initialValues: getInitialValues(flatItems),
    };
  }, [additionalPositions, mappedRows, refetchAdditionalPositions]);

  const { renderVolumeCell, onVolumeDoubleClick } = useMissionItemEdit(flatItems);

  const handleUpdateItem = useCallback(
    async ({
      rowId,
      formikBag: { setSubmitting, setFieldError, dirty },
      values,
      rowToFocus,
      skipCheck,
    }: IHandleUpdateItemParams) => {
      if (!skipCheck && rowId === activeRow) {
        return;
      }

      setActiveRow(rowId);

      if (!dirty) {
        return;
      }

      setSubmitting(true);

      if (!activeRow) {
        return setSubmitting(false);
      }

      const itemValues = values[activeRow];
      await onInputChange(itemValues, activeRow);

      try {
        await validationSchema.validate(itemValues);
      } catch (error: any) {
        setFieldError(`${activeRow}`, error.message);

        return setSubmitting(false);
      }

      const flatItemRowToEdit = flatItems.find((item) => item.id === activeRow)!;

      const finishedVolume = itemValues.finishedVolume ?? 0;
      const updateItemValues = {
        ...itemValues,
        canBeDeleted: !itemValues.isFinished,
        finishedVolume,
        itemFinishedVolume: finishedVolume,
      };

      updateMissionItem(activeRow, updateItemValues, true);
      refetchMissionContainerInOtherTableTypes(flatItemRowToEdit, projectNumber, client);

      executeForPopulatedBoqTableTypes(client, projectNumber, (tableType) =>
        PAGINATION_HELPERS[projectNumber][tableType]?.updateItem(
          {
            updateItem: {
              id: removeTableTypeFromId(flatItemRowToEdit.item.id),
              doneVolume: finishedVolume,
              openVolume: itemValues.openVolume,
            },
          },
          false,
        ),
      );

      setSubmitting(false);

      setFocusedRow(rowId);

      if (!flatItems) {
        return;
      }

      if (rowToFocus) {
        return focusRow(rowToFocus);
      }

      const rowIndex = flatItems.findIndex((item) => item.id === activeRow);
      const nextActiveRowIndex = !(rowIndex + 1 >= flatItems.length) ? rowIndex + 1 : rowIndex;

      focusRow(flatItems[nextActiveRowIndex].id);
    },
    [
      setActiveRow,
      onInputChange,
      activeRow,
      focusRow,
      flatItems,
      updateMissionItem,
      projectNumber,
      client,
    ],
  );

  const toggleShowFinishedItems = useCallback(() => {
    setShowFinishedMissionItems((v) => !v);
    onToggleShowFinishedItems(!showFinishedMissionItems);
  }, [showFinishedMissionItems, onToggleShowFinishedItems]);
  const fetchMoreItems = useMemo(
    () => onLoadMore({ hideFullyInvoiced }),
    [hideFullyInvoiced, onLoadMore],
  );

  const dataTableOptions: IDataTableOptions = useMemo(
    () => ({
      tableName: 'MEASUREMENT_MISSION_SELECTOR',
      fixedWidthColumns: true,
      filterText: searchState.searchTerm,
      levels: [
        {
          columns: missionColumns,
          isDefaultClosed: true,
          className: (row: IDataTableRow<ProjectMissionMeasures_project_missions>) => {
            return getBlockedClassName(row.data.isMissionBlocked, row.data.manualLockingStatus);
          },
          onLoadMore: fetchMoreItems,
          isOnLoadMoreDisabled,
          isLevelToggleButtonVisible,
          rowActions: ({ row }: any) => (
            <>
              <Tooltip title="Bearbeiten">
                <Link
                  to={`/projekte/${projectNumber}/ausmass/${row.data.id}/details/editieren`}
                  tabIndex={-1}
                >
                  <IconButton aria-label="Bearbeiten" tabIndex={-1}>
                    <CreateIcon fontSize="small" />
                  </IconButton>
                </Link>
              </Tooltip>
              <DeleteMissionAction missionId={row.data.id} canBeDeleted={row.data.canBeDeleted} />
              <CopyMissionAction missionRowId={row.id} isFromMeasurements />
            </>
          ),
        },
        {
          columns: locationColumns,
          isDefaultClosed: true,
          onLoadMore: fetchMoreItems,
          isOnLoadMoreDisabled,
          isLevelToggleButtonVisible,
        },
        {
          columns: locationColumns,
          isDefaultClosed: true,
          onLoadMore: fetchMoreItems,
          isOnLoadMoreDisabled,
          isLevelToggleButtonVisible,
        },
        {
          columns: [
            {
              id: 'productNumber',
              label: 'Produktnummer',
              width: 125,
            },
            {
              id: 'descriptionOne',
              label: 'Beschreibung 1',
              width: 120,
            },
            {
              id: 'billOfQuantity',
              label: 'LV',
              width: 120,
              hideOnDefault: true,
            },
            {
              id: 'unit',
              label: 'Einheit',
              width: 60,
            },
            {
              id: 'freeText',
              label: 'Freitext',
              width: 100,
            },
            {
              id: 'volume',
              label: 'Offerten Menge',
              width: 75,
              render: formatVolume,
            },
            {
              id: 'allocatedVolume',
              label: 'Bisher geplante Menge',
              width: 75,
              render: (cell: any) => cell && formatVolume(cell),
              hideOnDefault: true,
            },
            {
              id: 'planVolume',
              label: 'Plan Menge',
              width: 65,
              render: renderVolumeCell,
              renderPlain: true,
              onDoubleClick: onVolumeDoubleClick,
            },
            {
              id: 'itemFinishedVolume',
              label: 'Ausmass Menge',
              width: 80,
              render: formatVolume,
            },
            {
              id: 'netOfferSum',
              label: 'Offerten Summe Netto',
              width: 120,
              render: formatCurrency,
              hideOnDefault: true,
            },
            {
              id: 'grossOfferSum',
              label: 'Offerten Summe Brutto',
              width: 120,
              render: formatCurrency,
              hideOnDefault: true,
            },
            {
              id: 'finishedVolume',
              label: 'Jetzt Ausmass Menge',
              width: 80,
              render: (cell: any, row: IDataTableRow) => {
                const field = (
                  <Field
                    name={`${row.id}.finishedVolume`}
                    component={FormikTextField}
                    type="number"
                    onKeyPress={preventNonNumericInput}
                    disabled={
                      !row.data.missionHasExecutionDate || row.data.mission.isMissionBlocked
                    }
                  />
                );

                return row.id === focusedRow ? (
                  <RootRef rootRef={focusedRowRef}>{field}</RootRef>
                ) : (
                  field
                );
              },
            },
            {
              id: 'isFinished',
              label: 'erledigt',
              width: 70,
              render: (cell: any, row: IDataTableRow) => (
                <Field
                  name={`${row.id}.isFinished`}
                  component={FormikCheckbox}
                  disabled={!row.data.missionHasExecutionDate || row.data.mission.isMissionBlocked}
                />
              ),
            },
            {
              id: 'openVolume',
              label: 'Offene Menge',
              width: 65,
              render: (cell: any, row: IDataTableRow) => {
                return (
                  <Field name={`${row.id}.openVolume`} onKeyPress={preventNonNumericInput}>
                    {({ field, form, ...props }: any) => {
                      // Row is disabled if
                      const isDisabled =
                        // user changed current row to finished (erledigt)
                        (activeRow === row.id
                          ? form.values[activeRow].isFinished
                          : // or is initially disabled (from server)
                            row.data.isFinished) ||
                        // mission was not executed (no execution date)
                        !row.data.missionHasExecutionDate ||
                        // mission is blocked (fully invoiced)
                        row.data.mission.isMissionBlocked;

                      return (
                        <FormikTextField
                          field={field}
                          form={form}
                          {...props}
                          disabled={isDisabled}
                          type="number"
                        />
                      );
                    }}
                  </Field>
                );
              },
            },
            {
              id: 'comment',
              label: 'Zusatztext',
              width: 100,
              hideOnDefault: true,
              render: (cell: any, row: IDataTableRow) => (
                <Field
                  name={`${row.id}.comment`}
                  component={FormikTextField}
                  disabled={!row.data.missionHasExecutionDate}
                />
              ),
            },
            {
              id: 'customerComment',
              label: 'Bemerkungen Kunde',
              width: 100,
              render: (_, row: IDataTableRow) => (
                <Field
                  name={`${row.id}.customerComment`}
                  component={FormikTextField}
                  disabled={!row.data.customerComment}
                />
              ),
            },
          ],
          rowActions: ({ row }: any) => (
            <>
              <Tooltip title="Editieren">
                <IconButton
                  onClick={() => setEditItemRow(row)}
                  aria-label="Editieren"
                  tabIndex={-1}
                >
                  <CreateIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <DeleteMissionItemAction
                disabled={!row.data.canBeDeleted}
                missionItemRowId={row.id}
                flattenedContainerRows={flattenedContainerRows}
              />
            </>
          ),
          actions: () => (
            // semantically wrong place but for now just going with it!
            <>
              <Tooltip
                title={`Abgeschlossene Positionen ${
                  showFinishedMissionItems ? 'ausblenden' : 'einblenden'
                }`}
              >
                <IconButton onClick={toggleShowFinishedItems}>
                  {showFinishedMissionItems ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Tooltip>
              <ContainerSortMissionAction tableType={TableType.MEASUREMENT} />
            </>
          ),
        },
      ],
      onChangeActiveRow: (rows) => {
        const activeRow = last(rows);

        onChange(activeRow && activeRow.data);
      },
    }),
    [
      searchState,
      showFinishedMissionItems,
      setEditItemRow,
      activeRow,
      focusedRowRef,
      projectNumber,
      focusedRow,
      onChange,
      isOnLoadMoreDisabled,
      fetchMoreItems,
      flattenedContainerRows,
      isLevelToggleButtonVisible,
      toggleShowFinishedItems,
      getBlockedClassName,
      renderVolumeCell,
      onVolumeDoubleClick,
    ],
  );

  const handleSubmit = useCallback(() => {
    return;
  }, []);

  useMount(() => {
    try {
      setAllRowsVisible(client.cache, TableType.MEASUREMENT);
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <>
      {loading && <AppProgress />}
      <DataTableHotKeysWrapper
        containerRows={tableData}
        options={dataTableOptions}
        search={
          <Search
            columns={['item.productNumber', 'item.descriptionOne', 'item.unit', 'comment']}
            columnsToHighlight={['productNumber', 'descriptionOne', 'unit', 'comment']}
            searchState={searchState}
            onSubmit={(search) =>
              onSearchItems(
                search,
                { isFinished: showFinishedMissionItems ? undefined : false },
                projectNumber,
              )
            }
            loading={loading}
          />
        }
      >
        {(context) => {
          return (
            <>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnChange={false}
              >
                {({ values, setFieldValue, touched, ...formikProps }) => {
                  return (
                    <InlineEditingFormikForm
                      dirty={formikProps.dirty}
                      enterUpSubmitHandler={(e: any) =>
                        handleUpdateItem({
                          rowId: e.target.name.split('.')[0],
                          values,
                          formikBag: formikProps,
                          skipCheck: true,
                        })
                      }
                      onClick={(e: any) =>
                        e.target?.name &&
                        e.target?.nodeName === 'INPUT' &&
                        handleUpdateItem({
                          rowId: e.target.name.split('.')[0],
                          values,
                          formikBag: formikProps,
                          rowToFocus: e.target.name.split('.')[0],
                        })
                      }
                      tabSubmitHandler={(e) => {
                        const [rowId] = e.target.name.split('.');

                        handleUpdateItem({
                          rowId,
                          values,
                          formikBag: formikProps,
                        });
                      }}
                      clickAwaySubmitHandler={(e) => {
                        handleUpdateItem({
                          rowId: e.target.name?.split('.')[0] ?? activeRow,
                          values,
                          formikBag: formikProps,
                          skipCheck: true,
                        });
                      }}
                      onChange={(e: any) => {
                        if (!e && !e.target) {
                          return;
                        }
                        const [rowId, fieldName] = e.target.name.split('.');

                        if (activeRow !== rowId) {
                          setActiveRow(rowId);
                        }

                        const currentValues: any = values[rowId];
                        const initialItem = find(flatItems, ['id', rowId]);

                        if (!initialItem) {
                          return;
                        }

                        switch (fieldName) {
                          case 'finishedVolume':
                            // case 4 openVolume will be calculated automatically
                            // if finisfed volume is less than plan volume
                            if (
                              !currentValues.isFinished &&
                              e.target.value < initialItem.planVolume
                            ) {
                              const openVolume = roundToTwoDecimalPlaces(
                                initialItem.planVolume - e.target.value,
                              );

                              setFieldValue(`${rowId}.openVolume`, openVolume, true);
                            } else {
                              // case 5 - 6 openVolume will be provided by user
                              setFieldValue(`${rowId}.openVolume`, initialItem.openVolume, true);
                            }

                            break;
                          case 'isFinished':
                            //  case 1 - 3 openVolume is zero if item is finished
                            if (e.target.checked) {
                              setFieldValue(`${rowId}.openVolume`, 0, true);
                            } else {
                              if (currentValues.finishedVolume < initialItem.planVolume) {
                                const openVolume = roundToTwoDecimalPlaces(
                                  initialItem.planVolume - currentValues.finishedVolume,
                                );
                                setFieldValue(`${rowId}.openVolume`, openVolume, true);
                              }
                            }
                            break;
                          default:
                            break;
                        }
                      }}
                    >
                      <DataTableBody context={context} />
                    </InlineEditingFormikForm>
                  );
                }}
              </Formik>
            </>
          );
        }}
      </DataTableHotKeysWrapper>
      {editItemRow && (
        <>
          <ItemDetails
            open={!!editItemRow}
            itemId={editItemRow.data.item.id}
            onClose={() => setEditItemRow(null)}
          />
        </>
      )}
    </>
  );
};

export default MissionSelector;
