import { Box, makeStyles, Typography } from '@material-ui/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { FC } from 'react';
import { FullAddressBookItem } from '../types/FullAddressBookItem';

interface IProps {
  addressBookItem: Maybe<FullAddressBookItem>;
}

const useStyles = makeStyles({
  text: {
    fontSize: '1.1rem',
  },
});

export const AddressPreview: FC<IProps> = ({ addressBookItem }) => {
  const classes = useStyles();

  const contactPerson = addressBookItem?.contactPerson;
  const addressBookItemAddress = addressBookItem?.address;
  const contactAddress = contactPerson?.address;

  return (
    <Box>
      {addressBookItemAddress?.additionalOne && (
        <Typography variant="body1" classes={{ root: classes.text }}>
          {addressBookItemAddress.additionalOne}
        </Typography>
      )}
      {(addressBookItemAddress?.firstName || addressBookItemAddress?.name) && (
        <Typography variant="body1" classes={{ root: classes.text }}>
          {addressBookItemAddress.firstName ? addressBookItemAddress.firstName + ' ' : ''}
          {addressBookItemAddress.name}
        </Typography>
      )}
      {addressBookItemAddress?.department && (
        <Typography variant="body1" classes={{ root: classes.text }}>
          {addressBookItemAddress.department}
        </Typography>
      )}
      {addressBookItemAddress?.additionalTwo && (
        <Typography variant="body1" classes={{ root: classes.text }}>
          {addressBookItemAddress.additionalTwo}
        </Typography>
      )}
      {contactPerson && contactAddress && (contactAddress.firstName || contactPerson.name) && (
        <Box>
          {contactAddress.firstName && (
            <>
              <Typography variant="body1" display="inline" classes={{ root: classes.text }}>
                {contactAddress.firstName}
              </Typography>{' '}
            </>
          )}
          {contactPerson.name && (
            <Typography variant="body1" display="inline" classes={{ root: classes.text }}>
              {contactPerson.name}
            </Typography>
          )}
        </Box>
      )}
      {addressBookItemAddress?.streetAndNumber && (
        <Typography variant="body1" classes={{ root: classes.text }}>
          {addressBookItemAddress.streetAndNumber}
        </Typography>
      )}
      {addressBookItemAddress?.postbox && (
        <Typography variant="body1" classes={{ root: classes.text }}>
          {addressBookItemAddress.postbox}
        </Typography>
      )}
      {(addressBookItemAddress?.postCode || addressBookItemAddress?.city) && (
        <Box>
          {addressBookItemAddress.postCode && (
            <>
              <Typography variant="body1" display="inline" classes={{ root: classes.text }}>
                {addressBookItemAddress.postCode}
              </Typography>{' '}
            </>
          )}
          {addressBookItemAddress.city && (
            <Typography variant="body1" display="inline" classes={{ root: classes.text }}>
              {addressBookItemAddress.city}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
