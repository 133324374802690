import gql from 'graphql-tag';

export const GET_ACCOUNTING_LOGS = gql`
  query GetAccountingLogs {
    reports {
      id
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
      reportDate
      employees {
        id
        firstName
        lastName
      }
      teamLeader {
        id
        firstName
        lastName
      }
    }
  }
`;
