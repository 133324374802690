import gql from 'graphql-tag';
import { FULL_ADDRESS_BOOK, FULL_ADDRESS_BOOK_ITEM } from '../query';

const ADDRESS_SEARCH_FRAGMENT = gql`
  fragment AddressSearchItem on Address {
    id
    shortName
    firstName
    name
    city
    streetAndNumber
    postCode
    phone
    phoneTwo
    email
    department
  }
`;

export const ADDRESS_SEARCH_QUERY = gql`
  query AddressSearch($first: Int!, $after: Int, $search: AddressSearchInput) {
    abacusAddresses(first: $first, after: $after, search: $search) {
      ...AddressSearchItem
    }
  }
  ${ADDRESS_SEARCH_FRAGMENT}
`;

export const USE_ADDRESS_MUTATION = gql`
  mutation UseAddress(
    $projectNumber: String!
    $type: UpsertAddressBookItemType!
    $abacusAddressId: Int!
    $where: AddressBookItemWhereUniqueInput
  ) {
    upsertAddressBookItem(
      where: $where
      data: { abacusAddress: { id: $abacusAddressId } }
      projectNumber: $projectNumber
      type: $type
    ) {
      ...FullAddressBookItem
      addressBook {
        ...FullAddressBook
      }
    }
  }
  ${FULL_ADDRESS_BOOK_ITEM}
  ${FULL_ADDRESS_BOOK}
`;

export const DISCONNECT_ADDRESS_MUTATION = gql`
  mutation DisconnectAddress($addressBookItemId: Int!, $data: AddressBookItemUpdateInput!) {
    updateAddressBookItem(where: { id: $addressBookItemId }, data: $data) {
      ...FullAddressBookItem
    }
  }
  ${FULL_ADDRESS_BOOK_ITEM}
`;
