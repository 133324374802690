import gql from 'graphql-tag';

const itemBoilerplateFragment = gql`
  fragment mobileItemBoilerPlate on ItemBoilerplate {
    id
    acronym
    material
    productNumber
    descriptionOne
    unit
  }
`;

export const GET_MOBILE_ITEMBOILERPLATES = gql`
  query GetMobileItemboilerplates($catalogId: ID!, $search: SearchInput) {
    catalog(where: { id: $catalogId }) {
      id
      itemBoilerplates(page: { amountPerPage: 15, pageNumber: 0 }, search: $search) {
        ...mobileItemBoilerPlate
        predefinedVolume
        predefinedPricePerUnit
      }
    }
  }
  ${itemBoilerplateFragment}
`;

export const GET_PROJECT_CATALOG = gql`
  query ProjectStandardCatalog($projectNumber: String!) {
    project(where: { projectNumber: $projectNumber }) {
      id
      catalog {
        id
      }
    }
  }
`;

export const MOBILE_ITEM_BOILERPLATE_GROUPS = gql`
  query MobileItemBoilerPlateGroups($search: SearchInput) {
    itemBoilerplateGroups(search: $search) {
      id
      name
      itemBoilerplates {
        ...mobileItemBoilerPlate
        catalog {
          id
          useName
        }
      }
    }
  }
  ${itemBoilerplateFragment}
`;
