import gql from 'graphql-tag';

export const columnsSettingfragment = gql`
  fragment ColumnsSettingFields on ColumnsSetting {
    id
    name
    tableSettings {
      id
      tableName
      shownColumnIds
    }
  }
`;

export const UPDATE_COLUMNS_SETTING_MUTATION = gql`
  mutation UpdateColumnsSetting(
    $where: ColumnsSettingWhereUniqueInput!
    $data: ColumnsSettingUpdateInput!
  ) {
    updateColumnsSetting(where: $where, data: $data) {
      ...ColumnsSettingFields
    }
  }
  ${columnsSettingfragment}
`;

export const GET_CURRENT_COLUMNS_SETTING = gql`
  query CurentColumnsSetting {
    currentColumnsSetting @client {
      id
      name
      tableSettings {
        id
        tableName
        shownColumnIds
      }
    }
  }
`;

export const GET_TABLE_SETTING = gql`
  query TableSetting($where: TableSettingWhereUniqueInput!) {
    tableSetting(where: $where) @client {
      id
      shownColumnIds
    }
  }
`;

export const SET_CURRENT_COLUMNS_SETTING = gql`
  mutation SetCoulmnsSetting($columnsSetting: ClientColumnsSettingInput!) {
    setCurrentColumnsSetting(columnsSetting: $columnsSetting) @client {
      id
    }
  }
`;

export const GET_COLUMNS_SETTINGS = gql`
  query UserColumnsSettings($userName: String!) {
    columnsSettings(where: { user: { name: $userName } }) {
      ...ColumnsSettingFields
    }
  }
  ${columnsSettingfragment}
`;

export const GET_COLUMNS_SETTING = gql`
  query GetColumnsSetting($id: ID!) {
    columnsSetting(where: { id: $id }) {
      ...ColumnsSettingFields
    }
  }
  ${columnsSettingfragment}
`;
