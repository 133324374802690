import { Button, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-apollo';
import CustomizedSnackbar, { Variant } from '../../components/CustomizedSnackbar';
import FormikCheckboxWithLabel from '../../components/Form/FormikCheckboxWithLabel';
import FormikSelect from '../../components/Form/FormikSelect';
import FormikTextField from '../../components/Form/FormikTextField';
import AppProgress from '../../components/Page/AppProgress';
import { PStatus } from '../../types/graphql';
import createCurrencyFormatter from '../../utils/createCurrencyFormatter';
import { downloadCsv, generateCsv } from '../../utils/csv';
import { SELECTED_SUBSIDIARY_QUERY } from '../Missions/queries';
import { Subsidiary } from '../Missions/types/Subsidiary';
import { Months } from './months';
import { GET_BOUNDARIES_QUERY } from './query';
import {
  GetBoundaries,
  GetBoundaries_boundaries,
  GetBoundariesVariables,
} from './types/GetBoundaries';
import { getToken } from '../../utils/getToken';
import { useAusmassDigitalLink } from '../../utils/useAusmassDigitalLink';

const formatCurrency = createCurrencyFormatter({ format: '0.00' });
const currentMonth = new Date().getMonth() + 1;
const defaultMonth = currentMonth - 1 === 0 ? 12 : currentMonth - 1;
const defaultYear =
  currentMonth - 1 === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear();

type DateObj = Record<'year' | 'month', number>;

const handleCsvDownload = (boundaries: GetBoundaries_boundaries[], date: DateObj) => {
  const items = boundaries
    .map((boundary) => {
      return [
        {
          Name: boundary.name,
          'Abgrenzungen Filiale': formatCurrency(boundary.subsidiaryBoundaries),
        },
        ...boundary.boundaryProjects.map((project) => ({
          Name: project.name,
          Projektnummer: project.projectNumber,
          Filiale: project.subsidiaryName,
          Status: project.status,
          'Umsatz Gesamt': formatCurrency(project.finishedPriceTotal),
          'Verrechnet Gesamt': formatCurrency(project.invoicedPriceTotal),
          'Umsatz Monat': formatCurrency(project.finishedPriceMonth),
          'Verrechnet Monat': formatCurrency(project.invoicedPriceMonth),
          'Abgrenzungen per Ende Monat': formatCurrency(project.boundaries),
          'Abgrenzungen per Ende Vormonat': formatCurrency(project.boundariesPreviousMonth),
        })),
      ];
    })
    .flat(1);

  const csv = generateCsv(items, [
    'Name',
    'Projektnummer',
    'Status',
    'Filiale',
    'Abgrenzungen Filiale',
    'Umsatz Gesamt',
    'Verrechnet Gesamt',
    'Umsatz Monat',
    'Verrechnet Monat',
    'Abgrenzungen per Ende Monat',
    'Abgrenzungen per Ende Vormonat',
  ]);

  downloadCsv(csv, `Abgrenzungen_Projekte_${date.month}_${date.year}`);
};

const useStyles = makeStyles((theme) => ({
  checkBox: {
    marginTop: theme.spacing(2),
  },
}));

export const ProjectBoundaries: React.FC = () => {
  const [err, setErr] = useState('');

  const {
    data: subsidiaryData,
    error: subsidiaryError,
    loading: subsidiaryLoading,
  } = useQuery<Subsidiary>(SELECTED_SUBSIDIARY_QUERY);

  const { refetch: getBoundaries } = useQuery<GetBoundaries, GetBoundariesVariables>(
    GET_BOUNDARIES_QUERY,
    {
      fetchPolicy: 'network-only',
      skip: true,
    },
  );

  const ausmassDigitalLink = useAusmassDigitalLink();

  const onSubmit = useCallback(
    async ({ month, year, includeArchived }: any, { setSubmitting }: FormikHelpers<any>) => {
      if (!subsidiaryData) {
        return;
      }

      const { data } = await getBoundaries({
        month,
        year,
        ...(subsidiaryData.selectedSubsidiary !== 'all' && {
          subsidiary: subsidiaryData.selectedSubsidiary,
        }),
        excludeStatus: includeArchived ? [] : [PStatus.P6],
      });

      if (data.boundaries.every((boundary) => boundary.boundaryProjects.length === 0)) {
        setErr('Keine Abgrenzung erstellen möglich! Es wurde schon alles verrechnet.');

        return;
      }

      handleCsvDownload(data.boundaries, {
        year,
        month,
      });
      setSubmitting(false);
    },
    [subsidiaryData, getBoundaries, setErr],
  );

  const classes = useStyles();

  if (subsidiaryError) {
    console.log(subsidiaryError);
    return null;
  }

  if (subsidiaryLoading) {
    return null;
  }

  if (!subsidiaryData) {
    return null;
  }

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={8}>
          <Formik
            initialValues={{ month: defaultMonth, year: defaultYear, includeArchived: false }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <>
                {isSubmitting && <AppProgress />}
                <Form>
                  <Field name="month" label="Monat" component={FormikSelect}>
                    {Object.entries(Months).map(([monthName, monthNum]) => (
                      <MenuItem key={monthName} value={monthNum}>
                        {monthName}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field name="year" type="number" label="Jahr" component={FormikTextField} />
                  <div>
                    <Field
                      name="includeArchived"
                      Label={{ label: 'Archivierte projekte berücksichtigen' }}
                      component={FormikCheckboxWithLabel}
                      propClasses={{ root: classes.checkBox }}
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    style={{ marginTop: '2%' }}
                  >
                    Abgrenzung erstellen
                  </Button>
                </Form>
              </>
            )}
          </Formik>
          <hr />
          {ausmassDigitalLink && (
            <>
              <Typography variant="h4">Abgrenzung Neu</Typography>
              <iframe
                title="Abgrenzung Neu"
                src={`${ausmassDigitalLink}/abgrenzung?layout=no-layout&momoAuth=${getToken()}`}
                style={{ width: '600px', height: '240px', border: 'none' }}
              />
            </>
          )}
        </Grid>
      </Grid>
      <CustomizedSnackbar
        hasCloseButton={true}
        message={err}
        open={!!err}
        onClose={() => setErr('')}
        variant={Variant.error}
      />
    </>
  );
};
