import React from 'react';
import { DialogActions, Button, Typography } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import FormikTextField from '../../Form/FormikTextField';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  priceWrapper: {
    width: '100px',
  },
}));

interface IProps {
  oldProductNumber: string;
  newProductNumber: string;
  newProductBasePrice: number;
  onClose?: (...args: any[]) => void;
  onReplace: (isReplaceAllItems: boolean, price: number) => void;
}

const validationSchema = Yup.object().shape({
  price: Yup.number().required('Pflichtfeld').typeError('Preis muss eine Zahl sein!'),
});

const ConfirmationStep: React.FunctionComponent<IProps> = ({
  oldProductNumber,
  newProductNumber,
  newProductBasePrice,
  onClose,
  onReplace,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body2">
        Sollen alle Positionen mit Artikelnummer <strong>{oldProductNumber}</strong> durch{' '}
        <strong>{newProductNumber}</strong> ersetzt werden?
      </Typography>
      <Formik
        initialValues={{ price: (newProductBasePrice / 100).toFixed(2), replaceAll: false }}
        onSubmit={(values) =>
          onReplace(values.replaceAll, parseInt((parseFloat(values.price) * 100).toFixed(2), 10))
        }
        validationSchema={validationSchema}
      >
        {({ submitForm, setFieldValue }) => (
          <Form>
            <div className={classes.priceWrapper}>
              <Field name="price" label="Preis" component={FormikTextField} type="number" shrink />
            </div>

            <DialogActions>
              <Button onClick={onClose} color="primary">
                Abbrechen
              </Button>
              <Button type="submit" color="primary">
                Nein, nur diese Position
              </Button>
              <Button
                onClick={() => {
                  setFieldValue('replaceAll', true);
                  return submitForm();
                }}
                color="primary"
              >
                Alle Positionen
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConfirmationStep;
