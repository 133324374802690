import React from 'react';
import CreateLocationForm from '../../components/Location/CreateLocationForm';
import {
  CreateLocationVariables,
  CreateLocation,
} from '../../../components/BillOfQuantity/types/CreateLocation';
import { useMutation } from 'react-apollo';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { GET_LOCATIONS } from '../BillOfQuantityOverview/BillOfQuantityOverview.queries';
import { CREATE_LOCATION_MUTATION } from '../../../components/BillOfQuantity/BllOfQuantityTable/BillOfQuantityTable.queries';

const CreateLocationPage: React.FC<any> = () => {
  const params = useParams();
  const history = useHistory();
  const { state } = useLocation();

  const [createLocation] = useMutation<CreateLocation, CreateLocationVariables>(
    CREATE_LOCATION_MUTATION,
    {
      refetchQueries: [{ query: GET_LOCATIONS, variables: { id: state.boqId } }],
    },
  );

  return (
    <CreateLocationForm
      onSubmit={async (values) =>
        createLocation({
          variables: { parentLocationId: params.locationId, name: values.name },
        })
      }
      onClose={() => history.goBack()}
    />
  );
};

export default CreateLocationPage;
