import gql from 'graphql-tag';

export const GET_ACCOUNTING_SUBSIDIARIES = gql`
  query GetAccountingSubsidiaries($search: SearchInput, $page: PageInput) {
    subsidiaries(search: $search, page: $page) {
      id
      name
    }
  }
`;

export const GET_ACCOUNTING_EMPLOYEES = gql`
  query GetAccountingEmployees($where: EmployeeWhereInput, $search: SearchInput, $page: PageInput) {
    employees(where: $where, search: $search, page: $page) {
      id
      employeeNumber
      firstName
      lastName
      workload
      subsidiary {
        id
        name
      }
      function {
        id
        hourlyWage
        name
        acronym
      }
    }
  }
`;

export const GET_ACCOUNTING_MISSIONS = gql`
  query GetAccountingMissions($where: MissionWhereInput, $search: SearchInput, $page: PageInput) {
    missions(
      where: $where
      search: $search
      page: $page
      orderBy: { fieldName: "id_DESC", isComputed: false }
    ) {
      id
      name

      project {
        id
        projectNumber
        projectName
        subsidiary {
          id
          name
        }
      }
    }
  }
`;

export const GET_ACCOUNTING_MATERIALS = gql`
  query GetAccountingMaterials($search: SearchInput, $page: PageInput) {
    activeMaterialCatalog {
      id
      materials(search: $search, page: $page) {
        id
        materialNumber
        materialType
        acronym
        nameOne
        unit {
          id
          acronym
          name
        }
        pricePerUnit
      }
    }
  }
`;

export const GET_ACCOUNTING_VEHICLES = gql`
  query GetAccountingVehicles($where: VehicleWhereInput, $search: SearchInput, $page: PageInput) {
    vehicles(where: $where, search: $search, page: $page) {
      id
      nameOne
      nameTwo
      inventoryNumber
      internalHourlyWage
      category
      subsidiary {
        id
        name
      }
    }
  }
`;

export const GET_ACCOUNTING_MACHINES = gql`
  query GetAccountingMachines($where: MachineWhereInput, $search: SearchInput, $page: PageInput) {
    machines(where: $where, search: $search, page: $page) {
      id
      nameOne
      nameTwo
      inventoryNumber
      internalHourlyWage
      category
      subsidiary {
        id
        name
      }
    }
  }
`;

export const GET_ACCOUNTING_COLLECTIVE_ACCOUNTS = gql`
  query GetAccountingCollectiveAccounts {
    collectiveAccounts {
      id
      createdAt
      updatedAt
      nameOne
      nameTwo
      projectNumber
      subsidiary {
        id
        name
      }
    }
  }
`;

export const GET_ACCOUNTING_ACTIVITY_TYPES = gql`
  query GetAccountingActivityTypes {
    activityTypes {
      id
      createdAt
      updatedAt
      nameOne
      nameTwo
      number
      unit {
        id
        acronym
        name
      }
      category
      pricePerUnit
    }
  }
`;

const AccountingItemFieldsFragment = gql`
  fragment accountingItemFields on AccountingItem {
    id
    chargeConstraint
    activityDate
    startTime
    endTime
    amount
    comment
    creditedTo {
      id
      costPerUnit
      subsidiary {
        id
      }
      workload
      type
      employee {
        id
        employeeNumber
        firstName
        lastName
      }
      material {
        id
        materialNumber
        nameOne
        acronym
      }
      machine {
        id
        inventoryNumber
        nameOne
      }
      vehicle {
        id
        inventoryNumber
        nameOne
      }
      collectiveAccount {
        id
        nameOne
      }
      mission {
        id
        name
        project {
          id
          projectNumber
          projectName
        }
      }
    }
    chargedTo {
      id
      costPerUnit
      subsidiary {
        id
      }
      workload
      type
      employee {
        id
        employeeNumber
        firstName
        lastName
      }
      material {
        id
        materialNumber
        nameOne
        acronym
      }
      machine {
        id
        inventoryNumber
        nameOne
      }
      vehicle {
        id
        inventoryNumber
        nameOne
      }
      collectiveAccount {
        id
        nameOne
      }
      mission {
        id
        name
        project {
          id
          projectNumber
          projectName
        }
      }
    }
  }
`;

const PreviewAccountingItemFieldsFragment = gql`
  fragment previewAccountingItemFields on PreviewAccountingItem {
    id
    chargeConstraint
    activityDate
    startTime
    endTime
    amount
    comment
    creditedTo {
      id
      costPerUnit
      subsidiary {
        id
      }
      workload
      type
      employee {
        id
      }
      material {
        id
      }
      machine {
        id
      }
      vehicle {
        id
      }
      collectiveAccount {
        id
        nameOne
      }
      mission {
        id
      }
    }
    chargedTo {
      id
      costPerUnit
      subsidiary {
        id
      }
      workload
      type
      employee {
        id
      }
      material {
        id
      }
      machine {
        id
      }
      vehicle {
        id
      }
      collectiveAccount {
        id
        nameOne
      }
      mission {
        id
      }
    }
  }
`;

export const PREVIEW_ACCOUNTING_LOG_ITEMS = gql`
  query PreviewAccountingLogItems(
    $items: [AccountingItemInput!]!
    $teamLeaderId: ID!
    $existingReportId: ID
  ) {
    calculatePreviewItems(
      items: $items
      teamLeaderId: $teamLeaderId
      existingReportId: $existingReportId
    ) {
      setupItems {
        ...previewAccountingItemFields
      }
      drivingItems {
        ...previewAccountingItemFields
      }
      employeeBonusesOverviews {
        id
        plannedWorkTime
        totalWorkedTime
        accountingItems {
          ...previewAccountingItemFields
        }
      }
      items {
        ...previewAccountingItemFields
      }
      externalItems {
        ...previewAccountingItemFields
      }
      didChange
    }
  }
  ${PreviewAccountingItemFieldsFragment}
`;

export const GET_ACCOUNTING_LOG = gql`
  query GetAccountingLog($where: AccountingReportWhereUniqueInput!, $day: Date!) {
    report(where: $where) {
      id
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
      reportDate
      teamLeader {
        id
        firstName
        lastName
        employeeNumber
        function {
          id
          acronym
          plannedWorkTime(day: $day)
        }
      }
      employees {
        id
        firstName
        lastName
        employeeNumber
        function {
          id
          acronym
          plannedWorkTime(day: $day)
        }
      }
      accountingItems {
        ...accountingItemFields
      }
    }
  }
  ${AccountingItemFieldsFragment}
`;

export const GET_ACCOUNTING_LOG_DATE = gql`
  query AccountingReportDate($where: AccountingReportWhereUniqueInput!) {
    report(where: $where) {
      id
      reportDate
    }
  }
`;

export const UPSERT_ACCOUNTING_LOG = gql`
  mutation UpsertAccountingLog(
    $where: AccountingReportWhereUniqueInput
    $data: UpsertAccountingReportInput!
    $teamLeaderId: ID!
  ) {
    upsertReport(where: $where, data: $data, teamLeaderId: $teamLeaderId) {
      id
    }
  }
`;

export const REFRESH_ACCOUNTING_LOG = gql`
  mutation RefreshAccountingLog($where: AccountingReportWhereUniqueInput!) {
    refreshAccountingReport(where: $where) {
      id
    }
  }
`;
