import React from 'react';
import { IItemWithAmount } from './TabMissions';
import { Button, styled, Theme } from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import Dinero from 'dinero.js';
import {
  createSwissCurrencyFormatter,
  roundToFiveRappen,
} from '../../../utils/createCurrencyFormatter';
import { SPLITSCREEN_ITEM_PADDING } from '../../../components/SplitScreen';
import { ProjectMissions_project_missions } from './MissionSelector/types/ProjectMissions';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const CostSumInfo = styled('div')(({ theme }: { theme: Theme }) => ({
  height: 85,
  padding: theme.spacing(2, 4),
  marginTop: theme.spacing(2),
  marginLeft: -SPLITSCREEN_ITEM_PADDING,
  marginRight: -SPLITSCREEN_ITEM_PADDING,
  backgroundColor: blue[100],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  bottom: 0,
}));

const PlannedCost = styled('div')({
  fontWeight: 500,
});

const ChosenMission = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const ChosenMissionHeadline = styled('span')({
  fontSize: '0.8rem',
  color: grey[600],
});

interface IProps {
  items: IItemWithAmount[];
  mission?: ProjectMissions_project_missions;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  selectedContainerIds?: string[];
}

const SelectedItemsInfo: React.FC<IProps> = ({ items, mission, selectedContainerIds }) => {
  items = items.filter((i) => i.amount > 0);
  const sum = items.reduce(
    (sum, item) =>
      sum.add(roundToFiveRappen(Dinero({ amount: item.netPrice }).multiply(item.amount))),
    Dinero({ amount: 0 }),
  );
  const roundedSum = roundToFiveRappen(sum);

  const isElementsSelected =
    items.length > 0 || (selectedContainerIds && selectedContainerIds.length > 0);

  return (
    <CostSumInfo>
      <PlannedCost>Geplante Menge in Summe: {formatCurrency(roundedSum.getAmount())}</PlannedCost>
      <ChosenMission>
        <ChosenMissionHeadline>Ausgewählter Einsatz</ChosenMissionHeadline>{' '}
        <span>{mission && mission.name}</span>
      </ChosenMission>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        disabled={!mission || mission.isMissionBlocked || !isElementsSelected}
      >
        Geplante Positionen zum Einsatz hinzufügen
      </Button>
    </CostSumInfo>
  );
};

export default SelectedItemsInfo;
