import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import ScaleDiscountFormDialog from './ScaleDiscountFormDialog';
import { RouteComponentProps } from 'react-router-dom';
import { scaleDiscountFields, SCALE_DISCOUNTS_QUERY } from './ScaleDiscounts';
import { GET_ITEM_BOILERPLATES } from '../../pages/ItemBoilerplates';

const createScaleDiscountMutation = gql`
  mutation CreateScaleDiscount($itemBoilerplateId: ID!, $data: ScaleDiscountUpdateInput!) {
    createScaleDiscount(itemBoilerplateId: $itemBoilerplateId, data: $data) {
      ...scaleDiscountFields
    }
  }
  ${scaleDiscountFields}
`;

const CreateScaleDiscount: React.FunctionComponent<RouteComponentProps> = ({ history, match }) => {
  const { id } = match.params as any;

  return (
    <Mutation<any, any>
      mutation={createScaleDiscountMutation}
      refetchQueries={[{ query: GET_ITEM_BOILERPLATES }]}
      update={(cache, { data: { createScaleDiscount } }) => {
        const { scaleDiscounts } = cache.readQuery({
          query: SCALE_DISCOUNTS_QUERY,
          variables: { itemBoilerplateId: id },
        }) as any;
        cache.writeQuery({
          query: SCALE_DISCOUNTS_QUERY,
          variables: { itemBoilerplateId: id },
          data: { scaleDiscounts: scaleDiscounts.concat([createScaleDiscount]) },
        });
      }}
    >
      {(createScaleDiscount) => (
        <ScaleDiscountFormDialog
          onSubmit={(data: any) =>
            createScaleDiscount({ variables: { itemBoilerplateId: id, data } })
          }
          onClose={() => history.goBack()}
        />
      )}
    </Mutation>
  );
};

export default CreateScaleDiscount;
