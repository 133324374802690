import ApolloClient from 'apollo-client';
import { GET_DATA_TABLE } from '../../services/graphql-client';
import { GetDataTable, GetDataTableVariables } from '../../services/types/GetDataTable';
import { TableType } from '../../types/graphql';

/**
 * forces the dataTable for the given tableType to refetch completely
 *
 * is achieved by emptying the rows
 */
export const forceDataTableRefetch = (tableType: TableType, client: ApolloClient<any>): void => {
  return client.cache.writeQuery<GetDataTable, GetDataTableVariables>({
    query: GET_DATA_TABLE,
    variables: { id: tableType },
    data: {
      dataTable: {
        __typename: 'DataTable',
        id: tableType,
        rows: [],
      },
    },
  });
};
