import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { Paper, Grid, TextField, ClickAwayListener } from '@material-ui/core';
import { last, isEmpty } from 'lodash';
import classnames from 'classnames';
import { useTriggerSearch } from './SearchWithDropdown.hooks';
import { makeStyles } from '@material-ui/styles';

export interface ISearchState {
  setSearchTerm: (term: string) => void;
  searchTerm: string;
}

export const useSearchState = () => {
  const [searchTerm, setSearchTerm] = useState('');

  return { searchTerm, setSearchTerm };
};

interface IChildrenCbParams {
  setFieldSearchTerm: (value: string) => void;
  fieldSearchTerm: string;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  triggerSearch: () => void;
}

export interface ISearchSubmitProps {
  columns: string[];
  terms: string[];
}

export interface ISearchProps {
  onSubmit: (props: ISearchSubmitProps) => Promise<any>;
  columns: string[];
  /**
   * highlighting search content is done by using the column names from the columns property;
   * if there are properties in columns that are accessed from another object (e.g. 'item.productNumber')
   * you need to put just the name of the property (e.g. 'productNumber') into this array so highlighting
   * works correctly
   */
  columnsToHighlight?: string[];
  searchState: ISearchState;
  children?: (params: IChildrenCbParams) => JSX.Element;
  loading?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}

const FETCH_DELAY = 550;

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },

      '&:hover fieldset': {
        borderColor: '#979797',
      },
    },
  },
});

export const Search: React.FC<ISearchProps> = ({
  onSubmit,
  searchState: { searchTerm, setSearchTerm },
  columns,
  children,
  loading,
  autoFocus,
  disabled,
}) => {
  const classes = useStyles();

  const [fieldSearchTerm, setFieldSearchTerm] = useState(searchTerm);

  const [searchTermQueue, setSearchTermQueue] = useState<string[][]>([]);

  const [didClickOutside, setDidClickOutside] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const enteredSearchValues = useMemo(() => fieldSearchTerm.split(' '), [fieldSearchTerm]);

  const queueFn = useCallback(async () => {
    setSearchTermQueue((v) => [...v, enteredSearchValues]);

    setSearchTerm(fieldSearchTerm);
  }, [setSearchTermQueue, fieldSearchTerm, setSearchTerm, enteredSearchValues]);

  const triggerSearch = useTriggerSearch({
    delay: FETCH_DELAY,
    fetchFn: queueFn,
  });

  useEffect(() => {
    if (loading || isEmpty(searchTermQueue)) {
      return;
    }

    const queueItem = last(searchTermQueue);

    if (!queueItem) {
      return;
    }

    setSearchTermQueue((v) => v.slice(0, -2)); // remove all but last element from queue

    onSubmit({ columns, terms: queueItem });
  }, [searchTermQueue, onSubmit, columns, loading]);

  // input somehow looses focus sometimes -> force correct focusing and bluring
  useEffect(() => {
    if (didClickOutside) {
      inputRef.current?.blur();
    } else {
      // timeout is needed for FF; preventScroll is needed for Chromium
      setTimeout(() => inputRef.current?.focus({ preventScroll: true }), 5);
    }
  }, [didClickOutside, loading]); // loading in dep-array is necessary

  return children ? (
    children({ setFieldSearchTerm, fieldSearchTerm, inputRef, triggerSearch })
  ) : (
    <Grid item xs={12}>
      <Paper elevation={1}>
        <ClickAwayListener
          onClickAway={() => {
            setDidClickOutside(true);
          }}
        >
          <span>
            <TextField
              classes={{
                root: classnames(classes.root),
              }}
              autoComplete="off"
              autoFocus={autoFocus}
              fullWidth
              name="term"
              placeholder="Suche"
              variant="outlined"
              onChange={(e) => setFieldSearchTerm(e.target.value)}
              onKeyUp={() => triggerSearch()}
              onClick={() => {
                setDidClickOutside(false);
              }}
              inputRef={inputRef}
              disabled={disabled}
            />
          </span>
        </ClickAwayListener>
      </Paper>
    </Grid>
  );
};
