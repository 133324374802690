import gql from 'graphql-tag';

export const UPSERT_ACCOUNTING_LOG_ITEM = gql`
  mutation UpsertAccountingLogItem(
    $where: AccountingItemWhereUniqueInput
    $data: UpsertAccountingItem!
  ) {
    upsertAccountingItem(where: $where, data: $data) {
      id
    }
  }
`;

export const DELETE_ACCOUNTING_LOG_ITEM = gql`
  mutation DeleteAccountingLogItem($where: AccountingItemWhereUniqueInput!) {
    deleteAccountingItem(where: $where) {
      id
    }
  }
`;
