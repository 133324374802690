import React from 'react';
import InnerTable from './InnerTable';
import { IChildrenRows, IDataTableRow } from './types';
import ContainerRow from './ContainerRow';
import { ITableContext } from '.';
import { fetchMAD } from '../../utils/fetchMAD';
import { isEmpty } from 'lodash';
import { Typography } from '@material-ui/core';

interface IProps {
  row: IChildrenRows | IDataTableRow<any, any, any>;
  context: ITableContext;
  level: number;
}

const Rows: React.FunctionComponent<IProps> = React.memo(({ row, context, level }) => (
  <>
    {row.innerTableRows && row.innerTableRows.length > 0 && (
      <>
        <InnerTable
          rows={row.innerTableRows}
          context={context}
          level={level}
          parentRow={'id' in row ? row : undefined}
        />
        {!isEmpty((row as any)?.data?.additionalPositions) && (
          <div style={{ position: 'relative', maxWidth: 800, paddingLeft: 10 }}>
            <Typography variant="h6">Zusätzliche Positionen</Typography>
            {(row as any).data.additionalPositions.map((additionalPosition: any) => (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  position: 'relative',
                }}
                key={additionalPosition.id}
              >
                <div
                  style={{
                    width: '20%',
                  }}
                >
                  {additionalPosition.name}
                </div>
                <div
                  style={{
                    width: '20%',
                  }}
                >
                  {additionalPosition.color}
                </div>
                <div
                  style={{
                    width: '20%',
                  }}
                >
                  {additionalPosition.material}
                </div>
                <div
                  style={{
                    width: '20%',
                    textAlign: 'right',
                  }}
                >
                  {additionalPosition.volume}
                </div>
                <div
                  style={{
                    width: '20%',
                    paddingLeft: 10,
                  }}
                >
                  {additionalPosition.unit}
                </div>
                <div
                  style={{ position: 'absolute', right: 0, cursor: 'pointer' }}
                  onClick={async () => {
                    await fetchMAD('/api/ausmass-digital/additionalPosition', {
                      method: 'DELETE',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        additionalPositionId: additionalPosition.id,
                        missionId: additionalPosition.mission,
                      }),
                    });
                    (row as any).data.refetchAdditionalPositions(additionalPosition.mission);
                  }}
                >
                  <svg
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    style={{ fill: 'red' }}
                  >
                    <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                  </svg>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    )}

    {(row.containerRows || []).map((containerRow) => (
      <ContainerRow key={containerRow.id} row={containerRow} context={context}>
        <Rows row={containerRow} context={context} level={level + 1} />
      </ContainerRow>
    ))}
  </>
));

/** Render rows: First container rows, after that inner table rows. */
export default Rows;
