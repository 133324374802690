import gql from 'graphql-tag';
import { get } from 'lodash';
import React from 'react';
import { Query } from 'react-apollo';
import DataTable from '../../components/DataTable';
import { IDataTableColumn } from '../../components/DataTable/types';
import AppProgress from '../../components/Page/AppProgress';
import { Search, useSearchState } from '../../components/Search/Search';
import { getSubsidiaries, getSubsidiariesVariables } from './types/getSubsidiaries';

const columns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'formattedAddress',
    label: 'Adresse',
  },
  {
    id: 'leader',
    label: 'Filialleiter',
  },
  {
    id: 'telephone',
    label: 'Telefon',
  },
];

const GET_SUBSIDIARIES_QUERY = gql`
  query getSubsidiaries($search: SearchInput) {
    subsidiaries(search: $search) {
      formattedAddress
      email
      id
      name
      subsidiaryLeader {
        id
        name
      }
      telephone
    }
  }
`;

const toTableData = (element: any) => ({
  id: element.id,
  data: element,
});

const Subsidiaries = () => {
  const searchState = useSearchState();

  return (
    <Query<getSubsidiaries, getSubsidiariesVariables> query={GET_SUBSIDIARIES_QUERY}>
      {({ data, loading, error, refetch }) => {
        if (error) {
          return null;
        }

        if (!data) {
          return null;
        }

        const subsidiaries =
          data.subsidiaries
            ?.map((s: any) => ({
              ...s,
              leader: get(s, 'subsidiaryLeader.name', ''),
            }))
            .map(toTableData) ?? [];

        return (
          <>
            {loading && <AppProgress />}
            <DataTable
              innerTableRows={subsidiaries}
              search={
                <Search
                  columns={['name', 'email', 'address', 'telephone']}
                  searchState={searchState}
                  onSubmit={(search) => refetch({ search })}
                  loading={loading}
                />
              }
              options={{
                tableName: 'SUBSIDIARIES',
                filterText: searchState.searchTerm,
                levels: [
                  {
                    columns,
                  },
                ],
              }}
            />
          </>
        );
      }}
    </Query>
  );
};

export default Subsidiaries;
