/**
 * sleep for an amount of milliseconds
 * @param milliseconds how long
 * @returns promise that resolves after the duration
 */
export const sleep = (milliseconds: number) => {
  if (milliseconds <= 0) {
    return Promise.resolve();
  }
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

/**
 * sleep until a given date
 * @param date the date to sleep until
 * @returns a promise that resolves at the given date
 */
export const sleepUntil = (date: Date) => {
  const duration = date.getTime() - new Date().getTime();
  if (duration <= 0) {
    return Promise.resolve();
  }
  return sleep(duration);
};
