import gql from 'graphql-tag';

export const BILL_FIXED_QUERY = gql`
  query BillFixed($id: ID!) {
    bill(where: { id: $id }) {
      id
      status
    }
  }
`;
