import React, { useState, useCallback } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import ItemBoilerplateSearchVolumeEdit from './ItemBoilerplateSearch/ItemBoilerplateSearchVolumeEdit';
import ItemBoilerplateGroupSearch from './ItemBoilerplateGroupSearch';
import ItemForm from '../ItemForm';
import DialogCloseable from '../../Dialog/DialogCloseable';
import { useItemDialogStyles } from '../ItemDetails';
import { locationCompositeIdFields } from '../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/queries';
import { CreateFreeItem, CreateFreeItemVariables } from './types/CreateFreeItem';
import { PAGINATION_HELPERS } from '../../../utils/paginationHelpers';
import { compositeLocationId } from '../../BillOfQuantity/BllOfQuantityTable/utils/compositeLocationId';
import { useApolloClient, useMutation } from 'react-apollo';
import AppProgress from '../../Page/AppProgress';
import gql from 'graphql-tag';
import { buildBoqTableRowId } from '../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/mapper';
import { executeForPopulatedBoqTableTypes } from '../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { useBoqEntityType } from '../../../hooks/useBoqEntityType';

export interface IAddItem {
  rowId: string;
  volume: number;
  appliedPrice: number;
}

interface IProjectProp {
  projectNumber: string;
  catalogId?: string;
}

interface IItemImporterProps extends DialogProps {
  onAddItems: (items: IAddItem[], selectedCatalogId?: string, isImportCatalog?: boolean) => void;
  project?: IProjectProp;
  locationId?: string;
}

const CREATE_FREE_ITEM_MUTATION = gql`
  mutation CreateFreeItem($data: UpdateItemInput!, $locationId: ID!) {
    createFreeItem(data: $data, locationId: $locationId) {
      id
      location {
        id
        ...locationCompositeIdFields
      }
    }
  }
  ${locationCompositeIdFields}
`;

const ItemImporter: React.FunctionComponent<IItemImporterProps> = ({
  onAddItems,
  project,
  locationId,
  onClose,
  ...dialogProps
}) => {
  const client = useApolloClient();
  const [activeTab, setActiveTab] = useState(0);
  const boqEntityType = useBoqEntityType();

  const classes = useItemDialogStyles();

  const handleTabChange = (event: any, newValue: any) => setActiveTab(newValue);

  const addFreeItemToCache = useCallback(
    async (data: CreateFreeItem) => {
      const location = data.createFreeItem.location;
      if (!project || !location) {
        return;
      }

      const isBillOfQuantity = !location.parentLocation;
      const compositeId = isBillOfQuantity
        ? location.id
        : compositeLocationId({
            locationId: location.id,
            parentLocationId: location.parentLocation!.id,
            billOfQuantityId: location.billOfQuantity.id,
          });

      executeForPopulatedBoqTableTypes(
        client,
        project.projectNumber,
        (tableType) => {
          const tableRowId = buildBoqTableRowId(tableType)(compositeId);

          PAGINATION_HELPERS[project.projectNumber][tableType]?.refetchItemsInContainer([
            { containerId: tableRowId },
          ]);
        },
        boqEntityType,
      );
    },
    [project, client, boqEntityType],
  );

  const [createFreeItem, { loading }] = useMutation<CreateFreeItem, CreateFreeItemVariables>(
    CREATE_FREE_ITEM_MUTATION,
  );

  return (
    <>
      {loading && <AppProgress />}
      <DialogCloseable
        {...dialogProps}
        fullWidth
        maxWidth={false}
        fullHeight
        title="Position hinzufügen"
        className={classes.dialog}
        onClose={onClose}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Einzel-Positions-Vorlagen" />
          <Tab label="Vorlagen-Gruppen" />
          <Tab label="Händisch Erstellen" />
        </Tabs>
        {activeTab === 0 && (
          <ItemBoilerplateSearchVolumeEdit
            onSubmit={onAddItems}
            initCatalogId={project && project.catalogId}
          />
        )}
        {activeTab === 1 && <ItemBoilerplateGroupSearch onSubmit={onAddItems} />}
        {activeTab === 2 && (
          <ItemForm
            onClose={onClose}
            onSubmit={async (data) => {
              const freeItemResult = await createFreeItem({
                variables: { data, locationId: locationId! },
              });
              if (!freeItemResult || !freeItemResult.data) {
                return;
              }

              await addFreeItemToCache(freeItemResult.data);
            }}
          />
        )}
      </DialogCloseable>
    </>
  );
};

export default ItemImporter;
