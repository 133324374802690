import React, { useMemo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import RootRef from '@material-ui/core/RootRef';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import Paper from '@material-ui/core/Paper';
import uniqueId from 'lodash/uniqueId';
import HeaderRow from './HeaderRow';
import Row from './Row';
import { IDataTableRow } from '../types';
import getMaxInnerTableLevel from '../data/maxInnerTableLevel';
import { ITableContext } from '..';
import { InnerTableFooter } from './InnerTableFooter';
import { last } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 1020,
    borderCollapse: 'separate',
  },
  noMinWidth: {
    minWidth: 'auto',
  },
  root: {
    width: '100%',
    borderRadius: 6,
    paddingTop: theme.spacing(1),
  },
  tableFixedWidth: {
    tableLayout: 'fixed',
  },
}));

interface IProps {
  rows: IDataTableRow[];
  context: ITableContext;
  level: number;
  parentRow: IDataTableRow | undefined;
}

const InnerTable: React.FC<IProps> = React.memo(({ rows, context, level, parentRow }) => {
  const { tableData, droppableId = uniqueId('droppable') } = context;
  const { fixedWidthColumns, noMinWidth } = context.tableData.options;
  const classes = useStyles();

  const maxInnerTableLevel = getMaxInnerTableLevel(rows);
  const tableRows = rows.map((row) => (
    <Row
      key={row.id}
      row={row}
      maxInnerTableLevel={maxInnerTableLevel}
      innerLevel={0}
      context={context}
      parentRow={parentRow}
    />
  ));

  const rowIds = useMemo(() => rows.map((row) => row.id), [rows]);

  const levels = tableData.options.levels;
  const levelOptions = levels[level - 1]; // decrement level by 1 because the first level is the defaultLocation
  const onLoadMore = levelOptions?.onLoadMore;

  return (
    <Paper className={classes.root} elevation={levels.length > 0 ? 2 : 0}>
      <div>
        <Table
          className={`${classes.table} ${fixedWidthColumns ? classes.tableFixedWidth : ''} ${
            noMinWidth ? classes.noMinWidth : ''
          }`}
          aria-labelledby="Tabelle"
        >
          <HeaderRow
            maxInnerTableLevel={maxInnerTableLevel}
            rowIds={rowIds}
            context={context}
            level={level}
          />
          {tableData.innerTableOptions.isDragAndDropEnabled ? (
            <Droppable droppableId={droppableId}>
              {(provided) => (
                <RootRef rootRef={provided.innerRef}>
                  <TableBody {...provided.droppableProps}>{tableRows}</TableBody>
                </RootRef>
              )}
            </Droppable>
          ) : (
            <TableBody>{tableRows}</TableBody>
          )}
        </Table>
      </div>
      {levels.length !== 1 && onLoadMore ? (
        <InnerTableFooter
          loadMoreButtonIsDisabled={
            parentRow ? levelOptions.isOnLoadMoreDisabled?.(parentRow) : false
          }
          lastRow={last(rows)}
          parentRowId={parentRow?.id}
          onLoadMore={onLoadMore}
        />
      ) : undefined}
      {tableData.pagination && levels.length === 1 && tableData.pagination}
    </Paper>
  );
});

export default InnerTable;
