import { GetAccountingEmployees_employees } from '../AccountingLogger/types/GetAccountingEmployees';
import { GetAccountingMissions_missions } from '../AccountingLogger/types/GetAccountingMissions';
import { GetAccountingMaterials_activeMaterialCatalog_materials } from '../AccountingLogger/types/GetAccountingMaterials';
import { GetAccountingVehicles_vehicles } from '../AccountingLogger/types/GetAccountingVehicles';
import { GetAccountingActivityTypes_activityTypes } from '../AccountingLogger/types/GetAccountingActivityTypes';
import { GetAccountingMachines_machines } from '../AccountingLogger/types/GetAccountingMachines';
import { GetAccountingCollectiveAccounts_collectiveAccounts } from '../AccountingLogger/types/GetAccountingCollectiveAccounts';

/**
 * constituent parts for searchable strings
 */
type Employee = Pick<GetAccountingEmployees_employees, 'employeeNumber' | 'firstName' | 'lastName'>;

/**
 * converts object to a searchable string by combining fields into a single string
 * @returns a searchable employee
 * @param employee the employee to convert
 */
export const searchableEmployee = (employee: Readonly<Employee>): string =>
  `${employee.employeeNumber} - ${employee.firstName} ${employee.lastName}`;

/**
 * @returns object from collection by searchable string
 * @param searchable the searchable string
 * @param employees the collection to search
 */
export const getSearchableEmployee = <E extends Employee>(
  searchable: string,
  employees: ReadonlyArray<Readonly<E>>,
): E | undefined => employees.find((employee) => searchableEmployee(employee) === searchable);

/**
 * @returns the constituent parts of searchable string
 * @param searchable the searchable string
 */
export const parseSearchableEmployee = (searchable: string): { employeeNumber: string } => {
  const employeeNumber = searchable.match(/^([^\s]+)\s+-/)?.[1];
  if (employeeNumber == null) {
    throw new Error(`ParsingError: searchable employee type has no employee number! ` + searchable);
  }
  return { employeeNumber };
};

/**
 * constituent parts for searchable strings
 */
type Mission = Pick<GetAccountingMissions_missions, 'id' | 'name'>;

/**
 * converts object to a searchable string by combining fields into a single string
 * @returns a searchable mission
 * @param mission the mission to convert
 */
export const searchableMission = (mission: Readonly<Mission>): string =>
  `${mission.id} - ${mission.name}`;

/**
 * @returns object from collection by searchable string
 * @param searchable the searchable string
 * @param missions the collection to search
 */
export const getSearchableMission = <E extends Mission>(
  searchable: string,
  missions: ReadonlyArray<Readonly<E>>,
): E | undefined => missions.find((mission) => searchableMission(mission) === searchable);

/**
 * @returns the constituent parts of searchable string
 * @param searchable the searchable string
 */
export const parseSearchableMission = (searchable: string): Mission => {
  const [idStr, ...nameParts] = searchable.split(' - ');
  const id = Number(idStr);
  const name = nameParts.join(' - ');
  return { id, name };
};

/**
 * constituent parts for searchable strings
 */
type MissionProject = Mission & {
  project: Pick<GetAccountingMissions_missions['project'], 'projectNumber' | 'projectName'>;
};

/**
 * converts object to a searchable string by combining fields into a single string
 * @returns a searchable mission project
 * @param mission the mission to convert
 */
export const searchableMissionProject = (mission: Readonly<MissionProject>): string =>
  `${mission.project.projectNumber} - ${mission.project.projectName}`;

/**
 * @returns object from collection by searchable string
 * @param searchable the searchable string
 * @param missions the collection to search
 */
export const getSearchableMissionsByProject = <E extends MissionProject>(
  searchable: string,
  missions: ReadonlyArray<Readonly<E>>,
): Array<Readonly<E>> =>
  missions.filter((mission) => searchableMissionProject(mission) === searchable);

/**
 * constituent parts for searchable strings
 */
type Material = Pick<
  GetAccountingMaterials_activeMaterialCatalog_materials,
  'materialNumber' | 'nameOne' | 'acronym'
>;

/**
 * converts object to a searchable string by combining fields into a single string
 * @returns a searchable material
 * @param material the material to convert
 */
export const searchableMaterial = (material: Readonly<Material>): string =>
  `${material.materialNumber} - ${material.nameOne} (${material.acronym})`;

/**
 * @returns object from collection by searchable string
 * @param searchable the searchable string
 * @param materials the collection to search
 */
export const getSearchableMaterial = <E extends Material>(
  searchable: string,
  materials: ReadonlyArray<Readonly<E>>,
): E | undefined => materials.find((material) => searchableMaterial(material) === searchable);

/**
 * constituent parts for searchable strings
 */
type Vehicle = Pick<GetAccountingVehicles_vehicles, 'inventoryNumber' | 'nameOne'>;

/**
 * converts object to a searchable string by combining fields into a single string
 * @returns a searchable vehicle
 * @param vehicle the vehicle to convert
 */
export const searchableVehicle = (vehicle: Readonly<Vehicle>): string =>
  `${vehicle.inventoryNumber} - ${vehicle.nameOne}`;

/**
 * @returns object from collection by searchable string
 * @param searchable the searchable string
 * @param vehicles the collection to search
 */
export const getSearchableVehicle = <E extends Vehicle>(
  searchable: string,
  vehicles: ReadonlyArray<Readonly<E>>,
): E | undefined => vehicles.find((vehicle) => searchableVehicle(vehicle) === searchable);

/**
 * constituent parts for searchable strings
 */
type Machine = Pick<GetAccountingMachines_machines, 'inventoryNumber' | 'nameOne'>;

/**
 * converts object to a searchable string by combining fields into a single string
 * @returns a searchable machine
 * @param machine the machine to convert
 */
export const searchableMachine = (machine: Readonly<Machine>): string =>
  `${machine.inventoryNumber} - ${machine.nameOne}`;

/**
 * @returns object from collection by searchable string
 * @param searchable the searchable string
 * @param machines the collection to search
 */
export const getSearchableMachine = <E extends Machine>(
  searchable: string,
  machines: ReadonlyArray<Readonly<E>>,
): E | undefined => machines.find((machine) => searchableMachine(machine) === searchable);

/**
 * constituent parts for searchable strings
 */
type ActivityType = Pick<GetAccountingActivityTypes_activityTypes, 'number' | 'nameOne'> & {
  unit?: { acronym: string } | null;
};

/**
 * converts object to a searchable string by combining fields into a single string
 * @returns a searchable activityType
 * @param activityType the activityType to convert
 */
export const searchableActivityType = (activityType: Readonly<ActivityType>): string =>
  `${activityType.number} - ${activityType.nameOne}${
    activityType.unit ? ` (${activityType.unit.acronym})` : ''
  }`;

/**
 * @returns object from collection by searchable string
 * @param searchable the searchable string
 * @param activityTypes the collection to search
 */
export const getSearchableActivityType = <E extends ActivityType>(
  searchable: string,
  activityTypes: ReadonlyArray<Readonly<E>>,
): E | undefined =>
  activityTypes.find((activityType) => searchableActivityType(activityType) === searchable);

/**
 * constituent parts for searchable strings
 */
type CollectiveAccount = Pick<GetAccountingCollectiveAccounts_collectiveAccounts, 'nameOne'>;

/**
 * converts object to a searchable string by combining fields into a single string
 * @returns a searchable collective account
 * @param collectiveAccount the collective account to convert
 */
export const searchableCollectiveAccount = (
  collectiveAccount: Readonly<CollectiveAccount>,
): string => `SK - ${collectiveAccount.nameOne}`;

/**
 * @returns object from collection by searchable string
 * @param searchable the searchable string
 * @param collectiveAccounts the collection to search
 */
export const getSearchableCollectiveAccount = <E extends CollectiveAccount>(
  searchable: string,
  collectiveAccounts: ReadonlyArray<Readonly<E>>,
): E | undefined =>
  collectiveAccounts.find(
    (collectiveAccount) => searchableCollectiveAccount(collectiveAccount) === searchable,
  );

/**
 * An object that follows the activity type parsing contract.
 *
 * @see parseSearchableActivityType
 */
export interface IParsedActivityType {
  unit?: string;
  number: number;
  name: string;
}

/**
 * @returns the constituent parts of searchable string
 * @param searchable the searchable string
 */
export const parseSearchableActivityType = (searchable: string): IParsedActivityType => {
  const numberStr = searchable.match(/^([^\s]+)\s+-/)?.[1];
  if (numberStr == null) {
    throw new Error(`ParsingError: searchable activity type has no number! ` + searchable);
  }
  const num = Number(numberStr);
  if (isNaN(num)) {
    throw new Error(
      `ParsingError: searchable activity type has an incorrect number! ` + searchable,
    );
  }
  const unit = searchable.match(/\((.+)\)$/)?.[1];
  const name = searchable.replace(/\s+\(.*\)$/, '').match(/^[^\s]+\s+-\s+(.*)/)?.[1];
  if (name == null) {
    throw new Error(`ParsingError: searchable activity type has no name! ` + searchable);
  }

  return { number: num, unit, name };
};
