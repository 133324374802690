import { omit } from 'lodash/fp';
import { AccountingItemInput } from '../../../../../types/graphql';
import {
  PreviewAccountingLogItems_calculatePreviewItems_drivingItems,
  PreviewAccountingLogItems_calculatePreviewItems_employeeBonusesOverviews_accountingItems,
  PreviewAccountingLogItems_calculatePreviewItems_setupItems,
} from '../../types/PreviewAccountingLogItems';

type MixedAccountingItem =
  | AccountingItemInput
  | PreviewAccountingLogItems_calculatePreviewItems_drivingItems
  | PreviewAccountingLogItems_calculatePreviewItems_setupItems
  | PreviewAccountingLogItems_calculatePreviewItems_employeeBonusesOverviews_accountingItems;

/**
 * take a mixed bag of AccountingItemInput-likes and convert them to AccountingItemInput to be sent to the api
 * @param allItems mixed AccountingItemInput-likes
 * @returns data prepared to be sent to api
 */
export const toAccountingItemInput = (
  allItems: ReadonlyArray<MixedAccountingItem>,
): AccountingItemInput[] =>
  allItems.map((item) => ({
    ...omit(['__typename', 'id'], item),
    creditedTo:
      'data' in item.creditedTo
        ? omit(['data.collectiveAccount.nameOne'], item.creditedTo)
        : {
            data: {
              ...omit(['__typename', 'id'], item.creditedTo),
              subsidiary:
                item.creditedTo.subsidiary != null
                  ? omit(['__typename'], item.creditedTo.subsidiary)
                  : undefined,
              employee:
                item.creditedTo.employee != null
                  ? omit(['__typename'], item.creditedTo.employee)
                  : undefined,
              material:
                item.creditedTo.material != null
                  ? omit(['__typename'], item.creditedTo.material)
                  : undefined,
              machine:
                item.creditedTo.machine != null
                  ? omit(['__typename'], item.creditedTo.machine)
                  : undefined,
              vehicle:
                item.creditedTo.vehicle != null
                  ? omit(['__typename'], item.creditedTo.vehicle)
                  : undefined,
              collectiveAccount:
                item.creditedTo.collectiveAccount != null
                  ? omit(['__typename', 'nameOne'], item.creditedTo.collectiveAccount)
                  : undefined,
              mission:
                item.creditedTo.mission != null
                  ? omit(['__typename'], item.creditedTo.mission)
                  : undefined,
            },
          },
    chargedTo:
      'data' in item.chargedTo
        ? omit(['data.collectiveAccount.nameOne'], item.chargedTo)
        : {
            data: {
              ...omit(['__typename', 'id'], item.chargedTo),
              subsidiary:
                item.chargedTo.subsidiary != null
                  ? omit(['__typename'], item.chargedTo.subsidiary)
                  : undefined,
              employee:
                item.chargedTo.employee != null
                  ? omit(['__typename'], item.chargedTo.employee)
                  : undefined,
              material:
                item.chargedTo.material != null
                  ? omit(['__typename'], item.chargedTo.material)
                  : undefined,
              machine:
                item.chargedTo.machine != null
                  ? omit(['__typename'], item.chargedTo.machine)
                  : undefined,
              vehicle:
                item.chargedTo.vehicle != null
                  ? omit(['__typename'], item.chargedTo.vehicle)
                  : undefined,
              collectiveAccount:
                item.chargedTo.collectiveAccount != null
                  ? omit(['__typename', 'nameOne'], item.chargedTo.collectiveAccount)
                  : undefined,
              mission:
                item.chargedTo.mission != null
                  ? omit(['__typename'], item.chargedTo.mission)
                  : undefined,
            },
          },
  })) as AccountingItemInput[];
