import React, { useCallback } from 'react';
import MissionForm from '../../components/Mission/MissionForm';
import { RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import { AddMissionMutation, AddMissionMutationVariables } from './types/AddMissionMutation';
import DetailsFormPaper from '../../components/DetailsFormPaper';
import { MutationUpdaterFn } from 'apollo-client';
import { MISSION_VIEW_FRAGMENT } from '../Projects/TabMissions/MissionSelector/paginationHelpers/queries';
import { ADD_ROW_TO_DATA_TABLE } from '../../services/graphql-client';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../services/types/AddRowToDataTable';
import { DataTableRowInput, RowType } from '../../types/graphql';
import {
  buildTableId,
  mapMissionStatus,
} from '../Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { executeForPopulatedMissionTableTypes } from '../Projects/TabMissions/MissionSelector/paginationHelpers/executeForMissionTypes';
import { CreateMissionData, CreateMissionDataVariables } from './types/CreateMissionData';
import { FULL_ADDRESS_BOOK } from '../../components/Address/query';

interface IMatchParams {
  projectNumber: string;
}

const ADD_MISSION_MUTATION = gql`
  mutation AddMissionMutation($projectNumber: String!, $data: MissionCreateInput) {
    addMissionToProject(project: { projectNumber: $projectNumber }, data: $data) {
      ...missionViewFields
    }
  }
  ${MISSION_VIEW_FRAGMENT}
`;

const CREATE_MISSION_QUERY = gql`
  query CreateMissionData($projectNumber: String!) {
    project(where: { projectNumber: $projectNumber }) {
      id
      personResponsible {
        id
        name
      }
    }
    addressBook(where: { projectNumber: $projectNumber }) {
      ...FullAddressBook
    }
  }
  ${FULL_ADDRESS_BOOK}
`;

const CreateMission: React.FC<RouteComponentProps<IMatchParams>> = ({ match, history }) => {
  const { projectNumber } = match.params;
  const client = useApolloClient();

  const { data, error, loading } = useQuery<CreateMissionData, CreateMissionDataVariables>(
    CREATE_MISSION_QUERY,
    {
      variables: { projectNumber },
    },
  );

  const addMissionToCache = useCallback<MutationUpdaterFn<AddMissionMutation>>(
    (_, { data }) => {
      if (!data) {
        return;
      }

      const mission = data.addMissionToProject;

      const rowInput: DataTableRowInput = {
        __typename: 'DataTableRow',
        id: mission.id.toString(),
        containerRows: [],
        innerTableRows: [],
        hidden: false,
        data: JSON.stringify({
          ...mission,
          netPlanSum: 0,
          grossPlanSum: 0,
          netFinishedSum: 0,
          grossFinishedSum: 0,
          colorCodings: [],
          status: mission.status ? mapMissionStatus(mission.status, mission.executionDate) : null,
          hasDescendants: false,
        }),
      };

      executeForPopulatedMissionTableTypes(client, projectNumber, (tableType) =>
        client.query<AddRowToDataTable, AddRowToDataTableVariables>({
          query: ADD_ROW_TO_DATA_TABLE,
          variables: {
            where: { id: tableType },
            data: [
              {
                row: {
                  ...rowInput,
                  id: buildTableId(tableType)(rowInput.id),
                },
                type: RowType.CONTAINER,
              },
            ],
          },
        }),
      );
    },
    [client, projectNumber],
  );

  const [addMission] = useMutation<AddMissionMutation, AddMissionMutationVariables>(
    ADD_MISSION_MUTATION,
    {
      update: addMissionToCache,
    },
  );

  const handleClose = useCallback(() => history.goBack(), [history]);

  const handleMissionSubmit = useCallback(
    async (values) => {
      const submitValues = {
        ...values,
        project: undefined,
      };
      await addMission({ variables: { data: submitValues, projectNumber } });
      handleClose();
    },
    [addMission, projectNumber, handleClose],
  );

  if (loading || error || !data) {
    return null;
  }

  return (
    <DetailsFormPaper onClose={handleClose}>
      <MissionForm
        onSubmit={handleMissionSubmit}
        initialValues={{
          project: {
            ...data.project,
            projectAddress: data.addressBook?.projectAddress ?? undefined,
          },
        }}
      />
    </DetailsFormPaper>
  );
};

export default CreateMission;
