import gql from 'graphql-tag';

export const MATERIAL_CATALOG_CREATE_MUTATION = gql`
  mutation CreateMaterialCatalog($catalog: Upload!, $isOverride: Boolean) {
    importMaterialCatalog(data: { catalog: $catalog, isOverride: $isOverride }) {
      id
    }
  }
`;

export const GET_PROJECT_SUGGESTIONS = gql`
  query ProjectSuggestions {
    projects {
      id
      projectNumber
    }
  }
`;
