import React, { useCallback, useMemo, useState } from 'react';
import { Dialog, DialogTitle, IconButton, InputAdornment, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import History from '@material-ui/icons/History';
import CloseIcon from '@material-ui/icons/Close';
import { Field, FieldAttributes, Formik } from 'formik';
import { formatDate } from '../../utils/format/date';
import FormikSelect from './FormikSelect';
import { DataTableBody, DataTableHotKeysWrapper } from '../DataTable';
import InlineEditingFormikForm from './InlineEditingFormikForm';
import {
  IDataTableColumn,
  IDataTableOptions,
  IDataTableRow,
  ILevelOptions,
} from '../DataTable/types';
import AddButton from '../DataTable/DataTableToolbar/AddButton';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import CancelSaveIconButtons from './CancelSaveIconButtons';
import { useMutation } from 'react-apollo';
import {
  CREATE_EDITABLE_HISTORY_ITEM,
  DELETE_EDITABLE_HISTORY_ITEM,
  UPDATE_EDITABLE_HISTORY_ITEM,
} from '../History/history.queries';
import {
  createEditableHistoryItem,
  createEditableHistoryItemVariables,
} from '../History/types/createEditableHistoryItem';
import {
  updateEditableHistoryItem,
  updateEditableHistoryItemVariables,
} from '../History/types/updateEditableHistoryItem';
import {
  deleteEditableHistoryItem,
  deleteEditableHistoryItemVariables,
} from '../History/types/deleteEditableHistoryItem';
import AppProgress from '../Page/AppProgress';
import AppErrorMessage from '../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import { last, omit, sortBy } from 'lodash';
import { EditableHistoryItemFieldType } from '../../types/graphql';
import FormikTextField from './FormikTextField';
import { findRow } from '../BillOfQuantity/BllOfQuantityTable/BillOfQuantityTable.utils';
import { formatDateForForm } from '../../utils/format/date/formatDateForForm.util';
import { DeleteHistoryItemAction } from '../Actions/Delete/HistoryItem';
import { PureQueryOptions } from 'apollo-client';

const useStyles = makeStyles({
  columnTextField: {
    marginTop: '0',
    '& .MuiInput-formControl': {
      marginTop: '0',
    },
  },
  paper: {
    padding: '10px',
  },
  grow: {
    flexGrow: 1,
  },
  dialogTitle: {
    paddingLeft: 0,
  },
});

const useFetches = (refetchQueries: PureQueryOptions[]) => {
  const [createItem, { loading: createLoading, error: createError }] = useMutation<
    createEditableHistoryItem,
    createEditableHistoryItemVariables
  >(CREATE_EDITABLE_HISTORY_ITEM, { refetchQueries, awaitRefetchQueries: true });
  const [updateItem, { loading: updateLoading, error: updateError }] = useMutation<
    updateEditableHistoryItem,
    updateEditableHistoryItemVariables
  >(UPDATE_EDITABLE_HISTORY_ITEM, { refetchQueries, awaitRefetchQueries: true });
  const [deleteItem, { loading: deleteLoading, error: deleteError }] = useMutation<
    deleteEditableHistoryItem,
    deleteEditableHistoryItemVariables
  >(DELETE_EDITABLE_HISTORY_ITEM, { refetchQueries, awaitRefetchQueries: true });

  return {
    loading: createLoading || updateLoading || deleteLoading,
    error: createError || updateError || deleteError,
    createItem,
    updateItem,
    deleteItem,
  };
};

export interface IHistorizedFieldItem {
  id: string;
  date: string;
  value: string;
  user: string;
  validFrom?: string;
}

export interface IHistorizedFieldProps {
  history: IHistorizedFieldItem[];
  isEditable?: boolean;
  fieldType?: EditableHistoryItemFieldType;
  entityId?: string;
  refetchQueries?: PureQueryOptions[];
}

const NEW_ROW_ID = '__NEW__';

const addEmptyRow = (user: string): IDataTableRow => ({
  id: NEW_ROW_ID,
  data: {
    user,
    date: new Date().toLocaleDateString('en-US'),
    value: '',
    validFrom: '',
  },
});

interface IProps extends IHistorizedFieldProps, FieldAttributes<any> {}

const HistorizedField: React.FC<IProps> = ({
  history,
  children,
  isEditable = false,
  fieldType,
  entityId,
  refetchQueries,
  disabled,
  ...props
}) => {
  const [historyOpen, setHistoryOpen] = useState(false);
  const [rowToEditId, setRowToEditId] = useState('');

  const classes = useStyles();

  const { loading, error, createItem, updateItem, deleteItem } = useFetches(refetchQueries ?? []);

  const toggleHistory = useCallback(() => {
    if (!historyOpen) {
      setRowToEditId('');
    }

    setHistoryOpen(!historyOpen);
  }, [setHistoryOpen, historyOpen]);

  const user = useLoggedInUser();

  const HistoryAdornment = useMemo(
    () => (
      <InputAdornment position="start">
        <IconButton onClick={toggleHistory}>
          <History fontSize="small" />
        </IconButton>
      </InputAdornment>
    ),
    [toggleHistory],
  );

  // the endAdornment property is diffrent for the Select component
  const endAdornment =
    props.component === FormikSelect
      ? { endAdornment: HistoryAdornment }
      : { InputProps: { endAdornment: HistoryAdornment } };

  const columns = useMemo<IDataTableColumn[]>(
    () => [
      {
        id: 'value',
        label: 'Wert',
        render: (value: string, row) => {
          if (row.id !== rowToEditId) {
            return value;
          }

          return (
            <Field
              name="value"
              {...omit(props, 'label', 'name')}
              {...(props.component === FormikSelect && {
                FormControlProps: { className: classes.columnTextField },
              })}
              disabled={disabled}
            >
              {children}
            </Field>
          );
        },
      },
      { id: 'date', label: 'Am', render: (date: string) => formatDate(date) },
      { id: 'user', label: 'Von' },
      ...(isEditable
        ? [
            {
              id: 'validFrom',
              label: 'Gültig ab',
              render: (date: string, row: IDataTableRow) => {
                if (rowToEditId !== row.id) {
                  return formatDate(date);
                }

                return (
                  <Field
                    disabled={disabled}
                    name="validFrom"
                    component={FormikTextField}
                    type="date"
                  />
                );
              },
            },
          ]
        : []),
    ],
    [isEditable, rowToEditId, props, classes.columnTextField, disabled, children],
  );

  const getRowActions = useCallback<
    (isDisabled: boolean) => Exclude<ILevelOptions['rowActions'], undefined>
  >(
    (isDisabled) =>
      ({ row }) => {
        const isEditableRow = row.id === rowToEditId;

        if (!isEditableRow) {
          return (
            <>
              <DeleteHistoryItemAction
                onDelete={async () => {
                  await deleteItem({ variables: { where: { id: row.id } } });
                  toggleHistory();
                }}
                isDisabled={loading || disabled}
              />
            </>
          );
        }

        return (
          <>
            <CancelSaveIconButtons
              onCancel={() => setRowToEditId('')}
              isDisabled={isDisabled}
              cancelIsDisabled={false}
            />
          </>
        );
      },
    [rowToEditId, loading, disabled, deleteItem, toggleHistory],
  );

  const getDataTableOptions = useCallback(
    (isSubmitting: boolean, dirty: boolean): IDataTableOptions => ({
      noMinWidth: true,
      hideShowAdditionalColumnsBtn: true,
      hideInterface: !isEditable,
      activeRowId: '',
      actionColumnWidth: 50,
      levels: [
        {
          columns,
          onDoubleRowClick: (row) => setRowToEditId(row.id),
          rowActions: isEditable ? getRowActions(isSubmitting || !dirty || disabled) : undefined,
          actions: () => (
            <>
              <AddButton
                disabled={disabled}
                tooltip="Neu"
                onClick={() => setRowToEditId(NEW_ROW_ID)}
              />
            </>
          ),
        },
      ],
    }),
    [columns, isEditable, setRowToEditId, getRowActions, disabled],
  );

  const innerTableRows = useMemo<IDataTableRow[]>(() => {
    const innerTableRows: IDataTableRow[] = [];

    if (history) {
      innerTableRows.push(
        ...history.map<IDataTableRow>(({ id, date, user, value, validFrom }) => ({
          id,
          data: {
            date,
            user,
            value,
            validFrom,
          },
        })),
      );
    }

    if (rowToEditId === NEW_ROW_ID && user) {
      innerTableRows.push(addEmptyRow(user.name!));
    }

    return sortBy(innerTableRows, 'data.validFrom').reverse();
  }, [history, rowToEditId, user]);

  const onSubmit = useCallback(
    async ({ value, validFrom }: any) => {
      const isCreate = rowToEditId === NEW_ROW_ID;

      if (isCreate) {
        const initialValues = last(innerTableRows)!.data;

        await createItem({
          variables: {
            data: {
              createdBy: { name: initialValues.user },
              value,
              validFrom,
            },
            where: { entityId: entityId!, fieldType: fieldType! },
          },
        });
      } else {
        const initialValues = findRow(rowToEditId, innerTableRows)!;

        await updateItem({
          variables: {
            data: {
              value,
              validFrom,
            },
            where: { id: initialValues.id },
          },
        });
      }

      toggleHistory();
    },
    [updateItem, createItem, rowToEditId, innerTableRows, entityId, fieldType, toggleHistory],
  );

  const initialValues = useMemo(() => {
    const isCreate = rowToEditId === NEW_ROW_ID;

    if (isCreate || !rowToEditId) {
      return { value: '', validFrom: '' };
    }

    const row = findRow(rowToEditId, innerTableRows)!;

    return {
      value: row.data.value,
      validFrom: formatDateForForm(row.data.validFrom),
    };
  }, [rowToEditId, innerTableRows]);

  return (
    <>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}

      <Field disabled={disabled} {...props} {...endAdornment}>
        {children}
      </Field>

      {history && (
        <Dialog open={historyOpen} classes={{ paper: classes.paper }} disableEnforceFocus>
          <Toolbar>
            <DialogTitle classes={{ root: classes.dialogTitle }}>Historie</DialogTitle>
            <div className={classes.grow} />
            <IconButton onClick={toggleHistory} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>

          <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
            {({ isSubmitting, dirty, submitForm }) => (
              <InlineEditingFormikForm
                dirty={dirty}
                tabSubmitHandler={async (e) => {
                  if (e.target.name !== 'save-button') {
                    return;
                  }

                  await submitForm();

                  setRowToEditId('');
                }}
              >
                <DataTableHotKeysWrapper
                  options={getDataTableOptions(isSubmitting, dirty)}
                  innerTableRows={innerTableRows}
                >
                  {(context) => <DataTableBody context={context} />}
                </DataTableHotKeysWrapper>
              </InlineEditingFormikForm>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  );
};

export default HistorizedField;
