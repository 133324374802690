import { Field, FieldProps } from 'formik';
import React, { useEffect } from 'react';
import { MissionManualLockingStatus } from '../../types/graphql';
import FormikCheckboxWithLabel from '../Form/FormikCheckboxWithLabel';

interface IProps extends FieldProps {
  isMissionBlocked: boolean;
}

/**
 * mission is blocked => check box will unblock mission
 * mission is unblocked => check box will block mission
 *
 */

const MissionBlockingCheckbox: React.FC<IProps> = ({ field, form, isMissionBlocked }) => {
  useEffect(() => {
    if (field.value) {
      const value = isMissionBlocked
        ? MissionManualLockingStatus.UN_BLOCKED
        : MissionManualLockingStatus.BLOCKED;

      form.setFieldValue('manualLockingStatus', value);
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <>
      <Field
        name="missionManualLockingStatusCB"
        component={FormikCheckboxWithLabel}
        Label={{ label: isMissionBlocked ? 'Einsatz entsperren' : 'Einsatz sperren' }}
      />
      <Field name="manualLockingStatus" hidden={true} />
    </>
  );
};

export default MissionBlockingCheckbox;
