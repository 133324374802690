import { getOperationName } from 'apollo-utilities';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useMutation } from 'react-apollo';
import { GET_ADDRESS_BOOK } from '../query';
import { UPSERT_ADDRESS } from './query';
import { UpsertAddress, UpsertAddressVariables } from './types/UpsertAddress';

export const useAddressEditFetch = (addressId: Maybe<number>) => {
  const [executeUpsertAddress, { loading, error }] = useMutation<
    UpsertAddress,
    UpsertAddressVariables
  >(UPSERT_ADDRESS, {
    refetchQueries: !addressId ? [getOperationName(GET_ADDRESS_BOOK) ?? ''] : [],
  });

  return {
    executeUpsertAddress,
    loading,
    error,
  };
};
