import { last } from 'lodash';
import React from 'react';
import { AddressSearchUseFetchResult } from '../../fetch';
import { AddressSearch_abacusAddresses } from '../../types/AddressSearch';

const getLoader = () => <span></span>;
const getHeight = (windowHeight: number) => windowHeight * 0.55;
const createGetNextFn =
  (
    abacusAddresses: AddressSearch_abacusAddresses[],
    fetchMore: AddressSearchUseFetchResult['fetchMore'],
  ) =>
  () => {
    const lastAddress = last(abacusAddresses);
    if (!lastAddress) {
      return;
    }

    fetchMore({
      variables: { after: parseInt(lastAddress.id) },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          abacusAddresses: [...prev.abacusAddresses, ...fetchMoreResult.abacusAddresses],
        });
      },
    });
  };

export const ADDRESS_SEARCH_INFINITE_SCROLL_UTILS = {
  getLoader,
  getHeight,
  createGetNextFn,
};
