import {
  Divider,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  RootRef,
  Theme,
  Typography,
} from '@material-ui/core';
import { ListItemProps } from '@material-ui/core/ListItem/ListItem';
import { makeStyles } from '@material-ui/styles';
import { Field, FieldAttributes } from 'formik';
import React, { useRef } from 'react';
import Highlighter from 'react-highlight-words';
import FormikTextField from '../../../components/Form/FormikTextField';

interface IItem {
  unit?: string | null;
  productNumber?: string | null;
  descriptionOne?: string | null;
  material?: string | null;
}

interface IItemProps extends ListItemProps {
  item: IItem;
  fieldProps: FieldAttributes<any>;
  fieldRef?: (node: any) => void;
  filterText?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  secondaryAction: {
    right: 0,
  },
  secondaryText: {
    maxWidth: 200,
  },
  primaryText: {
    fontWeight: theme.typography.subtitle2.fontWeight,
  },
  gridContainer: {
    height: '100%',
  },
  input: {
    maxWidth: 80,
    padding: 0,
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightMedium,
    '&::placeholder': {
      fontSize: 19,
    },
  },
}));

const Item: React.FC<IItemProps> = ({
  fieldProps,
  item: { material, productNumber, descriptionOne, unit },
  fieldRef,
  filterText,
  ...props
}) => {
  const classes = useStyles();

  const inputRef = useRef<HTMLElement>();

  const field = (
    <Field
      {...fieldProps}
      component={FormikTextField}
      type="number"
      placeholder="Menge"
      className={classes.input}
      InputProps={{
        disableUnderline: true,
        classes: {
          input: classes.input,
        },
        ref: inputRef,
      }}
      size="medium"
    />
  );

  const secondaryText = material ? `${material} - ${descriptionOne}` : descriptionOne;

  const searchWords = filterText?.split(' ') ?? [];

  return (
    <>
      {/* the button prop is causing a problem, that is why we have to use type assertion */}
      <ListItem {...(props as any)}>
        <ListItemText
          primary={
            <Typography className={classes.primaryText}>
              <Highlighter
                searchWords={searchWords}
                textToHighlight={productNumber ?? ''}
                autoEscape
              />
            </Typography>
          }
          secondary={
            <Typography className={classes.secondaryText}>
              <Highlighter
                searchWords={searchWords}
                textToHighlight={secondaryText ?? ''}
                autoEscape
              />
            </Typography>
          }
        />
        <ListItemSecondaryAction
          className={classes.secondaryAction}
          onClick={() => inputRef.current?.click()}
        >
          <Grid container direction="column" className={classes.gridContainer} justify="flex-end">
            <Grid item>
              <Typography>
                <Highlighter searchWords={searchWords} textToHighlight={unit ?? ''} autoEscape />
              </Typography>
            </Grid>
            <Grid item>{fieldRef ? <RootRef rootRef={fieldRef}>{field}</RootRef> : field}</Grid>
          </Grid>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
};

export default Item;
