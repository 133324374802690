import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { Maybe } from 'graphql/jsutils/Maybe';
import { isEmpty, last, omit, uniq } from 'lodash';
import { pipe } from 'lodash/fp';
import { buildContainerItemCountMapping } from '../../../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/onSearch';
import { isOneOfRowInputs } from '../../../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/refetchItemsInContainer';
import { ISearchSubmitProps } from '../../../../../components/Search/Search';
import {
  ADD_ROW_TO_DATA_TABLE,
  UPDATE_DATA_TABLE_ROWS,
} from '../../../../../services/graphql-client';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../../../../services/types/AddRowToDataTable';
import { GetDataTable_dataTable_rows_containerRows_containerRows } from '../../../../../services/types/GetDataTable';
import { GetDataTables_dataTables } from '../../../../../services/types/GetDataTables';
import {
  UpdateDataTableRows,
  UpdateDataTableRowsVariables,
} from '../../../../../services/types/UpdateDataTableRows';
import {
  MissionItemWhereInput,
  ProjectWhereInput,
  RowType,
  SearchInput,
  TableType,
  UpdateDataTableRowsInput,
} from '../../../../../types/graphql';
import { deepCopy } from '../../../../../utils/deepCopy';
import { IPaginationHelperFns } from '../../../../../utils/paginationHelpers';
import { getAllContainerRows } from '../../../../../utils/paginationHelpers/getAllContainerRows';
import { executeForMissionTableTypes } from './executeForMissionTypes';
import {
  buildTableId,
  mapMissionItem,
  mapMissionItemToRowInput,
  removeTableTypeFromId,
} from './mapper';
import {
  MISSION_DEFAULT_REFETCH_ITEMS_IN_CONTAINER,
  MISSION_REFETCH_ITEMS_IN_CONTAINER,
} from './queries';
import {
  MissionDefaultRefetchItemsInContainer,
  MissionDefaultRefetchItemsInContainerVariables,
} from './types/MissionDefaultRefetchItemsInContainer';
import {
  MissionRefetchItemsInContainer,
  MissionRefetchItemsInContainer_missionItemLocations,
  MissionRefetchItemsInContainerVariables,
} from './types/MissionRefetchItemsInContainer';

interface IFetchRefetchItemsQueryQueryOpts {
  take: number;
  missionItemsWhere: MissionItemWhereInput;
  search: Maybe<SearchInput>;
}

const refetchLocations = (containerIds: string[], project: ProjectWhereInput) => {
  const ids = containerIds.map((id) => removeTableTypeFromId(id));
  const missionIdIn = uniq(ids.map((id) => parseInt(id.split('-')[0], 10)));

  return (client: ApolloClient<any>) => async (queryOpts: IFetchRefetchItemsQueryQueryOpts) => {
    const isDefaultLocationFetch = ids.some((id) => !id.includes('-'));

    const allLocations: MissionRefetchItemsInContainer_missionItemLocations[] = [];

    if (isDefaultLocationFetch) {
      const {
        data: { missions },
      } = deepCopy(
        await client.query<
          MissionDefaultRefetchItemsInContainer,
          MissionDefaultRefetchItemsInContainerVariables
        >({
          query: MISSION_DEFAULT_REFETCH_ITEMS_IN_CONTAINER,
          variables: {
            mission: {
              id_in: missionIdIn,
              project,
            },
            pagination: { take: queryOpts.take },
            missionItemWhere: queryOpts.missionItemsWhere ?? {},
            search: queryOpts.search,
          },
        }),
      );

      allLocations.push(...missions.map((mission) => mission.defaultMissionItemLocation));
    }

    const {
      data: { missionItemLocations },
    } = deepCopy(
      await client.query<MissionRefetchItemsInContainer, MissionRefetchItemsInContainerVariables>({
        query: MISSION_REFETCH_ITEMS_IN_CONTAINER,
        variables: {
          locationWhere: {
            id_in: ids.filter((id) => id.includes('-')), // the location id
            mission_id_in: missionIdIn,
          },
          pagination: { take: queryOpts.take },
          missionItemWhere: queryOpts.missionItemsWhere ?? {},
          search: queryOpts.search,
        },
      }),
    );

    allLocations.push(...missionItemLocations);

    return allLocations;
  };
};

const writeRefetchedLocationsToCache =
  (client: ApolloClient<any>) =>
  (tableType: TableType) =>
  (containerRowsToRefetch: ReturnType<typeof getAllContainerRows>) =>
  (refetchedLocations: MissionRefetchItemsInContainer_missionItemLocations[]): void => {
    const data = refetchedLocations.map<UpdateDataTableRowsInput & { hasNextPage: boolean }>(
      (refetchedLocation) => {
        const containerRow = containerRowsToRefetch.find((containerRow) => {
          return buildTableId(tableType)(refetchedLocation.id!) === containerRow.id;
        })!;

        return {
          where: { id: containerRow.id, tableType },
          data: {
            innerTableRows: refetchedLocation.missionItemsConnection.nodes.map(
              pipe(mapMissionItem, mapMissionItemToRowInput(tableType)),
            ),
          },
          hasNextPage: refetchedLocation.missionItemsConnection.pageInfo.hasNextPage,
        };
      },
    );

    client.query<UpdateDataTableRows, UpdateDataTableRowsVariables>({
      query: UPDATE_DATA_TABLE_ROWS,
      variables: {
        where: { id: tableType },
        data: data.map((v) => omit(v, 'hasNextPage')),
      },
    });

    data.forEach(({ where: { id: containerRowId }, hasNextPage }) => {
      client.cache.writeData({
        id: `ClientPageInfo:${containerRowId}`,
        data: {
          __typename: 'ClientPageInfo',
          id: containerRowId,
          hasNextPage,
        },
      });
    });
  };

const clientMissingLocationFragment = gql`
  fragment ClientMissingLocation on Location {
    id
    name
  }
`;

const buildMissingMissionItemLocations =
  (client: ApolloClient<any>) =>
  async (
    containerRowIdsToBuild: string[],
  ): Promise<GetDataTable_dataTable_rows_containerRows_containerRows[]> => {
    const locations = containerRowIdsToBuild.map((id) => {
      const location = client.cache.readFragment<{ id: string; name: string }>({
        id: `Location:${last(removeTableTypeFromId(id).split('-'))!}`,
        fragment: clientMissingLocationFragment,
      });

      return {
        ...location,
        id,
      };
    });

    const inputs = locations.map((location) => {
      const tableType = last(location.id.split('-'))!;
      const idWithoutTableType = removeTableTypeFromId(location.id);
      const [missionId, locationOneId, locationTwoId] = idWithoutTableType.split('-');
      const isL1 = !locationTwoId;
      const locationId = last(idWithoutTableType.split('-'))!;
      const containerId = isL1
        ? `${missionId}-${tableType}`
        : `${missionId}-${locationOneId}-${tableType}`;

      return {
        type: RowType.CONTAINER,
        containerId,
        row: {
          __typename: 'DataTableRow',
          hidden: false,
          id: location.id,
          data: JSON.stringify({
            ...location,
            id: locationId,
            hasDescendants: true,
          }),
          containerRows: [],
          innerTableRows: [],
        },
      };
    });

    await executeForMissionTableTypes((tableType) =>
      client.query<AddRowToDataTable, AddRowToDataTableVariables>({
        query: ADD_ROW_TO_DATA_TABLE,
        variables: {
          where: { id: tableType },
          data: inputs,
        },
      }),
    );

    return inputs.map((v) => v.row as GetDataTable_dataTable_rows_containerRows_containerRows);
  };

// TODO @Emirhan warum zu viel cary methoden?!!
/**
 * refetches the items with variables in the container with the given id
 * if necessary builds the container
 */
export const refetchMissionItems =
  (client: ApolloClient<any>) =>
  (tableType: TableType) =>
  (
    projectNumber: string,
    showFinishedMissionItem: boolean,
    hideFullyInvoiced: boolean | undefined,
  ) =>
  (searchState: ISearchSubmitProps) =>
  (setLoading: (v: boolean) => void) =>
  (
    filteredDataTable: GetDataTables_dataTables | null,
  ): IPaginationHelperFns<MissionItemWhereInput>['refetchItemsInContainer'] =>
  async (inputs, variables) => {
    if (!filteredDataTable) {
      return;
    }

    setLoading(true);

    const containerRows = getAllContainerRows(filteredDataTable);
    const containerRowsToRefetch = containerRows.filter(isOneOfRowInputs(inputs));

    const inputsToBuild = inputs.filter(
      (input) => !containerRowsToRefetch.some((row) => row.id === input.containerId),
    );

    const l1InputsToBuild = inputsToBuild.filter(
      (input) => removeTableTypeFromId(input.containerId).match(/-/g)!.length === 1,
    );

    const l2InputsToBuild = inputsToBuild.filter(
      (input) => removeTableTypeFromId(input.containerId).match(/-/g)!.length === 2,
    );

    const l2ParentIdsToBuild = l2InputsToBuild
      .filter((l2Input) => {
        const [, l1Id] = removeTableTypeFromId(l2Input.containerId).split('-');

        const notInCurrentContainerRows = !containerRows.some(
          (row) => JSON.parse(row.data).id === l1Id,
        );
        const willNotBeBuildedByOther = !l1InputsToBuild.some(
          (l1Input) => l1Input.containerId.split('-')[1] === l1Id,
        );

        return notInCurrentContainerRows && willNotBeBuildedByOther;
      })
      .map((l2Input) => {
        const [missionId, l1Id, , tableType] = l2Input.containerId.split('-');

        return `${missionId}-${l1Id}-${tableType}`;
      });

    if (isEmpty(containerRowsToRefetch) && isEmpty(inputsToBuild)) {
      return setLoading(false);
    }

    if (!isEmpty(inputsToBuild)) {
      const inputsToBuild = [...l1InputsToBuild, ...l2InputsToBuild];

      const idsToBuild = uniq([
        ...l2ParentIdsToBuild,
        ...inputsToBuild.map((input) => input.containerId),
      ]);

      const buildedLocations = await buildMissingMissionItemLocations(client)(idsToBuild);

      containerRowsToRefetch.push(...buildedLocations);
    }
    const containerItemCountMapping = buildContainerItemCountMapping(containerRowsToRefetch);

    const allRefetchedLocations: MissionRefetchItemsInContainer_missionItemLocations[] = [];

    for (const { itemAmount, containerIds } of containerItemCountMapping) {
      const isFinished = showFinishedMissionItem ? undefined : false;
      // include all items that are measured
      const isMeasured = tableType === TableType.BILL_MISSION ? true : undefined;

      const refetchedLocations = await refetchLocations(containerIds, { projectNumber })(client)({
        missionItemsWhere: {
          hideFullyInvoiced,
          isFinished,
          isMeasured,
          ...(variables ?? {}),
        },
        search: searchState,
        take: itemAmount,
      });

      allRefetchedLocations.push(...refetchedLocations);
    }

    writeRefetchedLocationsToCache(client)(tableType)(containerRowsToRefetch)(
      allRefetchedLocations,
    );

    setLoading(false);
  };
