import React from 'react';
import { useMutation } from 'react-apollo';

import {
  CreateItemFromBoilerplate,
  CreateItemFromBoilerplateVariables,
} from '../../../components/BillOfQuantity/types/CreateItemFromBoilerplate';
import MobileItemImporter from '../../components/Item/MobileItemImporter';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { CREATE_ITEM_MUTATION } from '../../../components/BillOfQuantity/BllOfQuantityTable/BillOfQuantityTable.queries';
import { GET_LOCATIONS } from '../BillOfQuantityOverview/BillOfQuantityOverview.queries';

export const AddItemsFromItemboilerplates: React.FC = () => {
  const { locationId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [createItem] = useMutation<CreateItemFromBoilerplate, CreateItemFromBoilerplateVariables>(
    CREATE_ITEM_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_LOCATIONS,
          variables: {
            id: location.state.boqId,
          },
        },
      ],
    },
  );

  return (
    <MobileItemImporter
      onAddItems={async (values) => {
        await Promise.all(
          values.map((item) => {
            return createItem({
              variables: {
                itemBoilerplateId: item.id,
                volume: item.volume,
                appliedPrice: item.appliedPrice,
                locationId,
              },
            });
          }),
        );
        history.goBack();
      }}
    />
  );
};
