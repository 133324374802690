// courtesy of: https://stackoverflow.com/a/58896161
/**
 * remove trailing 0s from a number with optionally bounded fixed decimals
 * @param num the number
 * @param fixedDecimal (optional) max number of decimal places
 * @returns a string with the trailing 0s removed
 */
export const removeTrailingZeroes = (num: number, fixedDecimal?: number): string => {
  const numStr =
    fixedDecimal != null && fixedDecimal >= 0 ? num.toFixed(fixedDecimal) : num.toString();
  return numStr.replace(/^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[0-9]*?)0*$/, '$1$2$3');
};
