import { Fab, IconButton, Tooltip } from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import gql from 'graphql-tag';
import { remove } from 'lodash';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Route } from 'react-router-dom';
import ColorCoder, { Color } from '../../components/ColorCoder';
import DataTable from '../../components/DataTable';
import AddButton from '../../components/DataTable/DataTableToolbar/AddButton';
import { IDataTableColumn } from '../../components/DataTable/types';
import ItemImporter from '../../components/Item/ItemImporter';
import { Search, useSearchState } from '../../components/Search/Search';
import { itemBoilerlateFields } from '../ItemBoilerplates/ItemBoilerplate';
import CreateItemBoilerplateGroup from './CreateItemBoilerplateGroup';
import {
  DeleteItemBoilerplateGroup,
  DeleteItemBoilerplateGroupVariables,
} from './types/DeleteItemBoilerplateGroup';
import { getBoilerPlateGroups, getBoilerPlateGroupsVariables } from './types/getBoilerPlateGroups';
import {
  UpdateItemBoilerplateGroup,
  UpdateItemBoilerplateGroupVariables,
} from './types/UpdateItemBoilerplateGroup';

const itemBoilerplateGroupColumns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Name',
  },
];

const itemBoilerplateColumns: IDataTableColumn[] = [
  {
    id: 'materialColor',
    label: 'Farbe',
    render: (colorCode: Color) => colorCode && <ColorCoder colorCodings={[colorCode]} />,
  },
  {
    id: 'catalog',
    label: 'Katalog',
  },
  {
    id: 'catalogSection',
    label: 'Kapitel',
  },
  {
    id: 'catalogSubsection',
    label: 'Absatz',
  },
  {
    id: 'catalogMainGroup',
    label: 'Hauptgruppe',
  },
  {
    id: 'catalogSectionDescription',
    label: 'Kapitel-Beschreibung',
  },
  {
    id: 'catalogMainGroupDescription',
    label: 'Hauptgruppe-Beschreibung',
  },
  {
    id: 'catalogSubsectionDescription',
    label: 'Absatz-Beschreibung',
  },
  {
    id: 'productNumber',
    label: 'Produktnummer',
  },
  {
    id: 'descriptionOne',
    label: 'Beschreibung 1',
  },
  {
    id: 'descriptionTwo',
    label: 'Beschreibung 2',
  },
  {
    id: 'unit',
    label: 'Einheit',
  },
  {
    id: 'material',
    label: 'Verbrauchsmaterial',
  },
  {
    id: 'targetConsumptionPerUnit',
    label: 'Sollverbrauch / Einheit',
  },
  {
    id: 'acronym',
    label: 'Kurzbezeichnung',
  },
  {
    id: 'markingStyle',
    label: 'Markierart',
  },
  {
    id: 'dimensionOne',
    label: 'Dimension 1',
  },
  {
    id: 'dimensionTwo',
    label: 'Dimension 2',
  },
  {
    id: 'reflexion',
    label: 'Reflektion',
  },
  {
    id: 'timeRequired',
    label: 'Zeitbedarf',
  },
  {
    id: 'category',
    label: 'Kategorie',
  },
];

export const GET_ITEM_BOILERPLATE_GROUPS = gql`
  query getBoilerPlateGroups($search: SearchInput) {
    itemBoilerplateGroups(search: $search) {
      id
      name
      itemBoilerplates {
        ...itemBoilerlateFields
      }
    }
  }
  ${itemBoilerlateFields}
`;

const DELETE_ITEM_BOILERPLATE_GROUP = gql`
  mutation DeleteItemBoilerplateGroup($id: ID!) {
    deleteItemBoilerplateGroup(id: $id) {
      id
    }
  }
`;

const UPDATE_ITEM_BOILERPLATE_GROUP = gql`
  mutation UpdateItemBoilerplateGroup(
    $where: ItemBoilerplateGroupWhereUniqueInput!
    $data: ItemBoilerplateGroupUpdateInput!
  ) {
    updateItemBoilerplateGroup(where: $where, data: $data) {
      id
      name
    }
  }
`;

const toTableData = (element: any) => ({
  id: element.id,
  data: element,
});

const ItemBoilerplateGroups = () => {
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [showItemBoilerplateSearch, setShowItemBoilerplateSearch] = useState(false);

  const searchState = useSearchState();

  const { data, error, loading, refetch } = useQuery<
    getBoilerPlateGroups,
    getBoilerPlateGroupsVariables
  >(GET_ITEM_BOILERPLATE_GROUPS);
  const [deleteItemBoilerplateGroup] = useMutation<
    DeleteItemBoilerplateGroup,
    DeleteItemBoilerplateGroupVariables
  >(DELETE_ITEM_BOILERPLATE_GROUP, {
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      const result = cache.readQuery<getBoilerPlateGroups>({
        query: GET_ITEM_BOILERPLATE_GROUPS,
      });

      if (!result) {
        return;
      }

      remove(
        result.itemBoilerplateGroups,
        (itemBoilerplateGroup: any) =>
          itemBoilerplateGroup.id === data.deleteItemBoilerplateGroup.id,
      );
      cache.writeQuery({
        query: GET_ITEM_BOILERPLATE_GROUPS,
        data: { itemBoilerplateGroups: result.itemBoilerplateGroups },
      });
    },
  });
  const [updateItemBoilerplateGroup] = useMutation<
    UpdateItemBoilerplateGroup,
    UpdateItemBoilerplateGroupVariables
  >(UPDATE_ITEM_BOILERPLATE_GROUP, {
    refetchQueries: [{ query: GET_ITEM_BOILERPLATE_GROUPS }],
  });

  const createOpenItemBoilerplateSearch = (itemBoilerplateGroupId: string) => () => {
    setSelectedGroupId(itemBoilerplateGroupId);
    setShowItemBoilerplateSearch(true);
  };

  const createDeleteItemBoilerplateGroup = (id: string) => () =>
    deleteItemBoilerplateGroup({ variables: { id } });

  if (error) {
    console.log(error);
    return null;
  }

  if (!data) {
    return null;
  }

  const itemBoilerplateGroups =
    data.itemBoilerplateGroups?.map((group: any) => ({
      ...toTableData(group),
      innerTableRows: group.itemBoilerplates.map((item: any) =>
        toTableData({ ...item, catalog: item.catalog.useName, groupId: group.id }),
      ),
    })) ?? [];

  return (
    <>
      <DataTable
        containerRows={itemBoilerplateGroups}
        search={
          <Search
            columns={['name']}
            searchState={searchState}
            onSubmit={(search) => refetch({ search })}
            loading={loading}
          />
        }
        options={{
          filterText: searchState.searchTerm,
          tableName: 'BOILERPLATE_GROUPS',
          levels: [
            {
              columns: itemBoilerplateGroupColumns,
              rowActions: ({ row }) => (
                <>
                  <Tooltip title="Position hinzufügen">
                    <Fab
                      color="primary"
                      size="small"
                      onClick={createOpenItemBoilerplateSearch(row.id)}
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Löschen">
                    <Fab
                      color="primary"
                      size="small"
                      onClick={createDeleteItemBoilerplateGroup(row.id)}
                    >
                      <DeleteIcon />
                    </Fab>
                  </Tooltip>
                </>
              ),
            },
            {
              columns: itemBoilerplateColumns,
              rowActions: ({ row }) => (
                <Tooltip title="Löschen">
                  <IconButton
                    onClick={() =>
                      updateItemBoilerplateGroup({
                        variables: {
                          where: { id: row.data.groupId },
                          data: {
                            itemBoilerplates: { disconnect: [{ id: row.id }] },
                          },
                        },
                      })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ),
              actions: () => (
                <AddButton href="/vorlagen-gruppen/create" tooltip="Vorlagen-Gruppe Erstellen" />
              ),
            },
          ],
        }}
      />
      <ItemImporter
        fullWidth
        open={showItemBoilerplateSearch}
        onClose={() => setShowItemBoilerplateSearch(false)}
        onAddItems={(items) => {
          updateItemBoilerplateGroup({
            variables: {
              where: { id: selectedGroupId },
              data: { itemBoilerplates: { connect: items.map((i) => ({ id: i.rowId })) } },
            },
          });
          setShowItemBoilerplateSearch(false);
        }}
      />

      <Route path="/vorlagen-gruppen/create" component={CreateItemBoilerplateGroup} />
    </>
  );
};

export default ItemBoilerplateGroups;
