import React from 'react';
import { Field, Form, Formik } from 'formik';
import FormikTextField from '../Form/FormikTextField';
import HistorizedField from '../Form/HistorizedField';
import FormikSelect from '../Form/FormikSelect';
import { Box, Grid, MenuItem } from '@material-ui/core';
import { GetVehicle_vehicle } from '../../pages/Vehicles/types/GetVehicle';
import { mapHistoryItem } from '../History/history.utils';
import { FormikSwitch } from '../Form/FormikSwitch';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import * as Yup from 'yup';
import { getFormInitialValues, mapSubmittedValues } from './Vehicle.utils';
import SubsidiarySelect from '../../pages/Projects/TabDetails/SubsidiarySelect';
import { EditableHistoryItemFieldType, Role } from '../../types/graphql';
import { GET_VEHICLE } from '../../pages/Vehicles/vehicle.query';
import Dropezone from '../Form/UploadField/Dropzone';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';

interface IProps {
  onSubmit: (values: any, setSubmitting: (isSubmitting: boolean) => void) => void;
  initialValues?: GetVehicle_vehicle;
}

export enum VehicleCategory {
  ANHAENGER = 'Anhänger',
  BUS = 'Bus',
  PW = 'PW',
}

const validationSchema = Yup.object().shape({
  inventoryNumber: Yup.string().required('Pflichtfeld!'),
  internalHourlyWage: Yup.number().required('Pflichtfeld!'),
  nameOne: Yup.string().required('Pflichtfeld!'),
  category: Yup.string()
    .oneOf(Object.keys(VehicleCategory), 'Wert ist nicht gültig!')
    .required('Pflichtfeld!'),
});

const VehicleForm: React.FC<IProps> = ({ onSubmit, initialValues }) => {
  const isEdit = !!initialValues;

  const formInitialValues = getFormInitialValues(initialValues);

  const user = useLoggedInUser();

  const isFormDisabled = user?.role !== Role.SUPER_ADMIN;

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(mapSubmittedValues(values, formInitialValues), setSubmitting);
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isSubmitting }) => {
        return (
          <Form>
            <Grid container spacing={5}>
              <Grid item md={6} lg={3}>
                <Field
                  name="inventoryNumber"
                  label="Inventarnummer *"
                  component={FormikTextField}
                  disabled={isFormDisabled}
                />
                <Field
                  name="projectNumber"
                  label="Projektnummer"
                  component={FormikTextField}
                  disabled={isFormDisabled}
                />
                <Field
                  name="category"
                  label="Kategorie *"
                  component={FormikSelect}
                  disabled={isFormDisabled}
                >
                  {Object.entries(VehicleCategory).map(([key, value]) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Field>
                <SubsidiarySelect
                  disabled={isFormDisabled}
                  name="subsidiary"
                  history={mapHistoryItem(initialValues?.subsidiaryHistory ?? [])}
                  isEditable={!!initialValues}
                  entityId={initialValues?.id}
                  fieldType={EditableHistoryItemFieldType.VEHICLE_SUBSIDIARY}
                  refetchQueries={[
                    { query: GET_VEHICLE, variables: { where: { id: initialValues?.id } } },
                  ]}
                />
                <HistorizedField
                  disabled={isFormDisabled}
                  name="driver"
                  label="Fahrer"
                  component={FormikTextField}
                  isEditable={!!initialValues}
                  entityId={initialValues?.id}
                  fieldType={EditableHistoryItemFieldType.VEHICLE_DRIVER}
                  refetchQueries={[
                    { query: GET_VEHICLE, variables: { where: { id: initialValues?.id } } },
                  ]}
                  history={
                    initialValues?.driverHistory?.map(
                      ({ id, createdBy, validFrom, createdAt, value }) => ({
                        id,
                        date: createdAt,
                        user: createdBy.name,
                        value,
                        validFrom,
                      }),
                    ) ?? []
                  }
                />
                <Field
                  disabled={isFormDisabled}
                  name="nameOne"
                  label="Bezeichnung 1 *"
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="nameTwo"
                  label="Bezeichnung 2"
                  component={FormikTextField}
                />
              </Grid>

              <Grid item md={6} lg={3}>
                <Field
                  disabled={isFormDisabled}
                  name="releaseDate"
                  label="Jahrgang"
                  type="date"
                  shrink
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="registrationNumber"
                  label="Kennzeichen"
                  component={FormikTextField}
                />
                <HistorizedField
                  disabled={isFormDisabled}
                  name="kilometers"
                  type="number"
                  label="Kilometerstand"
                  component={FormikTextField}
                  history={mapHistoryItem(initialValues?.kilometersHistory ?? [])}
                />
                <Field
                  disabled={isFormDisabled}
                  name="internalHourlyWage"
                  label="Stundensatz intern *"
                  type="number"
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="weight"
                  label="Gewicht KG"
                  component={FormikTextField}
                  type="number"
                />
                <Field
                  name="payload"
                  label="Nutzlast KG"
                  component={FormikTextField}
                  type="number"
                  disabled={isFormDisabled}
                />
                <Field
                  disabled={isFormDisabled}
                  name="emissionsTestDate"
                  label="Abgasuntersuchung"
                  type="date"
                  shrink
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="trafficOfficeDate"
                  label="STVA Termin"
                  type="date"
                  shrink
                  component={FormikTextField}
                />
              </Grid>
              <Grid item md={6} lg={3}>
                <Field
                  disabled={isFormDisabled}
                  name="lastInternalCheckAt"
                  label="Letzte Prüfung intern"
                  type="date"
                  component={FormikTextField}
                  shrink
                />
                <Field
                  disabled={isFormDisabled}
                  name="serialNumber"
                  label="Stammnummer"
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="chassisNumber"
                  label="Fahrgestellnummer"
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="trailerLoad"
                  label="Anhängerlast"
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="fuelCardOne"
                  label="Tankkarte 1"
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="fuelCardTwo"
                  label="Tankkarte 2"
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="pinOne"
                  label="Pin 1"
                  component={FormikTextField}
                />
              </Grid>
              <Grid item md={6} lg={3}>
                <Field
                  disabled={isFormDisabled}
                  name="pinTwo"
                  label="Pin 2"
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="tcsAsof"
                  label="TCS ab"
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="eVignette"
                  label="E-Vignette"
                  component={FormikTextField}
                />
                <Field
                  disabled={isFormDisabled}
                  name="note"
                  label="Bemerkung"
                  component={FormikTextField}
                />

                {isEdit && (
                  <Box marginTop={4}>
                    <Field
                      disabled={isFormDisabled}
                      name="isDeactivated"
                      label="Inaktiviert"
                      color="primary"
                      component={FormikSwitch}
                    />
                  </Box>
                )}

                <Box marginTop={2}>
                  <Grid item md={6}>
                    <Field
                      disabled={isFormDisabled}
                      name="documents"
                      label="Dokumente"
                      initialFiles={initialValues?.documents ?? []}
                      component={Dropezone}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid item container justify="flex-end" md={12}>
                <CancelSaveButtons isDisabled={isSubmitting || !dirty || isFormDisabled} />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VehicleForm;
