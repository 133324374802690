import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

const ITEM_BOILERPLATE_TITLE_QUERY = gql`
  query ItemBoilerplateTitle($id: ID!) {
    itemBoilerplate(where: { id: $id }) {
      id
      productNumber
    }
  }
`;

const ItemBoilerplateTitle: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
  const { id } = match.params as any;

  return (
    <Query<any, any> query={ITEM_BOILERPLATE_TITLE_QUERY} variables={{ id }}>
      {({ loading, error, data }) => {
        if (loading) {
          return null;
        }

        if (error) {
          console.error(error);
          return null;
        }

        return data && <>Positionsvorlage - {data.itemBoilerplate.productNumber}</>;
      }}
    </Query>
  );
};

export default ItemBoilerplateTitle;
