import React from 'react';
import { Fab, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface IProps {
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    width: 72,
    height: 72,
  },
}));

export const HoveringFab: React.FC<IProps> = ({ children, disabled }) => {
  const styles = useStyles();

  return (
    <Fab size="large" color="primary" className={styles.fab} type="submit" disabled={disabled}>
      {children}
    </Fab>
  );
};
