import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography, Theme, makeStyles } from '@material-ui/core';
import { Formik, Form, FastField as Field } from 'formik';
import { GpsFields } from '../../../../components/GpsFields';
import { MobileCancelSaveButtons } from '../../CancelSaveButtons';
import { useQuery, useMutation } from 'react-apollo';
import { MOBILE_ITEM_DETAILS_QUERY } from './ItemDetails.queries';
import { UPDATE_ITEM_MUTATION } from '../../../../components/Item/ItemDetails';
import { UpdateItem, UpdateItemVariables } from '../../../../components/Item/types/UpdateItem';
import FormikTextField from '../../../../components/Form/FormikTextField';
import { MobileItemDetails, MobileItemDetailsVariables } from './types/MobileItemDetails';
import { DocumentViewer } from '../../DocumentViewer';
import { omit } from 'lodash';
import defaultNilInitialValues from '../../../../utils/form/initialValuesDefaults';
import { getChangedFormValues } from '../../../../utils/form/getChanged';

const useStyles = makeStyles((theme: Theme) => ({
  docViewerContainer: {
    padding: `${theme.spacing(2.5)}px 0`,
  },
}));

export const ItemDetails: React.FC = () => {
  const { itemId: id } = useParams();
  const history = useHistory();

  const classes = useStyles();

  const { data, loading, error } = useQuery<MobileItemDetails, MobileItemDetailsVariables>(
    MOBILE_ITEM_DETAILS_QUERY,
    { variables: { id } },
  );

  const [updateItem] = useMutation<UpdateItem, UpdateItemVariables>(UPDATE_ITEM_MUTATION, {
    refetchQueries: [{ query: MOBILE_ITEM_DETAILS_QUERY, variables: { id } }],
  });

  if (loading || !data) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  const initialValues = {
    ...defaultNilInitialValues(omit(data.item, ['id', 'documents', '__typename']), {
      comment: '',
      nodeCoordinate: '',
      startCoordinate: '',
      endCoordinate: '',
    }),
    documents: { uploads: [], deletedFiles: [] },
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">Position editieren</Typography>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await updateItem({
            variables: {
              id,
              data: getChangedFormValues({ initialValues, values, ignoreKeys: ['documents'] }),
            },
          });
          history.goBack();
        }}
      >
        {({ isSubmitting, dirty }) => {
          return (
            <Form>
              <Grid container>
                <Grid item xs={12}>
                  <GpsFields mobile />
                </Grid>

                <Grid item xs={12}>
                  <Field name="comment" label="Notiz" component={FormikTextField} />
                </Grid>

                <Grid item xs={12} className={classes.docViewerContainer}>
                  <Field
                    name="documents"
                    component={DocumentViewer}
                    initialFiles={data.item.documents}
                    accept="image/png, image/jpeg"
                  />
                </Grid>

                <Grid item container justify="flex-end" md={12}>
                  <MobileCancelSaveButtons isDisabled={isSubmitting || !dirty} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};
