import { IDataTableRow } from '../../types';

/**
 *
 * @param containerRows
 * @returns
 */
export function flattenContainerRows(containerRows: IDataTableRow[]) {
  const flattContainerRows: IDataTableRow[] = [];

  containerRows.forEach((c) => {
    flattContainerRows.push(c);
    if (c.containerRows && c.containerRows.length > 0) {
      flattContainerRows.push(...flattenContainerRows(c.containerRows));
    }
  });

  return flattContainerRows;
}

export default function findContainerTree(containerRows: IDataTableRow[], parentId: string) {
  const flatContainers = flattenContainerRows(containerRows);

  return flatContainers.find((c) => c.id === parentId);
}
