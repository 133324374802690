import gql from 'graphql-tag';

export const SELECTED_SUBSIDIARY_QUERY = gql`
  query Subsidiary {
    selectedSubsidiary @client
  }
`;

export const MISSION_QUERY = gql`
  query GetMissions(
    $where: MissionWhereInput
    $search: SearchInput
    $page: PageInput
    $orderBy: OrderByInput
  ) {
    missions(where: $where, search: $search, page: $page, orderBy: $orderBy) {
      id
      colorCodings
      name
      description
      extraInformation
      executionDate

      commentBranchLeaderToBranchLeader
      commentToBackoffice
      commentToEquipmentOperator
      commentEquipmentOperatorToBranchLeader

      isSunday
      isSaturday
      isNight
      isUrgent

      project {
        id
        colorCodings
        projectNumber
        projectName
        contactPerson
        status

        subsidiary {
          id
          name
        }

        createdAt

        publisher {
          id
          name
        }

        personResponsible {
          id
          name
        }

        catalog {
          id
          useName
        }

        staffTariff
        isSpecialProject
        commentToBranchLeader
        commentToBackoffice
        commentToExecutives
      }

      status {
        id
        missionStatus
        calendarWeek
        date
        untilDate
      }
      surfaceType
      externalServices
      expectedWorkingTime
      expectedEquipmentOps
      expectedStaffNumber
      callCustomer
      customerOnSite
      weatherType
      surfaceConditions
      temperature
      workTypes
      publisher {
        id
        name
      }

      createdAt
      updatedAt
    }
  }
`;

export const HAS_PROJECT_MISSIONS_QUERY = gql`
  query HasProjectMissions($projectNumber: String!) {
    project(where: { projectNumber: $projectNumber }) {
      id
      hasMissions
    }
  }
`;

export const HAS_PROJECT_BOQ_QUERY = gql`
  query HasProjectBoqs($projectNumber: String!) {
    project(where: { projectNumber: $projectNumber }) {
      id
      hasBillOfQuantities
    }
  }
`;
