import { useApolloClient, useQuery } from 'react-apollo';
import { difference, isEmpty, isNil, omit, pick } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import {
  ADD_ROW_TO_DATA_TABLE,
  filterRows,
  GET_DATA_TABLE,
  GET_PAGE_INFOS,
  SET_PAGE_INFO,
  UPDATE_DATA_TABLE,
  UPDATE_DATA_TABLE_ROW,
} from '../../../../../services/graphql-client';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../../../../services/types/AddRowToDataTable';
import { GetDataTable, GetDataTableVariables } from '../../../../../services/types/GetDataTable';
import { GetDataTables_dataTables } from '../../../../../services/types/GetDataTables';
import { GetPageInfos } from '../../../../../services/types/GetPageInfos';
import {
  UpdateDataTableVariables,
  UpdateDataTable,
} from '../../../../../services/types/UpdateDataTable';
import {
  BillStatus,
  DataTableRowInput,
  RowType,
  SearchInput,
  TableType,
} from '../../../../../types/graphql';
import { marshalRow } from '../../../../../utils/dataTable/marshalRow';
import { unmarshalDataTable } from '../../../../../utils/dataTable/unmarshalDataTable';
import { deepCopy } from '../../../../../utils/deepCopy';
import {
  PAGINATION_HELPERS,
  IRefetchItemsInContainerInput,
  IPaginationHelperFns,
} from '../../../../../utils/paginationHelpers';
import { PROJECT_BILLS_QUERY } from '../bill.queries';
import { ProjectBillsQuery } from '../types/ProjectBillsQuery';
import {
  GET_BILL_LOCATION,
  GET_ITEMS_CONNECTION,
  REFETCH_BILL_COMPUTED_FIELDS,
  REFETCH_BILL_ITEM_COMPUTED_FIELDS,
  SEARCH_BILLS_QUERY,
} from './helper.queries';
import { GetBillLocation, GetBillLocationVariables } from './types/GetBillLocation';
import {
  GetItemsConnection,
  GetItemsConnectionVariables,
  GetItemsConnection_billLocation_billItemsConnection,
  GetItemsConnection_billLocation_billItemsConnection_nodes,
} from './types/GetItemsConnection';
import { SearchBills } from './types/SearchBills';
import {
  RefetchBillComputedFields,
  RefetchBillComputedFieldsVariables,
} from './types/RefetchBillComputedFields';
import {
  UpdateDataTableRow,
  UpdateDataTableRowVariables,
} from '../../../../../services/types/UpdateDataTableRow';
import {
  RefetchBillItemComputedFields,
  RefetchBillItemComputedFieldsVariables,
} from './types/RefetchBillItemComputedFields';
import { isOneOfRowInputs } from '../../../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/refetchItemsInContainer';

type StructureBill = ProjectBillsQuery['project']['bills'][number];
type StructureDefaultLocation = ProjectBillsQuery['project']['bills'][number]['defaultLocation'];
type StructureLocationOne =
  ProjectBillsQuery['project']['bills'][number]['defaultLocation']['locations'][number];
type StructureLocationTwo =
  ProjectBillsQuery['project']['bills'][number]['defaultLocation']['locations'][number]['locations'][number];
type BillItemsConnection = GetItemsConnection_billLocation_billItemsConnection;
interface ILocationOne extends StructureLocationOne {
  billItemsConnection?: BillItemsConnection;
  locations: ILocationTwo[];
}
interface ILocationTwo extends StructureLocationTwo {
  billItemsConnection?: BillItemsConnection;
}
interface IDefaultLocation extends StructureDefaultLocation {
  billItemsConnection?: BillItemsConnection;
  locations: ILocationOne[];
}
interface IBill extends StructureBill {
  defaultLocation: IDefaultLocation;
}

export const marshalBillData = (
  bill: Omit<IBill, 'defaultLocation' | 'billOfQuantity'> & {
    defaultLocation: Pick<StructureDefaultLocation, 'hasDescendants'>;
  },
) => JSON.stringify({ ...bill, hasDescendants: bill.defaultLocation.hasDescendants });

export const mapToContainerRow = (bill: IBill): DataTableRowInput => {
  const mapBillItem = createMapBillItem(bill.status === BillStatus.FIXIERT);

  const row: DataTableRowInput = {
    __typename: 'DataTableRow',
    id: bill.defaultLocation.id,
    hidden: null,
    data: marshalBillData(bill),
    containerRows: [],
    innerTableRows: bill.defaultLocation.billItemsConnection?.nodes?.map(mapBillItem) ?? [],
  };

  for (const location1 of bill.defaultLocation.locations) {
    const location1Row: DataTableRowInput = {
      __typename: 'DataTableRow',
      id: location1.id,
      hidden: null,
      data: JSON.stringify(location1),
      containerRows: [],
      innerTableRows: location1.billItemsConnection?.nodes?.map(mapBillItem) ?? [],
    };

    for (const location2 of location1.locations) {
      location1Row.containerRows!.push({
        __typename: 'DataTableRow',
        id: location2.id,
        hidden: null,
        data: JSON.stringify(location2),
        containerRows: [],
        innerTableRows: location2.billItemsConnection?.nodes?.map(mapBillItem) ?? [],
      });
    }

    row.containerRows!.push(location1Row);
  }

  return row;
};

/**
 * maps bills to containerRows
 */
const mapToContainerRows = (bills: IBill[]): DataTableRowInput[] => {
  return bills.map(mapToContainerRow);
};

/**
 * maps billItem to dataTableRow
 */
export const createMapBillItem =
  (isFixedBill: boolean) =>
  (
    billItem: Readonly<GetItemsConnection_billLocation_billItemsConnection_nodes>,
  ): DataTableRowInput & { __typename: 'DataTableRow'; hidden: false } =>
    marshalRow({
      __typename: 'DataTableRow',
      id: billItem.id,
      hidden: false,
      data: {
        isPriceChangeable: !isFixedBill && billItem.scaleDiscounts.length > 0,
        ...pick(
          billItem,
          'id',
          'pricePerUnit',
          'amountVolume',
          'invoicedVolumeSum',
          'canBeDeleted',
        ),
        itemLocation: billItem.missionItem.item.location,
        'missionItem.comment': billItem.missionItem.comment,
        'missionItem.customerComment': billItem.missionItem.customerComment,
        'missionItem.mission.id': billItem.missionItem.mission!.id,
        'missionItem.mission.name': billItem.missionItem.mission!.name,
        'missionItem.mission.executionDate': billItem.missionItem.mission!.executionDate,
        'missionItem.item.productNumber': billItem.missionItem.item.productNumber,
        'missionItem.item.descriptionOne': billItem.missionItem.item.descriptionOne,
        'missionItem.item.descriptionTwo': billItem.missionItem.item.descriptionTwo,
        'missionItem.item.freeText': billItem.missionItem.item.freeText,
        'missionItem.item.unit': billItem.missionItem.item.unit,
        'missionItem.item.markingStyle': billItem.missionItem.item.markingStyle,
        'missionItem.item.applyScaleDiscount': billItem.missionItem.item.applyScaleDiscount,
        'missionItem.item.comment': billItem.missionItem.item.comment,
      },
      containerRows: [],
      innerTableRows: [],
    });

export const useBillData = (projectNumber: string) => {
  const client = useApolloClient();

  const { data } = useQuery<GetDataTable, GetDataTableVariables>(GET_DATA_TABLE, {
    variables: { id: TableType.BILL },
  });

  const { data: pageInfosData } = useQuery<GetPageInfos>(GET_PAGE_INFOS);
  const { loading, error } = useQuery<ProjectBillsQuery>(PROJECT_BILLS_QUERY, {
    variables: { projectNumber },
    fetchPolicy: 'network-only',
    skip: !!data?.dataTable?.rows && !isEmpty(data.dataTable.rows),
    onCompleted: async (fillData) => {
      if (!!data?.dataTable?.rows && !isEmpty(data.dataTable.rows)) {
        return;
      }

      await client.query<UpdateDataTable, UpdateDataTableVariables>({
        query: UPDATE_DATA_TABLE,
        variables: {
          where: { id: TableType.BILL },
          data: {
            rows: mapToContainerRows(fillData.project.bills ?? []),
          },
        },
      });
    },
  });

  const getContainerRows = useCallback((dataTable: GetDataTables_dataTables) => {
    return dataTable.rows.flatMap((row) => [
      row,
      ...(row.containerRows?.flatMap((containerRow) => [
        containerRow,
        ...(containerRow.containerRows ?? []),
      ]) ?? []),
    ]);
  }, []);

  /**
   * fetches next items writes rows into cache
   */
  const fetchItemsConnection = useCallback(
    async (variables: GetItemsConnectionVariables, overwrite?: boolean) => {
      const { data } = await client.query<GetItemsConnection, GetItemsConnectionVariables>({
        query: GET_ITEMS_CONNECTION,
        variables,
      });

      if (!data) {
        return;
      }

      const id = data.billLocation.id;
      const isFixedBill = data.billLocation.bill.isFixedBill;
      const billItemsConnection = data.billLocation.billItemsConnection;
      const hasNextPage = billItemsConnection.pageInfo!.hasNextPage;

      const { data: apolloBillTableData } = await client.query<GetDataTable, GetDataTableVariables>(
        {
          query: GET_DATA_TABLE,
          variables: {
            id: TableType.BILL,
          },
        },
      );

      if (!apolloBillTableData.dataTable) {
        return;
      }

      const dataTable = deepCopy(apolloBillTableData.dataTable);

      if (!dataTable) {
        console.error('You probably forgot to propagate current billView to cache');

        return;
      }

      const locationRows = getContainerRows(dataTable);
      const locationToAppendTo = locationRows.find((v) => v.id === id);
      if (!locationToAppendTo) {
        console.warn(`no location with id ${id} found`);

        return;
      }

      if (overwrite) {
        locationToAppendTo.innerTableRows = billItemsConnection.nodes.map(
          createMapBillItem(!!isFixedBill),
        );
      } else {
        locationToAppendTo.innerTableRows?.push(
          ...billItemsConnection.nodes.map(createMapBillItem(!!isFixedBill)),
        );
      }

      await client.query({
        query: SET_PAGE_INFO,
        variables: {
          data: {
            id,
            hasNextPage,
          },
        },
      });

      await client.query<AddRowToDataTable, AddRowToDataTableVariables>({
        query: ADD_ROW_TO_DATA_TABLE,
        variables: {
          where: { id: TableType.BILL },
          data: locationToAppendTo.innerTableRows!.map((row) => ({
            row,
            type: RowType.INNER,
            containerId: locationToAppendTo.id,
          })),
        },
      });

      await client.query<UpdateDataTable, UpdateDataTableVariables>({
        query: UPDATE_DATA_TABLE,
        variables: {
          data: { rows: dataTable.rows },
          where: { id: dataTable.id },
        },
      });
    },
    [client, getContainerRows],
  );

  /**
   * fetches bills matching search, and mutates hidden field on bill-row in cache
   */
  const onSearch = useCallback(
    async (searchInput: SearchInput) => {
      const res = await client.query<SearchBills>({
        query: SEARCH_BILLS_QUERY,
        variables: { search: searchInput },
      });

      if (!res.data) {
        return;
      }

      const billIdsMatchingSearch = res.data.bills.map((bill) => bill.defaultLocation.id);
      const billIds = data!.dataTable!.rows.map((row) => row.id);
      const { data: cacheRes } = await client.query<GetDataTable, GetDataTableVariables>({
        query: GET_DATA_TABLE,
        variables: { id: TableType.BILL },
      });

      if (!cacheRes.dataTable) {
        return;
      }

      const billIdsToEvict = difference(billIds, billIdsMatchingSearch);

      const mappedDataRows =
        deepCopy(cacheRes).dataTable?.rows.map((dataRow) => {
          const willBeHidden = billIdsToEvict.includes(dataRow.id);
          const isHidden = !!dataRow.hidden;

          if (isHidden && !willBeHidden) {
            dataRow.hidden = false;
          }

          if (!isHidden && willBeHidden) {
            dataRow.hidden = true;
          }

          return dataRow;
        }) ?? [];

      await client.query<UpdateDataTable, UpdateDataTableVariables>({
        query: UPDATE_DATA_TABLE,
        variables: {
          where: { id: TableType.BILL },
          data: {
            rows: mappedDataRows,
          },
        },
      });
    },
    [client, data],
  );

  const hasNextPage = useCallback<IPaginationHelperFns['hasNextPage']>(
    (rowId) => {
      if (!pageInfosData?.pageInfos) {
        return true;
      }

      return (
        pageInfosData.pageInfos.find((pageInfo) => pageInfo.id === rowId)?.hasNextPage ?? false
      );
    },
    [pageInfosData],
  );

  const addBillLocationRow = useCallback(
    async (billLocationId: string, containerId: string) => {
      const {
        data: { billLocation },
      } = await client.query<GetBillLocation, GetBillLocationVariables>({
        query: GET_BILL_LOCATION,
        variables: {
          where: {
            id: billLocationId,
          },
        },
      });

      await client.query<AddRowToDataTable, AddRowToDataTableVariables>({
        query: ADD_ROW_TO_DATA_TABLE,
        variables: {
          data: [
            {
              row: marshalRow({ id: billLocation.id, data: billLocation, hidden: false }),
              type: RowType.CONTAINER,
              containerId,
            },
          ],
          where: {
            id: TableType.BILL,
          },
        },
      });
    },
    [client],
  );

  // used to refetch amount of items which are already in specified container
  const refetchItemsInContainer = useCallback(
    async (refetchInputs: IRefetchItemsInContainerInput[]) => {
      const { data } = await client.query<GetDataTable, GetDataTableVariables>({
        query: GET_DATA_TABLE,
        variables: { id: TableType.BILL },
      });

      if (!data?.dataTable) {
        return;
      }

      const containerRows = getContainerRows(data.dataTable);
      const containerRowsToRefetch = containerRows.filter(isOneOfRowInputs(refetchInputs));
      const inputsToBuild = refetchInputs.filter(
        (input) => !containerRowsToRefetch.some((row) => row.id === input.containerId),
      );
      const l1InputsToBuild = inputsToBuild.filter(
        (input) => !!input.parent && !input.parent.parentContainerId,
      );
      const l2InputsToBuild = inputsToBuild.filter((input) => !!input.parent?.parentContainerId);
      const l2ParentsToBuild = l2InputsToBuild
        .filter((l2Input) => {
          const notInCurrentContainerRows = !containerRows.some(
            (row) => JSON.parse(row.data).id === l2Input.parent!.id,
          );
          const willNotBeBuildedByOther = !l1InputsToBuild.some(
            (l1Input) => l1Input.containerId !== l2Input.parent!.id,
          );

          return notInCurrentContainerRows && willNotBeBuildedByOther;
        })
        .map((l2Input) => {
          return l2Input.parent!;
        });

      // create bill-locations in cache if not present
      await Promise.all(
        l1InputsToBuild.map((l1Input) =>
          addBillLocationRow(l1Input.containerId, l1Input.parent!.id),
        ),
      );
      await Promise.all(
        l2ParentsToBuild.map((l2Parent) =>
          addBillLocationRow(l2Parent.id, l2Parent.parentContainerId!),
        ),
      );
      await Promise.all(
        l2InputsToBuild.map((l2Input) =>
          addBillLocationRow(l2Input.containerId, l2Input.parent!.id),
        ),
      );

      for (const { containerId } of refetchInputs) {
        const containerRow = containerRows.find((containerRow) => containerRow.id === containerId)!;

        // refetch count of items already residing in container
        // if container got added by methods above then let graphql schema decide default value
        const amountToRefetch = containerRow?.innerTableRows?.length;
        const maxItemsCount = 100;

        await fetchItemsConnection(
          {
            where: { id: containerId },
            take:
              amountToRefetch === 0 || isNil(amountToRefetch)
                ? undefined
                : Math.min(amountToRefetch, maxItemsCount),
          },
          true,
        );
      }
    },
    [client, fetchItemsConnection, addBillLocationRow, getContainerRows],
  );

  const refetchBillComputedFields = useCallback(
    async (billDefaultLocationId: string) => {
      const billId = billDefaultLocationId.split('-')[0];

      const { data } = await client.query<
        RefetchBillComputedFields,
        RefetchBillComputedFieldsVariables
      >({
        query: REFETCH_BILL_COMPUTED_FIELDS,
        variables: { where: { id: billId } },
      });

      if (!data) {
        return;
      }

      await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
        query: UPDATE_DATA_TABLE_ROW,
        variables: {
          where: { id: billDefaultLocationId, tableType: TableType.BILL },
          data: {
            partial: true,
            data: JSON.stringify({
              netBillSum: data.bill.netBillSum,
              grossBillSum: data.bill.grossBillSum,
            }),
          },
        },
      });
    },
    [client],
  );

  const refetchBillItemComputedFields = useCallback(
    async (billItemId: string) => {
      const { data } = await client.query<
        RefetchBillItemComputedFields,
        RefetchBillItemComputedFieldsVariables
      >({ query: REFETCH_BILL_ITEM_COMPUTED_FIELDS, variables: { where: { id: billItemId } } });

      if (!data) {
        return;
      }

      await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
        query: UPDATE_DATA_TABLE_ROW,
        variables: {
          where: { id: billItemId, tableType: TableType.BILL },
          data: {
            partial: true,
            data: JSON.stringify({
              ...omit(data.billItem, 'id', '__typename'),
            }),
          },
        },
      });
    },
    [client],
  );

  useEffect(() => {
    PAGINATION_HELPERS[projectNumber] = {
      ...PAGINATION_HELPERS[projectNumber],
      BILL: {
        fetchItemsConnection,
        hasNextPage,
        onSearch,
        refetchItemsInContainer,
        refetchBillComputedFields,
        refetchBillItemComputedFields,
      },
    };
  }, [
    fetchItemsConnection,
    hasNextPage,
    onSearch,
    refetchItemsInContainer,
    refetchBillComputedFields,
    refetchBillItemComputedFields,
    projectNumber,
  ]);

  const mappedData = useMemo(
    () =>
      data?.dataTable
        ? { dataTable: { id: 'BILL', rows: unmarshalDataTable(filterRows(data.dataTable)) } }
        : undefined,
    [data],
  );

  return {
    data: mappedData,
    loading,
    error,
    fetchItemsConnection,
    hasNextPage,
    onSearch,
    refetchItemsInContainer,
  };
};

export const buildBillLocationId = (billId: string, itemLocationId: string) =>
  `${billId}-${itemLocationId}`;
