import { useQuery } from 'react-apollo';
import { CURRENT_PROJECT_QUERY } from './Header.queries';

interface ICurrentProject {
  projectName: string;
  projectNumber: string;
}

export const useCurrentProject = (): ICurrentProject | null => {
  const { data, error } = useQuery(CURRENT_PROJECT_QUERY);

  if (error) {
    console.log(error);
    return null;
  }

  if (data && data.currentProject) {
    return data.currentProject;
  }

  return null;
};
