import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, RootRef, Theme } from '@material-ui/core';
import FormikTextField from '../../Form/FormikTextField';
import { GpsFields } from '../../GpsFields';
import Dropezone from '../../Form/UploadField/Dropzone';
import CancelSaveButtons from '../../Form/CancelSaveButtons';
import { makeStyles } from '@material-ui/styles';
import { DocumentViewer } from '../../../mobile/components/DocumentViewer';
import defaultNilInitialValues from '../../../utils/form/initialValuesDefaults';
import { useFocusField } from '../../../hooks/useFocusField';

interface IProps {
  initialValues: any;
  onSubmit: (values: any) => Promise<any>;
  mobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  dropzoneContainer: {
    marginTop: theme.spacing(1.5),
  },
}));

export const LocationForm: React.FC<IProps> = ({ initialValues, onSubmit, mobile }) => {
  // field will be focused and text selected
  const firstField = useFocusField(true);
  const classes = useStyles();

  const formInitialValues = {
    ...defaultNilInitialValues(
      { ...initialValues },
      {
        name: '',
        comment: '',
        nodeCoordinate: '',
        startCoordinate: '',
        endCoordinate: '',
      },
    ),
    documents: { uploads: [], deletedFiles: [] },
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid container item>
              <Grid item xs={12}>
                <RootRef rootRef={firstField}>
                  <Field name="name" label="Name" component={FormikTextField} />
                </RootRef>
              </Grid>

              <Grid item xs={12}>
                <GpsFields mobile={mobile} />
              </Grid>

              <Grid item xs={12}>
                <Field name="comment" label="Notiz" component={FormikTextField} />
              </Grid>
            </Grid>

            {mobile ? (
              <Field
                name="documents"
                component={DocumentViewer}
                initialFiles={initialValues.documents}
              />
            ) : (
              <Grid item xs={12} classes={{ root: classes.dropzoneContainer }}>
                <Field
                  name="documents"
                  initialFiles={initialValues.documents}
                  label="Dokumente"
                  component={Dropezone}
                />
              </Grid>
            )}

            <Grid item container justify="flex-end" md={12}>
              <CancelSaveButtons isDisabled={isSubmitting || !dirty} />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
