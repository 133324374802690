import { ActivityTypeCategory } from '../../../../types/graphql';
import { GetAccountingActivityTypes_activityTypes } from '../types/GetAccountingActivityTypes';

/**
 * @returns is activity type a time activity?
 * @param activityType the activity type
 */
export const timeActivityTypesCondition = (
  activityType: Readonly<GetAccountingActivityTypes_activityTypes>,
) =>
  [
    ActivityTypeCategory.PRODUKTIV,
    ActivityTypeCategory.BEZABSENZ,
    ActivityTypeCategory.UNBEZABSENZ,
  ].includes(activityType.category) && ![1011, 1012, 3005].includes(activityType.number);

/**
 * @returns filtered activity types: time items
 * @param activityTypes the activity types to filter
 */
export const filterTimeActivityTypes = (
  activityTypes: ReadonlyArray<Readonly<GetAccountingActivityTypes_activityTypes>>,
) => activityTypes.filter(timeActivityTypesCondition);

/**
 * @returns is activity type an impersonal activity?
 * @param activityType the activity type
 */
export const impersonalActivityTypesCondition = (
  activityType: Readonly<GetAccountingActivityTypes_activityTypes>,
) =>
  ![
    // Material einbuchen
    7002,
    // Fremdleistung Unterhalt
    8002,
    // Fremdmaterial Unterhalt
    8004,
  ].includes(activityType.number) &&
  [
    ActivityTypeCategory.FAHRZEUGE,
    ActivityTypeCategory.MASCHINEN,
    ActivityTypeCategory.MATERIAL,
    ActivityTypeCategory.FREMD,
  ].includes(activityType.category);

/**
 * @returns filtered activity types: impersonal items
 * @param activityTypes the activity types to filter
 */
export const filterImpersonalActivityTypes = (
  activityTypes: ReadonlyArray<Readonly<GetAccountingActivityTypes_activityTypes>>,
) => activityTypes.filter(impersonalActivityTypesCondition);

/**
 * @returns is activity type a bonus activity?
 * @param activityType the activity type
 */
export const bonusActivityTypesCondition = (
  activityType: Readonly<GetAccountingActivityTypes_activityTypes>,
) =>
  [ActivityTypeCategory.SPESEN, ActivityTypeCategory.ZUSCHLAG].includes(activityType.category) ||
  [3005].includes(activityType.number);

/**
 * @returns filtered activity types: bonus items
 * @param activityTypes the activity types to filter
 */
export const filterBonusActivityTypes = (
  activityTypes: ReadonlyArray<Readonly<GetAccountingActivityTypes_activityTypes>>,
) => activityTypes.filter(bonusActivityTypesCondition);

/**
 * @returns is activity type a driving followup activity?
 * @param activityType the activity type
 */
export const drivingActivityTypesCondition = (
  activityType: Readonly<GetAccountingActivityTypes_activityTypes>,
) => activityType.number === 1011;

/**
 * @returns filtered activity types: driving items
 * @param activityTypes the activity types to filter
 */
export const filterDrivingActivityTypes = (
  activityTypes: ReadonlyArray<Readonly<GetAccountingActivityTypes_activityTypes>>,
) => activityTypes.filter(drivingActivityTypesCondition);

/**
 * @returns is activity type a setup followup activity?
 * @param activityType the activity type
 */
export const setupActivityTypesCondition = (
  activityType: Readonly<GetAccountingActivityTypes_activityTypes>,
) => activityType.number === 1012;

/**
 * @returns filtered activity types: setup items
 * @param activityTypes the activity types to filter
 */
export const filterSetupActivityTypes = (
  activityTypes: ReadonlyArray<Readonly<GetAccountingActivityTypes_activityTypes>>,
) => activityTypes.filter(setupActivityTypesCondition);
