import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import AppProgress from '../../components/Page/AppProgress';
import { IDataTableColumn, IDataTableRow } from '../../components/DataTable/types';
import DataTable from '../../components/DataTable';
import { useHistory } from 'react-router-dom';
import AddButton from '../../components/DataTable/DataTableToolbar/AddButton';
import { GetOfferConditions } from './types/GetOfferConditions';
import {
  DELETE_OFFER_CONDITION_MUTATION,
  GET_OFFER_CONDITIONS_QUERY,
} from './OfferConditions.queries';
import { translateBool } from '../../utils/bool.translate.util';
import { offerConditionFields } from './types/offerConditionFields';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { GetOfferCondition_offerCondition } from './types/GetOfferCondition';
import { DeleteOfferCondition, DeleteOfferConditionVariables } from './types/DeleteOfferCondition';
import ConfirmationDialog from '../../components/ConfirmationDialog';

const columns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Kurzbezeichnung',
  },
  {
    id: 'default',
    label: 'Default',
  },
  {
    id: 'text',
    label: 'Bedingung',
  },
];

/**
 * map offer conditions to data table rows
 * @param offerConditions the offer condition objects
 * @returns data table rows
 * @see offerConditionFields
 * @see IDataTableRow
 */
const mapToDataTable = (offerConditions: offerConditionFields[]): IDataTableRow[] =>
  offerConditions.map((offerCondition) => ({
    id: offerCondition.id,
    data: {
      ...offerCondition,
      default: translateBool(offerCondition.default),
    },
  }));

/**
 * Component that displays a table of offer conditions with an add button and edit/delete actions per row.
 * @constructor
 */
export const OfferConditionsTable: React.FC = () => {
  const history = useHistory();

  const { data, error, loading } = useQuery<GetOfferConditions>(GET_OFFER_CONDITIONS_QUERY);

  // if deleteId is non null triggers a confirmation dialog that will ultimately execute the delete mutation
  const [deleteId, setDeleteId] = useState<GetOfferCondition_offerCondition['id'] | null>(null);
  const [deleteOfferCondition] = useMutation<DeleteOfferCondition, DeleteOfferConditionVariables>(
    DELETE_OFFER_CONDITION_MUTATION,
    {
      refetchQueries: [{ query: GET_OFFER_CONDITIONS_QUERY }],
    },
  );

  if (error) {
    console.log(error);
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <DataTable
        innerTableRows={mapToDataTable(data.offerConditions ?? [])}
        options={{
          tableName: 'ALL_OFFER_CONDITIONS',
          levels: [
            {
              columns,
              rowActions: ({ row }) => (
                <>
                  <Tooltip title="Editieren">
                    <IconButton
                      aria-label="Editieren"
                      onClick={() =>
                        history.push({ pathname: `/angebotsbedingungen/${row.id}/editieren` })
                      }
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Löschen">
                    <IconButton
                      aria-label="Löschen"
                      onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        setDeleteId(row.id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </>
              ),
              actions: () => (
                <>
                  <AddButton
                    href="/angebotsbedingungen/erstellen"
                    tooltip="Angebotsbedingung erstellen"
                  />
                </>
              ),
            },
          ],
        }}
      />
      <ConfirmationDialog
        message="Bitte beachten Sie, dass die Angebotsbedingung in allen Angeboten gelöscht wird."
        title="Angebotsbedingung löschen!"
        onConfirm={async () => {
          if (deleteId != null) {
            await deleteOfferCondition({
              variables: { where: { id: deleteId } },
            });
          }
        }}
        open={deleteId != null}
        onClose={() => setDeleteId(null)}
      />
    </>
  );
};
