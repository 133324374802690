import React from 'react';
import { useMutation } from 'react-apollo';
import { CREATE_VEHICLES_MUTATION, GET_VEHICLES } from './vehicle.query';
import PageWrapper from '../../components/PageWrapper';
import { omit } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { CreateVehicle, CreateVehicleVariables } from './types/CreateVehicle';
import VehicleForm from '../../components/Vehicle/VehicleForm';
import AppErrorMessage from '../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import { USE_COUNT_QUERIES } from '../../hooks/useCount/useCount.queries';

export const CreateVehiclePage: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation();

  const [createVehicle, { error }] = useMutation<CreateVehicle, CreateVehicleVariables>(
    CREATE_VEHICLES_MUTATION,
    {
      refetchQueries: [
        { query: GET_VEHICLES, variables: state.variables },
        ...(state.variables
          ? [{ query: USE_COUNT_QUERIES.VEHICLE, variables: omit(state.variables, 'page') }]
          : []),
      ],
    },
  );

  const handleClose = () => {
    history.goBack();
  };

  return (
    <>
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <PageWrapper header={{ title: 'Fahrzeug erstellen', onClose: handleClose }}>
        <VehicleForm
          onSubmit={async (data, setSubmitting) => {
            try {
              await createVehicle({ variables: { data } });
              setSubmitting(false);
              handleClose();
            } catch (e) {
              console.log(e);
              setSubmitting(false);
            }
          }}
        />
      </PageWrapper>
    </>
  );
};
