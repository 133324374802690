import React, { useCallback, useMemo } from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import DialogCloseable from '../Dialog/DialogCloseable';
import ItemForm from './ItemForm';
import gql from 'graphql-tag';
import createCurrencyFormatter from '../../utils/createCurrencyFormatter';
import { makeStyles } from '@material-ui/styles';
import { ITEM_FRAGMENT } from '../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/queries';
import { Item, ItemVariables } from './types/Item';
import { UpdateItem, UpdateItemVariables } from './types/UpdateItem';
import { PAGINATION_HELPERS } from '../../utils/paginationHelpers';
import { useProjectNumber } from '../../hooks/useProjectNumber';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import { getChangedFormValues } from '../../utils/form/getChanged';
import { isEmpty, isNil, uniq } from 'lodash';
import { executeForMissionTableTypes } from '../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/executeForMissionTypes';
import { removeTableTypeFromId } from '../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { executeForPopulatedBoqTableTypes } from '../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { useBoqEntityType } from '../../hooks/useBoqEntityType';

const formatCurrency = createCurrencyFormatter({ format: '0.00' });

interface IItemDetails extends DialogProps {
  itemId: string;
  refetchQueries?: any[];
}

const ITEM_DETAIL_FRAGMENT = gql`
  fragment itemDetailFields on Item {
    acronym
    appliedPrice
    applyScaleDiscount
    catalogMainGroup
    catalogMainGroupDescription
    catalogSection
    catalogSectionDescription
    catalogSubsection
    catalogSubsectionDescription
    catalog
    category
    color
    comment
    customerComment
    documents {
      id
      name
      path
      mimetype
    }
    descriptionOne
    descriptionTwo
    dimensionOne
    dimensionTwo
    freeText
    markingStyle
    isFinished
    material
    materialColor
    productNumber
    reflexion
    targetConsumptionPerUnit
    timeRequired
    type
    unit
    volume
    nodeCoordinate
    startCoordinate
    endCoordinate
  }
`;

const ITEM_QUERY = gql`
  query Item($id: ID!) {
    item(id: $id) {
      ...itemDetailFields
    }
  }
  ${ITEM_DETAIL_FRAGMENT}
`;

export const UPDATE_ITEM_MUTATION = gql`
  mutation UpdateItem($id: ID!, $data: UpdateItemInput!) {
    updateItem(id: $id, data: $data) {
      ...itemFields
      ...itemDetailFields
      missionItems {
        id
        mission {
          id
        }
      }
      bills {
        id
        defaultLocation {
          id
        }
      }
      billItems {
        id
      }
    }
  }
  ${ITEM_FRAGMENT}
  ${ITEM_DETAIL_FRAGMENT}
`;

export const useItemDialogStyles = makeStyles({
  dialog: {
    '& .MuiDialogContent-root': {
      overflowY: 'unset',
      overflowX: 'unset',
    },
  },
});

export const isItemComputedChange = (changedValues: any) =>
  !isNil(changedValues.appliedPrice) ||
  !isNil(changedValues.volume) ||
  !isNil(changedValues.applyScaleDiscount) ||
  !isNil(changedValues.materialColor) ||
  'type' in changedValues;

const ItemDetails: React.FunctionComponent<IItemDetails> = ({
  itemId,
  refetchQueries,
  ...dialogProps
}) => {
  const classes = useItemDialogStyles();
  const projectNumber = useProjectNumber();
  const client = useApolloClient();
  const normalizedItemId = removeTableTypeFromId(itemId);
  const boqEntityType = useBoqEntityType();

  const { data } = useQuery<Item, ItemVariables>(ITEM_QUERY, {
    variables: { id: normalizedItemId },
  });
  const [editItem] = useMutation<UpdateItem, UpdateItemVariables>(UPDATE_ITEM_MUTATION, {
    refetchQueries: [
      { query: ITEM_QUERY, variables: { id: normalizedItemId } },
      ...(refetchQueries ?? []),
    ],
  });

  const dataItem = data?.item;

  const initialValues = useMemo(
    () =>
      dataItem
        ? {
            ...dataItem,
            materialColor: dataItem.materialColor || '',
            type: dataItem.type || '',
            appliedPrice: !isNil(dataItem.appliedPrice)
              ? formatCurrency(dataItem.appliedPrice)
              : null,
          }
        : null,
    [dataItem],
  );

  const submitForm = useCallback(
    async (values: any) => {
      if (!initialValues || !projectNumber) {
        return;
      }

      const changedFormValues = getChangedFormValues({ initialValues, values });

      const { data } = await editItem({
        variables: { id: normalizedItemId, data: changedFormValues },
      });
      if (!data) {
        return;
      }

      const isComputedChange = isItemComputedChange(changedFormValues);
      executeForPopulatedBoqTableTypes(
        client,
        projectNumber,
        (tableType) => {
          PAGINATION_HELPERS[projectNumber][tableType]?.updateItem(data, isComputedChange);
        },
        boqEntityType,
      );

      const missionItems = data.updateItem.missionItems;
      const bills = data.updateItem.bills;
      const billItems = data.updateItem.billItems;

      const uniqMissionIds = uniq(missionItems.map((missionItem) => missionItem.mission!.id));
      if (!isEmpty(uniqMissionIds)) {
        executeForMissionTableTypes(async (tableType) => {
          missionItems.forEach((missionItem) =>
            PAGINATION_HELPERS[projectNumber][tableType]?.refetchMissionItemComputedFields(
              missionItem.id,
            ),
          );
          for (const missionId of uniqMissionIds) {
            await PAGINATION_HELPERS[projectNumber][tableType]?.updateComputedFields({
              id: missionId,
              project: { projectNumber },
            });
          }
        });
      }

      const billItemIds = billItems.map((billItem) => billItem.id);
      billItemIds.forEach((billItemId) => {
        PAGINATION_HELPERS[projectNumber].BILL?.refetchBillItemComputedFields(billItemId);
      });

      const uniqBillIds = uniq(bills.map((bill) => bill.defaultLocation.id));
      for (const billId of uniqBillIds) {
        PAGINATION_HELPERS[projectNumber].BILL?.refetchBillComputedFields(billId);
      }
    },
    [initialValues, editItem, projectNumber, client, normalizedItemId, boqEntityType],
  );

  if (!projectNumber || !data?.item || !initialValues) {
    return null;
  }

  return (
    <DialogCloseable
      title={'Position Editieren'}
      {...dialogProps}
      fullWidth
      maxWidth={false}
      fullHeight
      className={classes.dialog}
    >
      <ItemForm onSubmit={submitForm} onClose={dialogProps.onClose} initialValues={initialValues} />
    </DialogCloseable>
  );
};

export default ItemDetails;
