import gql from 'graphql-tag';
import { historyFieldsFragment } from '../../History/history.queries';

export const GET_MACHINE = gql`
  query Machine($inventoryNumber: String!) {
    machine(where: { inventoryNumber: $inventoryNumber }) {
      id
      inventoryNumber
      projectNumber
      subsidiary {
        id
        name
      }
      subsidiaryHistory {
        ...historyFields
      }
      registrationNumber
      rootNumber
      chassisNumber
      internalHourlyWage
      nameOne
      nameTwo
      driver
      releaseDate
      weight
      hoursOfOperation
      hoursOfOperationHistory {
        ...historyFields
      }
      driverHistory {
        ...historyFields
      }
      emissionsTestDate
      trafficOfficeTestDate
      lastInternalTestDate
      category
      skills
      note
      isDeactivated
      documents {
        id
        mimetype
        name
        nameHash
        path
      }
    }
  }
  ${historyFieldsFragment}
`;
