import React from 'react';
import FormikSelect from './Form/FormikSelect';
import { MenuItem } from '@material-ui/core';
import { Field, FieldConfig } from 'formik';
import { Color } from './ColorCoder';

const MaterialColorSelect: React.FunctionComponent<FieldConfig & { label?: string }> = ({
  name,
  label,
}) => (
  <Field name={name} label={label} component={FormikSelect}>
    <MenuItem value="">
      <em />
    </MenuItem>
    {Object.entries(Color).map(([colorKey, colorValue]) => (
      <MenuItem key={colorKey} value={colorKey}>
        {colorValue}
      </MenuItem>
    ))}
  </Field>
);

export default MaterialColorSelect;
