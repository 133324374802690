type PreventableEvent = Pick<Event, 'stopPropagation' | 'preventDefault'>;

function createPreventAll<T extends any[]>(
  cb: (e: PreventableEvent | undefined, ...args: T) => void,
) {
  return (e: PreventableEvent | undefined, ...args: T) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    cb(e, ...args);
  };
}

export default createPreventAll;
