import ApolloClient from 'apollo-client';
import { UPDATE_DATA_TABLE_ROW } from '../../../../../services/graphql-client';
import {
  UpdateDataTableRow,
  UpdateDataTableRowVariables,
} from '../../../../../services/types/UpdateDataTableRow';
import { MissionWhereInput, TableType } from '../../../../../types/graphql';
import { buildTableId } from './mapper';
import { MISSION_FETCH_COMPUTED_FIELDS } from './queries';
import {
  FetchMissionComputedFields,
  FetchMissionComputedFieldsVariables,
} from './types/FetchMissionComputedFields';

export const updateMissionComputedFields =
  (client: ApolloClient<any>) =>
  (tableType: TableType) =>
  async (missionWhere: MissionWhereInput) => {
    const {
      data: {
        missions: [mission],
      },
    } = await client.query<FetchMissionComputedFields, FetchMissionComputedFieldsVariables>({
      query: MISSION_FETCH_COMPUTED_FIELDS,
      variables: {
        mission: missionWhere,
      },
    });

    await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
      query: UPDATE_DATA_TABLE_ROW,
      variables: {
        where: {
          id: buildTableId(tableType)(missionWhere.id!.toString()),
          tableType,
        },
        data: {
          partial: true,
          data: JSON.stringify(mission),
        },
      },
    });
  };
