import { IDataTableRow } from '../../components/DataTable/types';
import { GetDataTable_dataTable } from '../../services/types/GetDataTable';
import { unmarshalRow } from './unmarshalRow';

export const unmarshalDataTable = (
  dataTable: GetDataTable_dataTable,
): Array<IDataTableRow<any, any, any>> => {
  return dataTable.rows.map((entity) => ({
    ...unmarshalRow(entity),
    innerTableRows: entity.innerTableRows?.map(unmarshalRow),
    containerRows: entity.containerRows?.map((location1) => ({
      ...unmarshalRow(location1),
      innerTableRows: location1.innerTableRows?.map(unmarshalRow),
      containerRows: location1.containerRows?.map((location2) => ({
        ...unmarshalRow(location2),
        innerTableRows: location2.innerTableRows?.map(unmarshalRow),
      })),
    })),
  }));
};
