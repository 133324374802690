import React from 'react';
import { DialogTitle, Dialog, DialogContent, MenuItem } from '@material-ui/core';
import { pickBy } from 'lodash';
import { Formik, Form, Field } from 'formik';
import FormikTextField from '../../Form/FormikTextField';
import FormikSelect from '../../Form/FormikSelect';
import CancelSaveButtons from '../../Form/CancelSaveButtons';
import * as Yup from 'yup';

const REASON_NOT_RECEIVED = 'NOT_RECEIVED';

interface IRejectedStatusDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (values: any) => void;
}

export enum RejectedReason {
  DONT_EXECUTE = 'Wird nicht ausgeführt',
  REPLACED = 'Wird ersetzt',
  NOT_RECEIVED = 'Nicht erhalten',
}

export enum NotReceived {
  SIGNAL = 'Signal',
  TRAUFFER = 'Trauffer',
  ROBERIT_LABOSIM = 'Roberit / Labosim ',
  KLEMMFIX = 'Klemmfix',
  PUENTENER = 'Püntener',
  DIVERSES = 'Diverses',
  UNBEKANNT = 'Unbekannt',
}

const validationSchema = Yup.object().shape({
  reason: Yup.string(),
  notReceived: Yup.string().when('reason', {
    is: (val) => val === REASON_NOT_RECEIVED,
    then: Yup.string().required('Nicht erhalten ist ein Pflichtfeld'),
  }),
});

const RejectedStatusDialog: React.FunctionComponent<IRejectedStatusDialogProps> = ({
  open,
  onClose,
  onSave,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Abgelehnt-Begründung</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            comment: '',
            reason: '',
            notReceived: '',
            effektivSum: '',
          }}
          onSubmit={(values: any) => {
            onSave(pickBy(values));
            onClose();
          }}
          validationSchema={validationSchema}
        >
          {(formikProps) => {
            return (
              <Form
                onSubmitCapture={(e) => {
                  formikProps.handleSubmit(e);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Field name="comment" label="Kommentar" component={FormikTextField} />
                <Field name="reason" label="Begründung" component={FormikSelect}>
                  <MenuItem value="" />
                  {Object.entries(RejectedReason).map(([key, val]) => (
                    <MenuItem key={key} value={key}>
                      {val}
                    </MenuItem>
                  ))}
                </Field>
                {formikProps.values.reason === REASON_NOT_RECEIVED && (
                  <>
                    <Field
                      name="effektivSum"
                      label="Effektive Vergabesumme"
                      component={FormikTextField}
                    />
                    <Field name="notReceived" label="Nicht erhalten" component={FormikSelect}>
                      <MenuItem value="" />
                      {Object.entries(NotReceived).map(([key, val]) => (
                        <MenuItem key={key} value={key}>
                          {val}
                        </MenuItem>
                      ))}
                    </Field>
                  </>
                )}
                <CancelSaveButtons isDisabled={formikProps.isSubmitting || !formikProps.dirty} />
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default RejectedStatusDialog;
