import { Checkbox, Grid, Typography } from '@material-ui/core';
import { isNil } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useLocation } from 'react-router-dom';
import { errorPrefixRemover } from '../../../../../utils/errorPrefixRemover';
import AppErrorMessage from '../../../../Page/AppErrorMessage';
import AppProgress from '../../../../Page/AppProgress';
import { CREATE_ITEMS_FROM_CATALOG } from './query';
import {
  CreateItemsFromCatalog,
  CreateItemsFromCatalogVariables,
} from './types/CreateItemsFromCatalog';

export const useImportCatalog = () => {
  const [isSelected, setIsSelected] = useState(false);
  const [executeImportCatalog, { loading, error }] = useMutation<
    CreateItemsFromCatalog,
    CreateItemsFromCatalogVariables
  >(CREATE_ITEMS_FROM_CATALOG);
  const location = useLocation();

  // dont show jsx when called within ItemBoilerplateGroups
  const isVisible = location.pathname !== '/vorlagen-gruppen';

  const toggleCheckbox = useCallback(() => {
    setIsSelected((v) => !v);
  }, []);

  return {
    executeImportCatalog,
    isImportCatalogSelected: isSelected,
    ImportCatalogJsx: isVisible ? (
      <>
        {loading && <AppProgress />}
        <AppErrorMessage
          isOpen={!isNil(error)}
          message={errorPrefixRemover(error?.message ?? '')}
        />
        <Grid container alignItems="center">
          <Typography variant="body1">Ganzen Katalog auswählen</Typography>
          <Checkbox value={isSelected} onChange={toggleCheckbox} color="primary" />
        </Grid>
      </>
    ) : (
      <></>
    ),
  };
};
