import gql from 'graphql-tag';

export const INVOICE_CONDITIONS_QUERY = gql`
  query InvoiceConditions($where: InvoiceConditionWhereInput!) {
    invoiceConditions(where: $where) {
      id
      sequenceNumber
      name
      percentageAmount
      absoluteAmount
      sum
      discount
      referenceSum
      reference {
        id
        sequenceNumber
      }
    }
  }
`;

export const CREATE_INVOICE_CONDITION_MUTATION = gql`
  mutation CreateInvoiceCondition(
    $data: InvoiceConditionCreateInput!
    $where: InvoiceConditionWhereInput!
  ) {
    createInvoiceCondition(data: $data, where: $where) {
      id
    }
  }
`;

export const UPDATE_INVOICE_CONDITION_MUTATION = gql`
  mutation UpdateInvoiceCondition(
    $data: InvoiceConditionUpdateInput!
    $where: InvoiceConditionWhereUniqueInput!
  ) {
    updateInvoiceCondition(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_INVOICE_CONDITION_MUTATION = gql`
  mutation DeleteInvoiceCondition($id: String!) {
    deleteInvoiceCondition(where: { id: $id }) {
      id
    }
  }
`;
