import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import ItemBoilerplateList from './ItemBoilerplateList';
import ItemBoilerplateGroupsList from './ItemBoilerplateGroupsList';

interface IProps {
  onAddItems: (items: any[]) => void;
}

const MobileItemImporter: React.FC<IProps> = ({ onAddItems }) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Einzelpositionen" />
        <Tab label="Vorlagengruppen" />
      </Tabs>

      {activeTab === 0 && <ItemBoilerplateList onSubmit={(values) => onAddItems(values)} />}

      {activeTab === 1 && <ItemBoilerplateGroupsList onSubmit={(values) => onAddItems(values)} />}
    </>
  );
};

export default MobileItemImporter;
