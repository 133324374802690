import { IFilterConfig } from '../../components/Filter';
import { MissionExtraInformation, MissionWorkTypes, Color } from '../../types/graphql';

export const missionFilterConfigs: { [k: string]: IFilterConfig } = {
  [MissionExtraInformation.D]: { label: 'Dringend', type: 'enum' },
  [MissionExtraInformation.SO]: { label: 'Sonntag', type: 'enum' },
  [MissionExtraInformation.SA]: { label: 'Samstag', type: 'enum' },
  [MissionExtraInformation.NA]: { label: 'Nacht', type: 'enum' },
  [Color.LIGHT_BLUE]: { label: 'Material KSP', type: 'enum' },
  [Color.GREEN]: { label: 'Material KP/A', type: 'enum' },
  [Color.GREY]: { label: 'Material KP/S', type: 'enum' },
  [MissionWorkTypes.GARAGE]: { label: 'Arbeitstyp Garage', type: 'enum' },
  [MissionWorkTypes.INNEN]: { label: 'Arbeitstyp Innen', type: 'enum' },
  [MissionWorkTypes.HALLE]: { label: 'Arbeitstyp Halle', type: 'enum' },
  isSignals: { label: 'Signale', type: 'boolean' },
  nextTwoWeeks: { label: 'In den nächsten zwei Wochen', type: 'boolean' },
};

export const mapMissionWhereClause = (filters: any) => {
  return Object.entries(filters).reduce((missionWhere: any, [key, filter]: any) => {
    if (key in MissionExtraInformation) {
      missionWhere.extraInformation
        ? missionWhere.extraInformation.push(key)
        : (missionWhere.extraInformation = [key]);
    }

    if (key in MissionWorkTypes) {
      missionWhere.workTypes ? missionWhere.workTypes.push(key) : (missionWhere.workTypes = [key]);
    }

    if (key in Color) {
      missionWhere.colorCodings
        ? missionWhere.colorCodings.push(key)
        : (missionWhere.colorCodings = [key]);
    }

    if (filter.type === 'boolean') {
      missionWhere[key] = true;
    }

    return missionWhere;
  }, {});
};
