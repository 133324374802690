import React, { useCallback, useState } from 'react';
import { Form, useField, useFormikContext } from 'formik';
import { useMutation, useQuery } from 'react-apollo';
import { Button, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Group } from './Group';
import { TimesContainer } from './Times';
import { ImpersonalContainer } from './Impersonal';
import { Summary } from './Summary';
import { IAccountLogggerFormValues } from '../types';
import { GetAccountingEmployees_employees } from '../types/GetAccountingEmployees';
import { GetAccountingMissions_missions } from '../types/GetAccountingMissions';
import { GetAccountingMaterials_activeMaterialCatalog_materials } from '../types/GetAccountingMaterials';
import { GetAccountingVehicles_vehicles } from '../types/GetAccountingVehicles';
import { GetAccountingActivityTypes_activityTypes } from '../types/GetAccountingActivityTypes';
import { GetAccountingMachines_machines } from '../types/GetAccountingMachines';
import { GetAccountingSubsidiaries_subsidiaries } from '../types/GetAccountingSubsidiaries';
import {
  GET_ACCOUNTING_LOG,
  REFRESH_ACCOUNTING_LOG,
  UPSERT_ACCOUNTING_LOG,
} from '../AccountingLogger.queries';
import { UpsertAccountingLog, UpsertAccountingLogVariables } from '../types/UpsertAccountingLog';
import AppProgress from '../../../../components/Page/AppProgress';
import AppErrorMessage from '../../../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../../utils/errorPrefixRemover';
import { AccountingItemInput, EmployeeFunctionAcronym } from '../../../../types/graphql';
import { getSearchableEmployee } from '../../utils/searchable';
import { PreviewAccountingLogItems_calculatePreviewItems } from '../types/PreviewAccountingLogItems';
import { GET_ACCOUNTING_LOGS } from '../../AccountingLogOverview/AccountingLogOverview.queries';
import { useHistory } from 'react-router-dom';
import { Section } from '../../Section';
import { RefreshAccountingLog, RefreshAccountingLogVariables } from '../types/RefreshAccountingLog';
import { tabIndexOffset as impersonalTabIndexOffset } from './Impersonal/ImpersonalContainer';
import isEmpty from 'lodash/fp/isEmpty';
import gql from 'graphql-tag';
import { uniq } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

interface IProps {
  accountingLogId?: string;
  onRefetchAccountingLog: () => void;
  subsidiaries: readonly GetAccountingSubsidiaries_subsidiaries[];
  employees: readonly GetAccountingEmployees_employees[];
  missions: readonly GetAccountingMissions_missions[];
  materials: readonly GetAccountingMaterials_activeMaterialCatalog_materials[];
  vehicles: readonly GetAccountingVehicles_vehicles[];
  machines: readonly GetAccountingMachines_machines[];
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
  calculatePreviewItems?: PreviewAccountingLogItems_calculatePreviewItems;
}

const WORK_HOUR_PLANS_QUERY = gql`
  query WorkHourPlans($employeeFunctions: [EmployeeFunctionAcronym!], $day: Date!) {
    getPlannedWorkTime(employeeFunctions: $employeeFunctions, day: $day)
  }
`;

/**
 * The accounting logger is an easy way for back office workers to log accounting items for an accounting day
 * and a group working on multiple missions
 * @constructor
 */
export const AccountingLoggerForm: React.FC<IProps> = React.memo(
  ({
    accountingLogId,
    onRefetchAccountingLog,
    employees,
    missions,
    materials,
    vehicles,
    machines,
    activityTypes,
    subsidiaries,
    calculatePreviewItems,
  }) => {
    const styles = useStyles();

    const { resetForm, errors, validateForm } = useFormikContext<IAccountLogggerFormValues>();
    const hasInputError = !isEmpty(errors);

    const history = useHistory();

    const [{ value: isFinal }] = useField<IAccountLogggerFormValues['isFinal']>('isFinal');
    const [{ value: date }] = useField<IAccountLogggerFormValues['date']>('date');
    const { setFieldValue } = useFormikContext();
    const [{ value: chief }] = useField<IAccountLogggerFormValues['chief']>('chief');
    const [{ value: logEmployees }] = useField<IAccountLogggerFormValues['employees']>('employees');
    const name = 'isCheck';
    const [{ value: isCheck }] = useField<IAccountLogggerFormValues['isCheck']>(name);
    const groupDisabled = isFinal || isCheck;

    const [{ value: timeEntries }] =
      useField<IAccountLogggerFormValues['timeEntries']>('timeEntries');
    const [{ value: individualTimeEntries }] =
      useField<IAccountLogggerFormValues['individualTimeEntries']>('individualTimeEntries');
    const canCheck =
      timeEntries.length > 1 ||
      individualTimeEntries.some((individual) => individual.entries.length > 1);

    const [upsertAccountingLog, { error, loading }] = useMutation<
      UpsertAccountingLog,
      UpsertAccountingLogVariables
    >(UPSERT_ACCOUNTING_LOG, {
      refetchQueries: () => [
        { query: GET_ACCOUNTING_LOGS },
        ...(accountingLogId != null && date
          ? [
              {
                query: GET_ACCOUNTING_LOG,
                variables: {
                  where: {
                    id: accountingLogId,
                  },
                  day: date,
                },
              },
            ]
          : []),
      ],
    });

    const [refreshAccountingLog, { loading: refreshLoading, error: refreshError }] = useMutation<
      RefreshAccountingLog,
      RefreshAccountingLogVariables
    >(REFRESH_ACCOUNTING_LOG);

    // we need to check for existing WorkHoursPlans and WorkHoursPlansItems for chosen chief and employees
    // and disable the submit button if any are missing; otherwise we get into an error state after submitting
    const chiefEmployeeFunction =
      chief &&
      employees.find((employee) => employee.employeeNumber === chief.split(' - ')[0])!.function!
        .acronym;
    const employeeFunctions = employees
      .filter((employee) =>
        logEmployees.find((logEmployee) => logEmployee.split(' - ')[0] === employee.employeeNumber),
      )
      .map((employee) => employee.function!.acronym);
    const combinedFunctions = uniq([
      ...(chiefEmployeeFunction ? [chiefEmployeeFunction] : []),
      ...(employeeFunctions ? employeeFunctions : []),
    ]);

    const { loading: planLoading, error: planError } = useQuery<
      number,
      { employeeFunctions: EmployeeFunctionAcronym[]; day: Date }
    >(WORK_HOUR_PLANS_QUERY, {
      variables: {
        employeeFunctions: combinedFunctions,
        day: new Date(date),
      },
    });

    const someLoading = loading || refreshLoading || planLoading;

    const onRefresh = useCallback(async () => {
      if (accountingLogId) {
        await refreshAccountingLog({
          variables: { where: { id: accountingLogId } },
        });
        await onRefetchAccountingLog();
      }
    }, [accountingLogId, refreshAccountingLog, onRefetchAccountingLog]);

    /*
     * when loading an existing log the form will start in check mode
     * when the user clicks edit the loaded preview items wont be valid anymore so we ignore them
     * i.e. there is only a state transition from false -> true when user clicks "bearbeiten"
     */
    const [ignoreCalculatePreviewItems, setIgnoreCalculatePreviewItems] = useState(
      calculatePreviewItems == null,
    );

    // the chief check is very shallow for performance reasons, however this should be fine  since the chief field is
    // a fill on blur autocomplete limited to ECs
    const disabled = groupDisabled || (chief ?? '') === '';

    const onCheck = useCallback(async () => {
      const errors = await validateForm();
      const hasInputErrorOnCheck = !isEmpty(errors);
      if (!hasInputErrorOnCheck) {
        setFieldValue(name, true, false);
      }
    }, [setFieldValue, validateForm]);

    const onEdit = useCallback(() => {
      if (!ignoreCalculatePreviewItems) {
        setIgnoreCalculatePreviewItems(true);
      }
      setFieldValue(name, false, false);
    }, [ignoreCalculatePreviewItems, setIgnoreCalculatePreviewItems, setFieldValue]);

    const onSubmit = useCallback(
      async (items: AccountingItemInput[]) => {
        const allEmployees = [chief, ...logEmployees]
          .map((employee) => getSearchableEmployee(employee, employees))
          .filter(<E,>(employee: E): employee is NonNullable<E> => employee != null)
          .map(({ id }) => ({ id }));
        const teamLeaderId = allEmployees[0]?.id;
        if (teamLeaderId == null) {
          return;
        }
        const variables = {
          teamLeaderId,
          data: {
            employees: allEmployees,
            reportDate: date,
            items,
          },
          where: accountingLogId != null ? { id: accountingLogId } : undefined,
        };
        const report = await upsertAccountingLog({
          variables,
        });

        if (report.data) {
          resetForm();
          history.push({
            pathname: `/buchungsjournal/rapport/${report.data.upsertReport.id}`,
          });
        }
      },
      [
        chief,
        date,
        employees,
        logEmployees,
        upsertAccountingLog,
        accountingLogId,
        history,
        resetForm,
      ],
    );

    return (
      <>
        {someLoading ? <AppProgress /> : null}
        {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
        {refreshError && <AppErrorMessage message={errorPrefixRemover(refreshError.message)} />}
        {planError && (
          <AppErrorMessage
            message={'Fehler beim Abrufen der Zeitpläne für ausgewählte/n Equipenchef/Mitarbeiter!'}
          />
        )}
        <Form>
          <Grid container direction="column" alignItems="flex-end" className={styles.container}>
            <Section>
              <Group employees={employees} disabled={groupDisabled} />
            </Section>
            <Section>
              <TimesContainer
                employees={employees}
                missions={missions}
                vehicles={vehicles}
                machines={machines}
                activityTypes={activityTypes}
                disabled={disabled}
                date={date}
              />
            </Section>
            <Section>
              <ImpersonalContainer
                missions={missions}
                materials={materials}
                activityTypes={activityTypes}
                vehicles={vehicles}
                machines={machines}
                disabled={disabled}
                date={date}
              />
            </Section>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={hasInputError || disabled || !canCheck || !!planError}
              onClick={onCheck}
              className={styles.button}
              // use a safe tab index to make the button the last tab in the flow
              tabIndex={impersonalTabIndexOffset * 10}
            >
              Rapport Prüfen
            </Button>
            {isCheck &&
              subsidiaries.length &&
              employees.length &&
              missions.length &&
              materials.length &&
              activityTypes.length &&
              vehicles.length &&
              machines.length && (
                <Section>
                  <Summary
                    disabled={someLoading}
                    onRefresh={accountingLogId ? onRefresh : undefined}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    subsidiaries={subsidiaries}
                    employees={employees}
                    missions={missions}
                    materials={materials}
                    activityTypes={activityTypes}
                    vehicles={vehicles}
                    machines={machines}
                    calculatePreviewItems={
                      ignoreCalculatePreviewItems ? undefined : calculatePreviewItems
                    }
                  />
                </Section>
              )}
          </Grid>
        </Form>
      </>
    );
  },
);
