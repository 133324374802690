import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DialogProps } from '@material-ui/core/Dialog';
import BillOfQuantityDetailsForm from '../../components/BillOfQuantity/BillOfQuantityDetailsForm';
import { BillOfQuantityEntityType } from '../../types/graphql';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: 800,
  },
});

type IProps = DialogProps & {
  onCreate: (...args: any) => Promise<any>;
  onClose: (...args: any) => void;
  entityType: BillOfQuantityEntityType;
};

const CreateDialog: React.FunctionComponent<IProps> = ({
  onCreate,
  onClose,
  entityType,
  ...dialogProps
}) => {
  const classes = useStyles();

  return (
    <Dialog {...dialogProps} classes={{ paper: classes.dialogPaper }} onClose={onClose}>
      <DialogContent>
        <BillOfQuantityDetailsForm
          entityType={entityType}
          onClose={onClose}
          onSubmit={onCreate}
          getAllValues
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateDialog;
