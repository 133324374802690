import {
  GetDataTable_dataTable,
  GetDataTable_dataTable_rows,
  GetDataTable_dataTable_rows_containerRows,
  GetDataTable_dataTable_rows_containerRows_containerRows,
} from '../../services/types/GetDataTable';

export const getAllContainerRows = (
  dataTable: GetDataTable_dataTable,
): Array<
  | GetDataTable_dataTable_rows
  | GetDataTable_dataTable_rows_containerRows
  | GetDataTable_dataTable_rows_containerRows_containerRows
> => {
  return dataTable.rows.flatMap((row) => [
    row,
    ...(row.containerRows?.flatMap((containerRow) => [
      containerRow,
      ...(containerRow.containerRows ?? []),
    ]) ?? []),
  ]);
};
