import ApolloClient from 'apollo-client';
import { TableType } from '../../../../../types/graphql';
import { HAS_PROJECT_MISSIONS_QUERY } from '../../../../Missions/queries';
import {
  HasProjectMissions,
  HasProjectMissionsVariables,
} from '../../../../Missions/types/HasProjectMissions';
import { getPopulatedMissionTableTypes } from './getPopulatedTableTypes';

export type MissionTableType = TableType.MISSION | TableType.MEASUREMENT | TableType.BILL_MISSION;
export const missionTableTypes: MissionTableType[] = [
  TableType.MISSION,
  TableType.MEASUREMENT,
  TableType.BILL_MISSION,
];

type CallbackFn<Result> = (tableType: MissionTableType) => Promise<Result>;

export const executeForMissionTableTypes = async <Result>(
  callback: CallbackFn<Result>,
): Promise<void> => {
  await Promise.all(missionTableTypes.map(callback));
};

const checkProjectHasMissions = async (
  client: ApolloClient<any>,
  projectNumber: string,
): Promise<boolean> => {
  const { data: hasMissionsData } = await client.query<
    HasProjectMissions,
    HasProjectMissionsVariables
  >({
    query: HAS_PROJECT_MISSIONS_QUERY,
    variables: { projectNumber },
  });

  return hasMissionsData.project.hasMissions;
};

/**
 * executes a callback with all mission-tableTypes if project has no missions
 * else only executes callback with all populated mission-tableTypes
 *
 * marks that project has items after completion of callbacks
 */
export const executeForPopulatedMissionTableTypes = async <Result>(
  client: ApolloClient<any>,
  projectNumber: string,
  callback: CallbackFn<Result>,
): Promise<void> => {
  const populatedTypes = getPopulatedMissionTableTypes(client);
  const projectHasMissions = await checkProjectHasMissions(client, projectNumber);
  const typesToExecuteCallbackOn = projectHasMissions ? populatedTypes : missionTableTypes;

  await Promise.all(typesToExecuteCallbackOn.map(callback));

  if (projectHasMissions) {
    return;
  }

  markProjectHasMissions(client, projectNumber);
};

const markProjectHasMissions = (client: ApolloClient<any>, projectNumber: string): void => {
  const data = client.readQuery<HasProjectMissions, HasProjectMissionsVariables>({
    query: HAS_PROJECT_MISSIONS_QUERY,
    variables: { projectNumber },
  });

  if (!data) {
    return;
  }

  client.writeQuery<HasProjectMissions, HasProjectMissionsVariables>({
    query: HAS_PROJECT_MISSIONS_QUERY,
    variables: { projectNumber },
    data: { project: { ...data.project, hasMissions: true } },
  });
};
