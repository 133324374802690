import React from 'react';
import { Form, Formik } from 'formik';
import { DataTableBody, DataTableHotKeysWrapper, ActionType } from '../DataTable';
import { IDataTableRow } from '../DataTable/types';
import { Search } from '../Search';
import { useSearchState } from '../Search/Search';

export const defaultMapMissionItems = (item: any) => ({
  id: item.id,
  data: {
    ...item,
    ...item.item,
    comment: item.item.comment,
    additionalText: item.comment,
    billOfQuantity: item.item.billOfQuantity?.name,
  },
});

export const DefaultMissionDataMapper = (mission: any) => ({
  ...mission,
  status: mission.status && mission.status.missionStatus,
});

export const defaultMapMissionsToTableData = (missions: any[]): IDataTableRow[] =>
  missions.map(
    (mission): IDataTableRow => ({
      id: `${mission.id}`,
      data: DefaultMissionDataMapper(mission),
      innerTableRows: mission.defaultMissionItemLocation.missionItems.map((missionItem: any) =>
        defaultMapMissionItems({ ...missionItem, executionDate: mission.executionDate }),
      ),
      containerRows: (mission.defaultMissionItemLocation.locations || []).map((location: any) => ({
        id: `${location.id}`,
        data: location,
        innerTableRows: location.missionItems.map((missionItem: any) =>
          defaultMapMissionItems({ ...missionItem, executionDate: mission.executionDate }),
        ),
        containerRows: (location.locations || []).map((location: any) => ({
          id: `${location.id}`,
          data: location,
          innerTableRows: location.missionItems.map((missionItem: any) =>
            defaultMapMissionItems({ ...missionItem, executionDate: mission.executionDate }),
          ),
        })),
      })),
    }),
  );

export const defaultGetItemsFromMissionLocation = (missionLocation: any): any[] => {
  return [
    ...missionLocation.missionItems,
    ...(missionLocation.locations
      ? missionLocation.locations.flatMap(defaultGetItemsFromMissionLocation)
      : []),
  ];
};

export const defaultGetFlatItems = (missions: any[]): any[] =>
  missions.flatMap(({ defaultMissionItemLocation }) =>
    defaultGetItemsFromMissionLocation(defaultMissionItemLocation),
  );

interface IProps {
  data: { flatItems: any; tableData: any; initialValues: any };
  loading: boolean;
  tableOptions: any;
  onSubmit?: (
    missionItems: any[],
    checkedRowIds: any[],
    locationIdsToAdd: string[],
  ) => Promise<any> | undefined;
  children?: ({ context, values }: any) => React.ReactNode;
  onSearchSubmit: (search: any) => Promise<any>;
}

const MissionSelector: React.FC<IProps> = ({
  data: { flatItems, tableData, initialValues },
  loading,
  tableOptions,
  onSubmit,
  children,
  onSearchSubmit,
}) => {
  const searchState = useSearchState();

  if (!flatItems || !tableData || !initialValues) {
    return null;
  }

  return (
    <>
      <DataTableHotKeysWrapper
        containerRows={tableData}
        search={
          <Search
            columns={['item.productNumber', 'item.descriptionOne', 'item.unit', 'comment']}
            searchState={searchState}
            onSubmit={onSearchSubmit}
            loading={loading}
          />
        }
        options={{
          ...tableOptions,
          fixedWidthColumns: true,
          filterText: searchState.searchTerm,
          isSelectableContainers: true,
        }}
      >
        {(context) => {
          return (
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const missionItemsWithValues = Object.entries(values).flatMap(
                  ([id, { amountVolume }]: [string, any]) => {
                    return {
                      id,
                      ...flatItems.find((flatItem: any) => flatItem.id === id),
                      amountVolume,
                    };
                  },
                );

                const itemsOfCheckedContainers =
                  flatItems.find((item: any) => {
                    return context.state.selectedContainerIds.includes(item.location.id);
                  }) ?? [];

                await onSubmit?.(
                  missionItemsWithValues,
                  [...context.checkedRowIds, ...itemsOfCheckedContainers],
                  context.state.selectedContainerIds,
                );

                setSubmitting(false);
                resetForm();
                context.dispatch({ type: ActionType.RESET_CHECKBOXES });
              }}
              enableReinitialize
            >
              {({ values }) => {
                return (
                  <>
                    <Form>
                      <DataTableBody context={context} />
                      {children && children({ context, values })}
                    </Form>
                  </>
                );
              }}
            </Formik>
          );
        }}
      </DataTableHotKeysWrapper>
    </>
  );
};

export default MissionSelector;
