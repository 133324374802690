import React from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { IconButton } from '@material-ui/core';

interface IProps {
  onClick: () => void;
  isOpen: boolean;
}

const ToggleContainerButton: React.FC<IProps> = ({ onClick, isOpen }) => {
  return (
    <IconButton onClick={onClick}>
      {isOpen ? <ArrowDropDownIcon fontSize="large" /> : <ArrowRightIcon fontSize="large" />}
    </IconButton>
  );
};

export default ToggleContainerButton;
