import { ICopyLocationDialogProps } from '../CopyLocationDialog/CopyLocationDialog';
import { IDataTableRow } from '../../DataTable/types';
import { IOptimisticReorder } from '../../../utils/mapBillOfQuantityToTableData';

export interface IBoqTableReducerState {
  showSelect: boolean;
  showCreateBoq: boolean;
  showCreateLocation: boolean;
  duplicateBillOfQuantityId: string;
  replaceItem: { id: string; productNumber: string };
  selectedLocationId: string;
  editRow: { rowId: string; newRowContainer: string };
  newRowData: any | null;
  currentItemBoilerplateId: string | null;
  editItemId: string;
  editLocationId: string;
  copyLocationState: null | Omit<ICopyLocationDialogProps, 'onClose'>;
  activeRowPath: IDataTableRow[];
  deleteLocation: { openDeleteDialog: boolean; locationId: string };
  showFinishedItems: boolean;
  optimisticReorder?: IOptimisticReorder;
}

export enum BoqTableActionType {
  TOGGLE_SELECT,
  TOGGLE_CREATE_BOQ,
  TOGGLE_CREATE_LOCATION,
  SET_DUPLICATE_BOQ_ID,
  SET_REPLACE_ITEM,
  SET_SELECTED_LOCATION_ID,
  SET_EDIT_ROW,
  SET_NEW_ROW_DATA,
  SET_CURRENT_ITEM_BOILERPLATE_ID,
  SET_EDIT_ITEM_ID,
  SET_EDIT_LOCATION_ID,
  SET_COPY_LOCATION_STATE,
  SET_ACTIVE_ROW_PATH,
  TOGGLE_DELETE_LOCATION,
  TOGGLE_SHOW_FINISHED_ITEMS,
  SET_OPTIMISTIC_REORDER,
}

export type BoqTableAction =
  | {
      type: BoqTableActionType.SET_OPTIMISTIC_REORDER;
      payload: { optimisticReorder?: IOptimisticReorder };
    }
  | { type: BoqTableActionType.TOGGLE_SELECT }
  | { type: BoqTableActionType.TOGGLE_CREATE_BOQ }
  | { type: BoqTableActionType.TOGGLE_CREATE_LOCATION }
  | { type: BoqTableActionType.SET_DUPLICATE_BOQ_ID; payload: { id: string } }
  | { type: BoqTableActionType.SET_REPLACE_ITEM; payload: { id: string; productNumber: string } }
  | { type: BoqTableActionType.SET_SELECTED_LOCATION_ID; payload: { id: string } }
  | { type: BoqTableActionType.SET_EDIT_ROW; payload: { rowId: string; newRowContainer: string } }
  | { type: BoqTableActionType.SET_NEW_ROW_DATA; payload: { data: any } }
  | { type: BoqTableActionType.SET_CURRENT_ITEM_BOILERPLATE_ID; payload: { id: string } }
  | { type: BoqTableActionType.SET_EDIT_ITEM_ID; payload: { id: string } }
  | { type: BoqTableActionType.SET_EDIT_LOCATION_ID; payload: { id: string } }
  | {
      type: BoqTableActionType.SET_COPY_LOCATION_STATE;
      payload: { data: null | Omit<ICopyLocationDialogProps, 'onClose'> };
    }
  | { type: BoqTableActionType.SET_ACTIVE_ROW_PATH; payload: { rowPath: IDataTableRow[] } }
  | {
      type: BoqTableActionType.TOGGLE_DELETE_LOCATION;
      payload: { deleteData: { openDeleteDialog: boolean; locationId: string } };
    }
  | {
      type: BoqTableActionType.TOGGLE_SHOW_FINISHED_ITEMS;
    };

export const BillOfQuantityTableReducer = (
  state: IBoqTableReducerState,
  action: BoqTableAction,
): IBoqTableReducerState => {
  switch (action.type) {
    case BoqTableActionType.TOGGLE_SELECT:
      return { ...state, showSelect: !state.showSelect };

    case BoqTableActionType.TOGGLE_CREATE_BOQ:
      return { ...state, showCreateBoq: !state.showCreateBoq };

    case BoqTableActionType.TOGGLE_CREATE_LOCATION:
      return { ...state, showCreateLocation: !state.showCreateLocation };

    case BoqTableActionType.SET_DUPLICATE_BOQ_ID:
      return { ...state, duplicateBillOfQuantityId: action.payload.id };

    case BoqTableActionType.SET_REPLACE_ITEM:
      return { ...state, replaceItem: action.payload };

    case BoqTableActionType.SET_SELECTED_LOCATION_ID:
      return { ...state, selectedLocationId: action.payload.id };

    case BoqTableActionType.SET_EDIT_ROW:
      return { ...state, editRow: action.payload };

    case BoqTableActionType.SET_NEW_ROW_DATA:
      return { ...state, newRowData: action.payload.data };

    case BoqTableActionType.SET_CURRENT_ITEM_BOILERPLATE_ID:
      return { ...state, currentItemBoilerplateId: action.payload.id };

    case BoqTableActionType.SET_EDIT_ITEM_ID:
      return {
        ...state,
        editItemId: action.payload.id,
      };

    case BoqTableActionType.SET_EDIT_LOCATION_ID:
      return { ...state, editLocationId: action.payload.id };

    case BoqTableActionType.SET_COPY_LOCATION_STATE:
      return { ...state, copyLocationState: action.payload.data };

    case BoqTableActionType.SET_ACTIVE_ROW_PATH:
      return { ...state, activeRowPath: action.payload.rowPath };

    case BoqTableActionType.TOGGLE_DELETE_LOCATION:
      return { ...state, deleteLocation: action.payload.deleteData };

    case BoqTableActionType.TOGGLE_SHOW_FINISHED_ITEMS:
      return { ...state, showFinishedItems: !state.showFinishedItems };

    case BoqTableActionType.SET_OPTIMISTIC_REORDER:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
