import keyBy from 'lodash/keyBy';
import { IChildrenRows, IDataTableRow, INavigation, IRowHierarchy, IRowNavigation } from '../types';

/** Merge container and inner table navigation together when both navigation arrays contain items. */
const mergeNavigations = (
  childrenRows: IChildrenRows,
  innerTableNavigation: IRowNavigation[],
  containerNavigation: IRowNavigation[],
): IRowNavigation[] => {
  const innerTableRowsCount = childrenRows.innerTableRows!.length;
  const lastInnerTableRowId = childrenRows.innerTableRows![innerTableRowsCount - 1].id;
  const firstContainerRowId = childrenRows.containerRows![0].id;

  const updatedInnerTableNavigation = innerTableNavigation.map((navItem) => ({
    ...navItem,
    next: navItem.row.id === lastInnerTableRowId ? firstContainerRowId : navItem.next,
  }));

  const updatedContainerNavigation = containerNavigation.map((navItem) => ({
    ...navItem,
    index: navItem.index + innerTableRowsCount,
    previous: navItem.row.id === firstContainerRowId ? lastInnerTableRowId : navItem.previous,
  }));

  return [...updatedInnerTableNavigation, ...updatedContainerNavigation];
};

const concatNavigations = (
  childrenRows: IChildrenRows,
  innerTableNavigation: IRowNavigation[],
  containerNavigation: IRowNavigation[],
): IRowNavigation[] => {
  if (containerNavigation.length > 0) {
    return innerTableNavigation.length > 0
      ? mergeNavigations(childrenRows, innerTableNavigation, containerNavigation)
      : containerNavigation;
  } else {
    return innerTableNavigation;
  }
};

/**
 * @return INavigation Creates navigation information (previous, next, parent... row ids) for every row with their children.
 * First the inner table rows are rendered, after that all container rows.
 */
export const rowNavigationChildren = (childrenRows: IChildrenRows): INavigation => {
  const innerTableNavigation = rowNavigation(childrenRows.innerTableRows || [], false);
  const containerNavigation = rowNavigation(childrenRows.containerRows || [], true);

  const concatedNavigationArray = concatNavigations(
    childrenRows,
    innerTableNavigation,
    containerNavigation,
  );

  return keyBy(concatedNavigationArray, (x) => x.row.id);
};

const rowHierarchy = (
  row: IDataTableRow,
  path: string[],
  isContainer: boolean,
): IRowHierarchy[] => {
  const children = [...(row.innerTableRows || []), ...(row.containerRows || [])];
  const currentHierarchy: IRowHierarchy = {
    row,
    path,
    children: children.map((x) => x.id),
    isContainer,
  };

  const childrenPath = [...path, row.id];
  const containerChildrenHierarchies = row.containerRows
    ? row.containerRows.map((row) => rowHierarchy(row, childrenPath, true))
    : [];
  const rowChildrenHierarchies = row.innerTableRows
    ? row.innerTableRows.map((row) => rowHierarchy(row, childrenPath, false))
    : [];
  return [currentHierarchy].concat(...rowChildrenHierarchies, ...containerChildrenHierarchies);
};

const rowNavigation = (rows: IDataTableRow[], isContainer: boolean): IRowNavigation[] => {
  const parentNodes = rows.map((row) => rowHierarchy(row, [], isContainer));
  const flatParentNodes = parentNodes.reduce((arr, x) => arr.concat(x), []);
  return flatParentNodes.map((hierarchy, index) => ({
    previous: index > 0 ? flatParentNodes[index - 1].row.id : null,
    next: index + 1 < flatParentNodes.length ? flatParentNodes[index + 1].row.id : null,
    index,
    ...hierarchy,
  }));
};

export const firstRowId = (navigation: INavigation): string =>
  Object.keys(navigation).find((id) => navigation[id].previous === null) || '';
