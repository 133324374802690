import { useQuery } from 'react-apollo';
import { GET_LOGGEDIN_USER } from '../pages/Login';
import { GetLoggedInUser } from '../pages/types/GetLoggedInUser';

export const useLoggedInUser = () => {
  const { data, loading, error } = useQuery<GetLoggedInUser>(GET_LOGGEDIN_USER);

  if (error) {
    console.log(error);
    return null;
  }

  if (!data || loading) {
    return null;
  }

  return data.auth;
};
