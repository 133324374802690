import { ApolloCache } from 'apollo-cache';
import { isEmpty } from 'lodash';
import { GET_DATA_TABLES } from '../../services/graphql-client';
import { GetDataTables } from '../../services/types/GetDataTables';
import { TableType } from '../../types/graphql';
import { deepCopy } from '../deepCopy';
import { getAllRows } from './getAllRows';

export const setAllRowsVisible = (cache: ApolloCache<any>, tableType: TableType): void => {
  const cacheRes = deepCopy(
    cache.readQuery<GetDataTables>({
      query: GET_DATA_TABLES,
    }),
  );

  if (!cacheRes?.dataTables) {
    return;
  }

  const dataTable = cacheRes.dataTables.find((dataTable) => dataTable.id === tableType);

  if (!dataTable) {
    return;
  }

  const rowsToUpdate = getAllRows(dataTable).filter((row) => row.hidden);

  if (isEmpty(rowsToUpdate)) {
    return;
  }

  rowsToUpdate.forEach((row) => {
    row.hidden = false;
  });

  cache.writeQuery({
    query: GET_DATA_TABLES,
    data: {
      dataTables: cacheRes.dataTables,
    },
  });
};
