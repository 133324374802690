import React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps, withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import { hasAccessRights } from '../utils';
import { Role } from '../enums';
import { GET_LOGGEDIN_USER } from '../pages/Login';

type IProps = RouteProps & RouteComponentProps & { role: Role };

const RestrictedRoute: React.FunctionComponent<IProps> = ({ role: requiredRole, ...rest }) => (
  <Query<any, any> query={GET_LOGGEDIN_USER}>
    {({
      data: {
        auth: { role },
      },
    }) => (hasAccessRights(role, requiredRole) ? <Route {...rest} /> : <Redirect to="/" />)}
  </Query>
);

export default withRouter(RestrictedRoute);
