import gql from 'graphql-tag';
import { isNil } from 'lodash';
import React from 'react';
import { Query, useMutation, useQuery } from 'react-apollo';
import AppErrorMessage from '../../../components/Page/AppErrorMessage';
import AppProgress from '../../../components/Page/AppProgress';
import PageWrapper from '../../../components/PageWrapper';
import ProjectDetailsForm from '../../../components/ProjectDetailsForm';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import { HAS_PROJECT_BOQ_QUERY } from '../../Missions/queries';
import { HasProjectBoqs, HasProjectBoqsVariables } from '../../Missions/types/HasProjectBoqs';
import { GetProject, GetProjectVariables } from './types/GetProject';
import { UpdateProject, UpdateProjectVariables } from './types/UpdateProject';

export const PROJECT_FIELDS_FRAGMENT = gql`
  fragment projectFields on Project {
    id
    catalog {
      id
      useName
    }
    createdAt
    updatedAt
    projectNumber
    projectName
    isSpecialProject
    hasSeparateFileStorage
    contactPerson
    customerInput
    status
    staffTariff
    colorCodings
    commentToBranchLeader
    commentToBackoffice
    commentToExecutives
    parent {
      id
      projectNumber
      projectName
    }
    subsidiary {
      id
      name
    }
    personResponsible {
      id
      name
    }
    publisher {
      id
      name
    }
    statusHistory {
      id
      status
      createdAt
      setBy
    }
  }
`;

export const GET_PROJECT = gql`
  query GetProject($projectNumber: String!) {
    project(where: { projectNumber: $projectNumber }) {
      ...projectFields
    }
  }
  ${PROJECT_FIELDS_FRAGMENT}
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($projectNumber: String!, $data: ProjectUpdateInput) {
    updateProject(where: { projectNumber: $projectNumber }, data: $data) {
      ...projectFields
    }
  }
  ${PROJECT_FIELDS_FRAGMENT}
`;

const TabDetails = ({ match }: any) => {
  const { projectNumber } = match.params;

  const [updateProject, { error: mutationError }] = useMutation<
    UpdateProject,
    UpdateProjectVariables
  >(UPDATE_PROJECT, { refetchQueries: [{ query: GET_PROJECT, variables: { projectNumber } }] });
  const { data: hasProjectBoqData } = useQuery<HasProjectBoqs, HasProjectBoqsVariables>(
    HAS_PROJECT_BOQ_QUERY,
    { variables: { projectNumber } },
  );

  const hasProjectBoqs = !!hasProjectBoqData?.project?.hasBillOfQuantities;

  const handleSubmit = (data: any) => updateProject({ variables: { projectNumber, data } });

  return (
    <>
      {mutationError && <AppErrorMessage message={errorPrefixRemover(mutationError.message)} />}
      {/* 
        fetchPolicy: 'network-only' because we cannot refetch GET_PROJECT from pdfPrint's useQuery. 
        So that it registers the new document added when PdfPrint STORE_IN_PROJECT is true 
      */}
      <Query<GetProject, GetProjectVariables>
        query={GET_PROJECT}
        fetchPolicy="network-only"
        variables={{ projectNumber }}
      >
        {({ data, loading, error }) => {
          if (!data?.project) {
            return null;
          }

          return (
            <>
              {loading && <AppProgress />}
              <AppErrorMessage
                isOpen={!isNil(error)}
                message={errorPrefixRemover(error?.message ?? '')}
              />
              <PageWrapper>
                <ProjectDetailsForm
                  project={data.project}
                  hasBoqs={hasProjectBoqs}
                  onSubmit={handleSubmit}
                />
              </PageWrapper>
            </>
          );
        }}
      </Query>
    </>
  );
};

export default TabDetails;
