import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';

export interface IMetaDataItem {
  key: string;
  value: string | React.ReactChild;
}

interface IMetaDataProps {
  metaDataItems: IMetaDataItem[];
  title?: string;
}

const MetaDataContainer = styled(Grid)({ margin: 4 });

const MetaData: React.FC<IMetaDataProps> = ({ metaDataItems, title }) => (
  <>
    {title && (
      <Typography variant="caption" color="textSecondary">
        {title}
      </Typography>
    )}
    <MetaDataContainer container direction="column" justify={'center'}>
      {metaDataItems.map((metaDataItem) => (
        <Grid container spacing={1} alignContent={'center'} key={metaDataItem.key}>
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {metaDataItem.key}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{metaDataItem.value}</Typography>
          </Grid>
        </Grid>
      ))}
    </MetaDataContainer>
  </>
);

export default MetaData;
