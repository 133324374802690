import gql from 'graphql-tag';

export const UPDATE_PROJECT_ADDRESS_MUTATION = gql`
  mutation UpdateProjectAddress($projectNumber: String!, $projectAddress: String!) {
    updateAddressBook(
      where: { projectNumber: $projectNumber }
      data: { projectAddress: $projectAddress }
    ) {
      id
      projectAddress
    }
  }
`;
