import React from 'react';
import { Field } from 'formik';
import FormikTextField from '../../Form/FormikTextField';
import { IStrategyProps } from './MissionStatus';

const UntilDateStrategy: React.FC<IStrategyProps> = ({ disabled }) => (
  <Field
    name="status.untilDate"
    label="Bis Datum"
    type="date"
    shrink
    component={FormikTextField}
    disabled={disabled}
  />
);

export default UntilDateStrategy;
