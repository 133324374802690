import gql from 'graphql-tag';

export const LOCATION_SELECT_QUERY = gql`
  query LocationSelect($billOfQuantityId: ID!) {
    billOfQuantity(where: { id: $billOfQuantityId }) {
      id
      name
      defaultLocation {
        id
        name
        locations {
          id
          name
          parentLocation {
            id
          }
          locations {
            id
            name
            parentLocation {
              id
            }
          }
        }
      }
    }
  }
`;
