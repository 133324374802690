import {
  ChargeConstraint,
  parseChargeConstraint,
} from '../../pages/AccountingLog/utils/chargeConstraint';

export const NA = 'rgb(255, 255, 255)';

export const COLORS = {
  1001: 'rgb(253, 150, 53)',
  1002: 'rgb(253, 150, 53)',
  1003: 'rgb(253, 150, 53)',
  1004: 'rgb(249, 214, 77)',
  1005: 'rgb(254, 200, 46)',
  1006: 'rgb(189, 189, 189)',
  1007: 'rgb(189, 189, 189)',
  1008: 'rgb(189, 189, 189)',
  1009: 'rgb(200, 84, 213)',
  1010: 'rgb(189, 189, 189)',
  1011: NA,
  2001: NA,
  2002: NA,
  2003: NA,
  2004: NA,
  3001: 'rgb(62, 59, 59)',
  3002: 'rgb(44, 134, 49)',
  3003: 'rgb(43, 152, 240)',
  3004: 'rgb(43, 152, 240)',
  3005: 'rgb(202, 202, 202)',
  3006: 'rgb(164, 164, 164)',
  3007: 'rgb(164, 164, 164)',
  3008: 'rgb(164, 164, 164)',
  3009: 'rgb(123, 123, 123)',
  3101: 'rgb(0, 0, 0)',
  3102: 'rgb(79, 173, 84)',
  3103: 'rgb(79, 173, 84)',
  3104: 'rgb(76, 200, 248)',
  3105: 'rgb(134, 214, 138)',
  4001: NA,
  4002: NA,
  4003: NA,
  4004: NA,
  4005: NA,
  4006: NA,
  5001: NA,
  5002: NA,
  6001: NA,
  7001: NA,
  7002: NA,
  8001: NA,
  8002: NA,
  8003: NA,
  8004: NA,
} as const;

/**
 * Get the color of an activity type number, if it exists
 * @param activityTypeNumber the activity type number
 * @returns the associated color or the default color if not available
 * @see NA
 */
export const activityTypeColorByNumber = (activityTypeNumber: number) =>
  COLORS[activityTypeNumber as keyof typeof COLORS] ?? NA;

/**
 * Get the color of an activity type, if it exists
 * @param activityType the activity type
 * @returns the associated color or the default color if not available
 * @see NA
 */
export const activityTypeColor = ({ number: activityTypeNumber }: { number: number }) =>
  activityTypeColorByNumber(activityTypeNumber);

/**
 * Get the color of an activity type associated to a charge constraint, if it exists
 * @param chargeConstraint the charge constraint to parse
 * @returns the associated color or the default color if not available
 * @see NA
 */
export const chargeConstraintColor = (chargeConstraint: ChargeConstraint | string) =>
  activityTypeColorByNumber(parseChargeConstraint(chargeConstraint).activityType);
