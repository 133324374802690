import { IDataTableRow } from '../../components/DataTable/types';
/**
 *
 * @param {IDataTableRow[]} rows // the rows got from table context
 *
 * Preserves structure of rows
 * **Only to be used in conjunction with handleDownloadCsvClickUtil**
 */
export const flattenRows = (rows: IDataTableRow[]): IDataTableRow[] => {
  return rows.reduce((acc, row) => {
    acc.push(row);

    if (row.innerTableRows && row.innerTableRows.length > 0) {
      acc.push(...flattenRows(row.innerTableRows));
    }

    return acc;
  }, [] as IDataTableRow[]);
};

export const flattenContainerRows = (rows: IDataTableRow[]): IDataTableRow[] => {
  return rows.reduce((acc, row) => {
    acc.push(row);

    if (row.containerRows && row.containerRows.length > 0) {
      acc.push(...flattenContainerRows(row.containerRows));
    }

    return acc;
  }, [] as IDataTableRow[]);
};
