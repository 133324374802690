import React, { useCallback, useState } from 'react';
import { DataEntry } from './DataEntry';
import { Preview } from './Preview';
import {
  GetAccountingLogJournal,
  GetAccountingLogJournal_accountingItemsWithStats_accountingItems,
  GetAccountingLogJournalVariables,
} from '../AccountingLogJournal/types/GetAccountingLogJournal';
import { useMutation, useQuery } from 'react-apollo';
import {
  UpsertAccountingLogItem,
  UpsertAccountingLogItemVariables,
} from './types/UpsertAccountingLogItem';
import { UPSERT_ACCOUNTING_LOG_ITEM } from './AccountingLogJournalManual.queries';
import AppProgress from '../../../components/Page/AppProgress';
import AppErrorMessage from '../../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import { GET_ACCOUNTING_LOG_JOURNAL } from '../AccountingLogJournal/AccountingLogJournal.queries';
import { Compare } from './Compare';
import { cleanupPreviewItem } from './utils/cleanupPreviewItem';
import { prepareUpsertItem } from './utils/prepareUpsertItem';

/**
 * Component for manual accounting
 * @constructor
 */
export const AccountingLogManual: React.FC = () => {
  const journalQuery = useQuery<GetAccountingLogJournal, GetAccountingLogJournalVariables>(
    GET_ACCOUNTING_LOG_JOURNAL,
  );

  const [previewItems, setPreviewItems] = useState(
    [] as ReadonlyArray<Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems>>,
  );
  const [editPreviewIndex, setEditPreviewIndex] = useState(undefined as number | undefined);
  const [parent, setParent] = useState<
    GetAccountingLogJournal_accountingItemsWithStats_accountingItems['id'] | undefined
  >(undefined);
  const addPreviewItem = useCallback(
    (item: GetAccountingLogJournal_accountingItemsWithStats_accountingItems) => {
      setPreviewItems([...previewItems, { ...item, id: previewItems.length }]);
      setParent(undefined);
    },
    [previewItems, setPreviewItems, setParent],
  );
  const editPreviewItem = useCallback(
    (idx: number) => {
      setEditPreviewIndex(idx);
    },
    [setEditPreviewIndex],
  );
  const updatePreviewItem = useCallback(
    (item: GetAccountingLogJournal_accountingItemsWithStats_accountingItems) => {
      if (editPreviewIndex == null) {
        return;
      }
      const edited = previewItems.slice(0);
      edited.splice(editPreviewIndex, 1, { ...item, id: editPreviewIndex });
      setPreviewItems(edited);
      setEditPreviewIndex(undefined);
      setParent(undefined);
    },
    [editPreviewIndex, previewItems, setPreviewItems, setParent],
  );
  const deletePreviewItem = useCallback(
    (deleteIdx: number) => {
      const removed = previewItems.slice(0);
      removed.splice(deleteIdx, 1);
      setPreviewItems(removed.map((item, idx) => ({ ...item, id: idx })));
    },
    [previewItems, setPreviewItems],
  );
  const clearPreviewItems = useCallback(() => {
    setPreviewItems([]);
  }, [setPreviewItems]);

  const [upsertAccountingItem, { error, loading }] = useMutation<
    UpsertAccountingLogItem,
    UpsertAccountingLogItemVariables
  >(UPSERT_ACCOUNTING_LOG_ITEM);

  const onSave = useCallback(async () => {
    const cleanedItems = previewItems.map(cleanupPreviewItem);
    await Promise.all(
      cleanedItems.map((data) =>
        upsertAccountingItem({
          variables: { data },
        }),
      ),
    );
    await journalQuery.refetch();
    clearPreviewItems();
  }, [journalQuery, upsertAccountingItem, clearPreviewItems, previewItems]);

  const [editExistingItem, setExistingItem] = useState<
    Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems> | undefined
  >(undefined);

  const onEdit = useCallback(
    (item: Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems>) => {
      window.scrollTo(0, 0);
      setExistingItem(item);
    },
    [setExistingItem],
  );

  const updateExistingItem = useCallback(
    async (item: GetAccountingLogJournal_accountingItemsWithStats_accountingItems) => {
      if (editExistingItem == null) {
        return;
      }
      const data = prepareUpsertItem(item, editExistingItem);

      await upsertAccountingItem({
        variables: {
          where: { id: item.id },
          data,
        },
      });
      await journalQuery.refetch();

      setExistingItem(undefined);
    },
    [journalQuery, editExistingItem, setExistingItem, upsertAccountingItem],
  );

  return (
    <>
      {loading ? <AppProgress /> : null}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <DataEntry
        onAdd={
          editExistingItem
            ? updateExistingItem
            : editPreviewIndex != null
            ? updatePreviewItem
            : addPreviewItem
        }
        disabled={loading}
        editItem={editExistingItem ?? previewItems[editPreviewIndex ?? -1]}
        parent={parent}
      />
      <Preview
        accountingItems={previewItems}
        onSave={onSave}
        onDelete={deletePreviewItem}
        onEdit={editPreviewItem}
        disabled={loading || editExistingItem != null}
      />
      <Compare
        disabled={loading || editPreviewIndex != null}
        onParent={setParent}
        onEdit={onEdit}
        query={journalQuery}
      />
    </>
  );
};
