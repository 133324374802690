import { isEmpty, last, sortBy } from 'lodash';
import { DataTableInput, DataTableRowInput, ItemType } from '../../../../../types/graphql';
import { translateBool } from '../../../../../utils/bool.translate.util';
import { marshalRow } from '../../../../../utils/dataTable/marshalRow';
import { IDataTableRow } from '../../../../DataTable/types';
import { compositeLocationId } from '../compositeLocationId';
import { BoqTableType } from './executeForBoqTypes';
import { billOfQuantityFields } from './types/billOfQuantityFields';
import {
  BillOfQuantityStructureQuery_billOfQuantities,
  BillOfQuantityStructureQuery_billOfQuantities_defaultLocation_locations,
} from './types/BillOfQuantityStructureQuery';
import { GetMoreBillOfQuantityItems_location_itemsConnection_nodes } from './types/GetMoreBillOfQuantityItems';

export const buildBoqTableRowId =
  (tableType: BoqTableType) =>
  (compositeId: string): string =>
    `${compositeId}-${tableType}`;

export const mapLocationToContainerRow =
  (
    billOfQuantity: Pick<BillOfQuantityStructureQuery_billOfQuantities, 'id'>,
    tableType: BoqTableType,
  ) =>
  (
    location: Omit<
      BillOfQuantityStructureQuery_billOfQuantities_defaultLocation_locations,
      'locations'
    > & {
      locations?: Array<
        Omit<BillOfQuantityStructureQuery_billOfQuantities_defaultLocation_locations, 'locations'>
      > | null;
    },
    index: number,
  ): DataTableRowInput => {
    const locations = location.locations!;

    const compositeId = compositeLocationId({
      billOfQuantityId: billOfQuantity.id,
      parentLocationId: location.parentLocation!.id,
      locationId: location.id,
    });
    const tableRowId = buildBoqTableRowId(tableType)(compositeId);

    return {
      __typename: 'DataTableRow',
      id: tableRowId,
      data: JSON.stringify({
        ...location,
        index,
      }),
      hidden: false,
      containerRows: locations?.map(mapLocationToContainerRow(billOfQuantity, tableType)) ?? [],
      innerTableRows: [],
    };
  };

export const mapBillOfQuantityToData = (
  billOfQuantity: billOfQuantityFields & { defaultLocation: { hasItemsOrLocations: boolean } },
): Record<string, any> => {
  const latestStatus = last(billOfQuantity.statuses)!;

  return {
    ...billOfQuantity,
    hasItemsOrLocations: billOfQuantity.defaultLocation.hasItemsOrLocations,
    createAt: new Date(billOfQuantity.createdAt).toLocaleDateString(),
    statuses: latestStatus.status,
    taskTypes: billOfQuantity.taskTypes.join(', '),
    hasScaleDiscount: translateBool(billOfQuantity.hasScaleDiscount),
  };
};

export const mapBillOfQuantityToContainerRow =
  (tableType: BoqTableType) =>
  (billOfQuantity: BillOfQuantityStructureQuery_billOfQuantities): DataTableRowInput => {
    const locations = billOfQuantity.defaultLocation.locations!;
    const tableRowId = buildBoqTableRowId(tableType)(billOfQuantity.defaultLocation.id);

    return {
      __typename: 'DataTableRow',
      id: tableRowId,
      data: JSON.stringify(mapBillOfQuantityToData(billOfQuantity)),
      hidden: false,
      containerRows: locations.map(mapLocationToContainerRow(billOfQuantity, tableType)),
      innerTableRows: [],
    };
  };

export const mapItemToInnerTableRow =
  (tableType: BoqTableType) =>
  (
    item: Partial<GetMoreBillOfQuantityItems_location_itemsConnection_nodes> & { id: string },
  ): DataTableRowInput => {
    const tableRowId = buildBoqTableRowId(tableType)(item.id);

    return marshalRow({
      id: tableRowId,
      hidden: null,
      data: {
        ...item,
        applyScaleDiscount: item.applyScaleDiscount
          ? translateBool(item.applyScaleDiscount)
          : undefined,
        type: item.type ? ItemType[item.type] : undefined,
      },
      containerRows: [],
      innerTableRows: [],
    });
  };

export const mapToDataTable = (
  billOfQuantities: BillOfQuantityStructureQuery_billOfQuantities[],
  tableType: BoqTableType,
): DataTableInput => {
  const rows: DataTableRowInput[] = billOfQuantities.map(
    mapBillOfQuantityToContainerRow(tableType),
  );

  return {
    rows,
  };
};

export const sortContainerRowsByIndex = (rows: IDataTableRow[]): IDataTableRow[] => {
  return sortBy(rows, 'data.index').map((row) => {
    if (!row.containerRows || isEmpty(row.containerRows)) {
      return row;
    }

    return {
      ...row,
      containerRows: sortContainerRowsByIndex(row.containerRows),
    };
  });
};
