export const toNum = (
  value: string | null | undefined,
  int: boolean = false,
): number | undefined => {
  if (value == null || value === '') {
    return undefined;
  }
  const num = Number(value);
  if (isNaN(num) || (int && num > Number.MAX_SAFE_INTEGER)) {
    return undefined;
  }
  return int ? Math.round(num) : num;
};
