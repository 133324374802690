import gql from 'graphql-tag';
import { billFields } from '../../../pages/Projects/TabBills/BillSelector/bill.queries';

export const BILL_TABLE_QUERY = gql`
  query BillTable(
    $subsidiaryId: ID
    $search: SearchInput
    $page: PageInput
    $orderBy: OrderByInput
  ) {
    bills(
      search: $search
      orderBy: $orderBy
      page: $page
      where: { project: { subsidiary: { id: $subsidiaryId } } }
    ) {
      ...billFields
      project {
        id
        projectNumber
        projectName
      }
    }
  }
  ${billFields}
`;
