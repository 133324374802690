import { Grid, MenuItem } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { pipe } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Noop } from '../../../types';
import { IDataTableOrder } from '../../DataTable/types';
import DialogCloseable, { IDialogCloseableProps } from '../../Dialog/DialogCloseable';
import CancelSaveButtons from '../../Form/CancelSaveButtons';
import FormikSelect from '../../Form/FormikSelect';

export interface IUseContainerSortDialogProps<AllowedField = string>
  extends Pick<IDialogCloseableProps, 'title' | 'open'> {
  onSubmit: (values: IContainerSortMapping<AllowedField>) => void;
  onClose: Noop;
  initialSortMapping: IContainerSortMapping<AllowedField>;
  fieldMapping: Array<IFieldMapping<AllowedField>>;
}
export interface IContainerSortMapping<AllowedField = string> {
  orderBy: AllowedField;
  direction: IDataTableOrder;
}
export interface IFieldMapping<AllowedField = string> {
  fieldName: AllowedField;
  displayName: string;
}
export const useContainerSortDialog = <AllowedFields extends string = string>({
  onSubmit,
  onClose,
  initialSortMapping,
  fieldMapping,
  ...dialogProps
}: IUseContainerSortDialogProps<AllowedFields>) => {
  const dialog = useMemo(
    () => (
      <DialogCloseable {...dialogProps} onClose={onClose}>
        <Formik initialValues={initialSortMapping} onSubmit={pipe(onSubmit, onClose)}>
          {({ dirty, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid container item xs={12}>
                  <Field name="orderBy" component={FormikSelect}>
                    {fieldMapping.map(({ fieldName, displayName }) => (
                      <MenuItem key={fieldName} value={fieldName}>
                        {displayName}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid container item xs={12}>
                  <Field name="direction" component={FormikSelect}>
                    <MenuItem key="asc" value="asc">
                      Aufsteigend
                    </MenuItem>
                    <MenuItem key="desc" value="desc">
                      Absteigend
                    </MenuItem>
                  </Field>
                </Grid>
                <Grid container item justify="flex-end" md={12}>
                  <CancelSaveButtons
                    isDisabled={!dirty || isSubmitting}
                    customSaveButtonLabel="Sortieren"
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogCloseable>
    ),
    [dialogProps, initialSortMapping, onSubmit, onClose, fieldMapping],
  );

  return {
    containerSortDialog: dialog,
  };
};
