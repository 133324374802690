import { CreateLocation_createLocation } from '../../components/BillOfQuantity/BllOfQuantityTable/types/CreateLocation';
import { DeleteItem } from '../../components/BillOfQuantity/BllOfQuantityTable/types/DeleteItem';
import { UpdateItemData } from '../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper';
import { MissionWhereInput, SearchInput, TableType } from '../../types/graphql';

type ProjectNumber = string;
export interface IRefetchItemsInContainerInput {
  containerId: string;
  parent?: { id: string; parentContainerId?: string };
}

export interface IPaginationHelperFns<
  RefetchItemsInContainerVariables extends Record<string, any> = any,
> {
  fetchItemsConnection: (variables: any) => Promise<void>;
  hasNextPage: (id: string) => boolean;
  onSearch: (searchInput: SearchInput, showFinishedItems: boolean) => Promise<void>;
  refetchItemsInContainer: (
    data: IRefetchItemsInContainerInput[],
    variables?: RefetchItemsInContainerVariables,
  ) => Promise<void>;
}

export type IPaginationHelper = Record<
  Exclude<TableType, TableType.ORDER | TableType.MISSION>,
  IPaginationHelperFns
> & {
  [TableType.OFFER]: IPaginationHelperFns & {
    updateItem: (data: UpdateItemData, isComputedChange: boolean) => Promise<void>;
    removeItemFromCache: ({ data }: { data: DeleteItem }) => Promise<void>;
    addLocationToCache: (data: CreateLocation_createLocation) => Promise<void>;
    refetchItemsComputedFields: (idIn: string[], billOfQuantityId: string) => Promise<void>;
    updateBillOfQuantityComputedFields: (id: string) => Promise<void>;
  };
  [TableType.ORDER]: IPaginationHelperFns & {
    updateItem: (data: UpdateItemData, isComputedChange: boolean) => Promise<void>;
    removeItemFromCache: ({ data }: { data: DeleteItem }) => Promise<void>;
    addLocationToCache: (data: CreateLocation_createLocation) => Promise<void>;
    refetchItemsComputedFields: (idIn: string[], billOfQuantityId: string) => Promise<void>;
    updateBillOfQuantityComputedFields: (id: string) => Promise<void>;
  };
  [TableType.ORDER_MISSION]: IPaginationHelperFns & {
    updateItem: (data: UpdateItemData, isComputedChange: boolean) => Promise<void>;
    removeItemFromCache: ({ data }: { data: DeleteItem }) => Promise<void>;
    addLocationToCache: (data: CreateLocation_createLocation) => Promise<void>;
    refetchItemsComputedFields: (idIn: string[], billOfQuantityId: string) => Promise<void>;
    updateBillOfQuantityComputedFields: (id: string) => Promise<void>;
  };
  [TableType.ORDER_MEASUREMENT]: IPaginationHelperFns & {
    updateItem: (data: UpdateItemData, isComputedChange: boolean) => Promise<void>;
    removeItemFromCache: ({ data }: { data: DeleteItem }) => Promise<void>;
    addLocationToCache: (data: CreateLocation_createLocation) => Promise<void>;
    refetchItemsComputedFields: (idIn: string[], billOfQuantityId: string) => Promise<void>;
    updateBillOfQuantityComputedFields: (id: string) => Promise<void>;
  };
} & {
  [TableType.MISSION]: IPaginationHelperFns & {
    updateComputedFields: (mission: MissionWhereInput) => Promise<void>;
    updateMissionItem: (rowId: string, values: any, isComputedChange: boolean) => void;
    refetchMissionItemComputedFields: (missionItemId: string) => Promise<void>;
  };
  [TableType.MEASUREMENT]: IPaginationHelperFns & {
    updateComputedFields: (mission: MissionWhereInput) => Promise<void>;
    updateMissionItem: (rowId: string, values: any, isComputedChange: boolean) => void;
    refetchMissionItemComputedFields: (missionItemId: string) => Promise<void>;
  };
  [TableType.BILL_MISSION]: IPaginationHelperFns & {
    updateComputedFields: (mission: MissionWhereInput) => Promise<void>;
    updateMissionItem: (rowId: string, values: any, isComputedChange: boolean) => void;
    refetchMissionItemComputedFields: (missionItemId: string) => Promise<void>;
  };
  [TableType.BILL]: IPaginationHelperFns & {
    refetchBillComputedFields: (billDefaultLocationId: string) => Promise<void>;
    refetchBillItemComputedFields: (billItemId: string) => Promise<void>;
  };
};
export const PAGINATION_HELPERS: Record<ProjectNumber, IPaginationHelper> = {};
