import { IWithError, someError } from './someError';
import { IWithLoading, someLoading } from './someLoading';

/**
 * Combine multiple query indicators
 * @param statefuls an object that indicates apollo state
 * @returns [loading: boolean, error: boolean] tuple of loading and error states
 */
export const someState = (
  ...statefuls: ReadonlyArray<Readonly<IWithError & IWithLoading>>
): [boolean, boolean] => [someLoading(...statefuls), someError(...statefuls)];
