import React, { useCallback, useState } from 'react';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import ToggleContainerButton from './ToggleContainerButton';
import { lighten } from '@material-ui/core/styles';

interface IProps {
  name: string;
  onSelect: () => void;
  hasChildren: boolean;
  isSelected?: boolean;
  label?: string;
  isDefaultClosed?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: theme.spacing(2),
  },
  childrenWrapper: {
    paddingLeft: theme.spacing(1.25),
    borderLeft: `2px dashed ${theme.palette.grey[600]}`,
  },
  container: {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.grey[300],
    borderRadius: '4px',
    padding: theme.spacing(2, 2),
  },
  selected: {
    boxShadow: 'unset',
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  disabled: {
    opacity: 0.5,
  },
}));

const Container: React.FC<IProps> = ({
  name,
  onSelect,
  isSelected,
  label,
  children,
  hasChildren,
  isDefaultClosed,
  disabled,
}) => {
  const classes = useStyles();

  // if isDefaultClosed is set explicitly, use it's value, otherwise base the decision on whether the component has children
  const [open, setOpen] = useState(isDefaultClosed == null ? hasChildren : !isDefaultClosed);

  const containerClassName = classnames(classes.container, {
    [classes.selected]: isSelected,
    [classes.disabled]: disabled,
  });

  const onClick = useCallback(() => !disabled && onSelect(), [onSelect, disabled]);

  return (
    <Grid container item xs={12} className={classes.wrapper}>
      <Grid
        item
        xs={12}
        container
        justify="center"
        className={containerClassName}
        onClick={onClick}
      >
        <Grid item xs={2}>
          {hasChildren && <ToggleContainerButton isOpen={open} onClick={() => setOpen(!open)} />}
        </Grid>
        <Grid item container xs={10} alignContent="center">
          {label && (
            <Grid item xs={12}>
              <Typography variant="body2">{label}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body1">{name}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {open && (
        <Grid item xs={12} className={classes.childrenWrapper}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default Container;
