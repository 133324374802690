import React from 'react';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Icon from '@mdi/react';
import classnames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { BOQType, BOQTaskTypes } from '../../../types/graphql';
import ColorCoder, { Color } from '../../../components/ColorCoder';
import { mdiSale } from '@mdi/js';
import { translateBool } from '../../../utils/bool.translate.util';

interface IProps {
  id: string;
  name: string;
  createdAt: string;
  colorCodings: Color[];
  status: string;
  taskTypes: BOQTaskTypes[];
  type: BOQType;
  sumNet: number;
  sumGross: number;
  skontoDeadline: number;
  paymentDeadline: number;
  hasScaleDiscount: boolean;
  isSelected: boolean;
  onSelect: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.grey[200],
    borderRadius: '10px',
  },
  selectedContainer: {
    backgroundColor: lighten(theme.palette.primary.light, 0.9),
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: '0 0 10px lightgrey',
  },
  scaleDiscountsIcon: {
    fill: theme.palette.action.active,
  },
  grey: {
    color: theme.palette.grey[600],
  },
  spaceLeft: {
    marginLeft: theme.spacing(0.5),
  },
}));

export const BillOfQuantity: React.FC<IProps> = ({ onSelect, isSelected, ...props }) => {
  const classes = useStyles();

  const paperRootClassName = classnames(classes.container, {
    [classes.selectedContainer]: isSelected,
  });

  return (
    <Grid
      container
      item
      spacing={2}
      classes={{ root: paperRootClassName }}
      onClick={() => !isSelected && onSelect(props.id)}
    >
      <Grid item container xs={12}>
        <Grid item container xs={12} justify="space-between">
          <Grid item xs={4}>
            <Typography variant="h6">{props.name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <ColorCoder colorCodings={props.colorCodings} />
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Typography variant="caption" className={classes.grey}>
            erstellt am:
          </Typography>
          <Typography variant="caption" className={classes.spaceLeft}>
            {props.createdAt}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.grey}>
              Status:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{props.status}</Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.grey}>
              Arbeitsgattung:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{props.taskTypes.join(', ')}</Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.grey}>
              Typ:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{props.type}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        <Grid item xs={6}>
          <Typography variant="caption">Summe</Typography>
          <Typography variant="body1">{props.sumNet}</Typography>
        </Grid>

        <Grid item container xs={6} alignItems="center" spacing={2}>
          <Grid item>
            <Icon path={mdiSale} size={1.6} className={classes.scaleDiscountsIcon} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{translateBool(props.hasScaleDiscount)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
