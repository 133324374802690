import { Grid, IconButton, Theme, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { Maybe } from 'graphql/jsutils/Maybe';
import { isNil } from 'lodash';
import React, { FC, useCallback } from 'react';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import AppErrorMessage from '../../Page/AppErrorMessage';
import AppProgress from '../../Page/AppProgress';
import { useDeleteAddressBookItem } from './fetch';

export const useAddressSectionStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightLight,
  },
  smallSectionHeader: {
    fontSize: '1.7rem',
    fontWeight: theme.typography.fontWeightLight,
  },
}));

export enum AddressHeaderTitle {
  PROJECT_ADDRESS = 'Projektadresse',
  BILLING_ADDRESS = 'Rechnungsadresse',
  SHIPPING_ADDRESS = 'Versandadresse',
}

interface IProps {
  title: AddressHeaderTitle;
  addressBookItemId: Maybe<number>;
}

export const AddressHeader: FC<IProps> = ({ title, addressBookItemId }) => {
  const classes = useAddressSectionStyles();

  const { executeDeleteAddressBookItem, loading, error } = useDeleteAddressBookItem();
  const deleteAddressBookItem = useCallback(() => {
    executeDeleteAddressBookItem({ variables: { where: { id: addressBookItemId! } } });
  }, [addressBookItemId, executeDeleteAddressBookItem]);

  const tooltipTitle = `${title} leeren`;
  const { confirmationDialog, requestConfirmation } = useConfirmationDialog({
    title: tooltipTitle,
    message: `Möchtest du die ${title} leeren?`,
    onConfirm: deleteAddressBookItem,
  });

  const isProjectAddress = title === AddressHeaderTitle.PROJECT_ADDRESS;
  const isDisabled = isNil(addressBookItemId);

  return (
    <>
      {loading && <AppProgress />}
      <AppErrorMessage message={errorPrefixRemover(error?.message ?? '')} isOpen={!!error} />
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="body1" className={classes.sectionHeader}>
            {title}
          </Typography>
        </Grid>
        {!isProjectAddress ? (
          <Grid item>
            <IconButton disabled={isDisabled} onClick={requestConfirmation}>
              <Tooltip title={tooltipTitle}>
                <CloseIcon />
              </Tooltip>
            </IconButton>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {confirmationDialog}
    </>
  );
};
