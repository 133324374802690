export const MISSION_COMPUTED_FIELDS = [
  'project.colorCodings',
  'project.projectNumber',
  'project.projectName',
  'project.status',
  'project.subsidiary.name',
  'project.createdAt',
  'project.publisher.name',
  'project.projectAddress.street',
  'project.projectAddress.mailbox',
  'project.projectAddress.postCode',
  'project.projectAddress.city',
  'project.projectAddress.country',
  'project.shippingAddress.street',
  'project.shippingAddress.mailbox',
  'project.shippingAddress.postCode',
  'project.shippingAddress.city',
  'project.shippingAddress.country',
  'project.catalog.useName',
  'project.staffTariff',
  'project.isSpecialProject',
  'project.commentToBranchLeader',
  'project.commentToBackoffice',
  'project.commentToExecutives',
  'colorCodings',
  'projectAddress.street',
  'projectAddress.mailbox',
  'projectAddress.postCode',
  'projectAddress.city',
  'projectAddress.country',
  'status.missionStatus',
  'status.date',
  'status.untilDate',
  'isSaturday',
  'isSunday',
  'isNight',
  'isUrgent',
  'publisher.name',
  'workTypes',
  'surfaceType',
] as const;
