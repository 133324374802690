import gql from 'graphql-tag';

export const MISSION_LOCATION_STRUCTURE_FRAGMENT = gql`
  fragment missionLocationStructureFields on MissionItemLocation {
    id
    name
    hasDescendants
  }
`;

export const MISSION_VIEW_FRAGMENT = gql`
  fragment missionViewFields on Mission {
    id
    name
    colorCodings
    finishedVolumeSum
    executionDate
    netPlanSum
    grossPlanSum
    netFinishedSum
    grossFinishedSum
    canBeDeleted
    isMissionBlocked
    manualLockingStatus
    status {
      id
      missionStatus
      date
      calendarWeek
      untilDate
      time
    }
  }
`;

export const MISSION_VIEW_COMPUTED_FRAGMENT = gql`
  fragment missionViewComputedFields on Mission {
    colorCodings
    netFinishedSum
    grossFinishedSum
    netPlanSum
    grossPlanSum
    canBeDeleted
    isMissionBlocked
    manualLockingStatus
  }
`;

export const MISSION_STRUCTURE_FRAGMENT = gql`
  fragment missionStructureFields on Mission {
    ...missionViewFields
    defaultMissionItemLocation {
      ...missionLocationStructureFields
      locations {
        ...missionLocationStructureFields
        locations {
          ...missionLocationStructureFields
        }
      }
    }
  }
  ${MISSION_LOCATION_STRUCTURE_FRAGMENT}
  ${MISSION_VIEW_FRAGMENT}
`;

export const MISSION_STRUCTURE_QUERY = gql`
  query MissionStructureQuery($projectNumber: String!) {
    missions(where: { project: { projectNumber: $projectNumber } }) {
      ...missionStructureFields
    }
  }
  ${MISSION_STRUCTURE_FRAGMENT}
`;

export const MISSION_ITEM_VIEW_FRAGMENT = gql`
  fragment missionItemViewFields on MissionItem {
    id
    planVolume
    isFinished
    openVolume
    finishedVolume
    comment
    customerComment
    invoicedVolume
    openInvoicedVolume
    invoicedSum
    openInvoicedSum
    missionItemSum
    canBeDeleted
    measurementCanBeDeleted: canBeDeleted(isFromMeasurement: true)
    mission {
      id
      executionDate
      isMissionBlocked
    }
    item {
      id
      productNumber
      comment
      customerComment
      descriptionOne
      descriptionTwo
      freeText
      unit
      markingStyle
      openVolume
      volume
      netOfferSum
      grossOfferSum
      finishedVolume
      openSum
      allocatedVolume
      openSum
      netPrice
      location {
        id
        parentLocation {
          id
          parentLocation {
            id
          }
        }
      }
      billOfQuantity {
        id
        name
      }
    }
  }
`;

export const MISSION_DEFAULT_FETCH_ITEMS = gql`
  query MissionDefaultFetchItems(
    $missionWhere: MissionWhereUniqueInput!
    $pagination: CursorLikePagination!
    $missionItemWhere: MissionItemWhereInput
    $search: SearchInput
  ) {
    mission(where: $missionWhere) {
      defaultMissionItemLocation {
        id
        missionItemsConnection(pagination: $pagination, where: $missionItemWhere, search: $search) {
          pageInfo {
            hasNextPage
          }
          nodes {
            ...missionItemViewFields
          }
        }
      }
    }
  }
  ${MISSION_ITEM_VIEW_FRAGMENT}
`;

export const MISSION_FETCH_ITEMS = gql`
  query MissionFetchItems(
    $locationWhere: MissionItemLocationWhereInput!
    $pagination: CursorLikePagination!
    $missionItemWhere: MissionItemWhereInput
    $search: SearchInput
  ) {
    missionItemLocations(where: $locationWhere) {
      id
      missionItemsConnection(pagination: $pagination, where: $missionItemWhere, search: $search) {
        pageInfo {
          hasNextPage
        }
        nodes {
          ...missionItemViewFields
        }
      }
    }
  }
  ${MISSION_ITEM_VIEW_FRAGMENT}
`;

export const MISSION_REFETCH_ITEMS_IN_CONTAINER = gql`
  query MissionRefetchItemsInContainer(
    $locationWhere: MissionItemLocationWhereInput!
    $pagination: CursorLikePagination!
    $missionItemWhere: MissionItemWhereInput
    $search: SearchInput
  ) {
    missionItemLocations(where: $locationWhere) {
      id
      missionItemsConnection(pagination: $pagination, where: $missionItemWhere, search: $search) {
        pageInfo {
          hasNextPage
        }
        nodes {
          ...missionItemViewFields
        }
      }
    }
  }
  ${MISSION_ITEM_VIEW_FRAGMENT}
`;

export const MISSION_DEFAULT_REFETCH_ITEMS_IN_CONTAINER = gql`
  query MissionDefaultRefetchItemsInContainer(
    $mission: MissionWhereInput!
    $pagination: CursorLikePagination!
    $missionItemWhere: MissionItemWhereInput
    $search: SearchInput
  ) {
    missions(where: $mission) {
      defaultMissionItemLocation {
        id
        missionItemsConnection(pagination: $pagination, where: $missionItemWhere, search: $search) {
          pageInfo {
            hasNextPage
          }
          nodes {
            ...missionItemViewFields
          }
        }
      }
    }
  }
  ${MISSION_ITEM_VIEW_FRAGMENT}
`;

export const MISSION_SEARCH_QUERY = gql`
  query MissionSearchQuery($search: SearchInput!, $projectNumber: String!) {
    missions(search: $search, where: { project: { projectNumber: $projectNumber } }) {
      id
    }
  }
`;

export const MISSION_FETCH_COMPUTED_FIELDS = gql`
  query FetchMissionComputedFields($mission: MissionWhereInput!) {
    missions(where: $mission) {
      id
      ...missionViewComputedFields
    }
  }
  ${MISSION_VIEW_COMPUTED_FRAGMENT}
`;

export const MISSION_SEARCH_MISSION_ITEMS = gql`
  query MissionSearchMissionItems(
    $take: Int!
    $locationWhere: MissionItemLocationWhereInput!
    $missionItemWhere: MissionItemWhereInput
    $search: SearchInput
  ) {
    missionItemLocations(where: $locationWhere) {
      id
      missionItemsConnection(
        pagination: { take: $take }
        where: $missionItemWhere
        search: $search
      ) {
        pageInfo {
          hasNextPage
        }
        nodes {
          ...missionItemViewFields
        }
      }
    }
  }
  ${MISSION_ITEM_VIEW_FRAGMENT}
`;

export const MISSION_SEARCH_DEFAULT_MISSION_ITEMS = gql`
  query MissionSearchDefaultMissionItems(
    $take: Int!
    $missionIdIn: [Int!]!
    $missionItemWhere: MissionItemWhereInput
    $search: SearchInput
    $projectWhere: ProjectWhereInput!
  ) {
    missions(where: { id_in: $missionIdIn, project: $projectWhere }) {
      defaultMissionItemLocation {
        id
        missionItemsConnection(
          pagination: { take: $take }
          where: $missionItemWhere
          search: $search
        ) {
          pageInfo {
            hasNextPage
          }
          nodes {
            ...missionItemViewFields
          }
        }
      }
    }
  }
  ${MISSION_ITEM_VIEW_FRAGMENT}
`;

export const REFETCH_MISSION_ITEM_QUERY = gql`
  query RefetchMissionItem($where: MissionItemWhereUniqueInput!) {
    missionItem(where: $where) {
      ...missionItemViewFields
    }
  }
  ${MISSION_ITEM_VIEW_FRAGMENT}
`;
