import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import MissionForm from '../../components/Mission/MissionForm';
import gql from 'graphql-tag';
import { isNil, omit } from 'lodash';
import { Mission_mission } from './types/Mission';
import {
  UpdateMission,
  UpdateMission_updateMission,
  UpdateMissionVariables,
} from './types/UpdateMission';
import DetailsFormPaper from '../../components/DetailsFormPaper';
import { formatDate } from '../../utils/format/date';
import { MutationUpdaterFn } from 'apollo-client';
import { UPDATE_DATA_TABLE_ROW } from '../../services/graphql-client';
import {
  UpdateDataTableRow,
  UpdateDataTableRowVariables,
} from '../../services/types/UpdateDataTableRow';
import { UpdateDataTableRowInput } from '../../types/graphql';
import {
  buildTableId,
  mapMissionStatus,
} from '../Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { executeForMissionTableTypes } from '../Projects/TabMissions/MissionSelector/paginationHelpers/executeForMissionTypes';
import { UPDATE_PROJECT } from '../Projects/TabDetails';
import { UpdateProject, UpdateProjectVariables } from '../Projects/TabDetails/types/UpdateProject';
import { useProjectAddressQuery } from '../../components/Address/projectAddress/fetch';
import { FULL_ADDRESS_BOOK } from '../../components/Address/query';
import {
  MissionAndAddressBook,
  MissionAndAddressBookVariables,
} from './types/MissionAndAddressBook';

interface IPropsMissionDetails {
  isMeasurementForm?: boolean;
}

const MISSION_DETAIL_FRAGMENT = gql`
  fragment missionDetailFields on Mission {
    id
    createdAt
    customerOnSite
    description
    executionDate
    expectedStaffNumber
    expectedWorkingTime
    expectedEquipmentOps
    surfaceType
    name
    surfaceConditions
    weatherType
    temperature
    workTypes
    isCustomerOnSite
    isTimeFixed
    externalServices
    commentBranchLeaderToBranchLeader
    commentToBackoffice
    commentToEquipmentOperator
    hasMissionItems
    extraInformation
    callCustomer
    isMissionBlocked
    manualLockingStatus
    ec {
      id
      name
    }
    status {
      id
      missionStatus
      calendarWeek
      date
      time
      postponementOne
      postponementTwo
      untilDate
    }
    publisher {
      id
      name
    }
    missionItems {
      id
    }
    project {
      id
      personResponsible {
        id
        name
      }
    }
  }
`;

const GET_MISSION_AND_ADDRESS_BOOK = gql`
  query MissionAndAddressBook($where: MissionWhereUniqueInput!, $projectNumber: String!) {
    mission(where: $where) {
      ...missionDetailFields
    }
    addressBook(where: { projectNumber: $projectNumber }) {
      ...FullAddressBook
    }
  }
  ${MISSION_DETAIL_FRAGMENT}
  ${FULL_ADDRESS_BOOK}
`;

const UPDATE_MISSION_MUTATION = gql`
  mutation UpdateMission($where: MissionWhereUniqueInput!, $data: MissionUpdateInput!) {
    updateMission(where: $where, data: $data) {
      ...missionDetailFields
    }
  }
  ${MISSION_DETAIL_FRAGMENT}
`;

interface IUrlParams {
  projectNumber: string;
  missionId: string;
}

const MissionDetails: React.FC<IPropsMissionDetails> = ({ isMeasurementForm }) => {
  const { projectNumber, missionId } = useParams<IUrlParams>();
  const history = useHistory();
  const client = useApolloClient();
  const id = parseInt(missionId, 10);

  const { data, loading, error } = useQuery<MissionAndAddressBook, MissionAndAddressBookVariables>(
    GET_MISSION_AND_ADDRESS_BOOK,
    {
      variables: { where: { id }, projectNumber },
    },
  );

  const setMeasurementItemsEditableState = useCallback(
    async (mission: UpdateMission_updateMission) => {
      for (const missionItem of mission.missionItems) {
        await executeForMissionTableTypes(async (tableType) => {
          await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
            query: UPDATE_DATA_TABLE_ROW,
            variables: {
              where: { id: `${missionItem.id}-${tableType}`, tableType },
              data: {
                partial: true,
                data: JSON.stringify({
                  mission: {
                    executionDate: mission.executionDate,
                    isMissionBlocked: mission.isMissionBlocked,
                    __typename: 'Mission',
                  },
                }),
              },
            },
          });
        });
      }
    },
    [client],
  );

  const updateMissionInCache = useCallback<MutationUpdaterFn<UpdateMission>>(
    (_, { data }) => {
      if (!data) {
        return;
      }

      const mission = data.updateMission;

      const rowUpdateInput: UpdateDataTableRowInput = {
        data: JSON.stringify({
          ...mission,
          status: mission.status ? mapMissionStatus(mission.status, mission.executionDate) : null,
        }),
        partial: true,
      };

      executeForMissionTableTypes((tableType) =>
        client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
          query: UPDATE_DATA_TABLE_ROW,
          variables: {
            where: { id: buildTableId(tableType)(missionId), tableType },
            data: rowUpdateInput,
          },
        }),
      );
    },
    [client, missionId],
  );

  const [updateMission] = useMutation<UpdateMission, UpdateMissionVariables>(
    UPDATE_MISSION_MUTATION,
    {
      update: updateMissionInCache,
    },
  );

  const [updateProject] = useMutation<UpdateProject, UpdateProjectVariables>(UPDATE_PROJECT);

  const { executeUpdateProjectAddress } = useProjectAddressQuery();

  const onClose = useCallback(() => {
    if (!isMeasurementForm) {
      history.goBack();

      return;
    }

    history.push(`/projekte/${projectNumber}/ausmass`);
  }, [history, isMeasurementForm, projectNumber]);

  const handleMissionUpdate = async (
    changedValues: Mission_mission & {
      personResponsible?: { name: string };
      projectAddress?: string;
    },
  ) => {
    const PATHS_TO_OMIT = [
      'id',
      '__typename',
      'createdAt',
      'publisher',
      'hasMissionItems',
      'project',
      'personResponsible',
      'projectAddress',
    ];

    const omittedData: any = {
      ...omit(changedValues, PATHS_TO_OMIT),
      status: { ...omit(changedValues.status, PATHS_TO_OMIT) },
    };

    if (changedValues?.personResponsible?.name) {
      const newPersonResponsible = changedValues.personResponsible.name;
      await updateProject({
        variables: {
          projectNumber,
          data: { personResponsible: { name: newPersonResponsible } },
        },
      });
    }

    if (changedValues?.projectAddress) {
      await executeUpdateProjectAddress({
        variables: {
          projectNumber,
          projectAddress: changedValues.projectAddress,
        },
      });
    }

    const { data: updateMissionData } = await updateMission({
      variables: { where: { id }, data: omittedData },
    });

    if (
      updateMissionData &&
      // use hasOwnProperty to allow setting date to null
      (Object.prototype.hasOwnProperty.call(updateMissionData, 'executionDate') ||
        !isNil(updateMissionData.updateMission.isMissionBlocked))
    ) {
      await setMeasurementItemsEditableState(updateMissionData.updateMission);
    }

    if (missionId === 'erstellen') onClose();
  };

  if (loading) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  if (!data) {
    console.log('error: no Data!');
    return null;
  }

  const publisherMetaData = [
    {
      key: 'Eröffnet von',
      value: data.mission.publisher.name,
    },
    {
      key: 'am',
      value: formatDate(data.mission.createdAt),
    },
  ];

  return (
    <DetailsFormPaper metaData={publisherMetaData} onClose={onClose}>
      <MissionForm
        onSubmit={handleMissionUpdate}
        initialValues={{
          ...data.mission,
          project: {
            ...data.mission.project,
            projectAddress: data?.addressBook?.projectAddress ?? undefined,
          },
        }}
        isMeasurementForm={isMeasurementForm}
        isEditForm={true}
      />
    </DetailsFormPaper>
  );
};

export default MissionDetails;
