import { IconButton, Tooltip } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import { debounce, isEqual, isNil, omit, pick } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-apollo';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { REORDER_LOCATION_MUTATION } from '../../../../components/BillOfQuantity/BllOfQuantityTable/BillOfQuantityTable.queries';
import {
  ReorderLocation,
  ReorderLocationVariables,
} from '../../../../components/BillOfQuantity/BllOfQuantityTable/types/ReorderLocation';
import { parseCompositeLocationId } from '../../../../components/BillOfQuantity/BllOfQuantityTable/utils/compositeLocationId';
import {
  tryParseCompositeLocationId,
  useBillOfQuantityData,
} from '../../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper';
import {
  ActionType,
  DataTableBody,
  DataTableHotKeysWrapper,
} from '../../../../components/DataTable';
import {
  IDataTableOptions,
  IDataTableRow,
  ILevelOptions,
  OnLoadMore,
} from '../../../../components/DataTable/types';
import { Filter, IFilterConfig, useFilterReducer } from '../../../../components/Filter';
import AppErrorMessage from '../../../../components/Page/AppErrorMessage';
import AppProgress from '../../../../components/Page/AppProgress';
import { Search, useSearchState } from '../../../../components/Search/Search';
import { useL8Filter } from '../../../../hooks/BillOfQuantity/useL8Filter';
import { BillOfQuantityEntityType, TableType } from '../../../../types/graphql';
import { errorPrefixRemover } from '../../../../utils/errorPrefixRemover';
import { getChangedFormValues } from '../../../../utils/form/getChanged';
import { getAllInnerTableRows } from '../../../../utils/paginationHelpers/getAllInnerTableRows';
import getSelectedContainersToRefetch from '../../BillOfQuantitySelector/getSelectedContainersToRefetch';
import { IMissionLocationIdInput } from '../MissionSelector/paginationHelpers';
import { removeTableTypeFromId } from '../MissionSelector/paginationHelpers/mapper';
import { ProjectMissions_project_missions } from '../MissionSelector/types/ProjectMissions';
import SelectedItemsInfo from '../SelectedItemsInfo';
import { IItem, IItemWithAmount } from '../TabMissions';
import { billOfQuantityColumns, itemColumns, locationColumns } from './row-definitions';

export const getFlatItems = (mappedRows: IDataTableRow[]): IItem[] => {
  const innerTableRows = mappedRows.flatMap(getAllInnerTableRows);

  return innerTableRows.map((row) => row.data);
};

const getInitialValues = (items: IItem[]): { [x: string]: number } =>
  items.reduce(
    (init, item) => ({
      ...init,
      [`volume-${item.id}`]: item.openVolume,
    }),
    {},
  );

const searchColumns = ['acronym', 'productNumber', 'descriptionOne', 'category', 'type', 'unit'];

interface IProps {
  projectNumber: string;
  mission?: ProjectMissions_project_missions;
  onSubmit: (
    items: IItemWithAmount[],
    allItems: IItem[],
    containerIdsToAdd: string[],
    containersToRefetch: IMissionLocationIdInput[],
  ) => Promise<any> | undefined;
}

const filterConfigs: { [key: string]: IFilterConfig } = {
  acronym: { label: 'Kurzbezeichnung', type: 'string' },
  volume: { label: 'Offerten Menge', type: 'number' },
  catalogMainGroup: { label: 'Hierarchienr 3', type: 'string' },
  catalogMainGroupDescription: { label: 'Hierarchie 3', type: 'string' },
  catalogSection: { label: 'Hierarchienr 1', type: 'string' },
  catalogSectionDescription: { label: 'Hierarchie 1', type: 'string' },
  catalogSubsection: { label: 'Hierarchienr 2', type: 'string' },
  catalogSubsectionDescription: { label: 'Hierarchie 2', type: 'string' },
  category: { label: 'Kategorie', type: 'string' },
  color: { label: 'Farbe', type: 'string' },
  comment: { label: 'Kommentar Position', type: 'string' },
  descriptionOne: { label: 'Beschreibung 1', type: 'string' },
  descriptionTwo: { label: 'Beschreibung 2', type: 'string' },
  dimensionOne: { label: 'Dimension 1', type: 'string' },
  dimensionTwo: { label: 'Dimension 2', type: 'string' },
  freeText: { label: 'Freitext', type: 'string' },
  markingStyle: { label: 'Typ', type: 'string' },
  material: { label: 'Verbrauchsmaterial', type: 'string' },
  productNumber: { label: 'Produktnummer', type: 'string' },
  reflexion: { label: 'Reflektion', type: 'string' },
  targetConsumptionPerUnit: { label: 'Sollverbrauch EinheitMat/Einheitpos', type: 'number' },
  timeRequired: { label: 'Zeitbedarf min/einheit', type: 'number' },
  type: { label: 'Positionsart', type: 'string' },
  unit: { label: 'Einheit', type: 'string' },
};

const isLevelToggleButtonVisible = (row: IDataTableRow) => row.data.hasItemsOrLocations;
const normalizeCheckedRowIds = (checkedRowIds: string[]): string[] =>
  checkedRowIds.map(removeTableTypeFromId);

/** the minimum amount of time needed to pass when updating saving formik values manually */
const MIN_INTERVAL_FORM_VALUE_CHANGE = 400;

const BillOfQuantitySelector: React.FC<IProps> = ({ projectNumber, mission, onSubmit }) => {
  const [filterState, dispatchFilter] = useFilterReducer();
  const [showFinishedItems, setShowFinishedItems] = React.useState(false);
  /**
   * mirrors formik values to write into `formValuesToOverride` in order to keep changed values
   * when more items get loaded
   */
  const [changedFormValues, setChangedFormValues] = React.useState<Record<string, number>>({});
  /**
   * used to override formik initialValues because when more items get loaded
   * the changed values of user get overwritten with db-values
   */
  const [formValuesToOverride, setFormValuesToOverride] = React.useState<Record<string, number>>(
    {},
  );

  const setChangedFormValuesDebounced = useMemo(() => {
    return debounce(
      (currentChangedFormValues: Record<string, number>) =>
        setChangedFormValues(currentChangedFormValues),
      MIN_INTERVAL_FORM_VALUE_CHANGE,
    );
  }, []);

  const {
    mappedRows,
    onToggleShowFinishedItems: onToggleShowFinishedItemsInCache,
    onSearch,
    hasNextPage,
    fetchItemsConnection,
    onToggleL8,
    reorderLocation: reorderLocationInCache,
    loading,
  } = useBillOfQuantityData(projectNumber, TableType.ORDER_MISSION, BillOfQuantityEntityType.ORDER);

  const { L8Button } = useL8Filter({ onToggle: onToggleL8 });

  const searchState = useSearchState();

  const itemsWhere = useMemo(
    () =>
      Object.entries(filterState.addedFilters).reduce(
        (itemsWhere, [key, filter]) => ({
          ...itemsWhere,
          ...(filter.type === 'number'
            ? filter.value !== '' && { [key]: Number(filter.value) }
            : { [key]: filter.value }),
        }),
        {},
      ),
    [filterState.addedFilters],
  );

  const [reorderLocation, { loading: isReordering, error: reorderingError }] = useMutation<
    ReorderLocation,
    ReorderLocationVariables
  >(REORDER_LOCATION_MUTATION);

  const { flatItems, tableData, initialValues } = useMemo(() => {
    const flatItems = getFlatItems(mappedRows);

    flatItems.forEach((flatItem: any) => {
      if (!isNil(formValuesToOverride[`volume-${flatItem.id}`])) {
        flatItem.__formikDirty = true;
      }
    });

    return {
      flatItems,
      tableData: mappedRows,
      initialValues: getInitialValues(flatItems),
    };
  }, [mappedRows, formValuesToOverride]);

  const handleSubmit = useCallback(
    async (
      values: { [x: string]: number },
      checkedRowIds: string[],
      selectedContainerIds: string[],
    ) => {
      const submittableItems = (flatItems || [])
        .filter((item) => checkedRowIds.includes(item.id) && values[`volume-${item.id}`] > 0)
        .map((item) => ({
          ...item,
          amount: values[`volume-${item.id}`],
        }));

      const submittedFieldIds = submittableItems.map((item) => `volume-${item.id}`);

      const formValuesToOverrideWithoutSubmitted = omit(formValuesToOverride, submittedFieldIds);
      const changedFormValuesWithoutSubmitted = omit(changedFormValues, submittedFieldIds);

      setFormValuesToOverride(formValuesToOverrideWithoutSubmitted);
      setChangedFormValues(changedFormValuesWithoutSubmitted);

      await onSubmit(
        submittableItems,
        flatItems,
        selectedContainerIds,
        getSelectedContainersToRefetch(selectedContainerIds, tableData),
      );
    },
    [flatItems, formValuesToOverride, changedFormValues, onSubmit, tableData],
  );

  // calls the api for a reorder and already displays the reordered item at the new position until the source of truth is fetched
  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const parsed = parseCompositeLocationId(result.draggableId);
      const variables = {
        ...parsed,
        index: result.destination.index,
      };
      reorderLocationInCache(variables);
      try {
        await reorderLocation({
          variables,
        });
      } catch (err) {
        // errors handled via `reorderingError` or `error` of the mutation/queries
        console.log(err);
      }
    },
    [reorderLocation, reorderLocationInCache],
  );

  const onToggleShowFinishedItems = useCallback(() => {
    setShowFinishedItems(!showFinishedItems);
    onToggleShowFinishedItemsInCache({ isFinished: !showFinishedItems, ...itemsWhere })();
  }, [setShowFinishedItems, showFinishedItems, onToggleShowFinishedItemsInCache, itemsWhere]);

  const isOnLoadMoreDisabled = useCallback(
    (row: IDataTableRow) => {
      return !hasNextPage(row.id);
    },
    [hasNextPage],
  );
  const onLoadMore = useCallback<OnLoadMore>(
    (type, containingRowId, after) => {
      const locationId = tryParseCompositeLocationId('locationId')(
        removeTableTypeFromId(containingRowId),
      );

      setFormValuesToOverride(changedFormValues);

      return fetchItemsConnection({
        take: 10,
        after,
        where: { id: locationId },
        itemsWhere: { ...itemsWhere, isFinished: showFinishedItems },
      });
    },
    [fetchItemsConnection, itemsWhere, showFinishedItems, changedFormValues],
  );

  const isDragAndDropEnabled = !isReordering;
  const levels = useMemo<ILevelOptions[]>(
    () => [
      {
        columns: billOfQuantityColumns,
        isDragAndDropEnabled,
        isLevelToggleButtonVisible,
        isOnLoadMoreDisabled,
        onLoadMore,
        setDroppableProps: (row: { id: string }): { id: string; type: string } => {
          return {
            id: row.id,
            type: `LOCATION_ONES_${row.id}`,
          };
        },
      },
      {
        columns: locationColumns,
        isDefaultClosed: true,
        isDragAndDropEnabled,
        isLevelToggleButtonVisible,
        isOnLoadMoreDisabled,
        onLoadMore,
        setDraggableProps: (row: { id: string }): { id: string; type: string } => {
          const { billOfQuantityId } = parseCompositeLocationId(row.id);
          return {
            id: row.id,
            type: `LOCATION_ONES_${billOfQuantityId}`,
          };
        },
        setDroppableProps: (row: { id: string }): { id: string; type: string } => {
          const { billOfQuantityId, locationId } = parseCompositeLocationId(row.id);
          return {
            id: row.id,
            type: `LOCATION_TWOS_${billOfQuantityId}_${locationId}`,
          };
        },
      },
      {
        columns: locationColumns,
        isDefaultClosed: true,
        isDragAndDropEnabled,
        isLevelToggleButtonVisible,
        isOnLoadMoreDisabled,
        onLoadMore,
        setDraggableProps: (row: { id: string }): { id: string; type: string } => {
          const { billOfQuantityId, parentLocationId } = parseCompositeLocationId(row.id);
          return {
            id: row.id,
            type: `LOCATION_TWOS_${billOfQuantityId}_${parentLocationId}`,
          };
        },
      },
      {
        columns: itemColumns(false),
        actions: () => (
          <>
            <Tooltip
              title={`Abgeschlossene Positionen ${showFinishedItems ? 'ausblenden' : 'einblenden'}`}
            >
              <IconButton onClick={onToggleShowFinishedItems}>
                {showFinishedItems ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Tooltip>
            {L8Button}
          </>
        ),
        hasCheckboxes: true,
      },
    ],
    [
      isDragAndDropEnabled,
      showFinishedItems,
      onToggleShowFinishedItems,
      L8Button,
      isOnLoadMoreDisabled,
      onLoadMore,
    ],
  );

  const dataTableOptions = useMemo<IDataTableOptions>(
    () => ({
      levels,
      filterText: searchState.searchTerm,
      tableName: 'MISSIONS_BOQ_SELECTOR',
      isSelectableContainers: true,
    }),
    [levels, searchState.searchTerm],
  );

  const onSearchSubmit = useCallback(
    (searchInput) => onSearch(searchInput, showFinishedItems),
    [showFinishedItems, onSearch],
  );

  const showError = reorderingError;
  const formikInitialValues = { ...initialValues, ...formValuesToOverride };

  return (
    <>
      {isReordering && <AppProgress />}
      {showError && <AppErrorMessage message={errorPrefixRemover(showError.message)} />}
      <DragDropContext onDragEnd={onDragEnd}>
        <DataTableHotKeysWrapper
          containerRows={tableData}
          options={dataTableOptions}
          search={
            <Search
              columns={searchColumns}
              searchState={searchState}
              loading={loading}
              onSubmit={onSearchSubmit}
            />
          }
        >
          {(context) => {
            const activeColumnIds = context.tableData.innerTableOptions.columns.reduce<string[]>(
              (activeColumnIds, c) => [...activeColumnIds, c.id],
              [],
            );
            const filters = pick(filterConfigs, activeColumnIds);
            const normalizedCheckedRowIds = normalizeCheckedRowIds(context.checkedRowIds);

            return (
              <>
                <Filter
                  filters={filters}
                  addedFilters={filterState.addedFilters}
                  selectedFilter={filterState.selectedFilter}
                  dispatch={dispatchFilter}
                />
                <Formik<{ [x: string]: number }>
                  initialValues={formikInitialValues}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    await handleSubmit(
                      values,
                      normalizedCheckedRowIds,
                      context.state.selectedContainerIds,
                    );
                    setSubmitting(false);
                    resetForm();
                    context.dispatch({ type: ActionType.RESET_CHECKBOXES });
                  }}
                  enableReinitialize
                >
                  {({ values }) => {
                    const currentChangedFormValues = getChangedFormValues({
                      initialValues,
                      values,
                    });

                    if (!isEqual(currentChangedFormValues, changedFormValues)) {
                      setChangedFormValuesDebounced(currentChangedFormValues);
                    }

                    const selectedItems = (flatItems ?? [])
                      .filter((item) => {
                        return normalizedCheckedRowIds.includes(item.id);
                      })
                      .map((item) => ({
                        ...item,
                        amount: values[`volume-${item.id}`],
                      }));

                    return (
                      <Form>
                        <DataTableBody context={context} />
                        <SelectedItemsInfo
                          selectedContainerIds={context.state.selectedContainerIds}
                          items={selectedItems}
                          mission={mission}
                        />
                      </Form>
                    );
                  }}
                </Formik>
              </>
            );
          }}
        </DataTableHotKeysWrapper>
      </DragDropContext>
    </>
  );
};

export default BillOfQuantitySelector;
