import { useRouteMatch } from 'react-router-dom';
import { BillOfQuantityEntityType } from '../types/graphql';

export const useBoqEntityType = (): BillOfQuantityEntityType => {
  const match = useRouteMatch();

  return match.path.split('/').includes('auftraege')
    ? BillOfQuantityEntityType.ORDER
    : BillOfQuantityEntityType.OFFER;
};
