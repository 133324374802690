import React, { useCallback, useState, useMemo } from 'react';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { Noop } from '../types';
import createPreventAll from '../utils/createPreventEventDefault';

interface IProps {
  message: string;
  title: string;
  onConfirm: Noop;
}

export const useConfirmationDialog = (props: IProps) => {
  const [isRequestingConfirmation, setIsRequestionConfirmation] = useState(false);

  const closeConfirmationDialog = useMemo<React.MouseEventHandler<HTMLButtonElement>>(
    () =>
      createPreventAll(() => {
        setIsRequestionConfirmation(false);
      }),
    [],
  );
  const requestConfirmation = useCallback(() => {
    setIsRequestionConfirmation(true);
  }, []);

  const confirmationDialog = useMemo(
    () => (
      <ConfirmationDialog
        title={props.title}
        message={props.message}
        open={isRequestingConfirmation}
        onClose={closeConfirmationDialog}
        onConfirm={props.onConfirm}
      />
    ),
    [
      isRequestingConfirmation,
      closeConfirmationDialog,
      props.title,
      props.message,
      props.onConfirm,
    ],
  );

  return {
    requestConfirmation,
    confirmationDialog,
  };
};
