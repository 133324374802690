import gql from 'graphql-tag';

export const historyFieldsFragment = gql`
  fragment historyFields on HistoryItem {
    id
    createdAt
    value
    validFrom
    createdBy {
      id
      name
    }
  }
`;

export const CREATE_EDITABLE_HISTORY_ITEM = gql`
  mutation createEditableHistoryItem(
    $data: EditableHistoryItemCreateInput!
    $where: EditableHistoryItemWhereInput!
  ) {
    createEditableHistoryItem(data: $data, where: $where) {
      id
    }
  }
`;

export const UPDATE_EDITABLE_HISTORY_ITEM = gql`
  mutation updateEditableHistoryItem(
    $data: EditableHistoryItemUpdateInput!
    $where: EditableHistoryItemWhereUniqueInput!
  ) {
    updateEditableHistoryItem(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_EDITABLE_HISTORY_ITEM = gql`
  mutation deleteEditableHistoryItem($where: EditableHistoryItemWhereUniqueInput!) {
    deleteEditableHistoryItem(where: $where) {
      id
    }
  }
`;
