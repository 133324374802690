import { useEffect, useMemo } from 'react';
import { Noop } from '../../../../../types';
import { UpsertAddressBookItemType } from '../../../../../types/graphql';
import { IDataTableRow } from '../../../../DataTable/types';
import { AddressSearchUseFetchResult } from '../../fetch';
import { ADDRESS_COLUMNS, COLUMN_DEFAULT_WIDTH } from '../constants';
import { AddressSearchColumn } from '../types';

const isNumberColumn = (column: AddressSearchColumn): boolean => {
  const numberColumns = ['id', 'postCode'];

  return numberColumns.includes(column);
};

const buildTableColumns = () =>
  ADDRESS_COLUMNS.map((column) => ({
    id: column,
    label: '',
    preventSorting: true,
    width: COLUMN_DEFAULT_WIDTH[column],
    isNumber: isNumberColumn(column),
  }));

const createHandleRowClick =
  (
    executeUseAddress: AddressSearchUseFetchResult['executeUseAddress'],
    projectNumber: string,
    type: UpsertAddressBookItemType,
    onAddressSelect: Noop,
  ) =>
  async (row: IDataTableRow) => {
    await executeUseAddress({
      variables: { abacusAddressId: parseInt(row.id), projectNumber, type },
    });
    onAddressSelect();
  };

const useInnerTableRows = (data: AddressSearchUseFetchResult['data']) => {
  return useMemo(
    () => data?.abacusAddresses?.map((address) => ({ id: address.id, data: address })) ?? [],
    [data],
  );
};

const useResetQueryState = (
  isSurroundingModalOpen: boolean | undefined,
  refetch: AddressSearchUseFetchResult['refetch'],
) => {
  useEffect(() => {
    if (!isSurroundingModalOpen) {
      refetch({ first: 50, search: null, after: null });
    }
  }, [isSurroundingModalOpen, refetch]);
};

export const ADDRESS_SEARCH_TABLE_UTILS = {
  buildTableColumns,
  createHandleRowClick,
  useInnerTableRows,
  useResetQueryState,
};
