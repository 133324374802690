import React from 'react';
import Icon from '@mdi/react';
import acceptedMimeTypes from './acceptedMimeTypes';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  svg: {
    width: '1.2rem',
  },
});

interface IFileIconProps {
  mimeType: keyof typeof acceptedMimeTypes;
  path?: string;
  color?: string;
}

const FileIcon: React.FunctionComponent<IFileIconProps> = ({ mimeType, path, color }) => {
  const classes = useStyles();
  return (
    <Icon
      className={classes.svg}
      path={path ? path : acceptedMimeTypes[mimeType]}
      size={1}
      color={color ?? '#909090'}
    />
  );
};

export default FileIcon;
