import { createSwissCurrencyFormatter } from '../../../../utils/createCurrencyFormatter';

export enum ItemListActionType {
  SET_ACTIVE_ITEM_ID,
  UPSERT_ITEM,
  REMOVE_ITEM,
}

export interface IItem {
  id: string;
  volume: number;
  appliedPrice?: number;
}

export interface IItemListState {
  items: IItem[];
  activeItemId: string;
}

export type IItemListAction =
  | { type: ItemListActionType.SET_ACTIVE_ITEM_ID; payload: { id: string } }
  | { type: ItemListActionType.REMOVE_ITEM; payload: { id: string } }
  | {
      type: ItemListActionType.UPSERT_ITEM;
      payload: { id: string; volume: number; appliedPrice?: number | null };
    };

const formatCurrencyWithoutCurrency = createSwissCurrencyFormatter();

const transformRappenToChf = (rappen: number) =>
  Number.parseFloat(formatCurrencyWithoutCurrency(rappen));

export const itemBoilerplateListReducer = (
  state: IItemListState,
  action: IItemListAction,
): IItemListState => {
  switch (action.type) {
    case ItemListActionType.UPSERT_ITEM: {
      const existingItemIdx = state.items.findIndex((item) => item.id === action.payload.id);

      const items = state.items;

      if (existingItemIdx > -1) {
        items[existingItemIdx].volume = action.payload.volume;
      }
      if (existingItemIdx === -1) {
        items.push({
          id: action.payload.id,
          volume: action.payload.volume,
          ...(action.payload.appliedPrice && {
            appliedPrice: transformRappenToChf(action.payload.appliedPrice),
          }),
        });
      }

      return {
        ...state,
        items,
      };
    }
    case ItemListActionType.SET_ACTIVE_ITEM_ID:
      return { ...state, activeItemId: action.payload.id };
    case ItemListActionType.REMOVE_ITEM:
      return { ...state, items: state.items.filter((item) => item.id !== action.payload.id) };
    default:
      return state;
  }
};
