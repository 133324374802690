import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  caption: {
    color: theme.palette.text.secondary,
  },
}));

interface IProps {
  name: string;
  createdAt: Date;
}

const Publisher: React.FunctionComponent<IProps> = ({ name, createdAt }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="caption" className={classes.caption}>
        Eröffnet
      </Typography>

      <table>
        <tbody>
          <tr>
            <td>
              <Typography variant="caption" className={classes.caption}>
                von:
              </Typography>
            </td>
            <td>
              <Typography variant="body2">{name}</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="caption" className={classes.caption}>
                am:
              </Typography>
            </td>
            <td>
              <Typography variant="body2">{new Date(createdAt).toLocaleDateString()}</Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Publisher;
