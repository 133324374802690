import { IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { ActionType, IDispatchTable } from '.';
import createPreventAll from '../../utils/createPreventEventDefault';
import { IDataTableRow, IRowNavigation, OnLoadMore } from './types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  iconButton: {
    margin: 'auto',
  },
}));

interface IProps {
  rowNavigation: IRowNavigation;
  isOpen: boolean;
  isActive: boolean;
  dispatch: IDispatchTable;
  fixedWidth?: number;
  onLoadMore?: OnLoadMore;
  isLevelToggleButtonVisible?: boolean;
  innerTableRows?: Array<IDataTableRow<any, any, any>>;
}

const LevelToggleButton: React.FC<IProps> = ({
  rowNavigation,
  isOpen,
  isActive,
  dispatch,
  fixedWidth,
  onLoadMore,
  isLevelToggleButtonVisible,
  innerTableRows,
}) => {
  const classes = useStyles();

  const handleClick = useMemo(
    () =>
      createPreventAll(() => {
        if (!isOpen && isEmpty(innerTableRows) && onLoadMore) {
          onLoadMore('OPEN_CONTAINER', rowNavigation.row.id, undefined);
        }

        dispatch({ type: ActionType.TOGGLE_ROW, payload: { rowId: rowNavigation.row.id } });
      }),
    [rowNavigation.row.id, isOpen, innerTableRows, onLoadMore, dispatch],
  );

  const style = fixedWidth
    ? {
        width: fixedWidth,
      }
    : undefined;

  return (
    <div className={classes.root} style={style}>
      {(isLevelToggleButtonVisible || rowNavigation.children.length > 0 || !isOpen) && (
        <IconButton
          className={classes.iconButton}
          onClick={handleClick}
          aria-label={'Öffnen'}
          color={isActive ? 'primary' : 'default'}
          data-testid={'openRow'}
          tabIndex={-1}
        >
          {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        </IconButton>
      )}
    </div>
  );
};

export default LevelToggleButton;
