import { useMutation, useQuery } from 'react-apollo';
import { ADDRESS_SEARCH_QUERY, USE_ADDRESS_MUTATION } from './queries';
import { AddressSearch, AddressSearchVariables } from './types/AddressSearch';
import { UseAddress, UseAddressVariables } from './types/UseAddress';

export type AddressSearchUseFetchResult = ReturnType<typeof useAddressSearch>;

export const useAddressSearch = () => {
  const { data, loading, fetchMore, refetch } = useQuery<AddressSearch, AddressSearchVariables>(
    ADDRESS_SEARCH_QUERY,
    {
      variables: { first: 50 },
    },
  );

  const [executeUseAddress, { loading: mutationLoading }] = useMutation<
    UseAddress,
    UseAddressVariables
  >(USE_ADDRESS_MUTATION);

  return {
    data,
    loading: loading || mutationLoading,
    fetchMore,
    refetch,
    executeUseAddress,
  };
};
