import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme } from '@material-ui/core';
import { IDataTableRow, IDataTableColumn } from '../types';
import HighlightedCellContent from '../HighlightedCellContent';

const useStyles = makeStyles((theme: Theme) => ({
  root: { padding: `${theme.spacing(2)}px ${theme.spacing(1)}px `, wordWrap: 'break-word' },
}));

interface IProps {
  row: IDataTableRow;
  column: IDataTableColumn;
  searchWords: string[];
  width?: number;
}

const ContainerCell: React.FunctionComponent<IProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={{ width: props.width }}>
      <Typography variant="caption">{props.column.label}</Typography>
      <Typography variant="subtitle2">
        <HighlightedCellContent
          row={props.row}
          column={props.column}
          searchWords={props.searchWords}
        />
      </Typography>
    </div>
  );
};

export default ContainerCell;
