import React, { useCallback, useMemo } from 'react';
import { FastField as Field, useFormikContext } from 'formik';
import { useLazyQuery, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Autocomplete } from '../Autocomplete';
import { ProjectName, ProjectNameVariables } from '../types/ProjectName';
import FormikTextField from '../Form/FormikTextField';
import { FormControl, InputLabel, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CatalogSelect from '../Item/ItemImporter/ItemBoilerplateSearch/CatalogSelect';
import { useCatalogs } from '../../pages/Catalogs/fetch';

export const GET_ALL_USER_NAMES = gql`
  query getAllUsersNames($where: UserWhereInput!) {
    users(where: $where) {
      id
      name
      archived
    }
  }
`;

const PROJECT_NAME = gql`
  query ProjectName($projectNumber: String!) {
    project(where: { projectNumber: $projectNumber }) {
      id
      projectName
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  catalogFormControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
  hiddenCatalogField: {
    display: 'none',
  },
}));

export const PersonResponsibleField: React.FC = () => {
  const { data, error } = useQuery(GET_ALL_USER_NAMES, {
    variables: { where: { archived: false } },
  });

  const suggestions = useMemo<string[]>(
    () => (data && data.users ? data.users.map((user: any) => user.name) : []),
    [data],
  );

  const validate = useCallback(
    (value: any) => {
      if (!value) {
        return 'Verpflichtend!';
      }

      if (!suggestions.includes(value)) {
        return 'Verantwortlicher existiert nicht!';
      }
    },
    [suggestions],
  );

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <Field
      name="personResponsible.name"
      label="Verantwortlicher"
      minLengthToTrigger={3}
      suggestions={suggestions}
      size="normal"
      component={Autocomplete}
      // if we just pass the validate function it doesn't update correctly on change
      validate={(value: any) => validate(value)}
    />
  );
};

export const AutocompleteCatalogField: React.FC = () => {
  const classes = useStyles();
  const { catalogs, error } = useCatalogs();
  const { setFieldValue } = useFormikContext();

  const suggestions = useMemo(() => catalogs.map(({ useName }) => useName), [catalogs]);

  // the Autocomplete component sets value to the string in the dropdown; in this case
  // we need to show catalog names but submit the id
  const suggestionToValueMap = new Map<string, string>();
  catalogs.forEach(({ useName, id }) => suggestionToValueMap.set(useName, id));

  const validate = useCallback(
    (value: any) => {
      if (!value) {
        return 'Verpflichtend!';
      }

      if (!suggestions.includes(value)) {
        return 'Katalog existiert nicht!';
      }
    },
    [suggestions],
  );

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <>
      {/*the actual catalog field is hidden and is being set by the field below */}
      <Field name="catalog" className={classes.hiddenCatalogField} />
      <Field
        name="catalogVisible"
        label="Standardkatalog *"
        minLengthToTrigger={0}
        suggestions={suggestions}
        size="normal"
        component={Autocomplete}
        validate={(values: any) => validate(values)}
        onSetValue={(value: string) =>
          setFieldValue('catalog', suggestionToValueMap.get(value), false)
        }
      />
    </>
  );
};

export const ParentProjectField: React.FC = () => {
  const [getProjectName, result] = useLazyQuery<ProjectName, ProjectNameVariables>(PROJECT_NAME);

  const { initialValues, values } = useFormikContext<any>();

  return (
    <>
      <Field
        name="parent.projectNumber"
        label="Übergeordnetes Projekt"
        component={FormikTextField}
        validate={(value: any) => {
          if (value && result.error) {
            return 'Projekt ist nicht vorhanden!';
          }

          if (
            value === values.projectNumber ||
            (initialValues.projectNumber && value === initialValues.projectNumber)
          ) {
            return 'Ein Projekt kann nicht das übergeordnete Projekt von sich selbst sein!';
          }
        }}
        onBlur={(e: any) => {
          if (e.target.value) {
            getProjectName({ variables: { projectNumber: e.target.value } });
          }
        }}
      />
      {result && result.data && result.data.project && (
        <Field
          name="parent.projectName"
          label="Übergeordnetes Projekt - Name"
          shrink
          readOnly
          component={FormikTextField}
          inputProps={{ value: result.data.project.projectName, tabIndex: -1 }}
        />
      )}
    </>
  );
};

export const CatalogField: React.FC = () => {
  const classes = useStyles();

  return (
    <Field name="catalog">
      {({ field, form }: any) => (
        <FormControl className={classes.catalogFormControl}>
          <InputLabel shrink htmlFor={field.name}>
            Standardkatalog
          </InputLabel>
          <CatalogSelect
            {...field}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (e) {
                form.setFieldValue(field.name, e.target.value);
              }
            }}
          />
        </FormControl>
      )}
    </Field>
  );
};
