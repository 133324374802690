import ApolloClient from 'apollo-client';
import { Maybe } from 'graphql/jsutils/Maybe';
import { difference } from 'lodash';
import { ISearchSubmitProps } from '../../../../../components/Search/Search';
import { UPDATE_DATA_TABLE_ROWS } from '../../../../../services/graphql-client';
import { GetDataTable_dataTable_rows } from '../../../../../services/types/GetDataTable';
import { GetDataTables_dataTables } from '../../../../../services/types/GetDataTables';
import {
  UpdateDataTableRows,
  UpdateDataTableRowsVariables,
} from '../../../../../services/types/UpdateDataTableRows';
import { SearchInput, TableType } from '../../../../../types/graphql';
import { deepCopy } from '../../../../../utils/deepCopy';
import { IPaginationHelperFns } from '../../../../../utils/paginationHelpers';
import { MISSION_SEARCH_QUERY } from './queries';
import {
  MissionSearchQuery,
  MissionSearchQuery_missions,
  MissionSearchQueryVariables,
} from './types/MissionSearchQuery';

const fetchMissions =
  (client: ApolloClient<any>) => (projectNumber: string) => async (searchInput: SearchInput) => {
    const {
      data: { missions },
    } = deepCopy(
      await client.query<MissionSearchQuery, MissionSearchQueryVariables>({
        query: MISSION_SEARCH_QUERY,
        variables: {
          search: searchInput,
          projectNumber,
        },
      }),
    );

    return missions;
  };

const updateVisibility =
  (client: ApolloClient<any>) =>
  (tableType: TableType) =>
  (missionRows: GetDataTable_dataTable_rows[]) =>
  async (missionsMatchingSearch: MissionSearchQuery_missions[]) => {
    const missionIdsMatchingSearch = missionsMatchingSearch.map((mission) => mission.id);
    const allMissionIds = missionRows.map((missionRow) => parseInt(missionRow.id, 10));

    const missionIdsToEvict = difference(allMissionIds, missionIdsMatchingSearch);

    const missionRowsWithNewVisibility = missionRows.map((missionRow) => {
      const willBeHidden = missionIdsToEvict.includes(parseInt(missionRow.id, 10));
      const isHidden = !!missionRow.hidden;

      if (isHidden && !willBeHidden) {
        missionRow.hidden = false;
      }

      if (!isHidden && willBeHidden) {
        missionRow.hidden = true;
      }

      return missionRow;
    });

    await client.query<UpdateDataTableRows, UpdateDataTableRowsVariables>({
      query: UPDATE_DATA_TABLE_ROWS,
      variables: {
        where: { id: tableType },
        data: missionRowsWithNewVisibility.map((missionRow) => ({
          where: { tableType, id: missionRow.id },
          data: {
            hidden: missionRow.hidden,
          },
        })),
      },
    });
  };

export const onSearchMission =
  (client: ApolloClient<any>) =>
  (tableType: TableType) =>
  (projectNumber: string) =>
  (setLoading: (v: boolean) => void, setSearchState: (searchProps: ISearchSubmitProps) => void) =>
  (dataTable: Maybe<GetDataTables_dataTables>): IPaginationHelperFns['onSearch'] =>
  async (searchInput) => {
    if (!dataTable) {
      return;
    }
    setLoading(true);
    setSearchState(searchInput);

    const missionRows = deepCopy(dataTable.rows);

    const missionsMatchingSearch = await fetchMissions(client)(projectNumber)(searchInput);

    await updateVisibility(client)(tableType)(missionRows)(missionsMatchingSearch);

    setLoading(false);
  };
