import React, { Component, ReactChild } from 'react';
import RootRef from '@material-ui/core/RootRef';

interface IDraggingContext {
  isDragOccurring: boolean;
  registerDroppableData: (...args: any) => void;
}

export const DraggingContext = React.createContext<IDraggingContext>({
  isDragOccurring: false,
  registerDroppableData: () => {
    return;
  },
});

interface ILockCellProps {
  children: ReactChild;
  draggableId?: string;
}

class LockDimensionsOnDrag extends Component<ILockCellProps> {
  ref = React.createRef<HTMLElement>();
  oldIsDragOccurring = false;

  getSnapshotBeforeUpdate() {
    if (!this.ref.current) {
      return null;
    }

    // this is buggy because `this.props.draggableId` gets undefined in some cases?!
    // if (this.props.draggableId && (this.props.draggableId !== this.context.draggableId)) {
    //   return null;
    // }

    const isDragStarting = this.context.isDragOccurring && !this.oldIsDragOccurring;
    this.oldIsDragOccurring = this.context.isDragOccurring;
    if (!isDragStarting) {
      return null;
    }

    const { width, height } = this.ref.current.getBoundingClientRect();

    const snapshot = {
      width,
      height,
      widthInlineSet: !!this.ref.current.style.width,
    };

    return snapshot;
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    const ref = this.ref.current;
    if (!ref) {
      return;
    }

    if (snapshot) {
      if (ref.style.width === snapshot.width) {
        return;
      }
      ref.style.width = `${snapshot.width}px`;
      ref.style.height = `${snapshot.height}px`;
      return;
    }

    if (this.context.isDragOccurring) {
      return;
    }

    // inline styles not applied
    if (ref.style.width === null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    ref.style.removeProperty('height');
    if (snapshot && !snapshot.widthInlineSet) {
      ref.style.removeProperty('width');
    }
  }

  render() {
    return <RootRef rootRef={this.ref}>{React.Children.only(this.props.children)}</RootRef>;
  }
}

LockDimensionsOnDrag.contextType = DraggingContext;

export default LockDimensionsOnDrag;
