import React, { useEffect } from 'react';
import { FieldProps, FormikHelpers } from 'formik';
import FormikSelect, { ISelectWithLabelProps } from '../../../Form/FormikSelect';
import { useQuery } from 'react-apollo';
import { LOCATION_SELECT_QUERY } from './LocationSelect.queries';
import { LocationSelect as TLocationSelect, LocationSelectVariables } from './types/LocationSelect';
import { MenuItem } from '@material-ui/core';
import { useValidLocations } from './LocationSelect.utils';

interface IProps extends FieldProps, ISelectWithLabelProps {
  billOfQuantityId: string;
  sourceLocationId: string;
  setValues?: FormikHelpers<{ targetLocationId: string }>['setValues'];
  selectFirst?: boolean;
}

export const LocationSelect: React.FC<IProps> = ({
  billOfQuantityId,
  children,
  sourceLocationId,
  setValues,
  selectFirst,
  ...field
}) => {
  const { data, loading, error } = useQuery<TLocationSelect, LocationSelectVariables>(
    LOCATION_SELECT_QUERY,
    { variables: { billOfQuantityId } },
  );

  const locations = useValidLocations(data, sourceLocationId);

  // get the default target location id from the bill of quantity
  const defaultTargetLocationId = data?.billOfQuantity?.defaultLocation?.id;
  // set the default target location once the data is available
  useEffect(() => {
    if (defaultTargetLocationId && selectFirst) {
      if (setValues) {
        setValues({ targetLocationId: defaultTargetLocationId });
      } else {
        console.warn('form helper not provided');
      }
    }
  }, [defaultTargetLocationId, setValues, selectFirst]);

  if (!data || loading) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <FormikSelect {...field}>
      {locations.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </FormikSelect>
  );
};
