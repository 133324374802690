import gql from 'graphql-tag';

export const CREATE_ITEMS_FROM_CATALOG = gql`
  mutation CreateItemsFromCatalog(
    $catalog: CatalogWhereUniqueInput!
    $targetLocation: LocationWhereUniqueInput!
  ) {
    createItemsFromCatalog(catalog: $catalog, targetLocation: $targetLocation) {
      id
    }
  }
`;
