import { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import { isNil, last } from 'lodash';
import React, { useCallback } from 'react';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import { useBoqEntityType } from '../../hooks/useBoqEntityType';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import { useProjectNumber } from '../../hooks/useProjectNumber';
import { refetchBillBoqDropdown } from '../../pages/Bills/BillDetails';
import { UPDATE_DATA_TABLE_ROW } from '../../services/graphql-client';
import {
  UpdateDataTableRow,
  UpdateDataTableRowVariables,
} from '../../services/types/UpdateDataTableRow';
import { BillOfQuantityEntityType, BOQStatus, Role, TableType } from '../../types/graphql';
import { forceDataTableRefetch } from '../../utils/paginationHelpers/forceDataTableRefetch';
import PageWrapper from '../PageWrapper';
import BillOfQuantityDetailsForm from './BillOfQuantityDetailsForm';
import { executeForPopulatedBoqTableTypes } from './BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import {
  buildBoqTableRowId,
  mapBillOfQuantityToData,
} from './BllOfQuantityTable/utils/paginationHelper/mapper';
import { BILL_OF_QUANTITY_FRAGMENT } from './BllOfQuantityTable/utils/paginationHelper/queries';
import { BillOfQuantity, BillOfQuantityVariables } from './types/BillOfQuantity';
import { CreateOrderFromOffer, CreateOrderFromOfferVariables } from './types/CreateOrderFromOffer';
import { UpdateBillOfQuantity, UpdateBillOfQuantityVariables } from './types/UpdateBillOfQuantity';
import { CreateOfferFromOrder, CreateOfferFromOrderVariables } from './types/CreateOfferFromOrder';

export const BILL_OF_QUANTITY_QUERY = gql`
  query BillOfQuantity($id: ID!) {
    billOfQuantity(where: { id: $id }) {
      id
      date
      name
      createdAt
      colorCodings
      hasScaleDiscount
      hasScaleDiscountAccumulate
      taskTypes
      type
      freeTextOne
      freeTextTwo
      freeTextThree
      freeTextFour
      isBonusApplicable
      isOfferBlocked
      entityType
      statuses {
        id
        comment
        createdAt
        effektivSum
        notReceived
        reason
        setBy {
          id
          name
        }
        status
      }
    }
  }
`;

const BILL_OF_QUANTITY_UPDATE_MUTATION = gql`
  mutation UpdateBillOfQuantity($data: BillOfQuantityUpdateInput!, $id: ID!) {
    updateBillOfQuantity(data: $data, id: $id) {
      ...billOfQuantityFields
      defaultLocation {
        id
        hasItemsOrLocations
      }
    }
  }
  ${BILL_OF_QUANTITY_FRAGMENT}
`;

const CREATE_ORDER_FROM_OFFER_MUTATION = gql`
  mutation CreateOrderFromOffer($projectNumber: String!, $offerId: String!) {
    createOrderFromOffer(projectNumber: $projectNumber, offerId: $offerId) {
      id
    }
  }
`;

const CREATE_OFFER_FROM_ORDER_MUTATION = gql`
  mutation CreateOfferFromOrder($projectNumber: String!, $orderId: String!) {
    createOfferFromOrder(projectNumber: $projectNumber, orderId: $orderId) {
      id
    }
  }
`;

const BillOfQuantityDetails: React.FunctionComponent<RouteComponentProps> = ({
  match,
  history,
}) => {
  const { id } = match.params as any;
  const projectNumber = useProjectNumber();
  const client = useApolloClient();
  const entityType = useBoqEntityType();

  const { data, loading, error } = useQuery<BillOfQuantity, BillOfQuantityVariables>(
    BILL_OF_QUANTITY_QUERY,
    {
      variables: { id },
    },
  );

  const user = useLoggedInUser();

  const updateBillOfQuantityInCache = useCallback<MutationUpdaterFn<UpdateBillOfQuantity>>(
    async (cache, { data }) => {
      if (!data || !projectNumber) {
        return;
      }

      const { updateBillOfQuantity: updatedBillOfQuantity } = data;
      await executeForPopulatedBoqTableTypes(
        client,
        projectNumber,
        async (tableType) => {
          // wrap in try-catch because boq dataTable is not in cache when user reloads while being in edit-form
          try {
            await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
              query: UPDATE_DATA_TABLE_ROW,
              variables: {
                where: {
                  id: buildBoqTableRowId(tableType)(updatedBillOfQuantity.defaultLocation.id),
                  tableType,
                },
                data: {
                  data: JSON.stringify(mapBillOfQuantityToData(updatedBillOfQuantity)),
                  hidden: last(updatedBillOfQuantity.statuses)?.status === BOQStatus.L8,
                },
              },
            });
          } catch {
            // skip
          }
        },
        entityType,
      );
    },
    [client, projectNumber, entityType],
  );

  const [updateBillOfQuantity] = useMutation<UpdateBillOfQuantity, UpdateBillOfQuantityVariables>(
    BILL_OF_QUANTITY_UPDATE_MUTATION,
    {
      update: updateBillOfQuantityInCache,
    },
  );

  const [createOrderFromOffer] = useMutation<CreateOrderFromOffer, CreateOrderFromOfferVariables>(
    CREATE_ORDER_FROM_OFFER_MUTATION,
  );
  const [createOfferFromOrder] = useMutation<CreateOfferFromOrder, CreateOfferFromOrderVariables>(
    CREATE_OFFER_FROM_ORDER_MUTATION,
  );

  const handleOrderCreation = useCallback(
    (values: any) => {
      const statuses = data?.billOfQuantity.statuses;

      if (!statuses || !values.statuses || !projectNumber) {
        return;
      }

      const currentStatus = statuses[statuses.length - 1];

      if (
        currentStatus &&
        currentStatus.status !== BOQStatus.L3 &&
        values.statuses.status === BOQStatus.L3
      ) {
        createOrderFromOffer({
          variables: { offerId: data.billOfQuantity.id, projectNumber },
        });
        forceDataTableRefetch(TableType.ORDER, client);
        forceDataTableRefetch(TableType.ORDER_MEASUREMENT, client);
        forceDataTableRefetch(TableType.ORDER_MISSION, client);
      }
    },
    [client, createOrderFromOffer, data, projectNumber],
  );

  const handleOrderToOfferCopy = useCallback(async () => {
    if (!data?.billOfQuantity.id || !projectNumber) {
      return;
    }

    await createOfferFromOrder({
      variables: { orderId: data.billOfQuantity.id, projectNumber },
    });
    forceDataTableRefetch(TableType.OFFER, client);
    history.push(`/projekte/${projectNumber}/offerten`);
  }, [client, createOfferFromOrder, data, history, projectNumber]);

  if (loading || !data) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  const isFormDisabled =
    (data.billOfQuantity.isOfferBlocked ?? false) && user?.role !== Role.SUPER_ADMIN;

  return (
    <PageWrapper>
      <BillOfQuantityDetailsForm
        entityType={data.billOfQuantity.entityType ?? BillOfQuantityEntityType.OFFER}
        isDisabled={isFormDisabled}
        initialValues={data.billOfQuantity}
        handleOrderToOfferCopy={handleOrderToOfferCopy}
        onSubmit={async (data) => {
          handleOrderCreation(data);

          // if user changes scale discount
          if (!isNil(data.hasScaleDiscount)) {
            if (entityType === BillOfQuantityEntityType.OFFER) {
              forceDataTableRefetch(TableType.OFFER, client);
            } else {
              executeForPopulatedBoqTableTypes(client, projectNumber!, (tableType) =>
                forceDataTableRefetch(tableType, client),
              );
            }
          }

          const updatedBoq = await updateBillOfQuantity({
            variables: {
              data,
              id,
            },
          });

          refetchBillBoqDropdown(client, projectNumber!);

          return updatedBoq;
        }}
        onClose={() => history.goBack()}
      />
    </PageWrapper>
  );
};

export default BillOfQuantityDetails;
