import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import { GpsFieldType } from './GpsFields';
import DialogCloseable from '../Dialog/DialogCloseable';
import MobileMap from '../../mobile/components/Map/MobileMap';
import { LatLng } from 'leaflet';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dialog: {
    padding: 0,
  },
});

interface IProps extends DialogProps {
  onMapSubmit: (cordinate: string) => void;
  gbsFieldType: GpsFieldType;
  coordinate: string;
}

const MapDialog: React.FC<IProps> = ({ onMapSubmit, gbsFieldType, coordinate, ...dialogProps }) => {
  const classes = useStyles();

  const [latString, lngString] = coordinate.split(',');

  const lat = parseFloat(latString);
  const lng = parseFloat(lngString);

  const isValidCodinate = !isNaN(lat) && !isNaN(lng);

  const getTitle = () => {
    let name;
    switch (gbsFieldType) {
      case GpsFieldType.NODE:
        name = 'Knoten';
        break;
      case GpsFieldType.END:
        name = 'Endpunkt';
        break;
      case GpsFieldType.START:
        name = 'Startpunkt';
        break;
      default:
        name = 'Koordinate';
        break;
    }
    return name + ' hinzufügen';
  };

  return (
    <DialogCloseable
      {...dialogProps}
      fullScreen
      title={getTitle()}
      mobile={true}
      contentClassName={classes.dialog}
    >
      <MobileMap
        coordinates={isValidCodinate ? new LatLng(lat, lng) : undefined}
        onSubmit={(cordinate) => {
          onMapSubmit(cordinate);
        }}
      />
    </DialogCloseable>
  );
};

export default MapDialog;
