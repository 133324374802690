import React, { useMemo } from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const PasteLocationIcon: React.FC<SvgIconProps & { disabled?: boolean }> = (props) => {
  const style = useMemo(() => {
    return { ...(props.style ?? {}), opacity: props.style?.opacity ?? (props.disabled ? 0.75 : 1) };
  }, [props.style, props.disabled]);
  return (
    <>
      <SvgIcon {...props} viewBox="0 0 38 36" shapeRendering="geometricPrecision" style={style}>
        <defs>
          <style>
            {
              '.cls-1,.cls-8{fill:#757575;}.cls-2{isolation:isolate;}.cls-3{opacity:0.77;mix-blend-mode:multiply;}.cls-4{fill:#979693;}.cls-5{clip-path:url(#clip-path);}.cls-6{clip-path:url(#clip-path-2);}.cls-7{clip-path:url(#clip-path-3);}.cls-8{stroke:#757575;stroke-miterlimit:10;}'
            }
          </style>
          <clipPath id="clip-path">
            <polygon
              className="cls-1"
              points="6 14.13 5.29 14.84 8.09 17.64 2 17.64 2 18.64 8.09 18.64 5.29 21.43 6 22.14 10 18.14 6 14.13"
            />
          </clipPath>
          <clipPath id="clip-path-2">
            <rect
              className="cls-1"
              x="-0.5"
              y="11.63"
              width="13"
              height="13"
              transform="translate(-0.49 0.17) rotate(-1.57)"
            />
          </clipPath>
          <clipPath id="clip-path-3">
            <rect
              className="cls-1"
              x="2"
              y="14.13"
              width="8"
              height="8"
              transform="translate(-0.49 0.17) rotate(-1.57)"
            />
          </clipPath>
          <image
            id="image"
            width="97"
            height="75"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGEAAABLCAYAAABgMenzAAAACXBIWXMAAAsSAAALEgHS3X78AAACe0lEQVR4Xu3bwW7TQBDG8ZlZ18RLI4RcJG7kwPP1FWK/abghgQVV2k1w7RkOpFwjkRR/h+93th1Lf+94fYhGhNCy7NwB9PoYAQAjAGAEAIwAgBEAMAIARgDACAAYAQAjAGAEAIwAgBEAMAIARgDACAAYAQAjAGAEAIwAgBEAMAIARgDACAAYAQAjAGAEAIwAgBEAMAKA6twB/5OqakRI3/d67th/td1uQ1UlgP6YoQj3oqradZ2KiO12u2qz2djj46OWUq4WI+cct7e3sdvtfLPZTCLiXdcFQgyICH3f2zAMNznnVUS8NbOVu9/Idcelm9mzux9V9amUcmzb9nm73fq5E1/b4uPotAos57wax7Gt6/rjNE2tqmZVrdz94tVgZhER0zRNJaU0jOP4Nec8iMj8ZwIu+yQuHiEi5P7+Pq1Wqyal9MHdP4vIJzN77+61mV0cISLCzMZ5nn+4+5eU0uzuZRiGY0TM585/bYtHEBHZ7/faNE0VEY2IvDOzO3e/E5E3InJxBBEJd/9lZikifopIo6rVfr+/xrUvBhFhvV5HREwichCRB3f/fnpaa7lShJeVoKoPInKIiGm9Xi86hl4sHkFVpeu6+XA4HMZx/FbXdTqNjau+E9x9EpFiZsPpdw5t286n7eq5S7yqxSNERPR976WUY855iIhjVVVfX3ZHZtfZIKmqp5Se3f1Y1/VTKeXYNI0v/VIWAdmi8jth+Xv4i1/MtJjrDFy6CCMAYAQAjACAEQAwAgBGAMAIABgBACMAYAQAjACAEQAwAgBGAMAIABgBACMAYAQAjACAEQAwAgBGAMAIABgBACMAYAQAjACAEQAwAgBGAPAbdKMv99mBzVMAAAAASUVORK5CYII="
          />
        </defs>
        <g className="cls-2">
          <g id="Ebene_1" data-name="Ebene 1">
            <g className="cls-3">
              <use transform="translate(-28 -6)" xlinkHref="#image" />
            </g>
            <rect className="cls-1" x="6.15" y="25.63" width="28.66" height="6.5" rx="1.5" />
            <path
              className="cls-1"
              d="M33.31,26.12a1,1,0,0,1,1,1v3.5a1,1,0,0,1-1,1H7.65a1,1,0,0,1-1-1v-3.5a1,1,0,0,1,1-1H33.31m0-1H7.65a2,2,0,0,0-2,2v3.5a2,2,0,0,0,2,2H33.31a2,2,0,0,0,2-2v-3.5a2,2,0,0,0-2-2Z"
            />
            <g className="cls-3">
              <image
                width="91"
                height="75"
                transform="translate(-22 -17)"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFsAAABLCAYAAAAf4PsCAAAACXBIWXMAAAsSAAALEgHS3X78AAACmklEQVR4Xu3aQW7aQBTG8fdmjA1UVlWSSs2qXXSVS/ho4DPkQMhnyI5Vu0qklKSRFYyNZ74u2qrdmUjhmcX3W89Y6K/R+IFQAEI23NACejuMbYixDTG2IcY2xNiGGNsQYxtibEOMbYixDTG2IcY2xNiGGNsQYxtibEOMbYixDTG2IcY2xNiGGNsQYxtibEOMbYixDTG2IcY2xNiGGNtQMrTAkqoqACnLUofWDlkul1BVwRn9AV3P4bOoqq5WKy2Kwn2rqqS7unKz2Uzrun519DzP0TQN0ru7+KUo+qqq4mq1wjlEP4vYZVm6xWIxybJs2nXdO+/9VKSbAHAi6dD2/3SiqlEkPYQQ9mmavrRtu398fDwsl8s4tPvURr9GVFXX67XbbDbTEHYXmXOfInAh4udwSIB49OlW9dCovUi/Sxy2fdjdZ9l8WxRF+H1DjXuyRo8NQG5ubrz3fgZMPsYkfgX0s4h8ULhUFMfHhgMcOhF9iirfYz8JQL+7vb3dAwhD+09t9NgiInmea13XiarORPS9iF4KcAlBppCjY0MAgbSi6kXkJ4BZjDHJ8/zoZ5zSWcSu6xre+x5AIxKfReSHqASFpnjVyVZA0YngSQTPqq5xzvV1XY96ffw1emxVlfV6HTabTRPC7sEF56PIk4jO4WIC6NGxReOfOxs7J9g6d3jwft5cX1+HP2Pg0BNOavTYAFCWZVwsFvssm2/brtt77+9Fugni66YR4N80cghhn6bTl7Zt91VVxbFfjiJnMvpxzh4Bv0HSm+EPUYYY2xBjG2JsQ4xtiLENMbYhxjbE2IYY2xBjG2JsQ4xtiLENMbYhxjbE2IYY2xBjG2JsQ4xtiLENMbYhxjbE2IYY2xBjG2JsQ4xtiLENMbahX5HwO9C5z8e0AAAAAElFTkSuQmCC"
              />
            </g>
            <path
              className="cls-4"
              d="M13.57,21.12c-.58,0-1.07-.68-1.07-1.5v-3.5c0-.81.49-1.5,1.07-1.5H33.74c.58,0,1.07.69,1.07,1.5v3.5c0,.82-.49,1.5-1.07,1.5Z"
            />
            <path
              className="cls-4"
              d="M33.74,15.12c.23,0,.57.39.57,1v3.5c0,.61-.34,1-.57,1H13.57c-.23,0-.57-.39-.57-1v-3.5c0-.61.34-1,.57-1H33.74m0-1H13.57a1.83,1.83,0,0,0-1.57,2v3.5a1.82,1.82,0,0,0,1.57,2H33.74a1.82,1.82,0,0,0,1.57-2v-3.5a1.83,1.83,0,0,0-1.57-2Z"
            />
            <g className="cls-3">
              <use transform="translate(-28 -28)" xlinkHref="#image" />
            </g>
            <rect className="cls-1" x="6.17" y="3.88" width="28.66" height="6.5" rx="1.5" />
            <path
              className="cls-1"
              d="M33.33,4.38a1,1,0,0,1,1,1v3.5a1,1,0,0,1-1,1H7.67a1,1,0,0,1-1-1V5.38a1,1,0,0,1,1-1H33.33m0-1H7.67a2,2,0,0,0-2,2v3.5a2,2,0,0,0,2,2H33.33a2,2,0,0,0,2-2V5.38a2,2,0,0,0-2-2Z"
            />
            <polygon
              className="cls-1"
              points="6 14.13 5.29 14.84 8.09 17.64 2 17.64 2 18.64 8.09 18.64 5.29 21.43 6 22.14 10 18.14 6 14.13"
            />
            <g className="cls-5">
              <g className="cls-2">
                <rect
                  className="cls-1"
                  x="-0.5"
                  y="11.63"
                  width="13"
                  height="13"
                  transform="translate(-0.49 0.17) rotate(-1.57)"
                />
                <g className="cls-6">
                  <rect
                    className="cls-1"
                    x="2"
                    y="14.13"
                    width="8"
                    height="8"
                    transform="translate(-0.49 0.17) rotate(-1.57)"
                  />
                  <g className="cls-7">
                    <rect
                      className="cls-1"
                      x="-0.5"
                      y="11.63"
                      width="13"
                      height="13"
                      transform="translate(-0.49 0.17) rotate(-1.57)"
                    />
                  </g>
                </g>
              </g>
            </g>
            <line className="cls-8" x1="2.5" y1="11.13" x2="2.5" y2="18.13" />
          </g>
        </g>
      </SvgIcon>
    </>
  );
};
