import React from 'react';
import EmployeeForm from '../../components/Employee/EmployeeForm';
import PaperWrapper from '../../components/PageWrapper';
import { useMutation } from 'react-apollo';
import { CREATE_EMPLOYEE_MUTATION, GET_EMPLOYEES } from './employee.queries';
import { CreateEmployee, CreateEmployeeVariables } from './types/CreateEmployee';
import { useHistory, useLocation } from 'react-router-dom';
import AppErrorMessage from '../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import { GET_ALL_EMPLOYES } from '../../components/Employee/EmployeeForm.queries';
import { GET_ACCOUNTING_EMPLOYEES } from '../AccountingLog/AccountingLogger/AccountingLogger.queries';
import { USE_COUNT_QUERIES } from '../../hooks/useCount/useCount.queries';
import { omit } from 'lodash';

const CreateEmployeePage = () => {
  const history = useHistory();
  const { state } = useLocation();

  const [createEmployee, { error }] = useMutation<CreateEmployee, CreateEmployeeVariables>(
    CREATE_EMPLOYEE_MUTATION,
    {
      refetchQueries: [
        { query: GET_EMPLOYEES, variables: state.variables },
        { query: GET_ALL_EMPLOYES },
        { query: GET_ACCOUNTING_EMPLOYEES },
        ...(state.variables
          ? [{ query: USE_COUNT_QUERIES.EMPLOYEE, variables: omit(state.variables, 'page') }]
          : []),
      ],
    },
  );

  return (
    <>
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <PaperWrapper header={{ title: 'Mitarbeiter erstellen', onClose: () => history.goBack() }}>
        <EmployeeForm
          onSubmit={async (data, setSubmitting) => {
            try {
              await createEmployee({ variables: { data } });
              setSubmitting(false);
              history.goBack();
            } catch (e) {
              setSubmitting(false);
            }
          }}
        />
      </PaperWrapper>
    </>
  );
};

export default CreateEmployeePage;
