export const BILL_COMPUTED_FIELDS = [
  'billItems',
  'billOfQuantity',
  'publisher',
  'updater',
  'netBillSum',
  'grossBillSum',
  'billSumWithVat',
  'isFixedBill',
  'hasBillItems',
  'shippingAddress',
  'billingAddress',
  'skontoDeadline',
  'paymentDeadline',
  'project.projectNumber',
  'project.projectName',
  'invoiceConditions',
];
