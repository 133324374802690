import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import BillOfQuantityDetailsForm from './BillOfQuantityDetailsForm';
import DialogCloseable from '../Dialog/DialogCloseable';
import gql from 'graphql-tag';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import {
  DuplicateBillOfQuantityVariables,
  DuplicateBillOfQuantity,
  DuplicateBillOfQuantity_duplicateBillOfQuantity,
} from './types/DuplicateBillOfQuantity';
import { BILL_OF_QUANTITY_QUERY } from './BillOfQuantityDetails';
import { BillOfQuantity, BillOfQuantityVariables } from './types/BillOfQuantity';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { MutationUpdaterFn } from 'apollo-client';
import {
  BILL_OF_QUANTITY_FRAGMENT,
  BOQ_STRUCTURE_FRAGMENT,
  STRUCTURE_QUERY,
} from './BllOfQuantityTable/utils/paginationHelper/queries';
import { ADD_ROW_TO_DATA_TABLE, mutateQueryCacheEntry } from '../../services/graphql-client';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../services/types/AddRowToDataTable';
import { BillOfQuantityEntityType, RowType } from '../../types/graphql';
import { mapBillOfQuantityToContainerRow } from './BllOfQuantityTable/utils/paginationHelper/mapper';
import { executeForPopulatedBoqTableTypes } from './BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import {
  BillOfQuantityStructureQuery,
  BillOfQuantityStructureQueryVariables,
} from './BllOfQuantityTable/utils/paginationHelper/types/BillOfQuantityStructureQuery';
import { DataProxy } from 'apollo-cache';

const DUPLICATE_BILL_OF_QUANTITY_MUTATION = gql`
  mutation DuplicateBillOfQuantity(
    $oldBillOfQuantityId: String!
    $projectNumber: String!
    $data: BillOfQuantityUpdateInput!
  ) {
    duplicateBillOfQuantity(
      oldBillOfQuantityId: $oldBillOfQuantityId
      projectNumber: $projectNumber
      data: $data
    ) {
      ...billOfQuantityFields
      ...boqStructureFields
    }
  }
  ${BILL_OF_QUANTITY_FRAGMENT}
  ${BOQ_STRUCTURE_FRAGMENT}
`;

interface IProps {
  billOfQuantityId: string;
  entityType: BillOfQuantityEntityType;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: theme.spacing(4),
  },
}));

export const BillOfQuantityDuplicateForm: React.FC<IProps> = ({
  billOfQuantityId,
  onClose,
  entityType,
}) => {
  const classes = useStyles();

  const { projectNumber } = useParams();
  const client = useApolloClient();

  const { data, error } = useQuery<BillOfQuantity, BillOfQuantityVariables>(
    BILL_OF_QUANTITY_QUERY,
    {
      variables: { id: billOfQuantityId },
    },
  );

  /**
   * add the duplicated billOfQuantity to the
   * cached query response of BillOfQuantity-Tab structure query
   */
  const addDuplicatedBoqToStructureQueryCache = useCallback(
    (
      cache: DataProxy,
      duplicatedBillOfQuantity: DuplicateBillOfQuantity_duplicateBillOfQuantity,
    ) => {
      mutateQueryCacheEntry<BillOfQuantityStructureQuery, BillOfQuantityStructureQueryVariables>(
        cache,
        STRUCTURE_QUERY,
        { projectNumber, entityType },
        (cacheEntry) => {
          cacheEntry.billOfQuantities.push(duplicatedBillOfQuantity);
        },
      );
    },
    [entityType, projectNumber],
  );

  const addDuplicatedBillOfQuantityToCache = useCallback<
    MutationUpdaterFn<DuplicateBillOfQuantity>
  >(
    async (cache, { data }) => {
      if (!data?.duplicateBillOfQuantity) {
        return;
      }

      const { duplicateBillOfQuantity: duplicatedBillOfQuantity } = data;

      addDuplicatedBoqToStructureQueryCache(cache, duplicatedBillOfQuantity);

      await executeForPopulatedBoqTableTypes(
        client,
        projectNumber,
        async (tableType) => {
          const row = mapBillOfQuantityToContainerRow(tableType)(duplicatedBillOfQuantity);

          await client.query<AddRowToDataTable, AddRowToDataTableVariables>({
            query: ADD_ROW_TO_DATA_TABLE,
            variables: {
              data: [{ row, type: RowType.CONTAINER }],
              where: {
                id: tableType,
              },
            },
          });
        },
        entityType,
      );
    },
    [client, projectNumber, addDuplicatedBoqToStructureQueryCache, entityType],
  );

  const [duplicateBillOfQuantity, { error: mutationError }] = useMutation<
    DuplicateBillOfQuantity,
    DuplicateBillOfQuantityVariables
  >(DUPLICATE_BILL_OF_QUANTITY_MUTATION, {
    update: addDuplicatedBillOfQuantityToCache,
  });

  if (error) {
    console.log(error);
  }

  if (mutationError) {
    console.log(mutationError);
  }

  if (!data || !data.billOfQuantity) {
    return null;
  }

  return (
    <DialogCloseable
      open={!!billOfQuantityId}
      title="LV duplizieren"
      onClose={onClose}
      contentClassName={classes.dialogContent}
    >
      <BillOfQuantityDetailsForm
        disableDirtyCheck
        getAllValues
        hideHeader
        customSaveButtonLabel="Duplizieren"
        initialValues={data.billOfQuantity}
        onClose={onClose}
        entityType={BillOfQuantityEntityType.OFFER}
        onSubmit={({
          id,
          __typename,
          colorCodings,
          createdAt,
          isOfferBlocked,
          entityType,
          ...data
        }) => {
          return duplicateBillOfQuantity({
            variables: { oldBillOfQuantityId: billOfQuantityId, projectNumber, data },
          });
        }}
      />
    </DialogCloseable>
  );
};
