import { BillTable_bills } from './types/BillTable';
import { IDataTableRow } from '../../DataTable/types';

export const mapBills = (bills: BillTable_bills[]) =>
  bills
    .map(
      (bill): IDataTableRow => ({
        id: bill.id,
        data: {
          ...bill,
          // use the complete key path for the sorting
          'project.projectName': bill.project.projectName,
          'project.projectNumber': bill.project.projectNumber,
        },
      }),
    )
    .reduce((acc, curr) => [...acc, curr], new Array<IDataTableRow>());
