import { Grid, MenuItem } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import { defaults, isEmpty, isNil, mapValues, pick } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { useApolloClient } from 'react-apollo';
import { useProjectNumber } from '../../../hooks/useProjectNumber';
import { Noop } from '../../../types';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import { getChangedFormValues } from '../../../utils/form/getChanged';
import FormikSelect from '../../Form/FormikSelect';
import FormikTextField from '../../Form/FormikTextField';
import AppErrorMessage from '../../Page/AppErrorMessage';
import AppProgress from '../../Page/AppProgress';
import { INVALID_SALUTATION_CODE, SALUTATION_MAP } from '../constants';
import { GET_ADDRESS_BOOK } from '../query';
import {
  FullAddressBookItem_address,
  FullAddressBookItem_contactPerson,
} from '../types/FullAddressBookItem';
import { GetAddressBook } from '../types/GetAddressBook';
import { useContactEditFetch } from './fetch';

interface IProps {
  address?: Maybe<FullAddressBookItem_address>;
  activeContact?: Maybe<FullAddressBookItem_contactPerson>;
  addressBookItemId?: number;
  disabled?: Maybe<boolean>;
}
type FormValues = Record<keyof typeof DEFAULT_VALUES, null | string>;

const DEFAULT_VALUES = {
  salutationCode: '',
  firstName: '',
  name: '',
  phone: '',
  email: '',
  phoneTwo: '',
  phoneFour: '',
};

const createHandleOnKeyUp = (submitForm: Noop) => (e: KeyboardEvent) => {
  const isEnter = e.key === 'Enter';
  if (isEnter) {
    submitForm();
  }
};

export const ContactEditForm: FC<IProps> = ({
  address,
  activeContact,
  addressBookItemId,
  disabled,
}) => {
  const { loading, error, executeUpsertContactPerson } = useContactEditFetch();
  const client = useApolloClient();
  const projectNumber = useProjectNumber();
  if (!projectNumber) {
    throw new Error();
  }

  const initialValues = useMemo(() => {
    if (!activeContact) {
      return DEFAULT_VALUES;
    }

    return mapValues(
      defaults(
        {
          ...pick(activeContact, ['salutationCode', 'phone', 'phoneTwo', 'phoneFour', 'email']),
          ...pick(activeContact.address, ['firstName', 'name']),
        },
        DEFAULT_VALUES,
      ),
      (value) => (value ? String(value) : ''),
    ) as FormValues; // map null to empty string)
  }, [activeContact]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const changedValues = getChangedFormValues({ initialValues, values });

      if (isEmpty(changedValues) || !address || !addressBookItemId) {
        return;
      }

      if (!isNil(changedValues.salutationCode)) {
        changedValues.salutationCode = parseInt(changedValues.salutationCode, 10);

        if (changedValues.salutationCode === INVALID_SALUTATION_CODE) {
          changedValues.salutationCode = null;
        }
      }

      await executeUpsertContactPerson({
        variables: {
          where: {
            id: activeContact ? parseInt(activeContact.id, 10) : null,
            address: { id: parseInt(address.id) },
            addressBookItem: { id: addressBookItemId },
          },
          data: changedValues,
        },
      });

      if (!activeContact) {
        await client.queryManager.query<GetAddressBook>({
          query: GET_ADDRESS_BOOK,
          variables: { projectNumber },
          fetchPolicy: 'network-only',
        });
      }
    },
    [
      initialValues,
      executeUpsertContactPerson,
      activeContact,
      address,
      addressBookItemId,
      client,
      projectNumber,
    ],
  );

  return (
    <>
      {loading && <AppProgress />}
      <AppErrorMessage message={errorPrefixRemover(error?.message ?? '')} isOpen={!!error} />
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ submitForm }) => (
          <Form>
            <Grid container xs={12}>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={5}>
                  <Field
                    name="salutationCode"
                    label="Anrede"
                    component={FormikSelect}
                    disabled={disabled}
                    onBlur={submitForm}
                  >
                    <MenuItem value={INVALID_SALUTATION_CODE}> </MenuItem>
                    {Object.entries(SALUTATION_MAP).map(([code, { shortSalutation }]) => (
                      <MenuItem key={shortSalutation} value={code}>
                        {shortSalutation}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={5}>
                  <Field
                    name="firstName"
                    label="Vorname"
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name="name"
                    label="Name"
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={5}>
                  <Field
                    name="phoneTwo"
                    label="Tel. Nr."
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name="email"
                    label="Mail"
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={5}>
                  <Field
                    name="phoneFour"
                    label="Mobile. Nr."
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
