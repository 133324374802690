import React, { ReactChild } from 'react';
import { styled } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

export const SPLITSCREEN_ITEM_PADDING = 10;

const SplitScreenWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
});

const SplitScreenItem = styled(Paper)({
  height: '100%',
  flex: 1,
  padding: SPLITSCREEN_ITEM_PADDING,
  [`& + &`]: {
    // this does not work easily with a theme...
    marginLeft: 20,
  },
});

interface IProps {
  left: ReactChild;
  right: ReactChild;
}

const SplitScreen: React.FC<IProps> = ({ left, right }) => (
  <SplitScreenWrapper>
    <SplitScreenItem>{left}</SplitScreenItem>
    <SplitScreenItem>{right}</SplitScreenItem>
  </SplitScreenWrapper>
);

export default SplitScreen;
