import { head } from 'lodash';

export const getToken = () => {
  const token = head(
    [
      window.localStorage.getItem('__momo_storage') as string,
      window.sessionStorage.getItem('__momo_storage') as string,
    ].flatMap((storage) => {
      try {
        const token = JSON.parse(storage)?.auth?.token as string;
        return token ? [token] : [];
      } catch {
        return [];
      }
    }),
  );
  if (!token) {
    throw new Error('no token found');
  }
  return token;
};
