import { Toolbar } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { makeStyles } from '@material-ui/styles';
import { isNil } from 'lodash';
import React, { useRef } from 'react';
import { ActionType, IDispatchTable, ITableContext } from '..';
import ColumnsPopup from './ColumnsPopup';

interface IProps {
  context: ITableContext;
  dispatch: IDispatchTable;
}

const useStyles = makeStyles({
  popover: {
    position: 'fixed',
    top: '0px',
    zIndex: 100000,
  },
});

export const ColumnsButton: React.FC<IProps> = ({ dispatch, context }) => {
  const classes = useStyles();

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const isOpen = Boolean(context.state.columnSelectorAnchorEl);
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    dispatch({
      type: ActionType.SET_COLUMNS_SELECTOR_REF,
      payload: { element: isOpen ? null : e.currentTarget },
    });
  };

  const handleClickAway = () => {
    if (context.state.columnSelectorAnchorEl) {
      dispatch({ type: ActionType.SET_COLUMNS_SELECTOR_REF, payload: { element: null } });
    }
  };

  const getPopoverXPos = (): React.CSSProperties => {
    const x = buttonRef.current?.getBoundingClientRect().x;
    // 300 is the approximate width of most popovers
    // so if there isn't space for at least 300px to the right of the button just position it on the right edge
    if (isNil(x) || x + 300 > window.innerWidth) {
      return { right: 0 };
    }
    // add 110px so the popover isn't positioned above the table whose columns are being edited
    return { left: `${x + 110}px` };
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <span>
        {/* The ClickAwayListener expects a single React element as child. */}
        <Tooltip title={'Spalten anzeigen'}>
          <IconButton aria-label="Spalten anzeigen" onClick={handleClick} ref={buttonRef}>
            <ViewColumnIcon />
          </IconButton>
        </Tooltip>
        <div className={classes.popover} style={isOpen ? getPopoverXPos() : {}}>
          {isOpen && (
            <>
              <Toolbar />
              <ColumnsPopup tableData={context.tableData} dispatch={dispatch} />
            </>
          )}
        </div>
      </span>
    </ClickAwayListener>
  );
};

export default ColumnsButton;
