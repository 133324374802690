import React, { useRef, useReducer, useEffect, useState } from 'react';
import { Formik } from 'formik';
import Item from '../Item';
import { List, makeStyles, Grid, Theme, Box } from '@material-ui/core';
import { defaults, isNil } from 'lodash';
import {
  itemBoilerplateListReducer,
  IItemListState,
  IItemListAction,
  ItemListActionType,
  IItem,
} from './ItemBoilerplateList.reducer';
import { HoveringFab } from '../../HoveringButton';
import AddIcon from '@material-ui/icons/Add';
import * as Yup from 'yup';
import CatalogMaterialQuery from '../../../../components/Item/ItemImporter/ItemBoilerplateSearch/CatalogMaterialQuery';
import { GET_MOBILE_ITEMBOILERPLATES, GET_PROJECT_CATALOG } from './mobileItemImporter.queries';
import { useCurrentProject } from '../../Header/useCurrentProject';
import { useQuery } from 'react-apollo';
import {
  ProjectStandardCatalog,
  ProjectStandardCatalogVariables,
} from './types/ProjectStandardCatalog';
import { GetMobileItemboilerplates_catalog_itemBoilerplates } from './types/GetMobileItemboilerplates';
import { useSearchState, Search } from '../../../../components/Search/Search';
import { safeFocusOnClick } from './utils/safeFocusOnClick';
import InlineEditingFormikForm from '../../../../components/Form/InlineEditingFormikForm';

export interface ISubmittedItemBoilerplate {
  id: string;
  volume: number;
  appliedPrice?: number;
}

interface IProps {
  onSubmit: (values: ISubmittedItemBoilerplate[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  catalogDropdown: {
    float: 'right',
  },
  form: {
    clear: 'right',
  },
  searchWrapper: {
    padding: theme.spacing(1, 0),
  },
}));

const validationSchema = (items: IItem[]) =>
  Yup.object().shape(
    items.reduce((acc: any, curr: any) => {
      acc[`${curr.id}-volume`] = Yup.number().required('Menge ist verpflichtend!');

      return acc;
    }, {}),
  );

const mapItemsToFormValues = (items: any[]) => {
  return items.reduce((acc: any, curr) => {
    acc[`${curr.id}-volume`] = curr.volume ? curr.volume : '';
    return acc;
  }, {});
};

const ItemBoilerplateList: React.FC<IProps> = ({ onSubmit }) => {
  const ref = useRef<any>({});

  const classes = useStyles();

  const currentProject = useCurrentProject();

  const searchState = useSearchState();

  const [state, dispatch] = useReducer<React.Reducer<IItemListState, IItemListAction>>(
    itemBoilerplateListReducer,
    {
      items: [],
      activeItemId: '',
    },
  );

  const [idToSelect, setIdToSelect] = useState<string | undefined>();

  useEffect(() => {
    if (idToSelect) {
      /**
       * there is a delay between react updating the component
       * and the function that should select the value in the input field
       *
       * so we have to push a new message to the queue, that will be called later (at end of the queue)
       * this way we're sure that react had already finished updating the component
       *
       * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/EventLoop
       */
      setTimeout(() => {
        ref.current[idToSelect]?.querySelector('input')?.select();
        setIdToSelect(undefined);
      }, 0);
    }
  }, [idToSelect]);

  const {
    data: catalogData,
    loading: loadingCatalog,
    error: errorCatalog,
  } = useQuery<ProjectStandardCatalog, ProjectStandardCatalogVariables>(GET_PROJECT_CATALOG, {
    variables: {
      projectNumber: currentProject?.projectNumber || '',
    },
  });

  const handleClick = (item: GetMobileItemboilerplates_catalog_itemBoilerplates) => {
    dispatch({ type: ItemListActionType.SET_ACTIVE_ITEM_ID, payload: { id: item.id } });

    if (!isNil(item.predefinedVolume)) {
      dispatch({
        type: ItemListActionType.UPSERT_ITEM,
        payload: { id: item.id, volume: item.predefinedVolume },
      });

      setIdToSelect(item.id);
    }
  };

  if (!catalogData) {
    return null;
  }

  if (errorCatalog) {
    console.log(errorCatalog);
    return null;
  }

  if (loadingCatalog) {
    return null;
  }
  return (
    <CatalogMaterialQuery
      query={GET_MOBILE_ITEMBOILERPLATES}
      initCatalogId={catalogData.project.catalog?.id}
      catalogSelectorClassName={classes.catalogDropdown}
    >
      {({ data, refetch, loading }) => {
        return (
          <>
            <Grid container className={classes.searchWrapper}>
              <Search
                columns={['productNumber', 'material', 'descriptionOne', 'unit']}
                searchState={searchState}
                onSubmit={(search) => refetch({ search })}
                loading={loading}
              />
            </Grid>
            <Formik
              initialValues={
                // this way the user always sees the filled out Volume
                // even if the list was updated (by the search for example)
                defaults(
                  mapItemsToFormValues(state.items),
                  mapItemsToFormValues(data?.catalog?.itemBoilerplates ?? []),
                )
              }
              enableReinitialize
              validationSchema={validationSchema(state.items)}
              onSubmit={(_, { setSubmitting }) => {
                onSubmit(state.items);
                setSubmitting(false);
              }}
            >
              {({ values, submitForm, dirty }) => {
                return (
                  <InlineEditingFormikForm
                    dirty={dirty}
                    className={classes.form}
                    enterDownSubmitHandler={() => {
                      (document.activeElement as HTMLFormElement | null)?.blur?.();
                      // noinspection JSIgnoredPromiseFromCall
                      submitForm();
                    }}
                  >
                    <Box marginBottom={12.5}>
                      <List>
                        {data?.catalog?.itemBoilerplates.map(
                          (itemBoilerplate: any, idx: number) => (
                            <Item
                              filterText={searchState.searchTerm}
                              selected={!!values[`${itemBoilerplate.id}-volume`]}
                              key={idx}
                              fieldProps={{
                                name: `${itemBoilerplate.id}-volume`,
                                onClick: () => {
                                  handleClick(itemBoilerplate);
                                },
                                onBlur: () => {
                                  const volume = values[`${itemBoilerplate.id}-volume`];
                                  if (volume) {
                                    dispatch({
                                      type: ItemListActionType.UPSERT_ITEM,
                                      payload: {
                                        id: itemBoilerplate.id,
                                        volume: values[`${itemBoilerplate.id}-volume`],
                                      },
                                    });
                                  } else {
                                    dispatch({
                                      type: ItemListActionType.REMOVE_ITEM,
                                      payload: { id: itemBoilerplate.id },
                                    });
                                  }
                                  dispatch({
                                    type: ItemListActionType.SET_ACTIVE_ITEM_ID,
                                    payload: { id: '' },
                                  });
                                },
                              }}
                              item={itemBoilerplate}
                              onClick={safeFocusOnClick(true, true, () => {
                                handleClick(itemBoilerplate);
                              })}
                              fieldRef={(node: any) => (ref.current[itemBoilerplate.id] = node)}
                            />
                          ),
                        )}
                      </List>
                    </Box>
                    <HoveringFab disabled={state.items.length === 0}>
                      <AddIcon />
                    </HoveringFab>
                  </InlineEditingFormikForm>
                );
              }}
            </Formik>
          </>
        );
      }}
    </CatalogMaterialQuery>
  );
};

export default ItemBoilerplateList;
