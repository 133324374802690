import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import { EmployeesTable } from './EmployeesTable';
import { EmployeeFunctionsTable } from './EmployeeFunctionsTable';

const Employees: React.FC = () => {
  const [tabeValue, setTabeValue] = useState<number>(0);

  return (
    <>
      <Box marginBottom={2}>
        <Tabs value={tabeValue} onChange={(e, value: number) => setTabeValue(value)}>
          <Tab label={'Mitarbeiter'} value={0} />
          <Tab label={'Funktionen'} value={1} />
        </Tabs>
      </Box>
      {tabeValue === 0 && <EmployeesTable />}
      {tabeValue === 1 && <EmployeeFunctionsTable />}
    </>
  );
};

export default Employees;
