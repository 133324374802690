import React, { useCallback } from 'react';
import TablePagination, { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { IUsePaginationStateResult } from './Pagination.utils';

interface IProps {
  paginationState: IUsePaginationStateResult;
}

export type AvailableRowsPerPageOptions = 25 | 50 | 100;

const ROWS_PER_PAGE_OPTIONS: AvailableRowsPerPageOptions[] = [25, 50, 100];

type HandleOnChangeRowsPerPage = React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;

const mapDisplayedRowsLabel = ({ from, to, count }: LabelDisplayedRowsArgs) =>
  `${from}-${to} von ${count}`;

export const Pagination: React.FC<IProps> = ({
  paginationState: {
    setRowsPerPage,
    setPage,
    page,
    rowsPerPage,
    totalNumberOfRows,
    incrementPage,
    decrementPage,
  },
}) => {
  const handleOnChangeRowsPerPage = useCallback<HandleOnChangeRowsPerPage>(
    (e) => {
      setRowsPerPage(parseInt(e.target.value, 10) as AvailableRowsPerPageOptions);
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );

  const handleOnChangePage = useCallback(
    (_, newPage: number) => {
      const isIncrement = newPage > page;

      if (isIncrement) {
        incrementPage();
        return;
      }

      decrementPage();
    },
    [page, incrementPage, decrementPage],
  );

  return (
    <TablePagination
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      component="div"
      rowsPerPage={rowsPerPage}
      page={page}
      count={totalNumberOfRows}
      labelRowsPerPage="Zeilen pro Seite:"
      labelDisplayedRows={mapDisplayedRowsLabel}
      backIconButtonProps={{
        'aria-label': 'Vorherige Seite',
      }}
      nextIconButtonProps={{
        'aria-label': 'Nächste Seite',
      }}
      onChangePage={handleOnChangePage}
      onChangeRowsPerPage={handleOnChangeRowsPerPage}
    />
  );
};
