export enum MATERIALS_TRANSLATION_ENUM {
  materialNumber = 'Materialnummer',
  acronym = 'Kurzbezeichnung',
  nameOne = 'Bezeichnung 1',
  nameTwo = 'Bezeichnung 2',
  unit = 'Einheit',
  pricePerUnit = 'Preis pro Einheit',
  materialType = 'Materialart',
  color = 'Farbe',
  provider = 'Lieferant',
}
