import { SyntheticEvent } from 'react';

/**
 * create an item click handler
 * @param focus should the input element be focused?
 * @param select should the input element be selected?
 * @param andThen (optional) a handler that is called after focussing/selecting the input element.
 */
export const safeFocusOnClick =
  (focus: boolean, select: boolean, andThen?: (inputElement: HTMLInputElement) => void) =>
  (evt: SyntheticEvent<HTMLElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    // we need a ref to the input element which is a `input` in our sibling
    const input = evt.currentTarget.parentNode?.querySelector('input');
    if (input == null) {
      return;
    }
    // focusing on mobile safari is very picky, see https://stackoverflow.com/a/16601288/4354645
    // works by manually blurring the active element *and* focusing the new element *while* handling the click event
    if (focus) {
      (document.activeElement as HTMLElement | null)?.blur?.();
      input.focus();
    }
    // preselect the input, only works if the input is also focused
    if (select && input === document.activeElement) {
      input.select();
    }
    andThen?.(input);
  };
