import { getOperationName } from 'apollo-utilities';
import { useMutation } from 'react-apollo';
import { GET_ADDRESS_BOOK } from '../query';
import { DELETE_ADDRESS_BOOK_ITEM } from './query';

export const useDeleteAddressBookItem = () => {
  const [executeDeleteAddressBookItem, { loading, error }] = useMutation(DELETE_ADDRESS_BOOK_ITEM, {
    refetchQueries: [getOperationName(GET_ADDRESS_BOOK) ?? ''],
  });

  return {
    executeDeleteAddressBookItem,
    loading,
    error,
  };
};
