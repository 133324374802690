import React, { useCallback, useMemo } from 'react';
import ApolloClient, { MutationUpdaterFn } from 'apollo-client';
import { useApolloClient, useMutation } from 'react-apollo';
import { DELETE_MISSION_ITEM_MUTATION } from './DeleteMissionItemAction.queries';
import { DeleteMissionItemVariables, DeleteMissionItem } from './types/DeleteMissionItem';
import { Tooltip, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { IDataTableRow } from '../../../DataTable/types';
import { REMOVE_DATA_TABLE_ROW } from '../../../../services/graphql-client';
import {
  RemoveDataTableRow,
  RemoveDataTableRowVariables,
} from '../../../../services/types/RemoveDataTableRow';
import { TableType } from '../../../../types/graphql';
import { useProjectNumber } from '../../../../hooks/useProjectNumber';
import { PAGINATION_HELPERS } from '../../../../utils/paginationHelpers';
import { compositeLocationId } from '../../../BillOfQuantity/BllOfQuantityTable/utils/compositeLocationId';
import {
  executeForMissionTableTypes,
  executeForPopulatedMissionTableTypes,
} from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/executeForMissionTypes';
import {
  removeTableTypeFromId,
  swapTableType,
} from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { executeForPopulatedBoqTableTypes } from '../../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { buildBoqTableRowId } from '../../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/mapper';

interface IProps {
  missionItemRowId: string;
  disabled?: boolean;
  flattenedContainerRows?: IDataTableRow[];
}

const removeMissionItem = (client: ApolloClient<any>) => (missionItemId: string) => {
  return executeForMissionTableTypes((tableType) =>
    client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
      query: REMOVE_DATA_TABLE_ROW,
      variables: {
        where: { id: missionItemId, tableType },
      },
    }),
  );
};

const removeLocation = (client: ApolloClient<any>) => (containerTableId: string) => {
  return Promise.all([
    client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
      query: REMOVE_DATA_TABLE_ROW,
      variables: {
        where: { id: containerTableId, tableType: TableType.MISSION },
      },
    }),
    client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
      query: REMOVE_DATA_TABLE_ROW,
      variables: {
        where: { id: containerTableId, tableType: TableType.MEASUREMENT },
      },
    }),
  ]);
};

const refetchContainerInBoq =
  (projectNumber: string, client: ApolloClient<any>) =>
  (isInMission: boolean) =>
  (missionItemRow: IDataTableRow) => {
    const missionItemData = missionItemRow.data;
    const billOfQuantityId = missionItemData.item.billOfQuantity.id;
    const locationId: string = missionItemData.item.location.id;
    const parentLocationId = missionItemData.item.location.parentLocation?.id;

    const compositeId = isInMission
      ? locationId
      : compositeLocationId({ billOfQuantityId, locationId, parentLocationId });

    executeForPopulatedBoqTableTypes(client, projectNumber, (tableType) =>
      PAGINATION_HELPERS[projectNumber][tableType]?.refetchItemsInContainer([
        { containerId: buildBoqTableRowId(tableType)(compositeId) },
      ]),
    );
  };

export const DeleteMissionItemAction: React.FC<IProps> = ({
  missionItemRowId,
  disabled,
  flattenedContainerRows,
}) => {
  const client = useApolloClient();
  const projectNumber = useProjectNumber();
  const missionItemId = removeTableTypeFromId(missionItemRowId);

  const container = useMemo(() => {
    return flattenedContainerRows?.find((container) =>
      container.innerTableRows?.some((row) => row.id === missionItemRowId),
    );
  }, [flattenedContainerRows, missionItemRowId]);
  const missionItem = useMemo(() => {
    if (!container?.innerTableRows) {
      return;
    }

    return container.innerTableRows.find((row) => row.id === missionItemRowId);
  }, [container, missionItemRowId]);

  const containerHasEntriesAfterDelete = useMemo(() => {
    if (!container) {
      return false;
    }

    const hasItemsAfterDelete = container.innerTableRows!.length > 1;
    const hasLocationsAfterDelete = container.containerRows
      ? container.containerRows.length >= 1
      : false;

    return hasItemsAfterDelete || hasLocationsAfterDelete;
  }, [container]);

  const removeItemInCaches = useCallback(
    async (missionItemRowId: string) => {
      if (!projectNumber) {
        return;
      }

      await executeForPopulatedMissionTableTypes(client, projectNumber, (tableType) =>
        client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
          query: REMOVE_DATA_TABLE_ROW,
          variables: {
            where: { tableType, id: swapTableType(tableType)(missionItemRowId) },
          },
        }),
      );
    },
    [client, projectNumber],
  );

  const deleteMissionItemInCache = useCallback<MutationUpdaterFn<DeleteMissionItem>>(
    async (_, { data }) => {
      if (!data || !container || !projectNumber || !missionItem) {
        return;
      }
      const containerTableId = container.id;
      const containerCompositeId = removeTableTypeFromId(containerTableId);
      const missionId = parseInt(containerCompositeId.split('-')[0], 10);

      await removeMissionItem(client)(missionItem.id);

      const isInMission = !containerCompositeId.includes('-');
      if (!containerHasEntriesAfterDelete && !isInMission) {
        await removeLocation(client)(containerTableId);
      }

      await removeItemInCaches(missionItem.id);
      refetchContainerInBoq(projectNumber, client)(isInMission)(missionItem);
      executeForMissionTableTypes((tableType) => {
        return PAGINATION_HELPERS[projectNumber][tableType]?.updateComputedFields({
          id: missionId,
          project: { projectNumber },
        });
      });
    },
    [
      client,
      containerHasEntriesAfterDelete,
      container,
      projectNumber,
      missionItem,
      removeItemInCaches,
    ],
  );

  const [deleteMissionItem, { loading }] = useMutation<
    DeleteMissionItem,
    DeleteMissionItemVariables
  >(DELETE_MISSION_ITEM_MUTATION, {
    update: deleteMissionItemInCache,
  });

  const handleOnClick = useCallback(
    () =>
      deleteMissionItem({
        variables: { missionItemId },
      }),
    [missionItemId, deleteMissionItem],
  );

  return (
    <Tooltip title="Löschen">
      <span>
        <IconButton
          disabled={disabled || loading}
          onClick={handleOnClick}
          tabIndex={-1}
          aria-label="Löschen"
        >
          <DeleteIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
