import React, { useCallback, useMemo, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { DataTableBody, DataTableHotKeysWrapper } from '../../components/DataTable';
import { IDataTableColumn } from '../../components/DataTable/types';
import ColorCoder, { Color } from '../../components/ColorCoder';
import Attachment from '@material-ui/icons/Attachment';
import AddButton from '../../components/DataTable/DataTableToolbar/AddButton';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import Filter2Icon from '@material-ui/icons/Filter2';
import { stopEventPropagation } from '../../utils/stopEventPropagation';
import { keyBy, omit } from 'lodash';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { translateBool } from '../../utils/bool.translate.util';
import { createSwissCurrencyFormatter } from '../../utils/createCurrencyFormatter';
import {
  Projects as TProjects,
  Projects_projects,
  Projects_projects_allChildren,
  ProjectsVariables,
} from './types/Projects';
import { formatDate } from '../../utils/format/date';
import { Search, useSearchState } from '../../components/Search/Search';
import AppProgress from '../../components/Page/AppProgress';
import { useSelectedSubsidiaryId } from '../../hooks/useSelectedSubsidiaryId';
import { useQuery } from 'react-apollo';
import { IPaginationState, usePaginationState } from '../../components/Pagination/Pagination.utils';
import { Pagination } from '../../components/Pagination';
import { useCount } from '../../hooks/useCount';
import { CountEntity } from '../../hooks/useCount/useCount.queries';
import { DownloadCsvAction } from '../../components/Actions/Download/Csv';
import { buildProjectsQuery } from './queries';
import { SearchProjects, SearchProjects_projects } from './types/SearchProjects';
import { createOrderByFieldName } from '../../utils/order/createOrderByFieldName';
import { isComputedField } from '../../utils/order/isComputedField';
import { PROJECT_COMPUTED_FIELDS } from './project.constants';
import { DeleteProjectAction } from '../../components/Actions/Delete/Project';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const TABLE_NAME = 'ALL_PROJECTS';

const columns: IDataTableColumn[] = [
  {
    id: 'colorCodings',
    label: 'Farbcodierung',
    render: (colorCodings: Color[]) => colorCodings && <ColorCoder colorCodings={colorCodings} />,
  },
  {
    id: 'projectNumber',
    label: 'Nr',
  },
  {
    id: 'projectName',
    label: 'Projektbezeichnung',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'subsidiary.name',
    label: 'Filiale',
  },
  {
    id: 'createdAt',
    label: 'Eröffnet Datum',
    render: (data) => formatDate(data),
  },
  {
    id: 'publisher.name',
    label: 'Eröffnet Von',
    hideOnDefault: true,
  },
  {
    id: 'personResponsible.name',
    label: 'Verantwortlicher',
  },
  {
    id: 'hasFiles',
    label: 'Daten',
    render: (hasFiles) => (hasFiles ? <Attachment color="action" /> : translateBool(hasFiles)),
  },
  {
    id: 'offerSum',
    label: 'Offertensumme',
    render: formatCurrency,
  },
  {
    id: 'sum',
    label: 'Auftragssumme',
    render: formatCurrency,
  },
  {
    id: 'contactPerson',
    label: 'Ansprechperson',
    hideOnDefault: true,
  },
  {
    id: 'projectAddress.street',
    label: 'Projektadr. Strasse',
    hideOnDefault: true,
  },
  {
    id: 'projectAddress.mailbox',
    label: 'Projektadr. Postfach',
    hideOnDefault: true,
  },
  {
    id: 'projectAddress.postCode',
    label: 'Projektadr. PLZ',
    hideOnDefault: true,
  },
  {
    id: 'projectAddress.country',
    label: 'Projektadr. Land',
    hideOnDefault: true,
  },
  {
    id: 'shippingAddress.street',
    label: 'Versandadr. Strasse',
    hideOnDefault: true,
  },
  {
    id: 'shippingAddress.mailbox',
    label: 'Versandadr. Postfach',
    hideOnDefault: true,
  },
  {
    id: 'shippingAddress.postCode',
    label: 'Versandadr. PLZ',
    hideOnDefault: true,
  },
  {
    id: 'shippingAddress.city',
    label: 'Versandadr. Ort',
    hideOnDefault: true,
  },
  {
    id: 'shippingAddress.country',
    label: 'Versandadr. Land',
    hideOnDefault: true,
  },
  {
    id: 'catalog.useName',
    label: 'Standardkatalog',
    hideOnDefault: true,
  },
  {
    id: 'staffTariff',
    label: 'Mitarbeitertarif',
    hideOnDefault: true,
  },
  {
    id: 'isSpecialProject',
    label: 'Spezialprojekt',
    hideOnDefault: true,
    render: translateBool,
  },
  {
    id: 'hasSeparateFileStorage',
    label: 'Separate Dokumentenablage',
    hideOnDefault: true,
    render: translateBool,
  },
  {
    id: 'commentToBranchLeader',
    label: 'Kommentar an FL',
    hideOnDefault: true,
  },
  {
    id: 'commentToBackoffice',
    label: 'Kommentar an BO',
    hideOnDefault: true,
  },
  {
    id: 'commentToExecutives',
    label: 'Kommentar an GF',
    hideOnDefault: true,
  },
];

const projectMapper = ({
  subsidiary,
  publisher,
  personResponsible,
  projectAddress,
  shippingAddress,
  ...project
}: any) => {
  return {
    id: project.projectNumber,
    data: {
      ...project,
      parent: project.parentId || undefined,
      'subsidiary.name': subsidiary && subsidiary.name,
      'publisher.name': publisher && publisher.name,
      'personResponsible.name': personResponsible && personResponsible.name,
      ...(projectAddress && {
        'projectAddress.street': projectAddress.street,
        'projectAddress.mailbox': projectAddress.mailbox,
        'projectAddress.postCode': projectAddress.postCode,
        'projectAddress.country': projectAddress.country,
      }),
      ...(shippingAddress && {
        'shippingAddress.street': shippingAddress.street,
        'shippingAddress.mailbox': shippingAddress.mailbox,
        'shippingAddress.postCode': shippingAddress.postCode,
        'shippingAddress.country': shippingAddress.country,
        'shippingAddress.city': shippingAddress.city,
      }),
    },
    innerTableRows: project.children?.map(projectMapper) ?? [],
  };
};

interface IMappedProject
  extends Omit<Projects_projects & Projects_projects_allChildren, 'allChildren'> {
  children: any[];
  allChildren: Projects_projects_allChildren[];
}

const mapProject = (project: any): IMappedProject => ({
  ...project,
  children: new Array<any>(),
});

const isSearchProject = (
  project: Projects_projects | SearchProjects_projects,
): project is SearchProjects_projects => {
  return 'allParents' in project;
};

const mapProjects = (projects: Array<Projects_projects | SearchProjects_projects>) => {
  // flatten projects and map
  const mappedProjects: IMappedProject[] = projects
    .map((project) => {
      const innerMappedProjects = [mapProject(project)];

      if (isSearchProject(project)) {
        innerMappedProjects.push(...project.allParents.map(mapProject));
      } else {
        innerMappedProjects.push(...project.allChildren.map(mapProject));
      }

      return innerMappedProjects;
    })
    .flat(2);

  const projectsMap = keyBy(mappedProjects, 'id');

  // build hierarchy
  for (const project of Object.values(projectsMap)) {
    if (project.parentId && projectsMap[project.parentId]) {
      projectsMap[project.parentId].children.push(project);
    }
  }

  // filter root/parent projects
  return Object.values(projectsMap)
    .filter((p) => p.parentId === null)
    .map(projectMapper);
};

const SEARCH_COLUMNS = [
  'projectName',
  'projectNumber',
  'status',
  'personResponsible.name',
  'subsidiary.name',
  'createdAt',
  'isSpecialProject',
];

const Projects = () => {
  const [projectRedirectId, setProjectRedirectId] = useState(null);
  const [showArchivedProjects, setShowArchivedProjects] = React.useState(true);

  const searchState = useSearchState();

  const selectedSubsidiary = useSelectedSubsidiaryId();

  const subsidiaryId = selectedSubsidiary === 'all' ? undefined : selectedSubsidiary;

  const query = useMemo(
    () => buildProjectsQuery(!!searchState.searchTerm),
    [searchState.searchTerm],
  );

  const { data, loading, refetch, variables } = useQuery<
    TProjects | SearchProjects,
    ProjectsVariables
  >(query, {
    variables: {
      subsidiaryId,
      archived: showArchivedProjects,
      search: searchState.searchTerm
        ? {
            columns: SEARCH_COLUMNS,
            terms: searchState.searchTerm.split(' '),
          }
        : undefined,
      page: {
        amountPerPage: 25,
        pageNumber: 0,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        subsidiaryId,
        archived: showArchivedProjects,
        page: {
          amountPerPage: state.rowsPerPage,
          pageNumber: state.page,
        },
      });
    },
    [refetch, showArchivedProjects, subsidiaryId],
  );

  const count = useCount({
    entity: CountEntity.PROJECT,
    variables: {
      where: {
        subsidiary: { id: subsidiaryId },
        archived: showArchivedProjects,
        isRootProject: !searchState.searchTerm,
      },
      ...omit(variables, 'page', 'subsidiaryId', 'archived', 'isRootProject'),
    },
  });

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  const projects = data?.projects;
  const innerTableRows = useMemo(() => mapProjects(projects ?? []), [projects]);

  if (projectRedirectId) {
    return <Redirect to={`/projekte/${projectRedirectId}/details`} />;
  }

  return (
    <>
      {loading && <AppProgress />}
      <DataTableHotKeysWrapper
        droppableId="projects"
        innerTableRows={innerTableRows}
        pagination={<Pagination paginationState={paginationState} />}
        search={
          <Search
            searchState={searchState}
            loading={loading}
            columns={SEARCH_COLUMNS}
            onSubmit={() => {
              return Promise.resolve(); // disable onSubmit refetch in favor of useQuery
            }}
          />
        }
        options={{
          filterText: searchState.searchTerm,
          tableName: TABLE_NAME,
          onChangeSort: async (fieldName, order) => {
            await refetch({
              orderBy: {
                fieldName: createOrderByFieldName(fieldName, order),
                isComputed: isComputedField(fieldName, PROJECT_COMPUTED_FIELDS),
              },
            });
          },
          levels: [
            {
              columns,
              isDragAndDropEnabled: false,
              onRowClick: (row: any) => setProjectRedirectId(row.id),
              rowActions: ({ row }) => (
                <>
                  <Tooltip title="Bearbeiten (Enter)">
                    <Link to={`/projekte/${row.id}/details`} onClick={stopEventPropagation}>
                      <IconButton aria-label="Bearbeiten">
                        <CreateIcon fontSize="small" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Duplizieren">
                    <Link
                      to={{
                        pathname: `/projekte/${row.id}/clone`,
                        state: { variables },
                      }}
                      onClick={stopEventPropagation}
                    >
                      <IconButton aria-label="Duplizieren">
                        <Filter2Icon fontSize="small" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <DeleteProjectAction project={row.data} onDeleted={refetch} />
                </>
              ),
            },
          ],
        }}
      >
        {(context) => {
          return (
            <DataTableBody
              context={context}
              toolbarProps={{
                actions: (
                  <>
                    <Tooltip
                      title={`Archivierte Projekte ${
                        showArchivedProjects ? 'ausblenden' : 'anzeigen'
                      }`}
                    >
                      <IconButton
                        onClick={() => {
                          setShowArchivedProjects(!showArchivedProjects);
                          refetch();
                        }}
                      >
                        {showArchivedProjects ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                    <DownloadCsvAction
                      csvName={`Projekte_${formatDate()}`}
                      entityType="PROJECT"
                      variables={{
                        where: {
                          subsidiary: { id: variables.subsidiaryId },
                          isRootProject: false,
                          archived: variables.archived,
                        },
                        search: variables.search,
                      }}
                    />
                    <AddButton tooltip="Neu" href="/projekte/erstellen" state={{ variables }} />
                  </>
                ),
              }}
            />
          );
        }}
      </DataTableHotKeysWrapper>
    </>
  );
};

export default Projects;
