import { Grid, Typography, makeStyles, MenuItem } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import FormikSelect from '../Form/FormikSelect';
import { CalendarNavigation } from './CalendarNavigation';
import { IEmployeeSuggestions } from '../../pages/EmployeeCalendar/employeeCalendar.utils';

interface IValues {
  employees: string[];
}

interface IProp {
  onChange: (values: IValues, formikHelpers: FormikHelpers<IValues>) => any;
  onChangeDate: (newDate: Date) => void;
  date?: Date;
  loading: boolean;
  employeeSuggestions: IEmployeeSuggestions;
}

const useStyles = makeStyles((theme) => {
  return {
    employeesFieldWrapper: {
      paddingTop: theme.spacing(1),
    },
    employeesField: {
      bottom: theme.spacing(3),
      marginTop: '0 !important',
    },
    employeesFieldLabel: {
      fontSize: theme.typography.pxToRem(19),
      fontWeight: theme.typography.fontWeightMedium,
    },
    toolbarContainer: {
      padding: `${theme.spacing(3.5)}px 0`,
    },
  };
});

const CalendarToolbar: React.FC<IProp> = ({
  onChange,
  onChangeDate,
  date,
  loading: parentLoading,
  employeeSuggestions,
}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ employees: new Array<string>() }}
      onSubmit={async ({ employees }, formikHelpers) => {
        onChange({ employees }, formikHelpers);
      }}
    >
      {({ submitForm }) => {
        return (
          <div className={classes.toolbarContainer}>
            <Form>
              <Grid container>
                <Grid item container xs={3} className={classes.employeesFieldWrapper}>
                  <Grid item xs={5}>
                    <Typography className={classes.employeesFieldLabel}>
                      Mitarbeiter auswählen
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      className={classes.employeesField}
                      name="employees"
                      multiple
                      disabled={parentLoading}
                      component={FormikSelect}
                      onBlur={async () => {
                        await submitForm();
                      }}
                    >
                      {employeeSuggestions.employeeMap.map((employee, idx) => {
                        return (
                          <MenuItem value={employee.fullName} key={idx}>
                            {employee.fullName}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item container xs={6}>
                  <CalendarNavigation
                    onChangeDate={onChangeDate}
                    date={date}
                    loading={parentLoading}
                  />
                </Grid>
              </Grid>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default CalendarToolbar;
