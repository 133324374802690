import React from 'react';
import { IDataTableRow, IDataTableColumn } from '../components/DataTable/types';

/**
 * [id, label, hideOnDefault, render]
 */

export type TCol = [
  string,
  string,
  boolean?,
  ((cellData: any, row?: IDataTableRow) => React.ReactElement<any> | string | null)?,
];

const colUtil = (...args: TCol): IDataTableColumn => {
  return {
    id: args[0],
    label: args[1],
    hideOnDefault: args[2],
    render: args[3],
  };
};

export const colsUtil = (arr: TCol[]) => {
  return arr.map((item) => colUtil(...item));
};
