import { Grid, IconButton, Select, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Maybe } from 'graphql/jsutils/Maybe';
import { isNil, isString } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import AppErrorMessage from '../../Page/AppErrorMessage';
import AppProgress from '../../Page/AppProgress';
import { useAddressSectionStyles } from '../header';
import {
  FullAddressBookItem_address,
  FullAddressBookItem_address_contactPersons,
  FullAddressBookItem_contactPerson,
} from '../types/FullAddressBookItem';
import { useContactDropdownQuery } from './fetch';
import { mapContactDropdownItemsToSelectOptions, mapContactsToDropdownItems } from './mapper';

interface IProps {
  addressBookId?: Maybe<string>;
  addressBookItemAddress?: Maybe<FullAddressBookItem_address>;
  activeContact?: Maybe<FullAddressBookItem_contactPerson>;
  contacts: FullAddressBookItem_address_contactPersons[];
  disabled?: Maybe<boolean>;
}

export const ContactDropdown: FC<IProps> = ({
  addressBookId,
  addressBookItemAddress,
  activeContact,
  contacts,
  disabled,
}) => {
  const [selectedContactId, setSelectedContactId] = useState<string>(activeContact?.id ?? '');

  const sectionClasses = useAddressSectionStyles();
  const { loading, error, executeClearContactPerson, executeSelectContactPerson } =
    useContactDropdownQuery();

  const dropdownItems = useMemo(() => mapContactsToDropdownItems(contacts), [contacts]);
  const selectOptions = useMemo(
    () => mapContactDropdownItemsToSelectOptions(dropdownItems),
    [dropdownItems],
  );

  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      if (!addressBookId || !addressBookItemAddress) {
        throw new Error();
      }

      const contactId = event.target.value;
      if (!isString(contactId)) {
        throw new Error(`${contactId} is not a string`);
      }

      const selectedContact = contacts.find((contact) => contact.id === contactId)!;

      executeSelectContactPerson({
        variables: {
          addressBookId: parseInt(addressBookId, 10),
          contactPerson: {
            id: parseInt(selectedContact.id, 10),
          },
        },
      });
    },
    [addressBookId, contacts, addressBookItemAddress, executeSelectContactPerson],
  );
  const clearContactPerson = useCallback(async () => {
    if (!addressBookId) {
      throw new Error();
    }

    await executeClearContactPerson({ variables: { addressBookId: parseInt(addressBookId, 10) } });
  }, [executeClearContactPerson, addressBookId]);

  useEffect(() => {
    setSelectedContactId(activeContact?.id ?? '');
  }, [activeContact]);

  return (
    <>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <Grid container item xs={12}>
        <Grid container item xs={12} spacing={2}>
          <Grid item>
            <Typography variant="body1" classes={{ root: sectionClasses.smallSectionHeader }}>
              Kontaktperson
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={clearContactPerson}
              disabled={disabled || loading || isNil(activeContact)}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Select
              value={selectedContactId}
              onChange={handleSelectChange}
              disabled={disabled || isNil(addressBookItemAddress)}
            >
              {selectOptions}
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
