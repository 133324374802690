export const BOQ_COMPUTED_FIELDS = [
  'defaultLocation',
  'colorCodings',
  'status.createdAt',
  'status.status',
  'sumNet',
  'sumGross',
  'hasFiles',
  'taskTypes',
  'discountedSum',
  'project.name',
  'projectAddress.city',
  'project.projectAddress.city',
  'project.projectNumber',
  'project.projectName',
  'projectAddress',
  'project.createdAt',
  'project.status',
  'project.subsidiary.name',
  'project.personResponsible.name',
  'invoiceConditions',
];
