import gql from 'graphql-tag';

export const UPDATE_MISSION_ITEM = gql`
  mutation editMissionItem($where: MissionItemWhereUniqueInput!, $planVolume: Float!) {
    updateMissionItem(where: $where, data: { planVolume: $planVolume }) {
      id
      planVolume
      item {
        id
        allocatedVolume
      }
    }
  }
`;
