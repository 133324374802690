import React, { useCallback, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useQuery } from 'react-apollo';
import {
  GENERATE_ACCOUNTING_JOURNAL_CSV,
  GENERATE_BILL_CSV,
  GENERATE_BILL_OF_QUANTITY_CSV,
  GENERATE_MACHINE_CSV,
  GENERATE_MATERIAL_CSV,
  GENERATE_MISSION_CSV,
  GENERATE_PROJECT_CSV,
  GENERATE_VEHICLE_CSV,
} from './DownloadCsvAction.queries';
import {
  BillOfQuantityWhereInput,
  BillWhereInput,
  DateFilterInput,
  MachineWhereInput,
  MissionWhereInput,
  ProjectWhereInput,
  SearchInput,
  VehicleWhereInput,
} from '../../../../types/graphql';
import AppProgress from '../../../Page/AppProgress';
import AppErrorMessage from '../../../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../../utils/errorPrefixRemover';
import { downloadCsv } from '../../../../utils/csv';
import DateFilter from './DateFilter';
import { SELECTED_SUBSIDIARY_QUERY } from '../../../../pages/Missions/queries';
import { Subsidiary } from '../../../../pages/Missions/types/Subsidiary';

export interface ICsvDownloadVariables {
  ACCOUNTING_JOURNAL: undefined;
  PROJECT: {
    where?: ProjectWhereInput | null;
    search?: SearchInput | null;
    dateFilter?: DateFilterInput | null;
  };
  OFFER: {
    where?: BillOfQuantityWhereInput | null;
    search?: SearchInput | null;
    dateFilter?: DateFilterInput | null;
  };
  ORDER: {
    where?: BillOfQuantityWhereInput | null;
    search?: SearchInput | null;
    dateFilter?: DateFilterInput | null;
  };
  BILL: {
    where?: BillWhereInput | null;
    search?: SearchInput | null;
    dateFilter?: DateFilterInput | null;
  };
  MATERIAL: {
    where?: never;
    search?: SearchInput | null;
    dateFilter?: DateFilterInput | null;
  };
  VEHICLE: {
    where?: VehicleWhereInput | null;
    search?: SearchInput | null;
    dateFilter?: DateFilterInput | null;
  };
  MACHINE: {
    where?: MachineWhereInput | null;
    search?: SearchInput | null;
    dateFilter?: DateFilterInput | null;
  };
  MISSION: {
    where?: MissionWhereInput | null;
    search?: SearchInput | null;
    dateFilter?: DateFilterInput | null;
  };
}

export type CsvDownloadEntityType = keyof ICsvDownloadVariables;

const entityQueryMap: Record<CsvDownloadEntityType, any> = {
  ACCOUNTING_JOURNAL: GENERATE_ACCOUNTING_JOURNAL_CSV,
  PROJECT: GENERATE_PROJECT_CSV,
  OFFER: GENERATE_BILL_OF_QUANTITY_CSV,
  ORDER: GENERATE_BILL_OF_QUANTITY_CSV,
  BILL: GENERATE_BILL_CSV,
  MATERIAL: GENERATE_MATERIAL_CSV,
  VEHICLE: GENERATE_VEHICLE_CSV,
  MACHINE: GENERATE_MACHINE_CSV,
  MISSION: GENERATE_MISSION_CSV,
};

const useCsvLazyQuery = (entityType: CsvDownloadEntityType) => {
  const { error, refetch } = useQuery(entityQueryMap[entityType], {
    fetchPolicy: 'no-cache',
    skip: true,
  });

  return { generateCsv: refetch, error };
};

interface IProps<EntityType extends CsvDownloadEntityType = CsvDownloadEntityType> {
  entityType: EntityType;
  variables: ICsvDownloadVariables[EntityType];
  csvName: string;
}

export const DownloadCsvAction = <EntityType extends CsvDownloadEntityType>({
  entityType,
  variables,
  csvName,
}: IProps<EntityType>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState<DateFilterInput>({ from: null, to: null });

  const { data: subsidiaryData } = useQuery<Subsidiary>(SELECTED_SUBSIDIARY_QUERY);

  const selectedSubsidiary = subsidiaryData?.selectedSubsidiary;

  const { generateCsv, error } = useCsvLazyQuery(entityType);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onClick = useCallback(async () => {
    setIsLoading(true);

    const subsidiary = selectedSubsidiary === 'all' ? undefined : { id: selectedSubsidiary };

    const { data } = await generateCsv({
      ...variables,
      where: {
        ...variables?.where,
        subsidiary,
      },
      ...(dateFilter.from && dateFilter.to && { dateFilter }),
    });

    const csv = Object.values(data)[0] as string;

    setIsLoading(false);

    downloadCsv(csv, csvName);
  }, [generateCsv, variables, selectedSubsidiary, dateFilter, csvName]);

  return (
    <>
      {isLoading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <Tooltip title="Csv runterladen">
        <span onMouseEnter={(e) => setAnchorEl(e.currentTarget)}>
          <IconButton onClick={onClick} disabled={isLoading}>
            <GetAppIcon />
          </IconButton>
        </span>
      </Tooltip>

      <DateFilter
        anchorElement={anchorEl}
        onClosePopup={() => setAnchorEl(null)}
        filterState={dateFilter}
        setFilterState={setDateFilter}
      />
    </>
  );
};
