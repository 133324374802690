import React from 'react';
import { getToken } from '../../utils/getToken';
import { Paper } from '@material-ui/core';
import { useAusmassDigitalLink } from '../../utils/useAusmassDigitalLink';

export const PlanningMainView: React.FunctionComponent = () => {
  const ausmassDigitalLink = useAusmassDigitalLink();

  if (ausmassDigitalLink === '') return null;

  console.log('navigating to ', ausmassDigitalLink + '/zh/planung/planungstool');
  window.location.href = ausmassDigitalLink + '/zh/planung/planungstool';

  return (
    <Paper style={{ padding: 20 }}>
      <iframe
        title="Neue Rechnungstabellen"
        src={`${ausmassDigitalLink}/planung/planungstool?layout=no-layout&momoAuth=${getToken()}`}
        style={{ width: '100%', height: '100vh', border: 'none', background: '#fafafa' }}
      />
    </Paper>
  );
};
