import { InputLabel, RootRef, IconButton, Grid, Theme } from '@material-ui/core';
import React from 'react';
import { PlusOutlineIcon } from '../Icons/PlusOutlineIcon';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';
import { DOCUMENT_CONTENT_HEIGHT, DOCUMENT_CONTENT_WIDTH } from './Document';

interface IProps {
  onCreate: (file: File) => void;
  accept?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: DOCUMENT_CONTENT_WIDTH,
    height: DOCUMENT_CONTENT_HEIGHT,
    backgroundColor: theme.palette.grey[300],
  },
}));

export const DocumentPlaceholder: React.FC<IProps> = ({ onCreate, accept }) => {
  const classes = useStyles();

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: (files) => onCreate(files[0]),
    multiple: false,
    accept,
  });

  const { ref, ...rootProps } = getRootProps();

  return (
    <>
      <InputLabel htmlFor="document-placeholder">
        <RootRef rootRef={ref}>
          <Grid
            container
            justify="center"
            alignContent="center"
            classes={{ root: classes.root }}
            {...rootProps}
          >
            <IconButton>
              <PlusOutlineIcon fontSize="large" />
            </IconButton>
          </Grid>
        </RootRef>
      </InputLabel>
      <input
        {...getInputProps()}
        name="document-placeholder"
        // this input needs to accept everything, but if we don't specify the accept attribute
        // to include images Firefox on Android doesn't ask whether to take a picture or take a
        // file from the filesystem; this should cover most things
        accept="image/*,text/*,video/*,audio/*,application/pdf"
      />
    </>
  );
};
