import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ItemBoilerplateDetailsForm from '../../components/ItemBoilerplate/ItemBoilerplateDetailsForm';
import EditScaleDiscount from '../../components/ItemBoilerplate/EditScaleDiscount';
import CreateScaleDiscount from '../../components/ItemBoilerplate/CreateScaleDiscount';
import ScaleDiscounts from '../../components/ItemBoilerplate/ScaleDiscounts';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import AppProgress from '../../components/Page/AppProgress';
import { filterInvalidData } from '../../utils/filterInvalidApiData';
import { omit } from 'lodash';
import { GET_ITEM_BOILERPLATES } from '.';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export const itemBoilerlateFields = gql`
  fragment itemBoilerlateFields on ItemBoilerplate {
    acronym
    catalog {
      id
      useName
    }
    catalogMainGroup
    catalogMainGroupDescription
    catalogSection
    catalogSectionDescription
    catalogSubsection
    catalogSubsectionDescription
    category
    color
    descriptionOne
    descriptionTwo
    dimensionOne
    dimensionTwo
    freeText
    id
    markingStyle
    material
    materialColor
    productNumber
    reflexion
    targetConsumptionPerUnit
    timeRequired
    unit
    basePrice
    predefinedPricePerUnit
    scaleDiscounts {
      id
      pricePerUnit
    }
  }
`;

const ITEM_BOILERPLATE_QUERY = gql`
  query ItemBoilerplate($id: ID!) {
    itemBoilerplate(where: { id: $id }) {
      ...itemBoilerlateFields
    }
  }
  ${itemBoilerlateFields}
`;

const UPDATE_MUTATION = gql`
  mutation UpdateItemBoilerplate($id: ID!, $data: ItemBoilerplateUpdateInput!) {
    updateItemBoilerplate(id: $id, data: $data) {
      ...itemBoilerlateFields
    }
  }
  ${itemBoilerlateFields}
`;

const ItemBoilerplate: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
  const classes = useStyles();

  const { id } = match.params as any;

  return (
    <Paper className={classes.root} elevation={1}>
      <div>
        <Query<any, any> query={ITEM_BOILERPLATE_QUERY} variables={{ id }}>
          {({ loading, error, data }) => {
            if (loading) {
              return <AppProgress />;
            }
            if (error) {
              console.error(error);
              return null;
            }
            return (
              data && (
                <Mutation<any, any>
                  mutation={UPDATE_MUTATION}
                  refetchQueries={[{ query: GET_ITEM_BOILERPLATES }]}
                >
                  {(updateItemBoilerplate) => (
                    <ItemBoilerplateDetailsForm
                      initialValues={{
                        ...omit(data.itemBoilerplate, [
                          'scaleDiscounts',
                          'basePrice',
                          'predefinedPricePerUnit',
                        ]),
                        catalog: data.itemBoilerplate.catalog.useName,
                      }}
                      onSubmit={(data) => {
                        return updateItemBoilerplate({
                          variables: { id, data: filterInvalidData(data) },
                        });
                      }}
                    />
                  )}
                </Mutation>
              )
            );
          }}
        </Query>
        <Switch>
          <Route
            path="/einzel-positions-vorlagen/:id/staffelrabatte/erstellen"
            component={CreateScaleDiscount}
            strict
          />
          <Route
            path={`/einzel-positions-vorlagen/:id/staffelrabatte/:scaleDiscountId/editieren`}
            component={EditScaleDiscount}
            strict
          />
          <Route path="/einzel-positions-vorlagen/:id/staffelrabatte" component={ScaleDiscounts} />
        </Switch>
      </div>
    </Paper>
  );
};

export default ItemBoilerplate;
