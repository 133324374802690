import React, { useState, useCallback } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import { COPY_MISSION_MUTATION } from './CopyMission.queries';
import { CopyMission, CopyMissionVariables } from './types/CopyMission';
import { Tooltip, IconButton } from '@material-ui/core';
import { Filter2 as Filter2Icon } from '@material-ui/icons';
import { CopyMissionDialog, ICopyMissionDialogFieldsMap } from './CopyMissionDialog';
import { MutationUpdaterFn } from 'apollo-client';
import { ADD_ROW_TO_DATA_TABLE } from '../../../../services/graphql-client';
import {
  mapMissionStructureToDataTableRowInput,
  removeTableTypeFromId,
} from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../../../services/types/AddRowToDataTable';
import { AddRowToDataTableInput, RowType, TableType } from '../../../../types/graphql';
import { executeForPopulatedMissionTableTypes } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/executeForMissionTypes';
import { useProjectNumber } from '../../../../hooks/useProjectNumber';

interface IProps {
  missionRowId: string;
  isFromMeasurements?: boolean;
}

export const CopyMissionAction: React.FC<IProps> = ({ missionRowId, isFromMeasurements }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const client = useApolloClient();
  const projectNumber = useProjectNumber();

  const addCopiedMissionToCache = useCallback<MutationUpdaterFn<CopyMission>>(
    (cache, { data }) => {
      if (!data || !projectNumber) {
        return;
      }

      const dataTableRowInput = mapMissionStructureToDataTableRowInput(
        isFromMeasurements ? TableType.MEASUREMENT : TableType.MISSION,
      )({
        ...data.copyMission,
        // defaults for a newly copied mission (can be deleted by default, ...)
        canBeDeleted: true,
        isMissionBlocked: false,
        manualLockingStatus: null,
      });

      const addRowInput: AddRowToDataTableInput = {
        type: RowType.CONTAINER,
        row: dataTableRowInput,
      };

      executeForPopulatedMissionTableTypes(client, projectNumber, (tableType) =>
        client.query<AddRowToDataTable, AddRowToDataTableVariables>({
          query: ADD_ROW_TO_DATA_TABLE,
          variables: {
            where: { id: tableType },
            data: [addRowInput],
          },
        }),
      );
    },
    [client, projectNumber, isFromMeasurements],
  );

  const [copyMission, { loading }] = useMutation<CopyMission, CopyMissionVariables>(
    COPY_MISSION_MUTATION,
    {
      update: addCopiedMissionToCache,
    },
  );

  const toggleDialog = useCallback(() => setDialogIsOpen((v) => !v), [setDialogIsOpen]);

  const onSubmit = useCallback(
    (values: ICopyMissionDialogFieldsMap) => {
      const missionId = parseInt(removeTableTypeFromId(missionRowId), 10);

      return copyMission({
        variables: {
          where: { id: missionId },
          data: {
            withOpenVolumes: isFromMeasurements,
            ...values,
          },
        },
      });
    },
    [copyMission, missionRowId, isFromMeasurements],
  );

  return (
    <>
      <Tooltip title="Duplizieren">
        <span>
          <IconButton disabled={loading} onClick={toggleDialog}>
            <Filter2Icon />
          </IconButton>
        </span>
      </Tooltip>
      <CopyMissionDialog
        onSubmit={onSubmit}
        isFromMeasurements={isFromMeasurements}
        title="Duplizieren"
        open={dialogIsOpen}
        onClose={toggleDialog}
      />
    </>
  );
};
