import { Grid, Input, InputAdornment, MenuItem, RootRef } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { defaultsDeep, isNil, omit, omitBy } from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import { useFocusField } from '../../hooks/useFocusField';
import { Mission_mission } from '../../pages/Missions/types/Mission';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import FormikSelect from '../Form/FormikSelect';
import FormikTextField from '../Form/FormikTextField';
import MeasurementFields from './MeasurementFields';
import MissionStatus from './MissionStatus/MissionStatus';
import { getChangedFormValues } from '../../utils/form/getChanged';
import { setFalseyValuesToNull } from '../../utils/form/setFalseyToNull';
import { MissionStatusType, Role } from '../../types/graphql';
import MissionBlockingCheckbox from './MissionBlockingCheckbox';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import { PersonResponsibleField } from '../ProjectDetailsForm/ProjectDetailsForm.common';

interface IMissionFormProps {
  onSubmit: (values: any) => void;
  initialValues?: Partial<Mission_mission> & {
    project?: {
      personResponsible: { name: string } | null;
      projectAddress?: string;
    };
  };
  isMeasurementForm?: boolean;
  isEditForm?: boolean;
}

enum WorkTypes {
  AUSSEN = 'Aussen',
  GARAGE = 'Garage',
  HALLE = 'Halle',
  INNEN = 'Innen',
  NACHMARKIERUNG = 'Nachmarkierung',
  NEUMARKIERUNG = 'Neumarkierung',
  DEMARKIERUNG = 'Demarkierung',
}

enum MissionExtraInformation {
  SA = 'SA - Samstagsarbeit',
  SO = 'SO - Sonntagsarbeit',
  NA = 'NA - Nachtarbeit',
  D = 'D - Dringend',
}

enum SurfaceType {
  ALTBELAG = 'Altbelag',
  BETON = 'Beton',
  FLUESTERBELAG = 'Flüsterbelag',
  GUSSASPHALT = 'Gussasphalt',
  GUSSASPHALT_GLAENZEND = 'Gussasphalt glänzend',
  KUNSTSTOFF = 'Kunststoff',
  KOPFSTEINPFLASTER = 'Kopfsteinpflaster',
  NEUBELAG = 'Neubelag',
  SPEZIELL = 'Speziell',
  VERBUNDSTEIN = 'Verbundstein',
}

const AllSatusFields = [
  'status.time',
  'status.postponementOne',
  'status.postponementTwo',
  'status.untilDate',
  'status.calendarWeek',
];

const MissionStatusToFields = [
  {
    status: MissionStatusType.FT,
    fields: ['status.time', 'status.postponementOne', 'status.postponementTwo'],
  },
  { status: MissionStatusType.KB, fields: [] },
  { status: MissionStatusType.PB, fields: ['status.untilDate'] },
  { status: MissionStatusType.KW, fields: ['status.calendarWeek'] },
  { status: MissionStatusType.VD, fields: ['status.date'] },
  { status: MissionStatusType.BD, fields: ['status.untilDate'] },
];

const getDefaultInitialValues = () => ({
  name: '',
  description: '',
  executionDate: '',
  workTypes: [],
  callCustomer: '',
  expectedWorkingTime: '',
  expectedEquipmentOps: '',
  expectedStaffNumber: '',
  weatherType: '',
  surfaceConditions: [],
  temperature: '',
  externalServices: '',
  surfaceType: [],
  customerOnSite: '',
  commentBranchLeaderToBranchLeader: '',
  commentToBackoffice: '',
  commentToEquipmentOperator: '',
  extraInformation: [],
  manualLockingStatus: '',
  isMissionBlocked: false,
  isTimeFixed: false,
  isCustomerOnSite: false,
  ec: '',
  status: {
    missionStatus: '',
    calendarWeek: 0,
    date: '',
    time: '',
    postponementOne: '',
    postponementTwo: '',
    untilDate: '',
  },
  personResponsible: {
    name: '',
  },
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Pflichtfeld'),

  ec: Yup.string(),

  temperature: Yup.number().typeError('Temperatur muss eine Zahl sein!'),

  expectedEquipmentOps: Yup.number().integer('Muss eine ganze Zahl sein!'),

  expectedStaffNumber: Yup.number().integer('Muss eine ganze Zahl sein!'),

  executionDate: Yup.string().when('missionStatus', {
    is: (val) => val === 'FT',
    then: Yup.string().required('Pflichtfeld'),
  }),

  status: Yup.object().shape({
    missionStatus: Yup.string(),

    date: Yup.string().when('missionStatus', {
      is: (val) => 'VD' === val,
      then: Yup.string().required('Pflichtfeld'),
    }),

    untilDate: Yup.string().when('missionStatus', {
      is: (val) => val === 'BD',
      then: Yup.string().required('Pflichtfeld'),
    }),

    calendarWeek: Yup.number()
      .typeError('Kalendarwoche muss eine Zahl sein!')
      .when('missionStatus', {
        is: (val) => val === 'KW',
        then: Yup.number()
          .max(52, 'Kalenderwoche kann nicht größer als 52 sein')
          .min(1, 'Kalenderwoche kann nicht kleiner als 1 sein')
          .required('Pflichtfeld'),
      }),
  }),
});

const mapInitialValues = (mission: Required<IMissionFormProps>['initialValues']) =>
  defaultsDeep(
    omitBy(
      {
        ...omitBy(mission, isNil),
        status: { ...omitBy(mission.status, isNil) },
        ec: mission?.ec?.name,
        personResponsible: {
          name: mission.project?.personResponsible?.name,
        },
        projectAddress: mission.project?.projectAddress,
      },
      isNil,
    ),
    getDefaultInitialValues(),
  );

const MissionForm: React.FunctionComponent<IMissionFormProps> = ({
  onSubmit,
  initialValues,
  isMeasurementForm,
  isEditForm,
}) => {
  const firstField = useFocusField();

  const user = useLoggedInUser();

  const initialFormValues = initialValues
    ? mapInitialValues(initialValues)
    : getDefaultInitialValues();

  const isFormDisabled = initialFormValues.isMissionBlocked;

  const handleSubmit = (values: any, { setSubmitting }: FormikHelpers<any>) => {
    const changedFormValues = getChangedFormValues({
      initialValues: initialFormValues,
      values: omit(values, ['missionManualLockingStatusCB', 'isMissionBlocked']),
    });

    // if missionStatus has been changed, status fields that are not being used
    // by the new MissionStatusType need to be set to null explicitly
    if (changedFormValues?.status?.missionStatus) {
      const statusFieldsNotForNewStatus = MissionStatusToFields.find(
        ({ status }) => status === changedFormValues.status.missionStatus,
      )!.fields;
      const statusFieldsToDelete = AllSatusFields.filter(
        (statusField) => !statusFieldsNotForNewStatus.includes(statusField),
      );
      changedFormValues.status = {
        ...statusFieldsToDelete.reduce<{ [key: string]: null }>((acc, statusField) => {
          acc[`${statusField.substr('status.'.length)}`] = null;
          return acc;
        }, {}),
        ...changedFormValues.status,
      };
    }

    if (changedFormValues.status?.missionStatus === '')
      changedFormValues.status.missionStatus = null;

    if (!isNil(changedFormValues.ec)) changedFormValues.ec = { name: changedFormValues.ec };

    onSubmit(
      setFalseyValuesToNull(changedFormValues, [
        'executionDate',
        'ec',
        'expectedWorkingTime',
        'expectedEquipmentOps',
        'expectedStaffNumber',
        'temperature',
        ...AllSatusFields,
      ]),
    );
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <Grid container spacing={3}>
            {isEditForm && user?.role === Role.SUPER_ADMIN && (
              <Grid item md={12}>
                <Field
                  component={MissionBlockingCheckbox}
                  isMissionBlocked={initialFormValues.isMissionBlocked}
                  name="missionManualLockingStatusCB"
                />
              </Grid>
            )}
            <Grid item md={6} lg={3}>
              <RootRef rootRef={firstField}>
                <Field
                  disabled={isFormDisabled}
                  component={FormikTextField}
                  name="name"
                  label="Einsatzname *"
                />
              </RootRef>
              <Field
                disabled={isFormDisabled}
                component={FormikTextField}
                name="description"
                label="Einsatzbeschreibung"
              />

              <Field
                disabled={isFormDisabled}
                component={FormikSelect}
                label="Arbeitstypen"
                name="workTypes"
                multiple
              >
                {Object.entries(WorkTypes).map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </Field>
              <Field
                label="Belagart / Untergrund"
                component={FormikSelect}
                disabled={isFormDisabled}
                name="surfaceType"
                multiple
                input={<Input id="surfaceType" />}
              >
                {Object.entries(SurfaceType).map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </Field>
              <PersonResponsibleField />
              <Field
                disabled={isFormDisabled}
                component={FormikTextField}
                name="expectedWorkingTime"
                label="Erwartete Arbeitszeit"
                InputProps={{
                  endAdornment: <InputAdornment position="start">h</InputAdornment>,
                }}
                type="number"
              />
              <Field
                disabled={isFormDisabled}
                component={FormikTextField}
                name="expectedEquipmentOps"
                label="Geschätzte Personalzahl EQCH"
                type="number"
              />
              <Field
                disabled={isFormDisabled}
                component={FormikTextField}
                name="expectedStaffNumber"
                label="Geschätzte Personalzahl MA"
                type="number"
              />
              <Field
                disabled={isFormDisabled}
                component={FormikTextField}
                name="commentToBackoffice"
                label="Kommentar an BO"
              />
            </Grid>
            <Grid item md={6} lg={3}>
              <Field
                disabled={isFormDisabled}
                component={FormikTextField}
                name="projectAddress"
                label="Adresse"
              />
              <Field
                disabled={isFormDisabled}
                name="extraInformation"
                label="Dispoangaben"
                multiple
                component={FormikSelect}
              >
                {Object.entries(MissionExtraInformation).map(([key, vale]) => (
                  <MenuItem key={key} value={key}>
                    {vale}
                  </MenuItem>
                ))}
              </Field>
              <Field
                disabled={isFormDisabled}
                component={FormikTextField}
                name="callCustomer"
                label="Kunde vorgängig informieren"
              />
              <Field
                disabled={isFormDisabled}
                component={FormikTextField}
                name="commentBranchLeaderToBranchLeader"
                label="Kommentar an Dispo"
              />
              <Field
                disabled={isFormDisabled}
                component={FormikTextField}
                label="Mögliche Fremdleistungen"
                name="externalServices"
              />
            </Grid>
            <Grid item md={6} lg={3}>
              <Field disabled={isFormDisabled} name="status" component={MissionStatus} />
              <Field
                disabled={isFormDisabled}
                component={FormikTextField}
                name="commentToEquipmentOperator"
                label="Kommentar an EC"
              />
              {isMeasurementForm && <MeasurementFields disabled={isFormDisabled} />}
              {!isFormDisabled && (
                <Field
                  component={FormikTextField}
                  shrink
                  name="executionDate"
                  label="Ausführungsdatum"
                  type="date"
                />
              )}
            </Grid>
            <Grid item container justify="flex-end" md={12}>
              <CancelSaveButtons isDisabled={isSubmitting || !dirty} />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default MissionForm;
