import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CreateItemBoilerplateGroupDialog from './CreateItemBoilerplateGroupDialog';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { GET_ITEM_BOILERPLATE_GROUPS } from './index';

const CREATE_ITEM_BOILERPLATE_GROUP = gql`
  mutation CreateItemBoilerplateGroup($name: String!) {
    createItemBoilerplateGroup(name: $name) {
      id
      name
    }
  }
`;

const CreateItemBoilerplateGroup: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  const handleClose = () => history.goBack();

  return (
    <Mutation<any, any>
      mutation={CREATE_ITEM_BOILERPLATE_GROUP}
      update={(cache, { data: { createItemBoilerplateGroup } }) => {
        const { itemBoilerplateGroups } = cache.readQuery({
          query: GET_ITEM_BOILERPLATE_GROUPS,
        }) as any;
        cache.writeQuery({
          query: GET_ITEM_BOILERPLATE_GROUPS,
          data: {
            itemBoilerplateGroups: itemBoilerplateGroups.concat([
              { ...createItemBoilerplateGroup, itemBoilerplates: [] },
            ]),
          },
        });
      }}
    >
      {(createItemBoilerplateGroup) => {
        const handleCreate = (variables: any) => {
          createItemBoilerplateGroup({ variables });
          handleClose();
        };
        return (
          <CreateItemBoilerplateGroupDialog
            open={true}
            onCreate={handleCreate}
            onClose={handleClose}
          />
        );
      }}
    </Mutation>
  );
};

export default CreateItemBoilerplateGroup;
