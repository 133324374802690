import { IDataTableRow } from '../../components/DataTable/types';
import {
  GetDataTable_dataTable,
  GetDataTable_dataTable_rows_innerTableRows,
} from '../../services/types/GetDataTable';

export const getAllInnerTableRows = (row: IDataTableRow): IDataTableRow[] => {
  return [
    ...(row.innerTableRows ?? []),
    ...(row.containerRows?.flatMap(getAllInnerTableRows) ?? []),
  ];
};

export const getAllInnerTableRowsFromDataTable = (
  dataTable: GetDataTable_dataTable,
): GetDataTable_dataTable_rows_innerTableRows[] => {
  return dataTable.rows.flatMap((row) => [
    ...(row.innerTableRows ?? []),
    ...(row.containerRows?.flatMap((c1) => [
      ...(c1.innerTableRows ?? []),
      ...(c1.containerRows?.flatMap((c2) => c2.innerTableRows ?? []) ?? []),
    ]) ?? []),
  ]);
};
