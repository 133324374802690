import React, { useMemo } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import AppProgress from '../../../components/Page/AppProgress';
import { IDataTableColumn, IDataTableRow } from '../../../components/DataTable/types';
import DataTable from '../../../components/DataTable';
import { useHistory } from 'react-router-dom';
import AddButton from '../../../components/DataTable/DataTableToolbar/AddButton';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Refresh from '@material-ui/icons/Refresh';
import { GET_ACCOUNTING_LOGS } from './AccountingLogOverview.queries';
import { GetAccountingLogs, GetAccountingLogs_reports } from './types/GetAccountingLogs';
import AppErrorMessage from '../../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import { formatDate } from '../../../utils/format/date';
import { REFRESH_ACCOUNTING_LOG } from '../AccountingLogger/AccountingLogger.queries';
import {
  RefreshAccountingLog,
  RefreshAccountingLogVariables,
} from '../AccountingLogger/types/RefreshAccountingLog';
import { DeleteAccountingReportAction } from '../../../components/Actions/Delete/AccountingReport';

const columns: IDataTableColumn[] = [
  {
    id: 'reportDay',
    label: 'Rapporttag',
    render: (data) => formatDate(data),
  },
  {
    id: 'createdBy',
    label: 'Rapportiert von',
  },
  {
    id: 'employees',
    label: 'Equippe',
  },
];

/**
 * map accounting days to data table rows
 * @param reports the accounting days to map
 * @returns data table rows
 * @see IMockAccountingDay
 * @see IDataTableRow
 */
const mapToDataTable = (reports: readonly GetAccountingLogs_reports[]): IDataTableRow[] =>
  reports
    .slice(0)
    .sort((a, z) => (z.reportDate < a.reportDate ? -1 : 1))
    .map((report) => ({
      id: report.id,
      data: {
        reportDay: report.reportDate,
        createdBy: report.createdBy.name,
        employees: [report.teamLeader, ...report.employees]
          .map((employee) =>
            // can't really be null, quirk of the api that the teamLeader has to be typed as optional
            employee == null ? '' : `${employee.firstName[0]}. ${employee.lastName}`,
          )
          .join(', '),
      },
    }));

/**
 * Component that displays a table of reported accounting days
 * @constructor
 */
export const AccountingLogOverview: React.FC = () => {
  const history = useHistory();

  const { data, loading, error } = useQuery<GetAccountingLogs>(GET_ACCOUNTING_LOGS);
  const [refreshAccountingLog, { loading: refreshLoading, error: refreshError }] = useMutation<
    RefreshAccountingLog,
    RefreshAccountingLogVariables
  >(REFRESH_ACCOUNTING_LOG);

  const someLoading = loading || refreshLoading;
  const options = useMemo(
    () => ({
      tableName: 'ALL_ACCOUNTING_DAYS',
      levels: [
        {
          columns,
          rowActions: ({ row }: { row: IDataTableRow }) => {
            const editStr = `Rapport ${row.data.isFinal ? 'Fixiert' : 'Edititieren'}`;
            const refreshStr = `Rapport ${row.data.isFinal ? 'Fixiert' : 'Aktualisieren'}`;
            return (
              <>
                <Tooltip title={refreshStr}>
                  <IconButton
                    aria-label={refreshStr}
                    disabled={someLoading || row.data.isFinal}
                    onClick={() => {
                      refreshAccountingLog({
                        variables: { where: { id: row.id } },
                        refetchQueries: [{ query: GET_ACCOUNTING_LOGS }],
                        awaitRefetchQueries: true,
                      });
                    }}
                  >
                    <Refresh />
                  </IconButton>
                </Tooltip>
                <DeleteAccountingReportAction reportId={row.id} />
                <Tooltip title={editStr}>
                  <IconButton
                    aria-label={editStr}
                    disabled={someLoading || row.data.isFinal}
                    onClick={() => {
                      history.push({
                        pathname: `/buchungsjournal/rapport/${row.id}`,
                      });
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
          actions: () => (
            <>
              <AddButton href="/buchungsjournal/rapport/erstellen" tooltip="Rapport erstellen" />
            </>
          ),
        },
      ],
    }),
    [history, someLoading, refreshAccountingLog],
  );

  const innerTableRows = useMemo(() => mapToDataTable(data?.reports ?? []), [data]);

  return (
    <>
      {someLoading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      {refreshError && <AppErrorMessage message={errorPrefixRemover(refreshError.message)} />}
      <DataTable innerTableRows={innerTableRows} options={options} />
    </>
  );
};
