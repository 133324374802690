import { useQuery } from 'react-apollo';
import {
  GET_CURRENT_COLUMNS_SETTING,
  GET_TABLE_SETTING,
} from '../../components/ColumnsSettings/clumnsSettings.queries';
import React, { useEffect } from 'react';
import { IAction, ActionType } from '../../components/DataTable';
import {
  TableSetting,
  TableSettingVariables,
} from '../../components/ColumnsSettings/types/TableSetting';
import { CurentColumnsSetting } from '../../components/ColumnsSettings/types/CurentColumnsSetting';
import { isEmpty } from 'lodash';

export const useColumnsSetting = (
  tableName: string | undefined,
  dispatch: React.Dispatch<IAction>,
  defaultColumns: string[],
  alwaysVisibleColumns: string[] = [],
  fixedPositionColumns: Array<{ id: string; index: number }> = [],
) => {
  const { data: currentCoulmnsSettingData } = useQuery<CurentColumnsSetting, null>(
    GET_CURRENT_COLUMNS_SETTING,
  );

  const { data: tableSettingData, loading: tableSettingLoading } = useQuery<
    TableSetting,
    TableSettingVariables
  >(GET_TABLE_SETTING, {
    variables: {
      where: {
        id: `${currentCoulmnsSettingData?.currentColumnsSetting?.id}-${tableName}`,
      },
    },
  });

  const tableSetting = tableSettingData?.tableSetting;

  useEffect(() => {
    if (!tableName || !tableSetting) {
      dispatch({ type: ActionType.SET_SHOWN_COLUMN_IDS, payload: defaultColumns });
      return;
    }

    const payload = [
      ...tableSetting.shownColumnIds,
      ...alwaysVisibleColumns.filter((columnId) => !tableSetting.shownColumnIds.includes(columnId)),
    ];

    const payloadWFixedPositions = !isEmpty(fixedPositionColumns)
      ? fixedPositionColumns.reduce(
          // put columns with fixedPosition in the correct position in payload
          (sortedPayload, fixedPositionColumn) => {
            const payloadWithoutFixedPositionColumn = payload.filter(
              (column) => column !== fixedPositionColumn.id,
            );

            return [
              ...payloadWithoutFixedPositionColumn.slice(0, fixedPositionColumn.index),
              fixedPositionColumn.id,
              ...payloadWithoutFixedPositionColumn.slice(fixedPositionColumn.index),
            ];
          },
          payload,
        )
      : null;

    dispatch({
      type: ActionType.SET_SHOWN_COLUMN_IDS,
      payload: payloadWFixedPositions ?? payload,
    });
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSettingLoading, tableSetting, tableName]);
};
