import { IChildrenRows, IDataTableRow, ILevelOptions, IDataTableColumn } from '../types';

const filterChildren = (
  childrenRows: IChildrenRows,
  levels: ILevelOptions[],
  filterText: string,
): IChildrenRows =>
  filterText.length > 0
    ? filterChildrenWords(childrenRows, levels, queryToWords(filterText))
    : childrenRows;

const filterChildrenWords = (
  childrenRows: IChildrenRows,
  levels: ILevelOptions[],
  queryWords: string[],
): IChildrenRows => ({
  containerRows: filteredRows(childrenRows.containerRows || [], levels, queryWords),
  innerTableRows: filteredRows(
    childrenRows.innerTableRows || [],
    [levels[levels.length - 1]],
    queryWords,
  ),
});

const filteredRows = (
  rows: IDataTableRow[],
  levels: ILevelOptions[],
  queryWords: string[],
): IDataTableRow[] =>
  rows
    .map((row) => filteredRow(row, levels, queryWords))
    .filter((x) => x !== null) as IDataTableRow[];

/**
 * Returns the row if it matches the filter or null.
 * Multiple words are concenated as AND query.
 */
const filteredRow = (
  row: IDataTableRow,
  levels: ILevelOptions[],
  queryWords: string[],
): IDataTableRow | null => {
  const [currentLevel, ...remainingLevels] = levels;
  const rowSearchText = rowToSearchText(row, currentLevel.columns);
  const parentMatches = queryWords.every((word) => rowSearchText.includes(word));

  if (parentMatches) {
    return row;
  } else {
    const childrenLevels = remainingLevels.length > 0 ? remainingLevels : levels;
    const matchingChildren = filterChildrenWords(row, childrenLevels, queryWords);
    return matchingChildren.containerRows!.length > 0 || matchingChildren.innerTableRows!.length > 0
      ? { ...row, ...matchingChildren }
      : null;
  }
};

const rowToSearchText = (row: IDataTableRow, columns: IDataTableColumn[]): string =>
  columns
    .map((column) =>
      ['string', 'number'].includes(typeof row.data[column.id]) ? row.data[column.id] : '',
    )
    .join(' ')
    .toLowerCase();

export const queryToWords = (filterText: string): string[] =>
  filterText.trim().toLowerCase().split(/\s+/g);

/**
 * Filter rows with their children.
 *
 * ## Query language
 * The filterText can contain multiple terms separated by white space.
 * The search terms are searched with AND.
 * Character case or additional whitespace are ignored.
 * Example query: `green House  ` -> matches rows containing `Green`, `BIGHOUSE`, `green yellow house`
 *
 * ## Results
 *
 * - Only columns containing string or number data is filtered, React.Element columns are ignored
 * - If the parent entry of the row matches the filter, the row is returned with all child rows
 * - If it doesn't match the parent entry but a child row, the row is returned with only the matching child rows
 */
export default filterChildren;
