import gql from 'graphql-tag';
import { PureQueryOptions } from 'apollo-client';

export const MACHINE_TABLE_QUERY = gql`
  query Machines(
    $showDeactivated: Boolean
    $subsidiary: SubsidiaryWhereUniqueInput
    $search: SearchInput
    $page: PageInput
    $orderBy: OrderByInput
  ) {
    machines(
      where: { showDeactivated: $showDeactivated, subsidiary: $subsidiary }
      search: $search
      page: $page
      orderBy: $orderBy
    ) {
      id
      inventoryNumber
      projectNumber
      subsidiary {
        id
        name
      }
      registrationNumber
      rootNumber
      chassisNumber
      internalHourlyWage
      nameOne
      nameTwo
      driver
      releaseDate
      weight
      hoursOfOperation
      hoursOfOperationHistory(last: 1) {
        id
        createdBy {
          id
          name
        }
        createdAt
      }
      emissionsTestDate
      trafficOfficeTestDate
      lastInternalTestDate
      category
      skills
      note
      isDeactivated
    }
  }
`;

export const refetchMachineTableQueries: PureQueryOptions[] = [
  {
    query: MACHINE_TABLE_QUERY,
    variables: {
      showDeactivated: false,
    },
  },
];

export const MACHINE_COMPUTED_FIELDS = ['subsidiary.name'];
