import React, { useMemo } from 'react';
import { default as Fab, FabProps } from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    margin: theme.spacing(1),
  },
}));

interface IAddButtonProps extends FabProps {
  tooltip: string;
  href?: string;
  state?: any;
}

const AddButton: React.FunctionComponent<IAddButtonProps> = ({ tooltip, href, state, ...rest }) => {
  const classes = useStyles();

  const AddFab = useMemo(
    () => (
      <Fab {...rest} color="primary" size="small" className={classes.fab} onClick={rest.onClick}>
        <AddIcon />
      </Fab>
    ),
    [classes, rest],
  );

  return (
    <Tooltip title={tooltip}>
      {href && !rest.disabled ? <Link to={{ pathname: href, state }}>{AddFab}</Link> : AddFab}
    </Tooltip>
  );
};

export default AddButton;
