import gql from 'graphql-tag';

export const FULL_ADDRESS_BOOK_ITEM_CONTACT_PERSON = gql`
  fragment BookItemContactPerson on ContactPerson {
    id
    salutationName
    salutationCode
    name
    phone
    phoneTwo
    phoneFour
    email
    contactPersonID
    contactPersonSequence
    address {
      id
      salutationName
      salutationCode
      name
      firstName
      phone
      phoneTwo
      email
      city
      postCode
      role
    }
  }
`;

export const FULL_ADDRESS = gql`
  fragment FullAddress on Address {
    id
    customerNumber
    shortName
    firstName
    name
    department
    postCode
    postbox
    city
    phone
    email
    streetAndNumber
    additionalOne
    additionalTwo
    secondResponsible
    comment
    customerConditions
  }
`;

export const FULL_ADDRESS_BOOK_ITEM = gql`
  fragment FullAddressBookItem on AddressBookItem {
    id
    lineOne
    lineTwo
    lineThree
    lineFour
    contactPerson {
      ...BookItemContactPerson
    }
    address {
      ...FullAddress
      contactPersons {
        ...BookItemContactPerson
      }
    }
  }
  ${FULL_ADDRESS_BOOK_ITEM_CONTACT_PERSON}
  ${FULL_ADDRESS}
`;

export const FULL_ADDRESS_BOOK = gql`
  fragment FullAddressBook on AddressBook {
    id
    projectAddress
    shippingAddress {
      ...FullAddressBookItem
    }
    billingAddress {
      ...FullAddressBookItem
    }
  }
  ${FULL_ADDRESS_BOOK_ITEM}
`;

export const UPDATE_ADDRESS_BOOK = gql`
  mutation UpdateAddressBook($projectNumber: String!, $data: AddressBookUpdateInput!) {
    updateAddressBook(where: { projectNumber: $projectNumber }, data: $data) {
      ...FullAddressBook
    }
  }
  ${FULL_ADDRESS_BOOK}
`;

export const GET_ADDRESS_BOOK = gql`
  query GetAddressBook($projectNumber: String!) {
    addressBook(where: { projectNumber: $projectNumber }) {
      ...FullAddressBook
    }
  }
  ${FULL_ADDRESS_BOOK}
`;
