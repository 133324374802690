import gql from 'graphql-tag';

const SINGLE_LOCATION_FRAGMENT = gql`
  fragment singleLocationFields on Location {
    id
    name
    comment
    nodeCoordinate
    startCoordinate
    endCoordinate
    documents {
      id
      name
      path
      mimetype
    }
  }
`;

export const GET_LOCATION = gql`
  query GetLocation($where: LocationWhereUniqueInput!) {
    location(where: $where) {
      ...singleLocationFields
    }
  }
  ${SINGLE_LOCATION_FRAGMENT}
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($where: LocationWhereUniqueInput!, $data: LocationUpdateInput!) {
    updateLocation(where: $where, data: $data) {
      ...singleLocationFields
      billOfQuantity {
        id
      }
      parentLocation {
        id
      }
    }
  }
  ${SINGLE_LOCATION_FRAGMENT}
`;
