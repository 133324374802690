import { useMemo, useState, useCallback } from 'react';
import { ISearchColumn } from './SearchWithDropdown';
import { PopperProps } from '@material-ui/core/Popper';

export interface IUseTriggerSearchOpts {
  delay: number;
  fetchFn: () => any;
}

export const useEnteredSearchValues = (input: string, delimiter: string) =>
  useMemo(() => input.split(delimiter).map((v) => v.trimStart()), [input, delimiter]);

export const useActiveColumn = (columns: ISearchColumn[], enteredSearchValues: string[]) =>
  useMemo(() => {
    const activeColumn = columns[enteredSearchValues.length - 1];

    return activeColumn ? activeColumn.name : '';
  }, [enteredSearchValues, columns]);

export const useTriggerSearch = ({ delay, fetchFn }: IUseTriggerSearchOpts) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  return useCallback(() => {
    // clear timer when user types a new character

    if (timer) {
      clearTimeout(timer);
    }

    // trigger fetch after delay
    const timeout = setTimeout(async () => {
      await fetchFn();
    }, delay);

    setTimer(timeout);
  }, [fetchFn, delay, setTimer, timer]);
};

export const useSearchPopperPlacement = (anchorEl: HTMLDivElement | null) =>
  useMemo<PopperProps['placement']>(() => {
    if (!anchorEl) {
      return undefined;
    }

    // minimum distance needed to the right hand side to display popper
    const MINIMUM_DISTANCE = 200;

    // check if popper has enough room to fit to the right hand side
    if (window.innerWidth - anchorEl.getBoundingClientRect().right < MINIMUM_DISTANCE) {
      return 'bottom-end';
    }

    return 'bottom-start';
  }, [anchorEl]);
