import React from 'react';
import { Grid } from '@material-ui/core';
import { FastField as Field, Formik, Form, FormikHelpers } from 'formik';
import Dropzone from '../Form/UploadField/Dropzone';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import FormikCheckboxWithLabel from '../Form/FormikCheckboxWithLabel';

const initialValues = {
  isOverride: false,
  documents: { uploads: [], deletedFiles: [] },
};

interface IProps {
  onSubmit: (values: any, helpers: FormikHelpers<any>) => any;
}

export const MaterialCatalogCreateForm: React.FC<IProps> = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ documents, ...values }, helpers) => {
        const catalog = documents.uploads[0];

        return onSubmit({ catalog, ...values }, helpers);
      }}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <Grid container spacing={1} justify="center">
            <Grid item xs={10}>
              <Field
                name="documents"
                accept={
                  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                justify="center"
                multiple={false}
                component={Dropzone}
              />
            </Grid>
            <Grid container item xs={10} justify="center">
              <Field
                name="isOverride"
                Label={{ label: 'Ersetzen' }}
                component={FormikCheckboxWithLabel}
              />
            </Grid>
            <Grid container item justify="flex-end">
              <CancelSaveButtons isDisabled={isSubmitting || !dirty} />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
