import { IDataTableRow } from '../../components/DataTable/types';
import { GetDataTable_dataTable_rows_innerTableRows } from '../../services/types/GetDataTable';

export const unmarshalRow = ({
  __typename,
  ...row
}: GetDataTable_dataTable_rows_innerTableRows): IDataTableRow<any, any, any> => {
  return {
    ...row,
    data: JSON.parse(row.data),
  };
};
