import {
  GetAccountingLogJournal_accountingItemsWithStats_accountingItems,
  GetAccountingLogJournal_accountingItemsWithStats_accountingItems_chargedTo,
  GetAccountingLogJournal_accountingItemsWithStats_accountingItems_creditedTo,
} from '../../../AccountingLogJournal/types/GetAccountingLogJournal';
import { GetAccountingEmployees_employees } from '../../../AccountingLogger/types/GetAccountingEmployees';
import { GetAccountingMissions_missions } from '../../../AccountingLogger/types/GetAccountingMissions';
import { GetAccountingMaterials_activeMaterialCatalog_materials } from '../../../AccountingLogger/types/GetAccountingMaterials';
import { GetAccountingVehicles_vehicles } from '../../../AccountingLogger/types/GetAccountingVehicles';
import { GetAccountingMachines_machines } from '../../../AccountingLogger/types/GetAccountingMachines';
import { GetAccountingCollectiveAccounts_collectiveAccounts } from '../../../AccountingLogger/types/GetAccountingCollectiveAccounts';
import { ChargeType } from '../../../../../types/graphql';
import {
  getSearchableActivityType,
  getSearchableCollectiveAccount,
  getSearchableEmployee,
  getSearchableMachine,
  getSearchableMaterial,
  getSearchableMission,
  getSearchableVehicle,
} from '../../../utils/searchable';
import { GetAccountingActivityTypes_activityTypes } from '../../../AccountingLogger/types/GetAccountingActivityTypes';
import { IManualAccountingFormValues } from '../DataEntryForm';
import {
  chargeConstraint,
  chargedToFromChargeType,
  creditedToFromChargeType,
} from '../../../utils/chargeConstraint';
import { toDay } from '../../../../../utils/durations';
import { get } from 'lodash';

/**
 * helper function to turn empty strings into or undefineds into value | null
 * @param value the string or number to clip
 * @returns the value or null
 */
export const clipString = <T extends string | number>(value: T | null | undefined): T | null => {
  if (typeof value === 'string') {
    const trimmed: string = (value ?? '').trim();
    return trimmed !== '' ? (trimmed as T) : null;
  }
  return value != null ? value : null;
};

type BuildableAccountingEntity = Omit<
  GetAccountingLogJournal_accountingItemsWithStats_accountingItems_creditedTo,
  'workload' | 'costPerUnit' | 'subsidiary'
> &
  Partial<
    Pick<
      GetAccountingLogJournal_accountingItemsWithStats_accountingItems_creditedTo,
      'workload' | 'costPerUnit' | 'subsidiary'
    >
  >;

export type SomeEntity =
  | GetAccountingEmployees_employees
  | GetAccountingMissions_missions
  | GetAccountingMaterials_activeMaterialCatalog_materials
  | GetAccountingVehicles_vehicles
  | GetAccountingMachines_machines
  | GetAccountingCollectiveAccounts_collectiveAccounts;

/**
 * helper for typescript access to generic entity
 * @param key the key to access
 * @param entity the entity to access from
 */
export const accessSomeEntity = <T extends SomeEntity>(
  key: string,
  entity: T | null | undefined,
) => {
  if (!entity) {
    return undefined;
  }

  return get(entity, key);
};

/**
 * create an entity finder that can find accounting entities based on their searchable string
 * @param employees the possible employees to map
 * @param missions the possible missions to map
 * @param materials the possible materials to map
 * @param vehicles the possible vehicles to map
 * @param machines the possible machines to map
 * @param collectiveAccounts the possible collective accounts to map
 * @returns the charge type, the entity and a mapped accounting entity stub, if found
 */
export const entityFinder =
  (
    employees: readonly GetAccountingEmployees_employees[],
    missions: readonly GetAccountingMissions_missions[],
    materials: readonly GetAccountingMaterials_activeMaterialCatalog_materials[],
    vehicles: readonly GetAccountingVehicles_vehicles[],
    machines: readonly GetAccountingMachines_machines[],
    collectiveAccounts: readonly GetAccountingCollectiveAccounts_collectiveAccounts[],
  ) =>
  (searchable: string): [ChargeType, SomeEntity, BuildableAccountingEntity] => {
    const tests = [
      { collection: employees, fetcher: getSearchableEmployee, type: ChargeType.EMPLOYEE },
      { collection: missions, fetcher: getSearchableMission, type: ChargeType.MISSION },
      { collection: materials, fetcher: getSearchableMaterial, type: ChargeType.MATERIAL },
      { collection: vehicles, fetcher: getSearchableVehicle, type: ChargeType.VEHICLE },
      { collection: machines, fetcher: getSearchableMachine, type: ChargeType.MACHINE },
      {
        collection: collectiveAccounts,
        fetcher: getSearchableCollectiveAccount,
        type: ChargeType.COLLECTIVEACCOUNT,
      },
    ];

    let chargeType = null;
    let entity = null;
    // we assume the (searchable) entries are unique, can do something more complex should that become a problem
    for (const { collection, fetcher, type } of tests) {
      entity = (fetcher as any)(searchable, collection);
      if (entity != null) {
        chargeType = type;
        break;
      }
    }
    if (entity == null || chargeType == null) {
      throw new Error('entity not found: ' + searchable);
    }

    const accountingEntity: BuildableAccountingEntity = {
      __typename: 'AccountingEntity',
      id: '',
      type: chargeType,
      employee: chargeType === ChargeType.EMPLOYEE ? entity : null,
      material: chargeType === ChargeType.MATERIAL ? entity : null,
      machine: chargeType === ChargeType.MACHINE ? entity : null,
      vehicle: chargeType === ChargeType.VEHICLE ? entity : null,
      collectiveAccount: chargeType === ChargeType.COLLECTIVEACCOUNT ? entity : null,
      mission: chargeType === ChargeType.MISSION ? entity : null,
    };
    return [chargeType, entity, accountingEntity];
  };

/**
 * map form values to an accounting item
 * @param id of an existing accountingItem
 * @param createdBy what user should be used? (normally provided by api)
 * @param employees the possible employees to map
 * @param missions the possible missions to map
 * @param materials the possible materials to map
 * @param vehicles the possible vehicles to map
 * @param machines the possible machines to map
 * @param collectiveAccounts the possible collective accounts to map
 * @param activityTypes the possible activity types to map
 * @param values the form values
 * @returns a mapped accounting item
 */
export const mapFormToAccountingItem = (
  id: GetAccountingLogJournal_accountingItemsWithStats_accountingItems['id'] | null | undefined,
  createdBy: string,
  employees: readonly GetAccountingEmployees_employees[],
  missions: readonly GetAccountingMissions_missions[],
  materials: readonly GetAccountingMaterials_activeMaterialCatalog_materials[],
  vehicles: readonly GetAccountingVehicles_vehicles[],
  machines: readonly GetAccountingMachines_machines[],
  collectiveAccounts: readonly GetAccountingCollectiveAccounts_collectiveAccounts[],
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[],
  values: IManualAccountingFormValues,
): GetAccountingLogJournal_accountingItemsWithStats_accountingItems => {
  const activityType = getSearchableActivityType(values.activityType, activityTypes);
  if (activityType == null) {
    throw new Error('activity type not found: ' + values.activityType);
  }

  const findEntity = entityFinder(
    employees,
    missions,
    materials,
    vehicles,
    machines,
    collectiveAccounts,
  );
  const [creditedToChargeType, creditedToEntity, creditedTo] = findEntity(values.creditedTo);
  const [chargedToChargeType, chargedToEntity, chargedTo] = findEntity(values.chargedTo);
  const newChargeConstraint = chargeConstraint(
    activityType,
    creditedToFromChargeType(creditedToChargeType),
    chargedToFromChargeType(chargedToChargeType),
  );

  const common: { costPerUnit?: number; workload: number } = {
    costPerUnit: undefined,
    workload: 0,
  };
  chargedTo.subsidiary =
    accessSomeEntity('project', chargedToEntity)?.subsidiary ??
    accessSomeEntity('subsidiary', chargedToEntity);
  creditedTo.subsidiary = accessSomeEntity('subsidiary', creditedToEntity);
  // for things booked towards an employees the values of the employees are the correct ones
  if (creditedToChargeType === ChargeType.EMPLOYEE) {
    common.workload = accessSomeEntity('workload', creditedToEntity) ?? 0;
    common.costPerUnit = accessSomeEntity('function', creditedToEntity)?.hourlyWage ?? 0;
  }
  // there is only one where this is true
  else if (creditedToChargeType === ChargeType.MATERIAL) {
    common.costPerUnit = accessSomeEntity('pricePerUnit', creditedToEntity) ?? 0;
    if (chargedTo.subsidiary == null) {
      throw new Error('trying to book a material where no subsidiary is present');
    }
    creditedTo.subsidiary = chargedTo.subsidiary;
  }
  // otherwise take the values from the entity, workload remains at 0
  else {
    const fromEntity =
      accessSomeEntity('pricePerUnit', creditedToEntity) ??
      accessSomeEntity('internalHourlyWage', creditedToEntity);
    if (fromEntity != null) {
      common.costPerUnit = fromEntity;
    }
  }

  // happens on materials
  if (chargedTo.subsidiary == null) {
    chargedTo.subsidiary = creditedTo.subsidiary;
  }

  if (creditedTo.subsidiary == null || chargedTo.subsidiary == null) {
    throw new Error('trying to book a material where no subsidiary is present');
  }

  // if the activityType specifies a costPerUnit it has priority over everything else
  if (activityType.pricePerUnit != null) {
    common.costPerUnit = activityType.pricePerUnit;
  }
  if (common.costPerUnit == null) {
    // no price is defined -> use provided value; form value is in franks not rappen
    common.costPerUnit = values.pricePerUnit * 100;
  }
  Object.assign(creditedTo, common);
  Object.assign(chargedTo, common);

  const createdAt = toDay(new Date());
  const parent = clipString(values.parent);
  return {
    __typename: 'AccountingItem',
    id: id ?? -1,
    chargeConstraint: newChargeConstraint,
    createdBy,
    createdAt,
    activityDate: values.activityDate,
    startTime: clipString(values.startTime),
    endTime: clipString(values.endTime),
    amount: values.amount,
    comment: values.comment,
    parent: parent ? Number(parent) : null,
    manual: true,
    creditedTo:
      creditedTo as GetAccountingLogJournal_accountingItemsWithStats_accountingItems_creditedTo,
    chargedTo:
      chargedTo as GetAccountingLogJournal_accountingItemsWithStats_accountingItems_chargedTo,
    reportId: null,
  };
};
