import { Grid, MenuItem, RootRef } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers as FormikActions } from 'formik';
import {
  defaultsDeep,
  isNil,
  isNull,
  omit,
  omitBy,
  pick,
  pickBy,
  startCase,
  toLower,
} from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import { useFocusField } from '../../hooks/useFocusField';
import { BillCreateInput, BillStatus, BillTitle } from '../../types/graphql';
import { convertToInputValue } from '../../utils/dateToInputValue';
import { filterInvalidData } from '../../utils/filterInvalidApiData';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import FormikSelectField from '../Form/FormikSelect';
import FormikTextField from '../Form/FormikTextField';

interface IBillOfQuantitySelect {
  id: string;
  name: string | null;
}

interface IBillFormProps {
  initialValues?: BillCreateInput | Pick<BillCreateInput, 'billOfQuantity'>;
  isFixedBill?: boolean;
  hasBillItems?: boolean;
  onSubmit: (values: BillCreateInput) => void;
  billOfQuantities: IBillOfQuantitySelect[];
}

const allowedFixedBillStatuses = {
  [BillStatus.FIXIERT]: BillStatus.FIXIERT,
  [BillStatus.STORNIERT]: BillStatus.STORNIERT,
};

const defaultInitialValues: BillCreateInput = {
  title: '',
  number: '',
  date: convertToInputValue(new Date()),
  billDate: convertToInputValue(new Date()),
  status: BillStatus.NEU,
  billOfQuantity: { id: '' },
  titleDropdown: BillTitle.RECHNUNG,
};

const validationSchema = Yup.object().shape({
  number: Yup.string().required('Rechnungsnummer ist ein Pflichtfeld'),
  date: Yup.date().required('Valutadatum ist ein Pflichtfeld'),
  billDate: Yup.date().required('Rechnungsdatum ist ein Pflichtfeld'),
  billOfQuantity: Yup.object({
    id: Yup.string().required('Leistungsverzeichnis ist ein Pflichtfeld'),
  }),
});

const getBillStatuses = (isFixedBill: boolean, isEdit: boolean, billStatus: any) => {
  if (isFixedBill) {
    return pick(billStatus, Object.values(allowedFixedBillStatuses));
  }

  if (!isEdit) {
    return pickBy(
      billStatus,
      (value: any) => !Object.values(allowedFixedBillStatuses).includes(value),
    );
  }

  return billStatus;
};

const BillForm: React.FunctionComponent<IBillFormProps> = ({
  initialValues,
  onSubmit,
  isFixedBill,
  hasBillItems,
  billOfQuantities,
}) => {
  const firstField = useFocusField();

  const handleSubmit = async (
    values: BillCreateInput,
    { setSubmitting }: FormikActions<BillCreateInput>,
  ) => {
    values.billOfQuantity = omit(values.billOfQuantity, ['__typename', 'name']);
    onSubmit(filterInvalidData(values) as Required<BillCreateInput>);
    setSubmitting(false);
  };

  const billStatuses = getBillStatuses(!!isFixedBill, !!initialValues, {
    NEU: 'Neu',
    IN_ABKLAERUNG_INTERN: 'In Abklärung intern',
    KONTROLLRECHNUNG: 'Kontrollrechnung',
    FIXIERT: 'Fixiert',
    STORNIERT: 'Storniert',
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={defaultsDeep(
        omitBy(initialValues, isNil),
        {
          titleDropdown: isNull((initialValues as any).titleDropdown)
            ? ''
            : (initialValues as any).titleDropdown,
        },
        defaultInitialValues,
      )}
      enableReinitialize
    >
      {({ dirty, isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <RootRef rootRef={firstField}>
                <Field
                  name="titleDropdown"
                  label="Titel"
                  component={FormikSelectField}
                  disabled={isFixedBill}
                >
                  {Object.entries(BillTitle).map(([key, value]: any[]) => (
                    <MenuItem value={key} key={key}>
                      {startCase(toLower(value))}
                    </MenuItem>
                  ))}
                </Field>
              </RootRef>
              <Field
                name="number"
                label="Rechnungsnummer *"
                component={FormikTextField}
                disabled={isFixedBill}
              />
              <Field
                name="billOfQuantity.id"
                label="Leistungsverzeichnis *"
                component={FormikSelectField}
                disabled={isFixedBill || hasBillItems}
              >
                {billOfQuantities.map((billOfQuantity: IBillOfQuantitySelect) => (
                  <MenuItem key={billOfQuantity.id} value={billOfQuantity.id}>
                    {billOfQuantity.name}
                  </MenuItem>
                ))}
              </Field>
              <Field
                name="title"
                label="Kommentar"
                component={FormikTextField}
                disabled={isFixedBill}
              />
            </Grid>
            <Grid item xs={5}>
              <Field
                name="date"
                label="Valutadatum *"
                shrink
                type="date"
                component={FormikTextField}
                disabled={isFixedBill}
              />
              <Field name="status" label="Rechnungsstatus" component={FormikSelectField}>
                {Object.entries(billStatuses).map(([key, value]: any[]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </Field>
              <Field
                name="billDate"
                label="Rechnungsdatum *"
                shrink
                type="date"
                component={FormikTextField}
                disabled={isFixedBill}
              />
            </Grid>
            <Grid item container justify="flex-end" md={12}>
              <CancelSaveButtons isDisabled={isSubmitting || !dirty} />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default BillForm;
