/**
 * formats a date string to comply with the Swiss standard
 * @param {string} date date to format, if not provided the method returns the current date
 */
export const formatDate = (date?: string) => {
  const dateObject = date ? new Date(date) : new Date();

  // + 1 because in JS the month counter starts at 0
  // so January is 0 etc.
  return `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
};
