const INDEX_OBJ_CACHE: Record<number, Readonly<{ tabIndex: number }>> = {};
/**
 * caches tabIndex objects for use in field configs, prevents re-renders due to object identity
 * @param tabIndex the numeric tab index
 * @returns a cached {tabIndex: number} object
 */
export const cachedTabIndex = (tabIndex: number) => {
  if (!(tabIndex in INDEX_OBJ_CACHE)) {
    INDEX_OBJ_CACHE[tabIndex] = { tabIndex };
    const numIndices = Object.keys(INDEX_OBJ_CACHE).length;
    if (numIndices > 500) {
      console.warn('excessive tab indexing!', numIndices);
    }
  }
  return INDEX_OBJ_CACHE[tabIndex];
};

export const tabIndexDisabled = { tabIndex: -1 } as const;
