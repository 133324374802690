import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { GET_USERS } from './index';
import { CreateUser, CreateUserVariables } from './types/CreateUser';
import UserForm from '../../components/User/UserForm';
import DialogCloseable from '../../components/Dialog/DialogCloseable';
import { USE_COUNT_QUERIES } from '../../hooks/useCount/useCount.queries';
import { omit } from 'lodash';
import AppErrorMessage from '../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';

const CREATE_USER = gql`
  mutation CreateUser($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      name
      email
      role
    }
  }
`;

export const CreateUserDialog: React.FC = () => {
  const history = useHistory();

  const { state } = useLocation();

  const [createUser, { error }] = useMutation<CreateUser, CreateUserVariables>(CREATE_USER, {
    refetchQueries: [
      { query: GET_USERS, variables: state.variables },
      ...(state.variables
        ? [{ query: USE_COUNT_QUERIES.USER, variables: omit(state.variables, 'page') }]
        : []),
    ],
  });

  const onClose = () => {
    state.refetch?.();
    history.goBack();
  };

  return (
    <>
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <DialogCloseable title="Neuen Benutzer erstellen" open={true} onClose={onClose}>
        <UserForm
          onSubmit={async (data) => {
            try {
              await createUser({ variables: { data } });
              onClose();
            } catch {
              // ignore
            }
          }}
        />
      </DialogCloseable>
    </>
  );
};
