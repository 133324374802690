import { useState, useCallback, useMemo, useEffect } from 'react';
import { AvailableRowsPerPageOptions } from '.';

export interface IPaginationState {
  totalNumberOfRows: number;
  page: number; // starts at 0
  rowsPerPage: number;
}

// Functions for better DX
type IncrementPage = () => void;
type DecrementPage = () => void;
type SetRowsPerPage = (rowsPerPage: AvailableRowsPerPageOptions) => void;
type SetPage = (page: number) => void;
type SetTotalNumberOfRows = (totalNumberOfRows: number) => void;

export interface IUsePaginationStateResult extends IPaginationState {
  incrementPage: IncrementPage;
  decrementPage: DecrementPage;
  setRowsPerPage: SetRowsPerPage;
  setPage: SetPage;
  setTotalNumberOfRows: SetTotalNumberOfRows;
}

type Props = Pick<IPaginationState, 'totalNumberOfRows'> & {
  scrollOnPaginationAction?: boolean;
  onChangePage: (state: IPaginationState) => void;
  onSetRowsPerPage: (state: IPaginationState) => void;
};

const scrollToTopLeft = () => {
  window.scrollTo({ top: 0, left: 0 });
};

export const usePaginationState = ({
  scrollOnPaginationAction,
  onChangePage,
  onSetRowsPerPage,
  ...props
}: Props): IUsePaginationStateResult => {
  const [paginationState, setPaginationState] = useState<IPaginationState>({
    page: 0,
    rowsPerPage: 25,
    totalNumberOfRows: props.totalNumberOfRows,
  });

  useEffect(
    () => setPaginationState((state) => ({ ...state, totalNumberOfRows: props.totalNumberOfRows })),
    [props.totalNumberOfRows],
  );

  const handleScrollAction = useCallback(() => {
    if (scrollOnPaginationAction) {
      scrollToTopLeft();
    }
  }, [scrollOnPaginationAction]);

  const decrementPage = useCallback<DecrementPage>(() => {
    handleScrollAction();

    setPaginationState((state) => {
      const newState = { ...state, page: state.page - 1 };

      onChangePage(newState);

      return newState;
    });
  }, [setPaginationState, onChangePage, handleScrollAction]);

  const incrementPage = useCallback<IncrementPage>(() => {
    handleScrollAction();

    setPaginationState((state) => {
      const newState = { ...state, page: state.page + 1 };

      onChangePage(newState);

      return newState;
    });
  }, [setPaginationState, onChangePage, handleScrollAction]);

  const setRowsPerPage = useCallback<SetRowsPerPage>(
    (rowsPerPage) => {
      setPaginationState((state) => {
        const newState = { ...state, rowsPerPage };

        onSetRowsPerPage(newState);

        return newState;
      });
    },
    [setPaginationState, onSetRowsPerPage],
  );

  const setPage = useCallback<SetPage>(
    (page) => {
      setPaginationState((state) => {
        return { ...state, page };
      });
    },
    [setPaginationState],
  );

  const setTotalNumberOfRows = useCallback<SetTotalNumberOfRows>(
    (totalNumberOfRows) => {
      setPaginationState((state) => ({ ...state, totalNumberOfRows }));
    },
    [setPaginationState],
  );

  const { page, rowsPerPage, totalNumberOfRows } = paginationState;

  const isOverflowing = useMemo(
    () => page * rowsPerPage > totalNumberOfRows,
    [totalNumberOfRows, rowsPerPage, page],
  );

  useEffect(() => {
    if (!isOverflowing) {
      return;
    }

    setPaginationState((s) => ({ ...s, page: 0 }));
  }, [isOverflowing]);

  // wrap in useMemo to prevent useEffect from outside that watches on result from firing.
  // without useMemo effect gets called even when user selects the same rowsPerPage e.x. 25 and again 25
  const result = useMemo<IUsePaginationStateResult>(
    () => ({
      page: isOverflowing ? 0 : page,
      rowsPerPage,
      totalNumberOfRows,
      incrementPage,
      decrementPage,
      setRowsPerPage,
      setPage,
      setTotalNumberOfRows,
    }),
    [
      page,
      rowsPerPage,
      totalNumberOfRows,
      incrementPage,
      decrementPage,
      setRowsPerPage,
      isOverflowing,
      setPage,
      setTotalNumberOfRows,
    ],
  );

  return result;
};
