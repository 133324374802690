import React, { useCallback } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import ReplaceItemsDialog from './ReplaceItemsDialog';
import { ReplaceBoqItems, ReplaceBoqItemsVariables } from './types/ReplaceBoqItems';
import AppProgress from '../../Page/AppProgress';
import {
  ITEM_FRAGMENT,
  locationCompositeIdFields,
} from '../BllOfQuantityTable/utils/paginationHelper/queries';
import { MutationUpdaterFn } from 'apollo-client';
import { PAGINATION_HELPERS } from '../../../utils/paginationHelpers';
import { compositeLocationId } from '../BllOfQuantityTable/utils/compositeLocationId';
import { uniq } from 'lodash';
import { executeForPopulatedBoqTableTypes } from '../BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { buildBoqTableRowId } from '../BllOfQuantityTable/utils/paginationHelper/mapper';
import { TableType } from '../../../types/graphql';
import { forceDataTableRefetch } from '../../../utils/paginationHelpers/forceDataTableRefetch';
import { useBoqEntityType } from '../../../hooks/useBoqEntityType';

const REPLACE_BOQ_ITEMS = gql`
  mutation ReplaceBoqItems(
    $projectNumber: String!
    $oldItemId: ID!
    $newItemId: ID!
    $isReplaceAllItems: Boolean
    $price: Int!
  ) {
    replaceBoqItems(
      projectNumber: $projectNumber
      oldItemId: $oldItemId
      newItemId: $newItemId
      isReplaceAllItems: $isReplaceAllItems
      price: $price
    ) {
      ...itemFields
      location {
        id
        ...locationCompositeIdFields
      }
    }
  }
  ${ITEM_FRAGMENT}
  ${locationCompositeIdFields}
`;

export interface IItemToReplace {
  id: string;
  productNumber: string;
}

interface IProps {
  projectNumber: string;
  itemToReplace: IItemToReplace;
  catalogId: string | undefined;
  onCloseDialog: () => void;
  showFinishedItems: boolean;
}

// TODO Decide using Confirmation Dialog
const ReplaceItems: React.FunctionComponent<IProps> = ({
  projectNumber,
  itemToReplace,
  catalogId,
  onCloseDialog,
  showFinishedItems,
}) => {
  const client = useApolloClient();
  const boqEntityType = useBoqEntityType();

  const replaceItemsInCache = useCallback<MutationUpdaterFn<ReplaceBoqItems>>(
    async (cache, { data }) => {
      if (!data) {
        return;
      }

      const uniqContainerIds = uniq(
        data.replaceBoqItems.map((item) =>
          item.location?.parentLocation
            ? compositeLocationId({
                locationId: item.location.id,
                parentLocationId: item.location.parentLocation.id,
                billOfQuantityId: item.location.billOfQuantity.id,
              })
            : item.location!.id,
        ),
      );

      executeForPopulatedBoqTableTypes(
        client,
        projectNumber,
        (tableType) =>
          PAGINATION_HELPERS[projectNumber][tableType]?.refetchItemsInContainer(
            uniqContainerIds.map((containerId) => ({
              containerId: buildBoqTableRowId(tableType)(containerId),
            })),
            { isFinished: showFinishedItems },
          ),
        boqEntityType,
      );

      forceDataTableRefetch(TableType.BILL_MISSION, client);
      forceDataTableRefetch(TableType.ORDER_MISSION, client);
      forceDataTableRefetch(TableType.BILL, client);
      forceDataTableRefetch(TableType.ORDER_MEASUREMENT, client);
    },
    [projectNumber, showFinishedItems, client, boqEntityType],
  );

  const [replaceItems, { loading, error }] = useMutation<ReplaceBoqItems, ReplaceBoqItemsVariables>(
    REPLACE_BOQ_ITEMS,
    {
      update: replaceItemsInCache,
    },
  );

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <ReplaceItemsDialog
        open={itemToReplace.id.length > 0}
        itemToReplace={itemToReplace}
        catalogId={catalogId}
        onClose={onCloseDialog}
        onReplace={(compositeOldItemId, newItemId, isReplaceAllItems, price) => {
          const [oldItemId] = compositeOldItemId.split('-');

          return replaceItems({
            variables: { projectNumber, oldItemId, newItemId, isReplaceAllItems, price },
          });
        }}
      />
    </>
  );
};

export default ReplaceItems;
