import { useQuery } from 'react-apollo';
import { GET_ADDRESS_BOOK } from './query';
import { GetAddressBook, GetAddressBookVariables } from './types/GetAddressBook';

export const useAddressBookQuery = (projectNumber: string) => {
  const { data, loading, error } = useQuery<GetAddressBook, GetAddressBookVariables>(
    GET_ADDRESS_BOOK,
    {
      variables: { projectNumber },
    },
  );

  return { data, loading, error };
};
