import { flatten, uniq } from 'lodash';
import { IDataTableRow, ILevelOptions } from '../types';

const hasChildren = (row: IDataTableRow): boolean =>
  (row.containerRows && row.containerRows.length > 0) ||
  (row.innerTableRows && row.innerTableRows.length > 0) ||
  false;

const defaultClosedRowIds = (containerRows: IDataTableRow[], levels: ILevelOptions[]): string[] => {
  const [currentLevel, ...childrenLevels] = levels;

  const currentLevelResult = uniq([
    ...(currentLevel.isDefaultClosed ? containerRows.filter(hasChildren).map((row) => row.id) : []),
    ...(currentLevel.isLevelToggleButtonVisible
      ? containerRows.filter(currentLevel.isLevelToggleButtonVisible).map((row) => row.id)
      : []),
  ]);
  const childrenResult = flatten(
    containerRows.map((row) =>
      row.containerRows ? defaultClosedRowIds(row.containerRows, childrenLevels) : [],
    ),
  );

  return [...currentLevelResult, ...childrenResult];
};

/**
 * @return array of container row IDs that are closed by default
 */
export default defaultClosedRowIds;
