import { FlexTable, IFlexTableProps } from '../FlexTable/FlexTable';
import classNames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { cachedFlex } from '../../utils/cachedFlex';
import React, { useCallback, useMemo, useState } from 'react';
import { toDay } from '../../utils/durations';
import { getWeek } from './utils/getWeek';
import { roundFloat } from '../../utils/roundFloat';

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    padding: '1em 0',
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
  },
  detailsExpander: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(13),
    cursor: 'pointer',
  },
  activityTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingLeft: '18%',
  },
  timeContainer: {
    paddingRight: '5%',
  },
}));

const mapDetailedDaysToRows = (
  date: string | Date,
  showDetails: boolean,
  detailedDays: ReadonlyArray<IDetailedDay>,
) => {
  const days = getWeek(typeof date === 'string' ? date : toDay(date));
  const activityTypes = new Set(
    detailedDays.flatMap((day) =>
      day.activityTypeSummaries.map(
        ({ activityType: { number: activityTypeNumber } }) => activityTypeNumber,
      ),
    ),
  );
  const detailedDaysKeyed = Object.fromEntries(
    days.map((day) => [day, detailedDays.find(({ date }) => day === date)]),
  );
  const workedTimeRow = ['Arbeitszeit'];
  const plannedWorkTimeRow = ['Sollzeit'];
  const flexTimeBalanceRow = ['Gleitzeitsaldo'];
  const dayBalanceRowRow = ['Tagessaldo'];
  const activityTypeRowsKeyed = Object.fromEntries(
    Array.from(activityTypes).map((activityType) => [activityType, ['' as string | string[]]]),
  );
  for (const dayKey of days) {
    const detailedDay = detailedDaysKeyed[dayKey];
    workedTimeRow.push(detailedDay ? roundFloat(detailedDay.workedTimeSum!, 2) + ' h' : '');
    plannedWorkTimeRow.push(detailedDay ? roundFloat(detailedDay.plannedWorkTime!, 2) + ' h' : '');
    flexTimeBalanceRow.push(detailedDay ? roundFloat(detailedDay.flextimeBalance!, 2) + ' h' : '');
    dayBalanceRowRow.push(detailedDay ? roundFloat(detailedDay.dayBalance!, 2) + ' h' : '');
    Array.from(activityTypes).forEach((activityTypeNumber) => {
      const activityTypeSummary = detailedDay?.activityTypeSummaries.find(
        ({ activityType }) => activityTypeNumber === activityType?.number,
      );
      const unit = activityTypeSummary?.activityType.unit?.acronym;
      const column =
        activityTypeSummary != null
          ? [
              activityTypeSummary.activityType.nameOne,
              `${activityTypeSummary.amount}${unit ? ' ' + unit.toLowerCase() : ''}`,
            ]
          : '';
      activityTypeRowsKeyed[activityTypeNumber].push(column);
    });
  }
  const activityTypeRows = !showDetails
    ? []
    : Object.keys(activityTypeRowsKeyed)
        .sort()
        .map((key) => activityTypeRowsKeyed[key]);
  return [
    workedTimeRow,
    ...activityTypeRows,
    plannedWorkTimeRow,
    flexTimeBalanceRow,
    dayBalanceRowRow,
  ];
};

interface IUnit {
  id: string;
  acronym: string;
}

interface IActivityType {
  id: string;
  number: number;
  nameOne: string;
  unit: IUnit | null;
}

interface IActivityTypeSummary {
  id: string;
  amount: number;
  activityType: IActivityType;
}

interface IDetailedDay {
  id: string;
  date: string | null;
  dayBalance: number | null;
  flextimeBalance: number | null;
  plannedWorkTime: number | null;
  workedTimeSum: number | null;
  activityTypeSummaries: ReadonlyArray<IActivityTypeSummary>;
}

export interface IEmployeeTimeOverviewProps extends Pick<IFlexTableProps, 'flex'> {
  detailedDays: ReadonlyArray<IDetailedDay>;
  date: Date | string;
}

export const EmployeeTimeOverview: React.FC<IEmployeeTimeOverviewProps> = ({
  flex,
  detailedDays,
  date,
}) => {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const onDetailsExpand = useCallback(
    () => setShowDetails(!showDetails),
    [showDetails, setShowDetails],
  );
  const overviews: ReadonlyArray<ReadonlyArray<string | string[]>> = useMemo(
    () => mapDetailedDaysToRows(date, showDetails, detailedDays),
    [date, detailedDays, showDetails],
  );
  return (
    <FlexTable flex={flex} defaultFlex={1}>
      {overviews.map((row, idx) => {
        const [label, ...times] = row;
        const timeLabels = times.map((time) => {
          if (Array.isArray(time)) {
            return (
              <div
                key={idx}
                className={classNames(classes.activityTypeContainer, classes.timeContainer)}
              >
                <Typography align="left" style={cachedFlex(1)} noWrap>
                  {time[0]}
                </Typography>
                <Typography align="right" noWrap>
                  {time[1]}
                </Typography>
              </div>
            );
          }
          return (
            <Typography
              key={idx}
              className={classNames(classes.timeContainer, idx === 0 ? classes.label : undefined)}
              align="right"
              noWrap
            >
              {time}
            </Typography>
          );
        });
        return [
          <div className={classes.labelContainer} key={idx}>
            <Typography className={classes.label} align="right" noWrap>
              {label}
            </Typography>
            {idx === 0 && (
              <Typography
                noWrap
                className={classes.detailsExpander}
                align="right"
                onClick={onDetailsExpand}
              >
                Leistungsarten {showDetails ? 'ausblenden <' : 'anzeigen >'}
              </Typography>
            )}
          </div>,
          ...timeLabels,
        ];
      })}
    </FlexTable>
  );
};
