import { ApolloCache } from 'apollo-cache';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { isNil, omit } from 'lodash';
import { eq, get, pipe } from 'lodash/fp';
import { getLastLocationIdxAtLevel } from '.';
import { ADD_ROW_TO_DATA_TABLE } from '../../../../../services/graphql-client';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../../../../services/types/AddRowToDataTable';
import { GetDataTable } from '../../../../../services/types/GetDataTable';
import { RowType } from '../../../../../types/graphql';
import { getAllContainerRows } from '../../../../../utils/paginationHelpers/getAllContainerRows';
import { CreateLocation_createLocation } from '../../types/CreateLocation';
import { CompositeLocationId, compositeLocationId } from '../compositeLocationId';
import { BoqTableType } from './executeForBoqTypes';
import { buildBoqTableRowId, mapLocationToContainerRow } from './mapper';
import { BILL_OF_QUANTITY_FRAGMENT, BOQ_STRUCTURE_FRAGMENT } from './queries';
import { fullBillOfQuantityStructure } from './types/fullBillOfQuantityStructure';

export const FULL_BILL_OF_QUANTITY_STRUCTURE_FRAGMENT = gql`
  fragment fullBillOfQuantityStructure on BillOfQuantity {
    ...billOfQuantityFields
    ...boqStructureFields
  }
  ${BILL_OF_QUANTITY_FRAGMENT}
  ${BOQ_STRUCTURE_FRAGMENT}
`;

const buildContainerId = (
  createdLocation: CreateLocation_createLocation,
  tableType: BoqTableType,
): string | CompositeLocationId => {
  const isBillOfQuantityParent = !createdLocation.parentLocation!.parentLocation;
  const containerId = isBillOfQuantityParent
    ? createdLocation.parentLocation!.id
    : compositeLocationId({
        billOfQuantityId: createdLocation.billOfQuantity.id,
        locationId: createdLocation.parentLocation!.id,
        parentLocationId: createdLocation.parentLocation!.parentLocation!.id,
      });

  return buildBoqTableRowId(tableType)(containerId);
};

const markParentRowAsOpenable =
  (cache: ApolloCache<any>) =>
  (containerId: string) =>
  (parentContainerRow: ReturnType<typeof getAllContainerRows>[number]): void => {
    cache.writeData({
      id: `DataTableRow:${containerId}`,
      data: {
        ...omit(parentContainerRow, 'containerRows', 'innerTableRows'),
        data: JSON.stringify({
          ...JSON.parse(parentContainerRow.data),
          hasItemsOrLocations: true,
        }),
      },
    });
  };

/**
 * adds the location to the actual BillOfQuantity (the one from graphql response)
 * has to be done so that cached BillOfQuantity includes newly created location
 */
const addLocationToBoqEntity = (
  client: ApolloClient<any>,
  createdLocation: CreateLocation_createLocation,
): void => {
  const billOfQuantityId = createdLocation.billOfQuantity.id;
  const billOfQuantityCacheKey = `BillOfQuantity:${billOfQuantityId}`;

  const billOfQuantityStructure = client.cache.readFragment<fullBillOfQuantityStructure>({
    fragment: FULL_BILL_OF_QUANTITY_STRUCTURE_FRAGMENT,
    id: billOfQuantityCacheKey,
    fragmentName: 'fullBillOfQuantityStructure',
  })!;

  const isL1 = !createdLocation.parentLocation!.parentLocation;
  if (isL1) {
    const isAlreadyExisting = billOfQuantityStructure.defaultLocation.locations!.some(
      (location) => location.id === createdLocation.id,
    );
    if (isAlreadyExisting) {
      return;
    }

    billOfQuantityStructure.defaultLocation.locations!.push({ locations: [], ...createdLocation });
  } else {
    const parentLocation = billOfQuantityStructure.defaultLocation.locations!.find(
      (l) => l.id === createdLocation.parentLocation!.id,
    )!;

    const isAlreadyExisting = parentLocation.locations!.some(
      (location) => location.id === createdLocation.id,
    );
    if (isAlreadyExisting) {
      return;
    }

    parentLocation.locations!.push(createdLocation);
  }

  client.cache.writeData({ id: billOfQuantityCacheKey, data: billOfQuantityStructure });
};

export const addLocationToCacheInBillOfQuantity =
  (client: ApolloClient<any>, tableType: BoqTableType) =>
  (data: GetDataTable | undefined) =>
  (setLoading: (v: boolean) => void) =>
  async (createdLocation: CreateLocation_createLocation): Promise<void> => {
    if (!data?.dataTable) {
      return;
    }

    setLoading(true);

    const containerId = buildContainerId(createdLocation, tableType);
    const idxOfLastRowInParent = await getLastLocationIdxAtLevel(client, tableType)(containerId);
    if (isNil(idxOfLastRowInParent)) {
      return setLoading(false);
    }

    const parentContainerRow = getAllContainerRows(data.dataTable).find(
      pipe(get('id'), eq(containerId)),
    );
    if (!parentContainerRow) {
      return setLoading(false);
    }

    const row = mapLocationToContainerRow(createdLocation.billOfQuantity, tableType)(
      createdLocation,
      idxOfLastRowInParent + 1,
    );

    markParentRowAsOpenable(client.cache)(containerId)(parentContainerRow);

    await client.query<AddRowToDataTable, AddRowToDataTableVariables>({
      query: ADD_ROW_TO_DATA_TABLE,
      variables: {
        where: { id: tableType },
        data: [
          {
            row,
            containerId,
            type: RowType.CONTAINER,
          },
        ],
      },
    });
    addLocationToBoqEntity(client, createdLocation);

    setLoading(false);
  };
