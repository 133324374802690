import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ProjectTitleQuery, ProjectTitleQueryVariables } from './types/ProjectTitleQuery';

const QUERY_PROJECT_TITLE = gql`
  query ProjectTitleQuery($projectNumber: String!) {
    project(where: { projectNumber: $projectNumber }) {
      id
      projectName
      projectNumber
    }
  }
`;

const ProjectTitle = (props: any) => {
  const { projectNumber } = props.match.params;

  const { data, error, loading } = useQuery<ProjectTitleQuery, ProjectTitleQueryVariables>(
    QUERY_PROJECT_TITLE,
    {
      variables: {
        projectNumber,
      },
    },
  );

  if (loading) {
    return null;
  }

  if (!data) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <>
      {data.project.projectName} - {data.project.projectNumber}
    </>
  );
};

export default ProjectTitle;
