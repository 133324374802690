import React from 'react';
import { Field, Form, Formik } from 'formik';
import FormikTextField from '../Form/FormikTextField';
import { Grid, RootRef } from '@material-ui/core';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import * as Yup from 'yup';
import { GetOfferCondition_offerCondition } from '../../pages/OfferConditions/types/GetOfferCondition';
import { omit } from 'lodash';
import defaultNilInitialValues from '../../utils/form/initialValuesDefaults';
import { useFocusField } from '../../hooks/useFocusField';
import FormikCheckBoxWithLabel from '../Form/FormikCheckboxWithLabel';

/**
 * subset of an offer condition entity with the form-editable fields
 * @see GetOfferCondition_offerCondition
 */
type IFormValues = Omit<GetOfferCondition_offerCondition, 'id' | '__typename'>;

const validationSchema = Yup.object().shape<IFormValues>({
  name: Yup.string().required('Pflichtfeld!'),
  text: Yup.string().required('Pflichtfeld!'),
  default: Yup.boolean().default(false),
});

/**
 * combine initial values with default values
 * @param initialValues (optional) initial values to prefill the form
 * @see defaultNilInitialValues
 */
const getFormInitialValues = (initialValues?: GetOfferCondition_offerCondition): IFormValues => {
  const formInitialValues = omit(initialValues, ['id', '__typename']);

  return defaultNilInitialValues(formInitialValues, {
    name: '',
    text: '',
    default: false,
  });
};

interface IProps {
  onSubmit: (values: IFormValues, setSubmitting: (isSubmitting: boolean) => void) => void;
  initialValues?: GetOfferCondition_offerCondition;
}

/**
 * Component that displays a form used to edit offer conditions
 * @param onSubmit called on submitting the form
 * @param initialValues initial values to prefill the form
 * @constructor
 */
const OfferConditionForm: React.FC<IProps> = ({ onSubmit, initialValues }) => {
  const firstField = useFocusField();

  const formInitialValues = getFormInitialValues(initialValues);

  return (
    <Formik<IFormValues>
      initialValues={formInitialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form>
          <RootRef rootRef={firstField}>
            <Field name="name" label="Kurzbezeichnung" component={FormikTextField} />
          </RootRef>
          <Field name="text" label="Text" component={FormikTextField} multiline rows={4} />
          <Field name="default" component={FormikCheckBoxWithLabel} Label={{ label: 'Default' }} />
          <Grid container justify="flex-end">
            <CancelSaveButtons isDisabled={!isValid || isSubmitting || !dirty} />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default OfferConditionForm;
