import React from 'react';

interface IProps {
  crossed?: boolean;
}
export const L8Icon: React.FC<IProps> = ({ crossed }) => {
  return crossed ? (
    <>
      <path d="M12.326,14.467a3.684,3.684,0,0,0-.027.411,3.322,3.322,0,0,0,1.2,2.7,4.8,4.8,0,0,0,2.91.975Z" />
      <path d="M16.24,13.091A1.635,1.635,0,0,1,16.773,13a1.428,1.428,0,0,1,1.077.427,1.7,1.7,0,0,1,.408,1.217,2.141,2.141,0,0,1-.06.409l2.184,2.184a3.408,3.408,0,0,0,.847-2.356,3.165,3.165,0,0,0-.536-1.841,3.7,3.7,0,0,0-1.451-1.226,3.359,3.359,0,0,0,1.27-1.142,2.946,2.946,0,0,0,.462-1.644A3.285,3.285,0,0,0,19.84,6.388a4.588,4.588,0,0,0-3.076-.966,4.562,4.562,0,0,0-3.059.966,3.285,3.285,0,0,0-1.134,2.637,3.274,3.274,0,0,0,.028.425ZM15.854,8.1a1.094,1.094,0,0,1,.91-.391,1.114,1.114,0,0,1,.928.4A1.707,1.707,0,0,1,18,9.183a1.844,1.844,0,0,1-.3,1.108,1.044,1.044,0,0,1-.909.422,1.091,1.091,0,0,1-.931-.422,1.8,1.8,0,0,1-.317-1.108A1.7,1.7,0,0,1,15.854,8.1Z" />
      <path d="M5.856,8V16.03h5.37V18.4H2.771V5.606h.694ZM2.064,4.206l17.73,17.73,1.435-1.323L3.5,2.883Z" />
    </>
  ) : (
    <>
      <path d="M5.856,16.03h5.37V18.4H2.771V5.606H5.856Z" />
      <path d="M20.974,9.025a2.946,2.946,0,0,1-.462,1.644,3.359,3.359,0,0,1-1.27,1.142,3.7,3.7,0,0,1,1.451,1.226,3.165,3.165,0,0,1,.536,1.841,3.351,3.351,0,0,1-1.182,2.707,4.913,4.913,0,0,1-3.257.993,5,5,0,0,1-3.287-1,3.322,3.322,0,0,1-1.2-2.7,3.2,3.2,0,0,1,.527-1.819,3.593,3.593,0,0,1,1.495-1.248,3.388,3.388,0,0,1-1.284-1.142,2.941,2.941,0,0,1-.466-1.644,3.285,3.285,0,0,1,1.134-2.637,4.562,4.562,0,0,1,3.059-.966,4.588,4.588,0,0,1,3.076.966A3.285,3.285,0,0,1,20.974,9.025Zm-2.716,5.616a1.7,1.7,0,0,0-.408-1.217,1.577,1.577,0,0,0-2.158,0,1.675,1.675,0,0,0-.413,1.217A1.691,1.691,0,0,0,15.7,15.85a1.431,1.431,0,0,0,1.094.443,1.377,1.377,0,0,0,1.064-.439A1.727,1.727,0,0,0,18.258,14.641ZM16.764,7.707a1.094,1.094,0,0,0-.91.391,1.7,1.7,0,0,0-.312,1.085,1.8,1.8,0,0,0,.317,1.108,1.091,1.091,0,0,0,.931.422,1.044,1.044,0,0,0,.909-.422A1.844,1.844,0,0,0,18,9.183,1.707,1.707,0,0,0,17.692,8.1,1.114,1.114,0,0,0,16.764,7.707Z" />
    </>
  );
};
