// tslint:disable-next-line
import { QueryResult } from '@apollo/react-common';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { IDataTableRow } from '../../../components/DataTable/types';
import AppErrorMessage from '../../../components/Page/AppErrorMessage';
import AppProgress from '../../../components/Page/AppProgress';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import { AccountingLogJournal } from '../AccountingLogJournal';
import {
  GetAccountingLogJournal,
  GetAccountingLogJournalVariables,
  GetAccountingLogJournal_accountingItemsWithStats_accountingItems,
} from '../AccountingLogJournal/types/GetAccountingLogJournal';
import { Heading, Section } from '../Section';
import { DELETE_ACCOUNTING_LOG_ITEM } from './AccountingLogJournalManual.queries';
import {
  DeleteAccountingLogItem,
  DeleteAccountingLogItemVariables,
} from './types/DeleteAccountingLogItem';

interface ICompareProps {
  disabled?: boolean;
  onParent: (id: GetAccountingLogJournal_accountingItemsWithStats_accountingItems['id']) => void;
  onEdit: (
    item: Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems>,
  ) => void;
  query: QueryResult<GetAccountingLogJournal, GetAccountingLogJournalVariables>;
}

/**
 * The comparison table for manual accounting
 * @param disabled is the preview disabled?
 * @param onParent called when a parent link is requested
 * @param onEdit called when an item should be edited
 * @param query pass in a managed query
 * @constructor
 */
export const Compare: React.FC<ICompareProps> = ({ disabled, onParent, onEdit, query }) => {
  const history = useHistory();

  const [deleteAccountingItem, deleteAccountingItemMeta] = useMutation<
    DeleteAccountingLogItem,
    DeleteAccountingLogItemVariables
  >(DELETE_ACCOUNTING_LOG_ITEM);

  const onDelete = useCallback(
    async (id: GetAccountingLogJournal_accountingItemsWithStats_accountingItems['id']) => {
      await deleteAccountingItem({
        variables: {
          where: {
            id,
          },
        },
      });
      await query.refetch();
    },
    [deleteAccountingItem, query],
  );

  const onJumpToReport = useCallback(
    (
      item: Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems>,
      reportId: GetAccountingLogJournal_accountingItemsWithStats_accountingItems['reportId'],
    ) => {
      if (reportId != null) {
        history.push({
          pathname: `/buchungsjournal/rapport/${reportId}`,
        });
      } else {
        onEdit(item);
      }
    },
    [history, onEdit],
  );

  const rowActions = useMemo(
    () =>
      ({ row }: { row: IDataTableRow }) => {
        return (
          <>
            <Tooltip title="Mutterbuchung">
              <span>
                <IconButton
                  aria-label="mutterbuchung"
                  disabled={disabled}
                  onClick={() => onParent(row.data.id)}
                >
                  <LinkIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Eintrag bearbeiten">
              <span>
                <IconButton
                  aria-label="bearbeiten"
                  disabled={disabled}
                  onClick={() => onJumpToReport(row.data.item, row.data.reportId)}
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            {row.data.manual && (
              <Tooltip title="Eintrag löschen">
                <span>
                  <IconButton
                    aria-label="löschen"
                    disabled={disabled}
                    onClick={() => onDelete(row.data.id)}
                  >
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    [onParent, onJumpToReport, onDelete, disabled],
  );
  return (
    <Section>
      {deleteAccountingItemMeta.loading ? <AppProgress /> : null}
      {deleteAccountingItemMeta.error && (
        <AppErrorMessage message={errorPrefixRemover(deleteAccountingItemMeta.error.message)} />
      )}
      <Heading>Vergleich</Heading>
      <AccountingLogJournal rowActions={rowActions} csvDownload={false} query={query} />
    </Section>
  );
};
