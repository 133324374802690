import React from 'react';
import { Form, FormikFormProps } from 'formik';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

interface IInlineEditingFormProps extends FormikFormProps {
  dirty: boolean;
  enterDownSubmitHandler?: (e: any) => void;
  enterUpSubmitHandler?: (e: any) => void;
  tabSubmitHandler?: (e: any) => void;
  clickAwaySubmitHandler?: (e: any) => void;
}

const InlineEditingFormikForm: React.FC<IInlineEditingFormProps> = ({
  enterDownSubmitHandler,
  enterUpSubmitHandler,
  tabSubmitHandler,
  clickAwaySubmitHandler,
  children,
  dirty,
  ...props
}) => {
  return (
    <ClickAwayListener
      onClickAway={(e) => dirty && clickAwaySubmitHandler && clickAwaySubmitHandler(e)}
    >
      <div>
        <Form
          onKeyDown={(e: any) => {
            if (e.keyCode === 13 && enterDownSubmitHandler) {
              enterDownSubmitHandler(e);
            }
          }}
          onKeyUp={(e: any) => {
            if (dirty) {
              if (!e.target || !e.target.name) {
                return;
              }

              switch (e.keyCode) {
                case 9:
                  if (tabSubmitHandler) {
                    tabSubmitHandler(e);
                  }
                  break;

                case 13:
                  if (enterUpSubmitHandler) {
                    enterUpSubmitHandler(e);
                  }
                  break;

                default:
                  return;
              }
            }
          }}
          {...props}
        >
          {children}
        </Form>
      </div>
    </ClickAwayListener>
  );
};

export default InlineEditingFormikForm;
