import gql from 'graphql-tag';
import { PureQueryOptions } from 'apollo-client';

export const GET_MATERIALS = gql`
  query Materials($search: SearchInput, $page: PageInput) {
    activeMaterialCatalog {
      id
      materials(search: $search, page: $page) {
        id
        materialNumber
        acronym
        nameOne
        nameTwo
        unit {
          id
          acronym
        }
        pricePerUnit
        materialType
        color
        provider
      }
    }
  }
`;

export const REFETCH_MATERIALS_QUERIES: PureQueryOptions[] = [
  {
    query: GET_MATERIALS,
  },
];
