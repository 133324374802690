export default class RememberMeStorage<T extends Record<string, unknown>> {
  private remember: boolean = false;
  private key: string = 'RememberMeStorage';
  private payload: T | null = null;

  constructor(key?: string, init?: T) {
    this.key = key || this.key;
    this.restore();
    if (this.payload === null && init) {
      this.persist(init);
    }
  }

  getEntry() {
    return this.payload;
  }

  update(payload: Partial<T>, remember?: boolean) {
    if (!this.payload) {
      throw new Error('Tried to update non initialized store');
    }
    this.persist({ ...this.payload, ...payload }, remember);
  }

  persist(payload: T, remember?: boolean) {
    if (remember !== undefined && this.remember !== remember) {
      // if the remember policy changes we remove the entry from the store
      this.storage.removeItem(this.key);
      // change the policy
      this.remember = remember;
    }
    this.payload = payload;
    this.storage.setItem(this.key, JSON.stringify(this.payload));
  }

  remove() {
    this.storage.removeItem(this.key);
  }

  private restore() {
    // check localStorage for key
    let persistedStore = localStorage.getItem(this.key);
    if (persistedStore) {
      // if we find the entry we know we got it from localStorage and are done
      this.remember = true;
    } else {
      // else we try in the session storage
      persistedStore = sessionStorage.getItem(this.key);
      if (!persistedStore) {
        // if we find nothing we are done
        return;
      }
    }
    this.payload = JSON.parse(persistedStore);
  }

  private get storage() {
    return this.remember ? localStorage : sessionStorage;
  }
}
