import ApolloClient from 'apollo-client';
import { inRange } from 'lodash';
import { IDataTableRow } from '../../../../../components/DataTable/types';
import { UPDATE_DATA_TABLE_ROWS } from '../../../../../services/graphql-client';
import {
  UpdateDataTableRows,
  UpdateDataTableRowsVariables,
} from '../../../../../services/types/UpdateDataTableRows';
import { TableType } from '../../../../../types/graphql';
import { flattenContainerRows } from '../../../../../utils/flattenRows/flattenRows';

export const reorderLocationInMission =
  (client: ApolloClient<any>, mappedRows: IDataTableRow[], tableType: TableType) =>
  async (sourceIndex: number, destinationIndex: number, parentRowId: string) => {
    const allContainerRows = flattenContainerRows(mappedRows);

    const parentLocation = allContainerRows.find((containerRow) => containerRow.id === parentRowId);
    if (!parentLocation) {
      return;
    }

    const containerRows = parentLocation.containerRows ?? [];
    const locationRowToReorder = containerRows[sourceIndex];
    const isDestinationGreaterThanCurrent = destinationIndex > locationRowToReorder.data.index;
    /**
     * filter out only the affected rows
     * (all locationRows which fall into range dest-source)
     */
    const locationRowsToMutate = containerRows.filter(
      (containerRow) =>
        containerRow.id !== locationRowToReorder.id &&
        inRange(
          containerRow.data.index,
          Math.min(destinationIndex, sourceIndex),
          Math.max(destinationIndex, sourceIndex) + 1,
        ),
    );
    await client.query<UpdateDataTableRows, UpdateDataTableRowsVariables>({
      query: UPDATE_DATA_TABLE_ROWS,
      variables: {
        where: { id: tableType },
        data: [
          ...locationRowsToMutate.map((locationRow) => ({
            where: { tableType, id: locationRow.id },
            data: {
              data: JSON.stringify({
                ...locationRow.data,
                /**
                 * when index greater than current we need to decrement every index till
                 * locationRow at new index
                 */
                index: isDestinationGreaterThanCurrent
                  ? locationRow.data.index - 1
                  : locationRow.data.index + 1,
              }),
            },
          })),
          {
            where: { tableType, id: locationRowToReorder.id },
            data: {
              data: JSON.stringify({ ...locationRowToReorder.data, index: destinationIndex }),
            },
          },
        ],
      },
    });
  };
