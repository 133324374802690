import { MenuItem } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { FC, useCallback } from 'react';
import { useApolloClient } from 'react-apollo';
import { useBoqEntityType } from '../../../../hooks/useBoqEntityType';
import { useProjectNumber } from '../../../../hooks/useProjectNumber';
import {
  removeTableTypeFromId,
  swapTableType,
} from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { PAGINATION_HELPERS } from '../../../../utils/paginationHelpers';
import { executeForPopulatedBoqTableTypes } from '../../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { IDataTableRow } from '../../../DataTable/types';
import DialogCloseable, { IDialogCloseableProps } from '../../../Dialog/DialogCloseable';
import CancelSaveButtons from '../../../Form/CancelSaveButtons';
import FormikSelect from '../../../Form/FormikSelect';

export interface ICopyItemFieldsMap {
  targetLocationId: string;
}

interface IProps extends Omit<IDialogCloseableProps, 'onSubmit'> {
  flattenedContainerRows: IDataTableRow[];
  onSubmit: (values: ICopyItemFieldsMap) => Promise<void>;
  showFinishedItems: boolean;
}

const INITIAL_VALUES: ICopyItemFieldsMap = {
  targetLocationId: '',
};

export const CopyItemDialog: FC<IProps> = ({
  flattenedContainerRows,
  onSubmit,
  showFinishedItems,
  ...dialogProps
}) => {
  const dialogPropsOnClose = dialogProps.onClose;
  const client = useApolloClient();
  const projectNumber = useProjectNumber();
  const boqEntityType = useBoqEntityType();
  if (!projectNumber) {
    throw new Error();
  }

  const refetchTargetLocation = useCallback(
    (targetLocationId: string) => {
      const targetContainerRow = flattenedContainerRows.find((containerRow) =>
        containerRow.data.defaultLocation
          ? removeTableTypeFromId(containerRow.id) === targetLocationId
          : containerRow.data.id === targetLocationId,
      );
      if (!targetContainerRow) {
        throw new Error(`No containerRow with location id ${targetLocationId} found`);
      }

      const isTargetBillOfQuantity = !!targetContainerRow.data.defaultLocation;
      const billOfQuantityId = isTargetBillOfQuantity
        ? targetContainerRow.data.id
        : targetContainerRow.data.billOfQuantity.id;

      executeForPopulatedBoqTableTypes(
        client,
        projectNumber,
        async (tableType) => {
          await PAGINATION_HELPERS[projectNumber][tableType].refetchItemsInContainer(
            [{ containerId: swapTableType(tableType)(targetContainerRow.id) }],
            { isFinished: showFinishedItems },
          );
          await PAGINATION_HELPERS[projectNumber][tableType].updateBillOfQuantityComputedFields(
            billOfQuantityId,
          );
        },
        boqEntityType,
      );
    },
    [flattenedContainerRows, client, projectNumber, boqEntityType, showFinishedItems],
  );

  const handleSubmit = useCallback(
    async (values: ICopyItemFieldsMap, helpers: FormikHelpers<ICopyItemFieldsMap>) => {
      await onSubmit(values);
      refetchTargetLocation(values.targetLocationId);

      helpers.setSubmitting(false);
      dialogPropsOnClose?.();
    },
    [onSubmit, dialogPropsOnClose, refetchTargetLocation],
  );

  return (
    <DialogCloseable {...dialogProps}>
      <Formik<ICopyItemFieldsMap> initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
        {({ isSubmitting, dirty }) => (
          <Form>
            <Field name="targetLocationId" component={FormikSelect}>
              {flattenedContainerRows.map((containerRow) => (
                <MenuItem
                  key={containerRow.id}
                  value={
                    containerRow.data.defaultLocation
                      ? removeTableTypeFromId(containerRow.id)
                      : containerRow.data.id
                  }
                >
                  {containerRow.data.name}
                </MenuItem>
              ))}
            </Field>
            <CancelSaveButtons isDisabled={isSubmitting || !dirty} />
          </Form>
        )}
      </Formik>
    </DialogCloseable>
  );
};
