import { AccountingItemWhereFlatInput } from '../../../../types/graphql';
import zip from 'lodash/fp/zip';
import omitBy from 'lodash/fp/omitBy';
import isEqual from 'lodash/fp/isEqual';
import { toNum } from './toNum';
import { toRangeQuery, toRangeQueryDate, toRangeQueryFloat, toRangeQueryInt } from './toRangeQuery';
import { accountingLogJournalColumns } from '../../AccountingLogJournalTable';

export const searchColumns = accountingLogJournalColumns.map(({ id, label }) => ({
  label,
  name: id,
}));
const searchKeys = searchColumns.map(({ name }) => name);

let cached: AccountingItemWhereFlatInput | null = null;
export const toSearchParameters = (
  enteredSearchValues: readonly string[],
): AccountingItemWhereFlatInput => {
  const strValues: {
    [index in keyof AccountingItemWhereFlatInput]: string | undefined | null;
  } = Object.fromEntries(zip(searchKeys, enteredSearchValues));

  const createdAtDate = toRangeQueryDate(strValues.createdAt)?.eq as Date;
  const createdAt = createdAtDate
    ? {
        gt: createdAtDate,
        lt: new Date(
          createdAtDate.getFullYear(),
          createdAtDate.getMonth(),
          createdAtDate.getDate() + 1,
        ),
      }
    : undefined;

  const retVal = omitBy((value) => value == null || value === '', {
    id: toNum(strValues.id, true),
    activityDate: toRangeQueryDate(strValues.activityDate),
    createdAt,
    createdBy: strValues.createdBy,
    parent: toNum(strValues.parent, true),
    activityType: toNum(strValues.activityType, true),
    whatNameOne: strValues.whatNameOne,
    whatNameTwo: strValues.whatNameTwo,
    unit: strValues.unit,
    costPerUnit: toRangeQueryInt(strValues.costPerUnit, 100),
    creditedTo: strValues.creditedTo,
    creditedToNameOne: strValues.creditedToNameOne,
    creditedToNameTwo: strValues.creditedToNameTwo,
    creditedToSubsidiary: strValues.creditedToSubsidiary,
    chargedTo: strValues.chargedTo,
    projectNumber: strValues.projectNumber,
    chargedToNameOne: strValues.chargedToNameOne,
    chargedToNameTwo: strValues.chargedToNameTwo,
    chargedToSubsidiary: strValues.chargedToSubsidiary,
    startTime: toRangeQuery(strValues.startTime),
    endTime: toRangeQuery(strValues.endTime),
    amount: toRangeQueryFloat(strValues.amount),
    sum: toRangeQueryFloat(strValues.sum, 100),
    comment: strValues.comment,
  });
  if (!isEqual(retVal, cached)) {
    cached = retVal;
  }
  if (cached == null) {
    throw new Error("AssertionError: cached value can't be null");
  }
  return cached;
};
