import { useEffect, useRef, useState } from 'react';

/**
 * Focus a field based on a ref
 * @param select (optional) also select the input to directly override the input (default: false)
 */
export const useFocusField = (select: boolean = false) => {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const inputEl = ref.current.querySelector('input');

      if (inputEl) {
        inputEl.focus();
        if (select) {
          inputEl.select();
        }
      }
    }
  }, [ref, select]);

  return ref;
};

/**
 * can be used to focus and select a field even if it gets added dynamically after first render
 */
export const useDynamicFocusField = (select = false): ((instance: HTMLInputElement) => void) => {
  const [ref, setRef] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (ref) {
      const inputEl = ref.querySelector('input');

      if (inputEl) {
        inputEl.focus();
        if (select) {
          inputEl.select();
        }
      }
    }
  }, [ref, select]);

  return (instance) => {
    setRef(instance);
  };
};
