import ApolloClient from 'apollo-client';
import { UPDATE_DATA_TABLE_ROWS } from '../../../../../services/graphql-client';
import { GetDataTable_dataTable_rows } from '../../../../../services/types/GetDataTable';
import {
  UpdateDataTableRows,
  UpdateDataTableRowsVariables,
} from '../../../../../services/types/UpdateDataTableRows';
import { BillOfQuantityEntityType, BOQStatus } from '../../../../../types/graphql';
import { executeForPopulatedBoqTableTypes } from './executeForBoqTypes';
import { BILL_OF_QUANTITY_L8_QUERY } from './queries';
import {
  BillOfQuantityL8,
  BillOfQuantityL8Variables,
  BillOfQuantityL8_billOfQuantities,
} from './types/BillOfQuantityL8';
import { IFetchBillOfQuantitiesProps, IOnToggleL8Props } from './types/onToggleL8.types';

const fetchBillOfQuantities = async ({
  showStatusL8,
  client,
  project,
  entityType,
}: IFetchBillOfQuantitiesProps): Promise<BillOfQuantityL8_billOfQuantities[]> => {
  const {
    data: { billOfQuantities },
  } = await client.query<BillOfQuantityL8, BillOfQuantityL8Variables>({
    query: BILL_OF_QUANTITY_L8_QUERY,
    variables: {
      where: {
        project,
        entityType,
        ...(!showStatusL8 && { status: { status_not_in: [BOQStatus.L8] } }),
      },
    },
  });

  return billOfQuantities;
};

type ContainerVisibilityMapping = Record<
  'visible' | 'hidden',
  Array<{ id: string; hidden: boolean }>
>;

const buildContainerVisibilityMapping =
  (dataTableRows: GetDataTable_dataTable_rows[]) =>
  (billOfQuantities: BillOfQuantityL8_billOfQuantities[]): ContainerVisibilityMapping => {
    return dataTableRows.reduce<ContainerVisibilityMapping>(
      (acc, row) => {
        const isHidden = !!row.hidden;
        const isVisible = !isHidden;

        const isInResult = billOfQuantities.some(
          (billOfQuantity) => billOfQuantity.id === JSON.parse(row.data).id,
        );
        const willBeVisible = isInResult && isHidden;
        const willBeHidden = !isInResult && isVisible;

        if (willBeVisible) {
          acc.visible.push({ id: row.id, hidden: false });
        } else if (willBeHidden) {
          acc.hidden.push({ id: row.id, hidden: true });
        }

        return acc;
      },
      { hidden: [], visible: [] },
    );
  };

const writeContainerRowsWithNewVisibilityToCache =
  (client: ApolloClient<any>, projectNumber: string) =>
  async (
    containerVisibilityMapping: ContainerVisibilityMapping,
    entityType: BillOfQuantityEntityType,
  ) => {
    await executeForPopulatedBoqTableTypes(
      client,
      projectNumber,
      async (tableType) => {
        const dataTableRowInputs = [
          ...containerVisibilityMapping.visible,
          ...containerVisibilityMapping.hidden,
        ].map((row) => ({
          where: { id: row.id, tableType },
          data: { hidden: row.hidden },
        }));

        await client.query<UpdateDataTableRows, UpdateDataTableRowsVariables>({
          query: UPDATE_DATA_TABLE_ROWS,
          variables: {
            data: dataTableRowInputs,
            where: { id: tableType },
          },
        });
      },
      entityType,
    );
  };

export const onToggleL8 = async ({
  showStatusL8,
  client,
  setLoading,
  data,
  project,
  entityType,
}: IOnToggleL8Props): Promise<void> => {
  setLoading(true);

  if (!data?.dataTable) {
    setLoading(false);
    return;
  }

  const billOfQuantities = await fetchBillOfQuantities({
    client,
    showStatusL8,
    project,
    entityType,
  });

  const containerVisibilityMapping = buildContainerVisibilityMapping(data.dataTable.rows)(
    billOfQuantities,
  );
  await writeContainerRowsWithNewVisibilityToCache(client, project.projectNumber!)(
    containerVisibilityMapping,
    entityType,
  );

  setLoading(false);
};
