import { Role } from '../enums';

export const hasAccessRights = (role: Role, requiredRole: Role): boolean => {
  if (role === Role.SUPER_ADMIN || requiredRole === Role.USER) {
    return true;
  }

  if (role === Role.ADMIN && requiredRole === Role.ADMIN) {
    return true;
  }

  return false;
};
