import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLazyQuery } from 'react-apollo';
import { DocumentNode } from 'graphql';
import { Popper, ClickAwayListener, Paper, RootRef } from '@material-ui/core';
import DataTable from '../DataTable';
import { makeStyles } from '@material-ui/styles';
import {
  useActiveColumn,
  useTriggerSearch,
  useSearchPopperPlacement,
  useEnteredSearchValues,
} from './SearchWithDropdown.hooks';
import { SearchColumns } from './SearchColumns';
import { HotKeys } from 'react-hotkeys';
import { IDataTableRow } from '../DataTable/types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import AppErrorMessage from '../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import AppProgress from '../Page/AppProgress';

export interface ISearchColumn {
  label: string;
  name: string;
  noDisplay?: boolean;
}

interface IProps {
  columns: ISearchColumn[];
  query: DocumentNode;
  onSelect: (data: any) => void;
  maxItems?: number;
  fullWidth?: boolean;
  keepFocus?: boolean;
}

const mapToTableData = (data: any[], maxItems: number) => {
  const itemsToMap = maxItems >= data.length ? data : data.slice(0, maxItems);

  return itemsToMap.map((v) => ({ id: v.id, data: v }));
};

const convertToContains = (columns: ISearchColumn[], values: string[]) =>
  columns
    .map((v) => v.name)
    .reduce((acc, curr, i) => ({ ...acc, [`${curr}_contains`]: values[i] }), {});

const MODAL_Z_INDEX = 1300;
const POPPER_Z_INDEX = MODAL_Z_INDEX + 1;

const useStyles = makeStyles({
  container: {
    paddingTop: '10px',
  },
  dataTableWrapper: {
    maxHeight: '60vh',
    overflowY: 'scroll',
  },
  fullWidth: {
    width: '100%',
  },
  popper: {
    zIndex: POPPER_Z_INDEX,
  },
});

const DELIMITER = ',';
const DELAY = 300;

export const SearchWithDropdown: React.FC<IProps> = ({
  columns,
  query,
  onSelect,
  maxItems = Infinity,
  fullWidth,
  keepFocus,
}) => {
  const [input, setInput] = useState('');
  const [currentRow, setCurrentRow] = useState<IDataTableRow | null>(null);
  const [popperIsOpen, setPopperIsOpen] = useState(false);

  const ref = useRef<any>(null);

  const enteredSearchValues = useEnteredSearchValues(input, DELIMITER);

  const activeColumn = useActiveColumn(columns, enteredSearchValues);

  const classes = useStyles();
  const [fetchResults, { data, loading, error }] = useLazyQuery(query);

  useEffect(() => {
    if (data && !loading) {
      setPopperIsOpen(true);
    }
  }, [data, loading]);

  // wrap inside useCallback so that fn reference only changes when deps changes
  // otherwise useTriggerSearch executes all the time, because the fn reference is different
  const fetchFn = useCallback(
    () => fetchResults({ variables: { where: convertToContains(columns, enteredSearchValues) } }),
    [fetchResults, columns, enteredSearchValues],
  );

  const triggerSearch = useTriggerSearch({
    delay: DELAY,
    fetchFn,
  });

  useEffect(() => {
    // we can't just check whether enteredSearchValues is empty here because
    // if search input is empty then enteredSearchValues = ['']
    if (!enteredSearchValues.every(isEmpty)) {
      triggerSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredSearchValues]);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const popperPlacement = useSearchPopperPlacement(anchorEl);

  return (
    <>
      {loading && <AppProgress />}
      <AppErrorMessage message={error ? errorPrefixRemover(error.message) : ''} isOpen={!!error} />
      <HotKeys
        keyMap={{ HANDLE_FOCUS: ['up', 'down'] }}
        className={classNames({ [classes.fullWidth]: fullWidth })}
        handlers={{
          HANDLE_FOCUS: (e) => {
            if (e) {
              e.preventDefault();
              e.stopImmediatePropagation();
            }
            if (ref.current) {
              ref.current.focus();
            }
          },
        }}
      >
        <div className={classes.container}>
          <SearchColumns
            delimiter={DELIMITER}
            activeColumn={activeColumn}
            columns={columns}
            onInput={(e: any) => {
              if (!anchorEl) {
                setAnchorEl(e.currentTarget);
              }
              setInput(e.target.value);
            }}
            showColumns={popperIsOpen}
            fullWidth={fullWidth}
            keepFocus={keepFocus}
          />
          <Popper
            open={popperIsOpen}
            anchorEl={anchorEl}
            placement={popperPlacement}
            className={classes.popper}
            style={{ width: anchorEl?.clientWidth }}
            modifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
          >
            <ClickAwayListener onClickAway={() => setPopperIsOpen(false)}>
              <HotKeys
                handlers={{
                  ON_ENTER: () => {
                    if (currentRow) {
                      setPopperIsOpen(false);
                      onSelect(currentRow.data);
                    }
                  },
                }}
                keyMap={{ ON_ENTER: 'enter' }}
              >
                <Paper className={classes.dataTableWrapper}>
                  <RootRef rootRef={ref}>
                    <DataTable
                      innerTableRows={
                        data?.abacusAddresses ? mapToTableData(data.abacusAddresses, maxItems) : []
                      }
                      options={{
                        hideInterface: true,
                        onChangeActiveRow: ([activeRow]) => setCurrentRow(activeRow),
                        activeRowId: '',
                        levels: [
                          {
                            onRowClick: (row) => {
                              setPopperIsOpen(false);
                              onSelect(row.data);
                            },
                            columns: columns
                              .filter((v) => !v.noDisplay)
                              .map(({ label, name }) => ({ label, id: name })),
                          },
                        ],
                      }}
                    />
                  </RootRef>
                </Paper>
              </HotKeys>
            </ClickAwayListener>
          </Popper>
        </div>
      </HotKeys>
    </>
  );
};
