import React from 'react';
import { Grid } from '@material-ui/core';
import { GetBillOfQuantityOfferConditions_billOfQuantity_offerConditions } from './types/GetBillOfQuantityOfferConditions';
import { GridItem } from './OfferConditionsGridItem';
import { activeKey, textKey } from './OfferConditionsGrid.utils';

interface IGridItemsProps {
  values: Record<string, string | boolean>;
  items: GetBillOfQuantityOfferConditions_billOfQuantity_offerConditions[];
}

/**
 * Puts GridItems into a grid consisting of at most 3 columns on large screens, 2 columns on md screens and 1 on
 * small screens
 * @param values the form values to use
 * @param items the offer conditions the form values are based on
 */
export const GridItems: React.FC<IGridItemsProps> = React.memo(({ items, values }) => {
  const lg = items.length > 3 ? 4 : items.length > 1 ? 6 : 12;
  const md = items.length > 3 ? 6 : 12;
  return (
    <>
      {items.map((element) => (
        <Grid item lg={lg} md={md} xs={12} key={activeKey(element)}>
          <GridItem
            name={element.name}
            activeKey={activeKey(element)}
            textKey={textKey(element)}
            disabled={!values[activeKey(element)]}
          />
        </Grid>
      ))}
    </>
  );
});
