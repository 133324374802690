import React, { useMemo } from 'react';
import { Field } from 'formik';
import FormikTextField from '../../Form/FormikTextField';
import { IStrategyProps } from './MissionStatus';
import { Autocomplete } from '../../Autocomplete';
import { useQuery } from 'react-apollo';
import {
  getAllUsersNames,
  getAllUsersNamesVariables,
} from '../../ProjectDetailsForm/types/getAllUsersNames';
import { GET_ALL_USER_NAMES } from '../../ProjectDetailsForm/ProjectDetailsForm.common';
import FormikCheckboxWithLabel from '../../Form/FormikCheckboxWithLabel';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  provisionalPriceWrapper: {
    marginTop: 16,
    marginBottom: 6,
  },
});

const FixedAppointment: React.FC<IStrategyProps> = ({ disabled }) => {
  const classes = useStyles();

  const { data: usersData } = useQuery<getAllUsersNames, getAllUsersNamesVariables>(
    GET_ALL_USER_NAMES,
    {
      variables: {
        where: { archived: false },
      },
    },
  );

  const userSuggestions = useMemo<string[]>(
    () => (usersData && usersData.users ? usersData.users.map((user: any) => user.name) : []),
    [usersData],
  );

  return (
    <>
      <Field
        name="ec"
        label="Equipenchef"
        minLengthToTrigger={3}
        suggestions={userSuggestions}
        size="normal"
        component={Autocomplete}
        validate={(value: any) => {
          if (value && !userSuggestions.includes(value)) {
            return 'User existiert nicht!';
          }
        }}
      />
      <Field
        name="status.time"
        label="Uhrzeit"
        shrink
        component={FormikTextField}
        type="time"
        disabled={disabled}
      />
      <div className={classes.provisionalPriceWrapper}>
        <Field
          disabled={disabled}
          component={FormikCheckboxWithLabel}
          name="isTimeFixed"
          Label={{ label: 'Uhrzeit fix' }}
        />
      </div>
      <div className={classes.provisionalPriceWrapper}>
        <Field
          disabled={disabled}
          component={FormikCheckboxWithLabel}
          name="isCustomerOnSite"
          Label={{ label: 'Kunde vor Ort' }}
        />
      </div>
      <Field
        name="status.postponementOne"
        label="Verschiebedatum 1"
        component={FormikTextField}
        shrink
        type="date"
        disabled={disabled}
      />
      <Field
        name="status.postponementTwo"
        label="Verschiebedatum 2"
        component={FormikTextField}
        shrink
        type="date"
        disabled={disabled}
      />
    </>
  );
};

export default FixedAppointment;
