import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tooltip, IconButton, Theme } from '@material-ui/core';
import { Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    justifyItems: 'flex-end',
    alignItems: 'flex-end',
  },
  saveIcon: {
    color: green[500],
  },
  cancelIcon: {
    color: red[500],
  },
  iconDisabled: {
    color: theme.palette.grey[400],
  },
}));

interface IProps {
  isDisabled: boolean;
  cancelIsDisabled?: boolean;
  onCancel: () => void;
  disabledTooltipTitles?: Partial<Record<'save' | 'cancel', string>>;
}

const CancelSaveIconButtons: React.FunctionComponent<IProps> = ({
  isDisabled,
  cancelIsDisabled,
  onCancel,
  disabledTooltipTitles,
}) => {
  const saveTooltipTitle = isDisabled ? disabledTooltipTitles?.save ?? 'Speichern' : 'Speichern';
  const cancelTooltipTitle = isDisabled
    ? disabledTooltipTitles?.cancel ?? 'Abbrechen'
    : 'Abbrechen';

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Tooltip title={saveTooltipTitle}>
        <div>
          <IconButton
            type="submit"
            aria-label={saveTooltipTitle}
            disabled={isDisabled}
            color="primary"
            name="save-button"
          >
            <CheckIcon
              className={classNames([classes.saveIcon, isDisabled && classes.iconDisabled])}
            />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title={cancelTooltipTitle}>
        <div>
          <IconButton
            aria-label={cancelTooltipTitle}
            disabled={cancelIsDisabled ?? isDisabled}
            onClick={onCancel}
          >
            <ClearIcon
              className={classNames([classes.cancelIcon, isDisabled && classes.iconDisabled])}
            />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

export default CancelSaveIconButtons;
