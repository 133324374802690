import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { IDataTableRow } from '../types';
import HeaderRow from './HeaderRow';
import { ITableContext } from '..';
import { Droppable } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  children: {
    display: 'flex',
  },
  childrenLeft: {
    width: '14px',
    borderColor: theme.palette.grey[400],
    borderLeftStyle: 'dashed',
    borderLeftWidth: 2,
  },
  childrenRight: { width: '100%' },
}));

interface IProps {
  row: IDataTableRow;
  context: ITableContext;
}

const ContainerRow: React.FunctionComponent<IProps> = React.memo(({ row, children, context }) => {
  const classes = useStyles();
  const { closedRowIds } = context;

  const rowNavigation = context.tableData.navigation[row.id];

  const level = rowNavigation.path.length;
  const levelOptions = context.tableData.options.levels[level];
  const droppableProps = levelOptions.setDroppableProps && levelOptions.setDroppableProps(row);

  /** Container rows are closed if they are in the closed row ids and there are no children rows that are always open */
  const isOpen =
    closedRowIds.indexOf(row.id) === -1 ||
    (row.containerRows || []).length > 0 ||
    (row.innerTableRows || []).length > 0;

  return levelOptions.isDragAndDropEnabled && droppableProps ? (
    <div>
      <HeaderRow row={row} context={context} />
      <Droppable droppableId={droppableProps.id} type={droppableProps.type}>
        {(provided) => {
          return (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {children && isOpen && (
                <div className={classes.children}>
                  <div className={classes.childrenLeft} />
                  <div className={classes.childrenRight}>{children}</div>
                </div>
              )}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </div>
  ) : (
    <div>
      <HeaderRow row={row} context={context} />
      {children && isOpen && (
        <div className={classes.children}>
          <div className={classes.childrenLeft} />
          <div className={classes.childrenRight}>{children}</div>
        </div>
      )}
    </div>
  );
});

export default ContainerRow;
