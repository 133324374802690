import { Grid, IconButton, RootRef, Theme, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { mdiSale } from '@mdi/js';
import Icon from '@mdi/react';
import { FastField as Field, Form, Formik } from 'formik';
import { omit } from 'lodash';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useFocusField } from '../../hooks/useFocusField';
import ColorCoder from '../ColorCoder';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import FormikTextField from '../Form/FormikTextField';
import MaterialColorSelect from '../MaterialColorSelect';

const useStyles = makeStyles((theme: Theme) => ({
  containerGrid: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  scaleDiscountsIcon: {
    fill: theme.palette.action.active,
  },
  scaleDiscountsButton: {
    marginTop: theme.spacing(2),
  },
  colorCoderContainer: {
    padding: theme.spacing(1.5),
  },
}));

interface IItemBoilerplateDetailsFormProps {
  initialValues: any;
  onSubmit: (data: any) => Promise<any>;
}

const mapFormValues = (values: any) => ({
  ...omit(values, ['id', '__typename']),
  targetConsumptionPerUnit: parseFloat(values.targetConsumptionPerUnit),
  timeRequired: parseFloat(values.timeRequired),
});

const ItemBoilerplateDetailsForm: React.FunctionComponent<IItemBoilerplateDetailsFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const firstField = useFocusField();

  const classes = useStyles();
  const [close, setClose] = React.useState(false);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    onSubmit(mapFormValues(values)).then(setSubmitting(false));
  };

  if (close) {
    return <Redirect to="/einzel-positions-vorlagen" />;
  }
  return (
    <Formik
      initialValues={{
        ...initialValues,
        materialColor: initialValues.materialColor || '',
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <Grid container spacing={2} className={classes.containerGrid}>
            <Grid container item>
              <Grid item xs={6} className={classes.colorCoderContainer}>
                {initialValues.materialColor && (
                  <ColorCoder justify="flex-start" colorCodings={[initialValues.materialColor]} />
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid container justify="flex-end">
                  <Tooltip title="Schliessen">
                    <IconButton onClick={() => setClose(true)} aria-label="Close">
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} lg={3}>
              <RootRef rootRef={firstField}>
                <Field
                  label="Katalog-Verwendungsname"
                  name="catalog"
                  component={FormikTextField}
                  required
                />
              </RootRef>
              <Field
                label="Produktnummer"
                name="productNumber"
                component={FormikTextField}
                required
              />
              <Field label="Kapitel" name="catalogSection" component={FormikTextField} required />
              <Field
                label="Kapitel-Beschreibung"
                name="catalogSectionDescription"
                component={FormikTextField}
                required
              />
              <Field label="Absatz" name="catalogSubsection" component={FormikTextField} required />

              <Field
                label="Absatz-Beschreibung"
                name="catalogSubsectionDescription"
                component={FormikTextField}
                required
              />
              <Field
                label="Hauptgruppe"
                name="catalogMainGroup"
                component={FormikTextField}
                required
              />

              <Field
                label="Hauptgrupprn-Beschreibung"
                name="catalogMainGroupDescription"
                component={FormikTextField}
                required
              />
            </Grid>
            <Grid item md={6} lg={3}>
              <Field label="Beschreibung 1" name="descriptionOne" component={FormikTextField} />
              <Field label="Beschreibung 2" name="descriptionTwo" component={FormikTextField} />
              <Field label="Einheit" name="unit" component={FormikTextField} />
              <Field
                label="Sollverbrauch / Einheit"
                type="number"
                name="targetConsumptionPerUnit"
                component={FormikTextField}
              />
              <Field label="Kurzbezeichnung" name="acronym" component={FormikTextField} />
              <Field label="Typ" name="markingStyle" component={FormikTextField} />
              <Field label="Dimension" name="dimensionOne" component={FormikTextField} />
              <Field label="Dimension 2" name="dimensionTwo" component={FormikTextField} />
            </Grid>
            <Grid item md={6} lg={3}>
              <Field label="Freitext" name="freeText" component={FormikTextField} />
              <Field label="Reflektion" name="reflexion" component={FormikTextField} />
              <Field
                label="Zeitbedarf"
                type="number"
                name="timeRequired"
                component={FormikTextField}
              />
              <Field label="Kategorie" name="category" component={FormikTextField} />
              <MaterialColorSelect name="materialColor" label="Materialcode" />
              <Field label="Farbe" name="color" component={FormikTextField} />
              <Tooltip title="Staffelrabatte">
                <Link to={`/einzel-positions-vorlagen/${initialValues.id}/staffelrabatte`}>
                  <IconButton className={classes.scaleDiscountsButton}>
                    <Icon path={mdiSale} size={1.7} className={classes.scaleDiscountsIcon} />
                  </IconButton>
                </Link>
              </Tooltip>
            </Grid>
            <Grid container justify="flex-end">
              <CancelSaveButtons isDisabled={isSubmitting || !dirty} />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ItemBoilerplateDetailsForm;
