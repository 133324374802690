import ApolloClient from 'apollo-client';
import { Maybe } from 'graphql/jsutils/Maybe';
import { isEmpty } from 'lodash';
import { tryParseCompositeLocationId } from '.';
import { SET_PAGE_INFO } from '../../../../../services/graphql-client';
import { GetDataTables_dataTables } from '../../../../../services/types/GetDataTables';
import { SetPageInfo, SetPageInfoVariables } from '../../../../../services/types/SetPageInfo';
import { ItemWhereInput, SearchInput } from '../../../../../types/graphql';
import { deepCopy } from '../../../../../utils/deepCopy';
import {
  IPaginationHelperFns,
  IRefetchItemsInContainerInput,
} from '../../../../../utils/paginationHelpers';
import { getAllContainerRows } from '../../../../../utils/paginationHelpers/getAllContainerRows';
import { ISearchSubmitProps } from '../../../../Search/Search';
import { BoqTableType } from './executeForBoqTypes';
import { mapItemToInnerTableRow } from './mapper';
import { buildContainerItemCountMapping } from './onSearch';
import { REFETCH_ITEMS_IN_CONTAINER } from './queries';
import {
  RefetchItemsInContainer,
  RefetchItemsInContainer_locations,
  RefetchItemsInContainerVariables,
} from './types/RefetchItemsInContainer';

export const isOneOfRowInputs =
  (inputs: IRefetchItemsInContainerInput[]) =>
  (containerRow: ReturnType<typeof getAllContainerRows>[number]) => {
    return inputs.some(({ containerId }) => containerId === containerRow.id);
  };

interface IFetchRefetchItemsQueryQueryOpts {
  take: number;
  itemsWhere: ItemWhereInput;
  search: Maybe<SearchInput>;
}

const refetchLocations = (containerIds: string[]) => {
  const idIn = containerIds.map(tryParseCompositeLocationId('locationId'));

  return (client: ApolloClient<any>) =>
    async (
      queryOpts: IFetchRefetchItemsQueryQueryOpts,
    ): Promise<RefetchItemsInContainer_locations[]> => {
      const {
        data: { locations: refetchedLocations },
      } = deepCopy(
        await client.query<RefetchItemsInContainer, RefetchItemsInContainerVariables>({
          query: REFETCH_ITEMS_IN_CONTAINER,
          variables: {
            where: { id_in: idIn },
            take: queryOpts.take,
            itemsWhere: queryOpts.itemsWhere ?? {},
            search: queryOpts.search,
          },
        }),
      );

      return refetchedLocations;
    };
};

const writeRefetchedLocationToCache =
  (client: ApolloClient<any>, tableType: BoqTableType) =>
  (containerRowsToRefetch: ReturnType<typeof getAllContainerRows>) =>
  (refetchedLocation: RefetchItemsInContainer_locations): void => {
    const containerRow = containerRowsToRefetch.find((containerRow) => {
      return refetchedLocation.id === tryParseCompositeLocationId('locationId')(containerRow.id);
    })!;

    client.cache.writeData({
      id: `DataTableRow:${containerRow.id}`,
      data: {
        ...containerRow,
        innerTableRows: refetchedLocation.itemsConnection.nodes.map(
          mapItemToInnerTableRow(tableType),
        ),
      },
    });
    client.query<SetPageInfo, SetPageInfoVariables>({
      query: SET_PAGE_INFO,
      variables: {
        data: {
          id: containerRow.id,
          hasNextPage: refetchedLocation.itemsConnection.pageInfo.hasNextPage,
        },
      },
    });
  };

export const refetchItemsInContainerBillOfQuantity =
  (client: ApolloClient<any>, tableType: BoqTableType) =>
  (searchState: ISearchSubmitProps) =>
  (setLoading: (v: boolean) => void) =>
  (
    filteredDataTable: GetDataTables_dataTables | null,
  ): IPaginationHelperFns<ItemWhereInput>['refetchItemsInContainer'] =>
  async (inputs, variables) => {
    setLoading(true);

    if (!filteredDataTable) {
      return setLoading(false);
    }

    const containerRows = getAllContainerRows(filteredDataTable);
    const containerRowsToRefetch = containerRows.filter(isOneOfRowInputs(inputs));
    if (isEmpty(containerRowsToRefetch)) {
      return setLoading(false);
    }

    const containerItemCountMapping = buildContainerItemCountMapping(containerRowsToRefetch);
    for (const { itemAmount, containerIds } of containerItemCountMapping) {
      const refetchedLocations = await refetchLocations(containerIds)(client)({
        itemsWhere: variables ?? { isFinished: true },
        search: searchState,
        take: itemAmount,
      });

      refetchedLocations.forEach(
        writeRefetchedLocationToCache(client, tableType)(containerRowsToRefetch),
      );
    }

    setLoading(false);
  };
