import gql from 'graphql-tag';

export const PDF_PRINT_EXECUTION_DATE_RANGE_QUERY = gql`
  query PdfPrintExecutionDateRange($projectNumber: String!) {
    project(where: { projectNumber: $projectNumber }) {
      id
      missions(orderBy: { fieldName: "executionDate_DESC", isComputed: false }) {
        id
        executionDate
      }
    }
  }
`;

export const PDF_PRINT_QUERY = gql`
  query PdfPrint(
    $type: PdfPrintType!
    $where: PdfPrintWhereInput!
    $include: PdfPrintIncludeInput!
    $storeIn: [PdfPrintStoreIn!]!
  ) {
    pdfPrint(type: $type, where: $where, include: $include, storeIn: $storeIn) {
      fileName
    }
  }
`;
