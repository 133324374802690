import { makeStyles } from '@material-ui/styles';
import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import Dinero from 'dinero.js';
import { Field, Form, useField, useFormikContext } from 'formik';
import classNames from 'classnames';
import FormikTextField from '../../../../components/Form/FormikTextField';
import {
  preventNonNumericInput,
  preventNonNumericInputSigned,
} from '../../../../utils/preventNonNumericInput';
import { Autocomplete } from '../../../../components/Autocomplete';
import { fuzzyMatch } from '../../utils/fuzzyMatch';
import Fab from '@material-ui/core/Fab/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { createSwissCurrencyFormatter } from '../../../../utils/createCurrencyFormatter';
import { getDuration, toDay } from '../../../../utils/durations';
import { cachedTabIndex, tabIndexDisabled } from '../../utils/tabIndex';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

export interface IManualAccountingFormValues {
  activityDate: string;
  parent: number | string;
  activityType: string;
  creditedTo: string;
  chargedTo: string;
  amount: number;
  startTime: string;
  endTime: string;
  comment: string;
  pricePerUnit: number;
}

const useStyles = makeStyles(() => ({
  entryContainer: {
    minWidth: 2160,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    minHeight: 150,
  },
  entryField: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingRight: '1em',
    '&:last-of-type': {
      paddingRight: 0,
    },
    '& > div': {
      paddingTop: '0.25em',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  dateEntryField: {
    flex: 0.75,
  },
  parentEntryField: {
    flex: 0.5,
  },
  timeEntryField: {
    flex: 0.3,
  },
  amountEntryField: {
    flex: 0.4,
  },
}));

const amountInputProps = {
  onFocus: (evt: SyntheticEvent<HTMLElement>) => {
    evt.currentTarget.parentNode?.querySelector('input')?.select();
  },
  ...cachedTabIndex(6),
};

const pricePerUnitInputProps = {
  ...amountInputProps,
  ...tabIndexDisabled,
};

interface IActivityTypeMeta {
  nameTwo?: string | null;
  pricePerUnit?: number | null;
  manualPricePerUnit?: boolean;
}

interface ICreditedToMeta {
  nameTwo?: string | null;
  subsidiary?: string;
  // only exists on person which only exist in creditedTo
  workload?: number;
}

interface IChargedToMeta {
  nameTwo?: string | null;
  projectNumber?: string;
  subsidiary?: string;
}

interface IDataEntryFormProps {
  disabled?: boolean;
  activityTypeMeta?: IActivityTypeMeta;
  activityTypeSuggestions: readonly string[];
  validateActivityTypeSuggestion: (value: string | undefined) => string | undefined;
  creditedToMeta?: ICreditedToMeta;
  creditedToSuggestions: readonly string[];
  validateCreditedToSuggestion: (value: string | undefined) => string | undefined;
  chargedToMeta?: IChargedToMeta;
  chargedToSuggestions: readonly string[];
  validateChargedToSuggestion: (value: string | undefined) => string | undefined;
  unit: string;
  edit?: boolean;
}

/**
 * The entry form for manual accounting
 * @param disabled is the form disabled?
 * @param unit which unit is the amount in?
 * @param activityTypeMeta metadata for the activity type
 * @param activityTypeSuggestions possible activity type suggestions
 * @param validateActivityTypeSuggestion validation function for the activity types field
 * @param creditedToMeta metadata for the creditedTo entity
 * @param creditedToSuggestions possible credited to suggestions
 * @param validateCreditedToSuggestion validation function for the credited to field
 * @param chargedToMeta metadata for the chargedTo entity
 * @param chargedToSuggestions possible charged to suggestions
 * @param validateChargedToSuggestion validation function for the charged to field
 * @param edit ist the form in edit mode?
 * @constructor
 */
export const DataEntryForm: React.FC<IDataEntryFormProps> = ({
  disabled = false,
  unit,
  activityTypeMeta,
  activityTypeSuggestions,
  validateActivityTypeSuggestion,
  creditedToMeta,
  creditedToSuggestions,
  validateCreditedToSuggestion,
  chargedToMeta,
  chargedToSuggestions,
  validateChargedToSuggestion,
  edit = false,
}) => {
  const styles = useStyles();

  const [{ value: startTime }] = useField<IManualAccountingFormValues['startTime']>('startTime');
  const validateEndTime = useCallback(
    (endTime: string | undefined) => {
      // time strings have 5 characters: 12:45
      if (
        endTime != null &&
        endTime.length === 5 &&
        (startTime == null || startTime.length !== 5)
      ) {
        return 'Endzeit benötigt Startzeit!';
      }
      if (
        startTime != null &&
        startTime.length === 5 &&
        (endTime == null || endTime.length !== 5)
      ) {
        return 'Startzeit benötigt Endzeit!';
      }
      // time strings are self sorting
      if (
        endTime != null &&
        endTime.length === 5 &&
        startTime != null &&
        startTime.length === 5 &&
        startTime >= endTime
      ) {
        return 'Ende darf nicht vor Anfang liegen!';
      }
    },
    [startTime],
  );

  const [{ value: endTime }] = useField<IManualAccountingFormValues['endTime']>('endTime');

  const [{ value: amount }] = useField<IManualAccountingFormValues['amount']>('amount');
  const [{ value: pricePerUnit }] =
    useField<IManualAccountingFormValues['pricePerUnit']>('pricePerUnit');

  const timeFieldsDisabled = unit.toUpperCase() !== 'H';

  const { setFieldValue } = useFormikContext();

  const amountKeyPress = useCallback(
    (e: KeyboardEvent) => {
      setFieldValue('startTime', ' ');
      setFieldValue('endTime', ' ');
      preventNonNumericInputSigned(e);
    },
    [setFieldValue],
  );

  const [{ value: creditedTo }] = useField<IManualAccountingFormValues['creditedTo']>('creditedTo');
  // clear creditedto if it's not part of the suggestions anymore
  useEffect(
    () => {
      if (creditedToSuggestions.length > 0 && !creditedToSuggestions.includes(creditedTo)) {
        setFieldValue('creditedTo', '');
      }
    },
    // creditedTo is _not_ part of this mechanism
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFieldValue, creditedToSuggestions],
  );
  const [{ value: chargedTo }] = useField<IManualAccountingFormValues['chargedTo']>('chargedTo');
  // clear chargedto if it's not part of the suggestions anymore
  useEffect(
    () => {
      if (chargedToSuggestions.length > 0 && !chargedToSuggestions.includes(chargedTo)) {
        setFieldValue('chargedTo', '');
      }
    },
    // chargedTo is _not_ part of this mechanism
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFieldValue, chargedToSuggestions],
  );

  // using an effect here since onkeypress is a bit choppy with the time component
  useEffect(() => {
    if (startTime.length === 5 && endTime.length === 5 && endTime > startTime) {
      setFieldValue('amount', getDuration(toDay(new Date()), startTime, endTime));
    }
  }, [setFieldValue, startTime, endTime]);

  return (
    <Form>
      <div className={styles.entryContainer}>
        <div className={classNames(styles.entryField, styles.dateEntryField)}>
          <Field
            name="activityDate"
            label="AR. Aufwandsdatum"
            type="date"
            component={FormikTextField}
            disabled={disabled}
            required
            inputProps={cachedTabIndex(1)}
          />
        </div>
        <div className={classNames(styles.entryField, styles.parentEntryField)}>
          <Field
            name="parent"
            label="Mutterbuchung"
            component={FormikTextField}
            type="number"
            onKeyPress={preventNonNumericInput}
            disabled={disabled}
            inputProps={cachedTabIndex(2)}
          />
        </div>
        <div className={styles.entryField}>
          <Field
            name="activityType"
            label="Was"
            type="text"
            component={Autocomplete}
            suggestions={activityTypeSuggestions}
            maxSuggestions={75}
            matcher={fuzzyMatch}
            minLengthToTrigger={0}
            selectOnFocus
            fillOnEnter
            fillOnBlur
            next="input[name='creditedTo']"
            validate={validateActivityTypeSuggestion}
            disabled={disabled}
            required
            inputProps={cachedTabIndex(3)}
          />
          {activityTypeMeta?.nameTwo != null && activityTypeMeta?.nameTwo.length > 0 && (
            <div>Bezeichnung 2: {activityTypeMeta?.nameTwo}</div>
          )}
          {activityTypeMeta?.pricePerUnit != null && (
            <div>AR. Ansatz: {formatCurrency(activityTypeMeta?.pricePerUnit)}</div>
          )}
          {activityTypeMeta?.manualPricePerUnit && (
            <Field
              name="pricePerUnit"
              label="AR. Ansatz"
              component={FormikTextField}
              type="number"
              onKeyPress={preventNonNumericInputSigned}
              inputProps={pricePerUnitInputProps}
              disabled={disabled}
              required
            />
          )}
        </div>
        <div className={styles.entryField}>
          <Field
            name="creditedTo"
            label="Zugunsten"
            type="text"
            component={Autocomplete}
            suggestions={creditedToSuggestions}
            maxSuggestions={75}
            matcher={fuzzyMatch}
            minLengthToTrigger={0}
            selectOnFocus
            fillOnEnter
            fillOnBlur
            next="input[name='chargedTo']"
            validate={validateCreditedToSuggestion}
            disabled={disabled}
            required
            inputProps={cachedTabIndex(4)}
          />
          {creditedToMeta?.nameTwo != null && creditedToMeta?.nameTwo.length > 0 && (
            <div>Bezeichnung 2: {creditedToMeta?.nameTwo}</div>
          )}
          {creditedToMeta?.workload != null && creditedToMeta?.workload > 0 && (
            <div>Arbeitspensum: {creditedToMeta?.workload}</div>
          )}
          {creditedToMeta?.subsidiary != null && <div>Filiale: {creditedToMeta?.subsidiary}</div>}
        </div>
        <div className={styles.entryField}>
          <Field
            name="chargedTo"
            label="Zulasten"
            type="text"
            component={Autocomplete}
            suggestions={chargedToSuggestions}
            maxSuggestions={75}
            matcher={fuzzyMatch}
            minLengthToTrigger={0}
            selectOnFocus
            fillOnEnter
            fillOnBlur
            next="input[name='amount']"
            validate={validateChargedToSuggestion}
            disabled={disabled}
            required
            inputProps={cachedTabIndex(5)}
          />
          {chargedToMeta?.nameTwo != null && chargedToMeta?.nameTwo.length > 0 && (
            <div>Bezeichnung 2: {chargedToMeta?.nameTwo}</div>
          )}
          {chargedToMeta?.projectNumber != null && chargedToMeta?.projectNumber.length > 0 && (
            <div>Projektnummer: {chargedToMeta?.projectNumber}</div>
          )}
          {chargedToMeta?.subsidiary != null && <div>Filiale: {chargedToMeta?.subsidiary}</div>}
        </div>
        <div className={classNames(styles.entryField, styles.amountEntryField)}>
          <Field
            name="amount"
            label="AR. Menge"
            component={FormikTextField}
            type="number"
            onKeyPress={amountKeyPress}
            inputProps={amountInputProps}
            disabled={disabled}
            required
          />
          {unit.length > 0 && <div>Einheit: {unit}</div>}
          {(activityTypeMeta?.pricePerUnit != null || activityTypeMeta?.manualPricePerUnit) && (
            <div>
              Summe:{' '}
              {formatCurrency(
                Dinero({ amount: activityTypeMeta?.pricePerUnit ?? 100 * pricePerUnit }).multiply(
                  Number(amount),
                ),
              )}
            </div>
          )}
        </div>
        <div className={classNames(styles.entryField, styles.timeEntryField)}>
          <Field
            name="startTime"
            label="AR. Startzeit"
            component={FormikTextField}
            type="time"
            onChange={console.log}
            onKeyPress={preventNonNumericInput}
            disabled={disabled || timeFieldsDisabled}
            inputProps={timeFieldsDisabled ? tabIndexDisabled : cachedTabIndex(7)}
          />
        </div>
        <div className={classNames(styles.entryField, styles.timeEntryField)}>
          <Field
            name="endTime"
            label="AR. Endzeit"
            component={FormikTextField}
            type="time"
            onKeyPress={preventNonNumericInput}
            disabled={disabled || timeFieldsDisabled}
            validate={validateEndTime}
            inputProps={timeFieldsDisabled ? tabIndexDisabled : cachedTabIndex(8)}
          />
        </div>
        <div className={styles.entryField}>
          <Field
            name="comment"
            label="Bemerkung"
            type="text"
            component={FormikTextField}
            disabled={disabled}
            inputProps={cachedTabIndex(9)}
          />
        </div>
        <div>
          <Fab
            color={edit ? 'secondary' : 'primary'}
            size="large"
            disabled={disabled}
            type="submit"
            tabIndex={10}
          >
            {edit ? <EditIcon /> : <AddIcon />}
          </Fab>
        </div>
      </div>
    </Form>
  );
};
