import { ICalendarEvent } from '../../components/Calendar/calender.types';
import { GetEmployeesCalendars_getEmployeesCalendars_events } from './types/GetEmployeesCalendars';

export interface IEmployeeSuggestions {
  employeeMap: EmployeeMap;
}

export type EmployeeMap = Array<{
  id: string;
  fullName: string;
}>;

export const mapEvents = (
  eventItems: GetEmployeesCalendars_getEmployeesCalendars_events[],
): ICalendarEvent[] => {
  return eventItems.map(({ start, end, chargedTo, activityType, reportId }) => {
    return {
      start: new Date(start),
      end: new Date(end),
      activityType: activityType.number,
      title: activityType.nameOne,
      ...(chargedTo.mission
        ? {
            description: [
              `${chargedTo.mission.id} - ${chargedTo.mission.name}`,
              `${chargedTo.mission.project.projectNumber} - ${chargedTo.mission.project.projectName}`,
              chargedTo.subsidiary.name,
            ],
          }
        : []),
      ...(chargedTo.collectiveAccount
        ? {
            description: [
              `${chargedTo.collectiveAccount.nameOne}${
                chargedTo.collectiveAccount.nameTwo
                  ? ' - ' + chargedTo.collectiveAccount.nameTwo
                  : ''
              }`,
              `${chargedTo.collectiveAccount.projectNumber} - Sammelprojekt`,
              chargedTo.collectiveAccount.subsidiary.name,
            ],
          }
        : []),
      ...(chargedTo.vehicle
        ? {
            description: [
              `${chargedTo.vehicle.inventoryNumber} - ${chargedTo.vehicle.nameOne}${
                chargedTo.vehicle.nameTwo ? ' - ' + chargedTo.vehicle.nameTwo : ''
              }`,
              chargedTo.vehicle.subsidiary?.name ?? '',
            ],
          }
        : []),
      url: reportId ? `/buchungsjournal/rapport/${reportId}` : '/buchungsjournal/manuell',
    };
  });
};
