import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { lighten } from '@material-ui/core/styles';
import { MissionManualLockingStatus } from '../../types/graphql';

const useStyles = makeStyles((theme) => {
  return {
    blocked: {
      backgroundColor: lighten(green[700], 0.7),
    },
    unBlocked: {
      backgroundColor: lighten(theme.palette.error.light, 0.7),
    },
  };
});

export default function useBlockedMissionClassName() {
  const classes = useStyles();

  const getBlockedClassName = (
    isMissionBlocked: boolean,
    manualLockingStatus: MissionManualLockingStatus | null,
  ) => {
    if (manualLockingStatus) {
      return manualLockingStatus === MissionManualLockingStatus.BLOCKED
        ? classes.blocked
        : classes.unBlocked;
    }

    if (isMissionBlocked) {
      return classes.blocked;
    }

    return '';
  };

  return getBlockedClassName;
}
