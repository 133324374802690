import * as Xlsx from 'xlsx';
import { IDataTableRow } from '../components/DataTable/types';

export const CSV_FS = ';';

export const generateCsv = (data: any[], headers?: string[]) => {
  const sheet = Xlsx.utils.json_to_sheet(data, { ...(headers && { header: headers }) });
  return Xlsx.utils.sheet_to_csv(sheet, { FS: CSV_FS });
};

export const downloadCsv = (csv: string, name: string) => {
  saveAs(new Blob(['\ufeff', csv], { type: 'text/plain;charset=utf-8' }), `${name}.csv`);
};

export interface IRowData {
  [s: string]: any;
}

export const handleDownloadCsvClickUtil = (
  innerTableRows: IDataTableRow[],
  cb: (rowData: IRowData) => { [s: string]: string | undefined },
) => {
  const data: IRowData[] = innerTableRows.map((row) => {
    const strippedObj: any = {};

    Object.entries(row.data)
      .filter(
        (item) =>
          typeof item[1] === 'boolean' ||
          typeof item[1] === 'number' ||
          (typeof item[1] === 'string' && item[1]) || // check if string (but not empty string)
          (item[1] && typeof item[1] !== 'boolean' && 'length' in (item[1] as any)), // check if array
      )
      .forEach((item) => (strippedObj[item[0]] = item[1]));

    return strippedObj;
  });

  const items = data.map((row) => cb(row));

  return generateCsv(items);
};
