/**
 * mutate an array and move an item from the old index to the new index
 * @param array the array to mutate
 * @param oldIndex the old index
 * @param newIndex the new index
 */
export const swapArrayValues = <T>(array: T[], oldIndex: number, newIndex: number) => {
  if (oldIndex < 0 || oldIndex >= array.length) {
    return array;
  }
  if (newIndex < 0 || newIndex >= array.length) {
    return array;
  }
  const oldValue = array[oldIndex];
  array.splice(oldIndex, 1);
  array.splice(newIndex, 0, oldValue);
  return array;
};
