import React from 'react';
import { Variant, default as CustomizedSnackbar } from '../CustomizedSnackbar';

interface IProps {
  message: string;
  noAutoHide?: boolean;
  noHideOnClickAway?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
}

const AppErrorMessage: React.FunctionComponent<IProps> = ({
  message,
  noAutoHide,
  noHideOnClickAway,
  isOpen,
  onClose,
}) => {
  const [open, setOpen] = React.useState(isOpen ?? true);

  return (
    <CustomizedSnackbar
      open={open}
      hasCloseButton={true}
      variant={Variant.error}
      autoHideDuration={noAutoHide ? undefined : 6000}
      message={message}
      onClose={(e, reason) => {
        if (noHideOnClickAway && reason === 'clickaway') {
          return;
        }

        setOpen(false);
        onClose?.();
      }}
    />
  );
};

export default AppErrorMessage;
