import React from 'react';
import Navigation from './Navigation';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
}));

interface ILayoutProps {
  main?: React.ReactNode;
  title?: React.ReactNode;
  tabs?: React.ReactNode;
}

const Layout: React.FunctionComponent<ILayoutProps> = ({ main, title, tabs }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Navigation title={title} tabs={tabs} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {main}
      </main>
    </div>
  );
};

export default Layout;
