import {
  emptyImpersonal,
  emptyImpersonalEntry,
  emptyInitialValues,
  emptyTimeEntry,
  IAccountLogggerFormValues,
  IImpersonalEntry,
  ITimeEntry,
} from '../types';
import { GetAccountingEmployees_employees } from '../types/GetAccountingEmployees';
import {
  searchableActivityType,
  searchableEmployee,
  searchableMachine,
  searchableMaterial,
  searchableMission,
  searchableVehicle,
} from '../../utils/searchable';
import { GetAccountingMissions_missions } from '../types/GetAccountingMissions';
import { GetAccountingMaterials_activeMaterialCatalog_materials } from '../types/GetAccountingMaterials';
import { GetAccountingVehicles_vehicles } from '../types/GetAccountingVehicles';
import {
  chargeConstraint,
  ChargedTo,
  CreditedTo,
  parseChargeConstraint,
} from '../../utils/chargeConstraint';
import { GetAccountingActivityTypes_activityTypes } from '../types/GetAccountingActivityTypes';
import { GetAccountingMachines_machines } from '../types/GetAccountingMachines';
import {
  AccountingEntityUpsertInput,
  AccountingItemInput,
  ChargeType,
} from '../../../../types/graphql';
import { GetAccountingLog_report } from '../types/GetAccountingLog';
import { isEqual } from 'lodash/fp';
import {
  getDuration,
  getDurationFromCell,
  isSpilloverFromCell,
  toDate,
  toDay,
} from '../../../../utils/durations';
import {
  PreviewAccountingLogItems_calculatePreviewItems,
  PreviewAccountingLogItems_calculatePreviewItems_drivingItems,
  PreviewAccountingLogItems_calculatePreviewItems_employeeBonusesOverviews,
  PreviewAccountingLogItems_calculatePreviewItems_employeeBonusesOverviews_accountingItems,
  PreviewAccountingLogItems_calculatePreviewItems_setupItems,
} from '../types/PreviewAccountingLogItems';
import {
  filterBonusActivityTypes,
  filterDrivingActivityTypes,
  filterImpersonalActivityTypes,
  filterSetupActivityTypes,
  filterTimeActivityTypes,
} from './filterItems';

// todo dynamic?
const vehicleCategories = ['PW', 'BUS', 'ANHAENGER'];

type TimeSpan = Pick<AccountingItemInput, 'startTime' | 'endTime' | 'amount' | 'activityDate'>;

/**
 * convert form values to accounting items that can be sent to the api
 * @param employees the available employees for object resolution
 * @param missions the available missions for object resolution
 * @param materials the available materials for object resolution
 * @param vehicles the available vehicles for object resolution
 * @param machines the available machines for object resolution
 * @param activityTypes the available activity types for object resolution
 * @param values the form values
 * @returns an array of accounting items to be sent to the api
 */
export const toAccountingItems = (
  employees: readonly GetAccountingEmployees_employees[],
  missions: readonly GetAccountingMissions_missions[],
  materials: readonly GetAccountingMaterials_activeMaterialCatalog_materials[],
  vehicles: readonly GetAccountingVehicles_vehicles[],
  machines: readonly GetAccountingMachines_machines[],
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[],
  values: IAccountLogggerFormValues,
): AccountingItemInput[] => {
  if (
    !employees.length ||
    !missions.length ||
    !materials.length ||
    !vehicles.length ||
    !machines.length ||
    !activityTypes.length
  ) {
    // not fully loaded yet
    return [];
  }
  /*
   * report date
   */
  const date = values.date;

  /*
   * indices for quicker lookup
   */
  const indexedEmployees = Object.fromEntries(
    employees.map((employee) => [searchableEmployee(employee), employee]),
  );
  const indexedMissions = Object.fromEntries(
    missions.map((mission) => [searchableMission(mission), mission]),
  );
  const indexedActitivityTypes = Object.fromEntries(
    activityTypes.map((activityType) => [searchableActivityType(activityType), activityType]),
  );
  const indexedMaterials = Object.fromEntries(
    materials.map((material) => [searchableMaterial(material), material]),
  );
  const indexedVehicles = Object.fromEntries(
    vehicles.map((vehicle) => [searchableVehicle(vehicle), vehicle]),
  );
  const indexedMachines = Object.fromEntries(
    machines.map((machine) => [searchableMachine(machine), machine]),
  );

  /*
   * prepare employees
   */
  const allEmployees = [values.chief, ...values.employees];
  const group = allEmployees.slice(0);
  const additionalEmployees = values.individualTimeEntries
    .filter(({ person }) => !group.includes(person))
    .map(({ person }) => person);
  group.push(...additionalEmployees);

  /*
   * flatten the work times
   */
  const workTimes = group
    .filter((employee) => employee !== '')
    .flatMap((searchableEmployee) => {
      const employee = indexedEmployees[searchableEmployee];
      if (!employee) {
        return null;
      }
      const employeeWhere = {
        id: employee?.id,
      };
      const workload = Number(employee.workload);
      console.assert(!isNaN(workload), 'should be a number');
      const costPerUnit = employee.function?.hourlyWage ?? 0;

      // concat the individual entries for the person
      const individualEntries =
        values.individualTimeEntries
          .find(({ person }) => person === searchableEmployee)
          ?.entries.slice(0, -1) ?? [];
      const timeEntries = (
        allEmployees.includes(searchableEmployee) ? values.timeEntries.slice(0, -1) : []
      )
        .concat(individualEntries)
        .filter(
          (timeEntry) =>
            /\d\d:\d\d/.test(timeEntry.timeFrom) || !/\d\d:\d\d/.test(timeEntry.timeTo),
        );
      return timeEntries.flatMap((timeEntry): AccountingItemInput[] => {
        const activityType = indexedActitivityTypes[timeEntry.activityType];
        if (activityType == null) {
          return [];
        }

        const common = {
          costPerUnit,
          workload,
        };
        const creditedTo: AccountingEntityUpsertInput = {
          data: {
            ...common,
            type: ChargeType.EMPLOYEE,
            employee: employeeWhere,
            subsidiary: { id: employee?.subsidiary.id },
          },
        };

        // chargedto is usually a mission
        // empty string or the string "Sammelkonto" (case insensitive) maps to a collective account
        // in the case of URE it can also be a vehicle or a machine
        const mission = indexedMissions[timeEntry.mission];
        const vehicle = indexedVehicles[timeEntry.mission];
        const machine = indexedMachines[timeEntry.mission];
        if (
          !['', 'sammelkonto'].includes(timeEntry.mission.trim().toLowerCase()) &&
          !mission &&
          !vehicle &&
          !machines
        ) {
          return [];
        }
        const chargedToType = mission
          ? ChargeType.MISSION
          : vehicle
          ? ChargeType.VEHICLE
          : machine
          ? ChargeType.MACHINE
          : ChargeType.COLLECTIVEACCOUNT;
        const chargedTo: AccountingEntityUpsertInput = {
          data: {
            ...common,
            type: chargedToType,
            // to have the backend fill in the collective account it's type should be COLLECTIVEACCOUNT and no relations
            // should be used
            ...(mission ? { mission: { id: mission.id } } : {}),
            ...(mission ? { subsidiary: { id: mission.project.subsidiary.id } } : {}),
            // potential URE relations
            ...(vehicle ? { vehicle: { id: vehicle.id } } : {}),
            ...(vehicle && vehicle.subsidiary ? { subsidiary: { id: vehicle.subsidiary.id } } : {}),
            ...(machine ? { machine: { id: machine.id } } : {}),
            ...(machine && machine.subsidiary ? { subsidiary: { id: machine.subsidiary.id } } : {}),
          },
        };
        // split spillover times into 2 entries
        const timeSpans: TimeSpan[] = [];
        if (isSpilloverFromCell(timeEntry)) {
          const amount1 = getDuration(date, timeEntry.timeFrom, '24:00');
          const day1 = {
            startTime: timeEntry.timeFrom,
            endTime: '24:00',
            amount: amount1,
            activityDate: date,
          };
          timeSpans.push(day1);
          // todo currently using 12:00 since the to Day part isn't fully complete
          const activityDate2 = toDay(toDate(date, '12:00', 1));
          const amount2 = getDuration(activityDate2, '00:00', timeEntry.timeTo);
          const day2 = {
            startTime: '00:00',
            endTime: timeEntry.timeTo,
            amount: amount2,
            activityDate: activityDate2,
          };
          timeSpans.push(day2);
        } else {
          const amount = getDurationFromCell(date, timeEntry);
          timeSpans.push({
            startTime: timeEntry.timeFrom,
            endTime: timeEntry.timeTo === '00:00' ? '24:00' : timeEntry.timeTo,
            amount,
            activityDate: date,
          });
        }
        return timeSpans.map((timeSpan) => ({
          chargeConstraint: chargeConstraint(
            activityType,
            CreditedTo.EMPLOYEE,
            ChargedTo[chargedToType],
          ),

          creditedTo,
          chargedTo,
          comment: timeEntry.comment,

          // v activityDate
          // v startTime
          // v endTime
          ...timeSpan,
        }));
      });
    })
    .filter((entry): entry is AccountingItemInput => entry != null);

  /*
   * flatten impersonal missions
   */
  const impersonals = values.impersonal
    .flatMap((impersonal) => {
      const mission = indexedMissions[impersonal.mission];
      if (mission == null) {
        return null;
      }
      return impersonal.entries.slice(0, -1).map((impersonalEntry): AccountingItemInput | null => {
        const activityType = indexedActitivityTypes[impersonalEntry.activityType];
        if (activityType == null) {
          return null;
        }
        const entity:
          | { id: string; internalHourlyWage?: number; pricePerUnit?: number; category?: string }
          | undefined =
          indexedMaterials[impersonalEntry.name] ??
          indexedVehicles[impersonalEntry.name] ??
          indexedMachines[impersonalEntry.name];
        if (
          entity == null &&
          !['', 'sammelkonto'].includes(impersonalEntry.name.trim().toUpperCase())
        ) {
          return null;
        }
        const type =
          entity == null
            ? ChargeType.COLLECTIVEACCOUNT
            : 'category' in entity
            ? vehicleCategories.includes(entity.category ?? '')
              ? ChargeType.VEHICLE
              : ChargeType.MACHINE
            : ChargeType.MATERIAL;

        const where = { id: entity?.id };
        const common = {
          costPerUnit:
            type === ChargeType.COLLECTIVEACCOUNT
              ? 1
              : entity?.internalHourlyWage ?? entity?.pricePerUnit ?? 0,
          subsidiary: { id: mission.project.subsidiary.id },
          workload: 0,
        };
        const creditedTo: AccountingEntityUpsertInput = {
          data: {
            ...common,
            type,
            ...(type === ChargeType.MATERIAL ? { material: where } : {}),
            ...(type === ChargeType.MACHINE ? { machine: where } : {}),
            ...(type === ChargeType.VEHICLE ? { vehicle: where } : {}),
          },
        };
        const chargedTo: AccountingEntityUpsertInput = {
          data: {
            ...common,
            type: ChargeType.MISSION,
            mission: { id: mission.id },
          },
        };
        return {
          chargeConstraint: chargeConstraint(activityType, CreditedTo[type], ChargedTo.MISSION),
          activityDate: impersonal.date,
          amount: impersonalEntry.amount,
          creditedTo,
          chargedTo,
          comment: impersonalEntry.comment,
        };
      });
    })
    .filter((entry): entry is AccountingItemInput => entry != null);

  /*
   * concat the items and return
   */
  return [...workTimes, ...impersonals];
};

export interface IParsedAccountingLog {
  values: IAccountLogggerFormValues;
  calculatePreviewItems?: PreviewAccountingLogItems_calculatePreviewItems;
}

/**
 * cached empty initial values
 */
const emptyParsedAccountingLog: IParsedAccountingLog = {
  values: emptyInitialValues(),
};

/**
 * parse a loaded accounting log
 * @param activityTypes the available activity types for object resolution
 * @param report the loaded accounting log (or null for empty initial values)
 * @returns form values and caculated previews of the accounting log
 */
export const fromAccountingLog = (
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[] | null | undefined,
  report: Readonly<GetAccountingLog_report> | null | undefined,
): IParsedAccountingLog => {
  if (report == null || activityTypes == null) {
    // return empty inital values
    return emptyParsedAccountingLog;
  }

  const sortedAccountingItems =
    report.accountingItems !== null
      ? [...report.accountingItems].sort((a, b) =>
          (a.startTime ?? '') > (b.startTime ?? '') ? 1 : -1,
        )
      : null;

  /*
   * index setup activity types
   * and filter the accounting items to get setup items
   */
  const setupActivityTypes = Object.fromEntries(
    filterSetupActivityTypes(activityTypes).map((activityType) => [
      activityType.number,
      activityType,
    ]),
  );
  const setupItems =
    sortedAccountingItems?.filter((accountingItem) => {
      const { activityType } = parseChargeConstraint(accountingItem.chargeConstraint);
      return activityType in setupActivityTypes;
    }) ?? [];

  /*
   * index driving activity types
   * and filter the accounting items to get driving items
   */
  const drivingActivityTypes = Object.fromEntries(
    filterDrivingActivityTypes(activityTypes).map((activityType) => [
      activityType.number,
      activityType,
    ]),
  );
  const drivingItems =
    sortedAccountingItems?.filter((accountingItem) => {
      const { activityType } = parseChargeConstraint(accountingItem.chargeConstraint);
      return activityType in drivingActivityTypes;
    }) ?? [];

  /*
   * index bonus types
   * and filter the accounting items to get bonus items
   */
  const bonusActivityTypes = Object.fromEntries(
    filterBonusActivityTypes(activityTypes).map((activityType) => [
      activityType.number,
      activityType,
    ]),
  );
  const bonusItems =
    sortedAccountingItems?.filter((accountingItem) => {
      const { activityType } = parseChargeConstraint(accountingItem.chargeConstraint);
      return activityType in bonusActivityTypes;
    }) ?? [];

  /*
   * index time types
   * and filter the accounting items to get the time items
   */
  const timeActivityTypes = Object.fromEntries(
    filterTimeActivityTypes(activityTypes).map((activityType) => [
      activityType.number,
      activityType,
    ]),
  );
  const uncategorizedTimeEntries =
    sortedAccountingItems?.filter((accountingItem) => {
      const { activityType } = parseChargeConstraint(accountingItem.chargeConstraint);
      return activityType in timeActivityTypes;
    }) ?? [];

  /*
   * group bonus items by employee to recreate the bonus overviews object
   * in this stage plannedWorkTime and totalWorkedTime are set to 0 and will be set/calculated in the next step
   */
  const employeeBonusesOverviews = bonusItems.reduce(
    (
      acc: {
        [
          employeeId: string
        ]: PreviewAccountingLogItems_calculatePreviewItems_employeeBonusesOverviews;
      },
      item,
    ) => {
      const employeeId = item.creditedTo.employee?.id;
      if (employeeId == null) {
        console.error('bonus item without credited to employee', item);
        return acc;
      }
      if (employeeId in acc) {
        acc[employeeId].accountingItems.push(
          item as unknown as PreviewAccountingLogItems_calculatePreviewItems_employeeBonusesOverviews_accountingItems,
        );
      } else {
        acc[employeeId] = {
          __typename: 'EmployeeBonusesOverview',
          id: employeeId,
          plannedWorkTime: 0,
          totalWorkedTime: 0,
          accountingItems: [
            item as unknown as PreviewAccountingLogItems_calculatePreviewItems_employeeBonusesOverviews_accountingItems,
          ],
        };
      }
      return acc;
    },
    {},
  );
  /*
   * add up the work times and planned times, done here since doing it in the acc above wont write it if there is no
   * bonus item
   */
  [...bonusItems, ...uncategorizedTimeEntries].forEach((item) => {
    const employeeId = item.creditedTo.employee?.id;
    if (employeeId == null) {
      return;
    }
    const plannedWorkTime =
      report.employees.find(({ id }) => id === employeeId)?.function?.plannedWorkTime ?? 0;
    if (employeeId in employeeBonusesOverviews) {
      employeeBonusesOverviews[employeeId].plannedWorkTime = plannedWorkTime;
      employeeBonusesOverviews[employeeId].totalWorkedTime += item.amount ?? 0;
    } else {
      employeeBonusesOverviews[employeeId] = {
        __typename: 'EmployeeBonusesOverview',
        id: employeeId,
        plannedWorkTime,
        totalWorkedTime: item.amount ?? 0,
        accountingItems: [],
      };
    }
  });

  /*
   * the object containing all calculated previews
   */
  const calculatePreviewItems = {
    __typename: 'CalculatePreviewItemsResult' as const,
    setupItems:
      setupItems as unknown as PreviewAccountingLogItems_calculatePreviewItems_setupItems[],
    drivingItems:
      drivingItems as unknown as PreviewAccountingLogItems_calculatePreviewItems_drivingItems[],
    employeeBonusesOverviews: Object.values(employeeBonusesOverviews),
    // todo
    items: [],
    externalItems: [],
    didChange: false,
  };

  /*
   * prepare the chief and employee fields
   */
  const chiefObj = report.teamLeader;
  if (chiefObj == null) {
    throw new Error(`ReportError: no chief part of report! ` + JSON.stringify(report));
  }
  const chief = searchableEmployee(chiefObj);
  const employees = report.employees.map(searchableEmployee).sort();

  /*
   * accumulate the time entries to build up the respective employee groups
   */
  const timeEntryAcc: { [entryKey: string]: { timeEntry: ITimeEntry; employees: string[] } } = {};
  const spilloversAcc: { [entryKey: string]: { timeEntry: ITimeEntry; employees: string[] } } = {};
  for (const reportTimeEntry of uncategorizedTimeEntries) {
    const { activityType: activityTypeNumber } = parseChargeConstraint(
      reportTimeEntry.chargeConstraint,
    );
    const activityType = searchableActivityType(timeActivityTypes[activityTypeNumber]);
    if (
      reportTimeEntry.chargedTo.mission == null &&
      ![ChargeType.COLLECTIVEACCOUNT, ChargeType.VEHICLE, ChargeType.MACHINE].includes(
        reportTimeEntry.chargedTo.type,
      )
    ) {
      throw new Error(
        'AssertionError: when parsing time entries there needs to be a mission in chargedTo if not billed to collective account! ' +
          JSON.stringify(reportTimeEntry),
      );
    }
    const mission =
      reportTimeEntry.chargedTo.mission != null
        ? searchableMission(reportTimeEntry.chargedTo.mission)
        : reportTimeEntry.chargedTo.vehicle != null
        ? searchableVehicle(reportTimeEntry.chargedTo.vehicle)
        : reportTimeEntry.chargedTo.machine != null
        ? searchableMachine(reportTimeEntry.chargedTo.machine)
        : '';
    const timeFrom = reportTimeEntry.startTime;
    const timeTo = reportTimeEntry.endTime;
    if (timeFrom == null || timeTo == null) {
      throw new Error(
        'AssertionError: when parsing time entries there needs to be a startTime and endTime! ' +
          JSON.stringify(reportTimeEntry),
      );
    }
    const comment = reportTimeEntry.comment;
    const timeEntry: ITimeEntry = {
      activityType,
      mission,
      timeFrom,
      timeTo,
      comment,
    };
    if (reportTimeEntry.creditedTo.employee == null) {
      throw new Error(
        'AssertionError: when parsing time entries there needs to be an employee in creditedTo! ' +
          JSON.stringify(reportTimeEntry),
      );
    }
    const employee = searchableEmployee(reportTimeEntry.creditedTo.employee);
    const timeEntryKey = JSON.stringify(timeEntry);
    if (report.reportDate !== reportTimeEntry.activityDate) {
      if (
        reportTimeEntry.activityDate === toDay(toDate(report.reportDate, '12:00', 1)) &&
        reportTimeEntry.startTime === '00:00'
      ) {
        if (timeEntryKey in spilloversAcc) {
          spilloversAcc[timeEntryKey].employees.push(employee);
          spilloversAcc[timeEntryKey].employees.sort();
        } else {
          spilloversAcc[timeEntryKey] = { timeEntry, employees: [employee] };
        }
      } else {
        console.error('encountered spurious time entry for', report.reportDate, reportTimeEntry);
      }
    } else {
      if (timeEntryKey in timeEntryAcc) {
        timeEntryAcc[timeEntryKey].employees.push(employee);
        timeEntryAcc[timeEntryKey].employees.sort();
      } else {
        timeEntryAcc[timeEntryKey] = { timeEntry, employees: [employee] };
      }
    }
  }

  /*
   * take the spillovers and find the appropriate place in the timeEntry acc and update entries if found
   */
  for (const { timeEntry: spillOverTimeEntry, employees } of Object.values(spilloversAcc)) {
    const previousEntry = Object.values(timeEntryAcc).find(
      (timeEntry) =>
        timeEntry.timeEntry.timeTo === '24:00' &&
        timeEntry.timeEntry.comment === spillOverTimeEntry.comment &&
        timeEntry.timeEntry.activityType === spillOverTimeEntry.activityType &&
        timeEntry.timeEntry.mission === spillOverTimeEntry.mission &&
        isEqual(employees, timeEntry.employees),
    );
    if (previousEntry == null) {
      console.error(
        'found no matching previous entry for spill over entry',
        spillOverTimeEntry,
        timeEntryAcc,
      );
      continue;
    }
    previousEntry.timeEntry.timeTo = spillOverTimeEntry.timeTo;
  }

  /*
   * split the accumulated time entries into group times, i.e. entries for the original group, and person times, i.e.
   * entries for individual persons
   */
  const groupTimes: IAccountLogggerFormValues['timeEntries'] = [];
  const personTimes: IAccountLogggerFormValues['individualTimeEntries'] = [];
  const allEmployees = [chief, ...employees];
  for (const timeEntryKey of Object.keys(timeEntryAcc)) {
    // group times
    const { timeEntry, employees: sortedTimeEntryEmployees } = timeEntryAcc[timeEntryKey];
    const isGroupTime = allEmployees.every((employee) =>
      sortedTimeEntryEmployees.includes(employee),
    );
    if (isGroupTime) {
      // this is a group time since the employees match the report employees
      groupTimes.push(timeEntry);
    }

    // person times
    const individuals = sortedTimeEntryEmployees.filter(
      (employee) => !allEmployees.includes(employee),
    );
    // time entries for individuals
    individuals.forEach((employee) => {
      const personTimeEntry = personTimes.find(({ person }) => person === employee);
      if (personTimeEntry) {
        personTimeEntry.entries.push(timeEntry);
      } else {
        personTimes.push({
          person: employee,
          entries: [timeEntry],
        });
      }
    });
  }

  // add input row
  groupTimes.push(emptyTimeEntry());
  // add input rows
  personTimes.forEach((personTime) => personTime.entries.push(emptyTimeEntry()));

  /*
   * index impersonal activity types
   * and filter the accounting items to get impersonal items
   */
  const impersonalActivityTypes = Object.fromEntries(
    filterImpersonalActivityTypes(activityTypes).map((activityType) => [
      activityType.number,
      activityType,
    ]),
  );
  const uncategorizedImpersonal =
    report.accountingItems?.filter((accountingItem) => {
      const { activityType } = parseChargeConstraint(accountingItem.chargeConstraint);
      return activityType in impersonalActivityTypes;
    }) ?? [];

  /*
   * accumulate all impersonal items based on mission > date
   */
  const impersonalAcc: { [mission: string]: { [date: string]: IImpersonalEntry[] } } = {};
  for (const reportImpersonalEntry of uncategorizedImpersonal) {
    const { activityType: activityTypeNumber } = parseChargeConstraint(
      reportImpersonalEntry.chargeConstraint,
    );
    const activityType = searchableActivityType(impersonalActivityTypes[activityTypeNumber]);

    const name = reportImpersonalEntry.creditedTo.machine
      ? searchableMachine(reportImpersonalEntry.creditedTo.machine)
      : reportImpersonalEntry.creditedTo.vehicle
      ? searchableVehicle(reportImpersonalEntry.creditedTo.vehicle)
      : reportImpersonalEntry.creditedTo.material
      ? searchableMaterial(reportImpersonalEntry.creditedTo.material)
      : '';

    const impersonalEntry = {
      activityType,
      name,
      amount: reportImpersonalEntry.amount ?? 0,
      comment: reportImpersonalEntry.comment ?? '',
    };

    if (reportImpersonalEntry.chargedTo.mission == null) {
      throw new Error(
        'AssertionError: when parsing impersonal entries there needs to be a mission in chargedTo! ' +
          JSON.stringify(reportImpersonalEntry),
      );
    }
    const mission = searchableMission(reportImpersonalEntry.chargedTo.mission);
    const date = reportImpersonalEntry.activityDate;
    if (mission in impersonalAcc) {
      if (date in impersonalAcc[mission]) {
        impersonalAcc[mission][date].push(impersonalEntry);
      } else {
        impersonalAcc[mission][date] = [impersonalEntry];
      }
    } else {
      impersonalAcc[mission] = { [date]: [impersonalEntry] };
    }
  }

  /*
   * flatten accumulated impersonal entries
   */
  const impersonal: IAccountLogggerFormValues['impersonal'] = Object.keys(impersonalAcc).flatMap(
    (mission) =>
      Object.keys(impersonalAcc[mission]).flatMap((date) => ({
        mission,
        date,
        entries: impersonalAcc[mission][date],
      })),
  );
  // add entry row
  impersonal.forEach((item) => item.entries.push(emptyImpersonalEntry()));
  if (impersonal.length === 0) {
    impersonal.push(emptyImpersonal(report.reportDate));
  }

  /*
   * object containing the form values
   */
  const values: IAccountLogggerFormValues = {
    // todo
    isFinal: false,
    // loaded accounting logs start in checking mode
    isCheck: true,
    date: report.reportDate,
    chief,
    employees: employees.concat(
      Array(
        Math.max(
          0,
          // pad form to min. 6 entry fields, -1 since team leader is not part of the array
          6 - report.employees.length - 1,
        ),
      ).fill(''),
    ),
    timeEntries: groupTimes,
    individualTimeEntries: personTimes,
    impersonal,
  };

  return {
    values,
    calculatePreviewItems,
  };
};
