import React, { useCallback, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { useQuery } from 'react-apollo';
import { MOBILE_ITEM_BOILERPLATE_GROUPS } from './mobileItemImporter.queries';
import {
  MobileItemBoilerPlateGroups,
  MobileItemBoilerPlateGroups_itemBoilerplateGroups,
  MobileItemBoilerPlateGroupsVariables,
} from './types/MobileItemBoilerPlateGroups';
import Container from '../../Container/Container';
import Item from '../Item';
import { List, Theme, Box } from '@material-ui/core';
import { HoveringFab } from '../../HoveringButton';
import AddIcon from '@material-ui/icons/Add';
import { pickBy } from 'lodash';
import { ISubmittedItemBoilerplate } from './ItemBoilerplateList';
import { useSearchState, Search } from '../../../../components/Search/Search';
import AppProgress from '../../../../components/Page/AppProgress';
import { makeStyles } from '@material-ui/styles';
import { safeFocusOnClick } from './utils/safeFocusOnClick';

interface IProp {
  onSubmit: (items: ISubmittedItemBoilerplate[]) => void;
}

const searchColumns = ['name'];

const mapSubmittedData = (data: { [index: string]: number }) => {
  return Object.entries(data).map(([id, volume]) => {
    return {
      id,
      volume,
    };
  });
};

const empty = {};
const emptyItemBoilerplateGroups: MobileItemBoilerPlateGroups_itemBoilerplateGroups[] = [];
const getInitialValues = (
  itemBoilerplateGroups: MobileItemBoilerPlateGroups_itemBoilerplateGroups[],
) => {
  if (itemBoilerplateGroups.length === 0) {
    return empty;
  }
  return itemBoilerplateGroups.reduce((acc: any, curr) => {
    curr.itemBoilerplates.forEach((item) => {
      acc[item.id] = 0;
    });

    return acc;
  }, {});
};

const useStyles = makeStyles((theme: Theme) => ({
  searchWrapper: {
    marginTop: theme.spacing(2),
  },
}));

const ItemBoilerplateGroupsList: React.FC<IProp> = ({ onSubmit }) => {
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');

  const classes = useStyles();

  const searchState = useSearchState();

  const { data, loading, error, refetch } = useQuery<
    MobileItemBoilerPlateGroups,
    MobileItemBoilerPlateGroupsVariables
  >(MOBILE_ITEM_BOILERPLATE_GROUPS);

  const onSubmitSearch = useCallback((search) => refetch({ search }), [refetch]);
  const onMappedSubmit = useCallback(
    (values) => {
      onSubmit(mapSubmittedData(pickBy(values)));
    },
    [onSubmit],
  );

  const itemBoilerplateGroups = data?.itemBoilerplateGroups ?? emptyItemBoilerplateGroups;

  const initialValues = useMemo(
    () => getInitialValues(itemBoilerplateGroups),
    [itemBoilerplateGroups],
  );

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <div className={classes.searchWrapper}>
        <Search
          columns={searchColumns}
          searchState={searchState}
          onSubmit={onSubmitSearch}
          loading={loading}
        />
      </div>
      <Formik onSubmit={onMappedSubmit} initialValues={initialValues} enableReinitialize>
        {({ values }) => (
          <Form>
            <Box marginBottom={12.5}>
              {itemBoilerplateGroups?.map((group) => {
                return (
                  <Container
                    key={group.id}
                    name={group.name}
                    onSelect={() => setSelectedGroupId(group.id)}
                    isSelected={group.id === selectedGroupId}
                    hasChildren={group.itemBoilerplates.length > 0}
                    isDefaultClosed
                  >
                    <List>
                      {group.itemBoilerplates.map((item) => {
                        const setGroupAndFocus = (andFocus: boolean) =>
                          safeFocusOnClick(andFocus, andFocus, () => {
                            if (selectedGroupId !== group.id) {
                              setSelectedGroupId(group.id);
                            }
                          });
                        return (
                          <Item
                            selected={
                              // default is a 0, meaning if the value is truthy (not 0|null|"") it will be highlighted since it will be added
                              !!values[item.id]
                            }
                            key={item.id}
                            item={item}
                            onClick={setGroupAndFocus(true)}
                            fieldProps={{
                              name: item.id,
                              // don't focus again here or it'll blow up the call stack
                              onFocus: setGroupAndFocus(false),
                            }}
                          />
                        );
                      })}
                    </List>
                  </Container>
                );
              })}
            </Box>
            <HoveringFab>
              <AddIcon />
            </HoveringFab>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ItemBoilerplateGroupsList;
