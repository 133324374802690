import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import PrivateRoute from './components/PrivateRoute';
import { Login, Pages } from './pages';
import { Mobile } from './mobile';

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <PrivateRoute path="/mobile" component={Mobile} />
        <PrivateRoute path="/" component={Pages} />
      </Switch>
    </Router>
  );
};

export default App;
