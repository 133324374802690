import * as React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Select, { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import { Theme, FormControl, FormHelperText, InputLabel, Chip } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import { FieldProps, getIn } from 'formik';
import { Omit } from './types';

/** Inspired by formik-material-ui */

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
  dirty: {
    color: green[700],
  },
  error: {
    color: theme.palette.error.main,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1 / 4),
  },
}));

interface ISelectProps extends FieldProps, Omit<MuiSelectProps, 'value'> {
  FormControlProps?: {
    className?: string;
  };
}

export type ISelectWithLabelProps = ISelectProps & {
  label: string;
};

const isDirty = ({ field, form: { initialValues } }: ISelectProps): boolean =>
  field.value !== getIn(initialValues, field.name);

const fieldToSelect = ({
  field,
  form: { isSubmitting },
  disabled = false,
  FormControlProps,
  ...props
}: ISelectProps): MuiSelectProps => {
  return {
    disabled: isSubmitting || disabled,
    ...props,
    ...field,
    onBlur: (e) => {
      props.onBlur?.(e);
      field.onBlur(e);
    },
  };
};

const FormikSelect: React.ComponentType<ISelectWithLabelProps> = ({ label, ...props }) => {
  const handleMultiInputChange = (event: any) => {
    props.form.setFieldValue(props.field.name, event.target.value);
  };

  const { errors, touched } = props.form;
  const { name } = props.field;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;
  const classes = useStyles();

  return (
    <FormControl className={`${classes.formControl} ${props.FormControlProps?.className ?? ''}`}>
      <InputLabel
        shrink
        htmlFor={name}
        classes={{ root: classNames({ [classes.dirty]: isDirty(props) }) }}
      >
        {label}
      </InputLabel>
      {props.multiple ? (
        <Select
          {...fieldToSelect(props)}
          value={props.field.value}
          onChange={handleMultiInputChange}
          onBlur={props.onBlur}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected
                ? (selected as any[]).map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))
                : []}
            </div>
          )}
        />
      ) : (
        <Select {...fieldToSelect(props)} onBlur={props.onBlur} />
      )}
      {showError && <FormHelperText className={classes.error}>{fieldError}</FormHelperText>}
    </FormControl>
  );
};

export default FormikSelect;

Select.displayName = 'FormikSelect';
