import { getToken } from './getToken';

export const fetchMAD = (url: string, init?: RequestInit) => {
  const { headers, ...rest } = init || {};
  return fetch(`/napi${url}`, {
    headers: {
      momoAuth: getToken(),
      ...headers,
    },
    ...rest,
  }).then((res) => res.json());
};
