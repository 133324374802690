import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { lighten } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    blocked: {
      backgroundColor: lighten(green[700], 0.7),
    },
    unBlocked: {
      backgroundColor: lighten(theme.palette.error.light, 0.7),
    },
  };
});

export default function useBlockedOfferClassName() {
  const classes = useStyles();

  const getBlockedClassName = (isOfferBlocked: boolean) => {
    if (isOfferBlocked) {
      return classes.blocked;
    }

    return '';
  };

  return getBlockedClassName;
}
