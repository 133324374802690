import React, { useMemo } from 'react';
import { FastField as Field } from 'formik';
import { chunk } from 'lodash';
import FormikCheckboxWithLabel from '../Form/FormikCheckboxWithLabel';
import FormikSelect from '../Form/FormikSelect';
import FormikTextField from '../Form/FormikTextField';
import Dropzone from '../Form/UploadField/Dropzone';
import MaterialColorSelect from '../MaterialColorSelect';
import { RootRef, MenuItem, Grid, Theme } from '@material-ui/core';
import { useFocusField } from '../../hooks/useFocusField';
import { ItemType } from '../BillOfQuantity/BllOfQuantityTable';
import { makeStyles } from '@material-ui/styles';
import { GpsFields } from '../GpsFields';

const useStyles = makeStyles((theme: Theme) => ({
  checkBox: {
    marginTop: theme.spacing(2),
  },
  dropzoneContainer: {
    marginTop: theme.spacing(2),
  },
}));

const LG_BREAKPOINT = 3;
const MD_BREAKPOINT = 6;

const useItemFormColumns = (initialValues: any) => {
  const classes = useStyles();

  const firstField = useFocusField();

  const isCreateForm = !initialValues;

  return useMemo(() => {
    const columns = [
      {
        componentToRender: () => (
          <RootRef rootRef={firstField}>
            <Field name="productNumber" label="Produktnummer *" component={FormikTextField} />
          </RootRef>
        ),
      },
      {
        name: 'acronym',
        label: 'Kurzbeschreibung',
        component: FormikTextField,
        hideOnCreate: true,
      },
      {
        name: 'descriptionOne',
        label: 'Beschreibung 1 *',
        component: FormikTextField,
      },
      {
        name: 'descriptionTwo',
        label: 'Beschreibung 2',
        component: FormikTextField,
      },
      {
        name: 'freeText',
        label: 'Freitext',
        component: FormikTextField,
      },
      {
        name: 'unit',
        label: 'Einheit *',

        component: FormikTextField,
      },
      {
        name: 'volume',
        label: 'Menge',
        type: 'number',
        component: FormikTextField,
      },
      {
        name: 'appliedPrice',
        label: 'Preis',
        type: 'number',
        component: FormikTextField,
      },
      {
        name: 'applyScaleDiscount',
        Label: { label: 'Staffelrabatt' },
        propClasses: { root: classes.checkBox },
        hideOnCreate: true,
        component: FormikCheckboxWithLabel,
      },
      {
        name: 'catalog',
        label: 'Katalog',
        component: FormikTextField,
        disabled: true,
      },
      {
        name: 'catalogSection',
        label: 'Hierarchienr 1',
        component: FormikTextField,
      },
      {
        name: 'catalogSubsection',
        label: 'Hierarchienr 2',
        component: FormikTextField,
      },
      {
        name: 'catalogMainGroup',
        label: 'Hierarchienr 3',
        component: FormikTextField,
      },
      {
        name: 'catalogSectionDescription',
        label: 'Hierarchie 1',
        hideOnCreate: true,
        component: FormikTextField,
      },
      {
        name: 'catalogSubsectionDescription',
        label: 'Hierarchie 2',
        hideOnCreate: true,
        component: FormikTextField,
      },
      {
        name: 'catalogMainGroupDescription',
        label: 'Hierarchie 3',
        hideOnCreate: true,
        component: FormikTextField,
      },
      {
        // custom fc component
        componentToRender: () => (
          <Field component={FormikSelect} name="type" label="Positionsart">
            <MenuItem value="" key={2}>
              <em />
            </MenuItem>
            {Object.entries(ItemType).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Field>
        ),
      },
      {
        name: 'category',
        label: 'Kategorie',
        component: FormikTextField,
      },
      {
        name: 'markingStyle',
        label: 'Typ',
        component: FormikTextField,
      },
      {
        componentToRender: MaterialColorSelect,
        name: 'materialColor',
        label: 'Materialcode',
      },
      {
        name: 'material',
        label: 'Verbrauchsmaterial',
        hideOnCreate: true,
        component: FormikTextField,
      },
      {
        name: 'targetConsumptionPerUnit',
        label: 'Sollverbrauch EinheitMat/Einheitpos',
        type: 'number',
        hideOnCreate: true,
        component: FormikTextField,
      },
      {
        name: 'timeRequired',
        label: 'Zeitbedarf min/einheit',
        type: 'number',
        hideOnCreate: true,
        component: FormikTextField,
      },
      {
        name: 'color',
        label: 'Farbe',
        hideOnCreate: true,
        component: FormikTextField,
      },
      {
        name: 'dimensionOne',
        label: 'Dimension 1',
        hideOnCreate: true,
        component: FormikTextField,
      },
      {
        name: 'dimensionTwo',
        label: 'Dimension 2',
        hideOnCreate: true,
        component: FormikTextField,
      },
      {
        name: 'reflexion',
        label: 'Reflektion',
        hideOnCreate: true,
        component: FormikTextField,
      },
      {
        component: GpsFields,
        hideOnCreate: true,
      },
      {
        name: 'comment',
        label: 'Kommentar Position',
        component: FormikTextField,
      },
      {
        name: 'customerComment',
        label: 'Bemerkungen Kunde',
        component: FormikTextField,
      },
      {
        name: 'isFinished',
        Label: { label: 'Erledigt' },
        hideOnCreate: true,
        propClasses: { root: classes.checkBox },
        component: FormikCheckboxWithLabel,
      },
      {
        componentToRender: () => (
          <Grid item md={MD_BREAKPOINT} lg={LG_BREAKPOINT} className={classes.dropzoneContainer}>
            <Field
              name="documents"
              initialFiles={initialValues && initialValues.documents}
              label="Dokumente"
              component={Dropzone}
              accept="image/png, image/jpeg"
            />
          </Grid>
        ),
      },
    ].filter((item) => (isCreateForm ? !item.hideOnCreate : true)); // dont include columns with hideOnCreate = true

    // when you need to change this you also need to change the md and lg breakpoints
    // so that the grids are positioned next to each other and not on top
    const gridAmount = isCreateForm ? 3 : 4;

    const itemsPerGrid = Math.ceil(columns.length / gridAmount);

    return chunk<any>(columns, itemsPerGrid) as Array<typeof columns>;
  }, [isCreateForm, classes.checkBox, classes.dropzoneContainer, firstField, initialValues]);
};

export const useItemFormGrids = (initialValues: any) => {
  const columns = useItemFormColumns(initialValues);

  return useMemo(
    () =>
      columns.map((grid, i) => (
        <Grid item md={MD_BREAKPOINT} lg={LG_BREAKPOINT} key={i}>
          {grid.map((column, j) => {
            const { componentToRender, hideOnCreate, ...props } = column;

            const FieldComponent = componentToRender ? componentToRender : Field;
            return <FieldComponent {...(props as any)} key={j} />;
          })}
        </Grid>
      )),
    [columns],
  );
};
