import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import PageWrapper from '../../components/PageWrapper';
import AppProgress from '../../components/Page/AppProgress';
import { GetOfferCondition, GetOfferConditionVariables } from './types/GetOfferCondition';
import {
  GET_OFFER_CONDITION_QUERY,
  GET_OFFER_CONDITIONS_QUERY,
  UPDATE_OFFER_CONDITION_MUTATION,
} from './OfferConditions.queries';
import { UpdateOfferCondition, UpdateOfferConditionVariables } from './types/UpdateOfferCondition';
import AppErrorMessage from '../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import OfferConditionForm from '../../components/OfferCondition/OfferConditionForm';

/**
 * Component that displays an update form for an offer condition.
 * @constructor
 */
export const UpdateOfferConditionPage: React.FC = () => {
  const { id } = useParams();
  const history = useHistory();

  const { data, error, loading } = useQuery<GetOfferCondition, GetOfferConditionVariables>(
    GET_OFFER_CONDITION_QUERY,
    {
      variables: {
        where: { id },
      },
    },
  );

  const [updateOfferCondition] = useMutation<UpdateOfferCondition, UpdateOfferConditionVariables>(
    UPDATE_OFFER_CONDITION_MUTATION,
    {
      refetchQueries: [
        { query: GET_OFFER_CONDITIONS_QUERY },
        {
          query: GET_OFFER_CONDITION_QUERY,
          variables: {
            where: { id },
          },
        },
      ],
    },
  );

  const goBack = useCallback(() => history.goBack(), [history]);

  // if the offer condition isn't found, e.g. following a stale link, go back
  useEffect(() => {
    if (!loading && data != null && !data.offerCondition) {
      goBack();
    }
  }, [loading, data, goBack]);

  if (!data) {
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <PageWrapper
        header={{
          title: `Angebotsbedingung editieren`,
          onClose: goBack,
        }}
      >
        <OfferConditionForm
          initialValues={data.offerCondition}
          onSubmit={async (data, setSubmitting) => {
            await updateOfferCondition({ variables: { data, where: { id } } });
            setSubmitting(false);
            goBack();
          }}
        />
      </PageWrapper>
    </>
  );
};
