import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import { defaults, Dictionary, isEmpty, isNil, mapValues, pick } from 'lodash';
import React, { FC } from 'react';
import { useProjectNumber } from '../../../hooks/useProjectNumber';
import { Noop } from '../../../types';
import { UpsertAddressBookItemType } from '../../../types/graphql';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import { getChangedFormValues } from '../../../utils/form/getChanged';
import FormikTextField from '../../Form/FormikTextField';
import AppErrorMessage from '../../Page/AppErrorMessage';
import AppProgress from '../../Page/AppProgress';
import { INVALID_SALUTATION_CODE } from '../constants';
import { FullAddress } from '../types/FullAddress';
import { useAddressEditFetch } from './fetch';

interface IProps {
  address: Maybe<FullAddress>;
  disabled?: boolean;
  addressBookItemId: Maybe<number>;
  addressBookItemType: UpsertAddressBookItemType;
}

const DEFAULT_VALUES = {
  name: '',
  firstName: '',
  department: '',
  streetAndNumber: '',
  postCode: '',
  postbox: '',
  city: '',
  phone: '',
  email: '',
  additionalOne: '',
  additionalTwo: '',
  customerNumber: '',
  secondResponsible: '',
  comment: '',
  customerConditions: '',
};
const FORM_FIELD_NAMES = Object.keys(DEFAULT_VALUES);

const createHandleOnKeyUp = (submitForm: Noop) => (e: KeyboardEvent) => {
  const isEnter = e.key === 'Enter';
  if (isEnter) {
    submitForm();
  }
};

export const AddressEditForm: FC<IProps> = ({
  address,
  disabled,
  addressBookItemId,
  addressBookItemType,
}) => {
  const parsedAddressId = address ? parseInt(address.id, 10) : undefined;
  const { executeUpsertAddress, loading, error } = useAddressEditFetch(parsedAddressId);
  const projectNumber = useProjectNumber();
  if (!projectNumber) {
    throw new Error(`No projectNumber found`);
  }

  const initialValues = mapValues(
    defaults(pick(address, FORM_FIELD_NAMES), DEFAULT_VALUES),
    (value) => (value ? String(value) : ''), // map null to empty string
  );
  const initialValuesMapped: Dictionary<string> = {
    ...initialValues,
    customerConditions: isEmpty(initialValues.customerConditions)
      ? ' '
      : initialValues.customerConditions,
  };

  return (
    <>
      {loading && <AppProgress />}
      <AppErrorMessage isOpen={!!error} message={errorPrefixRemover(error?.message ?? '')} />
      <Formik
        enableReinitialize
        initialValues={initialValuesMapped}
        onSubmit={async (values) => {
          const changedValues = getChangedFormValues({ initialValues, values });
          if (isEmpty(changedValues)) {
            return;
          }
          if (!isNil(changedValues.salutationCode)) {
            changedValues.salutationCode = parseInt(values.salutationCode, 10);

            if (changedValues.salutationCode === INVALID_SALUTATION_CODE) {
              changedValues.salutationCode = null;
            }
          }

          await executeUpsertAddress({
            variables: {
              where: {
                id: parsedAddressId,
                addressBook: { projectNumber },
                addressBookItem: addressBookItemId ? { id: addressBookItemId } : undefined,
                addressBookItemType: addressBookItemType,
              },
              data: changedValues,
            },
          });
        }}
      >
        {({ submitForm }) => (
          <Form>
            <Grid container>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={5}>
                  <Field
                    name="customerNumber"
                    label="Kundennummer"
                    component={FormikTextField}
                    disabled
                  />
                  <Field
                    name="additionalOne"
                    label="Zusatz 1"
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                  <Grid item xs={12} spacing={1}>
                    {!isEmpty(address?.firstName) && (
                      <Grid item xs={5}>
                        <Field
                          name="firstName"
                          label="Vorname"
                          component={FormikTextField}
                          disabled={disabled}
                          onBlur={submitForm}
                          onKeyUp={createHandleOnKeyUp(submitForm)}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Field
                        name="name"
                        label="Bezeichnung"
                        component={FormikTextField}
                        disabled={disabled}
                        onBlur={submitForm}
                        onKeyUp={createHandleOnKeyUp(submitForm)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name="comment"
                    label="Adressen Notiz"
                    multiline
                    rows={4}
                    variant="outlined"
                    component={FormikTextField}
                    disabled
                    onBlur={submitForm}
                  />
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Field
                      name="customerConditions"
                      label="Konditionsgruppe"
                      component={FormikTextField}
                      disabled
                      style={{ width: '15%', minWidth: 40 }}
                    />
                    <span style={{ display: 'inline-block', marginBottom: 5 }}>% Rabatt</span>
                  </div>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={5}>
                    <Field
                      name="department"
                      label="Abteilung"
                      component={FormikTextField}
                      disabled={disabled}
                      onBlur={submitForm}
                      onKeyUp={createHandleOnKeyUp(submitForm)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={5}>
                  <Field
                    name="secondResponsible"
                    label="Zuständigkeit"
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={5}>
                  <Field
                    name="streetAndNumber"
                    label="Adresse"
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={5}>
                  <Field
                    name="postbox"
                    label="Postfach"
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={5}>
                  <Field
                    name="postCode"
                    label="PLZ"
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name="city"
                    label="Ort"
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={5}>
                  <Field
                    name="phone"
                    label="Tel.Nr"
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name="email"
                    label="Email"
                    component={FormikTextField}
                    disabled={disabled}
                    onBlur={submitForm}
                    onKeyUp={createHandleOnKeyUp(submitForm)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
