import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    justifyItems: 'flex-end',
    alignItems: 'flex-end',
  },
  item: {
    margin: theme.spacing(1),
  },
}));

interface IProps {
  isDisabled: boolean;
  customSaveButtonLabel?: string;
  customResetButtonLabel?: string;
}

const CancelSaveButtons: React.FunctionComponent<IProps> = ({
  isDisabled,
  customSaveButtonLabel,
  customResetButtonLabel,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button type="reset" variant="contained" disabled={isDisabled} className={classes.item}>
        {customResetButtonLabel ? customResetButtonLabel : 'Zurücksetzen'}
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        disabled={isDisabled}
        className={classes.item}
      >
        {customSaveButtonLabel ? customSaveButtonLabel : 'Speichern'}
      </Button>
    </div>
  );
};

export default CancelSaveButtons;
