const GOOGLE_MAPS_DIRECTIONS_BASE_LINK = 'https://www.google.com/maps/dir/?api=1';
const GOOGLE_MAPS_SIMPLE_BASE_LINK = 'http://maps.google.com/maps';

/**
 * either a start/end pair or a single point
 */
type CoordinatesInput =
  | {
      startCoordinate: string;
      endCoordinate: string;
    }
  | {
      nodeCoordinate: string;
    };

/**
 * convert lat/lon variants to a google maps link that navigates from the current position
 *
 * @param input either a single point or start and endpoint
 * @param navigateToStart (optional) include a navigation step to the starting point
 * @returns a google maps link to a navigation based on the points
 */
export const getMapsUrl = (input: CoordinatesInput, navigateToStart: boolean = false): URL => {
  let url: URL;
  if ('nodeCoordinate' in input) {
    url = new URL(
      navigateToStart ? GOOGLE_MAPS_DIRECTIONS_BASE_LINK : GOOGLE_MAPS_SIMPLE_BASE_LINK,
    );
    url.searchParams.append(navigateToStart ? 'destination' : 'q', input.nodeCoordinate);
  } else {
    url = new URL(GOOGLE_MAPS_DIRECTIONS_BASE_LINK);
    url.searchParams.append(navigateToStart ? 'waypoints' : 'origin', input.startCoordinate);
    url.searchParams.append('destination', input.endCoordinate);
  }
  return url;
};
