import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Delete as DeleteIcon, PlaylistAdd as PlaylistAddIcon } from '@material-ui/icons';
import { Formik, FormikHelpers } from 'formik';
import { isNil, omit } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { createSwissCurrencyFormatter } from '../../../utils/createCurrencyFormatter';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import { flipSign } from '../../../utils/flipSign';
import { getChangedFormValues } from '../../../utils/form/getChanged';
import { setFalseyValuesToNull } from '../../../utils/form/setFalseyToNull';
import { xor } from '../../../utils/xor';
import { RelationEntityType } from '../../AddressTabs/types';
import { DetailClose } from '../../BillOfQuantity/BillOfQuantityDetailsForm';
import { DataTableBody, DataTableHotKeysWrapper } from '../../DataTable';
import { IDataTableColumn, IDataTableRow } from '../../DataTable/types';
import CancelSaveIconButtons from '../../Form/CancelSaveIconButtons';
import InlineEditingFormikForm from '../../Form/InlineEditingFormikForm';
import AppErrorMessage from '../../Page/AppErrorMessage';
import AppProgress from '../../Page/AppProgress';
import PageWrapper from '../../PageWrapper';
import { DeadlineFields } from './DeadlineFields/DeadlineFields';
import { INVOICE_CONDITION_UTILS } from './InvoiceConditionsTable.utils';

interface IProps {
  isNotInteractive?: boolean;
  isBillOfQuantity?: boolean;
  entityType: RelationEntityType;
  onClose: () => void;
}

export interface IInvoiceConditionRowToEdit {
  id: string;
  values: any;
}

const {
  appendNewRow,
  mapDataToInnerTableRows,
  useFetches,
  createFindNextRow,
  createRenderField,
  NEW_ROW_ID,
} = INVOICE_CONDITION_UTILS;

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });
const formatCurrencyForEdit = createSwissCurrencyFormatter();

const INITIAL_VALUES = {};
const isFieldEmpty = (v: any) => isNil(v) || v === '';

export const InvoiceConditionTable: React.FC<IProps> = ({
  isNotInteractive,
  onClose,
  entityType,
}) => {
  const [xorSnackbarIsOpen, setXorSnackbarIsOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState<IInvoiceConditionRowToEdit | null>(null);

  const {
    props: { data, variables, loading, error },
    actions: { createInvoiceCondition, updateInvoiceCondition, deleteInvoiceCondition },
  } = useFetches();

  const innerTableRows = useMemo<IDataTableRow[]>(
    () =>
      data
        ? [
            ...mapDataToInnerTableRows(data),
            ...(rowToEdit && rowToEdit.id === NEW_ROW_ID ? [appendNewRow()] : []),
          ]
        : [],
    [data, rowToEdit],
  );

  const columns = useMemo<IDataTableColumn[]>(() => {
    const renderField = createRenderField(rowToEdit);

    return [
      {
        id: 'sequenceNumber',
        label: 'Nummer',
        width: 50,
        render: renderField('sequenceNumber'),
      },
      {
        id: 'name',
        label: 'Bezeichnung',
        width: 120,
        render: renderField('name'),
      },
      {
        id: 'percentageAmount',
        label: 'Relativ-Betrag',
        width: 60,
        render: renderField('percentageAmount', 'PERCENTAGE'),
      },
      {
        id: 'absoluteAmount',
        label: 'Absolut-Betrag',
        width: 100,
        render: renderField('absoluteAmount', 'NUMBER'),
      },
      {
        id: 'reference',
        label: 'Bezug',
        width: 60,
        render: renderField('reference'),
      },
      {
        id: 'referenceSum',
        label: 'Bezugsbetrag',
        width: 100,
        render: (cell: any) => (!isNil(cell) ? formatCurrency(cell) : null),
      },
      {
        id: 'discount',
        label: 'Betrag',
        width: 100,
        render: (cell: any) =>
          !isNil(cell) ? formatCurrency(cell === 0 ? 0 : flipSign(cell)) : null,
      },
      {
        id: 'sum',
        label: 'Total',
        width: 100,
        render: (cell: any) => (!isNil(cell) ? formatCurrency(cell) : null),
      },
    ];
  }, [rowToEdit]);

  const initialValues = useMemo(
    () =>
      rowToEdit
        ? {
            ...omit(rowToEdit.values, 'sum', 'discount'),
            absoluteAmount:
              rowToEdit.values.absoluteAmount &&
              formatCurrencyForEdit(rowToEdit.values.absoluteAmount),
          }
        : INITIAL_VALUES,
    [rowToEdit],
  );

  const onDoubleRowClick = useCallback(
    (row: IDataTableRow) =>
      !isNotInteractive && row.data.editable && setRowToEdit({ id: row.id, values: row.data }),
    [setRowToEdit, isNotInteractive],
  );
  const findNextRow = useMemo(() => createFindNextRow(innerTableRows), [innerTableRows]);

  const setNextRowAsEdit = useCallback(() => {
    if (!rowToEdit) {
      return;
    }

    const nextRow = findNextRow(rowToEdit.id);

    if (!nextRow) {
      return setRowToEdit(null);
    }

    setRowToEdit({ id: nextRow.id, values: nextRow.data });
  }, [rowToEdit, setRowToEdit, findNextRow]);

  const mapDataForMutation = useCallback(
    (
      { percentageAmount, absoluteAmount, sequenceNumber, reference, ...row }: any,
      {
        isUnsetAbsoluteAmount,
        isUnsetPercentageAmount,
      }: {
        isUnsetAbsoluteAmount: boolean;
        isUnsetPercentageAmount: boolean;
      },
    ): any => {
      if (isUnsetAbsoluteAmount) {
        // unset absoluteAmount if percentageAmount is defined (xorHasFailed === true)
        absoluteAmount = percentageAmount ? null : 0;
      }

      if (isUnsetPercentageAmount) {
        percentageAmount = absoluteAmount ? null : 0;
      }

      return getChangedFormValues({
        values: setFalseyValuesToNull(
          {
            ...row,
            absoluteAmount: isNil(absoluteAmount) ? null : parseFloat(absoluteAmount),
            percentageAmount: isNil(isUnsetPercentageAmount) ? null : parseFloat(percentageAmount),
            sequenceNumber: sequenceNumber && parseInt(sequenceNumber, 10),
            reference: reference && parseInt(reference, 10),
          },
          ['percentageAmount', 'absoluteAmount'],
        ),
        initialValues,
        ignoreKeys: ['percentageAmount', 'absoluteAmount'],
      });
    },
    [initialValues],
  );

  const onSubmit = useCallback(
    async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      if (!rowToEdit) {
        return;
      }

      const isCreate = rowToEdit.id === NEW_ROW_ID;

      const absoluteAmountIsEmpty = isFieldEmpty(values.absoluteAmount);
      const percentageAmountIsEmpty = isFieldEmpty(values.percentageAmount);

      const xorHasFailed = !xor(absoluteAmountIsEmpty, percentageAmountIsEmpty);

      const isUnsetAbsoluteAmount = !isNil(initialValues.absoluteAmount) && absoluteAmountIsEmpty;
      const isUnsetPercentageAmount =
        !isNil(initialValues.percentageAmount) && percentageAmountIsEmpty;

      if (xorHasFailed && !(isUnsetAbsoluteAmount || isUnsetPercentageAmount)) {
        return setXorSnackbarIsOpen(true);
      }

      const data = mapDataForMutation(values, {
        isUnsetPercentageAmount,
        isUnsetAbsoluteAmount,
      });

      if (isCreate) {
        await createInvoiceCondition({
          variables: {
            where: variables.where,
            data,
          },
        });
      } else {
        await updateInvoiceCondition({ variables: { where: { id: rowToEdit.id }, data } });
      }

      setSubmitting(false);

      setNextRowAsEdit();
    },
    [
      rowToEdit,
      createInvoiceCondition,
      updateInvoiceCondition,
      variables,
      setNextRowAsEdit,
      mapDataForMutation,
      setXorSnackbarIsOpen,
      initialValues,
    ],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        sequenceNumber: Yup.number()
          .required('Pflichtfeld!')
          .integer('Muss eine ganze Zahl sein!')
          .positive('Muss positiv sein!'),
        absoluteAmount: Yup.number().nullable(),
        percentageAmount: Yup.number()
          .nullable()
          .max(100, 'Der Relativ-Betrag darf nicht grösser als 100 sein')
          .moreThan(-100, 'Der Relativ-Betrag darf nicht kleiner als -100 sein'),
        reference: Yup.number()
          .oneOf(
            innerTableRows.map((row) => row.data.sequenceNumber),
            'Bezugs-Kondition existiert nicht!',
          )
          .required('Pflichtfeld!'),
      }),
    [innerTableRows],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      {xorSnackbarIsOpen && (
        <AppErrorMessage
          isOpen={xorSnackbarIsOpen}
          onClose={() => setXorSnackbarIsOpen(false)}
          message="Es muss entweder der Relativ- ODER Absolutbetrag ausgefüllt sein!"
          noAutoHide
        />
      )}
      <PageWrapper>
        <Grid container item xs={12} justify="flex-end">
          <DetailClose onClose={onClose} />
        </Grid>
        {[RelationEntityType.ORDER, RelationEntityType.OFFER].includes(entityType) && (
          <DeadlineFields />
        )}
        {loading && <AppProgress />}
        {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ isSubmitting, submitForm }) => (
            <InlineEditingFormikForm
              dirty={true}
              clickAwaySubmitHandler={async () => {
                await submitForm();

                setRowToEdit(null);
              }}
              tabSubmitHandler={(e) => {
                if (e.target.name !== 'save-button') {
                  return;
                }

                submitForm();
              }}
            >
              <DataTableHotKeysWrapper
                innerTableRows={innerTableRows}
                options={{
                  fixedWidthColumns: true,
                  activeRowId: '',
                  levels: [
                    {
                      columns,
                      onDoubleRowClick,
                      actions: () => (
                        <Tooltip title="Erstellen">
                          <span>
                            <IconButton
                              disabled={!!rowToEdit || isNotInteractive}
                              onClick={() =>
                                setRowToEdit({
                                  id: NEW_ROW_ID,
                                  values: {},
                                })
                              }
                            >
                              <PlaylistAddIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ),
                      rowActions: ({ row }) => (
                        <>
                          {row.id === rowToEdit?.id && (
                            <CancelSaveIconButtons
                              onCancel={() => setRowToEdit(null)}
                              isDisabled={isSubmitting}
                            />
                          )}

                          {row.id !== rowToEdit?.id && (
                            <Tooltip title="Löschen">
                              <span>
                                <IconButton
                                  disabled={loading || isNotInteractive || !row.data.editable}
                                  onClick={() =>
                                    deleteInvoiceCondition({ variables: { id: row.id } })
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        </>
                      ),
                    },
                  ],
                }}
              >
                {(context) => <DataTableBody context={context} />}
              </DataTableHotKeysWrapper>
            </InlineEditingFormikForm>
          )}
        </Formik>
      </PageWrapper>
    </>
  );
};
