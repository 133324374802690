import React, { useMemo, useState, useCallback } from 'react';
import { useQuery } from 'react-apollo';
import { omit } from 'lodash';
import { GET_MATERIALS } from './Materials.queries';
import { Materials as TMaterials, MaterialsVariables } from './types/Materials';
import AppProgress from '../../components/Page/AppProgress';
import DataTable from '../../components/DataTable';
import { IDataTableRow, IDataTableColumn } from '../../components/DataTable/types';
import { useSearchState, Search } from '../../components/Search/Search';
import { createSwissCurrencyFormatter } from '../../utils/createCurrencyFormatter';
import AddButton from '../../components/DataTable/DataTableToolbar/AddButton';
import { hasAccessRights } from '../../utils';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import { Role } from '../../enums';
import { MaterialCatalogCreateDetails } from '../../components/MaterialCatalogCreate';
import { MATERIALS_TRANSLATION_ENUM } from './Materials.translation';
import { usePaginationState, IPaginationState } from '../../components/Pagination/Pagination.utils';
import { Pagination } from '../../components/Pagination';
import { useCount } from '../../hooks/useCount';
import { CountEntity } from '../../hooks/useCount/useCount.queries';
import { DownloadCsvAction } from '../../components/Actions/Download/Csv';
import { formatDate } from '../../utils/format/date';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const mapToTableData = (data: TMaterials | undefined): IDataTableRow[] =>
  data?.activeMaterialCatalog?.materials.map((material) => ({
    id: material.id,
    data: {
      ...material,
      pricePerUnit: formatCurrency(material.pricePerUnit),
      unit: material.unit.acronym.toLowerCase(),
    },
  })) ?? [];

const columns: IDataTableColumn[] = [
  {
    id: 'materialNumber',
    label: MATERIALS_TRANSLATION_ENUM.materialNumber,
  },
  {
    id: 'acronym',
    label: MATERIALS_TRANSLATION_ENUM.acronym,
  },
  {
    id: 'nameOne',
    label: MATERIALS_TRANSLATION_ENUM.nameOne,
  },
  {
    id: 'nameTwo',
    label: MATERIALS_TRANSLATION_ENUM.nameTwo,
  },
  {
    id: 'unit',
    label: MATERIALS_TRANSLATION_ENUM.unit,
  },
  {
    id: 'pricePerUnit',
    label: MATERIALS_TRANSLATION_ENUM.pricePerUnit,
  },
  {
    id: 'materialType',
    label: MATERIALS_TRANSLATION_ENUM.materialType,
  },
  {
    id: 'color',
    label: MATERIALS_TRANSLATION_ENUM.color,
  },
  {
    id: 'provider',
    label: MATERIALS_TRANSLATION_ENUM.provider,
  },
];

const columnIds = columns.map(({ id }) => id);

export const Materials: React.FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data, loading, error, refetch, variables } = useQuery<TMaterials, MaterialsVariables>(
    GET_MATERIALS,
    {
      variables: {
        page: {
          amountPerPage: 25,
          pageNumber: 0,
        },
      },
    },
  );

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        page: {
          pageNumber: state.page,
          amountPerPage: state.rowsPerPage,
        },
      });
    },
    [refetch],
  );

  const count = useCount({
    entity: CountEntity.MATERIAL,
    variables: omit(variables, 'page'),
  });

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  const innerTableRows = useMemo(() => mapToTableData(data), [data]);

  const searchState = useSearchState();

  const loggedInUser = useLoggedInUser();

  if (error) {
    console.log(error);
  }

  if (!data || !loggedInUser) {
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <DataTable
        options={{
          filterText: searchState.searchTerm,
          levels: [
            {
              columns,
              actions: () => (
                <>
                  <DownloadCsvAction
                    entityType="MATERIAL"
                    variables={{ search: variables.search }}
                    csvName={`Materialien_${formatDate()}`}
                  />
                  {loggedInUser.role && hasAccessRights(loggedInUser.role, Role.ADMIN) && (
                    <AddButton
                      tooltip="Neuen Material-Katalog importieren"
                      onClick={() => setIsDialogOpen(true)}
                    />
                  )}
                </>
              ),
            },
          ],
        }}
        innerTableRows={innerTableRows}
        pagination={<Pagination paginationState={paginationState} />}
        search={
          <Search
            columns={columnIds}
            searchState={searchState}
            onSubmit={({ columns, terms }) => refetch({ search: { columns, terms } })}
            loading={loading}
          />
        }
      />
      <MaterialCatalogCreateDetails
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          count?.refetch();
          refetch();
        }}
      />
    </>
  );
};
