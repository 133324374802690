import { Grid, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isNil } from 'lodash';
import React, { FC, useMemo } from 'react';
import { useProjectNumber } from '../../hooks/useProjectNumber';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import AppErrorMessage from '../Page/AppErrorMessage';
import AppProgress from '../Page/AppProgress';
import { Address } from './Address';
import { useAddressBookQuery } from './fetch';
import { AddressHeaderTitle } from './header';
import { ProjectAddress } from './projectAddress';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(0, 3),
  },
  paper: {
    padding: theme.spacing(3, 0, 3, 0),
  },
}));

export const Addresses: FC = () => {
  const projectNumber = useProjectNumber()!;

  const classes = useStyles();
  const { data, loading, error } = useAddressBookQuery(projectNumber);

  const isShippingDifferent = useMemo(() => {
    if (isNil(data) || isNil(data.addressBook)) {
      return null;
    }

    if (isNil(data.addressBook.billingAddress) || isNil(data.addressBook.shippingAddress)) {
      return false;
    }

    return data.addressBook.billingAddress.id !== data.addressBook.shippingAddress.id;
  }, [data]);

  return (
    <>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <Paper classes={{ root: classes.paper }}>
        <Grid container spacing={1} classes={{ root: classes.container }}>
          <Grid item xs={5}>
            <Address
              header={AddressHeaderTitle.BILLING_ADDRESS}
              addressBook={data?.addressBook}
              addressBookItem={data?.addressBook?.billingAddress}
            />
          </Grid>
          <Grid item xs={5}>
            <Address
              header={AddressHeaderTitle.SHIPPING_ADDRESS}
              addressBook={data?.addressBook}
              addressBookItem={
                isShippingDifferent
                  ? data?.addressBook?.shippingAddress
                  : data?.addressBook?.billingAddress
              }
              isShippingDifferent={isShippingDifferent}
            />
          </Grid>
          <Grid item xs={2}>
            <ProjectAddress projectAddress={data?.addressBook?.projectAddress ?? ''} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
