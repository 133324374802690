import ApolloClient from 'apollo-client';
import { isEmpty } from 'lodash';
import { IS_DATA_TABLE_POPULATED_QUERY } from '../../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/getPopulatedTableTypes';
import {
  IsDataTablePopulated,
  IsDataTablePopulatedVariables,
} from '../../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/types/IsDataTablePopulated';
import { BillOfQuantityEntityType, TableType } from '../../../../../types/graphql';
import { BoqTableType } from './executeForBoqTypes';

export const getPopulatedBoqTableTypes = (
  client: ApolloClient<any>,
  entityType: BillOfQuantityEntityType,
): BoqTableType[] => {
  const isOfferPopulated = !isEmpty(
    client.cache.readQuery<IsDataTablePopulated, IsDataTablePopulatedVariables>({
      query: IS_DATA_TABLE_POPULATED_QUERY,
      variables: { tableType: TableType.OFFER },
    })?.dataTable?.rows,
  );
  const isOrderPopulated = !isEmpty(
    client.cache.readQuery<IsDataTablePopulated, IsDataTablePopulatedVariables>({
      query: IS_DATA_TABLE_POPULATED_QUERY,
      variables: { tableType: TableType.ORDER },
    })?.dataTable?.rows,
  );
  const isBoqMissionPopulated = !isEmpty(
    client.cache.readQuery<any>({
      query: IS_DATA_TABLE_POPULATED_QUERY,
      variables: { tableType: TableType.ORDER_MISSION },
    })?.dataTable?.rows,
  );
  const isBoqMeasurementPopulated = !isEmpty(
    client.cache.readQuery<any>({
      query: IS_DATA_TABLE_POPULATED_QUERY,
      variables: { tableType: TableType.ORDER_MEASUREMENT },
    })?.dataTable?.rows,
  );

  const populatedTableTypes: BoqTableType[] = [];

  if (entityType === BillOfQuantityEntityType.OFFER) {
    if (isOfferPopulated) {
      populatedTableTypes.push(TableType.OFFER);
    }
  } else {
    if (isOrderPopulated) {
      populatedTableTypes.push(TableType.ORDER);
    }
    if (isBoqMissionPopulated) {
      populatedTableTypes.push(TableType.ORDER_MISSION);
    }
    if (isBoqMeasurementPopulated) {
      populatedTableTypes.push(TableType.ORDER_MEASUREMENT);
    }
  }

  return populatedTableTypes;
};
