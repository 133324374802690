import ApolloClient, { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import { useProjectNumber } from '../../../../hooks/useProjectNumber';
import {
  GET_BILL_ID_OF_BILL_ITEM,
  GET_SIMPLE_BILL,
} from '../../../../pages/Projects/TabBills/BillSelector/bill.queries';
import { GetBillIdOfBillItem } from '../../../../pages/Projects/TabBills/BillSelector/types/GetBillIdOfBillItem';
import { GetSimpleBill } from '../../../../pages/Projects/TabBills/BillSelector/types/GetSimpleBill';
import {
  buildBillLocationId,
  marshalBillData,
} from '../../../../pages/Projects/TabBills/BillSelector/utils/pagination.helpers';
import { buildMissionLocationId } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers';
import { executeForPopulatedMissionTableTypes } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/executeForMissionTypes';
import { buildTableId } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { getPageInfo } from '../../../../services/getPageInfo';
import { REMOVE_DATA_TABLE_ROW, UPDATE_DATA_TABLE_ROW } from '../../../../services/graphql-client';
import {
  RemoveDataTableRow,
  RemoveDataTableRowVariables,
} from '../../../../services/types/RemoveDataTableRow';
import { UpdateDataTableRow } from '../../../../services/types/UpdateDataTableRow';
import { TableType } from '../../../../types/graphql';
import { PAGINATION_HELPERS } from '../../../../utils/paginationHelpers';
import { IDataTableRow } from '../../../DataTable/types';
import { DELETE_BILL_ITEM_MUTATION } from './DeleteBillItemAction.queries';
import { DeleteBillItem, DeleteBillItemVariables } from './types/DeleteBillItem';
import {
  GetInnerTableRowIdsOfContainerRow,
  GetInnerTableRowIdsOfContainerRowVariables,
} from './types/GetInnerTableRowIdsOfContainerRow';

/**
 * returns a function that deletes the billItem and refetches the mission and bill tables
 */
export const useDeleteBillItem = (billItemRow: IDataTableRow) => {
  const client = useApolloClient();
  const projectNumber = useProjectNumber();

  const billItemId = billItemRow.data.id;

  const [executeDeleteBillItem] = useMutation<DeleteBillItem, DeleteBillItemVariables>(
    DELETE_BILL_ITEM_MUTATION,
    {
      update: createDeleteBillItemAfterCache(client),
      variables: {
        billItemId,
      },
    },
  );

  const deleteBillItem = useCallback(async () => {
    if (!projectNumber) {
      throw new Error();
    }

    const billId = await getBillIdOfBillItem(billItemId, client);

    await executeDeleteBillItem();

    await updateBillRowDataAfterDelete(billId, client);
    await updateMissionTablesAfterDelete(billItemRow, client, projectNumber);

    const billLocationId = buildBillLocationId(billId, billItemRow.data.itemLocation.id);
    const isBillLocationRowDeletionNeeded = await checkIfBillLocationDeletionIsNeeded(
      billLocationId,
      client,
    );
    if (isBillLocationRowDeletionNeeded) {
      await deleteBillLocationRow(billLocationId, client);
    }
  }, [executeDeleteBillItem, client, billItemId, projectNumber, billItemRow]);

  return deleteBillItem;
};

/** deletes the specified billItem from the cache */
export const createDeleteBillItemAfterCache =
  (client: ApolloClient<any>): MutationUpdaterFn<DeleteBillItem> =>
  async (cache, { data }) => {
    if (!data?.deleteBillItem) {
      return;
    }

    const { deleteBillItem: deletedBillItem } = data;

    await client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
      query: REMOVE_DATA_TABLE_ROW,
      variables: {
        where: {
          tableType: TableType.BILL,
          id: deletedBillItem.id,
        },
      },
    });
  };

/** returns the bill id of the specified billItem */
const getBillIdOfBillItem = async (billItemId: string, client: ApolloClient<any>) => {
  const {
    data: {
      billItem: {
        bill: { id: billId },
      },
    },
  } = await client.queryManager.query<GetBillIdOfBillItem>({
    query: GET_BILL_ID_OF_BILL_ITEM,
    fetchPolicy: 'cache-first',
    variables: {
      where: {
        id: billItemId,
      },
    },
  });

  return billId;
};

/** update computed fields of bill after deleting a billItem */
const updateBillRowDataAfterDelete = async (billId: string, client: ApolloClient<any>) => {
  const {
    data: { bill },
  } = await client.query<GetSimpleBill>({
    query: GET_SIMPLE_BILL,
    variables: {
      where: { id: billId },
    },
  });
  await client.query<UpdateDataTableRow>({
    query: UPDATE_DATA_TABLE_ROW,
    variables: {
      where: { id: bill.defaultLocation.id, tableType: TableType.BILL },
      data: {
        data: marshalBillData(bill),
      },
    },
  });
};

/**
 * refetches the missionItem location where the deleted billItem's missionItem is residing
 * and refetches the computed fields of the mission
 */
const updateMissionTablesAfterDelete = async (
  row: IDataTableRow,
  client: ApolloClient<any>,
  projectNumber: string,
) => {
  const missionLocationId = buildMissionLocationId(
    row.data['missionItem.mission.id'],
    row.data.itemLocation,
  );
  executeForPopulatedMissionTableTypes(client, projectNumber, async (tableType) => {
    PAGINATION_HELPERS[projectNumber][tableType]?.refetchItemsInContainer([
      { containerId: buildTableId(tableType)(missionLocationId) },
    ]);
    PAGINATION_HELPERS[projectNumber][tableType]?.updateComputedFields({
      id: row.data['missionItem.mission.id'],
      project: { projectNumber },
    });
  });
};

/** check if billLocation has to be deleted because it is empty after delete */
const checkIfBillLocationDeletionIsNeeded = async (
  billLocationId: string,
  client: ApolloClient<any>,
) => {
  const pageInfo = await getPageInfo(billLocationId, client);
  if (pageInfo?.hasNextPage) {
    return false;
  }

  const {
    data: { dataTableRow: billLocationRow },
  } = await client.query<
    GetInnerTableRowIdsOfContainerRow,
    GetInnerTableRowIdsOfContainerRowVariables
  >({
    query: GET_INNER_TABLE_ROW_IDS_OF_CONTAINER_ROW,
    variables: {
      where: {
        id: billLocationId,
        tableType: TableType.BILL,
      },
    },
  });
  if (!billLocationRow?.innerTableRows) {
    throw new Error();
  }

  return billLocationRow.innerTableRows.length === 0;
};

/** delete the billLocation row from cache */
const deleteBillLocationRow = async (billLocationId: string, client: ApolloClient<any>) => {
  await client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
    query: REMOVE_DATA_TABLE_ROW,
    variables: {
      where: {
        id: billLocationId,
        tableType: TableType.BILL,
      },
    },
  });
};

const GET_INNER_TABLE_ROW_IDS_OF_CONTAINER_ROW = gql`
  query GetInnerTableRowIdsOfContainerRow($where: DataTableRowWhereUniqueInput!) {
    dataTableRow(where: $where) @client {
      innerTableRows {
        id
      }
    }
  }
`;
