export const SALUTATION_MAP = {
  1: {
    shortSalutation: 'Herr',
    fullTextSalutation: 'Sehr geehrter Herr',
  },
  2: {
    shortSalutation: 'Frau',
    fullTextSalutation: 'Sehr geehrte Frau',
  },
  3: {
    shortSalutation: 'Damen und Herren',
    fullTextSalutation: 'Sehr geehrte Damen und Herren',
  },
  4: {
    shortSalutation: 'Familie',
    fullTextSalutation: 'Sehr geehrte Familie',
  },
  5: {
    shortSalutation: 'c/o',
    fullTextSalutation: 'c/o',
  },
  6: {
    shortSalutation: 'c/o Herr',
    fullTextSalutation: 'c/o Herr',
  },
  7: {
    shortSalutation: 'Herr Dr.',
    fullTextSalutation: 'Herr Dr.',
  },
  8: {
    shortSalutation: 'Herr und Frau',
    fullTextSalutation: 'Herr und Frau',
  },
  9: {
    shortSalutation: 'Herren',
    fullTextSalutation: 'Herren',
  },
};

export const INVALID_SALUTATION_CODE = -1;
