import gql from 'graphql-tag';
import { MISSION_LOCATION_STRUCTURE_FRAGMENT } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/queries';

export const COPY_MISSION_MUTATION = gql`
  mutation CopyMission($where: MissionWhereUniqueInput!, $data: CopyMissionCreateInput!) {
    copyMission(where: $where, data: $data) {
      id
      name
      colorCodings
      weatherType
      temperature
      surfaceConditions
      executionDate
      netFinishedSum
      grossFinishedSum
      netPlanSum
      grossPlanSum
      finishedVolumeSum
      executionDate
      status {
        id
        missionStatus
        date
        calendarWeek
        untilDate
        time
      }
      defaultMissionItemLocation {
        ...missionLocationStructureFields
        locations {
          ...missionLocationStructureFields
          locations {
            ...missionLocationStructureFields
          }
        }
      }
    }
  }
  ${MISSION_LOCATION_STRUCTURE_FRAGMENT}
`;
