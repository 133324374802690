import ApolloClient from 'apollo-client';
import { tryParseCompositeLocationId } from '.';
import { removeTableTypeFromId } from '../../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { ContainerRows } from '../../../../../services/graphql-client';
import { GetDataTables_dataTables } from '../../../../../services/types/GetDataTables';
import { SearchInput } from '../../../../../types/graphql';
import { IPaginationHelperFns } from '../../../../../utils/paginationHelpers';
import { getAllContainerRows } from '../../../../../utils/paginationHelpers/getAllContainerRows';
import { ISearchSubmitProps } from '../../../../Search/Search';
import { isCompositeLocationId, parseCompositeLocationId } from '../compositeLocationId';
import { BoqTableType } from './executeForBoqTypes';
import { mapItemToInnerTableRow } from './mapper';
import { SEARCH_BILL_OF_QUANTITIES_QUERY } from './queries';
import {
  SearchBillOfQuantities,
  SearchBillOfQuantitiesVariables,
  SearchBillOfQuantities_locations,
} from './types/SearchBillOfQuantities';

type ContainerItemCountMapping = Array<{
  itemAmount: number;
  containerIds: string[];
}>;

const MIN_AMOUNT_TO_FETCH = 10;
const MAX_AMOUNT_TO_FETCH = 100;

export const buildContainerItemCountMapping = (
  containerRows: ContainerRows,
): ContainerItemCountMapping => {
  return containerRows.reduce<ContainerItemCountMapping>((acc, containerRow) => {
    if (!containerRow.innerTableRows) {
      return acc;
    }

    const itemAmount = Math.min(
      Math.max(MIN_AMOUNT_TO_FETCH, containerRow.innerTableRows.length),
      MAX_AMOUNT_TO_FETCH,
    );
    const currentAccItem = acc.find((accItem) => accItem.itemAmount === itemAmount);
    if (currentAccItem) {
      currentAccItem.containerIds.push(containerRow.id);

      return acc;
    }

    acc.push({ itemAmount, containerIds: [containerRow.id] });

    return acc;
  }, []);
};

const getSearchData =
  (client: ApolloClient<any>) =>
  (itemAmount: number, searchInput: SearchInput, showFinishedItems: boolean) =>
  async (containerIds: string[]) => {
    const idIn = containerIds.map((containerId) =>
      isCompositeLocationId(containerId)
        ? parseCompositeLocationId(containerId).locationId
        : removeTableTypeFromId(containerId),
    );

    const { data: searchData } = await client.query<
      SearchBillOfQuantities,
      SearchBillOfQuantitiesVariables
    >({
      query: SEARCH_BILL_OF_QUANTITIES_QUERY,
      variables: {
        take: itemAmount,
        search: searchInput,
        where: { id_in: idIn },
        itemsWhere: { isFinished: showFinishedItems },
      },
    });

    return searchData;
  };

const writeLocationToCache =
  (client: ApolloClient<any>, tableType: BoqTableType) =>
  (containerRows: ContainerRows) =>
  (location: SearchBillOfQuantities_locations) => {
    const containerRow = containerRows.find(
      (containerRow) => tryParseCompositeLocationId('locationId')(containerRow.id) === location.id,
    );
    if (!containerRow) {
      return;
    }

    client.cache.writeData({
      id: `ClientPageInfo:${containerRow.id}`,
      data: {
        __typename: 'ClientPageInfo',
        hasNextPage: location.itemsConnection.pageInfo.hasNextPage,
      },
    });

    client.cache.writeData({
      id: `DataTableRow:${containerRow.id}`,
      data: {
        ...containerRow,
        innerTableRows: location.itemsConnection.nodes.map(mapItemToInnerTableRow(tableType)),
      },
    });
  };

export const onSearchBillOfQuantity =
  (client: ApolloClient<any>, tableType: BoqTableType) =>
  (setLoading: (v: boolean) => void, setSearchState: (searchProps: ISearchSubmitProps) => void) =>
  (filteredDataTable: GetDataTables_dataTables | null): IPaginationHelperFns['onSearch'] =>
  async (searchInput, showFinishedItems) => {
    if (!filteredDataTable) {
      return;
    }

    setSearchState(searchInput);
    setLoading(true);

    const containerRows = getAllContainerRows(filteredDataTable);
    const containerItemCountMapping = buildContainerItemCountMapping(containerRows);
    for (const { itemAmount, containerIds } of containerItemCountMapping) {
      const searchData = await getSearchData(client)(itemAmount, searchInput, showFinishedItems)(
        containerIds,
      );

      searchData.locations.forEach(writeLocationToCache(client, tableType)(containerRows));

      setLoading(false);
    }
  };
