export const preventNonNumericInput = (e: KeyboardEvent) => {
  if (!e.key.match(/^[0-9.]+$/)) {
    e.preventDefault();
  }
};

/**
 * same as `preventNonNumericInput` but allows for a leading minus
 *
 * @param e the keyboard event
 * @see preventNonNumericInput
 */
export const preventNonNumericInputSigned = (e: KeyboardEvent) => {
  if (!e.key.match(/^(-|-?[0-9.]+)$/)) {
    e.preventDefault();
  }
};
