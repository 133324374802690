import gql from 'graphql-tag';

const gqlClient = gql;

export const CURRENT_PROJECT_QUERY = gqlClient`
  query CurrentProject {
    currentProject @client {
      projectName
      projectNumber
    }
  }
`;

export const SET_CURRENT_PROJECT_MUTATION = gqlClient`
  mutation SetCurrentProject($data: ClientProjectInput) {
    setCurrentProject(data: $data) @client
  }
`;
