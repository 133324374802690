import React from 'react';
import { List } from '@material-ui/core';
import Item from '../Item';
import { Formik, Form } from 'formik';
import { getChangedFormValues } from '../../../../utils/form/getChanged';
import { mobileItemFragment } from '../../../pages/BillOfQuantityOverview/types/mobileItemFragment';

export interface ISubmittedItem {
  id: string;
  volume: number;
}

interface IProps {
  items: mobileItemFragment[];
  selectedItemId: string;
  onClick: (item: any) => void;
  onSubmit: (item: ISubmittedItem) => Promise<any>;
  filterText?: string;
  disabled?: boolean;
}

const validate = (v: number) => {
  if (!v) {
    return 'Menge ist verpflichtend!';
  }
};

const getItemsInisialValues = (items: any[]) => {
  return items.reduce((acc: any, curr: any) => {
    acc[curr.id] = curr.volume || '';
    return acc;
  }, {});
};

const ItemsList: React.FC<IProps> = ({
  items,
  selectedItemId,
  onClick,
  onSubmit,
  filterText,
  disabled,
}) => {
  const initialValues = getItemsInisialValues(items);

  return (
    <Formik
      onSubmit={async (values, { setSubmitting }) => {
        const [id, volume] = Object.entries(getChangedFormValues({ initialValues, values }))[0];

        await onSubmit({ id, volume });
        setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {({ handleBlur, values, submitForm }) => {
        const isFormChanged =
          Object.values(getChangedFormValues({ initialValues, values })).length > 0;

        return (
          <List>
            <Form>
              {items.map((item) => {
                return (
                  <Item
                    filterText={filterText}
                    key={item.id}
                    selected={item.id === selectedItemId}
                    onClick={() => onClick(item)}
                    disabled={disabled}
                    fieldProps={{
                      disabled,
                      name: item.id,
                      onClick: () => onClick(item),
                      onBlur: (e: any) => {
                        handleBlur(e);
                        if (isFormChanged) {
                          submitForm();
                        }
                      },
                      validate,
                    }}
                    item={item}
                  />
                );
              })}
            </Form>
          </List>
        );
      }}
    </Formik>
  );
};

export default ItemsList;
