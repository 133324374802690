import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import FullCalendar, { CalendarOptions } from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import CalendarEvent from './Event';
import { ICalendarEvent } from './calender.types';
import { useHistory } from 'react-router-dom';
import { activityTypeColorByNumber } from './colors';
import { makeStyles } from '@material-ui/core';

import './Calendar.css';
import { borderRadius, paddingBasedBorder } from './common';
import { DayHeader } from './DayHeader';

// magic number from fullcalendar.io
/** width of the label column of the calendar */
export const LABEL_WIDTH = 45;

const DEFAULT_FIRST_DAY = 1 as const;
const DEFAULT_INITIAL_VIEW = 'timeGridWeek' as const;
const DEFAULT_SLOT_MIN_TIME = { hours: 6, minutes: 0 } as const;
const DEFAULT_SLOT_MAX_TIME = { hours: 18, minutes: 1 } as const; // this value is exclusive so 18:00 means 17:59, that's why we need an extra minute
const DEFAULT_SCROLL_TIME = { hours: 6, minutes: 0 } as const;
const SLOT_LABEL_FORMAT = {
  hour: 'numeric',
  minute: '2-digit',
  omitZeroMinute: false,
  hour12: false,
} as const;

const TIME_FORMAT: CalendarOptions['eventTimeFormat'] = {
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
};

const PLUGINS: CalendarOptions['plugins'] = [timeGridPlugin];

const useStyles = makeStyles(() => ({
  event: {
    transition: 'transform 200ms',
    '&:hover': {
      filter: 'brightness(1.15)',
      transform: 'scale(1.02)',
    },
    borderRadius,
    ...paddingBasedBorder,
  },
}));

const onEventContent: CalendarOptions['eventContent'] = (arg) => <CalendarEvent {...arg} />;

interface IProps
  extends Pick<CalendarOptions, 'firstDay' | 'scrollTime' | 'initialDate' | 'events'> {
  events: ICalendarEvent[];
  slotMinTime?: { hours: number; minutes: number };
  slotMaxTime?: { hours: number; minutes: number };
  date: Date;
}

const Calendar: React.FC<IProps> = ({
  events,
  firstDay = DEFAULT_FIRST_DAY,
  scrollTime = DEFAULT_SCROLL_TIME,
  slotMinTime = DEFAULT_SLOT_MIN_TIME,
  slotMaxTime = DEFAULT_SLOT_MAX_TIME,
  initialDate,
  date,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const calendarRef = useRef<FullCalendar | null>(null);

  useEffect(() => {
    if (!calendarRef.current) {
      return;
    }

    const api = calendarRef.current.getApi();

    api.gotoDate(date);
  }, [date]);

  const [inclusiveMinTime, inclusiveMaxTime] = useMemo(() => {
    let minTime = slotMinTime ? slotMinTime.hours * 60 + slotMinTime.minutes : undefined;
    let maxTime = slotMaxTime ? slotMaxTime.hours * 60 + slotMaxTime.minutes : undefined;
    if (minTime == null || maxTime == null) {
      return [undefined, undefined];
    }
    for (const event of events) {
      const newMin = event.start.getHours() * 60 + event.start.getMinutes();
      if (newMin < minTime) {
        minTime = newMin;
      }
      const newMax = event.end.getHours() * 60 + event.end.getMinutes();
      if (newMax > maxTime) {
        maxTime = newMax;
      }
    }
    return [
      { hours: Math.floor(minTime / 60), minutes: minTime % 60 },
      { hours: Math.floor(maxTime / 60), minutes: maxTime % 60 },
    ];
  }, [events, slotMinTime, slotMaxTime]);

  const styledEvents = useMemo(
    () =>
      events.map((event) => ({
        backgroundColor: activityTypeColorByNumber(event.activityType),
        borderColor: activityTypeColorByNumber(event.activityType),
        textColor: 'black',
        ...event,
      })),
    [events],
  );

  const onEventClick: CalendarOptions['eventClick'] = useCallback(
    (ctx) => {
      ctx.jsEvent.preventDefault(); // don't let the browser navigate

      if (ctx.event.url) {
        history.push({
          pathname: ctx.event.url,
        });
      }
    },
    [history],
  );

  const onDayHeaderContent = useCallback((args) => <DayHeader {...args} />, []);

  return (
    <FullCalendar
      dayHeaderContent={onDayHeaderContent}
      allDaySlot={false}
      eventClick={onEventClick}
      expandRows={true}
      ref={calendarRef}
      aspectRatio={1}
      plugins={PLUGINS}
      initialView={DEFAULT_INITIAL_VIEW}
      headerToolbar={false}
      events={styledEvents}
      eventContent={onEventContent}
      initialDate={initialDate}
      firstDay={firstDay}
      scrollTime={scrollTime}
      slotMinTime={inclusiveMinTime}
      slotMaxTime={inclusiveMaxTime}
      eventClassNames={classes.event}
      contentHeight="60vh"
      slotLabelFormat={SLOT_LABEL_FORMAT}
      eventTimeFormat={TIME_FORMAT}
    />
  );
};

export default Calendar;
