import gql from 'graphql-tag';

export const DEADLINE_FIELDS_QUERY = gql`
  query GetDeadlineFields($where: BillOfQuantityWhereInput!) {
    billOfQuantity(where: $where) {
      id
      skontoDeadline
      paymentDeadline
    }
  }
`;

export const UPDATE_DEADLINE_FIELDS = gql`
  mutation UpdateDeadlineFields($id: ID!, $data: BillOfQuantityUpdateInput!) {
    updateBillOfQuantity(id: $id, data: $data) {
      id
    }
  }
`;
