import React, { useCallback, useMemo } from 'react';
import { EventContentArg } from '@fullcalendar/react';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { removeTrailingZeroes } from '../../utils/removeTrailingZeroes';
import Popover from '@material-ui/core/Popover';
import { borderRadius, paddingBasedBorder } from './common';
import { ICalendarEvent } from './calender.types';

const MICRO_EVENT_DURATION_LIMIT = 20 / 60;
const SMALL_EVENT_DURATION_LIMIT = 1.5;

const useStyles = makeStyles((theme) => {
  return {
    eventContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      flex: 1,
      width: '100%',
      height: '100%',
      padding: 0,
      margin: 0,
      overflow: 'hidden',
      borderRadius,
    },
    container: {
      display: 'flex',
      flexWrap: 'nowrap',
      flex: 1,
      width: '100%',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      overflow: 'hidden',
      backgroundColor: 'white',
      borderRadius,
    },
    columnContainer: { flexDirection: 'column', padding: '1rem', overflow: 'hidden' },
    rowContainer: {
      flexDirection: 'row',
      height: '100%',
      alignItems: 'center',
      overflow: 'hidden',
    },
    titleContainer: { overflow: 'hidden', paddingRight: '0.5em' },
    titleContainerSmall: {},
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(16),
    },
    descriptionContainer: {
      flex: 1,
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      overflow: 'hidden',
    },
    descriptionContainerSmall: {
      flexDirection: 'row',
    },
    description: {
      fontSize: theme.typography.pxToRem(15),
      minHeight: theme.typography.pxToRem(20),
      width: '100%',
    },
    timeRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      justifyContent: 'flex-end',
      minWidth: 50,
    },
    timeRowOverflow: {
      boxShadow: 'white 0px 0px 2px 2px',
    },
    timeRowSmall: {
      minWidth: 'unset',
    },
    time: {
      flex: 1,
      fontSize: theme.typography.pxToRem(14),
    },
    duration: {
      fontSize: theme.typography.pxToRem(14),
    },
    popover: {
      pointerEvents: 'none',
    },
    popoverInner: {
      display: 'flex',
      ...paddingBasedBorder,
    },
    paper: {
      borderRadius,
      width: '10vw',
      minWidth: 350,
      color: 'black',
      position: 'relative',
    },
  };
});

interface IEventDisplayProps extends Pick<ICalendarEvent, 'title' | 'description'> {
  timeText: string;
  duration: number;
  isSmallEvent: boolean;
  mode?: 'popover' | 'normal';
}

// using divs because of bizarre scaling behaviour of Grid
const EventDisplay: React.FC<IEventDisplayProps> = ({
  title,
  description,
  timeText,
  duration,
  isSmallEvent,
  mode = 'normal',
}) => {
  const classes = useStyles();
  const descriptionArray = useMemo(() => {
    const asArray = [description ?? ''].flat();
    if (isSmallEvent) {
      return asArray.slice(0, 1);
    }
    return asArray;
  }, [description, isSmallEvent]);

  return (
    <div
      className={classNames(
        classes.container,
        isSmallEvent ? classes.rowContainer : classes.columnContainer,
      )}
    >
      <div
        className={classNames(classes.titleContainer, isSmallEvent && classes.titleContainerSmall)}
      >
        <Typography className={classes.title} noWrap>
          {isSmallEvent ? title[0] : title}
        </Typography>
      </div>

      <div
        className={classNames(
          classes.descriptionContainer,
          isSmallEvent && classes.descriptionContainerSmall,
        )}
      >
        {descriptionArray.map((line, idx) => (
          <Typography key={idx} className={classes.description} noWrap={mode === 'normal'}>
            {line}
          </Typography>
        ))}
      </div>

      <div
        className={classNames(
          classes.timeRow,
          isSmallEvent ? classes.timeRowSmall : classes.timeRowOverflow,
        )}
      >
        {!isSmallEvent && (
          <Typography className={classes.time} noWrap>
            {timeText}
          </Typography>
        )}
        <Typography align="right" className={classes.duration} noWrap>{`${removeTrailingZeroes(
          duration,
          2,
        )} h`}</Typography>
      </div>
    </div>
  );
};

const popOverTransformOrigin = {
  vertical: 'center',
  horizontal: 'left',
} as const;

const popOverAnchorOrigin = {
  vertical: 'center',
  horizontal: 'right',
} as const;

const CalendarEvent: React.FC<EventContentArg> = ({ event, timeText }) => {
  const classes = useStyles();
  const { title, extendedProps } = event;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);

  // get duration in hours
  const start = event.start?.getTime() ?? 0;
  const end = event.end?.getTime() ?? 0;
  const duration = end === 0 || start === 0 ? 0 : (end - start) / 1000 / 60 / 60;
  // simplified display: reduced info
  const isSmallEvent = duration <= SMALL_EVENT_DURATION_LIMIT;
  // minimal display: only colored row
  const isMicroEvent = duration <= MICRO_EVENT_DURATION_LIMIT;

  const popOverClasses = useMemo(
    () => ({
      paper: classes.paper,
    }),
    [classes.paper],
  );

  const clientHeight = anchorEl?.clientHeight;
  const popOverBackgroundColor = useMemo(
    () => ({
      backgroundColor: event.backgroundColor,
      minHeight: clientHeight !== undefined ? clientHeight * 1.15 : undefined,
    }),
    [event.backgroundColor, clientHeight],
  );

  return (
    <div
      className={classes.eventContainer}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {!isMicroEvent && (
        <EventDisplay
          title={title}
          timeText={timeText}
          duration={duration}
          description={extendedProps.description}
          isSmallEvent={isSmallEvent}
        />
      )}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={popOverClasses}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={popOverAnchorOrigin}
        transformOrigin={popOverTransformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={classes.popoverInner} style={popOverBackgroundColor}>
          <EventDisplay
            title={title}
            timeText={timeText}
            duration={duration}
            description={extendedProps.description}
            isSmallEvent={false}
            mode="popover"
          />
        </div>
      </Popover>
    </div>
  );
};

export default CalendarEvent;
