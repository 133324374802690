import { getOperationName } from 'apollo-utilities';
import { useMutation } from 'react-apollo';
import { GET_ADDRESS_BOOK } from '../query';
import {
  CONNECT_SHIPPING_TO_BILLING_ADDRESS,
  CREATE_EMPTY_ADDRESS_BOOK_ITEM,
  DISCONNECT_SHIPPING_ADDRESS,
} from './query';
import {
  ConnectShippingToBillingAddress,
  ConnectShippingToBillingAddressVariables,
} from './types/ConnectShippingToBillingAddress';
import {
  CreateEmptyAddressBookItem,
  CreateEmptyAddressBookItemVariables,
} from './types/CreateEmptyAddressBookItem';
import {
  DisconnectShippingAddress,
  DisconnectShippingAddressVariables,
} from './types/DisconnectShippingAddress';

export const useInheritButtonQuery = () => {
  const [executeInherit, { loading, error }] = useMutation<
    ConnectShippingToBillingAddress,
    ConnectShippingToBillingAddressVariables
  >(CONNECT_SHIPPING_TO_BILLING_ADDRESS);
  const [executeDisconnect, { loading: loadingDisconnect, error: errorDisconnect }] = useMutation<
    DisconnectShippingAddress,
    DisconnectShippingAddressVariables
  >(DISCONNECT_SHIPPING_ADDRESS);
  const [
    executeCreateEmptyAddressBookItem,
    { loading: createEmptyLoading, error: createEmptyError },
  ] = useMutation<CreateEmptyAddressBookItem, CreateEmptyAddressBookItemVariables>(
    CREATE_EMPTY_ADDRESS_BOOK_ITEM,
    { refetchQueries: [getOperationName(GET_ADDRESS_BOOK) ?? ''] },
  );

  return {
    loading: loading || loadingDisconnect || createEmptyLoading,
    error: error ?? errorDisconnect ?? createEmptyError,
    executeInherit,
    executeDisconnect,
    executeCreateEmptyAddressBookItem,
  };
};
