import { Grid, Typography } from '@material-ui/core';
import { Field, Formik, FormikHelpers } from 'formik';
import { isEmpty, pick } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { errorPrefixRemover } from '../../../../utils/errorPrefixRemover';
import { getChangedFormValues } from '../../../../utils/form/getChanged';
import defaultNilInitialValues from '../../../../utils/form/initialValuesDefaults';
import { setFalseyValuesToNull } from '../../../../utils/form/setFalseyToNull';
import FormikTextField from '../../../Form/FormikTextField';
import AppErrorMessage from '../../../Page/AppErrorMessage';
import AppProgress from '../../../Page/AppProgress';
import { DEADLINE_FIELDS_QUERY, UPDATE_DEADLINE_FIELDS } from './DeadlineFields.queries';
import { GetDeadlineFields, GetDeadlineFieldsVariables } from './types/GetDeadlineFields';
import { UpdateDeadlineFields, UpdateDeadlineFieldsVariables } from './types/UpdateDeadlineFields';

const useFetches = (variables: GetDeadlineFieldsVariables) => {
  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useQuery<GetDeadlineFields, GetDeadlineFieldsVariables>(DEADLINE_FIELDS_QUERY, { variables });

  const [updateDeadlineFields, { loading: mutationLoading, error: mutationError }] = useMutation<
    UpdateDeadlineFields,
    UpdateDeadlineFieldsVariables
  >(UPDATE_DEADLINE_FIELDS, {
    refetchQueries: [{ query: DEADLINE_FIELDS_QUERY, variables }],
  });

  return useMemo(
    () => ({
      updateDeadlineFields,
      data,
      loading: queryLoading || mutationLoading,
      error: queryError || mutationError,
    }),
    [data, queryLoading, queryError, mutationLoading, mutationError, updateDeadlineFields],
  );
};

interface IFormValues {
  skontoDeadline: number | null;
  paymentDeadline: number | null;
}

const DEFAULT_VALUES: IFormValues = {
  paymentDeadline: null,
  skontoDeadline: null,
};

const VALIDATION_SCHEMA = Yup.object().shape({
  skontoDeadline: Yup.number().moreThan(-1, 'Skontofrist muss eine positive Zahl sein').nullable(),
  paymentDeadline: Yup.number().moreThan(-1, 'Nettofrist muss eine positive Zahl sein').nullable(),
});

const onKeyUp = (submitForm: () => void) => (e: React.KeyboardEvent) => {
  if (e.keyCode !== 13) {
    return;
  }

  submitForm();
};

const onBlur = (submitForm: () => void) => () => submitForm();

export const DeadlineFields: React.FC = () => {
  const { id } = useParams();

  const { data, loading, error, updateDeadlineFields } = useFetches({ where: { id } });

  const initialValues = useMemo(
    () =>
      defaultNilInitialValues(
        pick(data?.billOfQuantity ?? {}, 'skontoDeadline', 'paymentDeadline'),
        DEFAULT_VALUES,
      ),
    [data],
  );

  const onSubmit = useCallback(
    (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
      const changedValues = getChangedFormValues({ initialValues, values });

      const hasNotChanged = isEmpty(changedValues);

      if (hasNotChanged) {
        return setSubmitting(false);
      }

      return updateDeadlineFields({
        variables: {
          id,
          data: setFalseyValuesToNull(changedValues, ['skontoDeadline', 'paymentDeadline']),
        },
      });
    },
    [updateDeadlineFields, initialValues, id],
  );

  return (
    <div>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <Typography variant="h6" gutterBottom>
        Fristen
      </Typography>
      <Formik<IFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={VALIDATION_SCHEMA}
        enableReinitialize
      >
        {({ submitForm }) => (
          <Grid container spacing={10}>
            <Grid item>
              <Field
                name="skontoDeadline"
                label="Skontofrist"
                type="number"
                shrink
                component={FormikTextField}
                onBlur={onBlur(submitForm)}
                onKeyUp={onKeyUp(submitForm)}
              />
            </Grid>
            <Grid item>
              <Field
                name="paymentDeadline"
                label="Nettofrist"
                type="number"
                shrink
                component={FormikTextField}
                onBlur={onBlur(submitForm)}
                onKeyUp={onKeyUp(submitForm)}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};
