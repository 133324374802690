import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SCALE_DISCOUNTS_QUERY, scaleDiscountFields } from './ScaleDiscounts';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import ScaleDiscountFormDialog from './ScaleDiscountFormDialog';
import AppProgress from '../Page/AppProgress';
import createCurrencyFormatter from '../../utils/createCurrencyFormatter';

const SCALE_DISCOUNT_QUERY = gql`
  query ScaleDiscount($id: ID!) {
    scaleDiscount(where: { id: $id }) {
      ...scaleDiscountFields
    }
  }
  ${scaleDiscountFields}
`;
const UPDATE_SCALE_DISCOUNT = gql`
  mutation UpdateScaleDiscount($id: ID!, $data: ScaleDiscountUpdateInput!) {
    updateScaleDiscount(where: { id: $id }, data: $data) {
      ...scaleDiscountFields
    }
  }
  ${scaleDiscountFields}
`;

const formatCurrency = createCurrencyFormatter({ format: '0.00' });

const EditScaleDiscount: React.FunctionComponent<RouteComponentProps> = ({ history, match }) => {
  const itemBoilerplateId = (match.params as any).id;
  const { scaleDiscountId } = match.params as any;

  return (
    <Query<any, any> query={SCALE_DISCOUNT_QUERY} variables={{ id: scaleDiscountId }}>
      {({ loading, error, data }) => {
        if (loading) {
          return <AppProgress />;
        }

        if (error) {
          console.error(error);
          return null;
        }

        return (
          data && (
            <Mutation<any, any>
              mutation={UPDATE_SCALE_DISCOUNT}
              update={(cache, { data: { updateScaleDiscount } }) => {
                const { scaleDiscounts } = cache.readQuery({
                  query: SCALE_DISCOUNTS_QUERY,
                  variables: { itemBoilerplateId },
                }) as any;
                const scaleDiscountIdx = scaleDiscounts.findIndex(
                  (discount: any) => discount.id === scaleDiscountId,
                );
                scaleDiscounts[scaleDiscountIdx] = updateScaleDiscount;
                cache.writeQuery({
                  query: SCALE_DISCOUNTS_QUERY,
                  variables: { itemBoilerplateId },
                  data: { scaleDiscounts },
                });
              }}
            >
              {(updateSaleDiscount) => (
                <ScaleDiscountFormDialog
                  initialValues={{
                    ...data.scaleDiscount,
                    pricePerUnit: formatCurrency(data.scaleDiscount.pricePerUnit),
                  }}
                  onClose={() => history.goBack()}
                  onSubmit={(values) =>
                    updateSaleDiscount({ variables: { id: scaleDiscountId, data: values } })
                  }
                />
              )}
            </Mutation>
          )
        );
      }}
    </Query>
  );
};

export default EditScaleDiscount;
