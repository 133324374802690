import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import range from 'lodash/range';
import Checkbox from '@material-ui/core/Checkbox';
import LockDimensionsOnDrag from '../../LockDimensionsOnDrag';
import { ActionType, ITableContext } from '..';
import { TableSortLabel } from '@material-ui/core';
import { IDataTableOrder } from '../types';

interface IProps {
  maxInnerTableLevel: number;
  rowIds: string[];
  context: ITableContext;
  level: number;
}

const HeaderRow: React.FC<IProps> = ({ maxInnerTableLevel, rowIds, context, level }) => {
  const { tableData, checkedRowIds, dispatch } = context;

  const { hasCheckboxes } = tableData.innerTableOptions;

  const checkedIds = rowIds.filter((rowId) =>
    checkedRowIds.find((checkedRowId) => rowId === checkedRowId),
  );

  const notCheckedIds = rowIds.filter((rowId) => !checkedIds.includes(rowId));

  const { fixedWidthColumns, actionColumnWidth } = tableData.options;

  const maxInnerLevel = Math.max(
    ...Object.values(tableData.navigation).map(({ path }) => path.length),
  );

  /* 14px are for indentation per level */
  const paddingLeftFirstCell = 10 + 14 * (maxInnerLevel - level);

  return (
    <TableHead>
      <TableRow>
        {/* The first empty cells are used for the child/parent-relationships */}
        {range(maxInnerTableLevel).map((index) => {
          const cell = (
            <TableCell
              padding="none"
              style={{ width: fixedWidthColumns ? 0 : 'auto' }}
              key={fixedWidthColumns ? index : undefined}
            />
          );
          return fixedWidthColumns ? (
            cell
          ) : (
            <LockDimensionsOnDrag key={index}>{cell}</LockDimensionsOnDrag>
          );
        })}
        {tableData.innerTableOptions!.isDragAndDropEnabled && (
          <LockDimensionsOnDrag>
            <TableCell padding="none" />
          </LockDimensionsOnDrag>
        )}
        {hasCheckboxes && (
          <LockDimensionsOnDrag>
            <TableCell
              padding="none"
              // 42px is the width of the checkbox element; needs to be explicitly set here because
              // otherwise the cell might collapse if the table doesn't have a (min-)width set
              style={{ paddingLeft: paddingLeftFirstCell, width: 42 + paddingLeftFirstCell }}
            >
              {(hasCheckboxes === true || hasCheckboxes.all) && (
                <Checkbox
                  indeterminate={checkedIds.length > 0}
                  checked={checkedIds.length === rowIds.length}
                  onChange={(e, checked) => {
                    if (context.tableData.innerTableOptions.onSelectAllCheckboxChange) {
                      context.tableData.innerTableOptions.onSelectAllCheckboxChange(
                        checked ? notCheckedIds : checkedIds,
                        checked,
                        e,
                      );
                    }
                    dispatch({
                      type: ActionType.TOGGLE_CHECKBOXES,
                      payload: { toggleEntries: checked ? notCheckedIds : checkedIds },
                    });
                  }}
                />
              )}
            </TableCell>
          </LockDimensionsOnDrag>
        )}
        {tableData.innerTableOptions.columns.map((column, index) => {
          const width = fixedWidthColumns ? column.width ?? 150 : 'auto';
          const paddingLeft = 10 + 14 * (maxInnerLevel - level);

          const style: React.CSSProperties = {
            ...(index === 0 && !hasCheckboxes
              ? { paddingLeft, width, boxSizing: 'content-box' }
              : { width }),
            // add some padding to columns that can't be used to sort since the usual padding
            // that's added from the sorting arrows is missing
            ...(column.preventSorting ? { paddingRight: 18 } : {}),
          };

          const cellContent = column.width !== 0 ? column.label : '';

          const cell = (
            <TableCell
              align={column.isNumber ? 'right' : 'left'}
              sortDirection={tableData.orderByColumnId === column.id ? tableData.order : false}
              style={style}
              padding="none"
              key={column.id}
            >
              {column.preventSorting ? (
                cellContent
              ) : (
                <TableSortLabel
                  disabled={tableData.options.isReordering}
                  active={tableData.orderByColumnId === column.id}
                  direction={tableData.order}
                  onClick={() => {
                    const payload = {
                      orderByColumnId: column.id,
                      order: tableData.order,
                    };

                    if (tableData.orderByColumnId === column.id) {
                      payload.order = payload.order === 'asc' ? 'desc' : 'asc';
                    }

                    if (tableData.options.onChangeSort) {
                      tableData.options.onChangeSort(column.id, payload.order as IDataTableOrder);
                    }

                    dispatch({
                      type: ActionType.SET_ORDER_STATE,
                      payload,
                    });
                  }}
                  tabIndex={-1}
                >
                  {cellContent}
                </TableSortLabel>
              )}
            </TableCell>
          );

          return fixedWidthColumns ? (
            cell
          ) : (
            <LockDimensionsOnDrag key={column.id}>{cell}</LockDimensionsOnDrag>
          );
        })}
        {tableData.innerTableOptions.rowActions && (
          <TableCell style={{ width: actionColumnWidth ? actionColumnWidth : 150 }}>
            Aktion
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default HeaderRow;
