import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import WarningIcon from '@material-ui/icons/Warning';
import Snackbar from '@material-ui/core/Snackbar';
import { IconButton, SnackbarContent, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export enum Variant {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

const variantIcon: any = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-line',
  },
  hidden: {
    display: 'none',
  },
}));

interface ISnackbarProps {
  message: string;
  onClose?: (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.SyntheticEvent<any, Event>,
    reason?: string,
  ) => void;
  hasCloseButton: boolean;
  variant: Variant;
  open: boolean;
  autoHideDuration?: number;
}

const CustomizedSnackbar: React.FunctionComponent<ISnackbarProps> = ({
  message,
  onClose,
  variant,
  hasCloseButton,
  open,
  autoHideDuration,
}) => {
  const classes = useStyles();

  const Icon = variantIcon[variant];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={`${classes.icon} ${classes.iconVariant}`} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={!hasCloseButton ? classes.hidden : ''}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default CustomizedSnackbar;
