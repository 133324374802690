import React from 'react';
import { Button, styled, Theme } from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import Dinero from 'dinero.js';
import {
  createSwissCurrencyFormatter,
  roundToFiveRappen,
} from '../../../utils/createCurrencyFormatter';
import { SPLITSCREEN_ITEM_PADDING } from '../../../components/SplitScreen';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });
const CostSumInfo = styled('div')(({ theme }: { theme: Theme }) => ({
  height: 85,
  padding: theme.spacing(2, 4),
  marginTop: theme.spacing(2),
  marginLeft: -SPLITSCREEN_ITEM_PADDING,
  marginRight: -SPLITSCREEN_ITEM_PADDING,
  backgroundColor: blue[100],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  bottom: 0,
}));

const PlannedCost = styled('div')({
  fontWeight: 500,
});

const ChosenBill = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const ChosenBillHeadline = styled('span')({
  fontSize: '0.8rem',
  color: grey[600],
});

interface IProps {
  missionItems: any[];
  selectedContainers: string[];
  bill?: any;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SelectedItemsInfo: React.FC<IProps> = ({ missionItems, bill, selectedContainers }) => {
  const sum = missionItems.reduce(
    (sum, item) =>
      sum.add(
        roundToFiveRappen(Dinero({ amount: item.item.netPrice }).multiply(item.amountVolume)),
      ),
    Dinero({ amount: 0 }),
  );
  const roundedSum = roundToFiveRappen(sum);

  const isMissionBlocked = missionItems.some((mi) => mi.mission.isMissionBlocked);

  const isElementsSelected = missionItems.length > 0 || selectedContainers.length > 0;

  return (
    <CostSumInfo>
      <PlannedCost>Geplante Menge in Summe: {formatCurrency(roundedSum.getAmount())}</PlannedCost>
      <ChosenBill>
        <ChosenBillHeadline>Ausgewählte Rechnung</ChosenBillHeadline>{' '}
        <span>{bill && bill.title}</span>
      </ChosenBill>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        disabled={!bill || isMissionBlocked || !isElementsSelected}
      >
        Geplante Teilpositionen zur Rechnung hinzufügen
      </Button>
    </CostSumInfo>
  );
};

export default SelectedItemsInfo;
