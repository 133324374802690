import React, { useMemo } from 'react';
import { useField } from 'formik';
import { createSwissCurrencyFormatter } from '../../../../../utils/createCurrencyFormatter';
import { IAccountLogggerFormValues, ITimeEntry } from '../../types';
import {
  IDataTableColumn,
  IDataTableOptions,
  IDataTableRow,
} from '../../../../../components/DataTable/types';
import { DataTableBody, DataTableHotKeysWrapper } from '../../../../../components/DataTable';
import { Grid, Typography } from '@material-ui/core';
import { getDuration } from '../../../../../utils/durations';
import { GetAccountingEmployees_employees } from '../../types/GetAccountingEmployees';
import {
  getSearchableEmployee,
  getSearchableMission,
  parseSearchableActivityType,
  searchableActivityType,
  searchableMission,
  searchableMissionProject,
} from '../../../utils/searchable';
import Dinero from 'dinero.js';
import { GetAccountingMissions_missions } from '../../types/GetAccountingMissions';
import {
  PreviewAccountingLogItems_calculatePreviewItems_employeeBonusesOverviews,
  PreviewAccountingLogItems_calculatePreviewItems_items,
} from '../../types/PreviewAccountingLogItems';
import {
  ChargedTo,
  CreditedTo,
  parseChargeConstraint,
  testChargeConstraint,
} from '../../../utils/chargeConstraint';
import { GetAccountingActivityTypes_activityTypes } from '../../types/GetAccountingActivityTypes';
import { roundToTwoDecimalPlaces } from '../../../../../utils/calculator';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

type TableRow = IDataTableRow<
  { name: string; first: boolean },
  void,
  {
    activityType: string;
    mission: string;
    project: string;
    timeFrom: string;
    timeTo: string;
    amount: number;
    hourlyWage: Dinero.Dinero;
    sum: Dinero.Dinero;
  }
>;

const columns: Array<IDataTableColumn<TableRow>> = [
  {
    id: 'activityType',
    label: 'Leistungsart',
  },
  {
    id: 'mission',
    label: 'Zulasten',
  },
  {
    id: 'project',
    label: 'Projekt',
  },
  {
    id: 'timeFrom',
    label: 'Zeit Start',
  },
  {
    id: 'timeTo',
    label: 'Zeit Ende',
  },
  {
    id: 'amount',
    label: 'Menge',
    render: (value) => <>{roundToTwoDecimalPlaces(value)}</>,
  },
  {
    id: 'hourlyWage',
    label: 'Ansatz',
    render: formatCurrency,
  },
  {
    id: 'sum',
    label: 'Summe',
    render: formatCurrency,
  },
];

const employeeColumns: IDataTableColumn[] = [
  {
    id: 'name',
    label: '',
    renderPlain: true,
    render: (name, row) => {
      return (
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">
              {row.data.first ? 'Equipenchef' : 'Mitarbeiter'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{name}</Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 'totalWorkedTime',
    label: 'Arbeitszeit',
    render: (cellData) => <>{cellData} H</>,
  },
  {
    id: 'plannedWorkTime',
    label: 'Sollzeit',
    render: (cellData) => <>{cellData} H</>,
  },
  {
    id: 'totalBonus',
    label: 'Zuschlag Gesamt',
    render: formatCurrency,
  },
];

const options: IDataTableOptions = {
  hideShowAdditionalColumnsBtn: true,
  displayName: 'Mitarbeiter',
  tableName: 'ACCOUNTING_LOGGER_EMPLOYEE_SUMMARY',
  activeRowId: '',
  fixedWidthColumns: true,
  levels: [
    {
      columns: employeeColumns,
    },
    { columns: [] },
    { columns: [] },
    {
      columns,
    },
  ],
};

/**
 * Map time entries to data table rows
 * @param date the accounting day
 * @param logEmployees the employees
 * @param employees meta info for employees
 * @param bonusOverviews overviews of employee bonuses
 * @param missions meta info for missions
 * @param individualTimeEntries the individual time entries per person
 * @param timeEntries the time entries
 * @param mappedItems are time entries which got their collective accounts mapped
 * @param activityTypes meta info for activity types
 * @returns data table rows
 * @see IDataTableRow
 */
const mapToDataTable = (
  date: string,
  logEmployees: readonly string[],
  employees: readonly GetAccountingEmployees_employees[],
  individualTimeEntries: Array<{ person: string; entries: readonly ITimeEntry[] }>,
  timeEntries: readonly ITimeEntry[],
  mappedItems: readonly PreviewAccountingLogItems_calculatePreviewItems_items[],
  bonusOverviews: readonly PreviewAccountingLogItems_calculatePreviewItems_employeeBonusesOverviews[],
  missions: readonly GetAccountingMissions_missions[],
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[],
): TableRow[] => {
  // create an array that is easier to group with the group time entries per person and the individual time entries
  const personified = logEmployees
    .map((person) => ({ person, entries: timeEntries }))
    .concat(individualTimeEntries)
    .map((personifiedTimeEntries) => ({
      ...personifiedTimeEntries,
      // filter frontend only entries
      entries: personifiedTimeEntries.entries.filter((entry) => {
        try {
          const activityType = parseSearchableActivityType(entry.activityType);
          return (
            testChargeConstraint(activityType, CreditedTo.EMPLOYEE, ChargedTo.MISSION) ||
            // URE entries that aren't booked to a collective account
            (activityType.number === 1009 &&
              !['', 'sammelkonto'].includes(entry.mission.trim().toLowerCase()))
          );
        } catch {
          return false;
        }
      }),
    }));

  // group the time entries per person in and object indexed by the person
  const grouped = personified.reduce(
    (
      grouped: { [personId: string]: { person: string; entries: ITimeEntry[] } },
      personifiedEntry,
    ) => {
      if ((personifiedEntry.person ?? '') === '') {
        // empty row
        return grouped;
      }
      const personId = personifiedEntry.person;
      if (!(personId in grouped)) {
        grouped[personId] = {
          person: personifiedEntry.person,
          entries: personifiedEntry.entries.slice(0),
        };
      } else {
        grouped[personId].entries.push(...personifiedEntry.entries);
      }
      return grouped;
    },
    {},
  );
  return Object.keys(grouped).map((personId, personIdx) => {
    const person = getSearchableEmployee(personId, employees);
    const hourlyWage = Dinero({ amount: person?.function?.hourlyWage ?? 0 });
    const bonusOverview = bonusOverviews.find(({ id }) => id === person?.id);

    // create the inner table rows based on calculated preview items
    // will be concatenated to the other inner table rows
    const bonusInnerTableRows =
      bonusOverview?.accountingItems.map((item, idx) => {
        const amount = item.amount ?? 0;
        const hourlyWage = Dinero({ amount: item.creditedTo.costPerUnit });
        const missionId = item.chargedTo.mission?.id;
        const mission = missions.find(({ id }) => id === missionId);
        const project = mission ? searchableMissionProject(mission) : '';
        const activityType = activityTypes.find(
          (activityType) =>
            activityType.number === parseChargeConstraint(item.chargeConstraint).activityType,
        );
        return {
          id: `${personId}-bonus-${idx.toString()}`,
          data: {
            activityType: activityType ? searchableActivityType(activityType) : 'unbekannt',
            mission: mission
              ? searchableMission(mission)
              : item.chargedTo.collectiveAccount?.nameOne ?? 'unbekannt',
            project,
            timeFrom: item.startTime ?? '',
            timeTo: item.endTime ?? '',
            amount,
            hourlyWage,
            sum: hourlyWage.multiply(amount),
          },
        };
      }) ?? [];
    const totalBonus = bonusInnerTableRows.reduce(
      (acc, { data: { sum } }) => acc.add(sum),
      Dinero({ amount: 0 }),
    );

    const mappedItemRows = mappedItems
      .filter((item) => item.creditedTo.employee?.id === person?.id)
      .map((item, idx) => {
        const activityType = activityTypes.find(
          (activityType) =>
            activityType.number === parseChargeConstraint(item.chargeConstraint).activityType,
        );
        const amount = item.amount ?? 0;
        const hourlyWage = Dinero({ amount: item.creditedTo.costPerUnit });
        return {
          id: `${personId}-mapped-${idx.toString()}`,
          data: {
            activityType: activityType ? searchableActivityType(activityType) : 'unbekannt',
            mission: item.chargedTo.collectiveAccount?.nameOne ?? 'unbekanntes Sammelkonto',
            project: '',
            timeFrom: item.startTime ?? '',
            timeTo: item.endTime ?? '',
            amount,
            hourlyWage,
            sum: hourlyWage.multiply(amount),
          },
        };
      });

    return {
      id: personId,
      data: {
        first: personIdx === 0,
        name: `${person?.firstName ?? ''} ${person?.lastName ?? ''}`,
        plannedWorkTime: bonusOverview?.plannedWorkTime ?? 0,
        totalWorkedTime: bonusOverview?.totalWorkedTime ?? 0,
        totalBonus,
      },
      containerRows: [],
      innerTableRows: sortInnerRowItemsByStartTime(
        grouped[personId].entries
          .map((timeEntry, idx) => {
            const amount = getDuration(date, timeEntry.timeFrom, timeEntry.timeTo);
            const mission = getSearchableMission(timeEntry.mission, missions);
            const project = mission ? searchableMissionProject(mission) : '';

            return {
              id: `${personId}-${idx.toString()}`,
              data: {
                activityType: timeEntry.activityType,
                mission: timeEntry.mission,
                project,
                timeFrom: timeEntry.timeFrom,
                timeTo: timeEntry.timeTo,
                amount,
                hourlyWage,
                sum: hourlyWage.multiply(amount),
              },
            };
          })
          .concat(mappedItemRows)
          .concat(bonusInnerTableRows),
      ),
    };
  });
};

const sortInnerRowItemsByStartTime = (items: IDataTableRow[]): IDataTableRow[] =>
  items.sort((a, b) => (a.data.timeFrom > b.data.timeFrom ? 1 : -1));

export interface IEmployeeSummaryProps {
  bonusOverviews: readonly PreviewAccountingLogItems_calculatePreviewItems_employeeBonusesOverviews[];
  mappedItems: readonly PreviewAccountingLogItems_calculatePreviewItems_items[];
  employees: readonly GetAccountingEmployees_employees[];
  missions: readonly GetAccountingMissions_missions[];
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
}

/**
 * The employee summary section lists times and bonuses for employees.
 * @constructor
 */
export const EmployeeSummary: React.FC<IEmployeeSummaryProps> = React.memo(
  ({ employees, missions, bonusOverviews, mappedItems, activityTypes }) => {
    const [{ value: date }] = useField<IAccountLogggerFormValues['date']>('date');
    const [{ value: chief }] = useField<IAccountLogggerFormValues['chief']>('chief');
    const [{ value: logEmployees }] = useField<IAccountLogggerFormValues['employees']>('employees');
    const [{ value: individualTimeEntries }] =
      useField<IAccountLogggerFormValues['individualTimeEntries']>('individualTimeEntries');
    const [{ value: timeEntries }] =
      useField<IAccountLogggerFormValues['timeEntries']>('timeEntries');
    const containerRows = useMemo(
      () =>
        mapToDataTable(
          date,
          [chief, ...logEmployees].filter((employee) => employee !== ''),
          employees,
          individualTimeEntries.map((individual) => ({
            ...individual,
            entries: individual.entries.slice(0, -1),
          })),
          timeEntries.slice(0, -1),
          mappedItems,
          bonusOverviews,
          missions,
          activityTypes,
        ),
      [
        date,
        chief,
        logEmployees,
        employees,
        individualTimeEntries,
        timeEntries,
        mappedItems,
        bonusOverviews,
        missions,
        activityTypes,
      ],
    );

    return (
      <DataTableHotKeysWrapper containerRows={containerRows} options={options}>
        {(context) => {
          return <DataTableBody context={context} />;
        }}
      </DataTableHotKeysWrapper>
    );
  },
);
