import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';
import AppProgress from '../../../components/Page/AppProgress';
import AppErrorMessage from '../../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import { GET_ACCOUNTING_LOG_JOURNAL } from './AccountingLogJournal.queries';
import { IPaginationState } from '../../../components/Pagination/Pagination.utils';
import {
  GetAccountingLogJournal,
  GetAccountingLogJournalVariables,
} from './types/GetAccountingLogJournal';
import { GetAccountingActivityTypes } from '../AccountingLogger/types/GetAccountingActivityTypes';
import { GET_ACCOUNTING_ACTIVITY_TYPES } from '../AccountingLogger/AccountingLogger.queries';
import { someLoading } from '../utils/someLoading';
import { AccountingLogJournalTable } from '../AccountingLogJournalTable';
import { orEmpty } from '../AccountingLogger/utils/orEmpty';
import { AccountingLogJournalSearch } from './AccountingLogJournalSearch';
import { IDataTableOrder, ILevelOptions } from '../../../components/DataTable/types';
import { AccountingItemWhereFlatInput } from '../../../types/graphql';
// tslint:disable-next-line
import { QueryResult } from '@apollo/react-common';

interface IAccountingLogJournalProps {
  rowActions?: ILevelOptions['rowActions'];
  csvDownload?: boolean;
  query?: QueryResult<GetAccountingLogJournal, GetAccountingLogJournalVariables>;
}

/**
 * Component that displays a simple table of accounting items
 * @constructor
 */
export const AccountingLogJournal: React.FC<IAccountingLogJournalProps> = React.memo(
  ({ rowActions, csvDownload = true, query }) => {
    const journal =
      query ??
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useQuery<GetAccountingLogJournal, GetAccountingLogJournalVariables>(
        GET_ACCOUNTING_LOG_JOURNAL,
      );
    const activityTypes = useQuery<GetAccountingActivityTypes>(GET_ACCOUNTING_ACTIVITY_TYPES);

    const journalRefetch = journal.refetch;

    const [paginationState, setPaginationState] = useState<IPaginationState | undefined>(undefined);
    const onPaginationStateChange = useCallback(
      (state: IPaginationState) => {
        setPaginationState(state);
        journalRefetch({
          page: {
            amountPerPage: state.rowsPerPage,
            pageNumber: state.page,
          },
        });
      },
      [journalRefetch],
    );

    const [sortState, setSortState] = useState<string | undefined>(undefined);
    const onChangeSort = useCallback(
      (columnId: string, order: IDataTableOrder) =>
        setSortState(columnId === '' ? undefined : `${columnId}_${order.toUpperCase()}`),
      [setSortState],
    );

    const onSearch = useCallback(
      async (searchParameters: AccountingItemWhereFlatInput) => {
        const page =
          paginationState != null
            ? {
                amountPerPage: paginationState.rowsPerPage,
                pageNumber: paginationState.page,
              }
            : undefined;
        const orderBy = sortState != null ? { fieldName: sortState } : undefined;
        await journalRefetch({
          where: searchParameters,
          page,
          orderBy,
        });
      },
      [journalRefetch, paginationState, sortState],
    );

    const search = useMemo(() => <AccountingLogJournalSearch onSearch={onSearch} />, [onSearch]);

    return (
      <>
        {someLoading(journal, activityTypes) && <AppProgress />}
        {journal.error && <AppErrorMessage message={errorPrefixRemover(journal.error.message)} />}
        {activityTypes.error && (
          <AppErrorMessage message={errorPrefixRemover(activityTypes.error.message)} />
        )}

        <AccountingLogJournalTable
          tableName="ALL_ACCOUNTING_LOG_ITEMS"
          onPaginationStateChange={onPaginationStateChange}
          activityTypes={orEmpty(activityTypes.data?.activityTypes)}
          accountingItems={orEmpty(journal.data?.accountingItemsWithStats?.accountingItems)}
          totalNumberRows={journal.data?.accountingItemsWithStats?.totalNumberRows ?? 0}
          rowActions={rowActions}
          csvDownload={csvDownload}
          search={search}
          onChangeSort={onChangeSort}
        />
      </>
    );
  },
);
