/**
 * the event and popover border radius
 */
export const borderRadius = 15;

/**
 * a border created by using a skewed offset. wider ribbon on the left.
 */
export const paddingBasedBorder = {
  padding: '0.1em',
  paddingLeft: '1em',
  paddingBottom: '0.15em',
} as const;
