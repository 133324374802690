import gql from 'graphql-tag';
import { historyFieldsFragment } from '../../components/History/history.queries';
import { PureQueryOptions } from 'apollo-client';

export const CREATE_EMPLOYEE_MUTATION = gql`
  mutation CreateEmployee($data: EmployeeCreateInput!) {
    createEmployee(data: $data) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE_MUTATION = gql`
  mutation UpdateEmployee($data: EmployeeUpdateInput!, $where: EmployeeWhereUniqueInput!) {
    updateEmployee(data: $data, where: $where) {
      id
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query GetEmployee($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      id
      employeeNumber
      firstName
      lastName
      subsidiary {
        id
        name
      }
      photo {
        id
        mimetype
        name
        nameHash
        path
      }
      workload
      supervisor {
        id
        firstName
        lastName
      }
      user {
        id
        name
      }
      entryDate
      leaveDate
      function {
        id
        name
      }
      functionHistory {
        ...historyFields
      }
      subsidiaryHistory {
        ...historyFields
      }
      workloadHistory {
        ...historyFields
      }
      entryDateHistory {
        ...historyFields
      }
      leaveDateHistory {
        ...historyFields
      }
    }
  }
  ${historyFieldsFragment}
`;

export const GET_EMPLOYEES = gql`
  query GetEmployees(
    $where: EmployeeWhereInput
    $search: SearchInput
    $page: PageInput
    $orderBy: OrderByInput
  ) {
    employees(where: $where, search: $search, page: $page, orderBy: $orderBy) {
      id
      employeeNumber
      firstName
      lastName
      isDeactivated
      entryDate
      leaveDate
      user {
        id
        name
      }
      supervisor {
        id
        firstName
        lastName
      }
      subsidiary {
        id
        name
      }
      workload
      function {
        id
        name
      }
    }
  }
`;

export const employeesRefetchQueries: PureQueryOptions[] = [
  { query: GET_EMPLOYEES, variables: { where: { isDeactivated: false } } },
];

export const EMPLOYEE_FUNCTIONS = gql`
  query EmployeeFunctions($search: SearchInput) {
    employeeFunctions(search: $search) {
      id
      name
      hourlyWage
    }
  }
`;

export const EMPLOYEE_FUNCTIONS_SELECT = gql`
  query EmployeeFunctionsSelect {
    employeeFunctions {
      id
      name
    }
  }
`;

export const EMPLOYEE_COMPUTED_FIELDS = [
  'subsidiary.name',
  'function.name',
  'supervisor.firstName',
  'user.name',
];
