import React from 'react';
import { MenuItem, makeStyles, Theme } from '@material-ui/core';
import { useQuery, useMutation } from 'react-apollo';
import { UserColumnsSettings, UserColumnsSettingsVariables } from './types/UserColumnsSettings';
import {
  GET_COLUMNS_SETTINGS,
  GET_CURRENT_COLUMNS_SETTING,
  SET_CURRENT_COLUMNS_SETTING,
} from './clumnsSettings.queries';
import { CurentColumnsSetting } from './types/CurentColumnsSetting';
import { SetCoulmnsSetting, SetCoulmnsSettingVariables } from './types/SetCoulmnsSetting';
import { getOperationName } from 'apollo-utilities';
import { lighten } from '@material-ui/core/styles';

interface IProps {
  userName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  selected: {
    '&$selected': {
      boxShadow: 'unset',
      backgroundColor: lighten(theme.palette.primary.light, 0.85),
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      opacity: 1,
    },
  },
}));

const ColumnsSettings: React.FC<IProps> = ({ userName }) => {
  const classes = useStyles();

  const { data, error, loading } = useQuery<UserColumnsSettings, UserColumnsSettingsVariables>(
    GET_COLUMNS_SETTINGS,
    {
      variables: { userName },
    },
  );

  const { data: currentSettingData } = useQuery<CurentColumnsSetting, null>(
    GET_CURRENT_COLUMNS_SETTING,
  );

  const [setColumnSetting, { loading: mutaionLoading }] = useMutation<
    SetCoulmnsSetting,
    SetCoulmnsSettingVariables
  >(SET_CURRENT_COLUMNS_SETTING, {
    refetchQueries: [getOperationName(GET_CURRENT_COLUMNS_SETTING) || ''],
  });

  const setCurrentColumnsSetting = (id: string) => {
    // update the cache
    if (data && data.columnsSettings) {
      const newColumnsSetting = data.columnsSettings.filter((setting: any) => setting.id === id)[0];
      setColumnSetting({
        variables: {
          columnsSetting: {
            ...newColumnsSetting,
          },
        },
      });
    }
  };

  if (!data || error || loading) {
    return null;
  }

  return (
    <>
      <MenuItem disabled>Spalten Einstellungen</MenuItem>

      {data.columnsSettings.map((columnsSetting) => {
        const selected =
          currentSettingData && currentSettingData.currentColumnsSetting
            ? currentSettingData.currentColumnsSetting.id === columnsSetting.id
            : false;

        return (
          <MenuItem
            className={selected ? classes.selected : ''}
            selected={selected}
            disabled={selected || mutaionLoading}
            key={columnsSetting.id}
            onClick={() => setCurrentColumnsSetting(columnsSetting.id)}
          >
            {columnsSetting.name}
          </MenuItem>
        );
      })}
    </>
  );
};

export default ColumnsSettings;
