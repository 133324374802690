import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import gql from 'graphql-tag';
import { useApolloClient, useQuery } from 'react-apollo';
import { useHistory, useLocation } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { navigationProjects } from './types/navigationProjects';
import { useSearchState, Search } from '../Search/Search';
import { makeStyles } from '@material-ui/styles';
import { Theme, InputBase, ClickAwayListener } from '@material-ui/core';
import { IDataTableRow } from '../DataTable/types';
import { CLEAR_DATA_TABLES } from '../../services/graphql-client';
import { removeContainerSortMappingInProject } from '../Actions/ContainerSort/cache';

const GET_NAVIGATION_PROJECTS = gql`
  query navigationProjects($search: SearchInput) {
    projects(
      where: { archived: true }
      search: $search
      page: { amountPerPage: 20, pageNumber: 0 }
    ) {
      id
      projectNumber
      projectName
      status
      subsidiary {
        id
        name
      }
    }
  }
`;

const projectMapper = (project: any) => ({
  id: project.projectNumber,
  data: {
    ...project,
    subsidiary: project.subsidiary.name,
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  searchInput: {
    marginLeft: 8,
    flex: 1,
    color: theme.palette.common.white,
  },
}));

/**
 * needed because useProjectNumber does not return projectNumber in projects drop-down
 */
const extractProjectNumber = (pathname: string) =>
  pathname.startsWith('/projekte/')
    ? pathname.replace('/projekte/', '').replace(/\/.*/, '')
    : undefined;

const NavigationProjectsDropDown: React.FunctionComponent = () => {
  const client = useApolloClient();
  const location = useLocation();
  const { data, error, refetch, loading } = useQuery<navigationProjects>(GET_NAVIGATION_PROJECTS);
  const [didClickOutside, setDidClickOutside] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchState = useSearchState();
  const rows = useMemo(() => data?.projects?.map(projectMapper) ?? [], [data]);

  const removeFocus = useCallback(() => {
    setDidClickOutside(true);
  }, []);
  const addFocus = useCallback(() => {
    setDidClickOutside(false);
  }, []);

  const navigateToNewProject = useCallback(
    (row: IDataTableRow) => {
      client.query({ query: CLEAR_DATA_TABLES });

      // useUnmount in ProjectTabs does not trigger because component does not unmount but only change data
      const projectNumber = extractProjectNumber(location.pathname);
      if (projectNumber) {
        removeContainerSortMappingInProject(projectNumber, client);
      }

      history.push(`/projekte/${row.data.projectNumber}/details`);
    },
    [client, history, location],
  );

  // somehow input field looses focus when something new is fetched so we handle focusing and bluring manually
  useEffect(() => {
    if (didClickOutside) {
      inputRef.current?.blur();
    }
  }, [didClickOutside]);

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <ClickAwayListener onClickAway={removeFocus}>
      <span>
        <DataTable
          innerTableRows={rows}
          search={
            <Search
              columns={['projectNumber', 'projectName', 'status', 'subsidiary.name']}
              searchState={searchState}
              onSubmit={({ columns, terms }) => refetch({ search: { terms, columns } })}
              loading={loading}
            >
              {({ setFieldSearchTerm, fieldSearchTerm, triggerSearch }) => {
                return (
                  <InputBase
                    onClick={addFocus}
                    autoComplete="off"
                    className={classes.searchInput}
                    value={fieldSearchTerm}
                    placeholder="Projekte suchen"
                    onChange={(e) => setFieldSearchTerm(e.target.value)}
                    onKeyUp={() => triggerSearch()}
                    onBlur={() => {
                      if (!didClickOutside) {
                        setTimeout(() => inputRef.current?.focus(), 5); // wait 5m, else firefox
                      }
                    }}
                    inputRef={inputRef}
                  />
                );
              }}
            </Search>
          }
          options={{
            filterText: searchState.searchTerm,
            isDropDown: true,
            placeholder: 'Projekte suchen',
            levels: [
              {
                isDragAndDropEnabled: false,
                onRowClick: navigateToNewProject,
                columns: [
                  {
                    id: 'projectNumber',
                    label: 'Nr',
                  },
                  {
                    id: 'projectName',
                    label: 'Projektbezeichnung',
                  },
                  {
                    id: 'status',
                    label: 'Status',
                  },
                  {
                    id: 'subsidiary',
                    label: 'Filiale',
                  },
                ],
              },
            ],
          }}
        />
      </span>
    </ClickAwayListener>
  );
};

export default NavigationProjectsDropDown;
