import gql from 'graphql-tag';
import { FULL_ADDRESS } from '../query';

export const UPSERT_ADDRESS = gql`
  mutation UpsertAddress($where: AddressUpsertWhereInput!, $data: AddressUpdateInput!) {
    upsertAddress(where: $where, data: $data) {
      ...FullAddress
    }
  }
  ${FULL_ADDRESS}
`;
