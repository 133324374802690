import { LocationSelect } from './types/LocationSelect';
import { useMemo } from 'react';
import { isEmpty, flow } from 'lodash';

enum LocationLevels {
  DEFAULT_LOCATION = 0,
  ONE = 1,
  TWO = 2,
}

interface ILocation {
  id: string;
  name: string;
  level: LocationLevels;
  hasChildren: boolean;
}

const flattenData = ({ billOfQuantity }: LocationSelect): ILocation[] => {
  return [
    // use BillOfQuantity name but use defaultLocation id
    // when you use defaultLocation.name changing BillOfQuantity name, does not also apply to defaultLocation.name
    {
      id: billOfQuantity.defaultLocation.id,
      name: billOfQuantity.name || '',
      level: LocationLevels.DEFAULT_LOCATION,
      hasChildren: !isEmpty(billOfQuantity.defaultLocation.locations),
    },

    // map locations one level deep
    ...(billOfQuantity.defaultLocation.locations?.map(({ id, name, locations }) => ({
      id,
      name,
      level: LocationLevels.ONE,
      hasChildren: !isEmpty(locations),
    })) ?? []),

    // map locations two levels deep
    ...(
      billOfQuantity.defaultLocation.locations?.map(
        ({ locations }) =>
          locations?.map(({ id, name }) => ({
            id,
            name,
            level: LocationLevels.TWO,
            hasChildren: false,
          })) ?? [],
      ) ?? []
    ).flat(1),
  ];
};

const isNotSourceLocation = (sourceLocationId: string) => (location: ILocation) =>
  location.id !== sourceLocationId;

const filterNonSourceLocation = (sourceLocationId: string) => (locations: ILocation[]) =>
  locations.filter(isNotSourceLocation(sourceLocationId));

const isDefaultLocationLevel = (location: ILocation) =>
  location.level === LocationLevels.DEFAULT_LOCATION;

const isNotLevelTwoLocation = (location: ILocation) => location.level !== LocationLevels.TWO;

/**
 * to be used with flow or filterValidLocations(sourceLocationId)(locations)
 *
 * Filters the locations to which you can copy
 *
 * Location level-one cannot be copied to another location level-one if the former has subLocations
 * it can only be copied to the root level (boq/defaultLocation)
 *
 * If Location has no subLocations it can be copied everywhere except to itself
 */
const filterValidLocations = (sourceLocationId: string) => (locations: ILocation[]) => {
  const sourceLocation = locations.find((location) => location.id === sourceLocationId);
  if (!sourceLocation) {
    throw new Error(
      `No sourceLocation with id: ${sourceLocationId} found. You probably forget to specify sourceLocationId in props of LocationSelect`,
    );
  }

  if (sourceLocation.level === LocationLevels.ONE && sourceLocation.hasChildren) {
    return locations.filter(isDefaultLocationLevel);
  }

  // never include level-two locations, because those cannot have subLocations
  return locations.filter(isNotLevelTwoLocation);
};

// IMPORTANT ensure that filterNonSourceLocation gets called last
const pipeData = (data: LocationSelect, sourceLocationId: string) =>
  flow(
    flattenData,
    filterValidLocations(sourceLocationId),
    filterNonSourceLocation(sourceLocationId),
  )(data);

export const useValidLocations = (data: LocationSelect | undefined, sourceLocationId: string) =>
  useMemo(
    () => (data?.billOfQuantity?.defaultLocation ? pipeData(data, sourceLocationId) : []),
    [data, sourceLocationId],
  );
