import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import { ITableContext } from '..';

const useStyles = makeStyles((theme: Theme) => ({
  searchBar: {
    maxWidth: 800,
  },
  root: {
    padding: 0,
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  actions: {
    color: theme.palette.text.secondary,
    flex: '1',
    textAlign: 'right',
  },
  title: {
    flex: '3',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export interface IProps {
  actions?: React.ReactNode;
  context: ITableContext;
}

const TableToolbar: React.FC<IProps> = React.memo(({ actions, context }) => {
  const { tableData } = context;
  const classes = useStyles();

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {tableData.options.displayName && (
          <Typography variant="h5">{tableData.options.displayName}</Typography>
        )}
        {tableData.options.globalActions?.()}
        {tableData.search}
      </div>
      <div className={classes.actions}>
        {actions}
        {tableData.innerTableOptions.actions && tableData.innerTableOptions.actions()}
      </div>
    </Toolbar>
  );
});

export default TableToolbar;
