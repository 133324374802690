import { ApolloError } from 'apollo-client';

export interface IWithError {
  error?: ApolloError;
}

/**
 * Combine multiple error indicators
 * @param errors an object that has an apollo error field
 * @returns true if any of the objects has an error
 */
export const someError = (...errors: ReadonlyArray<Readonly<IWithError>>) =>
  errors.some(({ error }) => error != null);
