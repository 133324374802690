import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { getChangedFormValues } from '../../../utils/form/getChanged';
import { DetailClose } from '../../BillOfQuantity/BillOfQuantityDetailsForm';
import { BillOfQuantity_billOfQuantity } from '../../BillOfQuantity/types/BillOfQuantity';
import CancelSaveButtons from '../../Form/CancelSaveButtons';
import AppProgress from '../../Page/AppProgress';
import PageWrapper from '../../PageWrapper';
import {
  ASSIGN_BOQ_OFFER_CONDITION_MUTATION,
  GET_BOQ_OFFER_CONDITIONS_QUERY,
} from './OfferConditionsGrid.queries';
import {
  activeKey,
  reinitializeKey,
  textKey,
  toFormikValues,
  valuesContains,
} from './OfferConditionsGrid.utils';
import { GridItems } from './OfferConditionsGridItems';
import {
  AssignBillOfQuantityOfferCondition,
  AssignBillOfQuantityOfferConditionVariables,
} from './types/AssignBillOfQuantityOfferCondition';
import {
  GetBillOfQuantityOfferConditions,
  GetBillOfQuantityOfferConditionsVariables,
} from './types/GetBillOfQuantityOfferConditions';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 1440,
    width: '100%',
    margin: 'auto',
    padding: '2em',
  },
  cancelSaveContainer: {
    paddingTop: '1em',
  },
}));

interface IOfferConditionsGridProps {
  billOfQuantityId: BillOfQuantity_billOfQuantity['id'];
  onClose: () => void;
}

/**
 * Displays a grid of offer conditions for a specific bill of quantity
 * @param billOfQuantityId id of the bill of quantity
 */
const OfferConditionsGrid: React.FC<IOfferConditionsGridProps> = ({
  billOfQuantityId,
  onClose,
}) => {
  const { data, error, loading } = useQuery<
    GetBillOfQuantityOfferConditions,
    GetBillOfQuantityOfferConditionsVariables
  >(GET_BOQ_OFFER_CONDITIONS_QUERY, {
    variables: {
      where: {
        id: billOfQuantityId,
      },
    },
    /*
     * otherwise deleting/modifying an offer condition on the global /angebotsbedingunen page wont be reflected
     * without reload.
     */
    fetchPolicy: 'cache-and-network',
  });

  const [assignOfferCondition] = useMutation<
    AssignBillOfQuantityOfferCondition,
    AssignBillOfQuantityOfferConditionVariables
  >(ASSIGN_BOQ_OFFER_CONDITION_MUTATION, {
    refetchQueries: [
      {
        query: GET_BOQ_OFFER_CONDITIONS_QUERY,
        variables: {
          where: {
            id: billOfQuantityId,
          },
        },
      },
    ],
  });

  const offerConditions = data?.billOfQuantity?.offerConditions;
  const items = useMemo(() => offerConditions ?? [], [offerConditions]);

  const [initialValues, validationSchema] = useMemo(() => toFormikValues(items), [items]);

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const changed = getChangedFormValues({ initialValues, values });
      const isChanged = valuesContains(changed);
      // only use items if they're part of the changed set
      const changedOriginals = items.filter(isChanged);
      // send of mutations for all changed offer condition assignments
      await Promise.all(
        changedOriginals.map((original) => {
          const text = changed[textKey(original)];
          // is it actually an override of the text? if not remove
          const overrideText = text !== original.text ? text : undefined;
          const active = changed[activeKey(original)] ?? original.active;
          const data = { active, overrideText };
          const where = {
            billOfQuantityId: original.billOfQuantityId,
            offerConditionId: original.offerConditionId,
          };
          return assignOfferCondition({ variables: { where, data } });
        }),
      );
      setSubmitting(false);
    },
    [items, initialValues, assignOfferCondition],
  );

  /*
   * used as key prop for Formik because enableReinitilize in combination with changing schemas causes problems
   * using the key prob will rerender (on react level) if the schema/items change
   */
  const key = useMemo(() => reinitializeKey(items), [items]);

  const classes = useStyles();

  if (error) {
    console.log(error);
    return null;
  }

  if (!data) {
    return null;
  }

  if (items.length === 0) {
    // formik doesn't like changing schemas in combination with enableReinitialize
    return null;
  }

  return (
    <>
      <PageWrapper>
        {loading && <AppProgress />}
        <Grid container item xs={12} justify="flex-end">
          <DetailClose onClose={onClose} />
        </Grid>
        <Formik
          // hard reinitialize using react's `key`, see above
          key={key}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, isSubmitting, values }) => (
            <Form>
              <Grid container className={classes.container} direction="column">
                <Grid container spacing={3} direction="row">
                  <GridItems items={items} values={values} />
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  className={classes.cancelSaveContainer}
                  direction="row"
                >
                  <CancelSaveButtons isDisabled={!isValid || !dirty || isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </PageWrapper>
    </>
  );
};

export default OfferConditionsGrid;
