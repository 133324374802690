import gql from 'graphql-tag';
import { FULL_ADDRESS_BOOK_ITEM } from '../query';

export const DELETE_ADDRESS_BOOK_ITEM = gql`
  mutation DeleteAddressBookItem($where: AddressBookItemWhereUniqueInput!) {
    deleteAddressBookItem(where: $where) {
      ...FullAddressBookItem
    }
  }
  ${FULL_ADDRESS_BOOK_ITEM}
`;
