import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-apollo';
import { omit } from 'lodash';
import { GetVehicles, GetVehiclesVariables } from './types/GetVehicles';
import { GET_VEHICLES, VEHICLE_COMPUTED_FIELDS } from './vehicle.query';
import AppProgress from '../../components/Page/AppProgress';
import { IDataTableColumn } from '../../components/DataTable/types';
import { DataTableBody, DataTableHotKeysWrapper } from '../../components/DataTable';
import { IconButton, Tooltip } from '@material-ui/core';
import { Search } from '../../components/Search';
import { useSearchState } from '../../components/Search/Search';
import { useHistory } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { mapToDataTable } from './vehicleTable.utils';
import AddButton from '../../components/DataTable/DataTableToolbar/AddButton';
import { SELECTED_SUBSIDIARY_QUERY } from '../Missions/queries';
import { Subsidiary } from '../Missions/types/Subsidiary';
import { IPaginationState, usePaginationState } from '../../components/Pagination/Pagination.utils';
import { Pagination } from '../../components/Pagination';
import { useCount } from '../../hooks/useCount';
import { CountEntity } from '../../hooks/useCount/useCount.queries';
import { DownloadCsvAction } from '../../components/Actions/Download/Csv';
import { formatDate } from '../../utils/format/date';
import { createOrderByFieldName } from '../../utils/order/createOrderByFieldName';
import { isComputedField } from '../../utils/order/isComputedField';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import { Role } from '../../types/graphql';

const columns: IDataTableColumn[] = [
  {
    id: 'inventoryNumber',
    label: 'Inventarnummer',
  },
  {
    id: 'projectNumber',
    label: 'Projektnummer',
  },
  {
    id: 'category',
    label: 'Kategorie',
  },
  {
    id: 'subsidiary.name',
    label: 'Filiale',
  },
  {
    id: 'kilometers',
    label: 'Kilometerstand',
  },
  {
    id: 'kilometersUser',
    label: 'Kilometerstand-User',
    hideOnDefault: true,
  },
  {
    id: 'kilometersDate',
    label: 'Kilometerstand-Datum',
    hideOnDefault: true,
  },
  {
    id: 'internalHourlyWage',
    label: 'Stundensatz intern',
  },
  {
    id: 'isDeactivated',
    label: 'Inaktiv',
  },
  {
    id: 'nameOne',
    label: 'Bezeichnung 1',
  },
  {
    id: 'nameTwo',
    label: 'Bezeichnung 2',
    hideOnDefault: true,
  },
  {
    id: 'driver',
    label: 'Fahrer',
    hideOnDefault: true,
  },
  {
    id: 'releaseDate',
    label: 'Jahrgang',
    hideOnDefault: true,
  },
  {
    id: 'weight',
    label: 'Gewicht',
    hideOnDefault: true,
  },
  {
    id: 'payload',
    label: 'Nutzlast',
    hideOnDefault: true,
  },
  {
    id: 'registrationNumber',
    label: 'Kennzeichen',
    hideOnDefault: true,
  },
  {
    id: 'emissionsTestDate',
    label: 'Abgasuntersuchung',
    hideOnDefault: true,
  },
  {
    id: 'trafficOfficeDate',
    label: 'STVA Termin',
    hideOnDefault: true,
  },
  {
    id: 'note',
    label: 'Bemerkung',
    hideOnDefault: true,
  },
  {
    id: 'trailerLoad',
    label: 'Anhängerlast',
    hideOnDefault: true,
  },
  {
    id: 'fuelCardOne',
    label: 'Tankkarte 1',
    hideOnDefault: true,
  },
  {
    id: 'fuelCardTwo',
    label: 'Tankkarte 2',
    hideOnDefault: true,
  },
  {
    id: 'pinOne',
    label: 'Pin 1',
    hideOnDefault: true,
  },
  {
    id: 'pinTwo',
    label: 'Pin 2',
    hideOnDefault: true,
  },
  {
    id: 'tcsAsof',
    label: 'TCS ab',
    hideOnDefault: true,
  },
  {
    id: 'eVignette',
    label: 'E-Vignette',
  },
  {
    id: 'serialNumber',
    label: 'Stammnummer',
    hideOnDefault: true,
  },
  {
    id: 'chassisNumber',
    label: 'Fahrgestellnummer',
  },
  {
    id: 'lastInternalCheckAt',
    label: 'Letzte Prüfung intern',
    hideOnDefault: true,
  },
];

const columnIds = columns.map(({ id }) => id);

export const VehiclesTable: React.FC = () => {
  const history = useHistory();

  const searchState = useSearchState();
  const [showDeactivatedVehicles, setShowDeactivatedVehicles] = useState<boolean>(false);

  const user = useLoggedInUser();

  const { data: subsidiaryData } = useQuery<Subsidiary>(SELECTED_SUBSIDIARY_QUERY);

  const subsidiary =
    subsidiaryData?.selectedSubsidiary === 'all'
      ? undefined
      : { id: subsidiaryData?.selectedSubsidiary };

  const { data, error, loading, refetch, variables } = useQuery<GetVehicles, GetVehiclesVariables>(
    GET_VEHICLES,
    {
      variables: {
        where: {
          subsidiary,
          ...(!showDeactivatedVehicles && { isDeactivated: showDeactivatedVehicles }),
        },
        page: {
          pageNumber: 0,
          amountPerPage: 25,
        },
      },
    },
  );

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        page: {
          amountPerPage: state.rowsPerPage,
          pageNumber: state.page,
        },
      });
    },
    [refetch],
  );

  const count = useCount({
    entity: CountEntity.VEHICLE,
    variables: omit(variables, 'page'),
  });

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  if (error) {
    console.log(error);
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <DataTableHotKeysWrapper
        innerTableRows={mapToDataTable(data?.vehicles || [])}
        pagination={<Pagination paginationState={paginationState} />}
        search={
          <Search
            columns={columnIds}
            searchState={searchState}
            onSubmit={(search) => refetch({ search })}
            loading={loading}
          />
        }
        options={{
          tableName: 'ALL_VEHICLES',
          filterText: searchState.searchTerm,
          levels: [
            {
              columns,
              onRowClick: (row) =>
                history.push({ pathname: `/fahrzeuge/${row.id}/editieren`, state: { variables } }),
            },
          ],
          onChangeSort: async (fieldName, order) => {
            await refetch({
              orderBy: {
                fieldName: createOrderByFieldName(fieldName, order),
                isComputed: isComputedField(fieldName, VEHICLE_COMPUTED_FIELDS),
              },
            });
          },
        }}
      >
        {(context) => (
          <DataTableBody
            context={context}
            toolbarProps={{
              actions: (
                <>
                  <DownloadCsvAction
                    csvName={`Fahrzeuge_${formatDate()}`}
                    entityType="VEHICLE"
                    variables={{ where: variables.where, search: variables.search }}
                  />
                  <Tooltip
                    title={`Inaktive Fahrzeuge ${
                      showDeactivatedVehicles ? 'ausblenden' : 'einblenden'
                    }`}
                  >
                    <IconButton
                      onClick={() => {
                        setShowDeactivatedVehicles(!showDeactivatedVehicles);
                      }}
                    >
                      {showDeactivatedVehicles ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Tooltip>

                  <AddButton
                    href="/fahrzeuge/erstellen"
                    tooltip="Fahrzeug erstellen"
                    state={{ variables }}
                    disabled={user?.role !== Role.SUPER_ADMIN}
                  />
                </>
              ),
            }}
          />
        )}
      </DataTableHotKeysWrapper>
    </>
  );
};
