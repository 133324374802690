import React from 'react';
import { Formik, Form, Field } from 'formik';
import FormikTextField from '../Form/FormikTextField';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { GET_LOGGEDIN_USER } from '../../pages/Login';
import { GetLoggedInUser } from '../../pages/types/GetLoggedInUser';
import { CreateColumnsSetting, CreateColumnsSettingVariables } from './types/CreateColumnsSetting';
import { getOperationName } from 'apollo-link';
import {
  GET_COLUMNS_SETTINGS,
  columnsSettingfragment,
  SET_CURRENT_COLUMNS_SETTING,
} from './clumnsSettings.queries';
import { SetCoulmnsSetting, SetCoulmnsSettingVariables } from './types/SetCoulmnsSetting';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const CREATE_COLUMNS_SETTING_MUTATION = gql`
  mutation CreateColumnsSetting($data: ColumnsSettingCreateInput!, $where: UserWhereUniqueInput!) {
    createColumnsSetting(data: $data, where: $where) {
      ...ColumnsSettingFields
    }
  }
  ${columnsSettingfragment}
`;

const AddColumnSettingDialog: React.FC<IProps> = ({ open, onClose }) => {
  const [setCurrentColumnSetting] = useMutation<SetCoulmnsSetting, SetCoulmnsSettingVariables>(
    SET_CURRENT_COLUMNS_SETTING,
  );

  const [createColumnsSetting] = useMutation<CreateColumnsSetting, CreateColumnsSettingVariables>(
    CREATE_COLUMNS_SETTING_MUTATION,
    {
      refetchQueries: [getOperationName(GET_COLUMNS_SETTINGS) || ''],
      onCompleted(data) {
        // save changes to session storage
        setCurrentColumnSetting({
          variables: {
            columnsSetting: {
              ...data.createColumnsSetting,
              __typename: 'CurrentColumnsSetting',
            },
          },
        });
      },
    },
  );
  const { data, error, loading } = useQuery<GetLoggedInUser, null>(GET_LOGGEDIN_USER);

  if (!data || error || loading) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Neue Einstellung</DialogTitle>

      <DialogContent>
        <Formik
          onSubmit={async (values) => {
            await createColumnsSetting({
              variables: {
                where: { name: data.auth.name },
                data: values,
              },
            });
            onClose();
          }}
          initialValues={{
            name: '',
          }}
        >
          {() => {
            return (
              <Form>
                <Field name="name" required label="Name" component={FormikTextField} />

                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Abbrechen
                  </Button>
                  <Button type="submit" color="primary">
                    Erstellen
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddColumnSettingDialog;
