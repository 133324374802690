import gql from 'graphql-tag';

export const MOBILE_PROJECTS_QUERY = gql`
  query MobileProjects($search: SearchInput) {
    projects(page: { amountPerPage: 15, pageNumber: 0 }, search: $search) {
      id
      projectNumber
      projectName
    }
  }
`;
