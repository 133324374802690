import React, { FC, useCallback } from 'react';
import { useApolloClient, useLazyQuery } from 'react-apollo';
import { useContainerSortAction } from '..';
import { useProjectNumber } from '../../../../hooks/useProjectNumber';
import { MissionTableType } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/executeForMissionTypes';
import { createOrderByFieldName } from '../../../../utils/order/createOrderByFieldName';
import { sortDataTableRowsInCache, persistContainerSortMapping } from '../cache';
import { IContainerSortMapping, IFieldMapping, useContainerSortDialog } from '../dialog';
import { GET_SORTED_MISSIONS } from './queries';
import { GetSortedMissions, GetSortedMissionsVariables } from './types/GetSortedMissions';
import { useContainerSortMapping } from '../query';

const ALLOWED_FIELDS = ['id', 'executionDate'] as const;
const INITIAL_SORT_MAPPING: IContainerSortMapping<AllowedField> = {
  orderBy: 'id',
  direction: 'desc',
};
const FIELD_MAPPING: Array<IFieldMapping<AllowedField>> = [
  {
    fieldName: 'id',
    displayName: 'Nr',
  },
  {
    fieldName: 'executionDate',
    displayName: 'Ausmass-Datum',
  },
];

type AllowedField = typeof ALLOWED_FIELDS[number];
interface IProps {
  tableType: MissionTableType;
}

export const ContainerSortMissionAction: FC<IProps> = ({ tableType }) => {
  const projectNumber = useProjectNumber();
  const client = useApolloClient();
  const sortMapping = useContainerSortMapping<AllowedField>(tableType);

  /**
   * sorts the rows in the dataTable for the given tableType to
   * align with fetched sorted missions
   */
  const sortMissionRowsInCache = useCallback(
    (data: GetSortedMissions) => {
      const sortedIds = data.missions.map((mission) => mission.id.toString());
      sortDataTableRowsInCache(sortedIds, client, tableType, (data, idx) => ({
        ...data,
        index: idx,
      }));
    },
    [client, tableType],
  );

  const [fetchSortedMissions] = useLazyQuery<GetSortedMissions, GetSortedMissionsVariables>(
    GET_SORTED_MISSIONS,
    { onCompleted: sortMissionRowsInCache },
  );

  /**
   * fetches sorted missions and persists current container sort-mapping
   */
  const handleContainerSort = useCallback(
    (values: IContainerSortMapping<AllowedField>) => {
      if (!projectNumber) {
        throw new Error();
      }

      fetchSortedMissions({
        variables: {
          projectNumber,
          orderBy: { fieldName: createOrderByFieldName(values.orderBy, values.direction) },
        },
      });

      persistContainerSortMapping(values, projectNumber, tableType, client);
    },
    [fetchSortedMissions, projectNumber, client, tableType],
  );

  const { isContainerSortDialogOpen, closeContainerSortDialog, containerSortAction } =
    useContainerSortAction({ entityName: 'Einsatz' });
  const { containerSortDialog } = useContainerSortDialog({
    // use INITIAL_SORT_MAPPING, if user has not sorted containers
    initialSortMapping: sortMapping ?? INITIAL_SORT_MAPPING,
    fieldMapping: FIELD_MAPPING,
    title: 'Einsatz-Container sortieren',
    onClose: closeContainerSortDialog,
    open: isContainerSortDialogOpen,
    onSubmit: handleContainerSort,
  });

  return (
    <>
      {containerSortAction}
      {containerSortDialog}
    </>
  );
};
