import React from 'react';
import { makeStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 9999,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
  },
}));

const AppProgress: React.FunctionComponent = () => {
  const classes = useStyles();
  return <LinearProgress color="secondary" className={classes.root} />;
};

/** A progress bar on the top of the page */
export default AppProgress;
