import React, { useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { HotKeys } from 'react-hotkeys';
import noop from 'lodash/fp/noop';
import {
  IChildrenRows,
  IDataTableColumn,
  IDataTableOptions,
  IDataTableOrder,
  IDataTableRow,
  ITableData,
} from './types';
import DataTableBody from './DataTableBody';
import filterChildren, { queryToWords } from './data/filterChildren';
import sortWithChildren from './data/sortWithChildren';
import filterOpenChildrenRows from './data/filterOpenChildrenRows';
import defaultClosedRowIds from './data/defaultClosedRowIds';
import { firstRowId, rowNavigationChildren } from './data/rowNavigationChildren';
import { DataTableDropDown } from './DataTableDropDown';
import { DraggingContext } from '../LockDimensionsOnDrag';
import { curry, intersectionWith, isArray, isEmpty, isFunction, isNil } from 'lodash';
import createPreventAll from '../../utils/createPreventEventDefault';
import { useColumnsSetting } from '../../hooks/ColumnsSettings/useColumnsSetting';
import { usePrevious } from 'react-use';
import { flattenContainerRows } from '../../utils/flattenRows/flattenRows';

export const showColumn = curry(
  (shownColumnIds: string[], column: Pick<IDataTableColumn, 'id' | 'alwaysVisible'>) => {
    return column.alwaysVisible || shownColumnIds.includes(column.id);
  },
);

const toggleEntries = (toggleEntries: string | string[], oldEntries: string[]) => {
  toggleEntries = isArray(toggleEntries) ? toggleEntries : [toggleEntries];

  return [
    ...oldEntries.filter((x) => !toggleEntries.includes(x)),
    ...toggleEntries.filter((x) => !oldEntries.includes(x)),
  ];
};

export interface IDataTableProps {
  droppableId?: string;
  containerRows?: IDataTableRow[];
  innerTableRows?: IDataTableRow[];
  pagination?: React.ReactNode;
  search?: React.ReactNode;
  options: IDataTableOptions;
}

interface IRenderProps {
  children?: ((props: ITableContext) => React.ReactNode) | React.ReactNode;
}

interface IState {
  orderState: {
    order: IDataTableOrder;
    orderByColumnId: string;
  };
  shownColumnIds: string[];
  checkedRowIds: string[];
  closedRowIds: string[];
  filterText: string;
  activeRowId: string | null;
  activeRowRef: React.MutableRefObject<HTMLElement | undefined>;
  anchorEl: any;
  dropDownOpen: boolean;
  columnSelectorAnchorEl: HTMLElement | null;
  closedRowIdsCalculatedWithActualData: boolean;
  selectedContainerIds: string[];
}

export interface ITableContext {
  tableData: ITableData;
  droppableId?: string;
  shownColumnIds: string[];
  checkedRowIds: string[];
  closedRowIds: string[];
  activeRowId: string;
  activeRowRef: React.MutableRefObject<HTMLElement | undefined>;
  state: IState;
  dispatch: IDispatchTable;
}

export enum ActionType {
  SET_FILTER,
  TOGGLE_COLUMN,
  TOGGLE_CHECKBOXES,
  TOGGLE_ROW,
  SET_ACTIVE_ROW,
  RESET_CHECKBOXES,
  SET_COLUMNS_SELECTOR_REF,
  SET_SHOWN_COLUMN_IDS,
  SET_ORDER_STATE,
  SET_CLOSED_ROW_IDS,
  TOGGLE_SELECTED_CONTAINER,
}

export type IAction =
  | {
      type: ActionType.SET_ORDER_STATE;
      payload: { orderByColumnId: string; order?: IDataTableOrder };
    }
  | { type: ActionType.SET_FILTER; payload: { filter: string } }
  | { type: ActionType.TOGGLE_COLUMN; payload: { columnId: string } }
  | { type: ActionType.SET_SHOWN_COLUMN_IDS; payload: string[] }
  | { type: ActionType.TOGGLE_CHECKBOXES; payload: { toggleEntries: string | string[] } }
  | { type: ActionType.TOGGLE_ROW; payload: { rowId: string } }
  | { type: ActionType.SET_ACTIVE_ROW; payload: { rowId: string | null } }
  | { type: ActionType.RESET_CHECKBOXES }
  | { type: ActionType.SET_COLUMNS_SELECTOR_REF; payload: { element: HTMLElement | null } }
  | { type: ActionType.SET_CLOSED_ROW_IDS; payload: string[] }
  | { type: ActionType.TOGGLE_SELECTED_CONTAINER; payload: string[] };

const tableReducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case ActionType.SET_FILTER:
      return { ...state, filterText: action.payload.filter, activeRowId: '' };

    case ActionType.SET_ORDER_STATE:
      return {
        ...state,
        orderState: {
          ...state.orderState,
          order: action.payload.order ?? 'asc',
          orderByColumnId: action.payload.orderByColumnId,
        },
      };
    case ActionType.TOGGLE_COLUMN:
      return {
        ...state,
        shownColumnIds: toggleEntries(action.payload.columnId, state.shownColumnIds),
      };
    case ActionType.SET_SHOWN_COLUMN_IDS:
      return {
        ...state,
        shownColumnIds: action.payload,
      };
    case ActionType.TOGGLE_CHECKBOXES:
      return {
        ...state,
        checkedRowIds: toggleEntries(action.payload.toggleEntries, state.checkedRowIds),
      };
    case ActionType.TOGGLE_ROW:
      return {
        ...state,
        closedRowIds: toggleEntries(action.payload.rowId, state.closedRowIds),
      };
    case ActionType.SET_ACTIVE_ROW:
      return {
        ...state,
        activeRowId: action.payload.rowId,
      };
    case ActionType.RESET_CHECKBOXES:
      return {
        ...state,
        checkedRowIds: [],
        selectedContainerIds: [],
      };
    case ActionType.SET_COLUMNS_SELECTOR_REF:
      return {
        ...state,
        columnSelectorAnchorEl: action.payload.element,
      };
    case ActionType.SET_CLOSED_ROW_IDS:
      return {
        ...state,
        closedRowIds: action.payload,
        closedRowIdsCalculatedWithActualData: true,
      };

    case ActionType.TOGGLE_SELECTED_CONTAINER: {
      return {
        ...state,
        selectedContainerIds: toggleEntries(action.payload, state.selectedContainerIds),
      };
    }

    default:
      return state;
  }
};

export type IDispatchTable = React.Dispatch<IAction>;

interface IMapTableDataOptions {
  activeRowId: string | null;
  filterText: string;
  shownColumnIds: string[];
  closedRowIds: string[];
  orderState: {
    order: IDataTableOrder;
    orderByColumnId: string;
  };
  search?: React.ReactNode;
  pagination?: React.ReactNode;
  containerRows?: IDataTableRow[];
  innerTableRows?: IDataTableRow[];
  options: IDataTableOptions;
}

function mapTableData(tableData: IMapTableDataOptions): ITableData {
  const {
    activeRowId,
    filterText,
    shownColumnIds,
    closedRowIds,
    orderState: { order, orderByColumnId },
    containerRows,
    innerTableRows,
    options,
    search,
    pagination,
  } = tableData;

  const searchWords = queryToWords(`${options.filterText || ''} ${filterText}`);

  const emptyContainersReducer = (
    acc: IDataTableRow[],
    containerRow: Readonly<IDataTableRow>,
  ): IDataTableRow[] => {
    const containerRows = containerRow.containerRows?.reduce(emptyContainersReducer, []);

    if (
      !isEmpty(containerRow.innerTableRows) ||
      !isEmpty(containerRows) ||
      searchWords?.find((searchWord) =>
        containerRow?.data?.name?.toLowerCase().includes(searchWord.toLowerCase()),
      )
    ) {
      acc.push({
        ...containerRow,
        containerRows,
      });
    }
    return acc;
  };

  const rows: IChildrenRows = {
    containerRows: options.hideEmptyContainers
      ? containerRows?.reduce(emptyContainersReducer, [])
      : containerRows,
    innerTableRows,
  };
  const openRows = filterOpenChildrenRows(rows, closedRowIds, options.alwaysOpenRowIds || []);
  const filteredRows = filterChildren(openRows, options.levels, filterText);

  const sortedRows = !isNil(options.onChangeSort)
    ? filteredRows
    : sortWithChildren(filteredRows, orderByColumnId, order);

  const innerTableOptionsWithAllColumns = options.levels[options.levels.length - 1];

  const innerTableOptions = {
    ...innerTableOptionsWithAllColumns,
    // intersect between shownColumnIds and optionsColumns | preserve tableData.shownColumnIds sort-order
    columns: intersectionWith(
      tableData.shownColumnIds,
      innerTableOptionsWithAllColumns.columns,
      (a, b) => {
        return a === b.id;
      },
    ).map(
      (columnName) =>
        innerTableOptionsWithAllColumns.columns.find((v: any) => v.id === columnName)!,
    ),
  };

  const navigation = rowNavigationChildren(sortedRows);

  // the use of isNil will allow empty Strings
  // this will allow unselected Rows/Containers per default
  // See Projects/TabMissions/MissionSelector.tsx
  const defaultActiveRowId = !isNil(options.activeRowId)
    ? options.activeRowId
    : firstRowId(navigation);

  const normalizedActiveRowId =
    activeRowId && navigation[activeRowId] ? activeRowId : defaultActiveRowId;

  const activeNavigation = navigation[normalizedActiveRowId];

  return {
    filterText,
    searchWords,
    shownColumnIds,
    order,
    orderByColumnId,
    ...rows,
    options,
    innerTableOptions,
    pageRows: sortedRows,
    navigation,
    activeNavigation,
    normalizedActiveRowId,
    search,
    pagination,
  };
}

const getShownColumnIds = (columns: IDataTableColumn[]) => {
  const hiddenColumns = columns.filter(
    (column) => !column.hideOnDefault || Boolean(column.alwaysVisible),
  );
  if (hiddenColumns.length > 0) {
    return hiddenColumns.map((c) => c.id);
  }

  return [];
};

/**
 * intercepts when a new containerRow gets added and closes all of its containerRows and itself
 * Takes action when for e.x. mission gets copied
 */
const useCloseNewContainerWithData = (
  containerRows: IDataTableRow[] = [],
  closedRowIds: string[],
  dispatch: React.Dispatch<IAction>,
) => {
  const previousContainerRows = usePrevious(containerRows);
  const previousContainerAmount = usePrevious(containerRows.length);
  const currentContainerAmount = containerRows.length;

  useEffect(() => {
    if (!previousContainerAmount || !previousContainerRows) {
      return;
    }

    // only execute logic when container got added
    if (currentContainerAmount <= previousContainerAmount) {
      return;
    }

    const newlyCreatedRow = containerRows.find(
      (containerRow) =>
        !previousContainerRows.some(
          (previousContainerRow) => containerRow.id === previousContainerRow.id,
        ),
    );
    if (!newlyCreatedRow) {
      return;
    }

    const subContainers =
      newlyCreatedRow.containerRows?.flatMap((subContainer) => [
        subContainer,
        ...(subContainer?.containerRows ?? []),
      ]) ?? [];
    // we need to also close all sub-containers
    const subContainerIdsToClose = subContainers.map((subContainer) => subContainer.id);

    dispatch({
      type: ActionType.SET_CLOSED_ROW_IDS,
      payload: [...closedRowIds, newlyCreatedRow.id, ...subContainerIdsToClose],
    });
  }, [
    previousContainerAmount,
    currentContainerAmount,
    dispatch,
    previousContainerRows,
    containerRows,
    closedRowIds,
  ]);
};

const getInitialState =
  (ref: React.MutableRefObject<HTMLElement | undefined>) =>
  (props: IDataTableProps): IState => {
    const innerTableOptionsWithAllColumns = props.options.levels[props.options.levels.length - 1];

    const shownColumnIds = getShownColumnIds(innerTableOptionsWithAllColumns.columns);

    return {
      orderState: {
        order: 'asc',
        orderByColumnId: '',
      },

      shownColumnIds,
      checkedRowIds: [],
      closedRowIds: !isEmpty(props.containerRows)
        ? defaultClosedRowIds(props.containerRows as IDataTableRow[], props.options.levels)
        : [],
      filterText: props.options.filterText || '',
      activeRowId: null,
      activeRowRef: ref,
      anchorEl: null,
      dropDownOpen: false,
      columnSelectorAnchorEl: null,
      closedRowIdsCalculatedWithActualData: !isEmpty(props.containerRows),
      selectedContainerIds: [],
    };
  };

export const useDataTable = (props: IDataTableProps) => {
  const draggingContext = useContext(DraggingContext);
  const lastActiveRowRef = useRef<string | null>('');
  const [state, dispatch] = useReducer<React.Reducer<IState, IAction>, IDataTableProps>(
    tableReducer,
    props,
    getInitialState(useRef()),
  );

  const { droppableId } = props;

  const { activeRowId, activeRowRef, shownColumnIds, checkedRowIds, closedRowIds } = state;

  /* eslint-disable react-hooks/exhaustive-deps */
  const tableData = useMemo(
    () => mapTableData({ ...props, ...state }),
    [
      state.activeRowId,
      state.filterText,
      state.shownColumnIds,
      state.closedRowIds,
      state.orderState.order,
      state.orderState.orderByColumnId,
      state.selectedContainerIds,
      props.options,
      props.search,
      props.pagination,
      props.innerTableRows,
      props.containerRows,
    ],
  );
  /* eslint-enable */

  const { normalizedActiveRowId } = tableData;

  useColumnsSetting(
    props.options.tableName,
    dispatch,
    getShownColumnIds(tableData.options.levels[tableData.options.levels.length - 1].columns),
    tableData.options.levels[tableData.options.levels.length - 1].columns
      .filter((column) => Boolean(column.alwaysVisible))
      .map(({ id }) => id),
    tableData.options.levels[tableData.options.levels.length - 1].columns
      .filter(({ fixedPosition }) => fixedPosition !== undefined)
      .map(({ id, fixedPosition }) => ({ id, index: fixedPosition! })),
  );

  useEffect(() => {
    draggingContext.registerDroppableData(droppableId, tableData);
  }, [droppableId, tableData, draggingContext]);

  useEffect(() => {
    if (props.options.onChangeCheckedRowsChange) {
      props.options.onChangeCheckedRowsChange(checkedRowIds);
    }
  }, [props.options.onChangeCheckedRowsChange, checkedRowIds, props.options]);

  useEffect(() => {
    if (!isEmpty(tableData.containerRows) && !state.closedRowIdsCalculatedWithActualData) {
      dispatch({
        type: ActionType.SET_CLOSED_ROW_IDS,
        payload: defaultClosedRowIds(
          tableData.containerRows as IDataTableRow[],
          tableData.options.levels,
        ),
      });
    }
  }, [
    tableData.containerRows,
    state.closedRowIdsCalculatedWithActualData,
    tableData.options.levels,
  ]);

  useEffect(() => {
    const rowId = activeRowId || normalizedActiveRowId;
    const onChangeActiveRow = props.options.onChangeActiveRow;

    if (lastActiveRowRef.current !== rowId) {
      if (onChangeActiveRow) {
        const activeRowPath =
          rowId && tableData.navigation[rowId]
            ? [
                tableData.navigation[rowId].row,
                ...tableData.navigation[rowId].path.map((rowId) => tableData.navigation[rowId].row),
              ]
            : [];
        onChangeActiveRow(activeRowPath);
      }

      if (
        !tableData.options.disableFocusOnActiveRowChange &&
        !isEmpty(lastActiveRowRef.current) &&
        state.activeRowRef.current &&
        !state.activeRowRef.current.contains(document.activeElement)
      ) {
        state.activeRowRef.current.focus();
      }

      lastActiveRowRef.current = rowId;
    }
  }, [
    activeRowId,
    normalizedActiveRowId,
    tableData.navigation,
    tableData.options,
    props.options.onChangeActiveRow,
    state.activeRowRef,
  ]);

  const flattenedContainerRows = useMemo(
    () => flattenContainerRows(tableData.containerRows ?? []),
    [tableData.containerRows],
  );

  useCloseNewContainerWithData(flattenedContainerRows, closedRowIds, dispatch);

  // keep object identity
  return useMemo(
    () => ({
      droppableId,
      tableData,
      shownColumnIds,
      checkedRowIds,
      closedRowIds,
      activeRowId: normalizedActiveRowId,
      activeRowRef,
      state,
      dispatch,
    }),
    [
      activeRowRef,
      checkedRowIds,
      closedRowIds,
      droppableId,
      normalizedActiveRowId,
      shownColumnIds,
      state,
      tableData,
    ],
  );
};

export const DataTableWrapper: React.FC<IDataTableProps & IRenderProps> = React.memo(
  ({ children, ...props }) => {
    const tableContext = useDataTable(props);
    return <>{isFunction(children) ? children(tableContext) : children}</>;
  },
);

export const DataTableHotKeys: React.FC<ITableContext & IRenderProps> = React.memo(
  (tableContext) => {
    const {
      children,
      dispatch,
      tableData: { activeNavigation, normalizedActiveRowId, options },
    } = tableContext;

    const additionalKeyMap = options.additionalHotKeys?.keyMap;
    const keyMap = useMemo(
      () => ({
        MOVE_UP: ['up', 'command+up'],
        MOVE_DOWN: ['down', 'command+down'],
        TOGGLE_ROW: 'alt+enter',
        ESC: 'esc',
        ...additionalKeyMap,
      }),
      [additionalKeyMap],
    );

    const additionalHandlers = options.additionalHotKeys?.hotkeyHandlers;
    const activeNavigationNext = activeNavigation?.next;
    const activeNavigationPrevious = activeNavigation?.previous;
    const activeNavigationIsContainer = activeNavigation?.isContainer;
    const hotkeysHandlers = useMemo(
      () => ({
        MOVE_UP:
          activeNavigationPrevious != null
            ? createPreventAll(() =>
                dispatch({
                  type: ActionType.SET_ACTIVE_ROW,
                  payload: { rowId: activeNavigationPrevious },
                }),
              )
            : noop,
        MOVE_DOWN:
          activeNavigationNext != null
            ? createPreventAll(() =>
                dispatch({
                  type: ActionType.SET_ACTIVE_ROW,
                  payload: { rowId: activeNavigationNext },
                }),
              )
            : noop,
        TOGGLE_ROW:
          activeNavigationIsContainer != null
            ? createPreventAll(() => {
                if (
                  activeNavigationIsContainer &&
                  (tableContext.tableData.navigation[normalizedActiveRowId].children.length > 0 ||
                    tableContext.closedRowIds.includes(normalizedActiveRowId))
                ) {
                  dispatch({
                    type: ActionType.TOGGLE_ROW,
                    payload: { rowId: normalizedActiveRowId },
                  });
                }
              })
            : noop,
        ESC: createPreventAll(() =>
          dispatch({ type: ActionType.SET_FILTER, payload: { filter: '' } }),
        ),
        ...additionalHandlers,
      }),
      [
        additionalHandlers,
        normalizedActiveRowId,
        activeNavigationIsContainer,
        activeNavigationNext,
        activeNavigationPrevious,
        tableContext.closedRowIds,
        tableContext.tableData.navigation,
        dispatch,
      ],
    );

    return (
      <HotKeys keyMap={keyMap} handlers={hotkeysHandlers}>
        {isFunction(children) ? children(tableContext) : children}
      </HotKeys>
    );
  },
);

const DataTable: React.FC<IDataTableProps> = React.memo((props) => (
  <DataTableWrapper {...props}>
    {(context) => {
      const {
        tableData: { options },
      } = context;
      return (
        <DataTableHotKeys {...context}>
          {options.isDropDown && props.search ? (
            <DataTableDropDown dataTableProps={props}>
              <DataTableBody context={context} />
            </DataTableDropDown>
          ) : (
            <DataTableBody context={context} />
          )}
        </DataTableHotKeys>
      );
    }}
  </DataTableWrapper>
));

export const SlimDataTable: React.FC<IDataTableProps> = (props) => (
  <DataTableWrapper {...props}>
    {(context) => (
      <DataTableHotKeys {...context}>{() => <DataTableBody context={context} />}</DataTableHotKeys>
    )}
  </DataTableWrapper>
);

export const DataTableHotKeysWrapper: React.FC<IDataTableProps & IRenderProps> = (props) => (
  <DataTableWrapper {...props}>
    {(context) => <DataTableHotKeys {...context}>{props.children}</DataTableHotKeys>}
  </DataTableWrapper>
);

export default DataTable;

export { DataTableBody };
