import { saveAs } from 'file-saver';
import client, { GET_AUTH } from '../services/graphql-client';

export const downloadFile = async (path: string, fileName: string) => {
  const blob = await getBlob(path);
  if (blob) {
    return saveAs(blob, fileName);
  }
};

export const getBlob = async (path: string) => {
  const result = client.readQuery({ query: GET_AUTH });

  if (result && result.auth.token) {
    const Authorization = `Bearer ${result.auth.token}`;
    const response = await fetch(`${process.env.REACT_APP_API_PREFIX}/${path}`, {
      headers: {
        Authorization,
      },
    });
    return await response.blob();
  }
};
