import { Tabs } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import React, { useCallback, useMemo } from 'react';
import { useApolloClient } from 'react-apollo';
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useProjectNumber } from '../../hooks/useProjectNumber';
import { TableType } from '../../types/graphql';
import { forceDataTableRefetch } from '../../utils/paginationHelpers/forceDataTableRefetch';
import { Addresses } from '../Address/Addresses';
import FileRepository from '../FileRepository';
import { InvoiceConditionTable } from '../InvoiceCondition/InvoiceConditionsTable';
import OfferConditionsGrid from '../OfferCondition/OfferConditionsGrid/OfferConditionsGrid';
import AppProgress from '../Page/AppProgress';
import { useBillFixed } from './AddressTabs.utils';
import { RelationEntityType } from './types';

interface IParams {
  mainTabName: string;
  mainComponent: React.FunctionComponent<any>;
  type: RelationEntityType;
}

interface IUrlParams {
  projectNumber?: string;
  id?: string;
  billId?: string;
  missionId?: string;
}

const AddressTabs: React.FC<IParams> = ({ mainTabName, mainComponent, type }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const params = useParams<IUrlParams>();
  const location = useLocation();
  const client = useApolloClient();
  const projectNumber = useProjectNumber();

  const variables: Pick<IUrlParams, 'projectNumber' | 'id'> = {
    ...(params.projectNumber && { projectNumber: params.projectNumber }),
    ...(params.id && { id: params.id }),
    ...(params.billId && { id: params.billId }),
    ...(params.missionId && { id: params.missionId }),
  };

  const isBillFixed = useBillFixed(params.billId, !params.billId);

  const showInvoiceConditionsTab = useMemo(
    () =>
      [RelationEntityType.BILL, RelationEntityType.ORDER, RelationEntityType.OFFER].includes(type),
    [type],
  );

  const showOfferConditionsTab = useMemo(
    () => ![RelationEntityType.BILL, RelationEntityType.MISSION].includes(type),
    [type],
  );

  const closeView = useCallback(() => history.goBack(), [history]);

  const handleOnClose = useCallback(async () => {
    if (!projectNumber) {
      return;
    }

    if (type === RelationEntityType.PROJECT) {
      return closeView();
    }

    forceDataTableRefetch(TableType[type], client);

    // also clear all mission-related dataTables
    // because it uses the invoiceConditions of boqs to calculate price
    if (type === RelationEntityType.ORDER && variables.id) {
      forceDataTableRefetch(TableType.MISSION, client);
      forceDataTableRefetch(TableType.MEASUREMENT, client);
      forceDataTableRefetch(TableType.BILL_MISSION, client);
      forceDataTableRefetch(TableType.ORDER_MISSION, client);
      forceDataTableRefetch(TableType.ORDER_MEASUREMENT, client);
    }

    closeView();
  }, [closeView, type, projectNumber, variables.id, client]);

  if (isBillFixed === null) {
    return <AppProgress />;
  }

  const navigate = (e: React.ChangeEvent<any>, path: string) => history.replace(path);

  const baseLink = match.path.substr(0, match.path.lastIndexOf('/'));
  // change regex when you add new tabs
  const baseLinkWParams = match.url.replace(
    /((\/adressen)|(\/editieren)|(\/rechnungs_konditionen)|(\/angebotsbedingungen)|(\/dateiablage))(($)|(\/.*))/,
    '',
  );

  return (
    <>
      <Paper>
        <Tabs value={location.pathname} onChange={navigate}>
          <Tab label={mainTabName} value={`${baseLinkWParams}/editieren`} />
          <Tab label="Adressbuch" value={`${baseLinkWParams}/adressen`} />
          <Tab label="Dateiablage" value={`${baseLinkWParams}/dateiablage`} />
          {variables.id != null && showOfferConditionsTab && (
            <Tab label="Angebotsbedingungen" value={`${baseLinkWParams}/angebotsbedingungen`} />
          )}
          {showInvoiceConditionsTab && (
            <Tab label="Rechnungskonditionen" value={`${baseLinkWParams}/rechnungs_konditionen`} />
          )}
        </Tabs>
      </Paper>
      <Switch>
        <Route path={`${baseLink}/editieren`} component={mainComponent} />
        <Route path={`${baseLink}/adressen`}>
          <Addresses />
        </Route>
        <Route path={`${baseLink}/dateiablage`}>
          <FileRepository projectNumber={projectNumber ?? ''} />
        </Route>
        {variables.id != null && showOfferConditionsTab && (
          <Route path={`${baseLink}/angebotsbedingungen`}>
            <OfferConditionsGrid billOfQuantityId={variables.id} onClose={closeView} />
          </Route>
        )}
        {showInvoiceConditionsTab && (
          <Route path={`${baseLink}/rechnungs_konditionen`}>
            <InvoiceConditionTable
              isNotInteractive={isBillFixed}
              entityType={type}
              onClose={handleOnClose}
            />
          </Route>
        )}
      </Switch>
    </>
  );
};

export default AddressTabs;
