import React from 'react';
import { Field } from 'formik';
import FormikTextField from '../../Form/FormikTextField';
import { IStrategyProps } from './MissionStatus';

const CalendarWeekStrategy: React.FC<IStrategyProps> = ({ disabled }) => (
  <Field
    name="status.calendarWeek"
    label="Kalenderwoche"
    component={FormikTextField}
    type="number"
    min="1"
    max="52"
    disabled={disabled}
  />
);

export default CalendarWeekStrategy;
