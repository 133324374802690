import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

// file-document-edit-outline.svg
const OfferConditionIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d={
        'M 8 12 H 16 V 14 H 8 V 12 M 10 20 H 6 V 4 H 13 V 9 H 18 V 12.1 L 20 10.1 V 8 L 14 2 H 6 A 2 2 0 0 0 4 4 V 20 A 2 2 0 0 0 6 22 H 10 V 20 M 8 18 H 12.1 L 13 17.1 V 16 H 8 V 18 M 20.2 13 C 20.3 13 20.5 13.1 20.6 13.2 L 21.9 14.5 C 22.1 14.7 22.1 15.1 21.9 15.3 L 20.9 16.3 L 18.8 14.2 L 19.8 13.2 C 19.9 13.1 20 13 20.2 13 M 20.2 16.9 L 14.1 23 H 12 V 20.9 L 18.1 14.8 L 20.2 16.9 Z'
      }
    />
  </SvgIcon>
);

export default OfferConditionIcon;
