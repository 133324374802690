import React, { useCallback, useState } from 'react';
import {
  Typography,
  ExpansionPanelSummary as AccordionSummary,
  ExpansionPanelDetails as AccordionDetails,
  ExpansionPanel as Accordion,
  Avatar,
  makeStyles,
  Theme,
  Grid,
  IconButton,
  RootRef,
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { upperFirst } from 'lodash';
import { GetProjectStorageRepository_projectStorageRepository_folders } from './types/GetProjectStorageRepository';
import FileComponent from './FileComponent';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-apollo';
import { GET_PROJECT_REPOSITORY_QUERY, UPLOAD_FILES_TO_PROJECT_REPO } from './queries';
import {
  UploadFilesToProjectRepo,
  UploadFilesToProjectRepoVariables,
} from './types/UploadFilesToProjectRepo';
import UploadDialog from './UploadDialog';
import { Draggable } from 'react-beautiful-dnd';

interface Props {
  folder: GetProjectStorageRepository_projectStorageRepository_folders;
  projectNumber: string;
  deletedFiles: string[];
  setDeletedFiles: React.Dispatch<React.SetStateAction<string[]>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  hederText: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
  },
  uploadButton: {
    marginRight: theme.spacing(1.5),
    marginLeft: 'auto',
  },
}));

const Folder: React.FC<Props> = ({ folder, projectNumber, deletedFiles, setDeletedFiles }) => {
  const classes = useStyles();

  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);

  const [uploadFiles] = useMutation<UploadFilesToProjectRepo, UploadFilesToProjectRepoVariables>(
    UPLOAD_FILES_TO_PROJECT_REPO,
    {
      refetchQueries: [{ query: GET_PROJECT_REPOSITORY_QUERY, variables: { projectNumber } }],
    },
  );

  const onDelete = useCallback(
    (documentId: string) => {
      setDeletedFiles((state) => {
        return [...state, documentId];
      });
    },
    [setDeletedFiles],
  );

  const handleUpload = useCallback(async () => {
    await uploadFiles({
      variables: {
        files: acceptedFiles,
        repo: {
          folder: folder.category,
          project: { projectNumber },
        },
      },
    });

    setAcceptedFiles([]);
  }, [acceptedFiles, folder.category, projectNumber, uploadFiles]);

  const onDrop = useCallback(async (files) => {
    setAcceptedFiles(files);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    multiple: true,
  });

  const { ref, ...rootProps } = getRootProps();

  const folderName = upperFirst(folder.category.toLocaleLowerCase());

  return (
    <>
      <UploadDialog
        open={acceptedFiles.length > 0}
        files={acceptedFiles}
        setAcceptedFiles={setAcceptedFiles}
        folderName={folderName}
        onSubmit={handleUpload}
      />
      <input {...getInputProps()} />

      <RootRef rootRef={ref}>
        <Accordion {...(rootProps as any)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Avatar>
              <FolderIcon />
            </Avatar>
            <Typography className={classes.hederText}>{folderName}</Typography>
            <IconButton className={classes.uploadButton} onClick={open}>
              <CloudUploadIcon />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {folder.documents.map((doc, idx) => {
                return (
                  <Grid item key={doc.id}>
                    <Draggable draggableId={doc.id} index={idx} type="PERSON">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FileComponent
                            disabled={deletedFiles.includes(doc.id)}
                            onDelete={onDelete}
                            file={doc}
                          />
                        </div>
                      )}
                    </Draggable>
                  </Grid>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </RootRef>
    </>
  );
};

export default Folder;
