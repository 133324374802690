import React, { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { Box, Grid, MenuItem } from '@material-ui/core';
import FormikTextField from '../Form/FormikTextField';
import FormikSelect from '../Form/FormikSelect';
import { Autocomplete } from '../Autocomplete';
import Dropezone from '../Form/UploadField/Dropzone';
import { FormikSwitch } from '../Form/FormikSwitch';
import { GET_ALL_USER_NAMES } from '../ProjectDetailsForm/ProjectDetailsForm.common';
import { useQuery } from 'react-apollo';
import { GET_ALL_EMPLOYES } from './EmployeeForm.queries';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import * as Yup from 'yup';
import defaultNilInitialValues from '../../utils/form/initialValuesDefaults';
import { getChangedFormValues } from '../../utils/form/getChanged';
import { getAllEmployees, getAllEmployees_employees } from './types/getAllEmployees';
import HistorizedField from '../Form/HistorizedField';
import { mapHistoryItem } from '../History/history.utils';
import { EMPLOYEE_FUNCTIONS_SELECT, GET_EMPLOYEE } from '../../pages/Employees/employee.queries';
import {
  EmployeeFunctionsSelect,
  EmployeeFunctionsSelect_employeeFunctions,
} from '../../pages/Employees/types/EmployeeFunctionsSelect';
import SubsidiarySelect from '../../pages/Projects/TabDetails/SubsidiarySelect';
import { setFalseyValuesToNull } from '../../utils/form/setFalseyToNull';
import { EditableHistoryItemFieldType, Role } from '../../types/graphql';
import {
  getAllUsersNames,
  getAllUsersNamesVariables,
} from '../ProjectDetailsForm/types/getAllUsersNames';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import { getToken } from '../../utils/getToken';
import { useAusmassDigitalLink } from '../../utils/useAusmassDigitalLink';

interface IProps {
  initialValues?: Record<string, any>;
  onSubmit: (values: any, setSubmitting: (isSubmitting: boolean) => void) => void;
}

type EmployeeMap = Array<{ id: string; employeeFullName: string }>;
type FunctionMap = EmployeeFunctionsSelect_employeeFunctions[];

interface IEmployeesSuggestionsMemo {
  suggestions: string[];
  employeeMap: EmployeeMap;
}

const validationSchema = Yup.object().shape({
  employeeNumber: Yup.string().required('Pflichtfeld!'),
  firstName: Yup.string().required('Pflichtfeld!'),
  lastName: Yup.string().required('Pflichtfeld!'),
  workload: Yup.number().min(0, 'Muss positiv sein!').required('Pflichtfeld!'),
  entryDate: Yup.string().required('Pflichtfeld!'),
  subsidiary: Yup.string().required('Pflichtfeld!'),
});

const mapToApiData = (
  values: any,
  initialValues: any,
  employeeMap: EmployeeMap,
  functionMap: FunctionMap,
) => {
  const apiData = setFalseyValuesToNull(
    getChangedFormValues({ initialValues, values, ignoreKeys: ['photo'] }),
    ['user', 'supervisor'],
  );

  if (apiData.user) {
    apiData.user = { name: apiData.user };
  }

  if (apiData.function) {
    const fn = functionMap.find((fn) => fn.name === apiData.function);

    apiData.function = { id: fn!.id };
  }

  if (apiData.supervisor) {
    const supervisor = employeeMap.find((employee) => {
      return employee.employeeFullName === apiData.supervisor;
    });

    apiData.supervisor = {
      id: supervisor?.id,
    };
  }

  if (apiData.subsidiary) {
    apiData.subsidiary = {
      name: apiData.subsidiary,
    };
  }

  return apiData;
};

const getInitialValues = ({ ...employee }: any) => {
  if (employee) {
    if (employee.supervisor) {
      employee.supervisor = `${employee.supervisor.firstName} ${employee.supervisor.lastName}`;
    }

    if (employee.user) {
      employee.user = employee.user.name;
    }

    if (employee.function) {
      employee.function = employee.function.name;
    }

    if (employee.subsidiary) {
      employee.subsidiary = employee.subsidiary.name;
    }
  }

  return {
    ...defaultNilInitialValues(employee, {
      firstName: '',
      lastName: '',
      employeeNumber: '',
      user: '',
      workload: '',
      supervisor: '',
      entryDate: '',
      leaveDate: '',
      function: '',
      subsidiary: '',
      isDeactivated: false,
    }),
    photo: { uploads: [], deletedFiles: [] },
  };
};

const EmployeeForm: React.FC<IProps> = ({ initialValues, onSubmit }) => {
  const user = useLoggedInUser();

  const { data: usersData, error: usersError } = useQuery<
    getAllUsersNames,
    getAllUsersNamesVariables
  >(GET_ALL_USER_NAMES, {
    variables: {
      where: { archived: false },
    },
  });

  const { data: employeeData, error: errorData } = useQuery<getAllEmployees, null>(
    GET_ALL_EMPLOYES,
  );

  const { data: employeeFunctionsData } = useQuery<EmployeeFunctionsSelect, null>(
    EMPLOYEE_FUNCTIONS_SELECT,
  );

  const userSuggestions = useMemo<string[]>(
    () => (usersData && usersData.users ? usersData.users.map((user: any) => user.name) : []),
    [usersData],
  );

  const employeesSuggestions = useMemo<IEmployeesSuggestionsMemo>(() => {
    const employeesSuggestionsMemo: any = {
      suggestions: [],
      employeeMap: [],
    };

    if (employeeData && employeeData.employees) {
      employeeData.employees.forEach((employee: getAllEmployees_employees) => {
        const employeeFullName = `${employee.firstName} ${employee.lastName}`;
        employeesSuggestionsMemo.suggestions.push(employeeFullName);

        employeesSuggestionsMemo.employeeMap.push({ id: employee.id, employeeFullName });
      });
    }

    return employeesSuggestionsMemo;
  }, [employeeData]);

  const ausmassDigitalLink = useAusmassDigitalLink();

  if (usersError) {
    console.log(usersError);
    return null;
  }

  if (errorData) {
    console.log(errorData);
    return null;
  }

  const isEdit = Boolean(initialValues);

  const formInitialValues = getInitialValues(initialValues);

  const isFormDisabled = user?.role !== Role.SUPER_ADMIN;

  if (ausmassDigitalLink === '') return null;

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(
            mapToApiData(
              values,
              formInitialValues,
              employeesSuggestions.employeeMap,
              employeeFunctionsData?.employeeFunctions ?? [],
            ),
            setSubmitting,
          );
        }}
      >
        {({ isSubmitting, dirty }) => {
          return (
            <Form>
              <Grid container spacing={5}>
                <Grid md={6} lg={3} item>
                  <Field
                    label="Mitarbeiternummer *"
                    name="employeeNumber"
                    component={FormikTextField}
                    disabled={isFormDisabled}
                  />
                  <Field
                    disabled={isFormDisabled}
                    label="Vorname *"
                    name="firstName"
                    component={FormikTextField}
                  />
                  <Field
                    disabled={isFormDisabled}
                    label="Nachname *"
                    name="lastName"
                    component={FormikTextField}
                  />
                  <HistorizedField
                    disabled={isFormDisabled}
                    name="function"
                    label="Funktion *"
                    fieldType={EditableHistoryItemFieldType.EMPLOYEE_FUNCTION}
                    entityId={initialValues?.id}
                    refetchQueries={[
                      { query: GET_EMPLOYEE, variables: { where: { id: initialValues?.id } } },
                    ]}
                    isEditable={!!initialValues}
                    component={FormikSelect}
                    history={initialValues ? mapHistoryItem(initialValues.functionHistory) : []}
                  >
                    {(employeeFunctionsData?.employeeFunctions || []).map((employeeFunction) => {
                      return (
                        <MenuItem key={employeeFunction.id} value={employeeFunction.name}>
                          {employeeFunction.name}
                        </MenuItem>
                      );
                    })}
                  </HistorizedField>
                </Grid>
                <Grid md={6} lg={3} item>
                  <SubsidiarySelect
                    disabled={isFormDisabled}
                    fieldType={EditableHistoryItemFieldType.EMPLOYEE_SUBSIDIARY}
                    entityId={initialValues?.id}
                    name="subsidiary"
                    isEditable={!!initialValues}
                    refetchQueries={[
                      { query: GET_EMPLOYEE, variables: { where: { id: initialValues?.id } } },
                    ]}
                    history={initialValues ? mapHistoryItem(initialValues.subsidiaryHistory) : []}
                  />
                  <HistorizedField
                    disabled={isFormDisabled}
                    label="Arbeitspensum *"
                    name="workload"
                    type="number"
                    component={FormikTextField}
                    history={initialValues ? mapHistoryItem(initialValues.workloadHistory) : []}
                  />
                  <Field
                    disabled={isFormDisabled}
                    name="supervisor"
                    label="Vorgesetzter"
                    minLengthToTrigger={3}
                    suggestions={employeesSuggestions.suggestions}
                    size="normal"
                    component={Autocomplete}
                    validate={(value: any) => {
                      if (value && !employeesSuggestions.suggestions.includes(value)) {
                        return 'Verantwortlicher existiert nicht!';
                      }
                    }}
                  />
                  <Field
                    disabled={isFormDisabled}
                    name="user"
                    label="User"
                    minLengthToTrigger={3}
                    suggestions={userSuggestions}
                    size="normal"
                    component={Autocomplete}
                    validate={(value: any) => {
                      if (value && !userSuggestions.includes(value)) {
                        return 'User existiert nicht!';
                      }
                    }}
                  />
                </Grid>

                <Grid md={6} lg={3} item>
                  <HistorizedField
                    disabled={isFormDisabled}
                    label="Eintrittsdatum *"
                    name="entryDate"
                    type="date"
                    shrink
                    component={FormikTextField}
                    history={initialValues ? mapHistoryItem(initialValues.entryDateHistory) : []}
                  />
                  <HistorizedField
                    disabled={isFormDisabled}
                    label="Austrittsdatum"
                    name="leaveDate"
                    shrink
                    type="date"
                    component={FormikTextField}
                    history={initialValues ? mapHistoryItem(initialValues.leaveDateHistory) : []}
                  />

                  {isEdit && (
                    <Box marginTop={4}>
                      <Field
                        disabled={isFormDisabled}
                        component={FormikSwitch}
                        name="isDeactivated"
                        color="primary"
                        label="Inaktiv"
                      />
                    </Box>
                  )}
                  <Box marginTop={2}>
                    <Field
                      disabled={isFormDisabled}
                      label="Foto"
                      name="photo"
                      single
                      component={Dropezone}
                      initialFiles={
                        initialValues && initialValues.photo ? [initialValues.photo] : []
                      }
                    />
                  </Box>
                </Grid>
                <Grid item container justify="flex-end" md={12}>
                  <CancelSaveButtons isDisabled={isSubmitting || !dirty || isFormDisabled} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {initialValues?.id && (
        <iframe
          title="Standardmitarbeiter"
          src={`${ausmassDigitalLink}/mitarbeiter/${
            initialValues?.id
          }?layout=no-layout&momoAuth=${getToken()}`}
          style={{
            width: '100%',
            height: '500px',
            border: 'none',
            background: '#fafafa',
          }}
        />
      )}
    </>
  );
};

export default EmployeeForm;
