import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, RootRef } from '@material-ui/core';
import gql from 'graphql-tag';
import DataTable, { ActionType, IAction } from '../DataTable';
import { IDataTableRow } from '../DataTable/types';
import { pick, isString, isEmpty } from 'lodash';
import { createSwissCurrencyFormatter } from '../../utils/createCurrencyFormatter';
import { useQuery } from 'react-apollo';
import {
  GetItemBoilerplateSuggestions,
  GetItemBoilerplateSuggestionsVariables,
} from './types/GetItemBoilerplateSuggestions';
import createPreventAll from '../../utils/createPreventEventDefault';

const useStyles = makeStyles({
  autocompleteWrapper: {
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 10000,
  },
});

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const columns = [
  { id: 'acronym', label: 'Kurzbezeichnung' },
  { id: 'productNumber', label: 'Produktnummer' },
  { id: 'catalogSection', label: 'Hierarchienr 1' },
  { id: 'catalogSubsection', label: 'Hierarchienr 2' },
  { id: 'catalogMainGroup', label: 'Hierarchienr 3' },
  { id: 'catalogSectionDescription', label: 'Hierarchie 1' },
  { id: 'catalogSubsectionDescription', label: 'Hierarchie 2' },
  { id: 'catalogMainGroupDescription', label: 'Hierarchie 3' },
  { id: 'descriptionOne', label: 'Beschreibung 1' },
  { id: 'descriptionTwo', label: 'Beschreibung 2' },
  { id: 'unit', label: 'Einheit' },
  {
    id: 'basePrice',
    label: 'Preis',
    render: (basePrice: any) => basePrice && formatCurrency(basePrice),
  },
  { id: 'category', label: 'Kategorie' },
  { id: 'markingStyle', label: 'Typ' },
  { id: 'color', label: 'Farbe' },
  { id: 'dimensionOne', label: 'Dimension 1' },
  { id: 'dimensionTwo', label: 'Dimension 2' },
  { id: 'reflexion', label: 'Reflektion' },
  { id: 'material', label: 'Verbrauchsmaterial' },
  { id: 'targetConsumptionPerUnit', label: 'Sollverbrauch EinheitMat/Einheitpos' },
  { id: 'timeRequired', label: 'Zeitbedarf min/einheit' },
];

const FILTER_FIELDS = columns.reduce((filterIds: string[], column: any) => {
  const excludedColumns = ['basePrice'];

  if (!excludedColumns.includes(column.id)) {
    filterIds.push(column.id);
  }

  return filterIds;
}, []);

const GET_ITEM_BOILERPLATES = gql`
  query GetItemBoilerplateSuggestions($where: ItemBoilerplateWhereInput!) {
    itemBoilerplates(where: $where, page: { amountPerPage: 5, pageNumber: 0 }) {
      id
      predefinedVolume
      predefinedPricePerUnit
      basePrice
      ${FILTER_FIELDS.join()}
    }
  }
`;

const toTableElement = (element: { id: string }) => ({ id: element.id, data: element });

const createWhereClause = (filter: { [key: string]: string }, catalogId?: string) => {
  if (filter.acronym && filter.acronym !== '') {
    return { acronym_contains: filter.acronym };
  }

  const where: any = Object.entries(pick(filter, FILTER_FIELDS))
    .filter(([, value]) => value)
    .reduce((where, [key, value]) => ({ ...where, [`${key}_contains`]: value }), {});

  if (catalogId) {
    where.catalog = { id: catalogId };
  }

  return where;
};

interface IProps {
  filter: { [key: string]: string };
  defaultCatalogId?: string;
  onChange: (row: any) => void;
  onSubmit: (row: any) => void;
  activeColumns: string[];
  innerRef: React.MutableRefObject<HTMLElement | null>;
}

const ItemBoilerplateSelect: React.FunctionComponent<IProps> = ({
  filter,
  defaultCatalogId,
  onChange,
  onSubmit,
  innerRef,
  activeColumns,
}) => {
  const classes = useStyles();
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [isHidden, setHidden] = useState<any>(true);

  const handleActiveRowChange = ([row]: IDataTableRow[]) => setCurrentRow(row.data);
  const handleOnRowClick = (row: any, e?: any, dispatch?: (value: IAction) => void) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (currentRow.id === row.id) {
      return;
    }
    setCurrentRow(row);
    onSubmit(row.data);
    dispatch?.({
      type: ActionType.SET_ACTIVE_ROW,
      payload: { rowId: row.id },
    });
  };

  const where = createWhereClause(filter, defaultCatalogId);

  useEffect(() => {
    if (!isHidden) {
      onChange(currentRow);
    }
  }, [onChange, currentRow, isHidden]);

  useEffect(() => {
    const whereIsEmpty = !Object.values(where).some((v) => isString(v) && v !== '');

    if (whereIsEmpty) {
      if (!isHidden) {
        setHidden(true);
      }
      return;
    }

    if (isHidden && currentRow) {
      setHidden(false);
    }
  }, [currentRow, innerRef, isHidden, where]);

  useEffect(() => {
    innerRef.current?.scrollIntoView(false);
  }, [innerRef, isHidden]);

  const { data, error } = useQuery<
    GetItemBoilerplateSuggestions,
    GetItemBoilerplateSuggestionsVariables
  >(GET_ITEM_BOILERPLATES, { skip: isEmpty(where), variables: { where } });

  useEffect(() => {
    if (!data || !data.itemBoilerplates || data.itemBoilerplates.length === 0 || isHidden) {
      return;
    }

    setCurrentRow(data.itemBoilerplates[0]);
  }, [data, isHidden]);

  if (error) {
    console.log(error);

    return null;
  }

  return (
    <Paper className={classes.autocompleteWrapper} hidden={isHidden} tabIndex={10000}>
      <RootRef rootRef={innerRef}>
        <DataTable
          innerTableRows={data?.itemBoilerplates?.map(toTableElement) ?? []}
          options={{
            onChangeActiveRow: handleActiveRowChange,
            hideInterface: true,
            activeRowId: currentRow ? currentRow.id : null,
            disableFocusOnActiveRowChange: true,
            levels: [
              {
                columns: columns.filter(({ id }) => ['basePrice', ...activeColumns].includes(id)),
                onRowClick: handleOnRowClick,
              },
            ],
            additionalHotKeys: {
              keyMap: { TAB: 'tab' },
              hotkeyHandlers: {
                TAB: createPreventAll(() => handleOnRowClick(currentRow)),
              },
            },
          }}
        />
      </RootRef>
    </Paper>
  );
};

export default ItemBoilerplateSelect;
