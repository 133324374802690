import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

const PROJECTS_COUNT_QUERY = gql`
  query ProjectsCount(
    $where: ProjectWhereInput
    $orderBy: OrderByInput
    $page: PageInput
    $search: SearchInput
  ) {
    projectsCount(where: $where, orderBy: $orderBy, page: $page, search: $search)
  }
`;

const BILL_OF_QUANTITIES_COUNT_QUERY = gql`
  query BillOfQuantitiesCount(
    $where: BillOfQuantityWhereInput
    $search: SearchInput
    $page: PageInput
  ) {
    billOfQuantitiesCount(where: $where, search: $search, page: $page)
  }
`;

const MISSIONS_COUNT_QUERY = gql`
  query MissionsCount(
    $where: MissionWhereInput
    $page: PageInput
    $search: SearchInput
    $orderBy: OrderByInput
  ) {
    missionsCount(where: $where, page: $page, search: $search, orderBy: $orderBy)
  }
`;

const BILLS_COUNT_QUERY = gql`
  query BillsCount($where: BillWhereInput, $page: PageInput, $search: SearchInput) {
    billsCount(where: $where, page: $page, search: $search)
  }
`;

const MACHINES_COUNT_QUERY = gql`
  query MachinesCount($where: MachineWhereInput, $page: PageInput, $search: SearchInput) {
    machinesCount(where: $where, page: $page, search: $search)
  }
`;

const EMPLOYEES_COUNT_QUERY = gql`
  query EmployeesCount($where: EmployeeWhereInput, $search: SearchInput, $page: PageInput) {
    employeesCount(where: $where, search: $search, page: $page)
  }
`;

const MATERIALS_COUNT_QUERY = gql`
  query MaterialsCount($search: SearchInput, $page: PageInput) {
    materialsCount(search: $search, page: $page)
  }
`;

const VEHICLES_COUNT_QUERY = gql`
  query VehiclesCount($where: VehicleWhereInput, $search: SearchInput, $page: PageInput) {
    vehiclesCount(where: $where, search: $search, page: $page)
  }
`;

const CATALOGS_COUNT_QUERY = gql`
  query CatalogsCount($search: SearchInput, $page: PageInput) {
    catalogsCount(search: $search, page: $page)
  }
`;

const ITEMBOILERPLATE_GROUPS_COUNT_QUERY = gql`
  query ItemBoilerplateGroupsCount($search: SearchInput, $page: PageInput) {
    itemBoilerplateGroupsCount(search: $search, page: $page)
  }
`;

const ITEMBOILERPLATES_COUNT_QUERY = gql`
  query ItemBoilerplatesCount(
    $where: ItemBoilerplateWhereInput
    $page: PageInput
    $search: SearchInput
  ) {
    itemBoilerplatesCount(where: $where, page: $page, search: $search)
  }
`;

const USERS_COUNT_QUERY = gql`
  query UsersCount($where: UserWhereInput, $search: SearchInput, $page: PageInput) {
    usersCount(where: $where, search: $search, page: $page)
  }
`;

export enum CountEntity {
  PROJECT = 'PROJECT',
  BILL_OF_QUANTITY = 'BILL_OF_QUANTITY',
  MISSION = 'MISSION',
  BILL = 'BILL',
  MACHINE = 'MACHINE',
  EMPLOYEE = 'EMPLOYEE',
  MATERIAL = 'MATERIAL',
  VEHICLE = 'VEHICLE',
  CATALOG = 'CATALOG',
  ITEMBOILERPLATE_GROUP = 'ITEMBOILERPLATE_GROUP',
  ITEMBOILERPLATE = 'ITEMBOILERPLATE',
  USER = 'USER',
}

export const USE_COUNT_QUERIES: Record<keyof typeof CountEntity, DocumentNode> = {
  PROJECT: PROJECTS_COUNT_QUERY,
  BILL_OF_QUANTITY: BILL_OF_QUANTITIES_COUNT_QUERY,
  MISSION: MISSIONS_COUNT_QUERY,
  BILL: BILLS_COUNT_QUERY,
  MACHINE: MACHINES_COUNT_QUERY,
  EMPLOYEE: EMPLOYEES_COUNT_QUERY,
  MATERIAL: MATERIALS_COUNT_QUERY,
  VEHICLE: VEHICLES_COUNT_QUERY,
  CATALOG: CATALOGS_COUNT_QUERY,
  ITEMBOILERPLATE_GROUP: ITEMBOILERPLATE_GROUPS_COUNT_QUERY,
  ITEMBOILERPLATE: ITEMBOILERPLATES_COUNT_QUERY,
  USER: USERS_COUNT_QUERY,
};
