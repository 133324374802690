import { Grid } from '@material-ui/core';
import { FastField, useFormik as useFormikReal } from 'formik';
import { debounce, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useMount } from 'react-use';
import { Noop } from '../../../../../types';
import { getChangedFormValues } from '../../../../../utils/form/getChanged';
import FormikTextField from '../../../../Form/FormikTextField';
import { AddressSearchUseFetchResult } from '../../fetch';
import {
  ADDRESS_COLUMNS,
  ADDRESS_COLUMN_LABEL,
  DATA_TABLE_WRAPPER_ID,
  FORM_INITIAL_VALUES,
} from '../constants';
import { WidthMapping } from '../types';

type ShortNameFieldRef = React.MutableRefObject<HTMLInputElement | null>;
interface IUseSearchFieldsOpts {
  fieldWidthMapping: WidthMapping;
  shortNameFieldRef: ShortNameFieldRef;
  inputRootClass: string;
  onChange: Noop;
}

type UseFormikResult = ReturnType<typeof useFormik>;
const useFormik = (refetch: AddressSearchUseFetchResult['refetch']) => {
  return useFormikReal({
    initialValues: FORM_INITIAL_VALUES,
    onSubmit: (values) => {
      const changedValues = getChangedFormValues({
        initialValues: FORM_INITIAL_VALUES,
        values,
      });

      return refetch({ first: 50, search: changedValues });
    },
  });
};

const useHandleOnChange = (formik: UseFormikResult) => {
  const debouncedOnChange = useMemo(
    () => debounce(() => formik.submitForm(), 500),
    [formik.submitForm], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return useCallback(() => {
    debouncedOnChange();
  }, [debouncedOnChange]);
};

const useSetFieldWidths = (
  setFieldWidthMapping: React.Dispatch<React.SetStateAction<WidthMapping>>,
) => {
  useMount(() => {
    setTimeout(() => {
      const tableHeaders = Array.from(
        document.getElementById(DATA_TABLE_WRAPPER_ID)?.getElementsByTagName('th') ?? [],
      ).slice(1);

      const newFieldWidthMapping: any = {};
      tableHeaders.forEach((tableHeader, idx) => {
        newFieldWidthMapping[ADDRESS_COLUMNS[idx]] = tableHeader.clientWidth;
      });
      setFieldWidthMapping(newFieldWidthMapping);
    }, 300);
  });
};

const useFocusShortName = (
  isSurroundingModalOpen: boolean | undefined,
  shortNameFieldRef: ShortNameFieldRef,
) => {
  useEffect(() => {
    if (isSurroundingModalOpen) {
      shortNameFieldRef.current?.focus({ preventScroll: true });
    }
  }, [isSurroundingModalOpen, shortNameFieldRef]);
};

const useResetForm = (isSurroundingModalOpen: boolean | undefined, formik: UseFormikResult) => {
  useEffect(() => {
    if (!isSurroundingModalOpen && !isEqual(formik.values, formik.initialValues)) {
      formik.resetForm();
    }
  }, [formik, isSurroundingModalOpen]);
};

const useSearchFields = ({
  fieldWidthMapping,
  shortNameFieldRef,
  inputRootClass,
  onChange,
}: IUseSearchFieldsOpts) => {
  return useMemo(
    () =>
      ADDRESS_COLUMNS.map((column, idx) => (
        <Grid item style={{ width: fieldWidthMapping[column] }} key={column}>
          <FastField
            fullWidth
            noDisableOnSubmitting
            component={FormikTextField}
            name={column}
            inputRef={idx === 0 ? shortNameFieldRef : undefined}
            variant="outlined"
            label={ADDRESS_COLUMN_LABEL[column]}
            inputProps={{ className: inputRootClass }}
            onChange={onChange}
          />
        </Grid>
      )),
    [fieldWidthMapping, shortNameFieldRef, inputRootClass, onChange],
  );
};

export const ADDRESS_SEARCH_FORM_UTILS = {
  useFormik,
  useHandleOnChange,
  useSetFieldWidths,
  useFocusShortName,
  useResetForm,
  useSearchFields,
};
