import { useQuery } from 'react-apollo';
import { BillOfQuantitiesCountVariables } from './types/BillOfQuantitiesCount';
import { BillsCountVariables } from './types/BillsCount';
import { CatalogsCountVariables } from './types/CatalogsCount';
import { EmployeesCountVariables } from './types/EmployeesCount';
import { ItemBoilerplateGroupsCountVariables } from './types/ItemBoilerplateGroupsCount';
import { ItemBoilerplatesCountVariables } from './types/ItemBoilerplatesCount';
import { MachinesCountVariables } from './types/MachinesCount';
import { MaterialsCountVariables } from './types/MaterialsCount';
import { MissionsCountVariables } from './types/MissionsCount';
import { ProjectsCountVariables } from './types/ProjectsCount';
import { UsersCountVariables } from './types/UsersCount';
import { VehiclesCountVariables } from './types/VehiclesCount';
import { CountEntity, USE_COUNT_QUERIES } from './useCount.queries';

type UseCountOpts =
  | {
      entity: CountEntity.PROJECT;
      variables: ProjectsCountVariables;
    }
  | {
      entity: CountEntity.BILL_OF_QUANTITY;
      variables: BillOfQuantitiesCountVariables;
    }
  | {
      entity: CountEntity.MISSION;
      variables: MissionsCountVariables;
    }
  | {
      entity: CountEntity.BILL;
      variables: BillsCountVariables;
    }
  | {
      entity: CountEntity.MACHINE;
      variables: MachinesCountVariables;
    }
  | {
      entity: CountEntity.EMPLOYEE;
      variables: EmployeesCountVariables;
    }
  | {
      entity: CountEntity.MATERIAL;
      variables: MaterialsCountVariables;
    }
  | {
      entity: CountEntity.VEHICLE;
      variables: VehiclesCountVariables;
    }
  | {
      entity: CountEntity.CATALOG;
      variables: CatalogsCountVariables;
    }
  | {
      entity: CountEntity.ITEMBOILERPLATE_GROUP;
      variables: ItemBoilerplateGroupsCountVariables;
    }
  | {
      entity: CountEntity.ITEMBOILERPLATE;
      variables: ItemBoilerplatesCountVariables;
    }
  | {
      entity: CountEntity.USER;
      variables: UsersCountVariables;
    };

export const useCount = (opts: UseCountOpts) => {
  const documentNode = USE_COUNT_QUERIES[opts.entity];

  const { data, error, refetch } = useQuery(documentNode, {
    variables: opts.variables,
  });

  if (!data) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  return { totalNumberOfRows: Object.values<number>(data)[0], refetch, variables: opts.variables };
};
