/**
 * checks a reduced set of props to optimize rerender of formik fast fields
 * @props nextProps (react) next properties of the field
 * @props props (react) current properties
 */
export const shouldUpdate = (nextProps: any, props: any) => {
  return (
    nextProps.value !== props.value ||
    nextProps.disabled !== props.disabled ||
    nextProps.suggestions !== props.suggestions ||
    nextProps.last !== props.last ||
    nextProps.validate !== props.validate ||
    nextProps.next !== props.next ||
    nextProps.name !== props.name ||
    nextProps.autoFocus !== props.autoFocus
  );
};
