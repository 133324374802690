import { IDataTableRow } from '../types';

const maxInnerTableLevel = (rows: IDataTableRow[]): number =>
  rows.length > 0 ? Math.max(...innerTableLevels(rows)) : 0;

const innerTableLevels = (rows: IDataTableRow[]): number[] =>
  rows.map((row) => 1 + maxInnerTableLevel(row.innerTableRows || []));

/**
 * Returns the maximum level (row children) for an inner table
 */
export default maxInnerTableLevel;
