import React from 'react';
import { Fab, makeStyles, Theme } from '@material-ui/core';
import { FabProps } from '@material-ui/core/Fab';

export interface ISpeedDialActionProps {
  disabled?: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  color?: FabProps['color'] | 'error';
}

const useStyles = makeStyles((theme: Theme) => ({
  fabRootError: {
    backgroundColor: theme.palette.error.main,
  },
}));

export const SpeedDialAction: React.FC<ISpeedDialActionProps> = ({
  disabled,
  onClick,
  icon,
  color,
}) => {
  const classes = useStyles({ color });

  const isError = color === 'error';

  const fabColor: any = !isError ? color : undefined;

  return (
    <Fab
      color={fabColor}
      classes={{ root: isError ? classes.fabRootError : undefined }}
      variant="round"
      disabled={disabled}
      onClick={() => onClick()}
    >
      {icon}
    </Fab>
  );
};
