import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { emptyImpersonal, IAccountLogggerFormValues } from '../../types';
import { Impersonal } from './Impersonal';
import { GetAccountingMissions_missions } from '../../types/GetAccountingMissions';
import { GetAccountingMaterials_activeMaterialCatalog_materials } from '../../types/GetAccountingMaterials';
import { GetAccountingVehicles_vehicles } from '../../types/GetAccountingVehicles';
import { GetAccountingActivityTypes_activityTypes } from '../../types/GetAccountingActivityTypes';
import { GetAccountingMachines_machines } from '../../types/GetAccountingMachines';
import { filterImpersonalActivityTypes } from '../../utils/filterItems';

export const tabIndexOffset = 1000000;

/**
 * Impersonal table for a mission/date
 * @constructor
 */
const MappedImpersonal: React.FC<{
  idx: number;
  disabled?: boolean;
  missions: readonly GetAccountingMissions_missions[];
  materials: readonly GetAccountingMaterials_activeMaterialCatalog_materials[];
  vehicles: readonly GetAccountingVehicles_vehicles[];
  machines: readonly GetAccountingMachines_machines[];
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
  actionsLabel: string;
  onActions: (idx: number) => void;
}> = React.memo(
  ({
    idx,
    actionsLabel,
    onActions,
    disabled,
    materials,
    vehicles,
    missions,
    activityTypes,
    machines,
  }) => {
    const onClick = useCallback(() => onActions(idx), [idx, onActions]);

    const actions = useCallback(
      () => (
        <Button type="button" color="primary" size="large" onClick={onClick} disabled={disabled}>
          {actionsLabel}
        </Button>
      ),
      [actionsLabel, disabled, onClick],
    );

    return (
      <Impersonal
        key={idx}
        disabled={disabled}
        displayName={idx === 0 ? 'Material | Maschinen | Fahrzeuge' : ''}
        tableName={`ACCOUNTING_LOGGER_IMPERSONAL_${idx}`}
        actions={actions}
        prefix={`impersonal[${idx}]`}
        materials={materials}
        vehicles={vehicles}
        missions={missions}
        machines={machines}
        activityTypes={activityTypes}
        tabIndexOffset={tabIndexOffset + idx * 1000}
      />
    );
  },
);

interface IProps {
  disabled?: boolean;
  date: string;
  missions: readonly GetAccountingMissions_missions[];
  materials: readonly GetAccountingMaterials_activeMaterialCatalog_materials[];
  vehicles: readonly GetAccountingVehicles_vehicles[];
  machines: readonly GetAccountingMachines_machines[];
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
}

/**
 * The materials/vehicles section of the accounting logger.
 * Structured by missions and dates.
 * @constructor
 */
export const ImpersonalContainer: React.FC<IProps> = React.memo(
  ({ disabled, date, materials, vehicles, missions, machines, activityTypes }) => {
    // no object identity on setValue of useField!
    const { setFieldValue } = useFormikContext();

    const name = 'impersonal';
    const [{ value: impersonal }] =
      useField<Readonly<IAccountLogggerFormValues['impersonal']>>(name);

    const [autoFocus, setAutoFocus] = useState<number | null>(null);

    const onAddMission = useCallback(() => {
      setFieldValue(name, [...impersonal, emptyImpersonal(date)], false);
      setAutoFocus(impersonal.length);
    }, [date, impersonal, setFieldValue]);

    useEffect(() => {
      if (autoFocus != null) {
        document
          .querySelector<HTMLInputElement>(`input[name='impersonal[${autoFocus}].mission']`)
          ?.focus();
        setAutoFocus(null);
      }
    }, [autoFocus, setAutoFocus]);

    const onRemoveMission = useCallback(
      (idx: number) => {
        const newImpersonal = impersonal.slice(0);
        newImpersonal.splice(idx, 1);
        setFieldValue(name, newImpersonal, false);
      },
      [impersonal, setFieldValue],
    );

    const impersonalActivityTypes = useMemo(
      () => filterImpersonalActivityTypes(activityTypes),
      [activityTypes],
    );

    return (
      <>
        {impersonal.map((_, idx) => (
          <MappedImpersonal
            key={idx}
            idx={idx}
            disabled={disabled}
            actionsLabel={idx === 0 ? 'Zusätzlichen Einsatz Buchen' : 'Entfernen'}
            onActions={idx === 0 ? onAddMission : onRemoveMission}
            materials={materials}
            vehicles={vehicles}
            missions={missions}
            machines={machines}
            activityTypes={impersonalActivityTypes}
          />
        ))}
      </>
    );
  },
);
