import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import { omit, pick } from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import { getChangedFormValues } from '../../utils/form/getChanged';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import { convertSwissCoords } from '../GpsFields/GpsFields';
import { useItemFormGrids } from './ItemForm.utils';

interface IItemFormProps {
  onSubmit: ((data: any) => void) | ((data: any) => Promise<void>);
  onClose?: (...args: any) => void;
  initialValues?: any;
}

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'hidden',
  },
}));

const defaultInitialValues = {
  acronym: '',
  appliedPrice: 0,
  applyScaleDiscount: false,
  catalogMainGroup: '',
  catalogMainGroupDescription: '',
  catalogSection: '',
  catalogSectionDescription: '',
  catalogSubsection: '',
  catalogSubsectionDescription: '',
  category: '',
  color: '',
  comment: '',
  customerComment: '',
  descriptionOne: '',
  descriptionTwo: '',
  dimensionOne: '',
  dimensionTwo: '',
  freeText: '',
  markingStyle: '',
  material: '',
  materialColor: '',
  productNumber: '',
  targetConsumptionPerUnit: 0,
  timeRequired: 0,
  type: '',
  unit: '',
  reflexion: '',
  volume: 0,
  documents: {},
  isFinished: false,
};

const validationSchema = Yup.object().shape({
  acronym: Yup.string(),
  productNumber: Yup.string().required('Produktnummer ist ein Pflichtfeld'),
  descriptionOne: Yup.string().required('Beschreibung 1 ist ein Pflichtfeld'),
  unit: Yup.string().required('Einheit ist ein Pflichtfeld'),
  volume: Yup.number().typeError('Menge muss eine Zahl sein'),
  targetConsumptionPerUnit: Yup.number().typeError('Sollverbrauch muss eine Zahl sein'),
  timeRequired: Yup.number().typeError('Zeitbedarf muss eine Zahl sein'),
  appliedPrice: Yup.number().typeError('Preis muss eine Zahl sein'),
});

const ItemInterface: React.FunctionComponent<IItemFormProps> = ({
  onSubmit,
  onClose,
  initialValues,
}) => {
  const classes = useStyles();

  const grids = useItemFormGrids(initialValues);

  const forminitialValues = initialValues
    ? { ...initialValues, documents: {} }
    : defaultInitialValues;

  const handleSubmit = async (values: any) => {
    values = getChangedFormValues({ initialValues: forminitialValues, values });

    onSubmit({
      ...omit(
        {
          ...values,
          ...convertSwissCoords(pick(values, 'nodeCoordinate', 'startCoordinate', 'endCoordinate')),
        },
        ['__typename'],
      ),
      ...(values.appliedPrice && { appliedPrice: Number.parseFloat(values.appliedPrice) }),
    });

    onClose?.();
  };

  return (
    <Formik
      initialValues={forminitialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting }) => (
        <Form>
          <Grid container spacing={5} className={classes.container}>
            {grids}
            <Grid container justify="flex-end">
              <CancelSaveButtons isDisabled={isSubmitting || !dirty} />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ItemInterface;
