import React from 'react';
import { Paper, Box, IconButton, Theme } from '@material-ui/core';
import MetaData, { IMetaDataItem } from './MetaData';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@material-ui/styles';

interface IDetailsFormPaperProps {
  metaData?: IMetaDataItem[];
  onClose: () => void;
}

const PaddedPaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(2.5),
}));

const DetailsFormPaper: React.FC<IDetailsFormPaperProps> = ({ metaData, children, onClose }) => {
  return (
    <PaddedPaper>
      <Box display="flex" justifyContent="space-between">
        <Box p={2}>{metaData && <MetaData metaDataItems={metaData} />}</Box>
        <Box p={2}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      {children}
    </PaddedPaper>
  );
};

export default DetailsFormPaper;
