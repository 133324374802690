import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import { IDataTableColumn, IDataTableRow } from '../../../../../components/DataTable/types';
import DataTable from '../../../../../components/DataTable';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { Grid } from '@material-ui/core';
import { FastField, Field, useField, useFormikContext } from 'formik';
import { emptyImpersonalEntry, IAccountLogggerFormValues, IImpersonalEntry } from '../../types';
import FormikTextField from '../../../../../components/Form/FormikTextField';
import { preventNonNumericInput } from '../../../../../utils/preventNonNumericInput';
import { createSwissCurrencyFormatter } from '../../../../../utils/createCurrencyFormatter';
import { Autocomplete } from '../../../../../components/Autocomplete';
import { fuzzyMatch } from '../../../utils/fuzzyMatch';
import Typography from '@material-ui/core/Typography';
import { shouldUpdate } from '../../utils/shouldUpdate';
import { GetAccountingMaterials_activeMaterialCatalog_materials } from '../../types/GetAccountingMaterials';
import {
  getSearchableActivityType,
  getSearchableMachine,
  getSearchableMaterial,
  getSearchableVehicle,
  parseSearchableActivityType,
  searchableActivityType,
  searchableMachine,
  searchableMaterial,
  searchableMission,
  searchableVehicle,
} from '../../../utils/searchable';
import Dinero from 'dinero.js';
import { GetAccountingVehicles_vehicles } from '../../types/GetAccountingVehicles';
import { GetAccountingMissions_missions } from '../../types/GetAccountingMissions';
import { GetAccountingActivityTypes_activityTypes } from '../../types/GetAccountingActivityTypes';
import { GetAccountingMachines_machines } from '../../types/GetAccountingMachines';
import { ActivityTypeCategory } from '../../../../../types/graphql';
import { cachedTabIndex, tabIndexDisabled } from '../../../utils/tabIndex';
import { ChargedTo, CreditedTo, testChargeConstraint } from '../../../utils/chargeConstraint';

const useStyles = makeStyles(() => ({
  container: { paddingTop: '2.01em' },
  globalAction: {
    paddingRight: '1em',
    paddingBottom: '0.5em',
  },
}));

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

type TableRow = IDataTableRow<IImpersonalEntry & { last: boolean; unit: string }>;

const numTabbableFieldsPerRow = 5;

const tabIndexForRow = (row: TableRow, offset: number) =>
  cachedTabIndex(
    Number(row.id) * numTabbableFieldsPerRow +
      // tabindices start at "1"
      1 +
      offset,
  );

const onFocusSelect = (evt: SyntheticEvent<HTMLInputElement>) => {
  evt.currentTarget.select();
};

const creditedToDisabled = (searchableActivityType: string) => {
  let activityType;
  try {
    activityType = parseSearchableActivityType(searchableActivityType);
  } catch {
    activityType = null;
  }
  return (
    activityType == null ||
    testChargeConstraint(activityType, CreditedTo.COLLECTIVEACCOUNT, ChargedTo.MISSION)
  );
};

/**
 * create the columns for the data table
 * @param prefix which impersonal table are we working on
 * @param materials the materials available
 * @param vehicles the vehicles available
 * @param machines the machines available
 * @param activityTypes the activity types available
 * @param tabIndexOffset offset for the tab indexing
 * @param disabled is the table disabled?
 */
const useColumns = (
  prefix: string,
  materials: readonly GetAccountingMaterials_activeMaterialCatalog_materials[],
  vehicles: readonly GetAccountingVehicles_vehicles[],
  machines: readonly GetAccountingMachines_machines[],
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[],
  tabIndexOffset: number,
  disabled?: boolean,
): Array<IDataTableColumn<TableRow>> => {
  const { setFieldValue } = useFormikContext();

  const materialSuggestions = useMemo(() => materials.map(searchableMaterial), [materials]);
  const vehicleSuggestions = useMemo(() => vehicles.map(searchableVehicle), [vehicles]);
  const machineSuggestions = useMemo(() => machines.map(searchableMachine), [machines]);
  const activityTypeSuggestions = useMemo(
    () => activityTypes.map(searchableActivityType),
    [activityTypes],
  );

  const validateMaterialSuggestion = useCallback(
    (row: TableRow) => (value: string | undefined) => {
      if (
        ((row.data.last && value) || !row.data.last) &&
        (!value || !materialSuggestions.includes(value))
      ) {
        return 'Material existiert nicht!';
      }
    },
    [materialSuggestions],
  );
  const validateVehicleSuggestion = useCallback(
    (row: TableRow) => (value: string | undefined) => {
      if (
        ((row.data.last && value) || !row.data.last) &&
        (!value || !vehicleSuggestions.includes(value))
      ) {
        return 'Fahrzeug existiert nicht!';
      }
    },
    [vehicleSuggestions],
  );
  const validateMachineSuggestion = useCallback(
    (row: TableRow) => (value: string | undefined) => {
      if (
        ((row.data.last && value) || !row.data.last) &&
        (!value || !machineSuggestions.includes(value))
      ) {
        return 'Maschine existiert nicht!';
      }
    },
    [machineSuggestions],
  );
  const validateActivityTypeSuggestion = useCallback(
    (row: TableRow) => (value: string | undefined) => {
      if (
        ((row.data.last && value) || !row.data.last) &&
        (!value || !activityTypeSuggestions.includes(value))
      ) {
        return 'Leistungsart existiert nicht!';
      }
    },
    [activityTypeSuggestions],
  );
  const validateMissingActivityType = useCallback(
    (row: TableRow) => (value: string | undefined) => {
      if (!creditedToDisabled(row.data.activityType) && value) {
        return 'Leistungsart ausfüllen!';
      }
    },
    [],
  );
  const offsetTabIndexForRow = useCallback(
    (row: TableRow, offset: number) => tabIndexForRow(row, offset + tabIndexOffset),
    [tabIndexOffset],
  );
  return useMemo(
    () => [
      {
        id: 'activityType',
        label: 'Leistungsart',
        render: (_, row) => {
          return (
            <Field
              autoFocus={row.data.last && row.id !== '0'}
              last={row.data.activityType}
              disabled={disabled}
              name={`${prefix}.entries[${row.id}].activityType`}
              type="text"
              component={Autocomplete}
              suggestions={activityTypeSuggestions}
              matcher={fuzzyMatch}
              minLengthToTrigger={0}
              selectOnFocus
              fillOnEnter
              fillOnBlur
              useNext="click"
              // if we just pass the validate function it doesn't update correctly on change
              validate={(values: any) => validateActivityTypeSuggestion(row)(values)}
              inputProps={offsetTabIndexForRow(row, 0)}
              next={(value: string) => {
                try {
                  if (creditedToDisabled(value)) {
                    return `input[name='${prefix}.entries[${row.id}].amount']`;
                  }
                } catch {
                  // ignore
                }
                return `input[name='${prefix}.entries[${row.id}].name']`;
              }}
              onSetValue={(value: string) => {
                try {
                  if (creditedToDisabled(value)) {
                    setFieldValue(`${prefix}.entries[${row.id}].name`, '', false);
                    /* hacky but necessary: tabindex alone is not enough. changing the conditions for enabling the
                     * mission field only happens after the focus has already moved on already so it'll jump to the
                     * wrong field. this will jump to the correct field immediately letting the mission field render.
                     */
                    document
                      .querySelector<HTMLInputElement>(
                        `input[name='${prefix}.entries[${row.id}].amount']`,
                      )
                      ?.focus();
                  }
                } catch {
                  // ignore
                }
              }}
            />
          );
        },
      },
      {
        id: 'name',
        label: 'Zugunsten',
        render: (_, row) => {
          const activityType = getSearchableActivityType(row.data.activityType, activityTypes);
          const suggestions =
            activityType?.category === ActivityTypeCategory.MATERIAL
              ? materialSuggestions
              : activityType?.category === ActivityTypeCategory.MASCHINEN
              ? machineSuggestions
              : activityType?.category === ActivityTypeCategory.FAHRZEUGE
              ? vehicleSuggestions
              : [];
          const validateSuggestion =
            activityType?.category === ActivityTypeCategory.MATERIAL
              ? validateMaterialSuggestion
              : activityType?.category === ActivityTypeCategory.MASCHINEN
              ? validateMachineSuggestion
              : activityType?.category === ActivityTypeCategory.FAHRZEUGE
              ? validateVehicleSuggestion
              : validateMissingActivityType;

          const isDisabled = disabled || creditedToDisabled(row.data.activityType);
          return (
            <Field
              last={row.data.name}
              disabled={isDisabled}
              name={`${prefix}.entries[${row.id}].name`}
              type="text"
              component={Autocomplete}
              suggestions={suggestions}
              matcher={fuzzyMatch}
              minLengthToTrigger={0}
              selectOnFocus
              fillOnEnter
              fillOnBlur
              placeholder={isDisabled ? 'Sammelkonto' : ''}
              next={`input[name='${prefix}.entries[${row.id}].amount']`}
              // if we just pass the validate function it doesn't update correctly on change
              validate={(values: any) => validateSuggestion(row)(values)}
              inputProps={isDisabled ? tabIndexDisabled : offsetTabIndexForRow(row, 1)}
            />
          );
        },
      },
      {
        id: 'type',
        label: 'Typ',
      },
      {
        id: 'amount',
        label: 'Menge',
        render: (amount, row) => (
          <Grid container direction="row">
            <Grid item xs={10}>
              <FastField
                shouldUpdate={shouldUpdate}
                last={amount}
                disabled={disabled}
                name={`${prefix}.entries[${row.id}].amount`}
                component={FormikTextField}
                type="number"
                onKeyPress={preventNonNumericInput}
                onFocus={onFocusSelect}
                inputProps={offsetTabIndexForRow(row, 2)}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography display="block">{row.data.unit}</Typography>
            </Grid>
          </Grid>
        ),
      },
      {
        id: 'price',
        label: 'Ansatz',
        render: formatCurrency,
      },
      {
        id: 'sum',
        label: 'Summe',
        render: formatCurrency,
      },
      {
        id: 'comment',
        label: 'Bemerkung',
        render: (_, row) => (
          <FastField
            shouldUpdate={shouldUpdate}
            last={row.data.comment}
            disabled={disabled}
            name={`${prefix}.entries[${row.id}].comment`}
            component={FormikTextField}
            type="text"
            inputProps={offsetTabIndexForRow(row, 3)}
          />
        ),
      },
    ],
    [
      activityTypeSuggestions,
      activityTypes,
      disabled,
      machineSuggestions,
      materialSuggestions,
      prefix,
      validateActivityTypeSuggestion,
      validateMachineSuggestion,
      validateMaterialSuggestion,
      validateVehicleSuggestion,
      validateMissingActivityType,
      vehicleSuggestions,
      offsetTabIndexForRow,
      setFieldValue,
    ],
  );
};

/**
 * Map impersonal entries to data table rows
 * @param impersonalEntries the time entries
 * @param materials meta info for materials
 * @param vehicles meta info for vehicles
 * @param machines meta info for machines
 * @returns data table rows
 * @see IDataTableRow
 */
const mapToDataTable = (
  impersonalEntries: readonly IImpersonalEntry[],
  materials: readonly GetAccountingMaterials_activeMaterialCatalog_materials[],
  vehicles: readonly GetAccountingVehicles_vehicles[],
  machines: readonly GetAccountingMachines_machines[],
): TableRow[] => {
  return impersonalEntries.map((impersonalEntry, idx) => {
    const id = idx.toString();
    const last = idx === impersonalEntries.length - 1;
    let activityType = null;
    try {
      activityType = parseSearchableActivityType(impersonalEntry.activityType);
    } catch {
      // ignore
    }
    if (activityType != null && activityType?.unit?.toUpperCase() === 'CHF') {
      const price = Dinero({ amount: 100 });
      return {
        id,
        data: {
          ...impersonalEntry,
          unit: activityType.unit,
          price,
          sum: price.multiply(impersonalEntry.amount),
          type: '',
          last,
        },
      };
    }
    const material = getSearchableMaterial(impersonalEntry.name, materials);
    if (material == null) {
      const vehicleOrMachine: { internalHourlyWage: number; category: string } | undefined =
        getSearchableVehicle(impersonalEntry.name, vehicles) ??
        getSearchableMachine(impersonalEntry.name, machines);
      const unit = activityType?.unit ?? '';
      const price = Dinero({ amount: vehicleOrMachine?.internalHourlyWage ?? 0 });
      return {
        id,
        data: {
          ...impersonalEntry,
          unit,
          price,
          sum: price.multiply(impersonalEntry.amount),
          type: vehicleOrMachine?.category ?? '',
          last,
        },
      };
    } else {
      const price = Dinero({ amount: material?.pricePerUnit ?? 0 });
      return {
        id,
        data: {
          ...impersonalEntry,
          unit: material?.unit?.acronym ?? '',
          price,
          sum: price.multiply(impersonalEntry.amount),
          type: material?.materialType ?? '',
          last,
        },
      };
    }
  });
};

interface IProps {
  missions: readonly GetAccountingMissions_missions[];
  materials: readonly GetAccountingMaterials_activeMaterialCatalog_materials[];
  vehicles: readonly GetAccountingVehicles_vehicles[];
  machines: readonly GetAccountingMachines_machines[];
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
  disabled?: boolean;
  displayName?: string;
  tableName: string;
  prefix: string;
  actions?: () => React.ReactElement;
  tabIndexOffset?: number;
}

/**
 * The materials/vehicles section of the accounting logger.
 * Structured by missions and dates.
 * @constructor
 */
export const Impersonal: React.FC<IProps> = React.memo(
  ({
    disabled,
    displayName,
    tableName,
    prefix,
    actions,
    materials,
    vehicles,
    machines,
    missions,
    activityTypes,
    tabIndexOffset = 0,
  }) => {
    const styles = useStyles();

    // no object identity on setValue of useField!
    const { setFieldValue, errors } = useFormikContext();

    const [{ value: allImpersonal }] =
      useField<Readonly<IAccountLogggerFormValues['impersonal']>>('impersonal');

    const [{ value: impersonal }] =
      useField<Readonly<IAccountLogggerFormValues['impersonal'][number]>>(prefix);

    const [{ value: mission }] = useField<
      IAccountLogggerFormValues['impersonal'][number]['mission']
    >(`${prefix}.mission`);
    const noMission = (mission ?? '') === '';

    const onAddRow = useCallback(() => {
      setFieldValue(
        prefix,
        { ...impersonal, entries: [...impersonal.entries, emptyImpersonalEntry()] },
        false,
      );
    }, [impersonal, prefix, setFieldValue]);

    const onClearEntryRow = useCallback(() => {
      const newImpersonalEntries = impersonal.entries.slice(0, -1);
      newImpersonalEntries.push(emptyImpersonalEntry());
      setFieldValue(prefix, { ...impersonal, entries: newImpersonalEntries }, true);
    }, [impersonal, prefix, setFieldValue]);

    const onClearRow = useCallback(
      (rowId: number) => {
        if (impersonal.entries.length > 1 && rowId < impersonal.entries.length - 1) {
          const newImpersonalEntries = impersonal.entries.slice(0);
          newImpersonalEntries.splice(rowId, 1);
          setFieldValue(prefix, { ...impersonal, entries: newImpersonalEntries }, true);
        }
      },
      [impersonal, prefix, setFieldValue],
    );

    const columns = useColumns(
      prefix,
      materials,
      vehicles,
      machines,
      activityTypes,
      tabIndexOffset + 1,
      disabled || noMission,
    );

    const missionSuggestions = useMemo(() => missions.map(searchableMission), [missions]);
    // only the empty skeleton exists -> the mission field may be empty
    const impersonalIsEmpty = allImpersonal.length === 1 && allImpersonal[0].entries.length === 1;
    const validateMissionSuggestion = useCallback(
      (value: string | undefined) => {
        if (
          ((impersonalIsEmpty && value) || !impersonalIsEmpty) &&
          (!value || !missionSuggestions.includes(value))
        ) {
          return 'Einsatz existiert nicht!';
        }
      },
      [impersonalIsEmpty, missionSuggestions],
    );

    const hasInputError = Object.keys(errors ?? {}).length > 0;

    const options = useMemo(() => {
      return {
        hideShowAdditionalColumnsBtn: true,
        displayName,
        globalActions: () => {
          return (
            <Grid container direction="row">
              <Grid item xs={6} className={styles.globalAction}>
                <Field
                  name={`${prefix}.mission`}
                  label="Einsatz"
                  type="text"
                  component={Autocomplete}
                  disabled={disabled}
                  suggestions={missionSuggestions}
                  maxSuggestions={75}
                  matcher={fuzzyMatch}
                  minLengthToTrigger={0}
                  selectOnFocus
                  fillOnEnter
                  fillOnBlur
                  next={`input[name='${prefix}.entries[${
                    impersonal.entries.length - 1
                  }].activityType']`}
                  // if we just pass the validate function it doesn't update correctly on change
                  validate={(value: any) => validateMissionSuggestion(value)}
                  inputProps={cachedTabIndex(tabIndexOffset)}
                />
              </Grid>
              <Grid item xs={6} className={styles.globalAction}>
                <Field
                  name={`${prefix}.date`}
                  label="Einsatzdatum"
                  type="date"
                  component={FormikTextField}
                  disabled={disabled}
                  inputProps={noMission ? cachedTabIndex(tabIndexOffset + 1) : tabIndexDisabled}
                />
              </Grid>
            </Grid>
          );
        },
        tableName,
        activeRowId: '',
        levels: [
          {
            columns,
            rowActions: ({ row }: { row: IDataTableRow }) => {
              const stateStr = disabled ? 'Fixiert' : 'Löschen';
              const isFilled =
                row.data.activityType &&
                (creditedToDisabled(row.data.activityType) || row.data.name);
              const errorIcon = hasInputError || !isFilled;
              const addDisabled = hasInputError || disabled || !isFilled;
              return (
                <>
                  {Number(row.id) < impersonal.entries.length - 1 || disabled ? (
                    <Tooltip title={'Eintrag ' + stateStr}>
                      <div>
                        <IconButton
                          aria-label={stateStr}
                          disabled={disabled}
                          onClick={() => onClearRow(Number(row.id))}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  ) : (
                    <>
                      <Grid container direction="row">
                        <Tooltip title="Eintrag hinzufügen">
                          <div>
                            <IconButton
                              aria-label="hinzufügen"
                              disabled={addDisabled}
                              onClick={addDisabled ? undefined : onAddRow}
                              onFocus={addDisabled ? undefined : onAddRow}
                              {...tabIndexForRow(row, tabIndexOffset + 4)}
                            >
                              <CheckIcon
                                color={errorIcon ? 'error' : undefined}
                                htmlColor={errorIcon ? undefined : 'green'}
                              />
                            </IconButton>
                          </div>
                        </Tooltip>
                        <Tooltip title="Eintrag abbrechen">
                          <div>
                            <IconButton
                              aria-label="abbrechen"
                              disabled={disabled}
                              onClick={onClearEntryRow}
                            >
                              <ClearIcon color="error" />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </Grid>
                    </>
                  )}
                </>
              );
            },
            actions,
          },
        ],
      };
    }, [
      displayName,
      tableName,
      columns,
      actions,
      styles.globalAction,
      prefix,
      disabled,
      missionSuggestions,
      impersonal.entries.length,
      validateMissionSuggestion,
      onAddRow,
      onClearEntryRow,
      onClearRow,
      tabIndexOffset,
      hasInputError,
      noMission,
    ]);

    const innerTableRows = useMemo(
      () =>
        mapToDataTable(
          disabled || noMission ? impersonal.entries.slice(0, -1) : impersonal.entries,
          materials,
          vehicles,
          machines,
        ),
      [disabled, noMission, impersonal.entries, materials, vehicles, machines],
    );

    return (
      <div className={styles.container}>
        <DataTable innerTableRows={innerTableRows} options={options} />
      </div>
    );
  },
);
