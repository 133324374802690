import { AddressSearchColumn, WidthMapping } from './types';

export const ADDRESS_COLUMNS = [
  'shortName',
  'id',
  'firstName',
  'name',
  'department',
  'city',
  'streetAndNumber',
  'postCode',
  'phone',
  'phoneTwo',
  'email',
] as const;

export const ADDRESS_COLUMN_LABEL: Record<AddressSearchColumn, string> = {
  shortName: 'Kurzname',
  id: 'Kd. Nr.',
  firstName: 'Vorname',
  name: 'Name',
  city: 'Ort',
  streetAndNumber: 'Strasse',
  postCode: 'PLZ',
  phone: 'Tel Nr.',
  phoneTwo: 'Mob. Nr',
  email: 'Email',
  department: 'Abteilung',
};

export const COLUMN_DEFAULT_WIDTH: WidthMapping = {
  shortName: 35,
  id: 20,
  firstName: 35,
  name: 70,
  city: 45,
  streetAndNumber: 80,
  postCode: 30,
  phone: 60,
  phoneTwo: 59,
  email: 70,
  department: 40,
};

export const FORM_INITIAL_VALUES = {
  shortName: '',
  id: '',
  firstName: '',
  name: '',
  city: '',
  streetAndNumber: '',
  postCode: '',
  phone: '',
  phoneTwo: '',
  email: '',
};

export const DATA_TABLE_WRAPPER_ID = 'address-search-data-table-wrapper';
export const MODAL_OPENER_CLASS = 'address-search-modal-opener';
