import gql from 'graphql-tag';
import { locationCompositeIdFields } from '../../../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/queries';

const BILL_ITEM_VIEW_FRAGMENT = gql`
  fragment billItemViewFragment on BillItem {
    id
    amountVolume
    invoicedVolumeSum
    canBeDeleted
    pricePerUnit
    isScaleDiscountOverridden
    scaleDiscounts {
      id
    }
    missionItem {
      id
      comment
      customerComment
      mission {
        id
        locationsIndex
        executionDate
        name
      }
      item {
        id
        comment
        customerComment
        productNumber
        acronym
        unit
        isFinished
        descriptionOne
        descriptionTwo
        markingStyle
        freeText
        applyScaleDiscount
        appliedPrice
        billOfQuantity {
          id
          name
        }
        location {
          id
          name
          parentLocation {
            id
            name
            parentLocation {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_BILLS_QUERY = gql`
  query SearchBills($search: SearchInput!) {
    bills(search: $search) {
      defaultLocation {
        id
      }
    }
  }
`;

export const GET_ITEMS_CONNECTION = gql`
  query GetItemsConnection($where: BillLocationWhereUniqueInput!, $after: ID, $take: Int = 10) {
    billLocation(where: $where) {
      id
      bill {
        id
        isFixedBill
      }
      billItemsConnection(pagination: { take: $take, after: $after }) {
        pageInfo {
          hasNextPage
        }
        nodes {
          ...billItemViewFragment
        }
      }
    }
  }
  ${BILL_ITEM_VIEW_FRAGMENT}
`;

export const GET_BILL_LOCATION = gql`
  query GetBillLocation($where: BillLocationWhereUniqueInput!) {
    billLocation(where: $where) {
      id
      name
      itemLocation {
        id
        ...locationCompositeIdFields
        parentLocation {
          parentLocation {
            id
          }
        }
      }
    }
  }
  ${locationCompositeIdFields}
`;

export const REFETCH_BILL_COMPUTED_FIELDS = gql`
  query RefetchBillComputedFields($where: BillWhereUniqueInput!) {
    bill(where: $where) {
      id
      netBillSum
      grossBillSum
    }
  }
`;

export const REFETCH_BILL_ITEM_COMPUTED_FIELDS = gql`
  query RefetchBillItemComputedFields($where: BillItemWhereUniqueInput!) {
    billItem(where: $where) {
      id
      pricePerUnit
      isScaleDiscountOverridden
      amountVolume
      invoicedVolumeSum
    }
  }
`;
