import ApolloClient from 'apollo-client';
import { omit } from 'lodash';
import { ADD_ROW_TO_DATA_TABLE, SET_PAGE_INFO } from '../../../../../services/graphql-client';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../../../../services/types/AddRowToDataTable';
import { SetPageInfo, SetPageInfoVariables } from '../../../../../services/types/SetPageInfo';
import {
  AddRowToDataTableInput,
  ClientPageInfoInput,
  RowType,
  TableType,
} from '../../../../../types/graphql';
import { buildTableId, mapMissionItem, removeTableTypeFromId } from './mapper';
import { MISSION_DEFAULT_FETCH_ITEMS, MISSION_FETCH_ITEMS } from './queries';
import {
  MissionDefaultFetchItems,
  MissionDefaultFetchItemsVariables,
} from './types/MissionDefaultFetchItems';
import { MissionFetchItems, MissionFetchItemsVariables } from './types/MissionFetchItems';
import { missionItemViewFields } from './types/missionItemViewFields';

const isDefaultFetch = (
  data: MissionDefaultFetchItems | MissionFetchItems,
): data is MissionDefaultFetchItems => {
  return 'mission' in data;
};

type MissionItemLocationMap = Record<string, missionItemViewFields[]>;

const buildMissionItemLocationMap = (
  data: MissionDefaultFetchItems | MissionFetchItems,
): MissionItemLocationMap => {
  if (isDefaultFetch(data)) {
    return {
      [data.mission.defaultMissionItemLocation.id!]:
        data.mission.defaultMissionItemLocation.missionItemsConnection.nodes,
    };
  }

  return data.missionItemLocations.reduce<MissionItemLocationMap>((missionItemsMap, location) => {
    missionItemsMap[location.id!] = location.missionItemsConnection.nodes;

    return missionItemsMap;
  }, {});
};

const mapDataToClientPageInfoInputs =
  (tableType: TableType) =>
  (data: MissionDefaultFetchItems | MissionFetchItems): ClientPageInfoInput[] => {
    if (isDefaultFetch(data)) {
      return [
        {
          id: buildTableId(tableType)(data.mission.defaultMissionItemLocation.id!),
          hasNextPage:
            data.mission.defaultMissionItemLocation.missionItemsConnection.pageInfo.hasNextPage,
        },
      ];
    }

    return data.missionItemLocations.map((location) => ({
      id: buildTableId(tableType)(location.id!),
      hasNextPage: location.missionItemsConnection.pageInfo.hasNextPage,
    }));
  };

const setPageInfos = (client: ApolloClient<any>) => (inputs: ClientPageInfoInput[]) => {
  inputs.forEach((input) => {
    client.query<SetPageInfo, SetPageInfoVariables>({
      query: SET_PAGE_INFO,
      variables: { data: input },
    });
  });
};

export const fetchMissionItemsConnection =
  (client: ApolloClient<any>) =>
  (tableType: TableType) =>
  async (
    variables:
      | ({ __isMission: true } & MissionDefaultFetchItemsVariables)
      | ({ __isMission: false } & MissionFetchItemsVariables),
  ) => {
    variables.pagination.after = variables.pagination.after
      ? removeTableTypeFromId(variables.pagination.after)
      : undefined;

    const { data } = await (variables.__isMission
      ? client.query<MissionDefaultFetchItems, MissionDefaultFetchItemsVariables>({
          query: MISSION_DEFAULT_FETCH_ITEMS,
          variables: omit(variables, '__isMission'),
        })
      : client.query<MissionFetchItems, MissionFetchItemsVariables>({
          query: MISSION_FETCH_ITEMS,
          variables: omit(variables, '__isMission'),
        }));

    const missionItemLocationMap = buildMissionItemLocationMap(data);

    const pageInfoInputs = mapDataToClientPageInfoInputs(tableType)(data);

    setPageInfos(client)(pageInfoInputs);

    await client.query<AddRowToDataTable, AddRowToDataTableVariables>({
      query: ADD_ROW_TO_DATA_TABLE,
      variables: {
        where: { id: tableType },
        data: Object.entries(missionItemLocationMap).flatMap(([locationId, missionItems]) => {
          return missionItems.flatMap<AddRowToDataTableInput>((missionItem) => ({
            type: RowType.INNER,
            containerId: buildTableId(tableType)(locationId),
            row: {
              __typename: 'DataTableRow',
              id: buildTableId(tableType)(missionItem.id),
              hidden: false,
              containerRows: [],
              innerTableRows: [],
              data: JSON.stringify(mapMissionItem(missionItem)),
            },
          }));
        }),
      },
    });
  };
