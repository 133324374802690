import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Modal,
  Theme,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useMemo, useState } from 'react';
import { GetProjectStorageRepository_projectStorageRepository_folders_documents } from './types/GetProjectStorageRepository';
import FileIcon from '../Form/UploadField/FileIcon';
import FormikTextField from '../Form/FormikTextField';
import { Field } from 'formik';
import { handleFileDownload } from '../Form/UploadField/Dropzone';
import { getBlob } from '../../utils/downloadFile';
import { formatDate } from '../../utils/format/date';

interface Props {
  file: GetProjectStorageRepository_projectStorageRepository_folders_documents;
  disabled?: boolean;
  onDelete: (documentId: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 290,
    minHeight: 150,
    maxWidth: 290,
    display: 'flex',
    flexDirection: 'column',
  },
  actionButton: {
    margin: theme.spacing(0, 1),
  },
  actionButtonIcon: {
    fontSize: '1.8rem',
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  cardHeader: {
    paddingBottom: 0,
    paddingTop: theme.spacing(1),
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.50)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  list: {
    paddingBottom: 0,
  },
  listItem: {
    padding: theme.spacing(0, 2),
  },
  lisItemText: {
    margin: 0,
  },
  cardActions: {
    paddingTop: 0,
    marginBottom: 0,
    marginTop: 'auto',
  },
  cardContent: {
    padding: theme.spacing(0, 1),
  },
  previewModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewWrapper: {
    maxWidth: '80vw',
  },
  // iframe needs to be positioned separately because it can't access parent's dimensions
  previewIFrame: {
    position: 'absolute',
    width: '80vw',
    height: '80vh',
    left: '10vw',
    top: '10vh',
  },
  avatarPreviewable: {
    cursor: 'pointer',
  },
}));

const PREVIEW_MIMETYPES = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

const FileComponent: React.FC<Props> = ({ file, disabled, onDelete }) => {
  const classes = useStyles();

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [previewFile, setPreviewFile] = useState<Blob | null>(null);

  const allowPreview = useMemo(
    () => !disabled && PREVIEW_MIMETYPES.some((mimetype) => file.mimetype === mimetype),
    [file.mimetype, disabled],
  );

  useEffect(() => {
    if (showPreview) {
      getBlob(file.path).then((previewBlob) => previewBlob && setPreviewFile(previewBlob));
    } else {
      setPreviewFile(null);
    }
  }, [file.path, showPreview]);

  const preview = useMemo(
    () =>
      previewFile?.type === 'application/pdf' ? (
        <iframe
          src={window.URL.createObjectURL(previewFile)}
          title="PDF preview"
          className={classes.previewIFrame}
        />
      ) : previewFile?.type.startsWith('image/') ? (
        <img
          src={previewFile ? window.URL.createObjectURL(previewFile) : ''}
          width="100%"
          alt={''}
        />
      ) : null,
    [classes.previewIFrame, previewFile],
  );

  return (
    <Card
      className={`${classes.root} ${disabled ? classes.disabled : ''} ${
        allowPreview ? classes.avatarPreviewable : ''
      }`}
      onClick={allowPreview ? () => setShowPreview(!showPreview) : undefined}
    >
      <CardHeader
        avatar={
          <Avatar>
            <FileIcon color="#fafafa" mimeType={file.mimetype as any} />
          </Avatar>
        }
        title={
          <Field
            component={FormikTextField}
            name={`name-${file.id}`}
            onClick={(e: MouseEvent) => e.stopPropagation()}
            disabled={disabled}
          />
        }
        // subheader="von Dev am 20.12.2022"
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.lisItemText}
              primary={`hochgeladen am ${formatDate(file.createdAt)} ${
                file.uploadedBy ? `von ${file.uploadedBy.name}` : ''
              }`}
            />
          </ListItem>
          {file.updatedBy && (
            <ListItem className={classes.listItem}>
              <ListItemText
                className={classes.lisItemText}
                primary={`aktulisiert am ${formatDate(file.updatedAt)} von ${file.updatedBy.name}`}
              />
            </ListItem>
          )}
        </List>
      </CardContent>

      <CardActions className={classes.cardActions}>
        <IconButton
          className={classes.actionButton}
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            return handleFileDownload(file.path, file.name);
          }}
        >
          <GetAppIcon className={classes.actionButtonIcon} />
        </IconButton>
        <IconButton
          disabled={disabled}
          className={`${classes.actionButton} ${classes.deleteButton}`}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(file.id);
          }}
        >
          <DeleteIcon className={classes.actionButtonIcon} />
        </IconButton>
      </CardActions>
      {preview && (
        <Modal
          open={showPreview}
          onClose={() => setShowPreview(false)}
          className={classes.previewModal}
        >
          <div className={classes.previewWrapper}>{preview}</div>
        </Modal>
      )}
    </Card>
  );
};

export default FileComponent;
