import React, { useCallback, useMemo } from 'react';
import { IAddItem } from './index';
import gql from 'graphql-tag';
import { Tooltip, Fab } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import DataTable from '../../DataTable';
import { IDataTableColumn } from '../../DataTable/types';
import ColorCoder, { Color } from '../../ColorCoder';
import { useSearchState, Search } from '../../Search/Search';
import { useQuery } from 'react-apollo';
import {
  getItemBoilerPlateGroups,
  getItemBoilerPlateGroupsVariables,
} from './types/getItemBoilerPlateGroups';

const itemBoilerplateGroupColumns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Name',
  },
];

const itemBoilerplateColumns: IDataTableColumn[] = [
  {
    id: 'colorCode',
    label: 'Farbe',
    render: (colorCode: Color) => colorCode && <ColorCoder colorCodings={[colorCode]} />,
  },
  {
    id: 'catalog',
    label: 'Katalog',
  },
  {
    id: 'catalogSection',
    label: 'Kapitel',
  },
  {
    id: 'catalogSubsection',
    label: 'Absatz',
  },
  {
    id: 'catalogMainGroup',
    label: 'Hauptgruppe',
  },
  {
    id: 'catalogSectionDescription',
    label: 'Kapitel-Beschreibung',
  },
  {
    id: 'catalogMainGroupDescription',
    label: 'Hauptgruppe-Beschreibung',
  },
  {
    id: 'catalogSubsectionDescription',
    label: 'Absatz-Beschreibung',
  },
  {
    id: 'productNumber',
    label: 'Produktnummer',
  },
  {
    id: 'descriptionOne',
    label: 'Beschreibung 1',
  },
  {
    id: 'descriptionTwo',
    label: 'Beschreibung 2',
  },
  {
    id: 'unit',
    label: 'Einheit',
  },
  {
    id: 'material',
    label: 'Verbrauchsmaterial',
  },
  {
    id: 'targetConsumptionPerUnit',
    label: 'Sollverbrauch / Einheit',
  },
  {
    id: 'acronym',
    label: 'Kurzbezeichnung',
  },
  {
    id: 'markingStyle',
    label: 'Markierart',
  },
  {
    id: 'dimensionOne',
    label: 'Dimension 1',
  },
  {
    id: 'dimensionTwo',
    label: 'Dimension 2',
  },
  {
    id: 'reflexion',
    label: 'Reflektion',
  },
  {
    id: 'timeRequired',
    label: 'Zeitbedarf',
  },
  {
    id: 'category',
    label: 'Kategorie',
  },
];

const searchColumns = ['name'];

export const GET_ITEM_BOILERPLATE_GROUPS = gql`
  query getItemBoilerPlateGroups($search: SearchInput) {
    itemBoilerplateGroups(search: $search) {
      id
      name
      itemBoilerplates {
        acronym
        catalogMainGroup
        catalogMainGroupDescription
        catalogSection
        catalogSectionDescription
        catalogSubsection
        catalogSubsectionDescription
        category
        color
        materialColor
        descriptionOne
        descriptionTwo
        dimensionOne
        dimensionTwo
        freeText
        id
        markingStyle
        material
        productNumber
        reflexion
        targetConsumptionPerUnit
        timeRequired
        unit
        catalog {
          id
          useName
        }
      }
    }
  }
`;

const toTableData = (element: any) => ({
  id: element.id,
  data: element,
});

const ItemBoilerplateGroupSearch: React.FunctionComponent<{
  onSubmit: (items: IAddItem[]) => void;
}> = ({ onSubmit }) => {
  const searchState = useSearchState();

  const { data, loading, refetch, error } = useQuery<
    getItemBoilerPlateGroups,
    getItemBoilerPlateGroupsVariables
  >(GET_ITEM_BOILERPLATE_GROUPS);

  const itemBoilerplateGroups = useMemo(
    () =>
      data?.itemBoilerplateGroups?.map((group: any) => ({
        ...toTableData(group),
        innerTableRows: group.itemBoilerplates.map((item: any) =>
          toTableData({ ...item, catalog: item.catalog.useName }),
        ),
      })) ?? [],
    [data],
  );

  const onSubmitSearch = useCallback((search) => refetch({ search }), [refetch]);

  if (error) {
    console.log(error);
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <DataTable
      containerRows={itemBoilerplateGroups}
      search={
        <Search
          autoFocus
          searchState={searchState}
          columns={searchColumns}
          onSubmit={onSubmitSearch}
          loading={loading}
        />
      }
      options={{
        tableName: 'ITEM_BOILERPLATE_GROUP_SEARCH',
        filterText: searchState.searchTerm,
        levels: [
          {
            columns: itemBoilerplateGroupColumns,
            rowActions: ({ row }) => (
              <Tooltip title="Vorlagen-Gruppe hinzufügen">
                <Fab
                  color="primary"
                  size="small"
                  onClick={() =>
                    onSubmit(row.innerTableRows.map(({ id }: any) => ({ rowId: id, volume: 1 })))
                  }
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            ),
          },
          {
            columns: itemBoilerplateColumns,
          },
        ],
      }}
    />
  );
};

export default ItemBoilerplateGroupSearch;
