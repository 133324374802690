import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchColumns } from '../../../components/Search/SearchColumns';
import {
  useActiveColumn,
  useEnteredSearchValues,
} from '../../../components/Search/SearchWithDropdown.hooks';

import { AccountingItemWhereFlatInput } from '../../../types/graphql';
import { searchColumns, toSearchParameters } from './utils/toSearchParameters';
import { useDebounce } from '../../../hooks/useDebounce';

const DELIMITER = ',';

interface IAccountingLogJournalSearchProps {
  onSearch: (enteredSearchValues: AccountingItemWhereFlatInput) => void;
}

/**
 * Component that displays a search field optimized for the accounting log journal
 * @constructor
 */
export const AccountingLogJournalSearch: React.FC<IAccountingLogJournalSearchProps> = React.memo(
  ({ onSearch }) => {
    const [input, setInput] = useState<string>('');
    const enteredSearchValues = useEnteredSearchValues(input, DELIMITER);
    const activeColumn = useActiveColumn(searchColumns, enteredSearchValues);

    // build the search parameter object
    const searchParameters = useMemo(
      () => toSearchParameters(enteredSearchValues),
      [enteredSearchValues],
    );
    // curry the parameters for debouncer
    const curriedOnSearch = useCallback(
      () => onSearch(searchParameters),
      [onSearch, searchParameters],
    );
    // debounce the search
    const debouncedCurriedOnSearch = useDebounce(curriedOnSearch);

    // trigger the search when everything is parsed an changed
    useEffect(
      () => {
        debouncedCurriedOnSearch();
      },
      // this is how it works
      // eslint-disable-next-line
      [curriedOnSearch],
    );

    return (
      <SearchColumns
        onTextChanged={setInput}
        columns={searchColumns}
        activeColumn={activeColumn}
        delimiter={DELIMITER}
        justify="flex-start"
        showColumns={input.length > 0}
      />
    );
  },
);
