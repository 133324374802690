import { ClickAwayListener, makeStyles, Paper, Popper, TextField } from '@material-ui/core';
import React from 'react';
import { DateFilterInput } from '../../../../types/graphql';

interface IProps {
  filterState: DateFilterInput;
  setFilterState: React.Dispatch<DateFilterInput>;
  anchorElement: HTMLElement | null;
  onClosePopup: () => void;
}

const useStyles = makeStyles((theme: any) => ({
  formWrapper: {
    padding: theme.spacing(2),
  },
  toField: {
    marginLeft: theme.spacing(2),
  },
}));

const DateFilter: React.FC<IProps> = ({
  filterState,
  setFilterState,
  anchorElement,
  onClosePopup,
}) => {
  const classes = useStyles();

  return (
    <Popper open={Boolean(anchorElement)} anchorEl={anchorElement}>
      <ClickAwayListener onClickAway={onClosePopup}>
        <Paper className={classes.formWrapper}>
          <TextField
            onBlur={(e) => {
              setFilterState({
                ...filterState,
                from: e.target.value,
              });
            }}
            InputLabelProps={{ shrink: true }}
            label="Vom"
            type="date"
          />
          <TextField
            onBlur={(e) => {
              setFilterState({
                ...filterState,
                to: e.target.value,
              });
            }}
            InputLabelProps={{ shrink: true }}
            label="Bis"
            type="date"
            className={classes.toField}
          />
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default DateFilter;
