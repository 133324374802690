import { Grid, makeStyles } from '@material-ui/core';
import { Form, FormikContext } from 'formik';
import React, { FC, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useWindowSize } from 'react-use';
import { Noop } from '../../../../types';
import { UpsertAddressBookItemType } from '../../../../types/graphql';
import DataTable from '../../../DataTable';
import AppProgress from '../../../Page/AppProgress';
import { useAddressSearch } from '../fetch';
import { COLUMN_DEFAULT_WIDTH, DATA_TABLE_WRAPPER_ID } from './constants';
import { ADDRESS_SEARCH_FORM_UTILS } from './utils/form';
import { ADDRESS_SEARCH_INFINITE_SCROLL_UTILS } from './utils/infiniteScroll';
import { ADDRESS_SEARCH_TABLE_UTILS } from './utils/table';
import { isEmpty, isFunction } from 'lodash';

interface IProps {
  isOverlay?: boolean;
  isSurroundingModalOpen?: boolean;
  projectNumber?: string;
  type?: UpsertAddressBookItemType;
  onAddressSelect?: Noop;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  addressSearchOverlay: {
    boxShadow: '0 0 10px 3px black',
  },
}));

export const AddressSearch: FC<IProps> = ({
  isSurroundingModalOpen,
  projectNumber,
  type,
  onAddressSelect,
  isOverlay = true,
}) => {
  const [fieldWidthMapping, setFieldWidthMapping] = useState(COLUMN_DEFAULT_WIDTH);
  const shortNameFieldRef = useRef<HTMLInputElement | null>(null);

  const classes = useStyles();
  const windowSize = useWindowSize();

  const { data, loading, fetchMore, refetch, executeUseAddress } = useAddressSearch();
  const formik = ADDRESS_SEARCH_FORM_UTILS.useFormik(refetch);
  const handleOnChange = ADDRESS_SEARCH_FORM_UTILS.useHandleOnChange(formik);
  const innerTableRows = ADDRESS_SEARCH_TABLE_UTILS.useInnerTableRows(data);
  const searchFields = ADDRESS_SEARCH_FORM_UTILS.useSearchFields({
    fieldWidthMapping,
    inputRootClass: classes.root,
    onChange: handleOnChange,
    shortNameFieldRef,
  });

  ADDRESS_SEARCH_TABLE_UTILS.useResetQueryState(isSurroundingModalOpen, refetch);
  ADDRESS_SEARCH_FORM_UTILS.useSetFieldWidths(setFieldWidthMapping);
  ADDRESS_SEARCH_FORM_UTILS.useFocusShortName(isSurroundingModalOpen, shortNameFieldRef);
  ADDRESS_SEARCH_FORM_UTILS.useResetForm(isSurroundingModalOpen, formik);

  return (
    <>
      {loading && <AppProgress />}
      <div style={{ zIndex: 100 }} className={isOverlay ? classes.addressSearchOverlay : undefined}>
        <FormikContext.Provider value={formik}>
          <Form>
            <Grid container>{searchFields}</Grid>
          </Form>
        </FormikContext.Provider>
        <InfiniteScroll
          hasMore={!!data?.abacusAddresses}
          dataLength={data?.abacusAddresses?.length ?? 0}
          height={
            isOverlay ? ADDRESS_SEARCH_INFINITE_SCROLL_UTILS.getHeight(windowSize.height) : 'auto'
          }
          loader={ADDRESS_SEARCH_INFINITE_SCROLL_UTILS.getLoader()}
          next={ADDRESS_SEARCH_INFINITE_SCROLL_UTILS.createGetNextFn(
            data?.abacusAddresses ?? [],
            fetchMore,
          )}
        >
          <span id={DATA_TABLE_WRAPPER_ID}>
            <DataTable
              options={{
                hideInterface: true,
                fixedWidthColumns: true,
                fixedWidthColumnsHeader: true,
                activeRowId: '',
                levels: [
                  {
                    onRowClick:
                      !isEmpty(projectNumber) && !isEmpty(type) && isFunction(onAddressSelect)
                        ? ADDRESS_SEARCH_TABLE_UTILS.createHandleRowClick(
                            executeUseAddress,
                            projectNumber as string,
                            type as UpsertAddressBookItemType,
                            onAddressSelect as Noop,
                          )
                        : undefined,
                    columns: ADDRESS_SEARCH_TABLE_UTILS.buildTableColumns(),
                  },
                ],
              }}
              innerTableRows={innerTableRows}
            />
          </span>
        </InfiniteScroll>
      </div>
    </>
  );
};
