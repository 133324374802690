export enum ActionType {
  SET_SELECTED_ID,
  SET_SELECTED_TYPE,
  SET_SELECTED_DELETABLE,
}

export enum SelectedType {
  L1 = 'L1',
  L2 = 'L2',
  ITEM = 'ITEM',
  BOQ = 'BOQ',
}

export interface IState {
  selectedId: string;
  selectedType: SelectedType | null;
  isSelectedDeletable: boolean;
}

export type IAction =
  | { type: ActionType.SET_SELECTED_ID; payload: { selectedId: string } }
  | { type: ActionType.SET_SELECTED_TYPE; payload: { selectedType: SelectedType } }
  | { type: ActionType.SET_SELECTED_DELETABLE; payload: { isSelectedDeletable: boolean } };
export const boqReducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case ActionType.SET_SELECTED_ID:
      return { ...state, selectedId: action.payload.selectedId };
    case ActionType.SET_SELECTED_TYPE:
      return { ...state, selectedType: action.payload.selectedType };
    case ActionType.SET_SELECTED_DELETABLE:
      return { ...state, isSelectedDeletable: action.payload.isSelectedDeletable };
    default:
      return state;
  }
};
