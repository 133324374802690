import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';
import { EmployeeSummary, IEmployeeSummaryProps } from './EmployeeSummary';
import { IImpersonalSummaryProps, ImpersonalSummary } from './ImpersonalSummary';
import { PREVIEW_ACCOUNTING_LOG_ITEMS } from '../../AccountingLogger.queries';
import AppErrorMessage from '../../../../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../../../utils/errorPrefixRemover';
import AppProgress from '../../../../../components/Page/AppProgress';
import {
  PreviewAccountingLogItems,
  PreviewAccountingLogItems_calculatePreviewItems,
  PreviewAccountingLogItemsVariables,
} from '../../types/PreviewAccountingLogItems';
import { toAccountingItems } from '../../utils/accountingItems';
import { useFormikContext } from 'formik';
import { IAccountLogggerFormValues } from '../../types';
import { GetAccountingMaterials_activeMaterialCatalog_materials } from '../../types/GetAccountingMaterials';
import { GetAccountingMachines_machines } from '../../types/GetAccountingMachines';
import { GetAccountingActivityTypes_activityTypes } from '../../types/GetAccountingActivityTypes';
import { getSearchableEmployee } from '../../../utils/searchable';
import { Button, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AccountingItemInput, ChargeType } from '../../../../../types/graphql';
import { orEmpty } from '../../utils/orEmpty';
import { toAccountingItemInput } from './Summary.utils';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

type IProps = Omit<IEmployeeSummaryProps, 'bonusOverviews' | 'mappedItems'> &
  Pick<IImpersonalSummaryProps, 'activityTypes' | 'missions' | 'vehicles' | 'subsidiaries'> & {
    materials: readonly GetAccountingMaterials_activeMaterialCatalog_materials[];
    machines: readonly GetAccountingMachines_machines[];
    activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
    calculatePreviewItems?: PreviewAccountingLogItems_calculatePreviewItems;
    onEdit: () => void;
    onRefresh?: () => void;
    onSubmit: (items: AccountingItemInput[]) => Promise<void>;
    disabled?: boolean;
  };

/**
 * Section of the account logger that shows a summary including all calculated rates from the backend.
 * Consists of a section for employees and a section for vehicles
 * @constructor
 */
export const Summary: React.FC<IProps> = ({
  employees,
  missions,
  materials,
  vehicles,
  machines,
  activityTypes,
  subsidiaries,
  onRefresh,
  onEdit,
  onSubmit,
  calculatePreviewItems,
  disabled,
}) => {
  const styles = useStyles();
  const { id } = useParams();
  const { values, errors, dirty } = useFormikContext<IAccountLogggerFormValues>();
  const items: AccountingItemInput[] = useMemo(() => {
    if (isEmpty(errors)) {
      return toAccountingItems(
        employees,
        missions,
        materials,
        vehicles,
        machines,
        activityTypes,
        values,
      );
    } else {
      return [];
    }
  }, [activityTypes, employees, errors, machines, materials, missions, values, vehicles]);
  const teamLeaderId = useMemo(
    () => getSearchableEmployee(values.chief, employees)?.id ?? '',
    [employees, values],
  );
  const { data, loading, error } = useQuery<
    PreviewAccountingLogItems,
    PreviewAccountingLogItemsVariables
  >(PREVIEW_ACCOUNTING_LOG_ITEMS, {
    skip: calculatePreviewItems != null,
    variables: {
      items,
      teamLeaderId,
      existingReportId: id,
    } as any,
  });

  const previewItems = calculatePreviewItems ?? data?.calculatePreviewItems;

  const [alreadySubmitted, setAlreadySubmitted] = useState(false);

  const onSubmitWithFollowups = useCallback(async () => {
    const allItems = toAccountingItemInput([
      ...items.filter(
        (item) =>
          // these are mapped by the preview
          item.chargedTo.data.type !== ChargeType.COLLECTIVEACCOUNT &&
          item.creditedTo.data.type !== ChargeType.COLLECTIVEACCOUNT,
      ),
      ...(previewItems?.employeeBonusesOverviews ?? []).flatMap(
        ({ accountingItems }) => accountingItems,
      ),
      ...(previewItems?.setupItems ?? []),
      ...(previewItems?.drivingItems ?? []),
      ...(previewItems?.items ?? []),
      ...(previewItems?.externalItems ?? []),
    ]);

    await onSubmit(allItems as AccountingItemInput[]);

    setAlreadySubmitted(true);
  }, [items, onSubmit, previewItems]);
  return (
    <>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <EmployeeSummary
        activityTypes={activityTypes}
        mappedItems={orEmpty(previewItems?.items)}
        employees={employees}
        missions={missions}
        bonusOverviews={orEmpty(previewItems?.employeeBonusesOverviews)}
      />
      <ImpersonalSummary
        subsidiaries={subsidiaries}
        activityTypes={activityTypes}
        missions={missions}
        vehicles={vehicles}
        drivingItems={orEmpty(previewItems?.drivingItems)}
        setupItems={orEmpty(previewItems?.setupItems)}
      />
      <Grid container direction="row" justify="flex-end">
        {onRefresh && (
          <Grid item>
            <Button
              disabled={disabled || loading}
              variant="contained"
              size="large"
              onClick={onRefresh}
              className={styles.button}
            >
              Rapport Aktualisieren
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            disabled={disabled || loading}
            variant="contained"
            size="large"
            onClick={onEdit}
            className={styles.button}
          >
            Rapport Bearbeiten
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={
              data?.calculatePreviewItems?.didChange
                ? alreadySubmitted || false
                : disabled || !dirty || loading
            }
            variant="contained"
            color="primary"
            size="large"
            onClick={onSubmitWithFollowups}
            className={styles.button}
          >
            Rapport Speichern
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
