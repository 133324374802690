import React from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { omit } from 'lodash';
import { RouteComponentProps, Redirect, useLocation } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import FormikTextField from '../../components/Form/FormikTextField';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { USE_COUNT_QUERIES } from '../../hooks/useCount/useCount.queries';
import { projectNumberYupSchema } from '../../components/ProjectDetailsForm/ProjectDetailsForm';
import FormikCheckboxWithLabel from '../../components/Form/FormikCheckboxWithLabel';
import AppErrorMessage from '../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';

const CLONE_PROJECT = gql`
  mutation DuplicateProject(
    $oldProjectNumber: String!
    $newProjectNumber: String!
    $includeBoqs: Boolean
  ) {
    duplicateProject(
      oldProjectNumber: $oldProjectNumber
      newProjectNumber: $newProjectNumber
      includeBoqs: $includeBoqs
    ) {
      projectNumber
    }
  }
`;

const CloneDialog: React.FunctionComponent<RouteComponentProps<{ projectNumber: string }>> = ({
  match,
  history,
}) => {
  const { state } = useLocation();

  const projectNumber = match.params.projectNumber;
  const handleClose = () => {
    history.goBack();
  };

  const validationSchema = Yup.object().shape({
    newProjectNumber: projectNumberYupSchema,
  });

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Projekt {projectNumber} duplizieren?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Das Projekt <a href={`/projekte/${projectNumber}/details`}>{projectNumber}</a> wird mit
          allen Daten kopiert.
        </DialogContentText>
        <Mutation<any, any>
          mutation={CLONE_PROJECT}
          refetchQueries={[
            ...(state.variables
              ? [{ query: USE_COUNT_QUERIES.PROJECT, variables: omit(state.variables, 'page') }]
              : []),
          ]}
        >
          {(duplicateProject, { data, error }) => {
            if (data) {
              return <Redirect to={`/projekte/${data.duplicateProject.projectNumber}/details`} />;
            }

            return (
              <>
                {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
                <Formik
                  initialValues={{ newProjectNumber: '', includeBoqs: false }}
                  validationSchema={validationSchema}
                  onSubmit={(values: any, { setSubmitting }) => {
                    duplicateProject({
                      variables: {
                        oldProjectNumber: projectNumber,
                        newProjectNumber: values.newProjectNumber,
                        includeBoqs: values.includeBoqs,
                      },
                    }).finally(() => {
                      setSubmitting(false);
                    });
                  }}
                >
                  {({ isSubmitting, dirty }) => (
                    <Form>
                      <Field
                        name="newProjectNumber"
                        label="Neue Projektnummer"
                        component={FormikTextField}
                        autoFocus
                        margin="dense"
                        fullWidth
                      />
                      <Field
                        name="includeBoqs"
                        Label={{ label: "LV's mitkopieren" }}
                        component={FormikCheckboxWithLabel}
                      />
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Abbrechen
                        </Button>
                        <Button type="submit" color="primary" disabled={isSubmitting || !dirty}>
                          Duplizieren
                        </Button>
                      </DialogActions>
                    </Form>
                  )}
                </Formik>
              </>
            );
          }}
        </Mutation>
      </DialogContent>
    </Dialog>
  );
};

export default CloneDialog;
