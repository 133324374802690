import React from 'react';
import { Field, FieldProps } from 'formik';
import { MenuItem } from '@material-ui/core';
import FormikSelect from '../../Form/FormikSelect';
import FixedAppointmentStrategy from './FixedAppointmentStrategy';
import NowPlanableStrategy from './NowPlanableStrategy';
import CalendarWeekStrategy from './CalendarWeekStrategy';
import FromDatumStrategy from './FromDatumStrategy';
import UntilDateStrategy from './UntilDateStrategy';

export interface IStrategyProps {
  disabled: boolean;
}

interface IMissionStatusProps extends FieldProps {
  disabled: boolean;
}

enum MissionStatusType {
  FT = 'FT - Fix Termin',
  KB = 'KB - Kommt Bescheid',
  PB = 'PB - Jetzt Planbar',
  KW = 'KW - Kalenderwoche',
  VD = 'VD - Von Datum',
  BD = 'BD - Bis Datum',
}

const MissionStatus: React.FC<IMissionStatusProps> = ({ form: { values }, disabled }) => {
  const statusStrategies = {
    [MissionStatusType.FT]: <FixedAppointmentStrategy disabled={disabled} />,
    [MissionStatusType.KB]: <></>,
    [MissionStatusType.PB]: <NowPlanableStrategy disabled={disabled} />,
    [MissionStatusType.KW]: <CalendarWeekStrategy disabled={disabled} />,
    [MissionStatusType.VD]: <FromDatumStrategy disabled={disabled} />,
    [MissionStatusType.BD]: <UntilDateStrategy disabled={disabled} />,
  };

  const status = MissionStatusType[values.status.missionStatus as keyof typeof MissionStatusType];
  const strategy = statusStrategies[status];

  return (
    <>
      <Field
        name="status.missionStatus"
        label="Status"
        component={FormikSelect}
        disabled={disabled}
      >
        <MenuItem value={''} />
        {Object.entries(MissionStatusType).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </Field>
      {strategy}
    </>
  );
};

export default MissionStatus;
