import React from 'react';
import { Grid, Typography, IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { mdiFile } from '@mdi/js';
import { MinusOutlineIcon } from '../Icons/MinusOutlineIcon';
import { IDocument } from './DocumentViewer';
import FileIcon from '../../../components/Form/UploadField/FileIcon';
import acceptedMimeTypes from '../../../components/Form/UploadField/acceptedMimeTypes';
import { green } from '@material-ui/core/colors';
import { handleFileDownload } from '../../../components/Form/UploadField/Dropzone';

export interface IDocumentProps extends IDocument {
  onDelete?: (id: string) => void;
}

export const DOCUMENT_CONTENT_HEIGHT = 71;
export const DOCUMENT_CONTENT_WIDTH = DOCUMENT_CONTENT_HEIGHT;

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    height: DOCUMENT_CONTENT_HEIGHT,
    width: DOCUMENT_CONTENT_WIDTH,
  },
  greyContentContainer: {
    backgroundColor: theme.palette.grey[300],
    border: `1px solid ${theme.palette.grey[500]}`,
  },
  greenBorder: {
    border: `2px solid ${green[600]}`,
  },
  nameContainer: {
    wordBreak: 'break-all',
  },
}));

const MAX_CHAR_AMOUNT = 20;

const shortenFilename = (name: string) =>
  name.split('.').slice(0, -1).join('').slice(0, MAX_CHAR_AMOUNT);

const prepareUrl = (url: string) => url.replace('uploads/thumbnails', 'uploads');

export const Document: React.FC<IDocumentProps> = ({ path, name, id, mimetype, onDelete }) => {
  const classes = useStyles();

  const type = ['image/jpeg', 'image/png'].includes(mimetype) ? 'image' : 'document';

  const isNewlyCreated = path.startsWith('blob:');
  const isDocument = type === 'document';

  const contentRootClassName = classnames(classes.contentContainer, {
    [classes.greyContentContainer]: type === 'document',
    [classes.greenBorder]: isNewlyCreated && isDocument,
  });

  const imageClassName = classnames({
    [classes.greenBorder]: isNewlyCreated && !isDocument,
  });

  const imgSrc = isNewlyCreated
    ? path // path is Object url (blob:)
    : `${process.env.REACT_APP_API_PREFIX}/${path.replace('uploads/', 'uploads/thumbnails/')}`;

  const content = isDocument ? (
    <FileIcon mimeType={mimetype} path={!acceptedMimeTypes[mimetype] ? mdiFile : undefined} />
  ) : (
    <img
      src={imgSrc}
      width={DOCUMENT_CONTENT_WIDTH} // use to let html crop image when blob
      height={DOCUMENT_CONTENT_HEIGHT}
      className={imageClassName}
      alt={name}
    />
  );

  return (
    <Grid item container justify="center">
      <Grid
        container
        justify="center"
        alignContent="center"
        classes={{ root: contentRootClassName }}
        onClick={() => !isNewlyCreated && handleFileDownload(prepareUrl(path), name)}
      >
        {content}
      </Grid>
      <Grid item container justify="center" xs={12} classes={{ root: classes.nameContainer }}>
        <Typography variant="caption">{shortenFilename(name)}</Typography>
      </Grid>

      <Grid item container justify="center" xs={12}>
        <IconButton color="default" size="medium" disabled={isNewlyCreated}>
          <MinusOutlineIcon fontSize="large" onClick={() => onDelete && onDelete(id)} />
        </IconButton>
      </Grid>
    </Grid>
  );
};
