import React, { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { Heading, Section } from '../../Section';
import { GetAccountingEmployees_employees } from '../../AccountingLogger/types/GetAccountingEmployees';
import { GetAccountingMissions_missions } from '../../AccountingLogger/types/GetAccountingMissions';
import { GetAccountingMaterials_activeMaterialCatalog_materials } from '../../AccountingLogger/types/GetAccountingMaterials';
import { GetAccountingVehicles_vehicles } from '../../AccountingLogger/types/GetAccountingVehicles';
import { GetAccountingMachines_machines } from '../../AccountingLogger/types/GetAccountingMachines';
import { GetAccountingActivityTypes_activityTypes } from '../../AccountingLogger/types/GetAccountingActivityTypes';
import { filterChargeConstraints, parseChargeConstraint } from '../../utils/chargeConstraint';
import {
  getSearchableMaterial,
  searchableActivityType,
  searchableCollectiveAccount,
  searchableEmployee,
  searchableMachine,
  searchableMaterial,
  searchableMission,
  searchableVehicle,
} from '../../utils/searchable';
import { GetAccountingCollectiveAccounts_collectiveAccounts } from '../../AccountingLogger/types/GetAccountingCollectiveAccounts';
import { DataEntryForm, IManualAccountingFormValues } from './DataEntryForm';
import { accessSomeEntity, entityFinder } from './utils/mapFormToAccountingItem';

interface IDataEntryFormContainerProps {
  employees: readonly GetAccountingEmployees_employees[];
  missions: readonly GetAccountingMissions_missions[];
  materials: readonly GetAccountingMaterials_activeMaterialCatalog_materials[];
  vehicles: readonly GetAccountingVehicles_vehicles[];
  machines: readonly GetAccountingMachines_machines[];
  collectiveAccounts: readonly GetAccountingCollectiveAccounts_collectiveAccounts[];
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
  disabled?: boolean;
  edit?: boolean;
}

/**
 * Container for data entry form with more logic
 * @param employees the employees
 * @param missions the missions
 * @param materials the materials
 * @param vehicles the vehicles
 * @param machines the machines
 * @param collectiveAccounts the collective accounts
 * @param activityTypes the activity types
 * @param disabled is the form disabled?
 * @param edit is the form in edit mode?
 * @constructor
 */
export const DataEntryFormContainer: React.FC<IDataEntryFormContainerProps> = ({
  employees,
  missions,
  materials,
  vehicles,
  machines,
  collectiveAccounts,
  activityTypes,
  disabled = false,
  edit = false,
}) => {
  const indexedActivityTypes = useMemo(
    () =>
      Object.fromEntries<typeof activityTypes[number]>(
        activityTypes.map((activityType) => [searchableActivityType(activityType), activityType]),
      ),
    [activityTypes],
  );
  const activityTypeSuggestions = useMemo(
    () => activityTypes.map(searchableActivityType),
    [activityTypes],
  );
  const validateActivityTypeSuggestion = useCallback(
    (value: string | undefined) => {
      if (value && !activityTypeSuggestions.includes(value)) {
        return 'Leistungsart existiert nicht!';
      }
    },
    [activityTypeSuggestions],
  );

  const [{ value: selectedActivityType }] =
    useField<IManualAccountingFormValues['activityType']>('activityType');
  const activityType = indexedActivityTypes[selectedActivityType];
  // the relevant charge constraints for this activity type to filter out suggestions
  const relevantChargeConstraints = useMemo(
    () =>
      filterChargeConstraints({
        activityType: activityType?.number,
      }),
    [activityType],
  );
  const employeeSuggestions = useMemo(() => employees.map(searchableEmployee), [employees]);
  const missionSuggestions = useMemo(() => missions.map(searchableMission), [missions]);
  const materialSuggestions = useMemo(() => materials.map(searchableMaterial), [materials]);
  const vehicleSuggestions = useMemo(() => vehicles.map(searchableVehicle), [vehicles]);
  const machineSuggestions = useMemo(() => machines.map(searchableMachine), [machines]);
  const collectiveAccountSuggestions = useMemo(
    () => collectiveAccounts.map(searchableCollectiveAccount),
    [collectiveAccounts],
  );
  // get credited to suggestions based on allowed charge constraints
  const creditedToSuggestions = useMemo(() => {
    // we will use these creditedTo numbers as indices to select the relevant suggestions from the candidates below
    const relevantCreditedTo = Array.from(
      new Set(
        relevantChargeConstraints.map(parseChargeConstraint).map(({ creditedTo }) => creditedTo),
      ),
    );
    // the candidate suggestions ordered by their CreditedToIndex (-1)
    const candidateSuggestions = [
      employeeSuggestions,
      machineSuggestions,
      vehicleSuggestions,
      materialSuggestions,
      collectiveAccountSuggestions,
    ];
    return relevantCreditedTo.flatMap((creditedTo) => candidateSuggestions[creditedTo - 1]);
  }, [
    relevantChargeConstraints,
    employeeSuggestions,
    machineSuggestions,
    vehicleSuggestions,
    materialSuggestions,
    collectiveAccountSuggestions,
  ]);
  const validateCreditedToSuggestion = useCallback(
    (value: string | undefined) => {
      if (value && !creditedToSuggestions.includes(value)) {
        return 'Zugunsten existiert nicht!';
      }
    },
    [creditedToSuggestions],
  );
  // get charged to suggestions based on allowed charge constraints
  const chargedToSuggestions = useMemo(() => {
    // we will use these creditedTo numbers as indices to select the relevant suggestions from the candidates below
    const relevantChargedTo = Array.from(
      new Set(
        relevantChargeConstraints.map(parseChargeConstraint).map(({ chargedTo }) => chargedTo),
      ),
    );
    // the candidate suggestions ordered by their CreditedToIndex (-1)
    const candidateSuggestions = [
      missionSuggestions,
      machineSuggestions,
      vehicleSuggestions,
      materialSuggestions,
      collectiveAccountSuggestions,
    ];
    return relevantChargedTo.flatMap((chargedTo) => candidateSuggestions[chargedTo - 1]);
  }, [
    relevantChargeConstraints,
    missionSuggestions,
    machineSuggestions,
    vehicleSuggestions,
    materialSuggestions,
    collectiveAccountSuggestions,
  ]);
  const validateChargedToSuggestion = useCallback(
    (value: string | undefined) => {
      if (value && !chargedToSuggestions.includes(value)) {
        return 'Zulasten existiert nicht!';
      }
    },
    [chargedToSuggestions],
  );

  // find the selected entities in order to provide meta data for display
  const findEntity = useMemo(
    () => entityFinder(employees, missions, materials, vehicles, machines, collectiveAccounts),
    [employees, missions, materials, vehicles, machines, collectiveAccounts],
  );
  const [{ value: selectedCreditedTo }] =
    useField<IManualAccountingFormValues['creditedTo']>('creditedTo');
  const creditedTo = useMemo(() => {
    try {
      return findEntity(selectedCreditedTo)?.[1];
    } catch {
      // not found
    }
  }, [findEntity, selectedCreditedTo]);
  const [{ value: selectedChargedTo }] =
    useField<IManualAccountingFormValues['chargedTo']>('chargedTo');
  const chargedTo = useMemo(() => {
    try {
      return findEntity(selectedChargedTo)?.[1];
    } catch {
      // not found
    }
  }, [findEntity, selectedChargedTo]);
  const unit =
    activityType?.unit?.acronym ??
    getSearchableMaterial(selectedCreditedTo, materials)?.unit.acronym ??
    getSearchableMaterial(selectedChargedTo, materials)?.unit.acronym ??
    '';

  const activityTypeMeta = useMemo(() => {
    const pricePerUnit =
      activityType?.pricePerUnit ??
      accessSomeEntity('function', creditedTo)?.hourlyWage ??
      accessSomeEntity('pricePerUnit', creditedTo) ??
      accessSomeEntity('internalHourlyWage', creditedTo);
    const manualPricePerUnit = pricePerUnit == null && activityType != null && creditedTo != null;
    return { nameTwo: activityType?.nameTwo, pricePerUnit, manualPricePerUnit };
  }, [activityType, creditedTo]);

  const creditedToMeta = useMemo(() => {
    const nameTwo = accessSomeEntity('nameTwo', creditedTo);
    const workload = accessSomeEntity('workload', creditedTo);
    const subsidiary = accessSomeEntity('subsidiary', creditedTo);
    return { nameTwo, workload, subsidiary: subsidiary?.name };
  }, [creditedTo]);
  const chargedToMeta = useMemo(() => {
    const nameTwo = accessSomeEntity('nameTwo', chargedTo);
    const projectNumber = accessSomeEntity('project', chargedTo)?.projectNumber;
    const subsidiary =
      accessSomeEntity('project', chargedTo)?.subsidiary ??
      accessSomeEntity('subsidiary', chargedTo);
    return { nameTwo, projectNumber, subsidiary: subsidiary?.name };
  }, [chargedTo]);

  return (
    <Section>
      <Heading>Buchung</Heading>
      <DataEntryForm
        disabled={disabled}
        unit={unit}
        activityTypeMeta={activityTypeMeta}
        activityTypeSuggestions={activityTypeSuggestions}
        validateActivityTypeSuggestion={validateActivityTypeSuggestion}
        creditedToMeta={creditedToMeta}
        creditedToSuggestions={creditedToSuggestions}
        validateCreditedToSuggestion={validateCreditedToSuggestion}
        chargedToMeta={chargedToMeta}
        chargedToSuggestions={chargedToSuggestions}
        validateChargedToSuggestion={validateChargedToSuggestion}
        edit={edit}
      />
    </Section>
  );
};
