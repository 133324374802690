import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useField, FieldProps } from 'formik';
import { Document } from './Document';
import { AcceptedMimeType } from '../../../components/Form/UploadField/acceptedMimeTypes';
import { DocumentPlaceholder } from './DocumentPlaceholder';

export interface IDocument {
  id: string;
  name: string;
  path: string;
  mimetype: AcceptedMimeType;
}

interface IProps {
  initialFiles: IDocument[];
  accept?: string;
}

const NEWLY_CREATED_ID = '__new__';

export const DocumentViewer: React.FC<IProps & FieldProps> = ({
  initialFiles,
  accept,
  field: { name, value },
}) => {
  const [, , { setValue }] = useField(name);

  const [documents, setDocuments] = useState<IDocument[]>(initialFiles);

  const handleCreate = (file: File) => {
    setValue({ ...value, uploads: [...(value.uploads || []), file] });

    const url = URL.createObjectURL(file);

    setDocuments((arr) => [
      ...arr,
      {
        id: NEWLY_CREATED_ID,
        name: file.name,
        path: url,
        mimetype: file.type as AcceptedMimeType,
      },
    ]);
  };

  const handleDelete = (id: string) => {
    setValue({ ...value, deletedFiles: [...(value.deletedFiles || []), id] });
    setDocuments((arr) => arr.filter((document) => document.id !== id));
  };

  return (
    <Grid item container spacing={1}>
      {documents.map((document, idx) => {
        const isNew = document.path.startsWith('blob:');

        return (
          <Grid item container xs={3} key={`${document.id}_${idx}`}>
            <Document {...document} onDelete={!isNew ? handleDelete : undefined} />
          </Grid>
        );
      })}
      <Grid item container xs={3}>
        <DocumentPlaceholder accept={accept} onCreate={handleCreate} />
      </Grid>
    </Grid>
  );
};
