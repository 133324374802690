import ApolloClient from 'apollo-client';
import { isEmpty, uniq } from 'lodash';
import { executeForPopulatedBoqTableTypes } from '../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { buildBoqTableRowId } from '../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/mapper';
import { UPDATE_DATA_TABLE_ROWS } from '../../../services/graphql-client';
import {
  UpdateDataTableRows,
  UpdateDataTableRowsVariables,
} from '../../../services/types/UpdateDataTableRows';
import { PAGINATION_HELPERS } from '../../../utils/paginationHelpers';
import {
  buildMissionLocationId,
  IMissionLocationIdInput,
} from './MissionSelector/paginationHelpers';
import { executeForPopulatedMissionTableTypes } from './MissionSelector/paginationHelpers/executeForMissionTypes';
import { buildTableId } from './MissionSelector/paginationHelpers/mapper';
import { IItemWithAmount } from './TabMissions';

const refetchItemsAfterAdd = (
  selectedItems: IItemWithAmount[],
  selectedMissionId: number,
  projectNumber: string,
  client: ApolloClient<any>,
  refetchContainerIds?: IMissionLocationIdInput[],
) => {
  const missionLocationIds =
    refetchContainerIds && refetchContainerIds.length > 0
      ? uniq(
          refetchContainerIds.map((location) =>
            buildMissionLocationId(selectedMissionId, location),
          ),
        )
      : uniq(selectedItems.map((item) => buildMissionLocationId(selectedMissionId, item.location)));

  executeForPopulatedMissionTableTypes(client, projectNumber, async (tableType) => {
    return PAGINATION_HELPERS[projectNumber][tableType]?.refetchItemsInContainer(
      missionLocationIds.map((locationId) => ({
        containerId: buildTableId(tableType)(locationId),
      })),
    );
  });
};

/**
 * should be called from tabs "Einsatz" and "Ausmass"
 *
 * removes fully added items from boq
 * refetches computed fields of not fully added items in boq
 * refetches destination-locations in mission
 * refetches mission computed fields
 */
export const handleMissionLikeTabSubmitInCache = async (
  selectedItems: IItemWithAmount[],
  selectedMissionId: number,
  projectNumber: string,
  client: ApolloClient<any>,
  refetchContainerIds?: IMissionLocationIdInput[],
) => {
  refetchItemsAfterAdd(
    selectedItems,
    selectedMissionId,
    projectNumber,
    client,
    refetchContainerIds,
  );

  await executeForPopulatedMissionTableTypes(client, projectNumber, (tableType) => {
    return PAGINATION_HELPERS[projectNumber][tableType]?.updateComputedFields({
      id: selectedMissionId,
      project: { projectNumber },
    });
  });

  if (selectedItems.length < 1) {
    Promise.resolve();
    return;
  }

  // only remove items which are added to mission with 100% or more of their volume
  const itemsFullyPlanned = selectedItems.filter((item) => item.amount >= item.openVolume);

  executeForPopulatedBoqTableTypes(client, projectNumber, async (tableType) => {
    for (const item of itemsFullyPlanned) {
      await PAGINATION_HELPERS[projectNumber][tableType]?.removeItemFromCache({
        data: { deleteItem: { __typename: 'Item', id: item.id } },
      });
    }

    const billOfQuantityId = selectedItems[0].location.billOfQuantity.id;

    const itemsPartiallyPlanned = selectedItems.filter((item) => item.amount < item.openVolume);
    if (!isEmpty(itemsPartiallyPlanned)) {
      await client.query<UpdateDataTableRows, UpdateDataTableRowsVariables>({
        query: UPDATE_DATA_TABLE_ROWS,
        variables: {
          where: { id: tableType },
          data: itemsPartiallyPlanned.map((item) => ({
            where: { id: buildBoqTableRowId(tableType)(item.id), tableType },
            data: {
              partial: true,
              data: JSON.stringify({ openVolume: item.openVolume - item.amount }),
            },
          })),
        },
      });

      await PAGINATION_HELPERS[projectNumber][tableType]?.refetchItemsComputedFields(
        itemsPartiallyPlanned.map((item) => item.id),
        billOfQuantityId,
      );
    }
  });
};
