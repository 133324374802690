import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import classnames from 'classnames';

export const HOVERING_BUTTON_BOTTOM_SPACING = 2.5;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'fixed',
    bottom: theme.spacing(HOVERING_BUTTON_BOTTOM_SPACING),
    left: '0',
    textAlign: 'center',
    width: '100%',
  },
  btn: {
    borderRadius: '50px',
    fontSize: theme.typography.h6.fontSize,
    padding: theme.spacing(2, 4),
  },
}));

export const HoveringButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  const classes = useStyles();

  const btnClassName = classnames(classes.btn, { [props.className || '']: props.className });

  return (
    <div className={classes.container}>
      <Button {...props} className={btnClassName}>
        {children}
      </Button>
    </div>
  );
};
