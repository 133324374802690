import React, { createRef, useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import { Circle, CircleMarker, Map, Marker, TileLayer, WMSTileLayer } from 'react-leaflet';
import './leaflet.css';
import L, { LatLng, LatLngBounds } from 'leaflet';
import RoomIcon from '@material-ui/icons/Room';
import MapIcon from '@material-ui/icons/Map';
import { HoveringButton } from '../HoveringButton';
import { HOVERING_BUTTON_BOTTOM_SPACING } from '../HoveringButton/HoveringButton';
import { isEmpty } from 'lodash';

const MAX_ZOOM = 20;

const SWISSTOPO_API_URL = 'https://api3.geo.admin.ch/rest/services/all/MapServer/identify';

/*
this looks kind of janky but it is the best solution for the vh problem on mobile browsers
that I have found so far - also doesn't interfere with anything else
https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
const setDocHeight = () =>
  document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);

window.addEventListener('resize', setDocHeight);
window.addEventListener('orientationchange', setDocHeight);
setDocHeight();

const useStyles = makeStyles((theme: Theme) => ({
  map: {
    width: '100vw',
    height: '100%',
    zIndex: 0,
  },
  container: {
    height: 'calc(var(--vh, 1vh) * 100 - 64px)',
  },
  btn: {
    fontSize: 16,
    padding: theme.spacing(0, 4),
    height: 44,
    marginTop: '15px',
    '& svg': {
      marginRight: 8,
    },
  },
  switchLayerButton: {
    position: 'absolute',
    bottom: theme.spacing(HOVERING_BUTTON_BOTTOM_SPACING),
    left: 0,
    zIndex: 400,
    color: 'white',
    minWidth: 0,
    width: 40,
    height: 44,
  },
}));

interface IMobileMapProps {
  coordinates?: LatLng;
  onSubmit: (cordnateValue: string) => void;
}

interface IMapLayer {
  url: string;
  maxZoom: number;
}

const mapLayers: IMapLayer[] = [
  {
    url: 'https://wmts20.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg',
    maxZoom: MAX_ZOOM,
  },
  {
    url: 'https://wmts20.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg',
    maxZoom: 18,
  },
];

// these layers will always be shown on top of the selected 'main' layer
// additional WMS layers have to be implemented separately
const additionalTileLayers = [
  'https://wmts20.geo.admin.ch/1.0.0/ch.bav.haltestellen-oev/default/current/3857/{z}/{x}/{y}.png',
];

const convertGeolocationPosToLatLng = (pos: GeolocationPosition): LatLng => {
  return new LatLng(pos.coords.latitude, pos.coords.longitude);
};

const convertLatLngTo3857Coords = (latlng: LatLng): L.Point =>
  L.Projection.SphericalMercator.project(latlng);

const MobileMap: React.FC<IMobileMapProps> = ({ coordinates, onSubmit }) => {
  // this block is needed to prevent a bug that appears when the Leaflet stylesheet is
  // imported into a component instead of normally linked
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const L = require('leaflet');

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
  }, []);

  const classes = useStyles();

  const [devicePosition, setDevicePosition] = useState<LatLng>();
  const [initialDevicePosition, setInitialDevicePosition] = useState<LatLng>();
  const [devicePositionAccuracy, setDevicePositionAccuracy] = useState<number>();
  const [selectedMapLayer, setSelectedMapLayer] = useState<number>(0);
  const [markerHasBeenMoved, setMarkerHasBeenMoved] = useState<boolean>(!!coordinates);
  const [markerPosition, setMarkerPosition] = useState<LatLng | undefined>(coordinates);
  const [watchId, setWatchId] = useState<number>();
  const [mapManuallyMoved, setMapManuallyMoved] = useState<boolean>(false);

  const { geolocation } = navigator;

  const refMap = createRef<Map>();
  const refMainTileLayer = createRef<TileLayer>();

  useEffect(() => {
    const mainTileLayer = refMainTileLayer.current;
    if (mainTileLayer) {
      mainTileLayer.leafletElement.bringToBack();
    }
  }, [selectedMapLayer, refMainTileLayer]);

  useEffect(() => {
    if (!watchId) {
      setWatchId(
        geolocation.watchPosition(
          (position) => {
            setDevicePosition(convertGeolocationPosToLatLng(position));
            setDevicePositionAccuracy(position.coords.accuracy);
          },
          undefined,
          { enableHighAccuracy: true },
        ),
      );
    }

    return () => {
      if (watchId) {
        geolocation.clearWatch(watchId);
      }
    };
  }, [watchId, geolocation]);

  useEffect(() => {
    if (!markerHasBeenMoved) {
      setMarkerPosition(devicePosition);
    }
    if (!initialDevicePosition) {
      setInitialDevicePosition(devicePosition);
    }

    if (!mapManuallyMoved) {
      const map = refMap.current?.leafletElement;
      if (map && devicePosition) {
        map.panTo(devicePosition);
      }
    }
  }, [devicePosition, initialDevicePosition, mapManuallyMoved, markerHasBeenMoved, refMap]);

  const refMarker = createRef<Marker>();
  const refCurrentPosMarker = createRef<CircleMarker>();

  const updateNewMarkerPosition = () => {
    const marker = refMarker.current;
    if (!markerHasBeenMoved) {
      setMarkerHasBeenMoved(true);
    }
    if (marker) {
      setMarkerPosition(marker.leafletElement.getLatLng());
    }
  };

  const switchMap = () => {
    if (selectedMapLayer === mapLayers.length - 1) {
      setSelectedMapLayer(0);
      return;
    }
    setSelectedMapLayer(selectedMapLayer + 1);
  };

  const addNewMarker = () => {
    onSubmit(`${markerPosition?.lat},${markerPosition?.lng}`);
  };

  const mapOnClickListener = (e: any) => {
    const map = refMap.current;
    if (map) {
      const url = new URL(SWISSTOPO_API_URL);
      const { searchParams } = url;
      const { x, y } = convertLatLngTo3857Coords(e.latlng);
      searchParams.append('geometryType', 'esriGeometryPoint');
      searchParams.append('sr', '3857');
      searchParams.append('geometry', `${x},${y}`);
      searchParams.append('layers', 'all:ch.bav.haltestellen-oev');
      searchParams.append('tolerance', '10');
      searchParams.append('geometryFormat', 'geojson');
      searchParams.append('limit', '1');

      const bounds = map.leafletElement.getBounds();
      const { x: xMin, y: yMin } = convertLatLngTo3857Coords(bounds.getNorthWest());
      const { x: xMax, y: yMax } = convertLatLngTo3857Coords(bounds.getSouthEast());
      searchParams.append('mapExtent', `${xMin},${yMin},${xMax},${yMax}`);
      searchParams.append(
        'imageDisplay',
        // there is no way to get actual device ppi so assume 96 (default for most browsers)
        // and multiply it by devicePixelRatio if present
        `${map.container?.offsetWidth},${map.container?.offsetHeight},${
          96 * (window.devicePixelRatio ?? 1)
        }`,
      );
      fetch(url.toString())
        .then((data) => data.json())
        .then((data) => {
          if (!isEmpty(data.results)) {
            map.leafletElement.openPopup(
              L.popup().setLatLng(e.latlng).setContent(data.results[0].properties.name),
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Grid container direction="column" alignItems="center" className={classes.container}>
      <Map
        center={!coordinates ? initialDevicePosition : undefined}
        bounds={
          initialDevicePosition && coordinates
            ? new LatLngBounds(initialDevicePosition, coordinates).pad(0.5)
            : undefined
        }
        zoom={MAX_ZOOM}
        className={classes.map}
        minZoom={8}
        maxZoom={MAX_ZOOM}
        maxBounds={
          new LatLngBounds([
            [45.034, 4.268],
            [48.72357, 12.639],
          ])
        }
        zoomControl={false}
        attributionControl={false}
        ref={refMap}
        setView={false}
        bounceAtZoomLimits={false}
        onmove={(event) => {
          const eventType = (event as any)?.originalEvent?.type;
          if (eventType === 'mousemove' || eventType === 'touchmove') {
            setMapManuallyMoved(true);
          }
        }}
        onclick={mapOnClickListener}
      >
        {
          // little bit contrived but needs to be done this way because otherwise maxNativeZoom won't update
          //  to new value from new layer (see react-leaflet docs concerning dynamic/static props)
          mapLayers.map((layer, index) =>
            index === selectedMapLayer ? (
              <TileLayer
                url={layer.url}
                maxZoom={MAX_ZOOM}
                maxNativeZoom={layer.maxZoom}
                ref={refMainTileLayer}
                key={layer.url}
              />
            ) : null,
          )
        }
        <WMSTileLayer
          url={'https://wms.geo.admin.ch/?'}
          layers={'ch.swisstopo.amtliches-strassenverzeichnis'}
          format={'png'}
          transparent={true}
        />
        {additionalTileLayers.map((layer) => (
          <TileLayer url={layer} key={layer} maxZoom={MAX_ZOOM} maxNativeZoom={18} />
        ))}
        <WMSTileLayer
          url="https://www.ag.ch/geoportal/services/atb_rbbs_wms/MapServer/WMSServer?"
          layers={'0,3'}
          format={'png'}
          transparent={true}
        />
        {devicePosition && (
          <CircleMarker
            center={devicePosition}
            radius={10}
            color={'white'}
            fillColor={'#3388ff'}
            fillOpacity={1}
            weight={2}
            ref={refCurrentPosMarker}
          />
        )}
        {devicePosition && devicePositionAccuracy && (
          <Circle center={devicePosition} radius={devicePositionAccuracy} weight={1} />
        )}
        {markerPosition && (
          <Marker
            position={markerPosition}
            draggable={true}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onDragend={updateNewMarkerPosition}
            ref={refMarker}
          />
        )}
      </Map>
      <Button variant="text" className={classes.switchLayerButton} onClick={switchMap}>
        <MapIcon />
      </Button>
      <HoveringButton
        variant="contained"
        color="primary"
        onClick={addNewMarker}
        className={classes.btn}
      >
        <RoomIcon />
        Punkt hinzufügen
      </HoveringButton>
    </Grid>
  );
};

export default MobileMap;
