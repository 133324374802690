import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  heading: {
    paddingTop: '0.5em',
  },
  sectionContainer: {
    padding: '1em 0.5em',
    width: '100%',
  },
  section: {
    padding: '0.5em 1.5em 1.5em 1.5em',
  },
}));

/**
 * A Heading component for a standardized section.
 * @param children the heading text
 * @constructor
 */
export const Heading: React.FC<{ children: string }> = React.memo(({ children }) => {
  const styles = useStyles();
  return (
    <Typography variant="h5" className={styles.heading}>
      {children}
    </Typography>
  );
});

/**
 * Standardized Wrapper for sections of the accounting logger
 * @param children the section content to render
 * @constructor
 */
export const Section: React.FC<{ children: React.ReactNode }> = React.memo(({ children }) => {
  const styles = useStyles();
  return (
    <Grid item xs={12} className={styles.sectionContainer}>
      <Paper className={styles.section}>{children}</Paper>
    </Grid>
  );
});
