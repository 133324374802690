import { Maybe } from 'graphql/jsutils/Maybe';
import { IDataTableRow } from '../../../../../components/DataTable/types';
import {
  GetDataTable_dataTable_rows,
  GetDataTable_dataTable_rows_innerTableRows,
} from '../../../../../services/types/GetDataTable';
import { DataTableRowInput, TableType } from '../../../../../types/graphql';
import { formatDate } from '../../../../../utils/format/date';
import { missionItemViewFields } from './types/missionItemViewFields';
import { missionStructureFields } from './types/missionStructureFields';

export type MissionLocationTableId = string;

export const buildTableId =
  (tableType: TableType) =>
  (compositeId: string): MissionLocationTableId =>
    `${compositeId}-${tableType}`;
export const removeTableTypeFromId = (id: MissionLocationTableId): string =>
  id.replace(
    /-(MISSION|MEASUREMENT|BILL|OFFER|ORDER|ORDER_MISSION|ORDER_MEASUREMENT|BILL_MISSION)$/,
    '',
  );
export const swapTableType =
  (tableType: TableType) =>
  (id: string): string =>
    buildTableId(tableType)(removeTableTypeFromId(id));

export const mapMissionStatus = (
  {
    missionStatus,
    calendarWeek,
    date,
    untilDate,
    time,
  }: Exclude<missionStructureFields['status'], undefined | null>,
  executionDate: any,
): Maybe<string> => {
  const mapping = {
    KW: calendarWeek,
    FT: executionDate ? `${formatDate(executionDate)} ${time ? time : ''}` : '',
    BD: formatDate(untilDate),
    VD: `${formatDate(date)} - ${formatDate(untilDate)}`,
  };

  const matchingItem = mapping[missionStatus as keyof typeof mapping];

  return matchingItem ? `${missionStatus} ${matchingItem}` : missionStatus;
};

export const mapMissionStructureToDataTableRowInput =
  (tableType: TableType) =>
  (mission: missionStructureFields): DataTableRowInput => {
    const missionId = mission.id.toString();

    return {
      __typename: 'DataTableRow',
      id: buildTableId(tableType)(missionId),
      hidden: false,
      data: JSON.stringify({
        ...mission,
        status: mission.status ? mapMissionStatus(mission.status, mission.executionDate) : null,
        hasDescendants: mission.defaultMissionItemLocation.hasDescendants,
      }),
      containerRows: mission.defaultMissionItemLocation.locations.map((locationOne, index) => {
        return {
          __typename: 'DataTableRow',
          id: buildTableId(tableType)(`${missionId}-${locationOne.id!}`),
          hidden: false,
          data: JSON.stringify({ ...locationOne, index }),
          containerRows: locationOne.locations.map((locationTwo, index) => {
            return {
              __typename: 'DataTableRow',
              id: buildTableId(tableType)(`${missionId}-${locationOne.id}-${locationTwo.id!}`),
              hidden: false,
              data: JSON.stringify({ ...locationTwo, index }),
              containerRows: [],
              innerTableRows: [],
            };
          }),
          innerTableRows: [],
        };
      }),
      innerTableRows: [],
    };
  };

export const mapMissionItem = (missionItem: missionItemViewFields): any => {
  return {
    ...missionItem.item,
    ...missionItem,
    finishedVolume: missionItem.finishedVolume ?? 0,
    missionHasExecutionDate: !!missionItem.mission?.executionDate,
    // references the finishedVolume which is NOT a input-field in measurement
    // TODO migrate to correct name because it should not map to .item.finishedVolume (needs to be migrated in tableSettings too)
    itemFinishedVolume: missionItem.finishedVolume ?? 0,
    id: missionItem.id,
    billOfQuantity: missionItem.item.billOfQuantity!.name,
  };
};

export const mapMissionItemToRowInput =
  (tableType: TableType) =>
  (missionItem: any): DataTableRowInput => {
    return {
      __typename: 'DataTableRow',
      hidden: false,
      id: buildTableId(tableType)(missionItem.id),
      data: JSON.stringify(missionItem),
    };
  };

export const mapMissionItemRowToDataTableRow = (
  missionItem: GetDataTable_dataTable_rows_innerTableRows,
): IDataTableRow => ({
  id: missionItem.id,
  data: mapMissionItem(JSON.parse(missionItem.data)),
  containerRows: [],
  innerTableRows: [],
});

export const mapMissionRowsToTableData = (
  missionRows: GetDataTable_dataTable_rows[],
): IDataTableRow[] => {
  return missionRows
    .map((missionRow) => {
      const mission = JSON.parse(missionRow.data);

      const locationOnes = missionRow.containerRows ?? [];

      return {
        id: `${missionRow.id}`,
        data: mission,
        innerTableRows: missionRow.innerTableRows?.map(mapMissionItemRowToDataTableRow) ?? [],
        containerRows: locationOnes.map((locationOne, locationOneIdx) => {
          const locationTwos = locationOne.containerRows ?? [];
          const data = JSON.parse(locationOne.data);
          const index = data.index ?? locationOneIdx;

          return {
            id: locationOne.id,
            data: { ...data, index },
            innerTableRows: locationOne.innerTableRows?.map(mapMissionItemRowToDataTableRow) ?? [],
            containerRows: locationTwos.map((locationTwo, locationTwoIdx) => {
              const data = JSON.parse(locationTwo.data);
              const index = data.index ?? locationTwoIdx;

              return {
                id: locationTwo.id,
                data: { ...data, index },
                innerTableRows:
                  locationTwo.innerTableRows?.map(mapMissionItemRowToDataTableRow) ?? [],
              };
            }),
          };
        }),
      };
    })
    .sort((a, b) => {
      const result = parseInt(b.id, 10) - parseInt(a.id, 10);

      return result;
    });
};
