import { IDataTableRow } from '../../components/DataTable/types';
import { translateBool } from '../../utils/bool.translate.util';
import { formatDate } from '../../utils/format/date';
import { createSwissCurrencyFormatter } from '../../utils/createCurrencyFormatter';
import { ITableContext } from '../../components/DataTable';
import { downloadCsv, handleDownloadCsvClickUtil } from '../../utils/csv';
import { VehicleCategory } from '../../components/Vehicle/VehicleForm';
import { GetVehicles_vehicles } from './types/GetVehicles';

const currencyFormatter = createSwissCurrencyFormatter({ withCurrency: true });

export const mapToDataTable = (vehicles: GetVehicles_vehicles[]): IDataTableRow[] => {
  return vehicles.map((vehicle) => {
    return {
      id: vehicle.id,
      data: {
        ...vehicle,
        category: VehicleCategory[vehicle.category],
        'subsidiary.name': vehicle.subsidiary?.name ?? '',
        internalHourlyWage: currencyFormatter(vehicle.internalHourlyWage),
        isDeactivated: translateBool(vehicle.isDeactivated),
        releaseDate: formatDate(vehicle.releaseDate),
        emissionsTestDate: formatDate(vehicle.emissionsTestDate),
        trafficOfficeDate: formatDate(vehicle.trafficOfficeDate),
        lastInternalCheckAt: formatDate(vehicle.lastInternalCheckAt),
        kilometersUser: vehicle.kilometersHistory?.[0]?.createdBy.name ?? '',
        kilometersDate: vehicle.kilometersHistory?.[0]
          ? formatDate(vehicle.kilometersHistory[0].createdAt)
          : '',
      },
    };
  });
};

export const handleDownloadCsvClick = (context: ITableContext) => {
  downloadCsv(
    handleDownloadCsvClickUtil(context.tableData.pageRows.innerTableRows || [], (rowData) => {
      return {
        Inventarnummer: rowData.inventoryNumber,
        Projektnummer: rowData.projectNumber,
        Kategorie: rowData.category,
        Filiale: rowData.subsidiary,
        Fahrer: rowData.driver,
        'Bezeichnung 1': rowData.nameOne,
        'Bezeichnung 2': rowData.nameTwo,
        Jahrgang: rowData.releaseDate,
        Gewicht: rowData.weight,
        Kennzeichen: rowData.registrationNumber,
        Kilometerstand: rowData.kilometers,
        'Stundensatz intern': rowData.internalHourlyWage,
        Abgasuntersuchung: rowData.emissionsTestDate,
        'STVA Termin': rowData.trafficOfficeDate,
        Bemerkung: rowData.note,
        Anhängerlast: rowData.trailerLoad,
        'Tankkarte 1': rowData.fuelCardOne,
        'Tankkarte 2': rowData.fuelCardTwo,
        'Pin 1': rowData.pinOne,
        'Pin 2': rowData.pinTwo,
        'TCS ab': rowData.tcsAsof,
        eVignette: rowData.eVignette,
        Stammnummer: rowData.serialNumber,
        Fahrgestellnummer: rowData.chassisNumber,
        'Letzte Prüfung intern': rowData.lastInternalCheckAt,
        Inaktiv: rowData.isDeactivated,
      };
    }),
    `Fahrzeuge_${formatDate()}`,
  );
};
