import React from 'react';
import { DataTableBody, DataTableWrapper, IDataTableProps } from '../../../components/DataTable';

export const MobileDataTable: React.FC<IDataTableProps> = (props) => (
  <DataTableWrapper
    {...props}
    options={{ noMinWidth: true, hideInterface: true, ...props.options }}
  >
    {(context) => <DataTableBody context={context} />}
  </DataTableWrapper>
);
