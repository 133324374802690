import { MutableRefObject, useCallback, useRef } from 'react';
import { sleepUntil } from '../utils/sleep';

/**
 * recursive debouncer based on mutable refs. if called multiple times the state transitions to "pending" meaning it
 * will be called again once finished.
 * @param fnRef the ref to the function to run
 * @param debounce is the function currently running
 * @param keyDebounce used to debounce continuous key entries
 */
const debouncer = <F extends () => void>(
  fnRef: MutableRefObject<F>,
  debounce: MutableRefObject<boolean>,
  keyDebounce: MutableRefObject<Date>,
): Promise<void> => {
  // 400ms in the future, when called within this time it gets moved to the future delaying the calling of fn
  keyDebounce.current.setTime(new Date().getTime() + 400);
  if (!debounce.current) {
    debounce.current = true;
    return new Promise((resolve) => {
      window.requestAnimationFrame(async () => {
        while (new Date().getTime() < keyDebounce.current.getTime()) {
          await sleepUntil(keyDebounce.current);
        }
        await fnRef.current();
        debounce.current = false;
        resolve();
      });
    });
  }
  return Promise.resolve();
};

/**
 * can be used to debounce event streams. requests an animation frame and waits for an optional single previous fn to finish before running the fn again
 * @param fn the function to run
 * @returns a function that can be called multiple times but is debounced
 */
export const useDebounce = <F extends () => void>(fn: F): (() => Promise<void>) => {
  const debounce = useRef<boolean>(false);
  const keyDebounce = useRef<Date>(new Date());
  const fnRef = useRef<typeof fn>(fn);
  fnRef.current = fn;

  return useCallback(() => debouncer(fnRef, debounce, keyDebounce), []);
};
