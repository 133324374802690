import gql from 'graphql-tag';

export const GET_ALL_EMPLOYES = gql`
  query getAllEmployees {
    employees(where: { isDeactivated: false }) {
      id
      firstName
      lastName
    }
  }
`;
