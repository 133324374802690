import React from 'react';
import { Formik, Field, Form } from 'formik';
import FormikTextField from '../../../components/Form/FormikTextField';
import { Grid, Button, makeStyles, Typography } from '@material-ui/core';
import * as Yup from 'yup';

interface IProps {
  onSubmit: (values: any) => Promise<any>;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: '30%',
  },
  form: {
    position: 'relative',
    height: '50vh',
  },
}));

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name ist verpflichtend!'),
});

const CreateLocationForm: React.FC<IProps> = ({ onSubmit, onClose }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item container xs={12}>
        <Typography variant="h5">Örtlichkeit erstellen</Typography>
      </Grid>
      <Formik
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values);
          setSubmitting(false);
          onClose();
        }}
        initialValues={{ name: '' }}
      >
        {() => {
          return (
            <Form className={classes.form}>
              <Grid container justify="space-between" spacing={3} className={classes.container}>
                <Grid item container xs={12} justify="center">
                  <Field name="name" label="Name *" component={FormikTextField} />
                </Grid>
                <Grid item container xs={12} justify="space-around">
                  <Button variant="contained" size="large" onClick={() => onClose()}>
                    Abbrechen
                  </Button>
                  <Button variant="contained" color="primary" size="large" type="submit">
                    Erstellen
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateLocationForm;
