import React from 'react';
import { EMPLOYEE_FUNCTIONS } from './employee.queries';
import { EmployeeFunctions, EmployeeFunctionsVariables } from './types/EmployeeFunctions';
import { useQuery } from 'react-apollo';
import { IDataTableColumn, IDataTableRow } from '../../components/DataTable/types';
import { createSwissCurrencyFormatter } from '../../utils/createCurrencyFormatter';
import AppProgress from '../../components/Page/AppProgress';
import { DataTableHotKeysWrapper, DataTableBody } from '../../components/DataTable';
import { Search } from '../../components/Search';
import { useSearchState } from '../../components/Search/Search';

const currencyFormatter = createSwissCurrencyFormatter({ withCurrency: true });

const columns: IDataTableColumn[] = [
  { id: 'name', label: 'Name', width: 800 },
  {
    id: 'hourlyWage',
    label: 'Stundenansatz',
    width: 1600,
    render: (hourlyWage) => hourlyWage && currencyFormatter(hourlyWage),
  },
];

const mapToDataTable = (functions: any[]): IDataTableRow[] => {
  return functions.map((employeeFunction: any) => {
    return {
      id: employeeFunction.id,
      data: employeeFunction,
    };
  });
};

export const EmployeeFunctionsTable: React.FC = () => {
  const searchState = useSearchState();
  const { data, loading, error, refetch } = useQuery<EmployeeFunctions, EmployeeFunctionsVariables>(
    EMPLOYEE_FUNCTIONS,
  );

  if (!data || !data.employeeFunctions) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <DataTableHotKeysWrapper
        innerTableRows={mapToDataTable(data?.employeeFunctions || [])}
        search={
          <Search
            columns={['name']}
            searchState={searchState}
            onSubmit={(search) => refetch({ search })}
            loading={loading}
          />
        }
        options={{
          activeRowId: '',
          fixedWidthColumns: true,
          tableName: 'ALL_EMPLOYEE_FUNCTIONS',
          filterText: searchState.searchTerm,
          levels: [
            {
              columns,
            },
          ],
        }}
      >
        {(context) => <DataTableBody context={context} />}
      </DataTableHotKeysWrapper>
    </>
  );
};
