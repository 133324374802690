import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Paper, Theme } from '@material-ui/core';
import BillOfQuantityDetails from '../../components/BillOfQuantity/BillOfQuantityDetails';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const BillOfQuantityPaper: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.root}>
      <Switch>
        <Route exact path={'/auftraege/:id/details'} component={BillOfQuantityDetails} />
        <Route exact path={'/offerten/:id/details'} component={BillOfQuantityDetails} />
      </Switch>
    </Paper>
  );
};

export default BillOfQuantityPaper;
