import { pickBy, omit } from 'lodash';

const OMITED_FIELDS = ['id', '__typename'];

const isValidApiData = (value: unknown) => value !== '' && value !== null && value !== undefined;

export const filterInvalidData = (data: any) => {
  return pickBy(omit(data, OMITED_FIELDS), isValidApiData);
};

export const omitInvalidFields = (data: any) => omit(data, OMITED_FIELDS);
