import React from 'react';
import { Button, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface IClasses {
  saveBtn?: string;
  resetBtn?: string;
}

interface IProps {
  isDisabled: boolean;
  classes?: IClasses;
}

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.body1.fontSize,
  },
}));

export const MobileCancelSaveButtons: React.FC<IProps> = ({ isDisabled, classes }) => {
  const defaultClasses = useStyles();

  const resetBtnClassName = `${defaultClasses.btn} ${(classes && classes.resetBtn) || ''}`;
  const saveBtnClassName = `${defaultClasses.btn} ${(classes && classes.saveBtn) || ''}`;

  return (
    <Grid container item xs={12} justify="space-around">
      <Button type="reset" variant="contained" disabled={isDisabled} className={resetBtnClassName}>
        Zurücksetzen
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isDisabled}
        className={saveBtnClassName}
      >
        Speichern
      </Button>
    </Grid>
  );
};
