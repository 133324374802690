import { useMutation } from 'react-apollo';
import {
  CLEAR_CONTACT_PERSON_MUTATION,
  SELECT_CONTACT_PERSON_MUTATION,
  UPSERT_CONTACT_PERSON,
} from './query';
import { ClearContactPerson, ClearContactPersonVariables } from './types/ClearContactPerson';
import { SelectContactPerson, SelectContactPersonVariables } from './types/SelectContactPerson';
import { UpsertContactPerson, UpsertContactPersonVariables } from './types/UpsertContactPerson';

export const useContactDropdownQuery = () => {
  const [executeClearContactPerson, { loading, error }] = useMutation<
    ClearContactPerson,
    ClearContactPersonVariables
  >(CLEAR_CONTACT_PERSON_MUTATION);

  const [executeSelectContactPerson, { loading: loadingSelect, error: errorSelect }] = useMutation<
    SelectContactPerson,
    SelectContactPersonVariables
  >(SELECT_CONTACT_PERSON_MUTATION);

  return {
    loading: loadingSelect || loading,
    error: errorSelect ?? error,
    executeClearContactPerson,
    executeSelectContactPerson,
  };
};

export const useContactEditFetch = () => {
  const [executeUpsertContactPerson, { loading, error }] = useMutation<
    UpsertContactPerson,
    UpsertContactPersonVariables
  >(UPSERT_CONTACT_PERSON);

  return {
    loading,
    error,
    executeUpsertContactPerson,
  };
};
