import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { TableType } from '../../../../../types/graphql';
import { MissionTableType } from './executeForMissionTypes';
import { IsDataTablePopulated, IsDataTablePopulatedVariables } from './types/IsDataTablePopulated';

export const IS_DATA_TABLE_POPULATED_QUERY = gql`
  query IsDataTablePopulated($tableType: TableType!) {
    dataTable(where: { id: $tableType }) {
      rows {
        id
      }
    }
  }
`;

export const getPopulatedMissionTableTypes = (client: ApolloClient<any>): MissionTableType[] => {
  const isMissionPopulated = !isEmpty(
    client.cache.readQuery<IsDataTablePopulated, IsDataTablePopulatedVariables>({
      query: IS_DATA_TABLE_POPULATED_QUERY,
      variables: { tableType: TableType.MISSION },
    })?.dataTable?.rows,
  );
  const isMeasurementPopulated = !isEmpty(
    client.cache.readQuery<any>({
      query: IS_DATA_TABLE_POPULATED_QUERY,
      variables: { tableType: TableType.MEASUREMENT },
    })?.dataTable?.rows,
  );
  const isBillMissionPopulated = !isEmpty(
    client.cache.readQuery<any>({
      query: IS_DATA_TABLE_POPULATED_QUERY,
      variables: { tableType: TableType.BILL_MISSION },
    })?.dataTable?.rows,
  );

  const populatedTableTypes: MissionTableType[] = [];

  if (isMissionPopulated) {
    populatedTableTypes.push(TableType.MISSION);
  }

  if (isMeasurementPopulated) {
    populatedTableTypes.push(TableType.MEASUREMENT);
  }
  if (isBillMissionPopulated) {
    populatedTableTypes.push(TableType.BILL_MISSION);
  }

  return populatedTableTypes;
};
