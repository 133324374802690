import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import FormikTextField from '../Form/FormikTextField';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: 800,
  },
});

type IImportProps = DialogProps & {
  onSubmit: (...args: any) => void;
  onClose: (...args: any) => void;
};

const ImportDialog: React.FunctionComponent<IImportProps> = ({
  onSubmit,
  onClose,
  ...dialogProps
}) => {
  const classes = useStyles();
  return (
    <Dialog {...dialogProps} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle>Örtlichkeit Erstellen</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ name: '' }}
          onSubmit={(values: any) => {
            onSubmit(values);
          }}
        >
          <Form>
            <Field
              name="name"
              label="Name"
              component={FormikTextField}
              required
              autoFocus
              fullWidth
            />
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Abbrechen
              </Button>
              <Button type="submit" color="primary">
                Erstellen
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ImportDialog;
