import * as React from 'react';
import classNames from 'classnames';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/styles';
import { getIn } from 'formik';
import green from '@material-ui/core/colors/green';
import { ICheckboxProps, fieldToCheckbox } from './FormikCheckbox';
import { Omit } from './types';

/** Inspired by formik-material-ui */

const useStyles = makeStyles({
  dirty: {
    color: green[700],
  },
});

/**
 * Exclude props that are passed directly to the control
 * https://github.com/mui-org/material-ui/blob/v3.1.1/packages/material-ui/src/FormControlLabel/FormControlLabel.js#L71
 */
export interface ILabel {
  Label: Omit<MuiFormControlLabelProps, 'checked' | 'name' | 'onChange' | 'value' | 'inputRef'>;
}

export type ICheckboxWithLabelProps = ICheckboxProps &
  ILabel & {
    propClasses: any;
  };

const FormikCheckboxWithLabel: React.ComponentType<ICheckboxWithLabelProps> = ({
  Label,
  propClasses,
  ...props
}) => {
  const classes = useStyles();

  const { value, name } = props.field;
  const { initialValues } = props.form;
  const isDirty = value !== getIn(initialValues, name);

  return (
    <FormControlLabel
      classes={{ label: classNames({ [classes.dirty]: isDirty }), ...propClasses }}
      {...Label}
      control={
        <MuiCheckbox
          className={classNames({ [classes.dirty]: isDirty && !Label })}
          {...fieldToCheckbox(props)}
        />
      }
    />
  );
};

export default FormikCheckboxWithLabel;

FormikCheckboxWithLabel.displayName = 'FormikCheckboxWithLabel';
