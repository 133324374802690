import { Grid } from '@material-ui/core';
import { FastField as Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { SurfaceCondition, WeatherType } from '../../../../types/graphql';
import DialogCloseable, { IDialogCloseableProps } from '../../../Dialog/DialogCloseable';
import CancelSaveButtons from '../../../Form/CancelSaveButtons';
import FormikTextField from '../../../Form/FormikTextField';
import MeasurementFields from '../../../Mission/MeasurementFields';

const INITIAL_VALUES = {
  name: '',
  surfaceConditions: [] as any,
};

export interface ICopyMissionDialogFieldsMap {
  name: string;
  executionDate?: string;
  temperature?: number;
  surfaceConditions?: [SurfaceCondition];
  weatherType?: WeatherType;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Pflichtfeld'),
});

type Props = Omit<IDialogCloseableProps, 'onSubmit'> & {
  isFromMeasurements?: boolean;
  onSubmit: (values: ICopyMissionDialogFieldsMap) => Promise<any>;
};

export const CopyMissionDialog: React.FC<Props> = ({
  isFromMeasurements,
  onSubmit,
  children,
  ...dialogProps
}) => {
  const dialogPropsOnClose = dialogProps.onClose;
  const handleSubmit = useCallback(
    async (
      values: ICopyMissionDialogFieldsMap,
      helpers: FormikHelpers<ICopyMissionDialogFieldsMap>,
    ) => {
      await onSubmit(values);

      helpers.setSubmitting(false);

      dialogPropsOnClose?.();
    },
    [onSubmit, dialogPropsOnClose],
  );

  return (
    <DialogCloseable {...dialogProps}>
      <Formik<ICopyMissionDialogFieldsMap>
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting }) => (
          <Form>
            <Grid container item spacing={2}>
              <Grid container item>
                <Grid item xs={10}>
                  <Field name="name" label="Einsatzname *" autoFocus component={FormikTextField} />
                </Grid>
                {isFromMeasurements && (
                  <Grid item xs={10}>
                    <MeasurementFields />
                  </Grid>
                )}
              </Grid>
              <Grid container item justify="flex-end" md={12}>
                <CancelSaveButtons
                  isDisabled={!dirty || isSubmitting}
                  customSaveButtonLabel="Duplizieren"
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogCloseable>
  );
};
