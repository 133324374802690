import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { cachedFlex } from '../../utils/cachedFlex';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flex: 1,
    width: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    overflow: 'hidden',
  },
  column: {
    overflow: 'hidden',
  },
}));

export interface IFlexTableProps {
  /** either set a flex for all column or provide an array with the config */
  flex: number | ReadonlyArray<number | undefined> | Readonly<Record<number, number>>;
  /** if an incomplete flex configuration is provided the defaultFlex value will be used if present */
  defaultFlex?: number;
  children: ReadonlyArray<ReadonlyArray<ReactNode>>;
}

export const FlexTable: React.FC<IFlexTableProps> = ({ flex, defaultFlex, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {children.map((childrenRow, rowKey) => (
        <div key={rowKey} className={classes.row}>
          {childrenRow.map((childrenColumn, columnKey) => {
            const resolvedFlex =
              typeof flex === 'number'
                ? flex
                : Array.isArray(flex)
                ? flex[columnKey]
                : flex[columnKey];
            return (
              <div
                key={`${rowKey}-${columnKey}`}
                className={classes.column}
                style={cachedFlex(resolvedFlex ?? defaultFlex)}
              >
                {childrenColumn}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
