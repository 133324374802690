import * as React from 'react';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox';
import { FieldProps } from 'formik';
import { Omit } from './types';

/** Inspired by formik-material-ui */
export interface ICheckboxProps
  extends FieldProps,
    Omit<MuiCheckboxProps, 'form' | 'checked' | 'defaultChecked' | 'name' | 'onChange' | 'value'> {}

export const fieldToCheckbox = ({
  field,
  form: { isSubmitting },
  disabled = false,
  ...props
}: ICheckboxProps): MuiCheckboxProps => {
  return {
    disabled: isSubmitting || disabled,
    ...props,
    ...field,
    // TODO handle indeterminate
    checked: field.value ?? false,
    value: field.value ? 'checked' : '',
    color: 'default',
  };
};

export const FormikCheckbox: React.ComponentType<ICheckboxProps> = (props: ICheckboxProps) => (
  <MuiCheckbox {...fieldToCheckbox(props)} />
);

FormikCheckbox.displayName = 'FormikCheckbox';
