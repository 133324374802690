import { IDataTableRow } from '../../types';

interface ISelectedContainerItems {
  containerIds: string[];
  itemIds: string[];
}

/**
 *
 * @param container
 * @param currentIds
 * @param parentId
 * @returns
 */
export function fetchContainerTree(
  container: IDataTableRow,
  currentIds: string[],
  selectedItemIds: string[],
  parentId?: string,
): ISelectedContainerItems {
  // we use the composite id and send it to the api
  // the api will handle the magic
  const locationId = container.id;

  const locationIds: string[] = [];
  const itemIds: string[] = [];

  // if parent checked but child not => ignore
  // if parent wasn't checked but child checked =>ignore
  const isIgnoreContainer =
    (parentId && currentIds.includes(parentId) && !currentIds.includes(locationId)) ||
    (parentId && !currentIds.includes(parentId) && currentIds.includes(locationId));

  if (!isIgnoreContainer) {
    locationIds.push(locationId);

    const containerItemIds = container.innerTableRows?.map((row) => row.id) ?? [];

    const itemIdsToToggle = currentIds.includes(locationId)
      ? containerItemIds.filter((id) => selectedItemIds.includes(id))
      : containerItemIds;

    itemIds.push(...itemIdsToToggle);
  }

  if (container.containerRows && container.containerRows.length > 0) {
    container.containerRows?.forEach((container) => {
      const childTree = fetchContainerTree(container, currentIds, selectedItemIds, locationId);

      locationIds.push(...childTree.containerIds);
      itemIds.push(...childTree.itemIds);
    });
  }

  return { containerIds: [...new Set(locationIds)], itemIds: [...new Set(itemIds)] };
}

export function getLocationIdFromCompositeId(compositeId: string) {
  const idParts = compositeId.split('-');

  /**
   * the actual location id is always the second to the last
   * e.g. cl39rgrvnlwpu0739b1bj4lsa-ORDER_MISSION /
   * cl39rgrtilwpf0739o344f7xo-cl39rgrvnlwpu0739b1bj4lsa-cl39rgryhlwql07396ucbamv4-ORDER_MISSION
   */
  return idParts[idParts.length - 2];
}

export function isContainerChecked(selectedIds: string[], compositeId: string) {
  return selectedIds.includes(compositeId);
}
