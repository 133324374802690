import React, { useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { GetVehicleVariables, GetVehicle } from './types/GetVehicle';
import { useMutation, useQuery } from 'react-apollo';
import { UPDATE_VEHICLES_MUTATION, GET_VEHICLE, GET_VEHICLES } from './vehicle.query';
import PageWrapper from '../../components/PageWrapper';
import VehicleForm from '../../components/Vehicle/VehicleForm';
import AppProgress from '../../components/Page/AppProgress';
import { UpdateVehicle, UpdateVehicleVariables } from './types/UpdateVehicle';
import AppErrorMessage from '../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';

export const UpdateVehiclePage: React.FC = () => {
  const { id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const [submitError, setSubmitError] = useState();

  const { data, error, loading } = useQuery<GetVehicle, GetVehicleVariables>(GET_VEHICLE, {
    variables: {
      where: { id },
    },
    fetchPolicy: 'network-only',
  });

  const [updateVehicle] = useMutation<UpdateVehicle, UpdateVehicleVariables>(
    UPDATE_VEHICLES_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_VEHICLES,
          variables: state.variables,
        },
        {
          query: GET_VEHICLE,
          variables: {
            where: { id },
          },
        },
      ],
    },
  );

  const handleClose = () => history.goBack();

  if (error) {
    console.log(error);
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      {submitError && <AppErrorMessage message={errorPrefixRemover(submitError)} />}
      <PageWrapper header={{ title: 'Fahrzeug editieren', onClose: handleClose }}>
        <VehicleForm
          initialValues={data.vehicle}
          onSubmit={async (data, setSubmitting) => {
            try {
              await updateVehicle({ variables: { data, where: { id } } });
              setSubmitting(false);
              handleClose();
            } catch (e: any) {
              setSubmitting(false);
              setSubmitError(e.message);
            }
          }}
        />
      </PageWrapper>
    </>
  );
};
