import {
  Typography,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Fab,
  Tooltip,
} from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import gql from 'graphql-tag';
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import AppProgress from '../../components/Page/AppProgress';
import PageWrapper from '../../components/PageWrapper';
import { getAddressImportStatus } from './types/getAddressImportStatus';
import { mdiDatabaseImport } from '@mdi/js';
import Icon from '@mdi/react';

const GET_ADDRESS_IMPORT_STATUS = gql`
  query getAddressImportStatus {
    addressImportStatus {
      isSuccessful
      user
      on
      fileName
    }
  }
`;

const TRIGGER_IMPORT_MANUALLY = gql`
  mutation triggerImportManually {
    triggerAddressImport {
      isSuccessful
    }
  }
`;

const useStyles = makeStyles((theme) => {
  return {
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    warning: {
      color: amber[600],
    },
    triggerButton: {
      margin: theme.spacing(1.5),
    },
  };
});

const AddressImportStatus: React.FC = () => {
  const classes = useStyles();

  const { data, loading, error } = useQuery<getAddressImportStatus>(GET_ADDRESS_IMPORT_STATUS);

  const [triggerManually, { loading: mutationLoading }] = useMutation(TRIGGER_IMPORT_MANUALLY, {
    refetchQueries: [{ query: GET_ADDRESS_IMPORT_STATUS }],
  });

  return (
    <>
      {(loading || mutationLoading) && <AppProgress />}
      <PageWrapper>
        <Tooltip title="Manuell anstossen" aria-label="Manuell anstossen">
          <Fab
            onClick={async () => triggerManually()}
            disabled={mutationLoading}
            className={classes.triggerButton}
            color="primary"
          >
            <Icon path={mdiDatabaseImport} size={1.5} />
          </Fab>
        </Tooltip>

        <Typography variant="h6">Logbuch: Adressen-Import</Typography>
        {data && data.addressImportStatus && !error ? (
          <>
            <div>
              <List>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography>
                        <span className={classes.bold}>{'Am: '}</span>
                        {data.addressImportStatus.on}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={
                      <Typography>
                        <span className={classes.bold}>{'Erfolgreich: '}</span>
                        {data.addressImportStatus.isSuccessful ? 'Ja' : 'Nein'}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={
                      <Typography>
                        <span className={classes.bold}>{'Ausgeführt von: '}</span>
                        {data.addressImportStatus.user}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={
                      <Typography>
                        <span className={classes.bold}>{'Dateiname: '}</span>{' '}
                        {data.addressImportStatus.fileName}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </div>
          </>
        ) : (
          <Typography className={classes.bold}>es wurde noch keinen Import ausgeführt</Typography>
        )}
      </PageWrapper>
    </>
  );
};

export default AddressImportStatus;
