import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Theme } from '@material-ui/core';
import { ILevelOptions, IDataTableRow } from '../types';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    whiteSpace: 'nowrap',
  },
  activeRow: {
    borderWidth: 1,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderRightStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
}));

interface IProps {
  options: ILevelOptions;
  row: IDataTableRow;
  isActiveRow: boolean;
}

const ActionsCell: React.FunctionComponent<IProps> = ({ options, row, isActiveRow }) => {
  const classes = useStyles();

  return (
    <TableCell
      className={classNames(classes.root, { [classes.activeRow]: isActiveRow })}
      padding="none"
    >
      {options.rowActions!({ row })}
    </TableCell>
  );
};

export default ActionsCell;
