import React from 'react';
import { FastField as Field } from 'formik';
import { IDataTableColumn, IDataTableRow } from '../../../../components/DataTable/types';
import ColorCoder, { Color } from '../../../../components/ColorCoder';
import { createSwissCurrencyFormatter } from '../../../../utils/createCurrencyFormatter';
import FormikTextField from '../../../../components/Form/FormikTextField';
import { formatDate } from '../../../../utils/format/date';
import { formatVolume } from '../../../../utils/format/volume';
import { preventNonNumericInput } from '../../../../utils/preventNonNumericInput';
import { removeTableTypeFromId } from '../MissionSelector/paginationHelpers/mapper';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

export const billOfQuantityColumns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Leistungsv.',
  },
  {
    id: 'statuses',
    label: 'Status',
    width: 80,
  },
  {
    id: 'date',
    label: 'Datum',
    width: 80,
    render: (val) => formatDate(val),
  },
  {
    id: 'sumNet',
    label: 'Plan Summe Netto',
    render: formatCurrency,
  },
  {
    id: 'sumGross',
    label: 'Plan Summe Brutto',
    render: formatCurrency,
  },
  {
    id: 'colorCodings',
    label: '',
    render: (colorCodings: Color[]) => colorCodings && <ColorCoder colorCodings={colorCodings} />,
  },
];

export const locationColumns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Örtlichkeit',
  },
];

export const itemColumns = (isMeasurement: boolean): IDataTableColumn[] => [
  {
    id: 'acronym',
    label: 'Kurzbez.',
    width: 80,
  },
  {
    id: 'productNumber',
    label: 'Produktnummer',
    width: 120,
  },
  {
    id: 'catalogSection',
    label: 'Hierarchienr 1',
    hideOnDefault: true,
  },
  {
    id: 'catalogSubsection',
    label: 'Hierarchienr 2',
    hideOnDefault: true,
  },
  {
    id: 'catalogMainGroup',
    label: 'Hierarchienr 3',
    hideOnDefault: true,
  },
  {
    id: 'catalogSectionDescription',
    label: 'Hierarchie 1',
    hideOnDefault: true,
  },
  {
    id: 'catalogSubsectionDescription',
    label: 'Hierarchie 2',
    hideOnDefault: true,
  },
  {
    id: 'catalogMainGroupDescription',
    label: 'Hierarchie 3',
    hideOnDefault: true,
  },
  {
    id: 'descriptionOne',
    label: 'Beschreibung 1',
    width: 120,
  },
  {
    id: 'descriptionTwo',
    label: 'Beschreibung 2',
    hideOnDefault: true,
  },
  {
    id: 'freeText',
    label: 'Freitext',
    hideOnDefault: true,
  },
  {
    id: 'type',
    label: 'Positionsart',
    hideOnDefault: true,
  },
  {
    id: 'category',
    label: 'Kategorie',
    width: 80,
  },
  {
    id: 'markingStyle',
    label: 'Typ',
    width: 75,
    hideOnDefault: isMeasurement,
  },
  {
    id: 'comment',
    label: 'Kommentar Position',
    hideOnDefault: true,
  },
  {
    id: 'color',
    label: 'Farbe',
    hideOnDefault: true,
  },
  {
    id: 'dimensionOne',
    label: 'Dimension 1',
    hideOnDefault: true,
  },
  {
    id: 'dimensionTwo',
    label: 'Dimension 2',
    hideOnDefault: true,
  },
  {
    id: 'reflexion',
    label: 'Reflektion',
    hideOnDefault: true,
  },
  {
    id: 'unit',
    label: 'Einheit',
    width: 65,
  },
  {
    id: 'volume',
    label: 'Offerten Menge',
    render: formatVolume,
    width: 75,
  },
  {
    id: 'netOfferSum',
    label: 'Offerten Betrag Netto',
    render: formatCurrency,
    width: 120,
    hideOnDefault: true,
  },
  {
    id: 'grossOfferSum',
    label: 'Offerten Betrag Brutto',
    render: formatCurrency,
    width: 120,
  },
  {
    id: 'allocatedVolume',
    label: 'Bereits verplante Menge',
    render: formatVolume,
    width: 80,
  },
  {
    id: 'allocatedSum',
    label: 'Bereits verplanter Betrag',
    render: formatCurrency,
    width: 110,
    hideOnDefault: isMeasurement,
  },
  {
    id: 'openVolume',
    label: 'Noch offene Menge',
    render: formatVolume,
    width: 80,
  },
  {
    id: 'openSum',
    label: 'Noch Offener Betrag',
    render: formatCurrency,
    width: 110,
  },
  {
    id: 'appliedPrice',
    label: 'Preis - brutto',
    render: formatCurrency,
    width: 105,
  },
  {
    id: 'netPrice',
    label: 'Preis - netto',
    render: formatCurrency,
    width: 105,
    hideOnDefault: true,
  },
  {
    id: 'material',
    label: 'Verbrauchsmaterial',
    hideOnDefault: true,
  },
  {
    id: 'targetConsumptionPerUnit',
    label: 'Sollverbrauch EinheitMat/Einheitpos',
    hideOnDefault: true,
    render: formatVolume,
  },
  {
    id: 'timeRequired',
    label: 'Zeitbedarf min/einheit',
    hideOnDefault: true,
  },
  {
    id: 'doneVolume',
    label: 'Bereits ausgemessene Menge',
    hideOnDefault: true,
    render: formatVolume,
  },
  {
    id: 'chargedVolume',
    label: 'Bereits verrechnete Menge',
    hideOnDefault: true,
    render: formatVolume,
  },
  {
    id: 'amount',
    label: 'Menge',
    width: 80,
    // would produce an invalid nesting p > div
    renderPlain: true,
    render: (cell: any, row: IDataTableRow) => (
      <Field
        name={`volume-${removeTableTypeFromId(row.id)}`}
        component={FormikTextField}
        type="number"
        onKeyPress={preventNonNumericInput}
        dirty={row.data.__formikDirty}
      />
    ),
  },
];

const levels = [
  { columns: billOfQuantityColumns },
  { columns: locationColumns },
  { columns: locationColumns },
  { columns: itemColumns(false), hasCheckboxes: true },
];

export default levels;
