import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const PlusOutlineIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <>
      <SvgIcon {...props} viewBox="0 0 38 38" shapeRendering="geometricPrecision">
        <path d="M19.9324787,10.5555556 L18.0642056,10.5555556 L18.0642056,18.0617284 L10.5555556,18.0617284 L10.5555556,19.9406768 L18.0642056,19.9406768 L18.0642056,27.4444444 L19.9419684,27.4444444 L19.9324787,19.9406768 L27.4444444,19.9406768 L27.4444444,18.0617284 L19.9324787,18.0617284 L19.9324787,10.5555556 Z M19,0 C8.512,0 0,8.512 0,19 C0,29.488 8.512,38 19,38 C29.488,38 38,29.488 38,19 C38,8.512 29.488,0 19,0 Z M19,36.4920635 C9.3575,36.4920635 1.50793651,28.6425 1.50793651,19 C1.50793651,9.3575 9.3575,1.50793651 19,1.50793651 C28.6425,1.50793651 36.4920635,9.3575 36.4920635,19 C36.4920635,28.6425 28.6425,36.4920635 19,36.4920635 Z" />
      </SvgIcon>
    </>
  );
};
