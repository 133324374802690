import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Theme, TextField } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { TextFieldProps } from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) => ({
  navigationContainer: {
    margin: theme.spacing(0, 2.5),
  },
  arrowBackContainer: {
    borderRadius: 0,
    borderTopLeftRadius: theme.shape.borderRadius * 2.5,
    borderBottomLeftRadius: theme.shape.borderRadius * 2.5,
    backgroundColor: '#ece9e9',
    marginRight: `1px`,
  },
  arrowForwardContainer: {
    borderRadius: 0,
    borderTopRightRadius: theme.shape.borderRadius * 2.5,
    borderBottomRightRadius: theme.shape.borderRadius * 2.5,
    backgroundColor: '#ece9e9',
    marginLeft: `1px`,
  },
}));

interface IProps {
  onChangeDate: (newDate: Date) => void;
  date?: Date;
  loading: boolean;
}

const moveByOneWeek = (type: 'NEXT' | 'PREVIOUS', date: Date) => {
  const newDate = new Date(date);

  const amountOfWeeks = type === 'NEXT' ? 1 : -1;

  const amountOfDays = 7;

  newDate.setDate(date.getDate() + amountOfWeeks * amountOfDays);

  return newDate;
};

export const CalendarNavigation: React.FC<IProps> = ({ onChangeDate, date, loading }) => {
  /*
  Firefox Version 84.0.1 does not open the calendar when the date field is being clicked the 2nd, 4th, 6th, 8th,... time
  We need to manually track the state of the calendar because we emit onChangeDate when user enters date via calendar
  */
  const [calendarOpenedCount, setCalendarOpenedCount] = useState(0);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [fieldValue, setFieldValue] = useState(date);

  const classes = useStyles();

  const createOnWeekChange = useCallback(
    (type: 'NEXT' | 'PREVIOUS') => () => {
      const setDateToMidnight = (date: Date) => {
        date.setUTCHours(0, 0, 0, 0);
        return date;
      };

      const newDate = moveByOneWeek(type, fieldValue ?? setDateToMidnight(new Date()));

      setFieldValue(newDate);
      onChangeDate(newDate);
    },
    [fieldValue, onChangeDate],
  );

  const emitChangeToParent = useCallback(
    (fieldValue?: Date) => () => {
      if (!fieldValue) {
        return;
      }

      onChangeDate(fieldValue);
    },
    [onChangeDate],
  );

  const onChange = useCallback<Exclude<TextFieldProps['onChange'], undefined>>(
    (e) => {
      if (!e || !e.currentTarget.value) {
        return;
      }

      const parsedDate = new Date(e.currentTarget.value);

      setFieldValue(parsedDate);

      if (isCalendarVisible) {
        emitChangeToParent(parsedDate)();
      }
    },
    [isCalendarVisible, emitChangeToParent],
  );

  const onClick = useCallback(() => {
    const newCount = calendarOpenedCount + 1;

    setIsCalendarVisible(newCount % 2 === 1);
    setCalendarOpenedCount(newCount);
  }, [calendarOpenedCount]);

  const onKeyUp = useCallback(() => {
    if (!isCalendarVisible) {
      return;
    }

    // when user clicks in field and wants to enter date we should treat as if calendar is not open
    setIsCalendarVisible(false);
  }, [isCalendarVisible]);

  // firefox resets the state of the calendar when field gets disabled
  useEffect(() => {
    if (loading) {
      return;
    }

    setCalendarOpenedCount(0);
  }, [loading]);

  return (
    <>
      <TextField
        name="date"
        type="date"
        value={fieldValue?.toISOString().split('T')[0] ?? ''}
        onChange={onChange}
        onBlur={emitChangeToParent(fieldValue)}
        onClick={onClick}
        onKeyUp={onKeyUp}
        disabled={loading}
      />
      <div className={classes.navigationContainer}>
        <IconButton
          disabled={loading}
          onClick={createOnWeekChange('PREVIOUS')}
          classes={{ root: classes.arrowBackContainer }}
        >
          <ArrowForwardIosIcon style={{ transform: 'rotate(180deg)' }} />
        </IconButton>
        <IconButton
          disabled={loading}
          onClick={createOnWeekChange('NEXT')}
          classes={{ root: classes.arrowForwardContainer }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </>
  );
};
