/**
 * Escape a string so it can be used to create a plain regex.
 * @param str the string to escape
 * @returns plain string usable in a regex
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 */
const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

/**
 * Creates a regex that fuzzily matches strings based on the value.
 * @param value the value to create the fuzzy matcher for
 * @returns the fuzzy matcher
 * @example
 * const matcher = fuzzyMatcher("ab");
 * matcher.test("xayybxx"); // true
 * matcher.test("xayaybxx"); // true
 * matcher.test("aaabbbb"); // true
 * matcher.test("ba"); // false
 * matcher.test("xzbya"); // false
 */
export const fuzzyMatcher = (value: string): RegExp => {
  const escapedValue = escapeRegExp(value);
  return new RegExp('^.*' + escapedValue.split('').join('.*') + '.*$', 'i');
};

// simple size 1 cache for the fuzzy match regex
let fuzzyMatchCache: { value: string; matcher: RegExp } = {
  value: '',
  matcher: fuzzyMatcher(''),
};
/**
 * Fuzzily match a suggestion to a value
 * @param value the value to create the fuzzy matcher with
 * @param suggestion the suggestion to match
 * @returns fuzzy matches?
 */
export const fuzzyMatch = (value: string, suggestion: string) => {
  if (fuzzyMatchCache.value !== value) {
    fuzzyMatchCache = {
      value,
      matcher: fuzzyMatcher(value),
    };
  }
  return fuzzyMatchCache.matcher.test(suggestion);
};
