import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, Grid, Fab, Grow } from '@material-ui/core';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { ISpeedDialActionProps, SpeedDialAction } from '../SpeedDialAction/SpeedDialAction';

interface IProps {
  disabled?: boolean;
  actions?: ISpeedDialActionProps[];
  /**
   * dismissibleActions take priority over the speed dial action and are shown when present.
   */
  dismissibleActions?: ISpeedDialActionProps[];
  /**
   * called when dismissible actions are shown and the close button is clicked
   */
  onDismiss?: () => void;
}

interface IStyleProps {
  isActionsOpen: boolean;
}

const fill = 'white';
const dismissibleModeFill = 'black';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100px',
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  fabRoot: {
    width: '72px',
    height: '72px',
  },
  fabIcon: {
    fill,
    transform: (props: IStyleProps) => (props.isActionsOpen ? 'rotate(45deg)' : 'initial'),
    transition: 'transform 0.15s linear',
  },
  dontShowIfEmpty: {
    '&:empty': {
      display: 'none',
    },
  },
}));

const TIMEOUT_INTERVAL = 350;

const calcTimeout = (n: number) => n * TIMEOUT_INTERVAL;

export const SpeedDial: React.FC<IProps> = ({
  disabled,
  actions,
  onDismiss,
  dismissibleActions,
}) => {
  const [isActionsOpen, setIsActionsOpen] = useState(false);

  /*
   * when there are dismissible actions, they will be shown instead of the speed dial actions. the fab will change
   * color to secondary and call onDismiss when pressed.
   * it's the parent's responsibility to remove the dismissibleActions in order to switch back to normal mode.
   */
  const speedDialActions =
    dismissibleActions == null || dismissibleActions.length === 0 ? actions : dismissibleActions;
  // are we in dismissible actions mode?
  const dismissibleMode = speedDialActions != null && speedDialActions === dismissibleActions;

  const classes = useStyles({ isActionsOpen: isActionsOpen || dismissibleMode });

  // changes behaviour on whether we are in dismissible mode, see above
  const onClickFab = useCallback(() => {
    if (dismissibleMode) {
      onDismiss?.();
    } else {
      setIsActionsOpen((v) => !v);
    }
  }, [setIsActionsOpen, onDismiss, dismissibleMode]);

  return (
    <Grid container spacing={3} wrap="wrap-reverse" classes={{ root: classes.root }}>
      <Grid item container justify="center" xs={12}>
        <Fab
          classes={{ root: classes.fabRoot }}
          size="large"
          color={dismissibleMode ? 'secondary' : 'primary'}
          variant="round"
          onClick={onClickFab}
          disabled={disabled}
        >
          <Icon
            path={mdiPlus}
            size={1.4}
            className={classes.fabIcon}
            color={
              // setting it via class is not enough for 'secondary'
              dismissibleMode ? dismissibleModeFill : fill
            }
          />
        </Fab>
      </Grid>
      {speedDialActions &&
        !disabled &&
        speedDialActions.map(({ onClick, ...actionProps }, i) => (
          <Grid item container xs={12} justify="center" key={i} className={classes.dontShowIfEmpty}>
            <Grow
              timeout={{
                enter: calcTimeout(i + 1),
                exit: calcTimeout(speedDialActions.length - i),
              }}
              in={isActionsOpen || dismissibleMode}
              unmountOnExit
            >
              <span>
                <SpeedDialAction
                  onClick={() => {
                    onClick();
                    /*
                     * in dismissible mode opening and closing is ignored. the parent must remove the dismissibleActions
                     * to switch back to normal mode
                     */
                    if (!dismissibleMode) {
                      setIsActionsOpen(false);
                    }
                  }}
                  {...actionProps}
                />
              </span>
            </Grow>
          </Grid>
        ))}
    </Grid>
  );
};
