import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { FC } from 'react';
import { IDataTableRow } from '../../../DataTable/types';
import { useDeleteBillItem } from './removeBillItem';

interface IProps {
  row: IDataTableRow;
}

export const DeleteBillItemAction: FC<IProps> = ({ row }) => {
  const deleteBillItem = useDeleteBillItem(row);

  return (
    <Tooltip title="Löschen">
      <span>
        <IconButton disabled={!row.data.canBeDeleted} onClick={deleteBillItem}>
          <DeleteIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
