import React, { useMemo, useState, useCallback } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { FieldProps } from 'formik';
import { makeStyles } from '@material-ui/styles';
import {
  InputLabel,
  Theme,
  Typography,
  Paper,
  RootRef,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileIcon from './FileIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import { downloadFile } from '../../../utils/downloadFile';
import { GridProps } from '@material-ui/core/Grid';

type IDropzoneProps = FieldProps &
  DropzoneOptions & {
    initialFiles: File[];
    label: string;
    justify?: GridProps['justify'];
  };

const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    border: 'dashed 1px;',
    backgroundColor: theme.palette.grey['100'],
    borderColor: theme.palette.grey['400'],
    cursor: 'pointer', // move to input
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '295px',
    minHeight: '145px',
  },
  listItemText: {
    paddingLeft: '5px',
  },
  listItemActions: {
    right: -2,
  },
}));

export const handleFileDownload = async (path: string, name: string) => {
  await downloadFile(path, name);
};

const Dropezone: React.FunctionComponent<IDropzoneProps> = ({
  field: { name, value },
  form,
  label,
  initialFiles,
  justify,
  accept,
  ...dropzoneOptions
}) => {
  const [deletedFiles, setDeletedFiles] = useState<string[]>(value.deletedFiles ?? []);

  const onDrop = (acceptedFiles: any) => {
    form.setFieldValue(name, { uploads: acceptedFiles });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...dropzoneOptions,
  });

  const classes = useStyles();

  const handleDeleteNewUploads = useCallback(
    (file: File) => {
      const uploads = value.uploads;
      uploads.splice(uploads.indexOf(file));
      form.setFieldValue(name, { uploads });
    },
    [form, name, value],
  );

  const handleDeleteFromServer = useCallback(
    (file: any) => {
      const fieldDeletedFiles = value.deletedFiles || [];

      setDeletedFiles((v) => [...v, file.id]);

      fieldDeletedFiles.push(file.id);
      form.setFieldValue(name, { deletedFiles: fieldDeletedFiles });
    },
    [value, form, setDeletedFiles, name],
  );

  const { ref, ...rootProps } = getRootProps();

  const initialFilesToShow = useMemo(
    () =>
      [...(initialFiles ?? []), ...(value.uploads ?? [])].filter(
        (file: any) =>
          (!value.deletedFiles?.includes(file.id) ?? true) || !deletedFiles.includes(file.id ?? ''),
      ),
    [value, initialFiles, deletedFiles],
  );

  const FilesList = useMemo(
    () =>
      initialFilesToShow
        // check if file was previously deleted, if so dont filter it out
        .filter((file: any) => !deletedFiles?.includes(file.id ?? ''))
        .map((file, idx) => {
          const isFileFromServer = !!file.id;

          return (
            <ListItem
              key={idx}
              button={isFileFromServer as any}
              onClick={() => isFileFromServer && handleFileDownload(file.path, file.name)}
              dense
            >
              <FileIcon mimeType={file.mimetype as any} />
              <ListItemText primary={file.name} className={classes.listItemText} />
              <ListItemSecondaryAction className={classes.listItemActions}>
                <IconButton
                  onClick={() => {
                    isFileFromServer ? handleDeleteFromServer(file) : handleDeleteNewUploads(file);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        }),
    [classes, deletedFiles, handleDeleteFromServer, handleDeleteNewUploads, initialFilesToShow],
  );

  return (
    <Grid container justify={justify}>
      <Grid item>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <RootRef rootRef={ref}>
          <Paper elevation={0} className={classes.dropZone} square={true} {...rootProps}>
            <input {...getInputProps()} name={name} />
            <Typography variant="body2" color="textSecondary">
              Dateien hierher ziehen oder klicken!
            </Typography>
            <CloudUploadIcon color={'disabled'} fontSize="large" />
          </Paper>
        </RootRef>
      </Grid>
      <Grid item>
        <List>{FilesList}</List>
      </Grid>
    </Grid>
  );
};

export default Dropezone;
