import React from 'react';
import { useMutation } from 'react-apollo';
import PageWrapper from '../../components/PageWrapper';
import { useHistory } from 'react-router-dom';
import AppErrorMessage from '../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import { CreateOfferCondition, CreateOfferConditionVariables } from './types/CreateOfferCondition';
import {
  CREATE_OFFER_CONDITION_MUTATION,
  GET_OFFER_CONDITIONS_QUERY,
} from './OfferConditions.queries';
import OfferConditionForm from '../../components/OfferCondition/OfferConditionForm';

/**
 * Component that displays a creation form for an offer condition.
 * @constructor
 */
export const CreateOfferConditionPage: React.FC = () => {
  const history = useHistory();
  const goBack = () => history.goBack();

  const [createOfferCondition, { error }] = useMutation<
    CreateOfferCondition,
    CreateOfferConditionVariables
  >(CREATE_OFFER_CONDITION_MUTATION, {
    refetchQueries: [{ query: GET_OFFER_CONDITIONS_QUERY }],
  });

  return (
    <>
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <PageWrapper header={{ title: 'Angebotsbedingung erstellen', onClose: goBack }}>
        <OfferConditionForm
          onSubmit={async (data, setSubmitting) => {
            try {
              await createOfferCondition({ variables: { data } });
              setSubmitting(false);
              goBack();
            } catch (e) {
              console.log(e);
              setSubmitting(false);
            }
          }}
        />
      </PageWrapper>
    </>
  );
};
