import { MenuItem } from '@material-ui/core';
import React from 'react';
import { FullAddressBookItem_address_contactPersons } from '../types/FullAddressBookItem';

export interface ContactDropdownItem {
  id: string;
  name: string;
}

export const mapContactsToDropdownItems = (
  contacts: FullAddressBookItem_address_contactPersons[],
): ContactDropdownItem[] => {
  return contacts.map((contact) => ({
    id: contact.id,
    // <contactPersonSequence> - <firstName> <name> - <role>
    name: `${contact.contactPersonSequence} -${
      contact.address.firstName ? ` ${contact.address.firstName}` : ''
    }${contact.name ? ` ${contact.name} ` : ''}${
      contact.address.role ? `- ${contact.address.role}` : ''
    }`,
  }));
};

export const mapContactDropdownItemsToSelectOptions = (
  dropdownItems: ContactDropdownItem[],
): React.ReactNode => {
  return dropdownItems.map((dropdownItem) => (
    <MenuItem value={dropdownItem.id} key={dropdownItem.id}>
      {dropdownItem.name}
    </MenuItem>
  ));
};
