import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const dayNames = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

const useStyles = makeStyles((theme) => ({
  day: { fontWeight: theme.typography.fontWeightBold },
  date: { fontWeight: theme.typography.fontWeightMedium, color: theme.palette.grey.A200 },
}));

export const DayHeader: React.FC<{ date: Date }> = ({ date }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.day}>{dayNames[date.getDay()]}</Typography>
      <Typography className={classes.date}>{`${date.getDate()}.${date.getMonth() + 1}`}</Typography>
    </div>
  );
};
