import React, { useCallback, useMemo } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { emptyIndividualTimeEntry, emptyTimeEntry, IAccountLogggerFormValues } from '../../types';
import { Times } from './Times';
import { Button } from '@material-ui/core';
import Dinero from 'dinero.js';
import { GetAccountingEmployees_employees } from '../../types/GetAccountingEmployees';
import { GetAccountingMissions_missions } from '../../types/GetAccountingMissions';
import { getSearchableEmployee, searchableEmployee } from '../../../utils/searchable';
import { Autocomplete } from '../../../../../components/Autocomplete';
import { fuzzyMatch } from '../../../utils/fuzzyMatch';
import { GetAccountingActivityTypes_activityTypes } from '../../types/GetAccountingActivityTypes';
import { filterTimeActivityTypes } from '../../utils/filterItems';
import { cachedTabIndex } from '../../../utils/tabIndex';
import { GetAccountingVehicles_vehicles } from '../../types/GetAccountingVehicles';
import { GetAccountingMachines_machines } from '../../types/GetAccountingMachines';

const tabIndexOffset = 1000;

/**
 * Table to manage the times for the whole group
 * @constructor
 */
const GroupTimes: React.FC<{
  date: string;
  employees: readonly GetAccountingEmployees_employees[];
  missions: readonly GetAccountingMissions_missions[];
  vehicles: readonly GetAccountingVehicles_vehicles[];
  machines: readonly GetAccountingMachines_machines[];
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
  disabled?: boolean;
  onAddPersonTimes: () => void;
}> = React.memo(
  ({
    date,
    disabled,
    employees,
    missions,
    vehicles,
    machines,
    onAddPersonTimes,
    activityTypes,
  }) => {
    // no object identity on setValue of useField!
    const { setFieldValue } = useFormikContext();

    const [{ value: chief }] = useField<string>('chief');
    const [{ value: logEmployees }] = useField<readonly string[]>('employees');

    const name = 'timeEntries';
    const [{ value: timeEntries }] =
      useField<Readonly<IAccountLogggerFormValues['timeEntries']>>(name);

    const hourlyWage = useMemo(() => {
      const chiefHourlyWage = Dinero({
        amount: getSearchableEmployee(chief, employees)?.function?.hourlyWage ?? 0,
      });

      const employeesHourlyWage = logEmployees.reduce(
        (acc, person) =>
          acc.add(
            Dinero({ amount: getSearchableEmployee(person, employees)?.function?.hourlyWage ?? 0 }),
          ),
        Dinero({ amount: 0 }),
      );

      return chiefHourlyWage.add(employeesHourlyWage);
    }, [chief, logEmployees, employees]);

    const onAddRow = useCallback(() => {
      setFieldValue(name, [...timeEntries, emptyTimeEntry()], false);
    }, [setFieldValue, timeEntries]);

    const onClearEntryRow = useCallback(() => {
      const newTimeEntries = timeEntries.slice(0, -1);
      newTimeEntries.push(emptyTimeEntry());
      setFieldValue(name, newTimeEntries, true);
    }, [setFieldValue, timeEntries]);

    const onClearRow = useCallback(
      (rowId: number) => {
        if (timeEntries.length > 1 && rowId < timeEntries.length - 1) {
          const newTimeEntries = timeEntries.slice(0);
          newTimeEntries.splice(rowId, 1);
          setFieldValue(name, newTimeEntries, true);
        }
      },
      [setFieldValue, timeEntries],
    );

    const addPersonTimes = useCallback(
      () => (
        <Button
          type="button"
          color="primary"
          size="large"
          onClick={onAddPersonTimes}
          disabled={disabled}
        >
          Zusätzliche Arbeitszeiten Buchen
        </Button>
      ),
      [disabled, onAddPersonTimes],
    );

    return (
      <Times
        date={date}
        missions={missions}
        vehicles={vehicles}
        machines={machines}
        disabled={disabled}
        tableName="ACCOUNTING_LOGGER_TIMES"
        displayName="Arbeitszeiten"
        hourlyWage={hourlyWage}
        onAddRow={onAddRow}
        onClearEntryRow={onClearEntryRow}
        onClearRow={onClearRow}
        prefix="timeEntries"
        timeEntries={timeEntries}
        actions={addPersonTimes}
        activityTypes={activityTypes}
        tabIndexOffset={tabIndexOffset}
      />
    );
  },
);

/**
 * Table for managing times of an individual person
 * @constructor
 */
const PersonTimes: React.FC<{
  date: string;
  employees: readonly GetAccountingEmployees_employees[];
  missions: readonly GetAccountingMissions_missions[];
  vehicles: readonly GetAccountingVehicles_vehicles[];
  machines: readonly GetAccountingMachines_machines[];
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
  disabled?: boolean;
  personIdx: number;
  onClearPersonTimes: (personIdx: number) => void;
}> = React.memo(
  ({
    date,
    disabled,
    personIdx,
    employees,
    missions,
    vehicles,
    machines,
    onClearPersonTimes,
    activityTypes,
  }) => {
    // no object identity on setValue of useField!
    const { setFieldValue } = useFormikContext();

    const name = `individualTimeEntries[${personIdx}]`;
    const [{ value: individualTimeEntries }] =
      useField<IAccountLogggerFormValues['individualTimeEntries'][number]>(name);

    const person = useMemo(
      () => getSearchableEmployee(individualTimeEntries.person, employees),
      [employees, individualTimeEntries.person],
    );
    const noPerson = person == null;
    const hourlyWage = Dinero({ amount: person?.function?.hourlyWage ?? 0 });

    const onAddRow = useCallback(() => {
      individualTimeEntries.entries = [...individualTimeEntries.entries, emptyTimeEntry()];
      setFieldValue(name, individualTimeEntries, false);
    }, [individualTimeEntries, name, setFieldValue]);

    const onClearEntryRow = useCallback(() => {
      const newTimeEntries = individualTimeEntries.entries.slice(0, -1);
      newTimeEntries.push(emptyTimeEntry());
      individualTimeEntries.entries = newTimeEntries;
      setFieldValue(name, individualTimeEntries, false);
    }, [individualTimeEntries, name, setFieldValue]);
    const onClearRow = useCallback(
      (rowId: number) => {
        if (
          individualTimeEntries.entries.length > 1 &&
          rowId < individualTimeEntries.entries.length - 1
        ) {
          const newTimeEntries = individualTimeEntries.entries.slice(0);
          newTimeEntries.splice(rowId, 1);
          individualTimeEntries.entries = newTimeEntries;
          setFieldValue(name, individualTimeEntries, false);
        }
      },
      [setFieldValue, individualTimeEntries, name],
    );

    const prefix = `individualTimeEntries[${personIdx}].entries`;

    const suggestions = useMemo(() => employees.map(searchableEmployee), [employees]);
    const validateSuggestions = useCallback(
      (value) => {
        if (value === '') {
          return;
        }
        if (!suggestions.includes(value)) {
          return 'Mitarbeiter existiert nicht!';
        }
      },
      [suggestions],
    );

    const offset = tabIndexOffset + (personIdx + 1) * 1000;
    const globalActions = useCallback(
      () => (
        <Field
          name={`${name}.person`}
          label="Mitarbeiter"
          type="text"
          component={Autocomplete}
          disabled={disabled}
          autoFocus={!person}
          suggestions={suggestions}
          matcher={fuzzyMatch}
          minLengthToTrigger={0}
          selectOnFocus
          fillOnEnter
          fillOnBlur
          next={`input[name='${prefix}[${individualTimeEntries.entries.length - 1}].activityType']`}
          // if we just pass the validate function it doesn't update correctly on change
          validate={(value: any) => validateSuggestions(value)}
          inputProps={cachedTabIndex(offset)}
        />
      ),
      [
        name,
        disabled,
        person,
        suggestions,
        prefix,
        individualTimeEntries.entries.length,
        validateSuggestions,
        offset,
      ],
    );

    const onClick = useCallback(
      () => onClearPersonTimes(personIdx),
      [onClearPersonTimes, personIdx],
    );
    const actions = useCallback(
      () => (
        <Button type="button" color="primary" size="large" onClick={onClick} disabled={disabled}>
          Entfernen
        </Button>
      ),
      [disabled, onClick],
    );

    return (
      <Times
        date={date}
        missions={missions}
        vehicles={vehicles}
        machines={machines}
        activityTypes={activityTypes}
        disabled={disabled || noPerson}
        tableName={'ACCOUNTING_LOGGER_TIMES_PERSON_' + personIdx}
        hourlyWage={hourlyWage}
        globalActions={globalActions}
        actions={actions}
        onAddRow={onAddRow}
        onClearEntryRow={onClearEntryRow}
        onClearRow={onClearRow}
        prefix={`individualTimeEntries[${personIdx}].entries`}
        timeEntries={individualTimeEntries.entries}
        tabIndexOffset={offset + 1}
      />
    );
  },
);

interface IProps {
  disabled?: boolean;
  date: string;
  employees: readonly GetAccountingEmployees_employees[];
  missions: readonly GetAccountingMissions_missions[];
  vehicles: readonly GetAccountingVehicles_vehicles[];
  machines: readonly GetAccountingMachines_machines[];
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
}

/**
 * The work times section of the accounting logger. Here the time items for the whole group can be specified.
 * Specialized bookings for individual workers appear.
 * @constructor
 */
export const TimesContainer: React.FC<IProps> = React.memo(
  ({ disabled, employees, missions, vehicles, machines, date, activityTypes }) => {
    // no object identity on setValue of useField!
    const { setFieldValue } = useFormikContext();

    const name = 'individualTimeEntries';
    const [{ value: individualTimeEntries }] =
      useField<Readonly<IAccountLogggerFormValues['individualTimeEntries']>>(name);

    const onAddPersonTimes = useCallback(() => {
      setFieldValue(name, [...individualTimeEntries, emptyIndividualTimeEntry()], false);
      document
        .querySelector<HTMLInputElement>(
          `input[name='individualTimeEntries[${individualTimeEntries.length}].person']`,
        )
        ?.focus();
    }, [individualTimeEntries, setFieldValue]);

    const onClearPersonTimes = useCallback(
      (idx: number) => {
        const newIndividualTimeEntries = individualTimeEntries.slice(0);
        newIndividualTimeEntries.splice(idx, 1);
        setFieldValue(name, newIndividualTimeEntries, false);
      },
      [individualTimeEntries, setFieldValue],
    );

    const timeActivityTypes = useMemo(
      () => filterTimeActivityTypes(activityTypes),
      [activityTypes],
    );

    disabled = disabled || employees.length === 0;

    return (
      <>
        <GroupTimes
          missions={missions}
          vehicles={vehicles}
          machines={machines}
          activityTypes={timeActivityTypes}
          date={date}
          employees={employees}
          disabled={disabled}
          onAddPersonTimes={onAddPersonTimes}
        />
        {individualTimeEntries.map((_, personIdx) => (
          <PersonTimes
            missions={missions}
            vehicles={vehicles}
            machines={machines}
            activityTypes={timeActivityTypes}
            date={date}
            employees={employees}
            key={personIdx}
            personIdx={personIdx}
            disabled={disabled}
            onClearPersonTimes={onClearPersonTimes}
          />
        ))}
      </>
    );
  },
);
