import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Drawer from '@material-ui/core/Drawer';
import HomeIcon from '@material-ui/icons/Home';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SortIcon from '@material-ui/icons/Sort';
import StorageIcon from '@material-ui/icons/Storage';
import ShortTextIcon from '@material-ui/icons/ShortText';
import EventIcon from '@material-ui/icons/Event';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import AppDrawerNavItem from './AppDrawerNavItem';
import { Link } from 'react-router-dom';
import { hasAccessRights } from '../../utils';
import { Role } from '../../enums';
import NavigationProjectsDropDown from './NavigationProjectsDropDown';
import NavigationSubsidiary from './NavigationSubsidiary';
import gqlClient from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { GET_LOGGEDIN_USER } from '../../pages/Login';
import { GET_SIDEBAR_IS_OPEN } from '../../services/graphql-client';
import ColumnsSettings from '../ColumnsSettings/ColumnsSettings';
import { ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import AddColumnSettingDialog from '../ColumnsSettings/AddColumnSettingDialog';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CasinoIcon from '@material-ui/icons/Casino';
import { mdiAccountHardHat } from '@mdi/js';
import Icon from '@mdi/react';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import StandingDataIcon from '@material-ui/icons/AccountBalance';
import { makeStyles } from '@material-ui/styles';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ContactsIcon from '@material-ui/icons/Contacts';
import ListItem from '@material-ui/core/ListItem';
import { LocalShipping } from '@material-ui/icons';
import { getToken } from '../../utils/getToken';
import useLocalStorage from 'use-local-storage';

const DRAWER_WIDTH = 270;

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: { display: 'flex' },
  title: {
    flexGrow: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  tabs: {
    flexGrow: 3,
    alignSelf: 'flex-end',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    overflowX: 'hidden',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7), // TODO brauchen wir 57
    [theme.breakpoints.up('sm')]: {
      // TODO: Should we use breakpoints as in the MaterialUI-example "Mini variant drawer"?
      width: theme.spacing(7),
    },
  },
  momoTitle: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

interface IAppBarProps {
  title: React.ReactNode;
  tabs: React.ReactNode;
}

const LOGOUT = gqlClient`
  mutation Logout {
    logout @client(always: true)
  }
`;

const TOGGLE_SIDEBAR = gqlClient`
  mutation toggleSidebar {
    toggleSidebar @client
  }
`;

const MenuAppBar: React.FunctionComponent<IAppBarProps> = ({ title, tabs }) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(null);

  const handleOpenMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
    setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const isUserMenuOpen = anchorEl !== null;

  const [useNewTables, setUseNewTables] = useLocalStorage<boolean>('useNewTables', false);

  const ausmassDigitalLinkOnClick = async () => {
    const token = await getToken();
    const response = await fetch('/api/ausmass-digital', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { url } = await response.json();
    if (!url) {
      throw new Error("ausmass-digital endpoint didn't return url");
    }
    window.location.href = url;
  };

  const ausmassDigitalLink = (
    <ListItem button onClick={ausmassDigitalLinkOnClick}>
      <Tooltip title={'Ausmass Digital'}>
        <ListItemIcon>
          <LocalShipping />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={'Ausmass Digital'} />
    </ListItem>
  );

  return (
    <Mutation<any, any> mutation={LOGOUT}>
      {(logout) => (
        <Query<any, any> query={GET_LOGGEDIN_USER}>
          {({
            data: {
              auth: { role, name },
            },
          }) => {
            if (role === Role.EQUIPENCHEF) {
              ausmassDigitalLinkOnClick();
            }

            return (
              <Mutation<any, any> mutation={TOGGLE_SIDEBAR}>
                {(toggleSidebar) => (
                  <Query<any, any> query={GET_SIDEBAR_IS_OPEN}>
                    {({
                      data: {
                        navigation: { sidebarIsOpen: isSidebarOpen },
                      },
                    }) => (
                      <div className={classes.root}>
                        <AppBar
                          position="fixed"
                          className={classNames(classes.appBar, {
                            [classes.appBarShift]: isSidebarOpen,
                          })}
                        >
                          <Toolbar disableGutters={!isSidebarOpen}>
                            <IconButton
                              color="inherit"
                              aria-label="Open sidebar"
                              onClick={() => toggleSidebar()}
                              className={classNames(classes.menuButton, {
                                [classes.hide]: isSidebarOpen,
                              })}
                            >
                              <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.title}>
                              {title}
                            </Typography>

                            {tabs && <div className={classes.tabs}>{tabs}</div>}

                            <div>
                              <NavigationProjectsDropDown />
                            </div>
                            <div>
                              <IconButton
                                aria-owns={isUserMenuOpen ? 'menu-appbar' : undefined}
                                aria-haspopup="true"
                                onClick={handleOpenMenuClick}
                                color="inherit"
                              >
                                <AccountCircle />
                              </IconButton>
                              <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                open={isUserMenuOpen}
                                onClose={handleMenuClose}
                              >
                                <MenuItem button={false}>{`Eingeloggt als: ${name}`}</MenuItem>
                                <MenuItem onClick={() => logout()}>Ausloggen</MenuItem>
                                <Divider />
                                <MenuItem
                                  onClick={() => {
                                    setUseNewTables(!useNewTables);
                                  }}
                                >
                                  Zu {!useNewTables ? 'neuen' : 'alten'} Tabellen wechseln
                                </MenuItem>
                                <Divider />
                                <ColumnsSettings userName={name} />
                                <MenuItem onClick={() => setOpen(true)}>
                                  <ListItemIcon>
                                    <AddCircleIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Neue Einstellung" />
                                </MenuItem>
                              </Menu>
                              <AddColumnSettingDialog open={open} onClose={() => setOpen(!open)} />
                            </div>
                          </Toolbar>
                        </AppBar>
                        <Drawer
                          variant="permanent"
                          className={classNames(classes.drawer, {
                            [classes.drawerOpen]: isSidebarOpen,
                            [classes.drawerClose]: !isSidebarOpen,
                          })}
                          classes={{
                            paper: classNames(classes.drawerPaper, {
                              [classes.drawerOpen]: isSidebarOpen,
                              [classes.drawerClose]: !isSidebarOpen,
                            }),
                          }}
                          open={isSidebarOpen}
                        >
                          <div className={classes.toolbar}>
                            <Link to="/">
                              <Typography
                                variant="h6"
                                color="inherit"
                                className={classes.momoTitle}
                              >
                                Momo
                              </Typography>
                            </Link>
                            <IconButton onClick={() => toggleSidebar()}>
                              <ChevronLeftIcon />
                            </IconButton>
                          </div>
                          <Divider />
                          <List
                            subheader={<NavigationSubsidiary isSidebarOpen={isSidebarOpen} />}
                            disablePadding
                          >
                            <AppDrawerNavItem
                              href="/projekte"
                              title="Projekte"
                              icon={<HomeIcon />}
                            />
                            <AppDrawerNavItem
                              href="/offerten"
                              title="Offerten"
                              icon={<AssignmentIcon />}
                            />
                            <AppDrawerNavItem
                              href="/auftraege"
                              title="Aufträge"
                              icon={<ListAltIcon />}
                            />
                            <AppDrawerNavItem
                              href="/einsaetze"
                              title="Einsätze"
                              icon={<EventIcon />}
                            />
                            <AppDrawerNavItem
                              href="/ausmass"
                              title="Ausmass"
                              icon={<FormatPaintIcon />}
                            />
                            <AppDrawerNavItem
                              href="/rechnungen"
                              title="Rechnungen"
                              icon={<MonetizationOnIcon />}
                            />
                            <AppDrawerNavItem
                              href="/maschinen"
                              title="Maschinen"
                              icon={<CasinoIcon />}
                            />
                            <AppDrawerNavItem
                              href="/mitarbeiter"
                              title="Mitarbeiter"
                              icon={<Icon path={mdiAccountHardHat} size={1} />}
                            />
                            <AppDrawerNavItem
                              href="/materialien"
                              title="Materialien"
                              icon={<ClearAllIcon />}
                            />
                            <AppDrawerNavItem
                              href="/fahrzeuge"
                              title="Fahrzeuge"
                              icon={<AirportShuttleIcon />}
                            />
                          </List>
                          <Divider />
                          <List
                            subheader={
                              isSidebarOpen ? <ListSubheader>Spezial</ListSubheader> : undefined
                            }
                            disablePadding
                          >
                            <AppDrawerNavItem
                              href="/abgrenzung-projekte"
                              title="Abgrenzung Projekte"
                              icon={<CompareArrowsIcon />}
                            />
                            {ausmassDigitalLink}
                          </List>
                          <Divider />
                          <List
                            subheader={
                              isSidebarOpen ? <ListSubheader>Kataloge</ListSubheader> : undefined
                            }
                            disablePadding
                          >
                            {hasAccessRights(role, Role.ADMIN) && (
                              <>
                                <AppDrawerNavItem
                                  href="/kataloge"
                                  title="Kataloge"
                                  icon={<StorageIcon />}
                                />
                                <AppDrawerNavItem
                                  href="/vorlagen-gruppen"
                                  title="Vorlagen-Gruppen"
                                  icon={<SortIcon />}
                                />
                              </>
                            )}
                            <AppDrawerNavItem
                              href="/einzel-positions-vorlagen"
                              title="Einzel-Positions-Vorlagen"
                              icon={<ShortTextIcon />}
                            />
                          </List>

                          <>
                            <Divider />
                            <List
                              subheader={
                                isSidebarOpen ? (
                                  <ListSubheader>Administration</ListSubheader>
                                ) : undefined
                              }
                              disablePadding
                            >
                              {hasAccessRights(role, Role.SUPER_ADMIN) && (
                                <AppDrawerNavItem
                                  href="/benutzer"
                                  title="Stammdaten"
                                  icon={<StandingDataIcon />}
                                />
                              )}
                              {hasAccessRights(role, Role.SUPER_ADMIN) && (
                                <>
                                  <AppDrawerNavItem
                                    href="/adressen-import"
                                    title="Schnittstelle"
                                    icon={<ImportExportIcon />}
                                  />
                                  <AppDrawerNavItem
                                    href="/planung"
                                    title="Planung"
                                    icon={<CalendarIcon />}
                                  />
                                </>
                              )}
                              {/*{hasAccessRights(role, Role.SUPER_ADMIN) && name === 'dev' && (*/}
                              {/*  <AppDrawerNavItem*/}
                              {/*    href="/buchungsjournal/rapport/erstellen"*/}
                              {/*    title="Buchungsjournal"*/}
                              {/*    icon={<ViewListIcon />}*/}
                              {/*  />*/}
                              {/*)}*/}
                              <AppDrawerNavItem
                                href="/adressen"
                                title="Adressen"
                                icon={<ContactsIcon />}
                              />
                            </List>
                          </>
                        </Drawer>
                      </div>
                    )}
                  </Query>
                )}
              </Mutation>
            );
          }}
        </Query>
      )}
    </Mutation>
  );
};

export default MenuAppBar;
