import React from 'react';
import { Typography } from '@material-ui/core';
import ItemBoilerplateSearchSingle from '../../Item/ItemImporter/ItemBoilerplateSearch/ItemBoilerplateSearchSingle';
import { IDataTableRow } from '../../DataTable/types';

interface IProps {
  productNumber: string;
  catalogId?: string;
  onRowClick: (row: IDataTableRow) => any;
}

const ChooseNewPositionStep: React.FunctionComponent<IProps> = ({
  productNumber,
  catalogId,
  onRowClick,
}) => (
  <div>
    <Typography variant="body2">
      Wählen Sie einen Eintrag durch die Position mit Artikelnummer {productNumber} ersetzt werden
      soll:
    </Typography>
    <ItemBoilerplateSearchSingle onRowClick={onRowClick} initCatalogId={catalogId} />
  </div>
);

export default ChooseNewPositionStep;
