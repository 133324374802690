import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { colors, createMuiTheme, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import gqlClient from './services/graphql-client';
import './index.scss';
import App from './App';
import Dinero from 'dinero.js';

Dinero.globalRoundingMode = 'HALF_UP';

const theme = createMuiTheme({
  palette: {
    primary: colors.blue,
    secondary: colors.yellow,
  },
  typography: {
    subtitle2: {
      color: 'black',
    },
    caption: {
      color: 'black',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.7rem', // see #7594
      },
    },
    MuiIconButton: {
      root: {
        padding: '6px 6px',
      },
    },
  },
});

navigator.serviceWorker.register('/sw.js');

ReactDOM.render(
  <ApolloProvider client={gqlClient}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
