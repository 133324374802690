import { omitBy, isNil, defaultsDeep } from 'lodash';

/**
 * @param {any} initialValues - form's initial values
 * @param {T} defaultValues - the values to replace invalid initial values
 *
 * replaces initial values which are null or undefined (using lodash's isNil) with the default values
 */
const defaultNilInitialValues = <T>(initialValues: any, defaultValues: T): T => {
  return defaultsDeep(omitBy(initialValues, isNil), defaultValues);
};

export default defaultNilInitialValues;
