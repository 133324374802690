import { IChildrenRows, IDataTableRow } from '../types';

const filterOpenChildrenRows = (
  childrenRows: IChildrenRows,
  closedRowIds: string[],
  alwaysOpenRowIds: string[],
): IChildrenRows => ({
  containerRows: filterOpenRows(childrenRows.containerRows || [], closedRowIds, alwaysOpenRowIds),
  innerTableRows: filterOpenRows(childrenRows.innerTableRows || [], closedRowIds, alwaysOpenRowIds),
});

const filterOpenRows = (
  rows: IDataTableRow[],
  closedRowIds: string[],
  alwaysOpenRowIds: string[],
): IDataTableRow[] => rows.map((row) => filterOpenRow(row, closedRowIds, alwaysOpenRowIds));

const includesAlwaysOpenRowIds = (row: IDataTableRow, alwaysOpenRowIds: string[]): boolean =>
  alwaysOpenRowIds.includes(row.id) ||
  (row.containerRows || []).some((childRow) =>
    includesAlwaysOpenRowIds(childRow, alwaysOpenRowIds),
  ) ||
  (row.innerTableRows || []).some((childRow) =>
    includesAlwaysOpenRowIds(childRow, alwaysOpenRowIds),
  );

const filterOpenRow = (
  row: IDataTableRow,
  closedRowIds: string[],
  alwaysOpenRowIds: string[],
): IDataTableRow => {
  const isClosed =
    closedRowIds.indexOf(row.id) > -1 && !includesAlwaysOpenRowIds(row, alwaysOpenRowIds);

  const children = isClosed
    ? { containerRows: [], innerTableRows: [] }
    : filterOpenChildrenRows(row, closedRowIds, alwaysOpenRowIds);

  return {
    ...row,
    ...children,
  };
};

/**
 * Removes from all closed rows the children. It will search also in the child rows.
 *
 * Rows that are contained in alwaysOpenRowIds are always open, even if theire containers are in closedRowIds.
 * This makes it possible to open closed containers when the user f.ex. clicks on an Add-row-button in the table.
 */
export default filterOpenChildrenRows;
