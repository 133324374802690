import gql from 'graphql-tag';

export const COPY_LOCATION_MUTATION = gql`
  mutation CopyLocation($sourceId: String!, $targetId: String!, $targetBillOfQuantityId: String!) {
    copyLocation(
      sourceId: $sourceId
      targetId: $targetId
      targetBillOfQuantityId: $targetBillOfQuantityId
    ) {
      id
      hasItemsOrLocations
      name
      canBeDeleted
      billOfQuantity {
        id
      }
      parentLocation {
        id
        parentLocation {
          id
        }
      }
      locations {
        id
        hasItemsOrLocations
        name
        canBeDeleted
        billOfQuantity {
          id
        }
        parentLocation {
          id
        }
      }
    }
  }
`;
