import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useParams, useHistory } from 'react-router-dom';
import { Typography, Theme, Grid } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { makeStyles } from '@material-ui/styles';
import { MOBILE_BILL_OF_QUANTITIES_QUERY } from './BillOfQuantites.queries';
import { BillOfQuantity } from '../../components/BillOfQuantity';
import { formatDate } from '../../../utils/format/date';
import { createSwissCurrencyFormatter } from '../../../utils/createCurrencyFormatter';
import { useL8Filter } from '../../../hooks/BillOfQuantity/useL8Filter';
import { BOQStatus } from '../../../types/graphql';
import { HoveringButton } from '../../components/HoveringButton';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const mapData = (data: any[]) =>
  data.map((v) => ({
    ...v,
    status: v.status.status,
    createdAt: formatDate(v.createdAt),
    sumNet: formatCurrency(v.sumNet),
    sumGross: formatCurrency(v.sumGross),
  }));

const useStyles = makeStyles((theme: Theme) => ({
  selectLVContainer: {
    margin: theme.spacing(4, 0, 3, 0),
  },
  l8Button: {
    position: 'absolute',
    top: theme.spacing(11.25),
    right: theme.spacing(5.25),
  },
  boqsContainer: {
    marginBottom: theme.spacing(10),
  },
  listAltIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const BillOfQuantities: React.FC = () => {
  const { projectNumber } = useParams<any>();
  const history = useHistory();

  const { showStatusL8, L8Button } = useL8Filter({ fontSize: 'large', isMobile: true });

  const { data, loading, error } = useQuery(MOBILE_BILL_OF_QUANTITIES_QUERY, {
    variables: {
      projectNumber,
      ...(!showStatusL8 && { status: { status_not_in: [BOQStatus.L8] } }),
    },
  });

  const [selectedBoqId, setSelectedBoqId] = useState('');

  const classes = useStyles();

  const billOfQuantities = useMemo(() => {
    if (!data || loading) {
      return null;
    }

    return mapData(data.billOfQuantities).map((v, i) => (
      <BillOfQuantity
        onSelect={(id) => setSelectedBoqId(id)}
        isSelected={v.id === selectedBoqId}
        {...v}
        key={i}
      />
    ));
  }, [data, loading, selectedBoqId]);

  if (error) {
    console.log(error);
    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <div className={classes.l8Button}>{L8Button}</div>
      <div className={classes.selectLVContainer}>
        <Typography variant="h5">LV Auswählen</Typography>
      </div>
      <Grid container className={classes.boqsContainer}>
        {billOfQuantities}
      </Grid>
      <HoveringButton
        variant="contained"
        color="primary"
        disabled={!selectedBoqId}
        onClick={() => history.push(`leistungsverzeichnis/${selectedBoqId}`)}
      >
        <ListAltIcon fontSize="large" className={classes.listAltIcon} />
        Markierung starten
      </HoveringButton>
    </>
  );
};
