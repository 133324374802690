import React, { useState, useCallback } from 'react';
import { DataTableHotKeysWrapper, DataTableBody } from '../../components/DataTable';
import { IDataTableColumn, IDataTableRow } from '../../components/DataTable/types';
import { useQuery } from 'react-apollo';
import { omit } from 'lodash';
import { EMPLOYEE_COMPUTED_FIELDS, GET_EMPLOYEES } from './employee.queries';
import { GetEmployees, GetEmployeesVariables, GetEmployees_employees } from './types/GetEmployees';
import { translateBool } from '../../utils/bool.translate.util';
import AppProgress from '../../components/Page/AppProgress';
import { Tooltip, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { useSearchState, Search } from '../../components/Search/Search';
import { formatDate } from '../../utils/format/date';
import AddButton from '../../components/DataTable/DataTableToolbar/AddButton';
import { usePaginationState, IPaginationState } from '../../components/Pagination/Pagination.utils';
import { Pagination } from '../../components/Pagination';
import { useCount } from '../../hooks/useCount';
import { CountEntity } from '../../hooks/useCount/useCount.queries';
import { createOrderByFieldName } from '../../utils/order/createOrderByFieldName';
import { isComputedField } from '../../utils/order/isComputedField';
import { Subsidiary } from '../Missions/types/Subsidiary';
import { SELECTED_SUBSIDIARY_QUERY } from '../Missions/queries';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import { Role } from '../../types/graphql';

const columns: IDataTableColumn[] = [
  {
    id: 'employeeNumber',
    label: 'Mitarbeiternummer',
  },
  {
    id: 'firstName',
    label: 'Vorname',
  },
  {
    id: 'lastName',
    label: 'Nachname',
  },
  {
    id: 'function.name',
    label: 'Funktion',
  },
  {
    id: 'entryDate',
    label: 'Eintrittsdatum',
  },
  {
    id: 'subsidiary.name',
    label: 'Filiale',
  },
  {
    id: 'leaveDate',
    label: 'Austrittsdatum',
  },
  {
    id: 'workload',
    label: 'Arbeitspensum',
  },
  {
    id: 'supervisor',
    label: 'Vorgesetzter',
  },
  {
    id: 'user.name',
    label: 'User',
  },
  {
    id: 'isDeactivated',
    label: 'Inaktiv',
  },
];

const columnIds = columns.map(({ id }) => id);

const mapToDataTable = (employees: GetEmployees_employees[]): IDataTableRow[] => {
  return employees.map((employee) => {
    return {
      id: employee.id,
      data: {
        ...employee,
        isDeactivated: translateBool(employee.isDeactivated),
        'subsidiary.name': employee.subsidiary.name,
        entryDate: formatDate(employee.entryDate),
        leaveDate: employee.leaveDate && formatDate(employee.leaveDate),
        supervisor:
          employee.supervisor && `${employee.supervisor.firstName} ${employee.supervisor.lastName}`,
        'user.name': employee.user?.name,
        'function.name': employee.function?.name,
      },
    };
  });
};

export const EmployeesTable: React.FC = () => {
  const [showDeactivatedEmployees, setShowDeactivatedEmployees] = useState<boolean>(false);
  const searchState = useSearchState();

  const user = useLoggedInUser();

  const { data: subsidiaryData } = useQuery<Subsidiary>(SELECTED_SUBSIDIARY_QUERY);

  const subsidiary =
    subsidiaryData?.selectedSubsidiary === 'all'
      ? undefined
      : { id: subsidiaryData?.selectedSubsidiary };

  const { data, error, loading, refetch, variables } = useQuery<
    GetEmployees,
    GetEmployeesVariables
  >(GET_EMPLOYEES, {
    variables: {
      where: {
        ...(subsidiary ? { subsidiary: { id: subsidiary.id } } : {}),
        ...(!showDeactivatedEmployees && { isDeactivated: showDeactivatedEmployees }),
      },
      page: {
        amountPerPage: 25,
        pageNumber: 0,
      },
    },
  });

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        page: {
          amountPerPage: state.rowsPerPage,
          pageNumber: state.page,
        },
      });
    },
    [refetch],
  );

  const count = useCount({
    entity: CountEntity.EMPLOYEE,
    variables: omit(variables, 'page'),
  });

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  const history = useHistory();

  if (!data) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <DataTableHotKeysWrapper
        innerTableRows={mapToDataTable(data.employees || [])}
        pagination={<Pagination paginationState={paginationState} />}
        search={
          <Search
            columns={columnIds}
            searchState={searchState}
            onSubmit={(search) => refetch({ search })}
            loading={loading}
          />
        }
        options={{
          activeRowId: '',
          tableName: 'ALL_EMPLOYEES',
          filterText: searchState.searchTerm,
          levels: [
            {
              columns,
              onRowClick: (row) =>
                history.push({
                  pathname: `/mitarbeiter/${row.id}/editieren`,
                  state: { variables },
                }),
              actions: () => {
                return (
                  <>
                    <Tooltip
                      title={`Archivierte Mitarbeiter ${
                        showDeactivatedEmployees ? 'ausblenden' : 'einblenden'
                      }`}
                    >
                      <IconButton
                        onClick={() => {
                          setShowDeactivatedEmployees(!showDeactivatedEmployees);
                        }}
                      >
                        {showDeactivatedEmployees ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>

                    <AddButton
                      href="/mitarbeiter/erstellen"
                      state={{ variables }}
                      tooltip="Mitarbeiter erstellen"
                      disabled={user?.role !== Role.SUPER_ADMIN}
                    />
                  </>
                );
              },
            },
          ],
          onChangeSort: async (fieldName, order) => {
            await refetch({
              orderBy: {
                fieldName: createOrderByFieldName(fieldName, order),
                isComputed: isComputedField(fieldName, EMPLOYEE_COMPUTED_FIELDS),
              },
            });
          },
        }}
      >
        {(context) => <DataTableBody context={context} />}
      </DataTableHotKeysWrapper>
    </>
  );
};
