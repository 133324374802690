import React, { useCallback } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Machine, MachineVariables } from './MachineForm/types/Machine';
import { GET_MACHINE } from './MachineForm/MachineForm.queries';
import AppProgress from '../Page/AppProgress';
import { MachineForm } from './MachineForm/MachineForm';
import PageWrapper from '../PageWrapper';
import { UPDATE_MACHINE } from './Machine.queries';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import AppErrorMessage from '../Page/AppErrorMessage';
import { UpdateMachine, UpdateMachineVariables } from './types/UpdateMachine';
import { omitInvalidFields } from '../../utils/filterInvalidApiData';
import { MACHINE_TABLE_QUERY } from './MachineTable/MachineTable.queries';

const MachineEdit = () => {
  const { machineInventoryNumber } = useParams();

  const history = useHistory();
  const { state } = useLocation();

  const [updateMachine, { error: mutationError }] = useMutation<
    UpdateMachine,
    UpdateMachineVariables
  >(UPDATE_MACHINE, {
    refetchQueries: [
      { query: MACHINE_TABLE_QUERY, variables: state.variables },
      { query: GET_MACHINE, variables: { inventoryNumber: machineInventoryNumber } },
    ],
  });

  const { data, loading, error } = useQuery<Machine, MachineVariables>(GET_MACHINE, {
    variables: { inventoryNumber: machineInventoryNumber },
    fetchPolicy: 'network-only',
  });

  const handleSubmit = useCallback(
    async (data: any) => {
      await updateMachine({
        variables: {
          data: {
            ...omitInvalidFields(data),
            ...(data.subsidiary && { subsidiary: omitInvalidFields(data.subsidiary) }),
          },
          where: { inventoryNumber: machineInventoryNumber },
        },
      });

      history.goBack();
    },
    [updateMachine, history, machineInventoryNumber],
  );

  if (!data) {
    return null;
  }

  if (loading) {
    return <AppProgress />;
  }

  if (error) {
    console.log(error);
  }

  return (
    <>
      {mutationError && <AppErrorMessage message={errorPrefixRemover(mutationError.message)} />}
      <PageWrapper header={{ title: 'Maschine editieren', onClose: () => history.goBack() }}>
        <MachineForm machine={data.machine} onSubmit={handleSubmit} />
      </PageWrapper>
    </>
  );
};

export default MachineEdit;
