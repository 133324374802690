import { makeStyles, Paper, Theme, Box } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { DragDropContext, DraggableLocation, Droppable, DropResult } from 'react-beautiful-dnd';
import { FolderCategory, UpdateDocumentsInput } from '../../types/graphql';
import { deepCopy } from '../../utils/deepCopy';
import { getChangedFormValues } from '../../utils/form/getChanged';
import CancelSaveIconButtons from '../Form/CancelSaveIconButtons';
import AppProgress from '../Page/AppProgress';
import Folder from './Folder';
import {
  DELETE_DOCUMENT_MUTATION,
  GET_PROJECT_REPOSITORY_QUERY,
  UPDATE_MANY_DOCUMENTS_MUTATION,
} from './queries';
import { DeleteDocuments, DeleteDocumentsVariables } from './types/DeleteDocuments';
import {
  GetProjectStorageRepository,
  GetProjectStorageRepositoryVariables,
  GetProjectStorageRepository_projectStorageRepository_folders,
} from './types/GetProjectStorageRepository';
import { updateManyDocuments, updateManyDocumentsVariables } from './types/updateManyDocuments';

interface Props {
  projectNumber: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3, 0, 3, 0),
  },
  form: {
    margin: theme.spacing(2.5),
  },
  buttonsContainer: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function mapToUpdateDocumentsInput(values: Record<string, string>): UpdateDocumentsInput[] {
  return Object.entries(values).map(([key, value]) => {
    const [, documentId] = key.split('-');

    return {
      data: { name: value },
      where: { id: documentId },
    };
  });
}

const FileRepository: React.FC<Props> = ({ projectNumber }) => {
  const classes = useStyles();

  const [deletedFiles, setDeletedFiles] = useState<string[]>([]);

  const [folders, setFolders] = useState<
    GetProjectStorageRepository_projectStorageRepository_folders[]
  >([]);

  const { data, loading, refetch } = useQuery<
    GetProjectStorageRepository,
    GetProjectStorageRepositoryVariables
  >(GET_PROJECT_REPOSITORY_QUERY, { variables: { projectNumber } });

  const [deleteDocuments, { loading: deleteLoading }] = useMutation<
    DeleteDocuments,
    DeleteDocumentsVariables
  >(DELETE_DOCUMENT_MUTATION);

  const [updateDocuments, { loading: updateLoading }] = useMutation<
    updateManyDocuments,
    updateManyDocumentsVariables
  >(UPDATE_MANY_DOCUMENTS_MUTATION);

  const initialValues = useMemo(() => {
    if (!data || !data?.projectStorageRepository?.folders || loading) {
      return {};
    }

    return data.projectStorageRepository.folders.reduce<Record<string, string>>((acc, curr) => {
      curr.documents.forEach((doc) => {
        acc[`name-${doc.id}`] = doc.name;
      });

      return acc;
    }, {});
  }, [data, loading]);

  useEffect(() => {
    setFolders(data?.projectStorageRepository?.folders ?? []);
  }, [data]);

  const handleOptimisticDragEnd = useCallback(
    (source: DraggableLocation, destination: DraggableLocation) => {
      const newFolders = deepCopy(folders);

      const sourceFolderIdx = folders.findIndex((folder) => {
        return folder.category === source.droppableId;
      })!;
      const distinctionFolderIdx = folders.findIndex(
        (folder) => folder.category === destination.droppableId,
      );

      const fileIdx = folders[sourceFolderIdx].documents.findIndex((file) => file.id);

      newFolders[distinctionFolderIdx].documents.push(folders[sourceFolderIdx].documents[fileIdx]);
      newFolders[sourceFolderIdx].documents.splice(fileIdx, 1);

      setFolders(newFolders);
    },
    [folders],
  );

  const handleDragEnd = useCallback(
    async ({ source, destination, draggableId }: DropResult) => {
      if (!destination) {
        return;
      }

      handleOptimisticDragEnd(source, destination);

      await updateDocuments({
        variables: {
          documents: [
            {
              where: { id: draggableId },
              data: { category: destination.droppableId as FolderCategory },
            },
          ],
        },
      });

      await refetch();
    },
    [handleOptimisticDragEnd, refetch, updateDocuments],
  );

  return (
    <>
      {loading || (updateLoading && <AppProgress />)}
      <Paper classes={{ root: classes.paper }}>
        <Formik
          onSubmit={async (values) => {
            if (deletedFiles.length > 0) {
              await deleteDocuments({ variables: { documentIds: deletedFiles } });
              setDeletedFiles([]);
            }

            const changedValues = getChangedFormValues({ initialValues, values });

            if (!isEmpty(changedValues)) {
              const documents = mapToUpdateDocumentsInput(changedValues);

              await updateDocuments({ variables: { documents } });
            }

            await refetch();
          }}
          initialValues={initialValues}
          enableReinitialize
        >
          {({ dirty }) => {
            return (
              <Form className={classes.form}>
                <DragDropContext onDragEnd={handleDragEnd}>
                  {folders.map((folder) => (
                    <Droppable key={folder.category} droppableId={folder.category} type="PERSON">
                      {(provided) => {
                        return (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            <Folder
                              deletedFiles={deletedFiles}
                              setDeletedFiles={setDeletedFiles}
                              folder={folder}
                              projectNumber={projectNumber}
                            />
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  ))}
                </DragDropContext>
                <Box className={classes.buttonsContainer}>
                  <CancelSaveIconButtons
                    isDisabled={
                      deletedFiles.length < 1 && !dirty && !updateLoading && !deleteLoading
                    }
                    onCancel={() => {
                      setDeletedFiles([]);
                    }}
                    disabledTooltipTitles={{
                      save: 'Dokumente werden sofort nach dem Hochladen gespeichert',
                      cancel: '',
                    }}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </>
  );
};

export default FileRepository;
