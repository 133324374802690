import React, { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { useHistory, useLocation } from 'react-router-dom';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import AppErrorMessage from '../Page/AppErrorMessage';
import PageWrapper from '../PageWrapper';
import { CREATE_MACHINE } from './Machine.queries';
import { MachineForm } from './MachineForm/MachineForm';
import { MACHINE_TABLE_QUERY } from './MachineTable/MachineTable.queries';
import { CreateMachine, CreateMachineVariables } from './types/CreateMachine';

const newMachineValues: any = {
  category: '',
  chassisNumber: '',
  driver: '',
  emissionsTestDate: '',
  hoursOfOperation: '',
  hoursOfOperationHistory: [],
  internalHourlyWage: '',
  inventoryNumber: '',
  isDeactivated: false,
  lastInternalTestDate: '',
  nameOne: '',
  nameTwo: '',
  note: '',
  projectNumber: '',
  registrationNumber: '',
  releaseDate: '',
  rootNumber: '',
  skills: [],
  subsidiary: '',
  subsidiaryHistory: [],
  trafficOfficeTestDate: '',
  weight: '',
};

export const MachineCreate: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation();

  const [createMachine, { error }] = useMutation<CreateMachine, CreateMachineVariables>(
    CREATE_MACHINE,
    {
      refetchQueries: [
        {
          query: MACHINE_TABLE_QUERY,
          variables: state.variables,
        },
      ],
    },
  );

  const onSubmit = useCallback(
    async (data: any) => {
      await createMachine({ variables: { data: { ...data, skills: data.skills ?? [] } } });

      history.goBack();
    },
    [createMachine, history],
  );

  return (
    <>
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <PageWrapper header={{ title: 'Maschine erstellen', onClose: () => history.goBack() }}>
        <MachineForm machine={newMachineValues} onSubmit={onSubmit} />
      </PageWrapper>
    </>
  );
};
