import React, { useMemo } from 'react';
import { MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import FormikSelect from '../../../components/Form/FormikSelect';
import { GET_SUBSIDIARIES } from '../../../components/User/UserForm';
import { useQuery } from 'react-apollo';
import { getSubsidiaries } from '../../Subsidiaries/types/getSubsidiaries';
import HistorizedField, { IHistorizedFieldProps } from '../../../components/Form/HistorizedField';
import { SelectProps } from '@material-ui/core/Select';

interface IProps extends Partial<IHistorizedFieldProps> {
  multiple?: boolean;
  name?: string;
  disabled?: boolean;
}

const SubsidiarySelect: React.FC<IProps> = ({
  history,
  isEditable,
  entityId,
  fieldType,
  refetchQueries,
  disabled,
  ...props
}) => {
  const { data, loading, error } = useQuery<getSubsidiaries>(GET_SUBSIDIARIES);

  const selectProps = useMemo(
    (): SelectProps =>
      'name' in props
        ? { multiple: props.multiple, name: props.name }
        : { name: 'subsidiary.name' },
    [props],
  );

  const options = useMemo(
    () =>
      data?.subsidiaries?.map(({ id, name }) => (
        <MenuItem key={id} value={name}>
          {name}
        </MenuItem>
      )),
    [data],
  );

  if (!data || loading) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  return history ? (
    <HistorizedField
      {...selectProps}
      label="Filiale"
      component={FormikSelect}
      history={history}
      isEditable={isEditable}
      entityId={entityId}
      fieldType={fieldType}
      refetchQueries={refetchQueries}
      disabled={disabled}
    >
      {options}
    </HistorizedField>
  ) : (
    <Field disabled={disabled} {...selectProps} label="Filiale" component={FormikSelect}>
      {options}
    </Field>
  );
};

export default SubsidiarySelect;
