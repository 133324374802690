import React from 'react';
import { Tooltip, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtomMobile: {
    position: 'static',
    padding: 0,
  },
}));

interface IProps {
  onClose?: (...args: any[]) => void;
  mobile?: boolean;
}

const CloseIconButton: React.FunctionComponent<IProps> = ({ onClose, mobile }) => {
  const classes = useStyles();

  return onClose ? (
    <Tooltip title="Schließen (ESC)">
      <IconButton
        aria-label="Schließen"
        className={classNames(classes.closeButton, mobile ? classes.closeButtomMobile : '')}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Tooltip>
  ) : null;
};

/** A close icon button for dialogs */
export default CloseIconButton;
