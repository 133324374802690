import {
  RangeQueryDate,
  RangeQueryFloat,
  RangeQueryInt,
  RangeQueryString,
} from '../../../../types/graphql';
import mapValues from 'lodash/fp/mapValues';
import { isValidDate } from '../../../../utils/durations';

const operatorMap: Readonly<Record<string, string>> = {
  '<': 'lt',
  '<=': 'lte',
  '>': 'gt',
  '>=': 'gte',
};

export const toRangeQuery = (value: string | null | undefined): RangeQueryString | undefined => {
  if (value == null || value === '') {
    return undefined;
  }
  const parts = value.match(/^([><]?=?)(.*)/);
  const operatorStr = parts?.[1];
  const operator = operatorMap[operatorStr ?? ''] ?? 'eq';
  return { [operator]: parts?.[2] };
};

export const toRangeQueryFloat = (
  value: string | null | undefined,
  mult: number = 1,
): RangeQueryFloat | undefined => {
  const rangeQueryStr = toRangeQuery(value);
  if (rangeQueryStr == null) {
    return undefined;
  }

  try {
    return mapValues((valueStr) => {
      const float = Number(valueStr) * mult;
      if (isNaN(float)) {
        throw new Error('ParseError: could not parse number');
      }
      return float;
    }, rangeQueryStr);
  } catch (error) {
    return undefined;
  }
};

export const toRangeQueryInt = (
  value: string | null | undefined,
  mult: number = 1,
): RangeQueryInt | undefined => {
  const rangeQueryFloat = toRangeQueryFloat(value, mult);
  if (rangeQueryFloat == null) {
    return undefined;
  }
  return mapValues((value) => Math.round(value ?? /* can't happen here*/ 0), rangeQueryFloat);
};

export const toRangeQueryDate = (value: string | null | undefined): RangeQueryDate | undefined => {
  const rangeQueryStr = toRangeQuery(value);
  if (rangeQueryStr == null) {
    return undefined;
  }

  try {
    return mapValues((dateStr) => {
      // transforms 01/12/2020 to 2020/12/01 so it is valid for the date constructor
      const dateParts = (dateStr ?? '').split('/');
      if (dateParts.length !== 3 || dateParts[2].length !== 4) {
        throw new Error("ParseError: couldn't parse date");
      }
      dateParts.reverse();
      const formattedDate = dateParts.join('/');
      const date = new Date(formattedDate);
      if (!isValidDate(date)) {
        throw new Error("ParseError: couldn't parse date");
      }
      return date;
    }, rangeQueryStr);
  } catch (error) {
    return undefined;
  }
};
