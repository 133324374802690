import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { EditCatalog, EditCatalogVariables } from './types/EditCatalog';

const EDIT_CATALOG = gql`
  mutation EditCatalog($where: CatalogWhereUniqueInput!, $data: CatalogUpdateInput!) {
    updateCatalog(where: $where, data: $data) {
      id
      useName
    }
  }
`;

export const useEditCatalog = () => {
  const [executeEditCatalog, { loading, error }] = useMutation<EditCatalog, EditCatalogVariables>(
    EDIT_CATALOG,
  );

  return {
    executeEditCatalog,
    loading,
    error,
  };
};
