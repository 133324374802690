import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import BillOfQuantityTable from '../../components/BillOfQuantity/BllOfQuantityTable';
import PageWrapper from '../../components/PageWrapper';
import { useQuery } from 'react-apollo';
import { ProjectCatalog, ProjectCatalogVariables } from './types/ProjectCatalog';
import { BillOfQuantityEntityType } from '../../types/graphql';

const PROJECT_CATALOG_QUERY = gql`
  query ProjectCatalog($projectNumber: String!) {
    project(where: { projectNumber: $projectNumber }) {
      id
      projectNumber
      catalog {
        id
      }
    }
  }
`;

interface IUrlParams {
  projectNumber: string;
}

const BillOfQuantity: React.FC = () => {
  const { projectNumber } = useParams<IUrlParams>();

  const { data, error, loading } = useQuery<ProjectCatalog, ProjectCatalogVariables>(
    PROJECT_CATALOG_QUERY,
    { variables: { projectNumber } },
  );

  if (loading) {
    return null;
  }
  if (!data) {
    console.log('no data');
    return null;
  }
  if (error) {
    console.log(error);
    return null;
  }

  return (
    <PageWrapper>
      <BillOfQuantityTable entityType={BillOfQuantityEntityType.ORDER} project={data.project} />
    </PageWrapper>
  );
};

export default BillOfQuantity;
