import { Field, Form, Formik } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import { isNil } from 'lodash';
import React, { ReactElement, useCallback, useLayoutEffect, useState } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import { executeForPopulatedBoqTableTypes } from '../../../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { IDataTableRow } from '../../../../../components/DataTable/types';
import FormikTextField from '../../../../../components/Form/FormikTextField';
import AppErrorMessage from '../../../../../components/Page/AppErrorMessage';
import AppProgress from '../../../../../components/Page/AppProgress';
import { useProjectNumber } from '../../../../../hooks/useProjectNumber';
import { errorPrefixRemover } from '../../../../../utils/errorPrefixRemover';
import { formatVolume } from '../../../../../utils/format/volume';
import { PAGINATION_HELPERS } from '../../../../../utils/paginationHelpers';
import { executeForPopulatedMissionTableTypes } from '../paginationHelpers/executeForMissionTypes';
import { removeTableTypeFromId, swapTableType } from '../paginationHelpers/mapper';
import { missionItemViewFields } from '../paginationHelpers/types/missionItemViewFields';
import { UPDATE_MISSION_ITEM } from './query';

export const useMissionItemEdit = (items?: missionItemViewFields[]) => {
  const [editRowId, setEditRowId] = useState('');

  const [executeUpdateMissionItem, { loading, error }] = useMutation(UPDATE_MISSION_ITEM);
  const client = useApolloClient();

  const projectNumber = useProjectNumber();

  if (!projectNumber) {
    throw new Error('ProjectNumber not defined');
  }

  const fieldId = editRowId ? `plan-volume-${editRowId}` : '';
  useLayoutEffect(() => {
    const element = document.getElementById(fieldId) as Maybe<HTMLInputElement>;
    element?.select();
  }, [fieldId]);

  const handleSubmit = useCallback(
    async (values: Record<'planVolume', string>) => {
      const parsedPlanVolume = parseFloat(values.planVolume);

      await executeUpdateMissionItem({
        variables: {
          where: { id: removeTableTypeFromId(editRowId) },
          planVolume: parsedPlanVolume,
        },
      });

      executeForPopulatedMissionTableTypes(client, projectNumber, async (tableType) => {
        PAGINATION_HELPERS[projectNumber][tableType].updateMissionItem(
          swapTableType(tableType)(editRowId),
          { planVolume: parsedPlanVolume },
          true,
        );
      });

      const currentRowIdx = items?.findIndex((item) => item.id === editRowId);
      const currentMissionItem = items![currentRowIdx!];

      const nextRowId =
        items && !isNil(currentRowIdx) && currentRowIdx !== items.length - 1
          ? items[currentRowIdx + 1].id
          : '';

      await executeForPopulatedBoqTableTypes(client, projectNumber, async (tableType) => {
        const allocatedVolume =
          items?.reduce((acc, curr) => {
            if (curr.item.id === currentMissionItem.item.id && curr.id !== currentMissionItem.id) {
              acc += curr.planVolume;
            }

            return acc;
          }, parsedPlanVolume) ?? 0;

        const openVolume = currentMissionItem.item.volume - allocatedVolume;

        await PAGINATION_HELPERS[projectNumber][tableType].updateItem(
          {
            updateItem: {
              id: currentMissionItem.item.id,
              allocatedVolume,
              openVolume: openVolume > 0 ? openVolume : 0,
            },
          },
          true,
        );
      });

      setEditRowId(nextRowId);
    },
    [executeUpdateMissionItem, editRowId, client, projectNumber, items],
  );

  const onVolumeDoubleClick = useCallback((row) => {
    setEditRowId(row.id);
  }, []);

  const renderVolumeCell = useCallback(
    (planVolume: number, row: IDataTableRow): ReactElement | string => {
      if (row.id !== editRowId) {
        return formatVolume(planVolume);
      }

      return (
        <>
          {loading && <AppProgress />}
          <AppErrorMessage
            isOpen={!isNil(error)}
            message={errorPrefixRemover(error?.message ?? '')}
          />
          <Formik
            enableReinitialize
            initialValues={{ planVolume: planVolume.toString() }}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  id={fieldId}
                  name="planVolume"
                  component={FormikTextField}
                  disabled={isSubmitting}
                  shrink
                />
              </Form>
            )}
          </Formik>
        </>
      );
    },
    [editRowId, error, loading, handleSubmit, fieldId],
  );

  return {
    renderVolumeCell,
    onVolumeDoubleClick,
  };
};
