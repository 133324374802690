import React, { useState, useCallback } from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classNames from 'classnames';
import { useDataTable, IDataTableProps } from '.';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '15rem',
    backgroundColor: fade(theme.palette.common.white, 0.25),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.35),
    },
  },
  rootOpen: {
    transition: theme.transitions.create('width'),
    width: '30rem',
  },
  iconButton: {
    padding: 10,
    color: theme.palette.common.white,
  },
  popupPaper: {
    overflowY: 'scroll',
    maxHeight: '25rem', // TODO?
  },
}));

interface IProps {
  dataTableProps: IDataTableProps;
}

export const DataTableDropDown: React.FC<IProps> = React.memo(({ dataTableProps, children }) => {
  const {
    tableData: { search },
  } = useDataTable(dataTableProps);

  const [popperIsOpen, setPopperIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>(null);

  const handleClose = useCallback(() => setPopperIsOpen(false), [setPopperIsOpen]);

  const handleClick = useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setPopperIsOpen(true);
      setAnchorEl(currentTarget);
    },
    [setAnchorEl, setPopperIsOpen],
  );

  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Paper
        className={classNames(classes.root, { [classes.rootOpen]: popperIsOpen })}
        elevation={0}
        onClick={(event) => handleClick(event)}
      >
        <IconButton className={classes.iconButton} aria-label="Suche">
          <SearchIcon />
        </IconButton>
        {search}
        <IconButton color="primary" className={classes.iconButton} aria-label="Dropdown">
          <ArrowDropDownIcon />
        </IconButton>
        <Popper
          open={popperIsOpen && !isEmpty(dataTableProps.innerTableRows)}
          anchorEl={anchorEl}
          style={{ zIndex: 2000 }}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.popupPaper}>{children}</Paper>
            </Fade>
          )}
        </Popper>
      </Paper>
    </ClickAwayListener>
  );
});
