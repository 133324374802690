// todo we should introduce moment.js or something similar to better handle time zones
import { isDate } from 'lodash';
import { IDataTableRow } from '../components/DataTable/types';

type TimedRow = IDataTableRow<{ timeFrom: string; timeTo: string }>;

/**
 * Is this from/to combination going over 00:00? e.g. 20:00 - 04:00
 * @param fromStr from time string
 * @param toStr to time string
 * @returns is from/to combination going over 00:00?
 */
export const isSpillover = (fromStr: string, toStr: string): boolean =>
  toStr === '' || fromStr === '' || toStr === '00:00' ? false : toStr < fromStr;

/**
 * Get spillover based on a timed data table row
 * @param date the day date of the times
 * @param row the timed row
 * @returns is from/to combination going over 00:00?
 */
export const isSpilloverFromRow = (date: string, row: TimedRow) =>
  isSpillover(row.data.timeFrom, row.data.timeTo);

/**
 * Get spillover based on a timed data table cell.
 * @param cellData the timed cellData
 * @returns is from/to combination going over 00:00?
 */
export const isSpilloverFromCell = (cellData: TimedRow['data']) => {
  const { timeFrom, timeTo } = cellData;
  return isSpillover(timeFrom, timeTo);
};

/**
 * check whether a date is valid
 * @param date the date to check
 */
export const isValidDate = (date: Date): date is Date => {
  return isDate(date) && !isNaN(date.valueOf());
};

// todo this is incorrect! use moment.js
/**
 * get the day date part of a date object
 * @param date the date object
 * @returns string of the day date part
 */
export const toDay = (date: Date): string => date.toISOString().split('T')[0];

/**
 * create date object from a day and time and optionally add/subtract days
 * @param day the day date in the form 2000-01-01
 * @param time the time in the form 17:00
 * @param addDays (optional) number of days to add
 * @returns a date object
 */
export const toDate = (day: string, time: string, addDays: number = 0): Date => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(day) && /\d{2}:\d{2}/.test(time)) {
    const date = new Date(`${day}T${time}:00`);
    if (addDays !== 0) {
      date.setDate(date.getDate() + addDays);
    }
    return date;
  } else {
    throw new Error(`FormatError: day/time not in valid format day: ${day} time: ${time}`);
  }
};

/**
 * Get the duration between from/to time strings, supports spillover, i.e. time combinations that go over 00:00,
 * e.g. 20:00 - 04:00.
 * @param day the day to use, important for things like summer time
 * @param fromStr time from, e.g. 10:00
 * @param toStr time to, e.g. 12:00
 * @returns number of hours (rounded to 2 decimal places) between from and to
 */
export const getDuration = (day: string, fromStr: string, toStr: string): number => {
  if (day.length !== 10) {
    return 0;
  }
  if (fromStr === '' || toStr === '') {
    return 0;
  }
  const from = toDate(day, fromStr);
  if (toStr === '00:00') {
    toStr = '24:00';
  }
  const to = toDate(day, toStr, isSpillover(fromStr, toStr) ? 1 : 0);
  if (!isValidDate(from) || !isValidDate(to)) {
    throw new Error(`invalid date formats ${day} ${fromStr} ${toStr}`);
  }
  return Number(((to.getTime() - from.getTime()) / 1000 / 60 / 60).toFixed(2));
};

/**
 * Get the duration between from/to timestrings for a timed row.
 * @param date the day date of the times
 * @param row the timed data table row
 * @returns number of hours (rounded to 2 decimal places) between from and to
 */
export const getDurationFromRow = (date: string, row: TimedRow): number =>
  getDuration(date, row.data.timeFrom, row.data.timeTo);

/**
 * Get the duration between from/to timestrings for a timed row.
 * @param date the day date of the times
 * @param cellData the timed data table cell
 * @returns number of hours (rounded to 2 decimal places) between from and to
 */
export const getDurationFromCell = (date: string, cellData: TimedRow['data']): number => {
  const { timeFrom, timeTo } = cellData;
  return getDuration(date, timeFrom, timeTo);
};
