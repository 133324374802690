import { Maybe } from 'graphql/jsutils/Maybe';
import { head, isEmpty, uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApolloClient, useQuery } from 'react-apollo';
import { sortContainerRowsByIndex } from '../../../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/mapper';
import {
  IDataTableRow,
  ILevelOptions,
  OnLoadMore,
} from '../../../../../components/DataTable/types';
import { ISearchSubmitProps } from '../../../../../components/Search/Search';
import {
  ADD_ROW_TO_DATA_TABLE,
  filterRows,
  GET_DATA_TABLE,
  GET_PAGE_INFOS,
  UPDATE_DATA_TABLE_ROW,
} from '../../../../../services/graphql-client';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../../../../services/types/AddRowToDataTable';
import { GetDataTable, GetDataTableVariables } from '../../../../../services/types/GetDataTable';
import { GetPageInfos } from '../../../../../services/types/GetPageInfos';
import {
  UpdateDataTableRow,
  UpdateDataTableRowVariables,
} from '../../../../../services/types/UpdateDataTableRow';
import {
  AddRowToDataTableInput,
  MissionItemWhereInput,
  RowType,
  TableType,
} from '../../../../../types/graphql';
import { flattenContainerRows } from '../../../../../utils/flattenRows/flattenRows';
import { IPaginationHelperFns, PAGINATION_HELPERS } from '../../../../../utils/paginationHelpers';
import { getAllInnerTableRows } from '../../../../../utils/paginationHelpers/getAllInnerTableRows';
import { fetchMissionItemsConnection } from './fetchItemsConnection';
import {
  mapMissionItem,
  mapMissionRowsToTableData,
  mapMissionStructureToDataTableRowInput,
  removeTableTypeFromId,
} from './mapper';
import { onSearchMission } from './onSearch';
import { onSearchMissionItems } from './onSearchItems';
import { MISSION_STRUCTURE_QUERY, REFETCH_MISSION_ITEM_QUERY } from './queries';
import { refetchMissionItems } from './refetchItems';
import { reorderLocationInMission } from './reorderLocation';
import { MissionFetchItemsVariables } from './types/MissionFetchItems';
import {
  MissionStructureQuery,
  MissionStructureQueryVariables,
} from './types/MissionStructureQuery';
import { RefetchMissionItem, RefetchMissionItemVariables } from './types/RefetchMissionItem';
import { updateMissionComputedFields } from './updateMissionComputedFields';
import { fetchMAD } from '../../../../../utils/fetchMAD';

export interface IMissionLocationIdInput {
  id: string;
  parentLocation?: {
    id: string;
    parentLocation?: {
      id: string;
    };
  };
}

export const buildMissionLocationId = (
  missionId: number,
  locationInput: IMissionLocationIdInput,
) => {
  console.log(locationInput.parentLocation);
  if (!locationInput.parentLocation) {
    return `${missionId}`;
  }

  if (!locationInput.parentLocation.parentLocation) {
    return `${missionId}-${locationInput.id}`;
  }

  return `${missionId}-${locationInput.parentLocation.id}-${locationInput.id}`;
};

const isLevelToggleButtonVisible = (row: IDataTableRow): boolean => row.data.hasDescendants;

type AdditionalPositionData = {
  [missionId: string]: {
    [locationId: string]: Array<{
      id: number;
      name: string;
      color: string;
      volume: number;
      unit: string;
      material: string;
      mission: number;
    }>;
  };
};

export const useMissionData = (
  projectNumber: string,
  tableType: TableType.MEASUREMENT | TableType.MISSION | TableType.BILL_MISSION,
  showFinishedMissionItems: boolean,
  hideFullyInvoiced: boolean | undefined,
) => {
  const [loading, setLoading] = useState(false);
  const [searchState, setSearchState] = useState<ISearchSubmitProps>({ columns: [], terms: [] });
  const client = useApolloClient();

  const { data } = useQuery<GetDataTable, GetDataTableVariables>(GET_DATA_TABLE, {
    variables: {
      id: tableType,
    },
  });

  const missionIds = useMemo(
    () => uniq(data?.dataTable?.rows?.map((row) => head(row.id.split('-')))) ?? [],
    [data],
  );

  const [additionalPositions, setAdditionalPositions] = useState<AdditionalPositionData>();

  const fetchAdditionalPositions = useCallback(async () => {
    const fetchAdditional = async () => {
      const additionalPositionsFetched = await Promise.all(
        (missionIds.filter(Boolean) as string[]).map((missionId) => {
          const url = `/api/getAdditionalPositions?missionId=${missionId}`;

          return fetchMAD(url).then((json) => ({ [missionId]: json }));
        }),
      );
      setAdditionalPositions(
        additionalPositionsFetched.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
      );
    };
    fetchAdditional().catch((e) => console.error(e));
  }, [missionIds, setAdditionalPositions]);

  useEffect(() => {
    fetchAdditionalPositions();
  }, [missionIds, fetchAdditionalPositions]);

  const addMissionsToCache = useCallback(
    (structureData: MissionStructureQuery) => {
      if (!structureData || !isEmpty(data?.dataTable?.rows)) {
        return;
      }

      const inputs: AddRowToDataTableInput[] = structureData.missions.map((mission) => ({
        type: RowType.CONTAINER,
        row: mapMissionStructureToDataTableRowInput(tableType)(mission),
      }));

      client.query<AddRowToDataTable, AddRowToDataTableVariables>({
        query: ADD_ROW_TO_DATA_TABLE,
        variables: {
          where: { id: tableType },
          data: inputs,
        },
      });
    },
    [client, data, tableType],
  );

  useQuery<MissionStructureQuery, MissionStructureQueryVariables>(MISSION_STRUCTURE_QUERY, {
    variables: { projectNumber },
    // only gets fetched on-mount when no rows in datatable exist
    skip: !!data?.dataTable && !isEmpty(data.dataTable.rows),
    fetchPolicy: 'cache-and-network',
    onCompleted: addMissionsToCache,
  });

  const { data: pageInfosData } = useQuery<GetPageInfos>(GET_PAGE_INFOS);

  const dataTable = data?.dataTable;

  const filteredDataTable = !dataTable ? null : filterRows(dataTable);
  const mappedRows = sortContainerRowsByIndex(
    mapMissionRowsToTableData(filteredDataTable?.rows ?? []),
  );

  const flattenedContainerRows = useMemo(() => flattenContainerRows(mappedRows), [mappedRows]);

  const pageInfos = useMemo(() => {
    if (!pageInfosData?.pageInfos) {
      return [];
    }

    return pageInfosData.pageInfos;
  }, [pageInfosData]);

  const hasNextPage = useCallback<IPaginationHelperFns['hasNextPage']>(
    (containerId) => {
      return !!pageInfos.find((pageInfo) => pageInfo.id === containerId)?.hasNextPage;
    },
    [pageInfos],
  );

  const fetchItemsConnection = useMemo(
    () => fetchMissionItemsConnection(client)(tableType),
    [client, tableType],
  );
  const refetchItemsInContainer = useMemo(
    () =>
      refetchMissionItems(client)(tableType)(
        projectNumber,
        showFinishedMissionItems,
        hideFullyInvoiced,
      )(searchState)(setLoading)(filteredDataTable),
    [
      client,
      searchState,
      setLoading,
      filteredDataTable,
      tableType,
      projectNumber,
      showFinishedMissionItems,
      hideFullyInvoiced,
    ],
  );
  const onSearch = useMemo(
    () =>
      onSearchMission(client)(tableType)(projectNumber)(setLoading, setSearchState)(
        data?.dataTable,
      ),
    [client, setLoading, setSearchState, data, projectNumber, tableType],
  );
  const onSearchItems = useMemo(
    () => onSearchMissionItems(client)(tableType)(setLoading, setSearchState)(filteredDataTable),
    [client, setLoading, setSearchState, tableType, filteredDataTable],
  );
  const onToggleItems = useCallback(
    (showFinishedMissionItems: boolean | null, hideFullyInvoiced?: Maybe<boolean>) => {
      const isFinished =
        tableType === TableType.BILL_MISSION
          ? true
          : showFinishedMissionItems === null
          ? undefined
          : showFinishedMissionItems
          ? undefined
          : false;

      return refetchItemsInContainer(
        flattenedContainerRows.map((row) => ({ containerId: row.id })),
        {
          isFinished,
          hideFullyInvoiced: hideFullyInvoiced === null ? undefined : hideFullyInvoiced,
        },
      );
    },
    [flattenedContainerRows, refetchItemsInContainer, tableType],
  );
  const updateComputedFields = useMemo(
    () => updateMissionComputedFields(client)(tableType),
    [client, tableType],
  );

  const updateMissionItem = useCallback(
    (rowId: string, values: any, isComputedChange: boolean) => {
      client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
        query: UPDATE_DATA_TABLE_ROW,
        variables: {
          where: { id: rowId, tableType },
          data: {
            partial: true,
            data: JSON.stringify(values),
          },
        },
      });

      if (isComputedChange) {
        const missionRow = flattenedContainerRows.find(
          (containerRow) =>
            !removeTableTypeFromId(containerRow.id).includes('-') &&
            getAllInnerTableRows(containerRow).some((innerRow) => innerRow.id === rowId),
        )!;
        updateComputedFields({ id: parseInt(missionRow.data.id, 10), project: { projectNumber } });
      }
    },
    [client, updateComputedFields, flattenedContainerRows, tableType, projectNumber],
  );

  const refetchMissionItemComputedFields = useCallback(
    async (missionItemId: string): Promise<void> => {
      const { data } = await client.query<RefetchMissionItem, RefetchMissionItemVariables>({
        query: REFETCH_MISSION_ITEM_QUERY,
        variables: { where: { id: missionItemId } },
      });

      if (!data?.missionItem) {
        return;
      }

      await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
        query: UPDATE_DATA_TABLE_ROW,
        variables: {
          where: { id: `${missionItemId}-${tableType}`, tableType },
          data: { partial: true, data: JSON.stringify(mapMissionItem(data.missionItem)) },
        },
      });
    },
    [client, tableType],
  );

  const reorderLocation = useMemo(
    () => reorderLocationInMission(client, mappedRows, tableType),
    [client, mappedRows, tableType],
  );
  const onLoadMore = useCallback(
    (missionItemWhere: MissionItemWhereInput): OnLoadMore =>
      (type, containingRowId, after) => {
        const compositeId = removeTableTypeFromId(containingRowId);
        const isMission = !compositeId.includes('-');
        const missionId = parseInt(compositeId.split('-')[0], 10);

        const commonVariables: Pick<MissionFetchItemsVariables, 'pagination' | 'missionItemWhere'> =
          {
            pagination: {
              take: 10,
              after,
            },
            missionItemWhere,
          };

        if (isMission) {
          return fetchItemsConnection({
            __isMission: true,
            missionWhere: { id: missionId },
            ...commonVariables,
          });
        }

        return fetchItemsConnection({
          __isMission: false,
          locationWhere: {
            mission_id_in: [missionId],
            id_in: [compositeId],
          },
          ...commonVariables,
        });
      },
    [fetchItemsConnection],
  );
  const isOnLoadMoreDisabled = useCallback<
    Exclude<ILevelOptions['isOnLoadMoreDisabled'], undefined>
  >(
    (row) => {
      return !hasNextPage(row.id);
    },
    [hasNextPage],
  );

  useEffect(() => {
    PAGINATION_HELPERS[projectNumber] = {
      ...PAGINATION_HELPERS[projectNumber],
      [tableType]: {
        fetchItemsConnection,
        hasNextPage,
        refetchItemsInContainer,
        onSearch,
        updateComputedFields,
        updateMissionItem,
        refetchMissionItemComputedFields,
      },
    };
  }, [
    projectNumber,
    fetchItemsConnection,
    hasNextPage,
    refetchItemsInContainer,
    onSearch,
    updateComputedFields,
    updateMissionItem,
    refetchMissionItemComputedFields,
    tableType,
  ]);

  return {
    mappedRows,
    isLevelToggleButtonVisible,
    hasNextPage,
    fetchItemsConnection,
    flattenedContainerRows,
    refetchItemsInContainer,
    onSearch,
    onSearchItems,
    onToggleItems,
    updateMissionItem,
    updateComputedFields,
    reorderLocation,
    onLoadMore,
    isOnLoadMoreDisabled,
    refetchMissionItemComputedFields,
    loading,
    additionalPositions,
    refetchAdditionalPositions: fetchAdditionalPositions,
  };
};
