import { Grid, MenuItem, RootRef, Box } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import gqlClient from 'graphql-tag';
import { defaults, Dictionary, isEmpty, isNil, keyBy, omitBy } from 'lodash';
import React from 'react';
import { useQuery } from 'react-apollo';
import * as Yup from 'yup';
import { useFocusField } from '../../hooks/useFocusField';
import { Role } from '../../types/graphql';
import { filterInvalidData } from '../../utils/filterInvalidApiData';
import { getChangedFormValues } from '../../utils/form/getChanged';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import FormikCheckBoxWithLabel from '../Form/FormikCheckboxWithLabel';
import FormikSelect from '../Form/FormikSelect';
import FormikTextField from '../Form/FormikTextField';
import Dropezone from '../Form/UploadField/Dropzone';

interface IUser {
  name: string;
  password?: string;
  email?: string | null;
  role: Role;
  archived?: boolean;
  signature?: any;
  subsidiaries: string[] | null;
}

interface IUserFormProps {
  initialValues?: IUser;
  onSubmit: (data: any) => void;
}

export const GET_SUBSIDIARIES = gqlClient`
  query SubsidiariesSelector {
    subsidiaries {
      id
      name
      acronym
    }
  }
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Pflichtfeld'),
  role: Yup.string().oneOf(Object.values(Role)).required('Pflichtfeld'),
  email: Yup.string().email('Email-Adresse ist nicht korrekt'),
});

/*
 * we only store subsidiary ids in the data base
 * so we have to map subsidiary names to their ids
 * before the form is submitted
 */
const mapSubsidiaryNamesToIds = (subsidiariesNames: string[], subsidiaries: any[]) => {
  const subsidiriesNamesMap = keyBy(subsidiaries, 'name');

  return subsidiariesNames.map(
    (subsidiary) => subsidiriesNamesMap[subsidiary] && subsidiriesNamesMap[subsidiary].id,
  );
};

/*
 * we get subsidiary ids form the data base
 * so we have to map subsidiary ids to their names
 * in user interfaces
 */
export const mapIdsToSubsidiaryNames = (subsidiaryIds: string[], subsidiaries: any[]) => {
  const subsidiariesIdsMap = keyBy(subsidiaries, 'id');

  return subsidiaryIds.map(
    (subsidiaryId) => subsidiariesIdsMap[subsidiaryId] && subsidiariesIdsMap[subsidiaryId].name,
  );
};

interface IFormInitialValuesSource {
  name: string;
  password: string;
  email: string;
  role: Role;
  subsidiaries: string[];
  signature: Dictionary<any>;
}

const UserForm: React.FC<IUserFormProps> = ({ onSubmit, initialValues }) => {
  const firstField = useFocusField();

  const { data } = useQuery(GET_SUBSIDIARIES);

  const formInitialValues = defaults<Dictionary<any>, IFormInitialValuesSource>(
    omitBy(initialValues, isNil),
    {
      name: '',
      password: '',
      email: '',
      role: Role.USER,
      subsidiaries: [],
      signature: { uploads: [], deletedFiles: [] },
    },
  );

  if (formInitialValues.subsidiaries.length > 0) {
    formInitialValues.subsidiaries = mapIdsToSubsidiaryNames(
      formInitialValues.subsidiaries,
      data.subsidiaries,
    );
  }

  return (
    <Formik<IUser>
      initialValues={formInitialValues}
      onSubmit={(values, { setSubmitting }) => {
        const subsidiaries: string[] = [];

        const changedValues = getChangedFormValues({
          initialValues: formInitialValues,
          values,
        }) as IUser;

        if (changedValues.subsidiaries && changedValues.subsidiaries.length > 0) {
          subsidiaries.push(
            ...mapSubsidiaryNamesToIds(changedValues.subsidiaries, data.subsidiaries),
          );
        }

        onSubmit(
          filterInvalidData({
            ...changedValues,
            subsidiaries: isEmpty(subsidiaries) ? changedValues.subsidiaries : subsidiaries,
          }),
        );
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, values }) => (
        <Form>
          <RootRef rootRef={firstField}>
            <Field name="name" label="Name" component={FormikTextField} />
          </RootRef>
          <Field name="email" label="Email" type="email" component={FormikTextField} />
          <Field name="role" label="Rolle" component={FormikSelect}>
            {Object.entries(Role).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            ))}
          </Field>
          {values.role === Role.USER && (
            <Field name="subsidiaries" multiple label="Filialen" component={FormikSelect}>
              {data.subsidiaries.map(({ name, id }: any) => (
                <MenuItem key={id} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Field>
          )}
          <Field
            name="password"
            type="password"
            label={initialValues ? 'Passwort zurücksetzten' : 'Passwort'}
            component={FormikTextField}
            required={!initialValues}
          />
          {initialValues && (
            <Field
              name="archived"
              component={FormikCheckBoxWithLabel}
              Label={{ label: 'Archiviert' }}
            />
          )}
          <Box marginTop={2}>
            <Field
              name="signature"
              label="Unterschrift"
              single
              component={Dropezone}
              initialFiles={initialValues?.signature ? [initialValues.signature] : []}
            />
          </Box>
          <Grid container justify="flex-end">
            <CancelSaveButtons isDisabled={isSubmitting || !dirty} />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
