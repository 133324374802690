import React, { useMemo } from 'react';
import { LocationForm } from '../../../components/Location/LocationForm';
import { useMutation, useQuery } from 'react-apollo';
import {
  UpdateLocation,
  UpdateLocationVariables,
} from '../../../components/Location/LocationDetails/types/UpdateLocation';
import {
  UPDATE_LOCATION,
  GET_LOCATION,
} from '../../../components/Location/LocationDetails/LocationDetails.queries';
import {
  GetLocation,
  GetLocationVariables,
} from '../../../components/Location/LocationDetails/types/GetLocation';
import { useParams, useHistory } from 'react-router-dom';
import { omit } from 'lodash';
import { getChangedFormValues } from '../../../utils/form/getChanged';

export const MobileLocationDetails: React.FC = () => {
  const { locationId: id } = useParams();
  const history = useHistory();

  const { data, loading, error } = useQuery<GetLocation, GetLocationVariables>(GET_LOCATION, {
    variables: { where: { id } },
  });

  const [updateLocation] = useMutation<UpdateLocation, UpdateLocationVariables>(UPDATE_LOCATION, {
    refetchQueries: [{ query: GET_LOCATION, variables: { where: { id } } }],
  });

  const initialValues = useMemo(() => omit(data?.location, ['id', '__typename']), [data]);

  if (error) {
    console.log(error);
    return null;
  }

  if (loading || !data) {
    return null;
  }

  return (
    <LocationForm
      initialValues={initialValues}
      mobile
      onSubmit={async (values) => {
        await updateLocation({
          variables: {
            where: { id },
            data: getChangedFormValues({ initialValues, values, ignoreKeys: ['documents'] }),
          },
        });

        history.goBack();
      }}
    />
  );
};
