import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import { Mutation, useQuery } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import AppErrorMessage from '../../components/Page/AppErrorMessage';
import AppProgress from '../../components/Page/AppProgress';
import ProjectDetailsForm from '../../components/ProjectDetailsForm';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import { GET_LOGGEDIN_USER } from '../Login';
import { GetLoggedInUser } from '../types/GetLoggedInUser';
import { PROJECT_FIELDS_FRAGMENT } from './TabDetails';

const newProjectValues = {
  personResponsible: {
    name: '',
  },
  subsidiary: {
    name: 'Zürich',
  },
  projectNumber: '',
  projectName: '',
  status: 'P0',
  statusHistory: [],
  todoStandardkatalog: '',
  discount: 0,
  staffTariff: 0,
  commentToBranchLeader: '',
  commentToBackoffice: '',
  commentToExecutives: '',
  colorCodings: [],
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    ...theme.mixins.gutters(),
  },
}));

const CREATE_PROJECT = gql`
  mutation CreateProject($data: ProjectUpdateInput) {
    createProject(data: $data) {
      ...projectFields
    }
  }
  ${PROJECT_FIELDS_FRAGMENT}
`;

const ProjectCreate: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    data: loggedInUser,
    loading: loadingLoggedInUser,
    error: errorLoggedInUser,
  } = useQuery<GetLoggedInUser>(GET_LOGGEDIN_USER);
  const userName = loggedInUser?.auth.name;
  const newProjectValuesWithUserName = useMemo(
    () => ({
      ...newProjectValues,
      personResponsible: {
        name: userName,
      },
    }),
    [userName],
  );

  return (
    <Mutation<any, any> mutation={CREATE_PROJECT}>
      {(createProject, { data, error }) => {
        const handleSubmit: any = async (data: any) => {
          await createProject({ variables: { data } });
        };

        if (data) {
          return <Redirect to={`/projekte/${data.createProject.projectNumber}/details`} />;
        }

        return (
          <>
            {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
            {errorLoggedInUser && (
              <AppErrorMessage message={errorPrefixRemover(errorLoggedInUser.message)} />
            )}
            {loadingLoggedInUser && <AppProgress />}
            <Paper className={classes.root} elevation={1}>
              <ProjectDetailsForm
                onSubmit={handleSubmit}
                hasBoqs={false}
                project={newProjectValuesWithUserName}
              />
            </Paper>
          </>
        );
      }}
    </Mutation>
  );
};

export default ProjectCreate;
