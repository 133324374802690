import React, { useCallback, useState } from 'react';
import DetailsFormPaper from '../../components/DetailsFormPaper';
import BillForm from '../../components/Bill/BillForm';
import { RouteComponentProps } from 'react-router-dom';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Bill, BillVariables } from './types/Bill';
import { IMetaDataItem } from '../../components/MetaData';
import { UpdateBill, UpdateBillVariables } from './types/UpdateBill';
import {
  BillCreateInput,
  BillOfQuantityEntityType,
  BillStatus,
  TableType,
} from '../../types/graphql';
import { formatDate } from '../../utils/format/date';
import { BillOfQuantitySelect, BillOfQuantitySelectVariables } from './types/BillOfQuantitySelect';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { billFields } from '../Projects/TabBills/BillSelector/bill.queries';
import { UPDATE_DATA_TABLE_ROW } from '../../services/graphql-client';
import {
  UpdateDataTableRow,
  UpdateDataTableRowVariables,
} from '../../services/types/UpdateDataTableRow';
import { omit } from 'lodash';
import ApolloClient, { MutationUpdaterFn } from 'apollo-client';
import { marshalBillData } from '../Projects/TabBills/BillSelector/utils/pagination.helpers';
import { forceDataTableRefetch } from '../../utils/paginationHelpers/forceDataTableRefetch';

interface IMatchParams {
  projectNumber: string;
  billId: string;
}

const BILL_QUERY = gql`
  query Bill($billId: ID!) {
    bill(where: { id: $billId }) {
      ...billFields
      billOfQuantity {
        id
      }
      updater {
        id
        name
      }
      status
    }
  }
  ${billFields}
`;

export const BILL_OF_QUANTITIES_SELECT_QUERY = gql`
  query BillOfQuantitySelect($projectNumber: String, $entityType: BillOfQuantityEntityType) {
    billOfQuantities(
      where: {
        project: { projectNumber: $projectNumber }
        entityType: $entityType
        status: { status_not_in: [L8] }
      }
    ) {
      name
      id
    }
  }
`;

const UPDATE_BILL_MUTATION = gql`
  mutation UpdateBill($id: ID!, $data: BillCreateInput!) {
    updateBill(where: { id: $id }, data: $data) {
      ...billFields
      billOfQuantity {
        id
      }
    }
  }
  ${billFields}
`;

export const refetchBillBoqDropdown = async (client: ApolloClient<any>, projectNumber: string) => {
  await client.query({
    query: BILL_OF_QUANTITIES_SELECT_QUERY,
    variables: { projectNumber, entityType: BillOfQuantityEntityType.ORDER },
    fetchPolicy: 'network-only',
  });
};

const BillDetails: React.FC<RouteComponentProps<IMatchParams>> = ({ match, history }) => {
  const { billId, projectNumber } = match.params;
  const client = useApolloClient();

  const { data, loading, error } = useQuery<Bill, BillVariables>(BILL_QUERY, {
    variables: { billId },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: boqData,
    loading: boqLoading,
    error: boqError,
  } = useQuery<BillOfQuantitySelect, BillOfQuantitySelectVariables>(
    BILL_OF_QUANTITIES_SELECT_QUERY,
    {
      variables: { projectNumber, entityType: BillOfQuantityEntityType.ORDER },
    },
  );

  const updateBillInCache = useCallback<MutationUpdaterFn<UpdateBill>>(
    (cache, { data }) => {
      if (!data?.updateBill) {
        return;
      }

      client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
        query: UPDATE_DATA_TABLE_ROW,
        variables: {
          data: {
            data: marshalBillData(data.updateBill),
          },
          where: {
            id: data.updateBill.defaultLocation.id,
            tableType: TableType.BILL,
          },
        },
      });
    },
    [client],
  );
  const [updateBill] = useMutation<UpdateBill, UpdateBillVariables>(UPDATE_BILL_MUTATION, {
    update: updateBillInCache,
  });

  const [confirmationOverlayOpen, setConfirmationOverlayOpen] = useState<boolean>(false);
  const [billCreateData, setBillCreateData] = useState<BillCreateInput>();

  const handleClose = () => history.goBack();
  const handleSubmit = (values: BillCreateInput) => {
    if (values.status === BillStatus.STORNIERT) {
      setConfirmationOverlayOpen(true);
      setBillCreateData(values);
    } else {
      updateBill({ variables: { id: billId, data: values } });
    }
  };
  if (loading || boqLoading) {
    return null;
  }
  if (error || boqError) {
    console.log(error);
    return null;
  }
  if (!data || !boqData) {
    console.log('no data');
    return null;
  }

  const {
    bill: {
      createdAt,
      updatedAt,
      publisher,
      updater,
      isFixedBill,
      hasBillItems,
      defaultLocation,
      missions,
      canBeDeleted,
      ...formInitialValues
    },
  } = data;

  const metaData: IMetaDataItem[] = [
    {
      key: 'Erstellt am',
      value: formatDate(createdAt),
    },
    {
      key: 'Von',
      value: publisher.name,
    },
  ];

  if (updater) {
    metaData.push(
      ...[
        {
          key: 'Aktualisiert am',
          value: formatDate(updatedAt),
        },
        {
          key: 'von',
          value: updater.name,
        },
      ],
    );
  }

  return (
    <DetailsFormPaper onClose={handleClose} metaData={metaData}>
      <BillForm
        onSubmit={handleSubmit}
        initialValues={omit(formInitialValues, 'netBillSum', 'grossBillSum') as any} // TODO fix type
        isFixedBill={!!isFixedBill}
        billOfQuantities={boqData.billOfQuantities}
        hasBillItems={!!hasBillItems}
      />
      <ConfirmationDialog
        message="Bitte beachte, dass beim stornieren der Rechnung alle ihre zugehörigen Positionen gelöscht werden."
        title="Rechnung stornieren!"
        onConfirm={() => {
          updateBill({ variables: { id: billId, data: billCreateData as BillCreateInput } });
          forceDataTableRefetch(TableType.BILL, client);
        }}
        open={confirmationOverlayOpen}
        onClose={() => {
          setConfirmationOverlayOpen(false);
        }}
      />
    </DetailsFormPaper>
  );
};

export default BillDetails;
