import gql from 'graphql-tag';

export const UPDATE_LOCATIONS_INDEX = gql`
  mutation UpdateLocationsIndex(
    $missionId: Int!
    $sourceIndex: Int!
    $destinationIndex: Int!
    $locationOneId: ID
  ) {
    updateLocationsIndex(
      where: { id: $missionId }
      data: {
        sourceIndex: $sourceIndex
        destinationIndex: $destinationIndex
        locationOneId: $locationOneId
      }
    ) {
      id
    }
  }
`;
