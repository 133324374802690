import { useMutation, useApolloClient } from 'react-apollo';
import React, { FC, useCallback, useMemo } from 'react';
import AppProgress from '../../../Page/AppProgress';
import AppErrorMessage from '../../../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../../utils/errorPrefixRemover';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IDataTableRow } from '../../../DataTable/types';
import { useProjectNumber } from '../../../../hooks/useProjectNumber';
import { REMOVE_DATA_TABLE_ROW } from '../../../../services/graphql-client';
import {
  RemoveDataTableRow,
  RemoveDataTableRowVariables,
} from '../../../../services/types/RemoveDataTableRow';
import { BillOfQuantityEntityType, Role } from '../../../../types/graphql';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import { executeForPopulatedBoqTableTypes } from '../../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { DELETE_BILL_OF_QUANTITY_MUTATION } from './DeleteBillOfQuantity.queries';
import { useLoggedInUser } from '../../../../hooks/useLoggedInUser';
import { STRUCTURE_QUERY } from '../../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/queries';
import {
  BillOfQuantityStructureQuery,
  BillOfQuantityStructureQueryVariables,
} from '../../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/types/BillOfQuantityStructureQuery';
import { deepCopy } from '../../../../utils/deepCopy';
import {
  removeTableTypeFromId,
  swapTableType,
} from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { refetchBillBoqDropdown } from '../../../../pages/Bills/BillDetails';

interface IPropsBillOfQuantity {
  id: string;
  name: string;
  canBeDeleted: boolean;
  entityType: BillOfQuantityEntityType;
}

interface IProps {
  billOfQuantity: IPropsBillOfQuantity;
  row: IDataTableRow;
  buttonPrefixText: string;
}

export const DeleteBillOfQuantityAction: FC<IProps> = ({
  billOfQuantity,
  row,
  buttonPrefixText,
}) => {
  const client = useApolloClient();
  const projectNumber = useProjectNumber();
  const user = useLoggedInUser();

  const isAllowedToDelete = useMemo(() => user?.role === Role.SUPER_ADMIN, [user]);
  const removeBillOfQuantityInStructureData = useCallback(
    async (id: string) => {
      if (!projectNumber) {
        throw new Error();
      }

      const data = deepCopy(
        client.readQuery<BillOfQuantityStructureQuery, BillOfQuantityStructureQueryVariables>({
          query: STRUCTURE_QUERY,
          variables: { projectNumber, entityType: billOfQuantity.entityType },
        }),
      );
      if (!data) {
        throw new Error();
      }

      data.billOfQuantities = data.billOfQuantities.filter(
        (billOfQuantity) => billOfQuantity.id !== id,
      );

      client.writeQuery<BillOfQuantityStructureQuery, BillOfQuantityStructureQueryVariables>({
        query: STRUCTURE_QUERY,
        data,
        variables: {
          projectNumber,
          entityType: billOfQuantity.entityType,
        },
      });
    },
    [projectNumber, client, billOfQuantity.entityType],
  );
  const removeBillOfQuantityFromCache = useCallback(async () => {
    if (!projectNumber) {
      throw new Error();
    }

    executeForPopulatedBoqTableTypes(
      client,
      projectNumber,
      (tableType) => {
        return client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
          query: REMOVE_DATA_TABLE_ROW,
          variables: {
            where: {
              id: swapTableType(tableType)(row.id),
              tableType,
            },
          },
        });
      },
      billOfQuantity.entityType,
    );
    await removeBillOfQuantityInStructureData(removeTableTypeFromId(row.data.id));
  }, [
    client,
    row.id,
    projectNumber,
    removeBillOfQuantityInStructureData,
    row.data.id,
    billOfQuantity.entityType,
  ]);
  const [deleteBillOfQuantity, { loading, error }] = useMutation(DELETE_BILL_OF_QUANTITY_MUTATION, {
    update: removeBillOfQuantityFromCache,
  });
  const handleBillOfQuantityDeletion = useCallback(async () => {
    if (!projectNumber) {
      throw new Error();
    }

    await deleteBillOfQuantity({ variables: { where: { id: billOfQuantity.id } } });
    await refetchBillBoqDropdown(client, projectNumber);
  }, [billOfQuantity.id, deleteBillOfQuantity, projectNumber, client]);

  const { requestConfirmation, confirmationDialog } = useConfirmationDialog({
    message: `Willst du das LV ${billOfQuantity.name} wirklich löschen?`,
    title: `${buttonPrefixText} löschen`,
    onConfirm: handleBillOfQuantityDeletion,
  });

  return (
    <>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      {confirmationDialog}
      <Tooltip title={`${buttonPrefixText} löschen`}>
        <span>
          <IconButton
            aria-label={`${buttonPrefixText} löschen`}
            onClick={requestConfirmation}
            disabled={loading || !billOfQuantity.canBeDeleted || !isAllowedToDelete}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
