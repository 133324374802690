import { toDate, toDay } from '../../../utils/durations';

/**
 * convert a day into an array of weekDays
 * @param dayStr in the form 2020-10-01
 * @returns [...days] of week in the form 2020-10-01
 */
// courtesy: https://stackoverflow.com/a/4156516
export const getWeek = (
  dayStr: string,
): [string, string, string, string, string, string, string] => {
  const date = toDate(dayStr, '12:00');
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  const week: string[] = [toDay(new Date(date.setDate(diff)))];
  for (let weekDay = 1; weekDay < 7; weekDay++) {
    week.push(toDay(new Date(date.setDate(date.getDate() + 1))));
  }
  return week as [string, string, string, string, string, string, string];
};
