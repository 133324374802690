import React, { useCallback, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useMutation } from 'react-apollo';
import { DELETE_ACCOUNTING_REPORT } from './DeleteAccountingReportAction.queries';
import {
  DeleteAccountingReport,
  DeleteAccountingReportVariables,
} from './types/DeleteAccountingReport';
import AppProgress from '../../../Page/AppProgress';
import { errorPrefixRemover } from '../../../../utils/errorPrefixRemover';
import AppErrorMessage from '../../../Page/AppErrorMessage';
import { getOperationName } from 'apollo-utilities';
import { GET_ACCOUNTING_LOG_JOURNAL } from '../../../../pages/AccountingLog/AccountingLogJournal/AccountingLogJournal.queries';
import { GET_ACCOUNTING_LOGS } from '../../../../pages/AccountingLog/AccountingLogOverview/AccountingLogOverview.queries';
import ConfirmationDialog from '../../../ConfirmationDialog';

const TOOLTIP_TITLE = 'Rapport löschen';

const CONFIRMATION_DIALOG_TITLE = 'Rapport löschen';
const CONFIRMATION_DIALOG_MESSAGE =
  'Bist du dir sicher, dass du den Rapport und alle zugehörigen Buchungen löschen willst?';

interface IProps {
  reportId: string;
}

export const DeleteAccountingReportAction: React.FC<IProps> = ({ reportId }) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const [deleteReport, { loading, error }] = useMutation<
    DeleteAccountingReport,
    DeleteAccountingReportVariables
  >(DELETE_ACCOUNTING_REPORT, {
    refetchQueries: [
      getOperationName(GET_ACCOUNTING_LOG_JOURNAL) ?? '',
      getOperationName(GET_ACCOUNTING_LOGS) ?? '',
    ],
  });

  const executeDeleteReport = useCallback(
    () => deleteReport({ variables: { where: { id: reportId } } }),
    [deleteReport, reportId],
  );

  const openConfirmationDialog = useCallback(() => {
    setIsConfirmationDialogOpen(true);
  }, []);

  const closeConfirmationDialog = useCallback(() => {
    setIsConfirmationDialogOpen(false);
  }, []);

  return (
    <>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={CONFIRMATION_DIALOG_TITLE}
        message={CONFIRMATION_DIALOG_MESSAGE}
        onClose={closeConfirmationDialog}
        onConfirm={executeDeleteReport}
      />
      <Tooltip title={TOOLTIP_TITLE}>
        <span>
          <IconButton
            aria-label={TOOLTIP_TITLE}
            disabled={loading}
            onClick={openConfirmationDialog}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
