import React, { useCallback, useMemo, useState } from 'react';
import { FastField as Field, useFormikContext } from 'formik';
import { TextFieldProps } from '@material-ui/core/TextField';
import { IconButton, Grid, InputAdornment, Theme, Tooltip } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import FormikTextField from '../Form/FormikTextField';
import { makeStyles } from '@material-ui/styles';
import MapDialog from './MapDialog';
import NavigateIcon from '@material-ui/icons/Navigation';
import SwapIcon from '@material-ui/icons/Autorenew';
import { getMapsUrl } from './utils/googleMaps';
import { isEmpty, pick } from 'lodash';
import { LV95toWGS } from 'swiss-projection';

export enum GpsFieldType {
  NODE = 'NODE',
  START = 'START',
  END = 'END',
}

export const SWISS_COORDS_REGEX = /(^$|[0-9]{7}( ?\/ ?)[0-9]{7})/g;
export const pickCoords = (v: any) =>
  pick(v, ['nodeCoordinate', 'startCoordinate', 'endCoordinate']);

type Coords = 'nodeCoordinate' | 'startCoordinate' | 'endCoordinate';

type CoordsRecord = Partial<Record<Coords, string>>;

export const convertSwissCoords = (values: CoordsRecord) =>
  Object.entries(values).reduce<CoordsRecord>((acc, [key, value]) => {
    if (isEmpty(value) || !value!.match(SWISS_COORDS_REGEX)) {
      return acc;
    }
    const parsedSwissCoords = value!.split(/ ?\/ ?/).map((int) => parseInt(int, 10));
    acc[key as Coords] = LV95toWGS(parsedSwissCoords).reverse().join(', ');
    return acc;
  }, {});

interface IProps {
  mobile?: boolean;
}

// Change SUFFIX nodeName, startName, endName and Type if API naming changes

const SUFFIX = 'Coordinate';

const NODE_NAME = `node${SUFFIX}`;
const START_NAME = `start${SUFFIX}`;
const END_NAME = `end${SUFFIX}`;

const useStyles = makeStyles((theme: Theme) => ({
  endAdornment: {
    marginBottom: theme.spacing(2),
  },
  controls: {
    textAlign: 'center',
  },
  navigate90deg: {
    transform: 'rotate(90deg)',
  },
}));

const getFieldName = (gpsFieldType: GpsFieldType) => {
  switch (gpsFieldType) {
    case GpsFieldType.NODE:
      return NODE_NAME;
    case GpsFieldType.START:
      return START_NAME;
    case GpsFieldType.END:
      return END_NAME;
  }
};

// Link to RegeEx playground with regex down below: https://regex101.com/r/BjY7Vq/3
const validate = (v: string) => {
  if (
    v &&
    !v.match(/(^$|[0-9]{1,3}\.[0-9]{1,}(,|, )[0-9]{1,3}\.[0-9]{1,})/g) &&
    !v.match(SWISS_COORDS_REGEX)
  ) {
    return 'Format muss dem Dezimalgrad (43.2453...,53.22...) oder dem Schweizer LV95 System (z.B. 2659459 / 1258858) entsprechen!';
  }
};

enum FieldsVisibility {
  ALL = 'ALL',
  NODE = 'NODE',
  START_END = 'START_END',
}

const calculateFieldsVisibility = (
  nodeValue: string,
  startValue: string,
  endValue: string,
): FieldsVisibility => {
  if (nodeValue) {
    return FieldsVisibility.NODE;
  }

  if (startValue || endValue) {
    return FieldsVisibility.START_END;
  }

  return FieldsVisibility.ALL;
};

export const GpsFields: React.FC<IProps> = ({ mobile }) => {
  const classes = useStyles();

  const [mapType, setMapType] = useState<GpsFieldType | null>(null);

  const { values, setFieldValue } = useFormikContext<any>();

  const nodeValue = values[NODE_NAME];
  const startValue = values[START_NAME];
  const endValue = values[END_NAME];

  const fieldsVisibility = useMemo(
    () => calculateFieldsVisibility(nodeValue, startValue, endValue),
    [nodeValue, startValue, endValue],
  );

  const onMapClick = useCallback((type: GpsFieldType) => setMapType(type), [setMapType]);

  const onSwapCoordinates = useCallback(() => {
    setFieldValue(getFieldName(GpsFieldType.END), startValue);
    setFieldValue(getFieldName(GpsFieldType.START), endValue);
  }, [setFieldValue, startValue, endValue]);

  const onNavigate = useCallback(() => {
    if (startValue && endValue) {
      window.open(
        getMapsUrl({ startCoordinate: startValue, endCoordinate: endValue }, mobile).toString(),
        '_blank',
      );
    }
  }, [startValue, endValue, mobile]);

  const onPlace = useCallback(() => {
    if (nodeValue) {
      window.open(getMapsUrl({ nodeCoordinate: nodeValue }, mobile).toString(), '_blank');
    }
  }, [nodeValue, mobile]);

  const getInputProps = useCallback(
    (type: GpsFieldType): TextFieldProps['InputProps'] => ({
      endAdornment: (
        <InputAdornment position="end" classes={{ positionEnd: classes.endAdornment }}>
          {mobile && (
            <IconButton onClick={() => onMapClick(type)}>
              <MapIcon fontSize="large" />
            </IconButton>
          )}
          {type === GpsFieldType.NODE && nodeValue && (
            <Tooltip title={!mobile ? 'Ort Anzeigen' : 'Zu Ort Navigieren'}>
              <IconButton
                aria-label="Anzeigen"
                color="inherit"
                onClick={onPlace}
                className={classes.navigate90deg}
              >
                <NavigateIcon color="action" fontSize={mobile ? 'large' : 'default'} />
              </IconButton>
            </Tooltip>
          )}
          {type === GpsFieldType.START && startValue && (
            <Tooltip title={!mobile ? 'Route Anzeigen' : 'Zu Route Navigieren'}>
              <IconButton
                disabled={!endValue}
                aria-label="Navigate"
                color="inherit"
                onClick={onNavigate}
                className={classes.navigate90deg}
              >
                <NavigateIcon color="action" fontSize={mobile ? 'large' : 'default'} />
              </IconButton>
            </Tooltip>
          )}
          {type === GpsFieldType.END && endValue && (
            <Tooltip title="Koordinaten Tauschen">
              <IconButton aria-label="Tauschen" color="inherit" onClick={onSwapCoordinates}>
                <SwapIcon color="action" fontSize={mobile ? 'large' : 'default'} />
              </IconButton>
            </Tooltip>
          )}
        </InputAdornment>
      ),
    }),
    [
      classes.endAdornment,
      classes.navigate90deg,
      endValue,
      mobile,
      nodeValue,
      onMapClick,
      onNavigate,
      onPlace,
      onSwapCoordinates,
      startValue,
    ],
  );

  const nodeInputProps = useMemo(() => getInputProps(GpsFieldType.NODE), [getInputProps]);
  const startInputProps = useMemo(() => getInputProps(GpsFieldType.START), [getInputProps]);
  const endInputProps = useMemo(() => getInputProps(GpsFieldType.END), [getInputProps]);

  const showNode =
    fieldsVisibility === FieldsVisibility.ALL || fieldsVisibility === FieldsVisibility.NODE;

  const showStartEnd =
    fieldsVisibility === FieldsVisibility.ALL || fieldsVisibility === FieldsVisibility.START_END;

  return (
    <>
      <Grid container direction="row" alignItems={startValue || endValue ? 'center' : 'flex-end'}>
        <Grid container direction="column">
          {showNode && (
            <Grid item>
              <Field
                name={NODE_NAME}
                label="Knoten-Koordinate"
                component={FormikTextField}
                fullWidth
                validate={validate}
                InputProps={mobile || nodeValue ? nodeInputProps : undefined}
              />
            </Grid>
          )}
          {showStartEnd && (
            <>
              <Grid item>
                <Field
                  name={START_NAME}
                  label="Start-Koordinate"
                  component={FormikTextField}
                  fullWidth
                  validate={validate}
                  InputProps={mobile || startValue || endValue ? startInputProps : undefined}
                />
              </Grid>
              <Grid item>
                <Field
                  name={END_NAME}
                  label="End-Koordinate"
                  component={FormikTextField}
                  fullWidth
                  validate={validate}
                  InputProps={mobile || startValue || endValue ? endInputProps : undefined}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {mobile && mapType && (
        <MapDialog
          open={Boolean(mapType)}
          onClose={() => setMapType(null)}
          onMapSubmit={(cordinate) => {
            setFieldValue(getFieldName(mapType), cordinate);
            setMapType(null);
          }}
          gbsFieldType={mapType}
          coordinate={values[getFieldName(mapType)]}
        />
      )}
    </>
  );
};
