import { get, set, has } from 'lodash';

/**
 * Sets specified form values to null when they are falsy. **To be used in conjunction with getChangedFormValues**
 * @param {object} values the values coming from the form
 * @param {string[]} fieldsToSetNull fields which should be set to null if falsy. **lodash dot notation**
 */
export const setFalseyValuesToNull = ({ ...values }: any, fieldsToSetNull: string[]) => {
  fieldsToSetNull.forEach((fieldName) => {
    const value = get(values, fieldName);

    // check if prop really exists in the object and is falsy, but allow 0
    if (has(values, fieldName) && (value === '' || value === undefined || value === null)) {
      set(values, fieldName, null);
    }
  });

  return values;
};
