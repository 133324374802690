import React, { useMemo } from 'react';
import { FastField as Field } from 'formik';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { useQuery, useMutation } from 'react-apollo';
import { IDataTableRow } from '../../DataTable/types';
import FormikTextField from '../../Form/FormikTextField';
import { IInvoiceConditionRowToEdit } from './InvoiceConditionsTable';
import {
  INVOICE_CONDITIONS_QUERY,
  CREATE_INVOICE_CONDITION_MUTATION,
  UPDATE_INVOICE_CONDITION_MUTATION,
  DELETE_INVOICE_CONDITION_MUTATION,
} from './InvoiceConditionsTable.queries';
import { InvoiceConditionsVariables, InvoiceConditions } from './types/InvoiceConditions';
import {
  CreateInvoiceCondition,
  CreateInvoiceConditionVariables,
} from './types/CreateInvoiceCondition';
import {
  UpdateInvoiceCondition,
  UpdateInvoiceConditionVariables,
} from './types/UpdateInvoiceCondition';
import { PureQueryOptions } from 'apollo-client';
import { createSwissCurrencyFormatter } from '../../../utils/createCurrencyFormatter';
import { flipSign } from '../../../utils/flipSign';

type RenderField = (
  rowToEdit: IInvoiceConditionRowToEdit | null,
) => (
  fieldName: string,
  type?: 'NUMBER' | 'PERCENTAGE',
) => (cell: any, row: IDataTableRow) => React.ReactElement<any>;

const NEW_ROW_ID = '__NEW__';
const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const appendNewRow = (): IDataTableRow => ({
  id: NEW_ROW_ID,
  data: {
    sequenceNumber: null,
    name: '',
    percentageAmount: null,
    absoluteAmount: null,
    references: null,
  },
});

const mapDataToInnerTableRows = (data: InvoiceConditions) =>
  data.invoiceConditions
    ?.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
    .map<IDataTableRow>(({ __typename, id, ...data }, index) => ({
      id,
      data: {
        ...data,
        referenceSum: index === 0 ? null : data.referenceSum,
        percentageAmount: index === 0 ? null : data.percentageAmount,
        discount: index === 0 ? null : data.discount,
        reference: index === 0 ? null : data.reference?.sequenceNumber,
        editable: index !== 0,
      },
    })) ?? [];

const createRenderField: RenderField = (rowToEdit) => (fieldName, type) => (cell, row) => {
  if (rowToEdit?.id === row.id) {
    const autoFocus = fieldName === 'sequenceNumber';

    return (
      <Field
        name={fieldName}
        autoComplete="off"
        autoFocus={autoFocus}
        component={FormikTextField}
      />
    );
  }

  if (type === 'NUMBER' && !isNil(cell)) {
    return formatCurrency(flipSign(cell));
  }

  if (type === 'PERCENTAGE' && !isNil(cell)) {
    return `${flipSign(cell)}%`;
  }

  return cell;
};

const createFindNextRow = (innerTableRows: IDataTableRow[]) => (rowId: string) => {
  const currentRowIndex = innerTableRows.findIndex(({ id }) => id === rowId);

  if (currentRowIndex === -1) {
    return null;
  }

  const nextRow = innerTableRows[currentRowIndex + 1];

  return nextRow ?? null;
};

const useFetches = () => {
  const { billId, id: boqId } = useParams();

  const {
    data,
    loading: queryLoading,
    error: queryError,
    variables,
  } = useQuery<InvoiceConditions, InvoiceConditionsVariables>(INVOICE_CONDITIONS_QUERY, {
    variables: {
      where: { bill: billId && { id: billId }, billOfQuantity: boqId && { id: boqId } },
    },
  });

  const refetchQueries = useMemo<PureQueryOptions[]>(() => {
    return [{ query: INVOICE_CONDITIONS_QUERY, variables }];
  }, [variables]);

  const [createInvoiceCondition, { loading: createLoading, error: createError }] = useMutation<
    CreateInvoiceCondition,
    CreateInvoiceConditionVariables
  >(CREATE_INVOICE_CONDITION_MUTATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const [updateInvoiceCondition, { loading: updateLoading, error: updateError }] = useMutation<
    UpdateInvoiceCondition,
    UpdateInvoiceConditionVariables
  >(UPDATE_INVOICE_CONDITION_MUTATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const [deleteInvoiceCondition, { loading: deleteLoading, error: deleteError }] = useMutation(
    DELETE_INVOICE_CONDITION_MUTATION,
    { refetchQueries, awaitRefetchQueries: true },
  );

  return useMemo(
    () => ({
      props: {
        data,
        variables,
        loading: queryLoading || createLoading || updateLoading || deleteLoading,
        error: queryError || createError || updateError || deleteError,
      },
      actions: {
        createInvoiceCondition,
        updateInvoiceCondition,
        deleteInvoiceCondition,
      },
    }),
    [
      data,
      queryLoading,
      createLoading,
      updateLoading,
      deleteLoading,
      queryError,
      createError,
      updateError,
      deleteError,
      createInvoiceCondition,
      updateInvoiceCondition,
      deleteInvoiceCondition,
      variables,
    ],
  );
};

export const INVOICE_CONDITION_UTILS = {
  appendNewRow,
  mapDataToInnerTableRows,
  createRenderField,
  useFetches,
  createFindNextRow,
  NEW_ROW_ID,
} as const;
