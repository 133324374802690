import React from 'react';
import { useApolloClient } from 'react-apollo';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useUnmount } from 'react-use';
import AddressTabs from '../../components/AddressTabs/AddressTabs';
import { RelationEntityType } from '../../components/AddressTabs/types';
import BillOfQuantityDetails from '../../components/BillOfQuantity/BillOfQuantityDetails';
import { CLEAR_DATA_TABLES } from '../../services/graphql-client';
import BillOfQuantity from '../BillOfQuantity';
import BillDetails from '../Bills/BillDetails';
import CreateBill from '../Bills/CreateBill';
import CreateMission from '../Missions/CreateMission';
import MissionDetails from '../Missions/MissionDetails';
import Offer from '../Offer';
import BillsTab from './TabBills';
import TabDetails from './TabDetails';
import TabMissionMeasure from './TabMissionMeasure';
import TabMissions from './TabMissions';

const Project: React.FunctionComponent<RouteComponentProps<{ projectNumber: string }>> = ({
  match,
}) => {
  const client = useApolloClient();

  useUnmount(() => {
    client.query({ query: CLEAR_DATA_TABLES });
  });

  return (
    <Switch>
      <Route path="/projekte/:projectNumber/details/:projectAction">
        <AddressTabs
          mainTabName="Projektdetail"
          mainComponent={TabDetails}
          type={RelationEntityType.PROJECT}
        />
      </Route>
      <Route exact path="/projekte/:projectNumber/auftraege/:id/details/:boqAction">
        <AddressTabs
          mainTabName="LV-Details"
          mainComponent={BillOfQuantityDetails}
          type={RelationEntityType.ORDER}
        />
      </Route>

      <Route exact path="/projekte/:projectNumber/offerten/:id/details/:boqAction">
        <AddressTabs
          mainTabName="LV-Details"
          mainComponent={BillOfQuantityDetails}
          type={RelationEntityType.OFFER}
        />
      </Route>

      <Route
        path="/projekte/:projectNumber/auftraege/:id/details"
        render={(props) => (
          <Redirect
            to={`/projekte/${props.match.params.projectNumber}/auftraege/${props.match.params.id}/details/editieren`}
          />
        )}
      />
      <Route path="/projekte/:projectNumber/auftraege">
        <BillOfQuantity />
      </Route>
      <Route path="/projekte/:projectNumber/offerten">
        <Offer />
      </Route>
      <Route path="/projekte/:projectNumber/einsaetze/erstellen" component={CreateMission} />
      <Route path="/projekte/:projectNumber/ausmass/:missionId/details/editieren">
        <MissionDetails isMeasurementForm={true} />
      </Route>
      <Route path="/projekte/:projectNumber/einsaetze/:missionId/details/:einsatzAction">
        <AddressTabs
          mainTabName="Einsatz-Detail"
          mainComponent={MissionDetails}
          type={RelationEntityType.MISSION}
        />
      </Route>
      <Route
        path="/projekte/:projectNumber/einsaetze/:missionId/details"
        render={(props) => (
          <Redirect
            to={`/projekte/${props.match.params.projectNumber}/einsaetze/${props.match.params.missionId}/details/editieren`}
          />
        )}
      />
      <Route path="/projekte/:projectNumber/einsaetze" component={TabMissions} />
      <Route path="/projekte/:projectNumber/ausmass" component={TabMissionMeasure} />
      <Route path="/projekte/:projectNumber/rechnungen/:billId/details/:billAction">
        <AddressTabs
          mainTabName="Rechnung-Detail"
          mainComponent={BillDetails}
          type={RelationEntityType.BILL}
        />
      </Route>
      <Route
        path="/projekte/:projectNumber/rechnungen/:billId/details"
        render={(props) => (
          <Redirect
            to={`/projekte/${props.match.params.projectNumber}/rechnungen/${props.match.params.billId}/details/editieren`}
          />
        )}
      />
      <Route path="/projekte/:projectNumber/rechnungen/erstellen" component={CreateBill} />
      <Route path="/projekte/:projectNumber/rechnungen" component={BillsTab} />
      <Redirect to={`/projekte/${match.params.projectNumber}/details/editieren`} />
    </Switch>
  );
};

export default Project;
