import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableHead,
  TableCell,
  Toolbar,
  IconButton,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Close } from '@material-ui/icons';
import { STATUS_REJECTED } from './BillOfQuantityStatus';
import { RejectedReason, NotReceived } from './RejectedStatusDialog';
import { orderBy } from 'lodash';
import { formatDate } from '../../../utils/format/date';

interface IBillOfQuantityHistoryProps {
  history: any[];
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  clickableRow: {
    cursor: 'pointer',
  },
  grow: {
    flexGrow: 1,
  },
});

const BillOfQuantityHistory: React.FunctionComponent<IBillOfQuantityHistoryProps> = ({
  history,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const [openRejectedInfo, setOpenRejectedInfo] = React.useState(false);
  const [currentStatus, setCurrentStatus] = React.useState({} as any);

  const handleOpenRejectedStatus = (status: any) => {
    setCurrentStatus(status);
    setOpenRejectedInfo(true);
  };
  const handleCloseRejectedStatus = () => {
    setOpenRejectedInfo(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <Toolbar>
          <DialogTitle>LV-Statushistorie</DialogTitle>
          <div className={classes.grow} />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Toolbar>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Am</TableCell>
                <TableCell>Von</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(history, 'createdAt', 'desc').map((status: any, idx: number) => {
                const isRejectedStatus = status.status === STATUS_REJECTED;
                return (
                  <TableRow
                    className={isRejectedStatus ? classes.clickableRow : ''}
                    hover={isRejectedStatus}
                    onClick={
                      isRejectedStatus ? handleOpenRejectedStatus.bind(null, status) : undefined
                    }
                    key={idx}
                  >
                    <TableCell>{status.status}</TableCell>
                    <TableCell>{formatDate(status.createdAt)}</TableCell>
                    <TableCell>{status.setBy.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
      <Dialog open={openRejectedInfo} onClose={handleCloseRejectedStatus}>
        <Toolbar>
          <DialogTitle>Abgelehnt-Begründung</DialogTitle>
          <div className={classes.grow} />
          <IconButton onClick={handleCloseRejectedStatus}>
            <Close />
          </IconButton>
        </Toolbar>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Kommentar</TableCell>
                <TableCell>Begründung</TableCell>
                <TableCell>Nicht erhalten</TableCell>
                <TableCell>Effektive Summe</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{currentStatus.comment}</TableCell>
                <TableCell>
                  {RejectedReason[currentStatus.reason as keyof typeof RejectedReason]}
                </TableCell>
                <TableCell>
                  {NotReceived[currentStatus.notReceived as keyof typeof NotReceived]}
                </TableCell>
                <TableCell>{currentStatus.effektivSum}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BillOfQuantityHistory;
