import React, { useCallback, useMemo } from 'react';
import Dinero from 'dinero.js';
import { IDataTableColumn, IDataTableRow } from '../../../../../components/DataTable/types';
import DataTable from '../../../../../components/DataTable';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import P1Icon from '@material-ui/icons/ExposurePlus1';
import { Grid } from '@material-ui/core';
import { FastField, Field, useFormikContext } from 'formik';
import { ITimeEntry } from '../../types';
import FormikTextField from '../../../../../components/Form/FormikTextField';
import { preventNonNumericInput } from '../../../../../utils/preventNonNumericInput';
import { createSwissCurrencyFormatter } from '../../../../../utils/createCurrencyFormatter';
import { Autocomplete } from '../../../../../components/Autocomplete';
import { getDurationFromRow, isSpilloverFromRow } from '../../../../../utils/durations';
import { fuzzyMatch } from '../../../utils/fuzzyMatch';
import { shouldUpdate } from '../../utils/shouldUpdate';
import { GetAccountingMissions_missions } from '../../types/GetAccountingMissions';
import {
  getSearchableMission,
  parseSearchableActivityType,
  searchableActivityType,
  searchableMachine,
  searchableMission,
  searchableMissionProject,
  searchableVehicle,
} from '../../../utils/searchable';
import { GetAccountingActivityTypes_activityTypes } from '../../types/GetAccountingActivityTypes';
import { cachedTabIndex, tabIndexDisabled } from '../../../utils/tabIndex';
import { ChargedTo, CreditedTo, testChargeConstraint } from '../../../utils/chargeConstraint';
import { GetAccountingVehicles_vehicles } from '../../types/GetAccountingVehicles';
import { GetAccountingMachines_machines } from '../../types/GetAccountingMachines';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

type TableRow = IDataTableRow<ITimeEntry & { hourlyWage: Dinero.Dinero; last: boolean }>;

const numTabbableFieldsPerRow = 6;

const tabIndexForRow = (row: TableRow, offset: number) =>
  cachedTabIndex(
    Number(row.id) * numTabbableFieldsPerRow +
      // tabindices start at "1"
      1 +
      offset,
  );

const chargedToDisabled = (disabled: boolean | undefined, searchableActivityType: string) => {
  let activityType;
  try {
    activityType = parseSearchableActivityType(searchableActivityType);
  } catch {
    return false;
  }
  const isUre = activityType?.number === 1009;
  return (
    disabled ||
    (activityType != null &&
      !isUre &&
      !testChargeConstraint(activityType, CreditedTo.EMPLOYEE, ChargedTo.MISSION))
  );
};

/**
 * create the columns for the data table
 * @param date the day date
 * @param prefix individual times or group times?
 * @param missionSuggestions suggestions for mission auto complete
 * @param vehicleSuggestions suggestions for vehicle auto complete
 * @param machineSuggestions suggestions for machine auto complete
 * @param activityTypeSuggestions suggestions for activity type auto complete
 * @param tabIndexOffset offset for the tab indexing
 * @param disabled is the table disabled
 */
const useColumns = (
  date: string,
  prefix: string,
  missionSuggestions: readonly string[],
  vehicleSuggestions: readonly string[],
  machineSuggestions: readonly string[],
  activityTypeSuggestions: readonly string[],
  tabIndexOffset: number,
  disabled?: boolean,
): Array<IDataTableColumn<TableRow>> => {
  const { setFieldValue } = useFormikContext();

  const validateMissionSuggestions = useCallback(
    (row: TableRow) => (value: string | undefined) => {
      if (
        ((row.data.last && value) || !row.data.last) &&
        (!value || !missionSuggestions.includes(value))
      ) {
        return 'Einsatz existiert nicht!';
      }
    },
    [missionSuggestions],
  );

  const ureSuggestions = useMemo(
    () => [...vehicleSuggestions, ...machineSuggestions, 'Sammelkonto'],
    [vehicleSuggestions, machineSuggestions],
  );
  const validateUreSuggestion = useCallback(
    (row: TableRow) => (value: string | undefined) => {
      if (
        ((row.data.last && value) || !row.data.last) &&
        (!value || !ureSuggestions.includes(value))
      ) {
        return 'Fahrzeug oder Maschine existiert nicht!';
      }
    },
    [ureSuggestions],
  );

  const validateActivityTypeSuggestion = useCallback(
    (row: TableRow) => (value: string | undefined) => {
      if (
        ((row.data.last && value) || !row.data.last) &&
        (!value || !activityTypeSuggestions.includes(value))
      ) {
        return 'Leistungsart existiert nicht!';
      }
    },
    [activityTypeSuggestions],
  );

  const offsetTabIndexForRow = useCallback(
    (row: TableRow, offset: number) => tabIndexForRow(row, offset + tabIndexOffset),
    [tabIndexOffset],
  );

  return useMemo(
    (): Array<IDataTableColumn<TableRow>> => [
      {
        id: 'activityType',
        label: 'Leistungsart',
        render: (_, row) => {
          return (
            <Field
              autoFocus={row.data.last && row.id !== '0'}
              last={row.data.activityType}
              disabled={disabled}
              name={`${prefix}[${row.id}].activityType`}
              type="text"
              component={Autocomplete}
              suggestions={activityTypeSuggestions}
              matcher={fuzzyMatch}
              minLengthToTrigger={0}
              selectOnFocus
              fillOnEnter
              fillOnBlur
              next={(value: string) => {
                if (chargedToDisabled(disabled, value)) {
                  return `input[name='${prefix}[${row.id}].timeFrom']`;
                }
                return `input[name='${prefix}[${row.id}].mission']`;
              }}
              // if we just pass the validate function it doesn't update correctly on change
              validate={(values: any) => validateActivityTypeSuggestion(row)(values)}
              inputProps={offsetTabIndexForRow(row, 0)}
              onSetValue={(value: string) => {
                if (chargedToDisabled(disabled, value)) {
                  setFieldValue(`${prefix}[${row.id}].mission`, '', false);
                  /* hacky but necessary: tabindex alone is not enough. changing the conditions for enabling the
                   * mission field only happens after the focus has already moved on already so it'll jump to the
                   * wrong field. this will jump to the correct field immediately letting the mission field render.
                   */
                  document
                    .querySelector<HTMLInputElement>(`input[name='${prefix}[${row.id}].timeFrom']`)
                    ?.focus();
                }
              }}
            />
          );
        },
      },
      {
        id: 'mission',
        label: 'Zulasten',
        render: (_, row) => {
          let activityType;
          try {
            activityType = parseSearchableActivityType(row.data.activityType);
          } catch {
            // ignore
          }
          const isUre = activityType?.number === 1009;
          const isDisabled = chargedToDisabled(disabled, row.data.activityType);
          const validate = isUre
            ? validateUreSuggestion(row)
            : isDisabled
            ? undefined
            : validateMissionSuggestions(row);
          return (
            <Field
              last={row.data.mission}
              disabled={isDisabled}
              name={`${prefix}[${row.id}].mission`}
              type="text"
              component={Autocomplete}
              suggestions={isUre ? ureSuggestions : missionSuggestions}
              maxSuggestions={75}
              matcher={fuzzyMatch}
              minLengthToTrigger={0}
              selectOnFocus
              fillOnEnter
              fillOnBlur
              placeholder={isDisabled ? 'Sammelkonto' : ''}
              next={`input[name='${prefix}[${row.id}].timeFrom']`}
              // if we just pass the validate function it doesn't update correctly on change
              validate={(values: any) => validate?.(values)}
              inputProps={isDisabled ? tabIndexDisabled : offsetTabIndexForRow(row, 1)}
            />
          );
        },
      },
      {
        id: 'project',
        label: 'Projekt',
      },
      {
        id: 'timeFrom',
        label: 'Zeit Start',
        render: (_, row) => (
          <FastField
            shouldUpdate={shouldUpdate}
            last={row.data.timeFrom}
            disabled={disabled}
            name={`${prefix}[${row.id}].timeFrom`}
            component={FormikTextField}
            type="time"
            onKeyPress={preventNonNumericInput}
            inputProps={offsetTabIndexForRow(row, 2)}
          />
        ),
      },
      {
        id: 'timeTo',
        label: 'Zeit Ende',
        renderPlain: true,
        render: (_, row) => {
          const spillOver = isSpilloverFromRow(date, row);
          return (
            <Grid container direction="row">
              <Grid item xs={spillOver ? 9 : 12}>
                <FastField
                  shouldUpdate={shouldUpdate}
                  last={row.data.timeTo}
                  disabled={disabled}
                  name={`${prefix}[${row.id}].timeTo`}
                  component={FormikTextField}
                  type="time"
                  onKeyPress={preventNonNumericInput}
                  inputProps={offsetTabIndexForRow(row, 3)}
                />
              </Grid>
              {spillOver ? (
                <Grid item xs={3}>
                  <sup>
                    <P1Icon color="error" fontSize="small" />
                  </sup>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          );
        },
      },
      {
        id: 'amount',
        label: 'Menge pro MA',
        render: (_, row) => {
          return <>{Math.round(getDurationFromRow(date, row) * 100) / 100} H</>;
        },
      },
      {
        id: 'sum',
        label: 'Summe',
        render: (_, row) => {
          return formatCurrency(
            row.data.hourlyWage.multiply(getDurationFromRow(date, row)).getAmount(),
          );
        },
      },
      {
        id: 'comment',
        label: 'Bemerkung',
        render: (_, row) => (
          <FastField
            shouldUpdate={shouldUpdate}
            last={row.data.comment}
            disabled={disabled}
            name={`${prefix}[${row.id}].comment`}
            component={FormikTextField}
            type="text"
            inputProps={offsetTabIndexForRow(row, 4)}
          />
        ),
      },
    ],
    [
      activityTypeSuggestions,
      date,
      disabled,
      missionSuggestions,
      prefix,
      validateActivityTypeSuggestion,
      validateMissionSuggestions,
      offsetTabIndexForRow,
      setFieldValue,
      ureSuggestions,
      validateUreSuggestion,
    ],
  );
};

/**
 * Map time entries to data table rows
 * @param timeEntries the time entries
 * @param hourlyWage the total hourly wage for this time entries
 * @param missions meta info for missions
 * @returns data table rows
 * @see IDataTableRow
 */
const mapToDataTable = (
  timeEntries: readonly ITimeEntry[],
  hourlyWage: Dinero.Dinero,
  missions: readonly GetAccountingMissions_missions[],
): TableRow[] => {
  return timeEntries.map((timeEntry, idx) => {
    const mission = getSearchableMission(timeEntry.mission, missions);
    const project = mission ? searchableMissionProject(mission) : '';
    return {
      id: idx.toString(),
      data: {
        ...timeEntry,
        hourlyWage,
        project,
        last: idx === timeEntries.length - 1,
      },
    };
  });
};

interface IProps {
  date: string;
  disabled?: boolean;
  missions: readonly GetAccountingMissions_missions[];
  vehicles: readonly GetAccountingVehicles_vehicles[];
  machines: readonly GetAccountingMachines_machines[];
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
  displayName?: string;
  tableName: string;
  timeEntries: ReadonlyArray<Readonly<ITimeEntry>>;
  hourlyWage: Dinero.Dinero;
  prefix: string;
  globalActions?: () => React.ReactElement;
  actions?: () => React.ReactElement;
  onAddRow: () => void;
  onClearEntryRow: () => void;
  onClearRow: (rowId: number) => void;
  tabIndexOffset?: number;
}

/**
 * Displays a work time form table
 * @constructor
 */
export const Times: React.FC<IProps> = React.memo(
  ({
    date,
    disabled,
    actions,
    missions,
    vehicles,
    machines,
    activityTypes,
    globalActions,
    displayName,
    tableName,
    timeEntries,
    hourlyWage,
    prefix,
    onAddRow,
    onClearEntryRow,
    onClearRow,
    tabIndexOffset = 0,
  }) => {
    const missionSuggestions = useMemo(() => missions.map(searchableMission), [missions]);
    const vehicleSuggestions = useMemo(() => vehicles.map(searchableVehicle), [vehicles]);
    const machineSuggestions = useMemo(() => machines.map(searchableMachine), [machines]);
    const activityTypeSuggestions = useMemo(
      () => activityTypes.map(searchableActivityType),
      [activityTypes],
    );

    const columns = useColumns(
      date,
      prefix,
      missionSuggestions,
      vehicleSuggestions,
      machineSuggestions,
      activityTypeSuggestions,
      tabIndexOffset,
      disabled,
    );

    const { errors } = useFormikContext();
    const hasInputError = Object.keys(errors ?? {}).length > 0;

    const options = useMemo(
      () => ({
        hideShowAdditionalColumnsBtn: true,
        globalActions,
        displayName,
        tableName,
        activeRowId: '',
        levels: [
          {
            columns,
            rowActions: ({ row }: { row: IDataTableRow }) => {
              const stateStr = disabled ? 'Fixiert' : 'Löschen';
              // simple check for fast field dates
              const isError =
                !/\d\d:\d\d/.test(row.data.timeFrom) ||
                !/\d\d:\d\d/.test(row.data.timeTo) ||
                hasInputError;
              const isFilled =
                row.data.activityType &&
                (chargedToDisabled(disabled, row.data.activityType) || row.data.mission);
              const errorIcon = hasInputError || isError || !isFilled;
              const addDisabled = disabled || isError || !isFilled;
              return (
                <>
                  {Number(row.id) < timeEntries.length - 1 || disabled ? (
                    <Tooltip title={'Eintrag ' + stateStr}>
                      <div>
                        <IconButton
                          aria-label={stateStr}
                          disabled={disabled}
                          onClick={() => onClearRow(Number(row.id))}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  ) : (
                    <>
                      <Grid container direction="row">
                        <Tooltip title="Eintrag hinzufügen">
                          <div>
                            <IconButton
                              aria-label="hinzufügen"
                              disabled={addDisabled}
                              onClick={addDisabled ? undefined : onAddRow}
                              onFocus={addDisabled ? undefined : onAddRow}
                              {...tabIndexForRow(row, tabIndexOffset + 5)}
                            >
                              <CheckIcon
                                color={errorIcon ? 'error' : undefined}
                                htmlColor={errorIcon ? undefined : 'green'}
                              />
                            </IconButton>
                          </div>
                        </Tooltip>
                        <Tooltip title="Eintrag abbrechen">
                          <div>
                            <IconButton
                              aria-label="abbrechen"
                              disabled={disabled}
                              onClick={onClearEntryRow}
                            >
                              <ClearIcon color="error" />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </Grid>
                    </>
                  )}
                </>
              );
            },
            actions,
          },
        ],
      }),
      [
        actions,
        columns,
        disabled,
        displayName,
        globalActions,
        onAddRow,
        onClearEntryRow,
        tableName,
        timeEntries.length,
        onClearRow,
        tabIndexOffset,
        hasInputError,
      ],
    );

    const innerTableRows = useMemo(
      () => mapToDataTable(disabled ? timeEntries.slice(0, -1) : timeEntries, hourlyWage, missions),
      [disabled, timeEntries, hourlyWage, missions],
    );

    return <DataTable innerTableRows={innerTableRows} options={options} />;
  },
);
