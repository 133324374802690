import { IDataTableRow } from '../../components/DataTable/types';
import { MissionStatusType } from '../../types/graphql';
import { GetMissions } from './types/GetMissions';

export const mapToMissionTableData = (data: GetMissions) => {
  const missions: IDataTableRow[] =
    data.missions
      ?.map(({ project, ...mission }) => {
        const isFixedAppointment =
          mission.status && mission.status.missionStatus! === MissionStatusType.FT;

        return {
          id: mission.id.toString(),
          data: {
            ...mission,
            'project.colorCodings': project.colorCodings,
            'project.projectNumber': project.projectNumber,
            'project.projectName': project.projectName,
            'project.status': project.status,
            'project.subsidiary.name': project.subsidiary.name,
            'project.createdAt': project.createdAt,
            'project.publisher.name': project.publisher.name,
            'project.personResponsible.name': project.personResponsible?.name,
            // TODO
            // 'project.projectAddress.street': project.projectAddress?.street,
            // 'project.projectAddress.mailbox': project.projectAddress?.mailbox,
            // 'project.projectAddress.postCode': project.projectAddress?.postCode,
            // 'project.projectAddress.city': project.projectAddress?.city,
            // 'project.projectAddress.country': project.projectAddress?.country,
            // 'project.colorCodings.shippingAddress.street': project.shippingAddress?.street,
            // 'project.colorCodings.shippingAddress.mailbox': project.shippingAddress?.mailbox,
            // 'project.colorCodings.shippingAddress.postCode': project.shippingAddress?.postCode,
            // 'project.colorCodings.shippingAddress.city': project.shippingAddress?.city,
            // 'project.colorCodings.shippingAddress.country': project.shippingAddress?.country,
            'project.catalog.useName': project.catalog?.useName,
            'project.staffTariff': project.staffTariff,
            'project.isSpecialProject': project.isSpecialProject,
            'project.commentToBranchLeader': project.commentToBranchLeader,
            'project.commentToBackoffice': project.commentToBackoffice,
            'project.commentToExecutives': project.commentToExecutives,
            // 'projectAddress.street': mission.projectAddress?.street,
            // 'projectAddress.postCode': mission.projectAddress?.postCode,
            // 'projectAddress.city': mission.projectAddress?.city,
            // 'projectAddress.country': mission.projectAddress?.country,
            'status.missionStatus': mission.status?.missionStatus,
            'status.fixedAppointment': isFixedAppointment ? mission.status?.date : '',
            'status.calendarWeek': mission.status?.calendarWeek,
            'status.fromDate': mission.status?.date,
            'status.untilDate': mission.status?.untilDate,
            'publisher.name': mission.publisher.name,
            id: mission.id.toString(),
          },
        };
      })
      .flat(Infinity) ?? [];

  return missions;
};
