import gql from 'graphql-tag';

const mobileItemFragment = gql`
  fragment mobileItemFragment on Item {
    id
    productNumber
    descriptionOne
    unit
    material
    volume
    canBeDeleted
  }
`;

export const GET_LOCATIONS = gql`
  query GetLocations($id: ID!, $search: SearchInput) {
    billOfQuantity(where: { id: $id }) {
      id
      name
      defaultLocation {
        id
        items(search: $search, where: { isFinished: true }) {
          ...mobileItemFragment
        }
        locations {
          id
          name
          canBeDeleted
          items(search: $search, where: { isFinished: true }) {
            ...mobileItemFragment
          }
          locations {
            id
            name
            canBeDeleted
            items(search: $search, where: { isFinished: true }) {
              ...mobileItemFragment
            }
          }
        }
      }
    }
  }
  ${mobileItemFragment}
`;
