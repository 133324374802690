const emptyCache: readonly any[] = [];
/**
 * @returns a object identity preserving empty array
 */
export const empty = <T>(): readonly T[] => emptyCache;

/**
 * @returns the value or an object identity preserving empty array
 * @param value the optional value
 * @see empty
 */
export const orEmpty = <T>(value: readonly T[] | undefined) => value ?? empty<T>();
