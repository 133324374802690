import React, { useMemo } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FormikCheckBoxWithLabel from '../../Form/FormikCheckboxWithLabel';
import { Field } from 'formik';
import FormikTextField from '../../Form/FormikTextField';
import classNames from 'classnames';

const useStyles = makeStyles({
  gridItem: {
    padding: '1em',
  },
  gridItemDisabled: {
    opacity: 0.8,
  },
});

interface IGridItemProps {
  name: string;
  activeKey: string;
  textKey: string;
  disabled: boolean;
}

/**
 * A grid item consiting of a labeled check box acting as header and a text field
 * @param name name of this grid item, i.e. checkbox label
 * @param activeKey values form key for the active prop
 * @param textKey values form key for the text prop
 * @param disabled is the item currently disabled?
 */
export const GridItem: React.FC<IGridItemProps> = React.memo(
  ({ name, activeKey, textKey, disabled }) => {
    const label = useMemo(() => ({ label: name }), [name]);
    const classes = useStyles();
    return (
      <Paper
        className={classNames(classes.gridItem, { [classes.gridItemDisabled]: disabled })}
        elevation={disabled ? 1 : 3}
      >
        <Field name={activeKey} Label={label} component={FormikCheckBoxWithLabel} />
        <Field
          name={textKey}
          label="Text"
          disabled={disabled}
          component={FormikTextField}
          multiline
          rows={4}
        />
      </Paper>
    );
  },
);
