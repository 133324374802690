import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DialogProps } from '@material-ui/core/Dialog';
import { Formik, Field, Form } from 'formik';
import FormikTextField from '../../components/Form/FormikTextField';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: 800,
  },
});

type IProps = DialogProps & {
  onCreate: (...args: any) => void;
  onClose: (...args: any) => void;
};

const CreateItemBoilerplateGroupDialog: React.FunctionComponent<IProps> = ({
  onCreate,
  onClose,
  ...dialogProps
}) => {
  const classes = useStyles();
  return (
    <Dialog {...dialogProps} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle>Vorlagen-Gruppe Erstellen</DialogTitle>
      <DialogContent>
        <Formik initialValues={{ name: '' }} onSubmit={onCreate}>
          <Form>
            <Field
              name="name"
              label="Name"
              component={FormikTextField}
              required
              autoFocus
              fullWidth
            />
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Abbrechen
              </Button>
              <Button type="submit" color="primary">
                Erstellen
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CreateItemBoilerplateGroupDialog;
