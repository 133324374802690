import gql from 'graphql-tag';

const billOfQuantityOfferConditionFields = gql`
  fragment billOfQuantityOfferConditionFields on BillOfQuantityOfferCondition {
    billOfQuantityId
    offerConditionId
    name
    default
    overrideText
    text
    active
  }
`;

/**
 * Query to get all offer conditions of a bill of quantities.
 * This includes all global offer conditions that are set to `default` and are not actively set to `active: false`
 * in the assignment.
 */
export const GET_BOQ_OFFER_CONDITIONS_QUERY = gql`
  query GetBillOfQuantityOfferConditions($where: BillOfQuantityWhereInput!) {
    billOfQuantity(where: $where) {
      id
      offerConditions {
        ...billOfQuantityOfferConditionFields
      }
    }
  }
  ${billOfQuantityOfferConditionFields}
`;

/**
 * Assign an offer condition to a bill of quantity using their ids
 */
export const ASSIGN_BOQ_OFFER_CONDITION_MUTATION = gql`
  mutation AssignBillOfQuantityOfferCondition(
    $where: BillOfQuantityOfferConditionWhereUniqueInput!
    $data: BillOfQuantityOfferConditionAssignInput!
  ) {
    assignBillOfQuantityOfferCondition(where: $where, data: $data) {
      id
    }
  }
`;

/**
 * Dismiss a offer condition assignment, this will delete the actual assigned object
 * Most of the time you probably want to assign it with `active: false` instead
 */
export const DISMISS_BOQ_OFFER_CONDITION_MUTATION = gql`
  mutation DismissBillOfQuantityOfferCondition(
    $where: BillOfQuantityOfferConditionWhereUniqueInput!
  ) {
    dismissBillOfQuantityOfferCondition(where: $where) {
      id
    }
  }
`;
