import gql from 'graphql-tag';
import { historyFieldsFragment } from '../../components/History/history.queries';
import { PureQueryOptions } from 'apollo-client';

const vehicleMainFieldsFragment = gql`
  fragment vehicleFields on Vehicle {
    id
    inventoryNumber
    projectNumber
    category
    subsidiary {
      id
      name
    }
    driver
    nameOne
    nameTwo
    releaseDate
    weight
    payload
    registrationNumber
    kilometers
    internalHourlyWage
    emissionsTestDate
    trafficOfficeDate
    note
    isDeactivated
    trailerLoad
    fuelCardOne
    fuelCardTwo
    pinOne
    pinTwo
    tcsAsof
    eVignette
    serialNumber
    chassisNumber
    lastInternalCheckAt
  }
`;

export const GET_VEHICLE = gql`
  query GetVehicle($where: VehicleWhereUniqueInput!) {
    vehicle(where: $where) {
      ...vehicleFields
      subsidiaryHistory {
        ...historyFields
      }
      kilometersHistory {
        ...historyFields
      }
      driverHistory {
        ...historyFields
      }
      documents {
        id
        mimetype
        name
        nameHash
        path
      }
    }
  }
  ${historyFieldsFragment}
  ${vehicleMainFieldsFragment}
`;

export const GET_VEHICLES = gql`
  query GetVehicles(
    $where: VehicleWhereInput
    $search: SearchInput
    $page: PageInput
    $orderBy: OrderByInput
  ) {
    vehicles(where: $where, search: $search, page: $page, orderBy: $orderBy) {
      ...vehicleFields
      kilometersHistory(last: 1) {
        id
        createdBy {
          id
          name
        }
        createdAt
      }
    }
  }
  ${vehicleMainFieldsFragment}
`;

export const CREATE_VEHICLES_MUTATION = gql`
  mutation CreateVehicle($data: VehicleCreateInput!) {
    createVehicle(data: $data) {
      id
    }
  }
`;

export const UPDATE_VEHICLES_MUTATION = gql`
  mutation UpdateVehicle($where: VehicleWhereUniqueInput!, $data: VehicleUpdateInput!) {
    updateVehicle(data: $data, where: $where) {
      id
    }
  }
`;

export const vehiclesRefetchQueries: PureQueryOptions[] = [
  {
    query: GET_VEHICLES,
    variables: {
      where: {
        isDeactivated: false,
      },
    },
  },
  {
    query: GET_VEHICLES,
    variables: {
      where: {
        isDeactivated: undefined,
      },
    },
  },
];

export const VEHICLE_COMPUTED_FIELDS = ['subsidiary.name'];
