import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import OfferConditionIcon from '../../components/OfferCondition/OfferConditionIcon';
import DomainIcon from '@material-ui/icons/Domain';
import UserIcon from '@material-ui/icons/People';
import { tabIconViewBox, useTabStyles } from '../../utils/tabStyles';

const SUB_PATH_REGEX = /(\/benutzer|\/filialen|\/angebotsbedingungen)\/?.*/;

const StandingDataTabs: React.FC<RouteComponentProps> = ({ history, location }) => {
  const classes = useTabStyles();

  const navigate = useCallback(
    (e: React.ChangeEvent<any>, path: string) => history.push(path),
    [history],
  );
  const tabValue = SUB_PATH_REGEX.exec(location.pathname)![1];

  return (
    <Tabs
      value={tabValue}
      onChange={navigate}
      variant="scrollable"
      scrollButtons="auto"
      classes={{ scrollButtons: classes.scrollButtons }}
    >
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <UserIcon classes={{ root: classes.rootIcon }} viewBox={tabIconViewBox} />
            Benutzer
          </>
        }
        value={`/benutzer`}
      />
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <DomainIcon classes={{ root: classes.rootIcon }} viewBox={tabIconViewBox} />
            Filialen
          </>
        }
        value={`/filialen`}
      />
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <OfferConditionIcon classes={{ root: classes.rootIcon }} viewBox={tabIconViewBox} />
            Angebotsbedingungen
          </>
        }
        value={`/angebotsbedingungen`}
      />
    </Tabs>
  );
};

export default StandingDataTabs;
