import ApolloClient from 'apollo-client';
import { Maybe } from 'graphql/jsutils/Maybe';
import { buildMissionLocationId } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers';
import { executeForPopulatedMissionTableTypes } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/executeForMissionTypes';
import { buildTableId } from '../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { REMOVE_DATA_TABLE_ROW } from '../../../../services/graphql-client';
import {
  RemoveDataTableRow,
  RemoveDataTableRowVariables,
} from '../../../../services/types/RemoveDataTableRow';
import { TableType } from '../../../../types/graphql';
import { flattenContainerRows } from '../../../../utils/flattenRows/flattenRows';
import { PAGINATION_HELPERS } from '../../../../utils/paginationHelpers';
import { forceDataTableRefetch } from '../../../../utils/paginationHelpers/forceDataTableRefetch';
import { executeForPopulatedBoqTableTypes } from '../../../BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { IDataTableRow } from '../../../DataTable/types';

const refetchBillMissionTable = async (projectNumber: string, row: IDataTableRow) => {
  const missionIds: number[] = row.data.missions.map((mission: any) => mission.id);
  const billLocationRows = flattenContainerRows(row.containerRows ?? []);
  const missionLocationRowIds = missionIds.flatMap((missionId) =>
    billLocationRows.map(({ data: { itemLocation } }) =>
      buildMissionLocationId(missionId, itemLocation),
    ),
  );

  await PAGINATION_HELPERS[projectNumber].BILL_MISSION.refetchItemsInContainer([
    ...missionIds.map((missionId) => ({
      containerId: buildTableId(TableType.BILL_MISSION)(missionId.toString()),
    })),
    ...missionLocationRowIds.map((missionLocationRowId) => ({
      containerId: buildTableId(TableType.BILL_MISSION)(missionLocationRowId),
    })),
  ]);
};

/**
 * forces the MISSION, MEASUREMENT, and billOfQuantity dataTables to refetch
 *
 * is fine because the dataTables we force to refetch are not immediately visible
 * to the user (are in another tab)
 */
const forceDependantDataTablesToRefetch = async (
  client: ApolloClient<any>,
  projectNumber: string,
) => {
  executeForPopulatedBoqTableTypes(client, projectNumber, (tableType) => {
    forceDataTableRefetch(tableType, client);
  });
  await executeForPopulatedMissionTableTypes(client, projectNumber, async (tableType) => {
    if (tableType === TableType.BILL_MISSION) {
      return;
    }

    forceDataTableRefetch(tableType, client);
  });
};

/**
 * handles the refetches to be done after a bill is deleted
 */
export const createDeleteBillFromCache =
  (client: ApolloClient<any>, row: IDataTableRow, projectNumber: Maybe<string>) => async () => {
    if (!projectNumber) {
      throw new Error();
    }

    await refetchBillMissionTable(projectNumber, row);
    await forceDependantDataTablesToRefetch(client, projectNumber);

    await client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
      query: REMOVE_DATA_TABLE_ROW,
      variables: {
        where: {
          id: row.id,
          tableType: TableType.BILL,
        },
      },
    });
  };
