import React, { Component, useEffect } from 'react';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DragIndicator from '@material-ui/icons/DragIndicator';
import Checkbox from '@material-ui/core/Checkbox';
import RootRef from '@material-ui/core/RootRef';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { IDataTableRow } from '../types';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { Draggable } from 'react-beautiful-dnd';
import Cell from './Cell';
import LockDimensionsOnDrag from '../../LockDimensionsOnDrag';
import ActionsCell from './ActionsCell';
import LevelCells from './LevelCells';
import { stopEventPropagation } from '../../../utils/stopEventPropagation';
import { ActionType, ITableContext } from '..';
import { ISearchProps } from '../../Search/Search';
import { isContainerChecked } from '../utils/container/selectContainerUtils';

const useStyles = makeStyles((theme: Theme) => ({
  dragIndicator: {
    color: theme.palette.action.active, // see #7609
  },
  clickableRow: {
    cursor: 'pointer',
  },
  activeRow: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  activeRowInPath: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.95),
  },
  activeRowCell: {
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
  },
  activeRowLastColumnNoActions: {
    borderWidth: 1,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderRightStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
}));

interface IProps {
  row: IDataTableRow;
  innerLevel: number;
  maxInnerTableLevel: number;
  context: ITableContext;
  parentRow?: IDataTableRow;
}

const Row: React.FunctionComponent<IProps> = React.memo(
  ({ row, innerLevel, maxInnerTableLevel, context, parentRow }) => {
    const { tableData, closedRowIds, checkedRowIds, activeRowId, dispatch } = context;
    const classes = useStyles();

    const options = tableData.innerTableOptions;
    const rowNavigation = tableData.navigation[row.id];
    const isOpen = closedRowIds.indexOf(row.id) === -1;

    useEffect(() => {
      const isParentContainerChecked =
        parentRow && isContainerChecked(context.state.selectedContainerIds, parentRow.id);

      if (isParentContainerChecked) {
        dispatch({
          type: ActionType.TOGGLE_CHECKBOXES,
          payload: { toggleEntries: row.id },
        });
      }
      // the dependencies array should stay empty to prevent infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const childrenRows =
      rowNavigation.children.length > 0
        ? row.innerTableRows!.map((childRow, index) => (
            <Row
              key={index}
              row={childRow}
              maxInnerTableLevel={maxInnerTableLevel}
              innerLevel={innerLevel + 1}
              context={context}
            />
          ))
        : null;

    const isChecked = checkedRowIds.indexOf(row.id) > -1;

    const isActiveRow = activeRowId === row.id;

    const isActiveRowInPath =
      activeRowId && tableData.navigation[activeRowId]
        ? tableData.navigation[activeRowId].path.indexOf(row.id) > -1
        : false;
    const rowClassName = classNames({
      [classes.clickableRow]: options.onRowClick || options.onDoubleRowClick,
      [classes.activeRow]: isActiveRow,
      [classes.activeRowInPath]: isActiveRowInPath,
    });

    const actionsCell = options.rowActions && (
      <ActionsCell options={options} row={row} isActiveRow={isActiveRow} />
    );
    const cellClassName = isActiveRow ? classes.activeRowCell : undefined;
    const levelCells = (
      <LevelCells
        rowNavigation={rowNavigation}
        isOpen={isOpen}
        isActive={isActiveRow || isActiveRowInPath}
        innerLevel={innerLevel}
        maxInnerTableLevel={maxInnerTableLevel}
        context={context}
      />
    );

    const maxInnerLevel = Math.max(
      ...Object.values(tableData.navigation).map(({ path }) => path.length),
    );
    /* 14px are for indentation per level */
    const paddingLeftFirstCell = 10 + 14 * (maxInnerLevel - rowNavigation.path.length);

    const checkboxCell = options.hasCheckboxes && (
      <TableCell
        padding="none"
        className={cellClassName}
        onClick={stopEventPropagation}
        style={{ paddingLeft: paddingLeftFirstCell }}
      >
        <Checkbox
          checked={isChecked}
          color="primary"
          onChange={(e, checked) => {
            if (options.onCheckboxChange) {
              options.onCheckboxChange(row, checked, e);
            }

            dispatch({ type: ActionType.TOGGLE_CHECKBOXES, payload: { toggleEntries: row.id } });
          }}
        />
      </TableCell>
    );

    const handleRowClick = (e: any) => {
      if (options.onRowClick) {
        options.onRowClick(row, e, dispatch);
      }
    };
    const handleDoubleRowClick = (e: any) => {
      if (options.onDoubleRowClick) {
        options.onDoubleRowClick(row, e);
      }
    };

    return options!.isDragAndDropEnabled ? (
      <>
        <Draggable draggableId={row.id} index={rowNavigation.index}>
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <TableRow
                onClick={handleRowClick}
                onDoubleClick={handleDoubleRowClick}
                aria-checked={isChecked}
                tabIndex={-1}
                selected={isChecked}
                className={rowClassName}
                style={snapshot.isDragging ? { display: 'table' } : {}}
                ref={isActiveRow ? context.activeRowRef : undefined}
                {...provided.draggableProps}
              >
                <LockDimensionsOnDrag draggableId={row.id}>{levelCells}</LockDimensionsOnDrag>

                <LockDimensionsOnDrag draggableId={row.id}>
                  <TableCell padding="none" className={cellClassName}>
                    <span {...provided.dragHandleProps}>
                      <DragIndicator className={classes.dragIndicator} />
                    </span>
                  </TableCell>
                </LockDimensionsOnDrag>

                {options.hasCheckboxes && (
                  <LockDimensionsOnDrag draggableId={row.id}>
                    {checkboxCell as JSX.Element}
                  </LockDimensionsOnDrag>
                )}

                {options.columns.map((column) => (
                  <LockDimensionsOnDrag key={column.id} draggableId={row.id}>
                    <Cell
                      row={row}
                      column={column}
                      options={options}
                      searchWords={tableData.searchWords}
                      isActiveRow={isActiveRow}
                      style={tableData.options.breakWord ? { wordBreak: 'break-word' } : {}}
                    />
                  </LockDimensionsOnDrag>
                ))}
                <TableCell />
                {actionsCell}
              </TableRow>
            </RootRef>
          )}
        </Draggable>
        {childrenRows}
      </>
    ) : (
      <>
        <TableRow
          aria-checked={isChecked}
          tabIndex={-1}
          selected={isChecked}
          className={rowClassName}
          onClick={handleRowClick}
          onDoubleClick={handleDoubleRowClick}
          ref={isActiveRow ? context.activeRowRef : undefined}
        >
          {levelCells}
          {checkboxCell}
          {options.columns.map((column, index, { length }) => {
            const style: React.CSSProperties =
              index === 0 && !options.hasCheckboxes ? { paddingLeft: paddingLeftFirstCell } : {};
            if (tableData.options.breakWord) {
              style.wordBreak = 'break-word';
            }
            const isLastCellNotActions = isActiveRow && !options.rowActions && index === length - 1;
            const columns =
              (tableData?.search as Component<ISearchProps> | undefined)?.props
                .columnsToHighlight ??
              (tableData?.search as Component<ISearchProps> | undefined)?.props.columns;
            return (
              <Cell
                style={style}
                key={column.id}
                row={row}
                column={column}
                options={options}
                searchWords={tableData.searchWords}
                isActiveRow={isActiveRow}
                className={isLastCellNotActions ? classes.activeRowLastColumnNoActions : undefined}
                searchable={columns?.indexOf(column.id) !== -1}
              />
            );
          })}
          {actionsCell}
        </TableRow>
        {childrenRows}
      </>
    );
  },
);

export default Row;
