import React from 'react';
import { Mutation, MutationComponentOptions, MutationFunction, MutationResult } from 'react-apollo';
import AppProgress from '../Page/AppProgress';
import AppErrorMessage from '../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';

interface IWrappedMutationProps<TData, TVariables>
  extends MutationComponentOptions<TData, TVariables> {
  placeholder?: string;
}

function WrappedMutation<TData = any, TVariables = Record<string, any>>({
  children,
  placeholder,
  ...rest
}: IWrappedMutationProps<TData, TVariables>) {
  return (
    <Mutation<TData, TVariables> {...(rest as any)}>
      {(mutateFun: MutationFunction<TData, TVariables>, result: MutationResult<TData>) => {
        const { loading, error } = result;

        if (loading) {
          return placeholder || <AppProgress />;
        }

        if (error) {
          console.error(error);
          return (
            <>
              {children(mutateFun, result)}
              <AppErrorMessage message={errorPrefixRemover(error.message)} />
            </>
          );
        }

        return children(mutateFun, result);
      }}
    </Mutation>
  );
}

export default WrappedMutation;
