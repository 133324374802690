import gql from 'graphql-tag';

const PROJECT_FIELDS_FRAGMENT = gql`
  fragment ProjectFields on Project {
    id
    projectName
    parentId
    projectNumber
    projectName
    colorCodings
    isSpecialProject
    hasSeparateFileStorage
    contactPerson
    createdAt
    hasFiles
    status
    contactPerson
    sum
    offerSum
    canBeDeleted
    # TODO
    # projectAddress {
    #   id
    #   street
    #   number
    #   mailbox
    #   postCode
    #   country
    # }
    # shippingAddress {
    #   id
    #   street
    #   number
    #   mailbox
    #   postCode
    #   city
    #   country
    # }
    catalog {
      id
      useName
    }
    staffTariff
    isSpecialProject
    commentToBranchLeader
    commentToBackoffice
    commentToExecutives
    personResponsible {
      id
      name
    }
    publisher {
      id
      name
    }
    subsidiary {
      id
      name
    }
  }
`;

export const buildProjectsQuery = (isSearch?: boolean) => {
  return isSearch ? SEARCH_PROJECTS_QUERY : PROJECTS_QUERY;
};

const SEARCH_PROJECTS_QUERY = gql`
  query SearchProjects(
    $subsidiaryId: ID
    $archived: Boolean
    $search: SearchInput
    $isRootProject: Boolean
    $page: PageInput
    $orderBy: OrderByInput
  ) {
    projects(
      where: {
        subsidiary: { id: $subsidiaryId }
        archived: $archived
        isRootProject: $isRootProject
      }
      orderBy: $orderBy
      search: $search
      page: $page
    ) {
      ...ProjectFields
      allParents {
        ...ProjectFields
      }
    }
  }
  ${PROJECT_FIELDS_FRAGMENT}
`;

export const PROJECTS_QUERY = gql`
  query Projects(
    $subsidiaryId: ID
    $archived: Boolean
    $search: SearchInput
    $isRootProject: Boolean
    $page: PageInput
    $orderBy: OrderByInput
  ) {
    projects(
      where: {
        subsidiary: { id: $subsidiaryId }
        archived: $archived
        isRootProject: $isRootProject
      }
      orderBy: $orderBy
      search: $search
      page: $page
    ) {
      ...ProjectFields
      allChildren {
        ...ProjectFields
      }
    }
  }
  ${PROJECT_FIELDS_FRAGMENT}
`;
