import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MobileProjects } from './pages/Projects';
import { Header } from './components/Header';
import { MobileProjectDetails } from './pages/ProjectDetails';
import { BillOfQuantities } from './pages/BillOfQuantities';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import CreateLocationPage from './pages/CreateLocationPage';
import { BillOfQuantityOverview } from './pages/BillOfQuantityOverview';
import { AddItemsFromItemboilerplates } from './pages/AddItems';
import { ItemDetails } from './components/Item/ItemDetails';
import { MobileLocationDetails } from './pages/MobileLocationDetails';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    margin: theme.spacing(0, 3.25, 3, 3.25),
  },
}));

export const Mobile: React.FC = () => {
  const { path } = useRouteMatch();

  const classes = useStyles();

  return (
    <>
      <div>
        <Header />
      </div>
      <main className={classes.main}>
        <Switch>
          <Route path={`${path}/`} exact component={MobileProjects} />
          <Route path={`${path}/projekte/:projectNumber`} exact component={MobileProjectDetails} />
          <Route
            path={`${path}/projekte/:projectNumber/leistungsverzeichnis`}
            exact
            component={BillOfQuantities}
          />
          <Route
            exact
            path={`${path}/örtlichkeiten/:locationId/örtlichkeiten/erstellen`}
            component={CreateLocationPage}
          />
          <Route
            path={`${path}/projekte/:projectNumber/leistungsverzeichnis/:boqId`}
            exact
            component={BillOfQuantityOverview}
          />
          <Route
            path={`${path}/örtlichkeiten/:locationId/positionen/erstellen`}
            exact
            component={AddItemsFromItemboilerplates}
          />
          <Route
            path={`${path}/positionen/:itemId/details/editieren`}
            exact
            component={ItemDetails}
          />
          <Route
            path={`${path}/projekte/:projectNumber/leistungsverzeichnis/:boqId/örtlichkeiten/:locationId/editieren`}
            exact
            component={MobileLocationDetails}
          />
        </Switch>
      </main>
    </>
  );
};
