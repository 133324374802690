import { IconButton, Tooltip } from '@material-ui/core';
import Filter2Icon from '@material-ui/icons/Filter2';
import React, { FC, useCallback, useState } from 'react';
import { IDataTableRow } from '../../../DataTable/types';
import AppErrorMessage from '../../../Page/AppErrorMessage';
import AppProgress from '../../../Page/AppProgress';
import { CopyItemDialog, ICopyItemFieldsMap } from './CopyItemDialog';
import { useCopyItem } from './fetch';

interface IProps {
  itemId: string;
  flattenedContainerRows: IDataTableRow[];
  showFinishedItems: boolean;
}

export const CopyItemAction: FC<IProps> = ({
  itemId,
  flattenedContainerRows,
  showFinishedItems,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { executeCopyItem, loading, error } = useCopyItem();

  const toggleDialog = useCallback(() => {
    setIsDialogOpen((v) => !v);
  }, []);

  const handleSubmit = useCallback(
    async (values: ICopyItemFieldsMap) => {
      await executeCopyItem({
        variables: { targetLocation: { id: values.targetLocationId }, itemWhere: { id: itemId } },
      });
    },
    [executeCopyItem, itemId],
  );

  return (
    <>
      {loading && <AppProgress />}
      <AppErrorMessage isOpen={!!error} message={error?.message ?? ''} />
      <Tooltip title="Kopieren nach">
        <span>
          <IconButton disabled={loading} onClick={toggleDialog}>
            <Filter2Icon />
          </IconButton>
        </span>
      </Tooltip>
      <CopyItemDialog
        title="Kopieren nach"
        open={isDialogOpen}
        flattenedContainerRows={flattenedContainerRows}
        showFinishedItems={showFinishedItems}
        onClose={toggleDialog}
        onSubmit={handleSubmit}
      />
    </>
  );
};
