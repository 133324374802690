import { useEffect, useState } from 'react';

let globalAusmassDigitalLink = '';

export const useAusmassDigitalLink = () => {
  const [ausmassDigitalLink, setAusmassDigitalLink] = useState<string>(globalAusmassDigitalLink);

  useEffect(() => {
    const getAusmassDigitalLink = async () => {
      if (!globalAusmassDigitalLink) {
        const response = await fetch('/api/ausmass-digital-link');
        const link = await response.text();
        globalAusmassDigitalLink = link;
        setAusmassDigitalLink(link);
      }
    };
    getAusmassDigitalLink();
  }, []);

  return ausmassDigitalLink;
};
