import React, { useMemo } from 'react';
import { createSwissCurrencyFormatter } from '../../../../../utils/createCurrencyFormatter';
import { IAccountLogggerFormValues, IImpersonalEntry } from '../../types';
import {
  IDataTableColumn,
  IDataTableOptions,
  IDataTableRow,
} from '../../../../../components/DataTable/types';
import { DataTableBody, DataTableHotKeysWrapper } from '../../../../../components/DataTable';
import Dinero from 'dinero.js';
import {
  getSearchableVehicle,
  parseSearchableMission,
  searchableActivityType,
  searchableMission,
  searchableVehicle,
} from '../../../utils/searchable';
import { parseChargeConstraint } from '../../../utils/chargeConstraint';
import { GetAccountingActivityTypes_activityTypes } from '../../types/GetAccountingActivityTypes';
import { previewAccountingItemFields } from '../../types/previewAccountingItemFields';
import { GetAccountingMissions_missions } from '../../types/GetAccountingMissions';
import { GetAccountingVehicles_vehicles } from '../../types/GetAccountingVehicles';
import { ChargeType } from '../../../../../types/graphql';
import { useField } from 'formik';
import { GetAccountingSubsidiaries_subsidiaries } from '../../types/GetAccountingSubsidiaries';
import { roundToTwoDecimalPlaces } from '../../../../../utils/calculator';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

type TableRow = IDataTableRow<
  { mission: string; followupSum: Dinero.Dinero },
  void,
  {
    activityType: string;
    vehicle: string;
    date: string;
    amount: number;
    price: Dinero.Dinero;
    sum: Dinero.Dinero;
  }
>;

const columns: Array<IDataTableColumn<TableRow>> = [
  {
    id: 'activityType',
    label: 'Leistungsart',
  },
  {
    id: 'vehicle',
    label: 'Fahrzeug',
  },
  {
    id: 'date',
    label: 'Datum',
  },
  {
    id: 'amount',
    label: 'Menge',
    render: (amount) => <>{roundToTwoDecimalPlaces(amount)} H</>,
  },
  {
    id: 'price',
    label: 'Ansatz',
    render: formatCurrency,
  },
  {
    id: 'sum',
    label: 'Summe',
    render: formatCurrency,
  },
];

const missionColumns: IDataTableColumn[] = [
  {
    id: 'mission',
    label: 'Einsatz',
  },
  {
    id: 'followupSum',
    label: 'Summe',
    render: formatCurrency,
  },
];

const options: IDataTableOptions = {
  hideShowAdditionalColumnsBtn: true,
  displayName: 'Fahr- und Rüstzeiten',
  tableName: 'ACCOUNTING_LOGGER_IMPERSONAL_SUMMARY',
  activeRowId: '',
  fixedWidthColumns: true,
  levels: [
    {
      columns: missionColumns,
    },
    { columns: [] },
    { columns: [] },
    {
      columns,
    },
  ],
};

interface IGrouped {
  [missionId: string]: {
    mission: IAccountLogggerFormValues['impersonal'][number]['mission'];
    dates: {
      [date: string]: { followups: previewAccountingItemFields[]; impersonals: IImpersonalEntry[] };
    };
  };
}

/**
 * Map time entries to data table rows
 * @param subsidiaries the subsidiaries available for parsing
 * @param activityTypes the activity types available for parsing
 * @param missions the missions available for parsing
 * @param vehicles the vehicles available for parsing
 * @param items the fahr/rüstzeit entries
 * @param impersonalDriving the items from the materials section
 * @returns data table rows
 * @see IDataTableRow
 */
const mapToDataTable = (
  subsidiaries: readonly GetAccountingSubsidiaries_subsidiaries[],
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[],
  missions: readonly GetAccountingMissions_missions[],
  vehicles: readonly GetAccountingVehicles_vehicles[],
  items: readonly previewAccountingItemFields[],
  impersonalDriving: IAccountLogggerFormValues['impersonal'],
): TableRow[] => {
  // group calculated accounting items by mission id and date
  const grouped = items.reduce((grouped: IGrouped, item) => {
    const missionId = item.chargedTo.mission?.id;
    if (missionId == null) {
      console.error('should only contain charged to mission');
      return grouped;
    }
    const mission = missions.find(({ id }) => id === missionId);
    const date = item.activityDate;
    if (!(missionId in grouped)) {
      grouped[missionId] = {
        mission: mission ? searchableMission(mission) : 'Unbekannt: ' + missionId,
        dates: { [date]: { followups: [item], impersonals: [] } },
      };
    } else if (!(date in grouped[missionId].dates)) {
      grouped[missionId].dates[date] = { followups: [item], impersonals: [] };
    } else {
      grouped[missionId].dates[date].followups.push(item);
    }
    return grouped;
  }, {});

  // upsert the manual entries to the grouped items
  impersonalDriving.forEach(({ mission, date, entries }) => {
    const { id: missionId } = parseSearchableMission(mission);
    if (missionId in grouped) {
      if (date in grouped[missionId].dates) {
        grouped[missionId].dates[date].impersonals.push(...entries);
      } else {
        grouped[missionId].dates[date] = { impersonals: [...entries], followups: [] };
      }
    } else {
      grouped[missionId] = {
        mission,
        dates: { [date]: { impersonals: [...entries], followups: [] } },
      };
    }
  });

  return Object.keys(grouped).map((missionId) => {
    const innerTableRows = Object.keys(grouped[missionId].dates).flatMap((date) =>
      grouped[missionId].dates[date].impersonals
        .map((item, idx) => {
          const price = Dinero({
            amount: getSearchableVehicle(item.name, vehicles)?.internalHourlyWage ?? 0,
          });
          return {
            id: `${missionId}-impersonal-${date}-${idx.toString()}`,
            data: {
              activityType: item.activityType,
              vehicle: item.name,
              date,
              amount: item.amount,
              price,
              sum: price.multiply(item.amount),
            },
          };
        })
        .concat(
          grouped[missionId].dates[date].followups.map((item, idx) => {
            const activityType = activityTypes.find(
              (activityType) =>
                activityType.number === parseChargeConstraint(item.chargeConstraint).activityType,
            );
            const isCollectiveAccount = item.creditedTo.type === ChargeType.COLLECTIVEACCOUNT;
            const entityId = isCollectiveAccount
              ? item.creditedTo.subsidiary?.id
              : item.creditedTo.vehicle?.id;
            const entity = isCollectiveAccount
              ? subsidiaries.find(({ id }) => id === entityId)
              : vehicles.find(({ id }) => id === entityId);
            const amount = item.amount ?? 0;
            const price = Dinero({ amount: item.chargedTo.costPerUnit ?? 0 });
            return {
              id: `${missionId}-followup-${date}-${idx.toString()}`,
              data: {
                activityType: activityType ? searchableActivityType(activityType) : 'unbekannt',
                vehicle: entity
                  ? isCollectiveAccount
                    ? `Sammelkonto: ${'name' in entity ? entity.name : entity.id}`
                    : 'inventoryNumber' in entity
                    ? searchableVehicle(entity)
                    : `Fahrzeug: ${entity.id}`
                  : 'unbekannt',
                date,
                amount,
                price,
                sum: price.multiply(amount),
              },
            };
          }),
        ),
    );
    return {
      id: missionId,
      data: {
        mission: grouped[missionId].mission,
        followupSum: innerTableRows.reduce(
          (acc, { data: { sum } }) => acc.add(sum),
          Dinero({ amount: 0 }),
        ),
      },
      containerRows: [],
      innerTableRows,
    };
  });
};

export interface IImpersonalSummaryProps {
  drivingItems: readonly previewAccountingItemFields[];
  setupItems: readonly previewAccountingItemFields[];
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[];
  missions: readonly GetAccountingMissions_missions[];
  vehicles: readonly GetAccountingVehicles_vehicles[];
  subsidiaries: readonly GetAccountingSubsidiaries_subsidiaries[];
}

/**
 * The impersonal summary section lists times and follow ups for vehicles
 * @constructor
 */
export const ImpersonalSummary: React.FC<IImpersonalSummaryProps> = React.memo(
  ({ drivingItems, setupItems, activityTypes, missions, vehicles, subsidiaries }) => {
    const [{ value: impersonal }] = useField<IAccountLogggerFormValues['impersonal']>('impersonal');

    const impersonalDriving = impersonal
      .map((impersonalObj) => ({
        ...impersonalObj,
        entries: impersonalObj.entries
          .slice(0, -1)
          .filter((entry) => /^(5001|5002)/.test(entry.activityType)),
      }))
      .filter(({ entries }) => entries.length > 0);

    const containerRows = useMemo(
      () =>
        mapToDataTable(
          subsidiaries,
          activityTypes,
          missions,
          vehicles,
          [...drivingItems, ...setupItems],
          impersonalDriving,
        ),
      [
        activityTypes,
        drivingItems,
        impersonalDriving,
        missions,
        setupItems,
        subsidiaries,
        vehicles,
      ],
    );

    return (
      <DataTableHotKeysWrapper containerRows={containerRows} options={options}>
        {(context) => {
          return <DataTableBody context={context} />;
        }}
      </DataTableHotKeysWrapper>
    );
  },
);
