import React from 'react';
import { styled } from '@material-ui/styles';
import { Paper, Theme, Grid, Typography, Tooltip, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const PaperWrapper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const TabContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  '&:focus': {
    outline: 'none',
  },
  ...theme.mixins.gutters(),
}));

interface IHeader {
  title: string;
  onClose?: () => void;
}

interface IProps {
  header?: IHeader;
}

const PageWrapper: React.FC<IProps> = ({ header, children }) => (
  <PaperWrapper>
    <>
      {header && (
        <Grid container spacing={2}>
          <Grid item container xs={6}>
            <Box marginLeft={2}>
              <Typography variant="h6">{header.title}</Typography>
            </Box>
          </Grid>
          <Grid item container justify="flex-end" xs={6}>
            <Box marginRight={2}>
              {header.onClose && (
                <Tooltip title="Schliessen">
                  <IconButton onClick={() => header.onClose && header.onClose()} aria-label="Close">
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      <TabContainer>{children}</TabContainer>
    </>
  </PaperWrapper>
);

export default PageWrapper;
