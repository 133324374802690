import { TableCell, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { defaultTo, isNil } from 'lodash';
import React from 'react';
import HighlightedCellContent from '../HighlightedCellContent';
import { IDataTableColumn, IDataTableRow, ILevelOptions } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    padding: theme.spacing(1.5, 3, 1.5, 0),
    borderColor: theme.palette.grey[300],
    wordWrap: 'break-word',
  },
  activeRow: {
    borderWidth: 1,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
}));

interface IProps {
  row: IDataTableRow;
  column: IDataTableColumn;
  searchWords: string[];
  options: ILevelOptions;
  isActiveRow: boolean;
  style?: any;
  className?: string;
  searchable?: boolean;
}

const Cell: React.FunctionComponent<IProps> = (props) => {
  const { row, column, style, isActiveRow, className, searchable } = props;
  const classes = useStyles();

  // if column.id is e.g. 'item.name', first look if row.data has attribute 'item.name',
  // if not get row.data.item.name
  const columnValue = !isNil(row.data[column.id])
    ? row.data[column.id]
    : column.id.split('.').reduce((obj, key) => obj && obj[key], row.data);

  return (
    <TableCell
      className={classNames(className, classes.cell, { [classes.activeRow]: isActiveRow })}
      style={style}
      align={column.isNumber ? 'right' : 'left'}
      onDoubleClick={column.onDoubleClick ? () => column.onDoubleClick!(row) : undefined}
    >
      {searchable ? (
        <HighlightedCellContent row={row} column={column} searchWords={props.searchWords} />
      ) : // typography can cause dom nesting error e.g. p > div when rendering formik textfield
      column.renderPlain && column.render ? (
        column.render(row.data[column.id], row)
      ) : (
        <Typography variant="body2">
          {(column.render && column.render(row.data[column.id], row)) ||
            defaultTo(columnValue, '').toString()}
        </Typography>
      )}
    </TableCell>
  );
};

/**
 * A table cell with content:
 * - Filtertext is highlighted
 * - Cell contains an edit link of editUrlPrefix is set
 */
export default Cell;
