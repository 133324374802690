import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import UserForm from '../../components/User/UserForm';
import DialogCloseable from '../../components/Dialog/DialogCloseable';
import gql from 'graphql-tag';
import AppProgress from '../../components/Page/AppProgress';
import { GetUser, GetUserVariables } from './types/GetUser';
import { UpdateUser, UpdateUserVariables } from './types/UpdateUser';
import { UserUpdateInput } from '../../types/graphql';
import { GET_USERS } from '.';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import AppErrorMessage from '../../components/Page/AppErrorMessage';

const userFields = gql`
  fragment UserFields on User {
    id
    name
    email
    archived
    role
    subsidiaries
    signature {
      id
      name
      path
      mimetype
    }
  }
`;

const GET_USER = gql`
  query GetUser($id: ID!) {
    user(where: { id: $id }) {
      ...UserFields
    }
  }
  ${userFields}
`;

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: ID!, $data: UserUpdateInput!) {
    updateUser(where: { id: $id }, data: $data) {
      ...UserFields
    }
  }
  ${userFields}
`;

interface IUrlParams {
  id: string;
}

const UserDetails: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<IUrlParams>();

  const { data, error, loading } = useQuery<GetUser, GetUserVariables>(GET_USER, {
    variables: { id },
  });

  const [updateUser, { error: mutationError }] = useMutation<UpdateUser, UpdateUserVariables>(
    UPDATE_USER_MUTATION,
    {
      refetchQueries: [
        { query: GET_USERS, variables: { archived: false } },
        { query: GET_USERS, variables: { archived: undefined } },
      ],
    },
  );

  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async (data: UserUpdateInput) => {
    await updateUser({ variables: { id, data } });
    handleClose();
  };

  if (loading) {
    return <AppProgress />;
  }

  if (error) {
    console.log(error);
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {mutationError && <AppErrorMessage message={errorPrefixRemover(mutationError.message)} />}
      <DialogCloseable title="User Details" open onClose={handleClose}>
        <UserForm initialValues={data.user} onSubmit={handleSubmit} />
      </DialogCloseable>
    </>
  );
};

export default UserDetails;
