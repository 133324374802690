import React from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';
import { Tooltip, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  primary: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
  },
}));

interface IProps extends RouteComponentProps {
  title: string;
  href: string;
  icon: React.ReactElement<any>;
}

const AppDrawerNavItem: React.FunctionComponent<IProps> = ({ title, href, icon, location }) => {
  const classes = useStyles();
  return (
    <Link to={href}>
      <ListItem button>
        <Tooltip title={title}>
          <ListItemIcon className={href === location.pathname ? classes.primary : undefined}>
            {icon}
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={title} classes={href === location.pathname ? classes : {}} />
      </ListItem>
    </Link>
  );
};

export default withRouter(AppDrawerNavItem);
