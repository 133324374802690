import { Grid, IconButton, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { MouseEventHandler, useCallback, useState } from 'react';
import { useProjectNumber } from '../../../hooks/useProjectNumber';
import { UpsertAddressBookItemType } from '../../../types/graphql';
import { useAddressSectionStyles } from '../header';
import { AddressSearchPopper } from './modal';
import { MODAL_OPENER_CLASS } from './search/constants';

interface IProps {
  type: UpsertAddressBookItemType;
}

export const AddressSearchSection: React.FC<IProps> = ({ type }) => {
  const projectNumber = useProjectNumber();
  if (!projectNumber) {
    throw new Error();
  }

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  const sectionClasses = useAddressSectionStyles();

  const toggleSearch = useCallback(() => {
    setIsSearchOpen((v) => !v);
  }, []);
  const closeSearch = useCallback(() => {
    setIsSearchOpen(false);
  }, []);

  const handleOpenSearch = useCallback<MouseEventHandler<HTMLSpanElement>>(
    (e) => {
      toggleSearch();
      if (!anchorEl) {
        setAnchorEl(e.currentTarget);
      }
    },
    [toggleSearch, anchorEl],
  );

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="body1" classes={{ root: sectionClasses.smallSectionHeader }}>
            Suche
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={handleOpenSearch}
            classes={{ root: MODAL_OPENER_CLASS, label: MODAL_OPENER_CLASS }}
          >
            <SearchIcon classes={{ root: MODAL_OPENER_CLASS }} fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
      <AddressSearchPopper
        open={isSearchOpen}
        anchorEl={anchorEl}
        onClose={closeSearch}
        projectNumber={projectNumber}
        type={type}
      />
    </>
  );
};
