import ApolloClient from 'apollo-client';
import { remove } from 'lodash';
import { HAS_PROJECT_BOQ_QUERY } from '../../../../../pages/Missions/queries';
import {
  HasProjectBoqs,
  HasProjectBoqsVariables,
} from '../../../../../pages/Missions/types/HasProjectBoqs';
import { BillOfQuantityEntityType, TableType } from '../../../../../types/graphql';
import { getPopulatedBoqTableTypes } from './getPopulatedTableType';

export type BoqTableType =
  | TableType.OFFER
  | TableType.ORDER
  | TableType.ORDER_MISSION
  | TableType.ORDER_MEASUREMENT;

export const BOQ_TABLE_TYPES: BoqTableType[] = [
  TableType.ORDER,
  TableType.OFFER,
  TableType.ORDER_MEASUREMENT,
  TableType.ORDER_MISSION,
];

type CallbackFn<Result> = (tableType: BoqTableType) => Result;

export const checkProjectHasBoqs = async (
  client: ApolloClient<any>,
  projectNumber: string,
): Promise<boolean> => {
  // use queryManager because it adds `hasBillOfQuantity` to Project entity in cache automatically
  const { data } = await client.queryManager.query<HasProjectBoqs>({
    query: HAS_PROJECT_BOQ_QUERY,
    variables: { projectNumber },
  });

  return data.project.hasBillOfQuantities;
};

export const executeForPopulatedBoqTableTypes = async <Result>(
  client: ApolloClient<any>,
  projectNumber: string,
  callback: CallbackFn<Result>,
  entityType = BillOfQuantityEntityType.ORDER,
): Promise<void> => {
  const populatedTypes = getPopulatedBoqTableTypes(client, entityType);
  const projectHasBoqs = await checkProjectHasBoqs(client, projectNumber);
  const typesToExecuteCallbackOn: BoqTableType[] = projectHasBoqs
    ? populatedTypes
    : entityType === BillOfQuantityEntityType.OFFER
    ? [TableType.OFFER]
    : remove(BOQ_TABLE_TYPES, TableType.OFFER);

  await Promise.all(typesToExecuteCallbackOn.map(callback));

  if (projectHasBoqs) {
    return;
  }

  await markProjectHasBoqs(client, projectNumber);
};

export const markProjectHasBoqs = async (
  client: ApolloClient<any>,
  projectNumber: string,
): Promise<void> => {
  const { data } = await client.queryManager.query<HasProjectBoqs>({
    query: HAS_PROJECT_BOQ_QUERY,
    variables: { projectNumber },
  });

  if (!data) {
    return;
  }

  client.writeQuery<HasProjectBoqs, HasProjectBoqsVariables>({
    query: HAS_PROJECT_BOQ_QUERY,
    variables: { projectNumber },
    data: {
      project: {
        ...data.project,
        hasBillOfQuantities: true,
      },
    },
  });
};
