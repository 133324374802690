import gql from 'graphql-tag';
import { FULL_ADDRESS_BOOK_ITEM_CONTACT_PERSON } from '../query';

export const CLEAR_CONTACT_PERSON_MUTATION = gql`
  mutation ClearContactPerson($addressBookId: Int!) {
    updateAddressBookItem(where: { id: $addressBookId }, data: { contactPerson: null }) {
      id
      contactPerson {
        ...BookItemContactPerson
      }
    }
  }
  ${FULL_ADDRESS_BOOK_ITEM_CONTACT_PERSON}
`;

export const SELECT_CONTACT_PERSON_MUTATION = gql`
  mutation SelectContactPerson(
    $addressBookId: Int!
    $contactPerson: ContactPersonWhereUniqueInput
  ) {
    updateAddressBookItem(where: { id: $addressBookId }, data: { contactPerson: $contactPerson }) {
      id
      contactPerson {
        ...BookItemContactPerson
      }
    }
  }
  ${FULL_ADDRESS_BOOK_ITEM_CONTACT_PERSON}
`;

export const UPSERT_CONTACT_PERSON = gql`
  mutation UpsertContactPerson(
    $where: ContactPersonUpsertWhereInput!
    $data: ContactPersonUpdateInput!
  ) {
    upsertContactPerson(where: $where, data: $data) {
      ...BookItemContactPerson
    }
  }
  ${FULL_ADDRESS_BOOK_ITEM_CONTACT_PERSON}
`;
