import { GetVehicle_vehicle } from '../../pages/Vehicles/types/GetVehicle';
import defaultNilInitialValues from '../../utils/form/initialValuesDefaults';
import { getChangedFormValues } from '../../utils/form/getChanged';
import { omit } from 'lodash';
import createCurrencyFormatter from '../../utils/createCurrencyFormatter';

const currencyFormater = createCurrencyFormatter({ format: '0.00' });

export const getFormInitialValues = (initialValues?: GetVehicle_vehicle) => {
  const formInitialValues = {
    ...omit(initialValues, ['id', 'subsidiaryHistory', 'kilometersHistory', '__typeName']),
    ...(initialValues?.internalHourlyWage && {
      internalHourlyWage: currencyFormater(initialValues.internalHourlyWage),
    }),
    documents: {},
    subsidiary: initialValues?.subsidiary?.name,
  };

  return defaultNilInitialValues(formInitialValues, {
    inventoryNumber: '',
    projectNumber: '',
    category: '',
    subsidiary: '',
    driver: '',
    nameOne: '',
    nameTwo: '',
    releaseDate: '',
    weight: '',
    payload: '',
    registrationNumber: '',
    kilometers: '',
    internalHourlyWage: '',
    emissionsTestDate: '',
    trafficOfficeDate: '',
    note: '',
    trailerLoad: '',
    fuelCardOne: '',
    fuelCardTwo: '',
    pinOne: '',
    pinTwo: '',
    tcsAsof: '',
    eVignette: '',
    serialNumber: '',
    chassisNumber: '',
    lastInternalCheckAt: '',
    isDeactivated: false,
  });
};

export const mapSubmittedValues = (
  { subsidiaryHistory, driverHistory, ...values }: any,
  initialValues: any,
) => {
  const changedValues = getChangedFormValues({ initialValues, values });

  if (changedValues.subsidiary) {
    changedValues.subsidiary = {
      name: changedValues.subsidiary,
    };
  }

  return changedValues;
};
