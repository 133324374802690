import React from 'react';
import gql from 'graphql-tag';
import { Select, MenuItem } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { useQuery } from 'react-apollo';
import AppProgress from '../../../Page/AppProgress';
import AppErrorMessage from '../../../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../../utils/errorPrefixRemover';
import { CatalogMaterials, CatalogMaterialsVariables } from './types/CatalogMaterials';

const MATERIAL_QUERY = gql`
  query CatalogMaterials($catalogId: ID!) {
    catalog(where: { id: $catalogId }) {
      id
      materials
    }
  }
`;

const MaterialSelect: React.FunctionComponent<
  SelectProps & {
    catalogId: string;
  }
> = ({ catalogId, ...props }) => {
  const { data, loading, error } = useQuery<CatalogMaterials, CatalogMaterialsVariables>(
    MATERIAL_QUERY,
    {
      variables: { catalogId },
      skip: !catalogId,
    },
  );

  if (!catalogId) {
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <AppErrorMessage isOpen={!!error} message={errorPrefixRemover(error?.message ?? '')} />
      <Select {...props}>
        <MenuItem key="Alle" value="all">
          Alle
        </MenuItem>
        {data?.catalog?.materials.map((material) => (
          <MenuItem key={material} value={material}>
            {material}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default MaterialSelect;
