import ApolloClient from 'apollo-client';
import { buildContainerItemCountMapping } from '../../../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/onSearch';
import { ISearchSubmitProps } from '../../../../../components/Search/Search';
import { ContainerRows } from '../../../../../services/graphql-client';
import { GetDataTables_dataTables } from '../../../../../services/types/GetDataTables';
import { MissionItemWhereInput, SearchInput, TableType } from '../../../../../types/graphql';
import { getAllContainerRows } from '../../../../../utils/paginationHelpers/getAllContainerRows';
import { mapMissionItemToRowInput, removeTableTypeFromId } from './mapper';
import { MISSION_SEARCH_DEFAULT_MISSION_ITEMS, MISSION_SEARCH_MISSION_ITEMS } from './queries';
import {
  MissionSearchDefaultMissionItems,
  MissionSearchDefaultMissionItemsVariables,
} from './types/MissionSearchDefaultMissionItems';
import {
  MissionSearchMissionItems,
  MissionSearchMissionItemsVariables,
  MissionSearchMissionItems_missionItemLocations,
} from './types/MissionSearchMissionItems';

const getSearchDataOnMission =
  (client: ApolloClient<any>) =>
  (
    itemAmount: number,
    searchInput: SearchInput,
    missionItemWhere: MissionItemWhereInput,
    projectNumber: string,
  ) =>
  async (containerIds: string[]) => {
    const missionIdIn = containerIds.map((containerId) =>
      parseInt(removeTableTypeFromId(containerId), 10),
    );

    const { data: searchData } = await client.query<
      MissionSearchDefaultMissionItems,
      MissionSearchDefaultMissionItemsVariables
    >({
      query: MISSION_SEARCH_DEFAULT_MISSION_ITEMS,
      variables: {
        search: searchInput,
        missionIdIn,
        missionItemWhere,
        take: itemAmount,
        projectWhere: { projectNumber },
      },
    });

    return searchData;
  };

const getSearchDataOnLocation =
  (client: ApolloClient<any>) =>
  (itemAmount: number, searchInput: SearchInput, missionItemWhere: MissionItemWhereInput) =>
  async (containerIds: string[]) => {
    const idIn = containerIds.map(removeTableTypeFromId);
    const missionIdIn = idIn.map((id) => parseInt(id.split('-')[0], 10));

    const { data: searchData } = await client.query<
      MissionSearchMissionItems,
      MissionSearchMissionItemsVariables
    >({
      query: MISSION_SEARCH_MISSION_ITEMS,
      variables: {
        search: searchInput,
        locationWhere: { id_in: idIn, mission_id_in: missionIdIn },
        missionItemWhere,
        take: itemAmount,
      },
    });

    return searchData;
  };

const writeLocationToCache =
  (client: ApolloClient<any>) =>
  (tableType: TableType) =>
  (containerRows: ContainerRows) =>
  (location: MissionSearchMissionItems_missionItemLocations) => {
    const containerRow = containerRows.find(
      (containerRow) => removeTableTypeFromId(containerRow.id) === location.id,
    );

    if (!containerRow) {
      return;
    }

    client.cache.writeData({
      id: `ClientPageInfo:${containerRow.id}`,
      data: {
        __typename: 'ClientPageInfo',
        hasNextPage: location.missionItemsConnection.pageInfo.hasNextPage,
      },
    });

    client.cache.writeData({
      id: `DataTableRow:${containerRow.id}`,
      data: {
        ...containerRow,
        innerTableRows: location.missionItemsConnection.nodes.map(
          mapMissionItemToRowInput(tableType),
        ),
      },
    });
  };

export const onSearchMissionItems =
  (client: ApolloClient<any>) =>
  (tableType: TableType) =>
  (setLoading: (v: boolean) => void, setSearchState: (searchProps: ISearchSubmitProps) => void) =>
  (filteredDataTable: GetDataTables_dataTables | null) =>
  async (
    searchInput: SearchInput,
    missionItemWhere: MissionItemWhereInput,
    projectNumber: string,
  ) => {
    if (!filteredDataTable) {
      return;
    }

    setSearchState(searchInput);
    setLoading(true);

    const { missionContainerRows, locationContainerRows } = getAllContainerRows(
      filteredDataTable,
    ).reduce<Record<'missionContainerRows' | 'locationContainerRows', ContainerRows>>(
      (acc, containerRow) => {
        const compositeId = removeTableTypeFromId(containerRow.id);
        const isMission = !compositeId.includes('-');

        if (isMission) {
          acc.missionContainerRows.push(containerRow);
        } else {
          acc.locationContainerRows.push(containerRow);
        }

        return acc;
      },
      { missionContainerRows: [], locationContainerRows: [] },
    );

    const missionContainerItemCountMapping = buildContainerItemCountMapping(missionContainerRows);
    const locationContainerItemCountMapping = buildContainerItemCountMapping(locationContainerRows);

    for (const { itemAmount, containerIds } of missionContainerItemCountMapping) {
      const searchData = await getSearchDataOnMission(client)(
        itemAmount,
        searchInput,
        missionItemWhere,
        projectNumber,
      )(containerIds);

      searchData.missions
        .flatMap((mission) => mission.defaultMissionItemLocation)
        .forEach(writeLocationToCache(client)(tableType)(missionContainerRows));
    }

    for (const { itemAmount, containerIds } of locationContainerItemCountMapping) {
      const searchData = await getSearchDataOnLocation(client)(
        itemAmount,
        searchInput,
        missionItemWhere,
      )(containerIds);

      searchData.missionItemLocations.forEach(
        writeLocationToCache(client)(tableType)(locationContainerRows),
      );
    }

    setLoading(false);
  };
