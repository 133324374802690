import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Formik, Field, Form } from 'formik';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { omit } from 'lodash';
import gql from 'graphql-tag';
import FormikTextField from '../../components/Form/FormikTextField';
import FormikCheckBoxWithLabel from '../../components/Form/FormikCheckboxWithLabel';
import WrappedMutation from '../../components/apollo/WrappedMutation';
import { GET_CATALOGS } from './index';
import Dropezone from '../../components/Form/UploadField/Dropzone';
import { ImportCatalog, ImportCatalogVariables } from './types/ImportCatalog';
import { USE_COUNT_QUERIES } from '../../hooks/useCount/useCount.queries';

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: 800,
    minWidth: 500,
  },
});

type IImportProps = RouteComponentProps & {
  open: boolean;
};

const IMPORT_CATALOG = gql`
  mutation ImportCatalog($data: ImportCatalogInput!) {
    importCatalog(data: $data) {
      id
      useName
    }
  }
`;

const ImportDialog: React.FunctionComponent<IImportProps> = ({ history }) => {
  const classes = useStyles();

  const { state } = useLocation();

  const handleClose = () => history.goBack();

  return (
    <WrappedMutation<ImportCatalog, ImportCatalogVariables>
      mutation={IMPORT_CATALOG}
      refetchQueries={[
        { query: GET_CATALOGS, variables: state.variables },
        ...(state.variables
          ? [{ query: USE_COUNT_QUERIES.CATALOG, variables: omit(state.variables, 'page') }]
          : []),
      ]}
    >
      {(importCatalog) => (
        <Dialog open={true} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
          <DialogTitle>Ertragskatalog importieren</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                useName: '',
                isOverride: false,
                catalog: {},
                isProjectCatalog: false,
              }}
              onSubmit={(values: any, { setSubmitting }) => {
                const data = {
                  ...values,
                  catalog: values.catalog.uploads[0],
                };
                importCatalog({ variables: { data } }).then(() => handleClose());
                setSubmitting(false);
              }}
            >
              <Form>
                <Field
                  name="useName"
                  label="Verwendungsname"
                  component={FormikTextField}
                  required
                  autoFocus
                  margin="dense"
                  fullWidth
                />
                <Box display="flex" flexDirection="column">
                  <Field
                    name="isOverride"
                    component={FormikCheckBoxWithLabel}
                    Label={{ label: 'Katalog Überschreiben' }}
                  />
                  <Field
                    name="isProjectCatalog"
                    component={FormikCheckBoxWithLabel}
                    Label={{ label: 'Projektspezifischer Katalog' }}
                  />
                </Box>
                <Field
                  name="catalog"
                  label="Ertragskatalog"
                  accept={
                    'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  }
                  multiple={false}
                  component={Dropezone}
                />
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Abbrechen
                  </Button>
                  <Button type="submit" color="primary">
                    Importieren
                  </Button>
                </DialogActions>
              </Form>
            </Formik>
          </DialogContent>
        </Dialog>
      )}
    </WrappedMutation>
  );
};

export default ImportDialog;
