import gql from 'graphql-tag';

export const GENERATE_ACCOUNTING_JOURNAL_CSV = gql`
  query GenerateAccountingJournalCsv {
    generateAccountingJournalCsv
  }
`;

export const GENERATE_PROJECT_CSV = gql`
  query GenerateProjectCsv(
    $where: ProjectWhereInput
    $search: SearchInput
    $dateFilter: DateFilterInput
  ) {
    generateProjectCsv(where: $where, search: $search, dateFilter: $dateFilter)
  }
`;

export const GENERATE_BILL_OF_QUANTITY_CSV = gql`
  query GenerateBillOfQuantityCsv(
    $where: BillOfQuantityWhereInput
    $search: SearchInput
    $dateFilter: DateFilterInput
  ) {
    generateBillOfQuantityCsv(where: $where, search: $search, dateFilter: $dateFilter)
  }
`;

export const GENERATE_BILL_CSV = gql`
  query GenerateBillCsv(
    $where: BillWhereInput
    $search: SearchInput
    $dateFilter: DateFilterInput
  ) {
    generateBillCsv(where: $where, search: $search, dateFilter: $dateFilter)
  }
`;

export const GENERATE_MATERIAL_CSV = gql`
  query GenerateMaterialCsv($search: SearchInput) {
    generateMaterialCsv(search: $search)
  }
`;

export const GENERATE_VEHICLE_CSV = gql`
  query GenerateVehicleCsv(
    $where: VehicleWhereInput
    $search: SearchInput
    $dateFilter: DateFilterInput
  ) {
    generateVehicleCsv(where: $where, search: $search, dateFilter: $dateFilter)
  }
`;

export const GENERATE_MACHINE_CSV = gql`
  query GenerateMachineCsv(
    $where: MachineWhereInput
    $search: SearchInput
    $dateFilter: DateFilterInput
  ) {
    generateMachineCsv(where: $where, search: $search, dateFilter: $dateFilter)
  }
`;

export const GENERATE_MISSION_CSV = gql`
  query GenerateMissionCsv(
    $where: MissionWhereInput
    $search: SearchInput
    $dateFilter: DateFilterInput
  ) {
    generateMissionCsv(where: $where, search: $search, dateFilter: $dateFilter)
  }
`;
