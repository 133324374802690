import { useQuery } from 'react-apollo';
import { BillStatus } from '../../types/graphql';
import { BILL_FIXED_QUERY } from './AddressTabs.queries';
import { BillFixed, BillFixedVariables } from './types/BillFixed';

export const useBillFixed = (id: string, skip?: boolean) => {
  const { data, error } = useQuery<BillFixed, BillFixedVariables>(BILL_FIXED_QUERY, {
    variables: { id },
    skip,
  });

  if (error) {
    console.log(error);

    return null;
  }

  if (!data && !skip) {
    return null;
  }

  return data?.bill?.status === BillStatus.FIXIERT;
};
