import ApolloClient from 'apollo-client';
import { Maybe } from 'graphql/jsutils/Maybe';
import { removeTableTypeFromId } from '../../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { ADD_ROW_TO_DATA_TABLE, SET_PAGE_INFO } from '../../../../../services/graphql-client';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../../../../services/types/AddRowToDataTable';
import { SetPageInfo, SetPageInfoVariables } from '../../../../../services/types/SetPageInfo';
import { AddRowToDataTableInput, RowType, SearchInput } from '../../../../../types/graphql';
import { IPaginationHelperFns } from '../../../../../utils/paginationHelpers';
import { compositeLocationId } from '../compositeLocationId';
import { BoqTableType } from './executeForBoqTypes';
import { buildBoqTableRowId, mapItemToInnerTableRow } from './mapper';
import { GET_MORE_ITEMS } from './queries';
import {
  GetMoreBillOfQuantityItems,
  GetMoreBillOfQuantityItemsVariables,
} from './types/GetMoreBillOfQuantityItems';

export const fetchItemsConnectionBillOfQuantity =
  (client: ApolloClient<any>, tableType: BoqTableType) =>
  (searchState: Maybe<SearchInput>) =>
  (setLoading: (v: boolean) => void): IPaginationHelperFns['fetchItemsConnection'] =>
  async (variables) => {
    setLoading(true);

    const {
      data: {
        location: { id: locationId, billOfQuantity, parentLocation, itemsConnection },
      },
    } = await client.query<GetMoreBillOfQuantityItems, GetMoreBillOfQuantityItemsVariables>({
      query: GET_MORE_ITEMS,
      variables: {
        ...variables,
        after: variables.after ? removeTableTypeFromId(variables.after) : undefined,
        search: searchState,
      },
    });

    const compositeId = parentLocation
      ? compositeLocationId({
          billOfQuantityId: billOfQuantity.id,
          parentLocationId: parentLocation.id,
          locationId,
        })
      : locationId;
    const tableRowId = buildBoqTableRowId(tableType)(compositeId);

    const dataTableRowInputs: AddRowToDataTableInput[] = itemsConnection.nodes.map((node) => {
      return {
        row: mapItemToInnerTableRow(tableType)(node),
        type: RowType.INNER,
        containerId: tableRowId,
      };
    });

    await client.query<SetPageInfo, SetPageInfoVariables>({
      query: SET_PAGE_INFO,
      variables: {
        data: {
          id: tableRowId,
          hasNextPage: itemsConnection.pageInfo.hasNextPage,
        },
      },
    });

    await client.query<AddRowToDataTable, AddRowToDataTableVariables>({
      query: ADD_ROW_TO_DATA_TABLE,
      variables: { where: { id: tableType }, data: dataTableRowInputs },
    });

    setLoading(false);
  };
