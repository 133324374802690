import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Query from '../apollo/WrappedQuery';
import gqlClient from 'graphql-tag';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '1rem',
  },
  formControl: {
    width: '100%',
  },
  avatar: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    marginLeft: 12,
    marginRight: 40,
    width: 30,
    height: 30,
    fontSize: theme.typography.pxToRem(15),
  },
}));

const GET_SUBSIDIARIES = gqlClient`
  query Subsidiaries {
    subsidiaries {
      id
      name
      acronym
    }
    selectedSubsidiary @client
  }
`;

interface IProps {
  isSidebarOpen: boolean;
}

const NavigationSubsidiary: React.FC<IProps> = ({ isSidebarOpen }) => {
  const classes = useStyles();
  return (
    <Query<any, any> query={GET_SUBSIDIARIES}>
      {({ data: { subsidiaries, selectedSubsidiary }, client }: any) => {
        subsidiaries =
          subsidiaries.length > 0
            ? [{ id: 'all', acronym: 'Alle', name: 'Alle Filialen' }, ...subsidiaries]
            : subsidiaries;

        const subsidiary = subsidiaries.find((s: any) => s.id === selectedSubsidiary);

        return isSidebarOpen ? (
          <form autoComplete="off" className={classes.root}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="subsidiary">Filiale</InputLabel>
              <Select
                value={selectedSubsidiary}
                onChange={(e) => client.writeData({ data: { selectedSubsidiary: e.target.value } })}
              >
                {subsidiaries.map((subsidiary: any) => (
                  <MenuItem value={subsidiary.id} key={subsidiary.id}>
                    {subsidiary.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        ) : (
          <Tooltip title={subsidiary.name}>
            <Avatar className={classes.avatar}>{subsidiary.acronym}</Avatar>
          </Tooltip>
        );
      }}
    </Query>
  );
};

export default NavigationSubsidiary;
