export interface IWithLoading {
  loading: boolean;
}

/**
 * Combine multiple loading indicators
 * @param loading an object that has a loading field
 * @returns true if any of the objects is loading
 */
export const someLoading = (...loading: ReadonlyArray<Readonly<IWithLoading>>) =>
  loading.some(({ loading }) => loading);
