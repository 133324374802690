import gql from 'graphql-tag';

export const EMPLOYEES_CALENDAR_QUERY = gql`
  query GetEmployeesCalendars($where: EmployeesCalendarWhereInput!) {
    getEmployeesCalendars(where: $where) {
      id
      detailedDays {
        id
        date
        workedTimeSum
        flextimeBalance
        dayBalance
        plannedWorkTime
        activityTypeSummaries {
          id
          activityType {
            id
            number
            nameOne
            unit {
              id
              acronym
            }
          }
          amount
        }
      }
      events {
        id
        activityType {
          id
          number
          nameOne
          unit {
            id
            acronym
          }
        }
        start
        end
        reportId
        chargedTo {
          id
          subsidiary {
            id
            name
          }
          mission {
            id
            name
            project {
              id
              projectName
              projectNumber
              subsidiary {
                id
                name
              }
            }
          }
          vehicle {
            id
            nameOne
            nameTwo
            inventoryNumber
            subsidiary {
              id
              name
            }
          }
          collectiveAccount {
            id
            nameOne
            nameTwo
            subsidiary {
              id
              name
            }
            projectNumber
          }
        }
      }
    }
  }
`;
