import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';

interface IConfirmationDialogProps extends DialogProps {
  message: string;
  title: string;
  onConfirm: () => void;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}

const ConfirmationDialog: React.FunctionComponent<IConfirmationDialogProps> = ({
  message,
  title,
  onConfirm,
  onClose,
  ...dialogProps
}) => (
  <Dialog {...dialogProps}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Abbrechen</Button>
      <Button
        color="primary"
        autoFocus
        onClick={(e) => {
          onConfirm();
          onClose(e);
        }}
      >
        Zustimmen
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
