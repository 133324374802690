import gql from 'graphql-tag';
import { PROJECT_FIELDS_FRAGMENT } from '../../../pages/Projects/TabDetails';

export const MOBILE_PROJECT_DETAILS_QUERY = gql`
  query MobileProjectDetails($projectNumber: String!) {
    project(where: { projectNumber: $projectNumber }) {
      ...projectFields
    }
  }
  ${PROJECT_FIELDS_FRAGMENT}
`;
