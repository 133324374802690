import React, { useMemo } from 'react';
import classNames from 'classnames';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { FieldProps, getIn } from 'formik';
import green from '@material-ui/core/colors/green';
import { Omit } from './types';
import createPreventAll from '../../utils/createPreventEventDefault';

/** Inspired by formik-material-ui */

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
    dirty: {
      color: green[700],
    },
    noSpinner: {
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  });

export type IFormikTextFieldProps = FieldProps &
  Omit<TextFieldProps, 'error' | 'name' | 'value' | 'classes'> &
  WithStyles<typeof styles> & {
    shrink?: boolean;
    dirty?: boolean;
    classes?: { root?: string };
    noDisableOnSubmitting?: boolean;
  };

const fieldToTextField = ({
  field,
  form,
  variant,
  disabled = false,
  classes,
  shrink,
  dirty,
  fullWidth,
  noDisableOnSubmitting,
  ...props
}: IFormikTextFieldProps): TextFieldProps => {
  const { name, value } = field;
  const { touched, errors, isSubmitting, initialValues } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const isDirty = dirty ?? value !== getIn(initialValues, name);

  props.inputProps = {
    ...(props.inputProps && props.inputProps),
    className: `${classes.noSpinner} ${!props.label && isDirty && classes.dirty}`,
    step: 'any',
    onWheelCapture:
      props.type === 'number'
        ? createPreventAll(() => {
            return;
          })
        : undefined,
  };

  return {
    className: props.label ? classes.root : undefined,
    classes: classes?.root ? { root: classes.root } : undefined,
    ...props,
    ...field,
    onChange: (e) => {
      e.persist();
      field.onChange(e);
      props.onChange?.(e);
    },
    ...(props.onBlur && { onBlur: props.onBlur }), // DO NOT DELETE -> black magic from formik?
    InputLabelProps: { className: classNames({ [classes.dirty]: isDirty }), shrink },
    variant: variant as any,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: noDisableOnSubmitting ? disabled : isSubmitting || disabled,
    fullWidth: fullWidth ?? true,
  };
};

const FormikTextField: React.ComponentType<IFormikTextFieldProps> = React.memo(
  ({ children, ...props }: IFormikTextFieldProps) => {
    const textFieldProps = useMemo(() => fieldToTextField(props), [props]);
    return <TextField {...textFieldProps} children={children} />;
  },
);
FormikTextField.displayName = 'FormikTextField';

export default withStyles(styles)(FormikTextField);
