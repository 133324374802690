import gql from 'graphql-tag';
import { locationCompositeIdFields } from '../../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/queries';

export const billFields = gql`
  fragment billFields on Bill {
    id
    createdAt
    updatedAt
    date
    billDate
    number
    title
    titleDropdown
    netBillSum
    grossBillSum
    status
    billDate
    isFixedBill
    hasBillItems
    canBeDeleted
    publisher {
      id
      name
    }
    missions {
      id
    }
    defaultLocation {
      id
      hasDescendants
    }
  }
`;

export const billItemFields = gql`
  fragment billItemFields on BillItem {
    id
    amountVolume
    invoicedVolumeSum
    canBeDeleted
    pricePerUnit
    isScaleDiscountOverridden
    scaleDiscounts {
      id
    }
    missionItem {
      id
      comment
      customerComment
      mission {
        id
        locationsIndex
        executionDate
        name
        isMissionBlocked
        manualLockingStatus
      }
      item {
        id
        comment
        customerComment
        productNumber
        acronym
        unit
        isFinished
        descriptionOne
        descriptionTwo
        markingStyle
        freeText
        applyScaleDiscount
        appliedPrice
        billOfQuantity {
          id
          name
        }
        location {
          id
          name
          parentLocation {
            id
            name
            parentLocation {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const billFieldsForBillView = gql`
  fragment billFieldsForBillView on Bill {
    missions {
      id
    }
    isFixedBill
    billOfQuantity {
      id
      defaultLocation {
        id
        name
      }
    }
    defaultLocation {
      id
      name
      hasDescendants
      itemLocation {
        id
        ...locationCompositeIdFields
      }
      locations {
        id
        name
        hasDescendants
        itemLocation {
          id
          ...locationCompositeIdFields
        }
        locations {
          id
          name
          hasDescendants
          itemLocation {
            id
            ...locationCompositeIdFields
          }
        }
      }
    }
  }
  ${locationCompositeIdFields}
`;

export const PROJECT_BILLS_QUERY = gql`
  query ProjectBillsQuery($projectNumber: String!, $search: SearchInput) {
    project(where: { projectNumber: $projectNumber }) {
      id
      bills(search: $search) {
        ...billFields
        ...billFieldsForBillView
      }
    }
  }
  ${billFields}
  ${billFieldsForBillView}
`;

export const GET_BILL = gql`
  query GetBill($where: BillWhereUniqueInput!) {
    bill(where: $where) {
      ...billFields
      ...billFieldsForBillView
    }
  }
  ${billFields}
  ${billFieldsForBillView}
`;

export const GET_SIMPLE_BILL = gql`
  query GetSimpleBill($where: BillWhereUniqueInput!) {
    bill(where: $where) {
      ...billFields
    }
  }
  ${billFields}
`;

export const GET_BILL_ID_OF_BILL_ITEM = gql`
  query GetBillIdOfBillItem($where: BillItemWhereUniqueInput!) {
    billItem(where: $where) {
      id
      bill {
        id
      }
    }
  }
`;
