const cache: Record<number, Readonly<{ flex: number }>> = {};
const nullFlex = { flex: undefined };

/**
 * cached {flex: number} objects to be used in style prop
 * @param flex the flex number or an undefined
 * @returns a cached flex object maintaining object identity
 */
export const cachedFlex = (
  flex: number | null | undefined,
): Readonly<{ flex: number | undefined }> => {
  if (flex == null) {
    return nullFlex;
  }
  if (flex in cache) {
    return cache[flex];
  } else {
    cache[flex] = { flex };
    return cache[flex];
  }
};
