import { IChildrenRows, IDataTableRow } from '../types';
import orderBy from 'lodash/orderBy';

const sortWithChildren = (
  childrenRows: IChildrenRows,
  orderByColumnId: string,
  order: 'asc' | 'desc',
): IChildrenRows => ({
  containerRows: sortRows(childrenRows.containerRows || [], orderByColumnId, order),
  innerTableRows: sortRows(childrenRows.innerTableRows || [], orderByColumnId, order),
});

const sortRows = (
  rows: IDataTableRow[],
  orderByColumnId: string,
  order: 'asc' | 'desc',
): IDataTableRow[] =>
  (orderBy(rows, (row) => row.data[orderByColumnId], order) as IDataTableRow[]).map((row) => ({
    ...row,
    ...sortWithChildren(row, orderByColumnId, order),
  }));

/**
 * Sort rows with child rows.
 *
 * First the parent level is sorted.
 * Child rows are sorted separated for each parent row.
 */
export default sortWithChildren;
