import { IconButton, Tooltip } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import React, { useCallback, useMemo, useState } from 'react';

export interface IUseContainerSortActionProps {
  entityName: string;
}

export const useContainerSortAction = ({ entityName }: IUseContainerSortActionProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);
  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const action = useMemo(
    () => (
      <Tooltip title={`${entityName}-Container sortieren`}>
        <span>
          <IconButton onClick={openDialog}>
            <SortIcon />
          </IconButton>
        </span>
      </Tooltip>
    ),
    [entityName, openDialog],
  );

  return {
    isContainerSortDialogOpen: isDialogOpen,
    closeContainerSortDialog: closeDialog,
    containerSortAction: action,
  };
};
