import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getOperationName } from 'apollo-link';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { IDataTableColumn, IDataTableRow } from '../../../components/DataTable/types';
import AppProgress from '../../../components/Page/AppProgress';
import { Search, useSearchState } from '../../../components/Search/Search';
import {
  CURRENT_PROJECT_QUERY,
  SET_CURRENT_PROJECT_MUTATION,
} from '../../components/Header/Header.queries';
import { useCurrentProject } from '../../components/Header/useCurrentProject';
import { MobileDataTable } from '../../components/MobileDataTable';
import { MOBILE_PROJECTS_QUERY } from './Project.queries';
import { mapToTableData } from './Project.utils';
import { MobileProjects as TMobileProjects, MobileProjectsVariables } from './types/MobileProjects';

const columns: IDataTableColumn[] = [
  {
    id: 'projectNumber',
    label: 'Projektnummer',
  },
  {
    id: 'projectName',
    label: 'Projektname',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  projectTextWrapper: {
    margin: theme.spacing(3, 1),
  },
  searchWrapper: {
    margin: theme.spacing(3, 0),
  },
}));

export const MobileProjects: React.FC = () => {
  const { data, error, refetch, loading } = useQuery<TMobileProjects, MobileProjectsVariables>(
    MOBILE_PROJECTS_QUERY,
  );

  const currentProject = useCurrentProject();

  const searchState = useSearchState();

  const [setCurrentProject, { error: mutationError, called }] = useMutation(
    SET_CURRENT_PROJECT_MUTATION,
  );

  if (currentProject && !called) {
    setCurrentProject({ variables: { data: null } });
  }

  const innerTableRows = useMemo(
    () => (data && !isEmpty(data) ? mapToTableData(data.projects) : []),
    [data],
  );

  const classes = useStyles();

  const history = useHistory();

  const onRowClick = async (row: IDataTableRow) => {
    const { projectNumber, projectName, id } = row.data;

    await setCurrentProject({
      variables: { data: { projectNumber, projectName, id, __typename: 'CurrentProject' } },
      refetchQueries: [getOperationName(CURRENT_PROJECT_QUERY) || ''],
    });

    history.push(`/mobile/projekte/${projectNumber}`);
  };

  if (error) {
    console.log(error);
    return null;
  }

  if (mutationError) {
    console.log(mutationError);
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <div className={classes.projectTextWrapper}>
        <Typography variant="h5">Projekt auswählen</Typography>
      </div>

      <div className={classes.searchWrapper}>
        <Search
          columns={['projectName', 'projectNumber']}
          searchState={searchState}
          onSubmit={(search) => refetch({ search })}
          loading={loading}
        />
      </div>

      <MobileDataTable
        innerTableRows={innerTableRows}
        options={{
          activeRowId: '',
          filterText: searchState.searchTerm,
          breakWord: true,
          levels: [
            {
              columns,
              onRowClick,
            },
          ],
        }}
      />
    </>
  );
};
