import gql from 'graphql-tag';

const JournalAccountingItemFieldsFragment = gql`
  fragment journalAccountingItemFields on AccountingItem {
    id
    chargeConstraint
    createdBy
    createdAt
    activityDate
    startTime
    endTime
    amount
    comment
    parent
    manual
    reportId
    creditedTo {
      id
      costPerUnit
      subsidiary {
        id
        name
      }
      workload
      type
      employee {
        id
        employeeNumber
        firstName
        lastName
      }
      material {
        id
        materialNumber
        nameOne
        nameTwo
        acronym
        unit {
          id
          acronym
        }
      }
      machine {
        id
        inventoryNumber
        nameOne
        nameTwo
      }
      vehicle {
        id
        inventoryNumber
        nameOne
        nameTwo
      }
      collectiveAccount {
        id
        nameOne
        nameTwo
      }
      mission {
        id
        name
        project {
          id
          projectNumber
          projectName
        }
      }
    }
    chargedTo {
      id
      costPerUnit
      subsidiary {
        id
        name
      }
      workload
      type
      employee {
        id
        employeeNumber
        firstName
        lastName
      }
      material {
        id
        materialNumber
        nameOne
        nameTwo
        acronym
        unit {
          id
          acronym
        }
      }
      machine {
        id
        inventoryNumber
        nameOne
        nameTwo
      }
      vehicle {
        id
        inventoryNumber
        nameOne
        nameTwo
      }
      collectiveAccount {
        id
        nameOne
        nameTwo
      }
      mission {
        id
        name
        project {
          id
          projectNumber
          projectName
        }
      }
    }
  }
`;

export const GET_ACCOUNTING_LOG_JOURNAL = gql`
  query GetAccountingLogJournal(
    $where: AccountingItemWhereFlatInput
    $page: PageInput
    $orderBy: OrderByInput
  ) {
    accountingItemsWithStats(where: $where, page: $page, orderBy: $orderBy) {
      accountingItems {
        ...journalAccountingItemFields
      }
      totalNumberRows
    }
  }
  ${JournalAccountingItemFieldsFragment}
`;
