import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Maybe } from 'graphql/jsutils/Maybe';
import { isNil } from 'lodash';
import React, { FC } from 'react';
import { useProjectNumber } from '../../hooks/useProjectNumber';
import { UpsertAddressBookItemType } from '../../types/graphql';
import { AddressEditForm } from './addressEdit';
import { AddressSearchSection } from './addressSearch';
import { ContactDropdown } from './contactEdit';
import { ContactEditForm } from './contactEdit/form';
import { AddressHeader, AddressHeaderTitle } from './header';
import { InheritButton } from './inheritButton';
import { AddressPreview } from './preview';
import { FullAddressBookItem } from './types/FullAddressBookItem';
import { GetAddressBook_addressBook } from './types/GetAddressBook';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingBottom: theme.spacing(3),
  },
  dropDownContainer: {
    paddingBottom: theme.spacing(3),
  },
  editContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  searchContainer: {
    padding: theme.spacing(0, 0, 3, 0),
  },
}));

type Header = AddressHeaderTitle.BILLING_ADDRESS | AddressHeaderTitle.SHIPPING_ADDRESS;
interface IProps {
  header: Header;
  addressBook: Maybe<GetAddressBook_addressBook>;
  addressBookItem: Maybe<FullAddressBookItem>;
  isShippingDifferent?: Maybe<boolean>;
}

export const Address: FC<IProps> = ({
  header,
  addressBook,
  addressBookItem,
  isShippingDifferent,
}) => {
  const projectNumber = useProjectNumber();
  if (!projectNumber) {
    throw new Error();
  }

  const type =
    header === AddressHeaderTitle.BILLING_ADDRESS
      ? UpsertAddressBookItemType.BILLING
      : UpsertAddressBookItemType.SHIPPING;

  const classes = useStyles();
  const contacts = addressBookItem?.address?.contactPersons ?? [];
  const activeContact = addressBookItem?.contactPerson;

  const disabledProp = !isNil(isShippingDifferent) ? { disabled: !isShippingDifferent } : {};
  const addressBookItemId = addressBookItem ? parseInt(addressBookItem.id, 10) : undefined;
  const isShipping = type === UpsertAddressBookItemType.SHIPPING;

  return (
    <>
      <Grid container item xs={12}>
        <Grid
          item
          container
          xs={12}
          spacing={1}
          alignItems="center"
          classes={{ root: classes.header }}
        >
          <Grid item>
            <AddressHeader title={header} addressBookItemId={addressBookItemId} />
          </Grid>
          {isShipping && (
            <Grid item>
              <InheritButton
                projectNumber={projectNumber}
                billingAddressBookItemId={addressBook?.billingAddress?.id}
                shippingAddressBookItemId={addressBook?.shippingAddress?.id}
                isShippingDifferent={isShippingDifferent}
              />
            </Grid>
          )}
        </Grid>
        {(!isShipping || isShippingDifferent) && (
          <>
            <Grid item xs={12} classes={{ root: classes.searchContainer }}>
              <AddressSearchSection type={type} />
            </Grid>
            <Grid item xs={12}>
              <AddressPreview addressBookItem={addressBookItem} />
            </Grid>
            <Grid container item xs={12} classes={{ root: classes.editContainer }}>
              <AddressEditForm
                address={addressBookItem?.address}
                addressBookItemId={addressBookItemId}
                addressBookItemType={type}
                {...disabledProp}
              />
            </Grid>
            <Grid item xs={12} classes={{ root: classes.dropDownContainer }}>
              <ContactDropdown
                addressBookId={addressBookItem?.id}
                addressBookItemAddress={addressBookItem?.address}
                contacts={contacts}
                activeContact={activeContact}
                {...disabledProp}
              />
              <ContactEditForm
                activeContact={activeContact}
                address={addressBookItem?.address}
                addressBookItemId={addressBookItemId}
                disabled={disabledProp.disabled || isNil(addressBookItem?.address)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
