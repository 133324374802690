import { IDataTableRow } from '../../DataTable/types';

export const NEW_ROW_ID = '__new__';
export const EMPTY_ROW = {
  id: NEW_ROW_ID,
  data: { projectNumber: '', name: '', volume: 0, appliedPrice: 0 },
};

/** Adds a new empty row under the given container row */
export const addNewRow = (
  mappedData: IDataTableRow[],
  addNewContainerRowId: string,
  data?: any,
): IDataTableRow[] =>
  mappedData.map((row) => {
    return {
      ...row,
      innerTableRows:
        row.id === addNewContainerRowId
          ? [...(row.innerTableRows || []), { ...EMPTY_ROW, data: { ...EMPTY_ROW.data, ...data } }]
          : row.innerTableRows,
      containerRows: row.containerRows
        ? addNewRow(row.containerRows, addNewContainerRowId, data)
        : [],
    };
  });

export const findRow = (rowId: string, tableData: IDataTableRow[]): IDataTableRow | undefined =>
  tableData.find((row) => row.id === rowId) ||
  tableData
    .map((row) => row.containerRows && findRow(rowId, row.containerRows))
    .find((row) => !!row) ||
  tableData
    .map((row) => row.innerTableRows && findRow(rowId, row.innerTableRows))
    .find((row) => !!row);

export const isEditRow = (row: IDataTableRow, editRowId: string) =>
  row.id !== NEW_ROW_ID && editRowId === row.id;

export const isCreateRow = (row: IDataTableRow) => row.id === NEW_ROW_ID;
