import React, { useMemo } from 'react';
import { AccountingLogJournalTable } from '../AccountingLogJournalTable';
import { GetAccountingActivityTypes } from '../AccountingLogger/types/GetAccountingActivityTypes';
import { Button } from '@material-ui/core';
import { Section, Heading } from '../Section';
import { GetAccountingLogJournal_accountingItemsWithStats_accountingItems } from '../AccountingLogJournal/types/GetAccountingLogJournal';
import { useQuery } from 'react-apollo';
import { GET_ACCOUNTING_ACTIVITY_TYPES } from '../AccountingLogger/AccountingLogger.queries';
import { orEmpty } from '../AccountingLogger/utils/orEmpty';
import AppProgress from '../../../components/Page/AppProgress';
import AppErrorMessage from '../../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import { makeStyles } from '@material-ui/styles';
import { IDataTableRow } from '../../../components/DataTable/types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

const useStyles = makeStyles(() => ({
  saveButton: {
    marginTop: '1em',
  },
}));

interface IPreviewProps {
  accountingItems: ReadonlyArray<
    Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems>
  >;
  onSave: () => void;
  onDelete: (idx: number) => void;
  onEdit: (idx: number) => void;
  disabled?: boolean;
}

/**
 * The Preview table for manual accounting
 * @param accountingItems the preview accounting items to display, id is used for indexing
 * @param onSave trigger a save function
 * @param onDelete delete an index from the preview items
 * @param onEdit signal editing of an index from the preview items
 * @param disabled is the preview disabled?
 * @constructor
 */
export const Preview: React.FC<IPreviewProps> = ({
  accountingItems,
  onSave,
  onDelete,
  onEdit,
  disabled: propDisabled = false,
}) => {
  const styles = useStyles();
  const activityTypes = useQuery<GetAccountingActivityTypes>(GET_ACCOUNTING_ACTIVITY_TYPES);

  const disabled = propDisabled || activityTypes.loading;
  const canSave = !disabled && accountingItems.length > 0;

  const rowActions = useMemo(
    () =>
      ({ row }: { row: IDataTableRow }) => {
        return (
          <>
            <Tooltip title="Eintrag bearbeiten">
              <span>
                <IconButton
                  aria-label="bearbeiten"
                  disabled={disabled}
                  onClick={() => onEdit(Number(row.id))}
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Eintrag löschen">
              <span>
                <IconButton
                  aria-label="löschen"
                  disabled={disabled}
                  onClick={() => onDelete(Number(row.id))}
                >
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          </>
        );
      },
    [disabled, onEdit, onDelete],
  );
  return (
    <Section>
      {activityTypes.loading ? <AppProgress /> : null}
      {activityTypes.error && (
        <AppErrorMessage message={errorPrefixRemover(activityTypes.error.message)} />
      )}
      <Heading>Vorschau</Heading>
      <AccountingLogJournalTable
        tableName="ALL_ACCOUNTING_LOG_ITEMS_PREVIEW"
        activityTypes={orEmpty(activityTypes.data?.activityTypes)}
        accountingItems={accountingItems}
        totalNumberRows={accountingItems.length}
        rowActions={rowActions}
      />
      <Button
        disabled={!canSave}
        onClick={canSave ? onSave : undefined}
        variant="contained"
        color="primary"
        size="large"
        className={styles.saveButton}
      >
        Speichern
      </Button>
    </Section>
  );
};
