import React from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FormikTextField from '../Form/FormikTextField';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import { makeStyles } from '@material-ui/styles';
import { omit } from 'lodash';

interface IItemBoilerplateFormProps {
  initialValues?: any;
  onSubmit: (values: any) => Promise<any>;
  onClose: () => void;
}

const useStyles = makeStyles({
  toolBar: {
    justifyContent: 'space-between',
  },
});

const mapValues = (values: any) => ({
  ...omit(values, ['__typename', 'id']),
  threshhold: parseFloat(values.threshhold),
  pricePerUnit: parseFloat(values.pricePerUnit),
});

const ScaleDiscountFormDialog: React.FunctionComponent<IItemBoilerplateFormProps> = ({
  initialValues,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const handleSubmit = (values: any, { setSubmitting }: any) => {
    onSubmit(mapValues(values)).then(() => {
      setSubmitting(false);
      onClose();
    });
  };
  return (
    <Dialog open={true} onClose={onClose}>
      <Toolbar className={classes.toolBar}>
        <DialogTitle>Staffelrabatt</DialogTitle>
        <Tooltip title="Schliessen">
          <IconButton onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <DialogContent>
        <Formik
          initialValues={initialValues || { pricePerUnit: 0.0, threshhold: 0 }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              <Field label="Menge" name="threshhold" component={FormikTextField} type="number" />
              <Field label="Preis / Einheit" name="pricePerUnit" component={FormikTextField} />
              <DialogActions>
                <CancelSaveButtons isDisabled={isSubmitting || !dirty} />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ScaleDiscountFormDialog;
