import { GetAccountingActivityTypes_activityTypes } from '../../../AccountingLogger/types/GetAccountingActivityTypes';
import { GetAccountingLogJournal_accountingItemsWithStats_accountingItems } from '../../../AccountingLogJournal/types/GetAccountingLogJournal';
import { IManualAccountingFormValues } from '../DataEntryForm';
import { parseChargeConstraint } from '../../../utils/chargeConstraint';
import {
  searchableActivityType,
  searchableCollectiveAccount,
  searchableEmployee,
  searchableMachine,
  searchableMaterial,
  searchableMission,
  searchableVehicle,
} from '../../../utils/searchable';
import { clipString, SomeEntity } from './mapFormToAccountingItem';

/**
 * convert an accounting item into form values for editing
 * @param activityTypes the possible activity types to be mapped
 * @param item the accounting item
 * @returns form values
 * @see IManualAccountingFormValues
 */
export const mapAccountingItemToForm = (
  activityTypes: readonly GetAccountingActivityTypes_activityTypes[],
  item: Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems>,
): IManualAccountingFormValues => {
  const { activityType: activityTypeNumber } = parseChargeConstraint(item.chargeConstraint);
  const activityType = activityTypes.find(
    ({ number: existingActivityTypeNumber }) => existingActivityTypeNumber === activityTypeNumber,
  );
  if (activityType == null) {
    throw new Error(
      'invalid activity type for charge constraint: ' + JSON.stringify(item.chargeConstraint),
    );
  }
  const potentialEntities = [
    ['employee', searchableEmployee],
    ['material', searchableMaterial],
    ['machine', searchableMachine],
    ['vehicle', searchableVehicle],
    ['collectiveAccount', searchableCollectiveAccount],
    ['mission', searchableMission],
  ] as ReadonlyArray<[keyof typeof item.creditedTo, (entity: SomeEntity) => string]>;
  let creditedTo = '';
  for (const [key, makeSearchable] of potentialEntities) {
    const entity = item.creditedTo[key] as SomeEntity | undefined;
    if (entity != null && typeof entity === 'object') {
      creditedTo = makeSearchable(entity);
      break;
    }
  }
  let chargedTo = '';
  for (const [key, makeSearchable] of potentialEntities) {
    const entity = item.chargedTo[key] as SomeEntity | undefined;
    if (entity != null && typeof entity === 'object') {
      chargedTo = makeSearchable(entity);
      break;
    }
  }
  return {
    parent: (item.parent ?? '').toString(),
    activityDate: item.activityDate,
    activityType: searchableActivityType(activityType),
    creditedTo,
    chargedTo,
    amount: item.amount ?? 0,
    comment: item.comment,
    // adding a space here fixes a rendering glitch in the label of the fields
    startTime: clipString(item.startTime) ?? ' ',
    endTime: clipString(item.endTime) ?? ' ',
    pricePerUnit: item.creditedTo.costPerUnit / 100,
  };
};
