import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme } from '@material-ui/core';
import Highlighter from 'react-highlight-words';
import { IDataTableRow, IDataTableColumn } from './types';
import { isString, defaultTo } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  highlight: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface IProps {
  row: IDataTableRow;
  column: IDataTableColumn;
  searchWords: string[];
}

const HighlightedCellContent: React.FC<IProps> = ({ row, column, searchWords }) => {
  const classes = useStyles();
  const cell = row.data[column.id];

  const customRender = column.render && column.render(cell, row);

  if (customRender && !isString(customRender)) {
    return customRender;
  }

  return (
    <Typography variant="body2">
      <Highlighter
        searchWords={searchWords || []}
        autoEscape={true}
        textToHighlight={customRender || defaultTo(cell, '').toString()}
        highlightClassName={classes.highlight}
      />
    </Typography>
  );
};

export default HighlightedCellContent;
