import { useMutation } from 'react-apollo';
import { COPY_ITEM_MUTATION } from './queries';
import { CopyItem, CopyItemVariables } from './types/CopyItem';

export const useCopyItem = () => {
  const [executeCopyItem, { loading, error }] = useMutation<CopyItem, CopyItemVariables>(
    COPY_ITEM_MUTATION,
  );

  return { executeCopyItem, loading, error };
};
