import gql from 'graphql-tag';
import {
  BILL_OF_QUANTITY_FRAGMENT,
  BOQ_STRUCTURE_FRAGMENT,
  ITEM_FRAGMENT,
  locationCompositeIdFields,
} from './utils/paginationHelper/queries';

export const CREATE_ITEM_MUTATION = gql`
  mutation CreateItemFromBoilerplate(
    $itemBoilerplateId: ID!
    $locationId: ID!
    $volume: Float!
    $appliedPrice: Float
    $freeText: String
  ) {
    createItemFromBoilerplate(
      itemBoilerplateId: $itemBoilerplateId
      locationId: $locationId
      volume: $volume
      appliedPrice: $appliedPrice
      freeText: $freeText
    ) {
      ...itemFields
    }
  }
  ${ITEM_FRAGMENT}
`;

export const CREATE_ITEMS_MUTATION = gql`
  mutation CreateItemsFromBoilerplates($data: [CreateItemFromBoilerplateInput!]!) {
    createItemsFromBoilerplates(data: $data)
  }
`;

export const CREATE_BILL_OF_QUANTITY_MUTATION = gql`
  mutation CreateBillOfQuantity(
    $projectNumber: String!
    $data: BillOfQuantityUpdateInput!
    $entityType: BillOfQuantityEntityType
  ) {
    createBillOfQuantity(projectNumber: $projectNumber, data: $data, entityType: $entityType) {
      ...boqStructureFields
      ...billOfQuantityFields
    }
  }
  ${BILL_OF_QUANTITY_FRAGMENT}
  ${BOQ_STRUCTURE_FRAGMENT}
`;

export const DELETE_ITEM_MUTATION = gql`
  mutation DeleteItem($id: ID!) {
    deleteItem(id: $id) {
      id
    }
  }
`;

export const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocation($name: String!, $parentLocationId: ID!) {
    createLocation(name: $name, parentLocationId: $parentLocationId) {
      id
      name
      canBeDeleted
      hasItemsOrLocations
      ...locationCompositeIdFields
      parentLocation {
        id
        parentLocation {
          id
        }
      }
    }
  }
  ${locationCompositeIdFields}
`;

export const DELETE_LOCATION_MUTATION = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(where: { id: $id }) {
      id
    }
  }
`;

export const REORDER_LOCATION_MUTATION = gql`
  mutation ReorderLocation(
    $billOfQuantityId: ID!
    $parentLocationId: ID!
    $locationId: ID!
    $index: Int!
  ) {
    reorderLocation(
      billOfQuantity: { id: $billOfQuantityId }
      parentLocation: { id: $parentLocationId }
      location: { id: $locationId }
      index: $index
    ) {
      id
    }
  }
`;
