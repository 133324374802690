import omit from 'lodash/fp/omit';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';
import mapValues from 'lodash/fp/mapValues';
import pick from 'lodash/fp/pick';
import { UpsertAccountingItem } from '../../../../types/graphql';

const keep = () => false;
/**
 * clean up accounting items and ready them to be sent to the api.
 * this will remove all instances of __typename props and will reduce entity objects to their {id: ...} version
 * @param item an accounting item
 * @param keepNull (optional) keep null values (default: false)
 * @returns cleaned up accounting item
 */
const removeTypenameAndEntitydata = <T extends { __typename?: string }>(
  item: T,
  keepNull: boolean = false,
): Omit<T, '__typename'> => {
  return item != null && typeof item === 'object'
    ? omit(
        ['__typename'],
        omitBy(
          keepNull ? keep : isNil,
          mapValues(removeTypenameAndEntitydata, 'id' in item ? pick(['id'], item) : item),
        ),
      )
    : (item as any);
};

/**
 * remove automatic fields created by the api and clean up the accounting item to be ready to send to the api
 * @param item the accounting item
 * @returns a cleaned up accounting item version ready to be sent to the api
 * @see removeTypenameAndEntitydata
 */
export const cleanupPreviewItem = (item: any): UpsertAccountingItem => {
  const removedAutoFields = omit(
    ['id', 'reportId', 'creditedTo.id', 'chargedTo.id', 'createdAt', 'createdBy', 'manual'],
    item,
  );
  return removeTypenameAndEntitydata(removedAutoFields, true) as UpsertAccountingItem;
};
