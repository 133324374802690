import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { FC, useCallback } from 'react';
import { useProjectNumber } from '../../../hooks/useProjectNumber';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import FormikTextField from '../../Form/FormikTextField';
import AppErrorMessage from '../../Page/AppErrorMessage';
import AppProgress from '../../Page/AppProgress';
import { AddressHeader, AddressHeaderTitle } from '../header';
import { useProjectAddressQuery } from './fetch';

interface IProps {
  projectAddress?: Maybe<string>;
}

export const ProjectAddress: FC<IProps> = ({ projectAddress }) => {
  const projectNumber = useProjectNumber();
  if (!projectNumber) {
    throw new Error();
  }

  const { loading, error, executeUpdateProjectAddress } = useProjectAddressQuery();

  const handleSubmit = useCallback(
    async (values) => {
      if (values.projectAddress === projectAddress) {
        return;
      }

      return executeUpdateProjectAddress({
        variables: { projectNumber, projectAddress: values.projectAddress },
      });
    },
    [projectAddress, projectNumber, executeUpdateProjectAddress],
  );

  return (
    <>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <Grid container>
        <Grid item xs={12}>
          <AddressHeader title={AddressHeaderTitle.PROJECT_ADDRESS} addressBookItemId={null} />
        </Grid>
        <Grid item xs={12}>
          <Formik enableReinitialize initialValues={{ projectAddress }} onSubmit={handleSubmit}>
            {({ submitForm }) => (
              <Form>
                <Field
                  multiline
                  variant="outlined"
                  component={FormikTextField}
                  name="projectAddress"
                  onBlur={submitForm}
                  rows={15}
                />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};
