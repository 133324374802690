import { ApolloClient } from 'apollo-client';
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, DocumentNode, Observable, Operation } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import RememberMeStorage from '../utils/RememberMeStorage';
import gql from 'graphql-tag';
import { ApolloCache, DataProxy } from 'apollo-cache';
import { GetPageInfos } from './types/GetPageInfos';
import {
  AddRowToDataTableInput,
  ClientPageInfoInput,
  DataTableInput,
  DataTableRowWhereUniqueInput,
  DataTableWhereUniqueInput,
  RowType,
  TableType,
  UpdateDataTableRowInput,
  UpdateDataTableRowsInput,
} from '../types/graphql';
import { deepCopy } from '../utils/deepCopy';
import {
  GetDataTables,
  GetDataTables_dataTables,
  GetDataTables_dataTables_rows,
} from './types/GetDataTables';
import {
  GetDataTable,
  GetDataTable_dataTable_rows_containerRows,
  GetDataTable_dataTable_rows_containerRows_containerRows,
  GetDataTable_dataTable_rows_containerRows_innerTableRows,
  GetDataTable_dataTable_rows_innerTableRows,
  GetDataTableVariables,
} from './types/GetDataTable';
import { isEmpty, isNil } from 'lodash';
import { getAllRows } from '../utils/paginationHelpers/getAllRows';
import { isNotNil } from '../utils/isNotNil';

export type ContainerRows = Array<
  | GetDataTable_dataTable_rows_containerRows
  | GetDataTable_dataTable_rows_containerRows_containerRows
>;

export const ALL_TABLE_TYPES = [
  TableType.OFFER,
  TableType.ORDER,
  TableType.ORDER_MISSION,
  TableType.ORDER_MEASUREMENT,
  TableType.MISSION,
  TableType.MEASUREMENT,
  TableType.BILL_MISSION,
  TableType.BILL,
];

export const mutateQueryCacheEntry = <QueryResult, QueryVariables>(
  cache: DataProxy,
  query: DocumentNode,
  variables: QueryVariables,
  mutator: (cacheEntry: QueryResult) => void,
): void => {
  const cacheEntry = cache.readQuery<QueryResult, QueryVariables>({ query, variables })!;
  mutator(cacheEntry);
  cache.writeQuery<QueryResult, QueryVariables>({ query, variables, data: cacheEntry });
};

const cache = new InMemoryCache({
  addTypename: true,
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case 'MissionItemLocation':
        return null;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

const defaultStore = {
  navigation: {
    __typename: 'Navigation',
    sidebarIsOpen: true,
  },
  auth: {
    __typename: 'Auth',
    isLoggedIn: false,
    token: null,
    role: null,
    name: null,
  },
  selectedSubsidiary: 'all',
  currentProject: {
    __typename: 'CurrentProject',
    id: '',
    projectName: '',
    projectNumber: '',
  },
  currentColumnsSetting: {
    __typename: 'CurrentColumnsSetting',
    id: null,
    name: null,
    tableSettings: null,
  },
};

export const typeDefs = gql`
  extend type Query {
    auth: ClientAuth!
    navigation: ClientNavigation!
    selectedSubsidiary: String!
    currentProject: ClientProject!
    currentColumnsSetting: ClientColumnsSetting
    tableSetting(where: TableSettingWhereUniqueInput!): TableSetting
    dataTable(where: DataTableWhereUniqueInput!): DataTable
    dataTables: [DataTable!]!
    dataTableRow(where: DataTableRowWhereUniqueInput!): DataTableRow
    pageInfo(where: ClientPageInfoWhereUniqueInput!): ClientPageInfo
    pageInfos: [ClientPageInfo!]!
    containerSortMapping(projectNumber: String!, tableType: TableType!): ContainerSortMapping
  }
  type ClientAuth {
    isLoggedIn: Boolean!
    token: String
    role: Role
    name: String
  }
  type ClientNavigation {
    sidebarIsOpen: Boolean!
  }
  type ClientProject {
    id: ID!
    projectNumber: String!
    projectName: String!
  }
  enum TableType {
    ORDER
    ORDER_MISSION
    ORDER_MEASUREMENT
    OFFER
    BILL
    MISSION
    MEASUREMENT
    BILL_MISSION
  }
  type ClientPageInfo {
    """
    Parent row id
    """
    id: ID!
    hasNextPage: Boolean!
  }
  type DataTableRow {
    __typename: String
    id: ID!
    hidden: Boolean
    data: String! # JSON
    innerTableRows: [DataTableRow!]
    containerRows: [DataTableRow!]
  }
  type DataTable {
    __typename: String
    id: TableType!
    rows: [DataTableRow!]!
  }
  type ContainerSortMapping {
    orderBy: String!
    direction: SortDirection
  }
  enum SortDirection {
    asc
    desc
  }
  input DataTableWhereUniqueInput {
    id: TableType!
  }

  input ClientPageInfoWhereUniqueInput {
    """
    Parent row id
    """
    id: ID!
  }

  extend type Mutation {
    setAuth(auth: ClientAuthInput!, remember: Boolean!): Boolean
    setCurrentProject(data: ClientProjectInput): Boolean
    setCurrentColumnsSetting(columnsSetting: ClientColumnsSettingInput!): ClientColumnsSetting
    logout: Boolean
    setSelectedSubsidiary: Boolean
    toggleSidebar: Boolean
    setPageInfo(data: ClientPageInfoInput!): ClientPageInfo!
    updateDataTable(data: DataTableInput!, where: DataTableWhereUniqueInput!): DataTable
    addRowToDataTable(
      where: DataTableWhereUniqueInput!
      data: [AddRowToDataTableInput!]!
    ): DataTable
    updateDataTableRow(
      data: UpdateDataTableRowInput!
      where: DataTableRowWhereUniqueInput!
    ): DataTableRow!
    updateDataTableRows(
      data: [UpdateDataTableRowsInput!]!
      where: DataTableWhereUniqueInput!
    ): DataTable!
    removeDataTableRow(where: DataTableRowWhereUniqueInput!): DataTableRow!
    clearDataTables: Boolean
  }

  type ClientColumnsSetting {
    id: ID
    name: String
    tableSettings: [TableSetting]
  }

  enum RowType {
    INNER
    CONTAINER
  }
  input AddRowToDataTableInput {
    containerId: String
    type: RowType!
    row: DataTableRowInput!
  }
  input UpdateDataTableRowInput {
    data: String
    hidden: Boolean
    partial: Boolean
    innerTableRows: [DataTableRowInput!]
  }
  input UpdateDataTableRowsInput {
    data: UpdateDataTableRowInput!
    where: DataTableRowWhereUniqueInput!
  }

  input DataTableInput {
    rows: [DataTableRowInput!]!
  }

  input DataTableRowInput {
    __typename: String = "DataTableRow"
    id: ID!
    hidden: Boolean = false
    data: String!
    containerRows: [DataTableRowInput!]
    innerTableRows: [DataTableRowInput!]
  }
  input DataTableRowWhereUniqueInput {
    id: ID!
    tableType: TableType!
  }
  input DataTableRowWhereInput {
    id_in: [ID!]
    tableType: TableType!
  }

  input TableSettingWhereUniqueInput {
    id: ID
  }
  input TableSettingInput {
    __typename: String
    id: ID
    tableName: String
    shownColumnIds: [String!]
  }
  input ClientColumnsSettingInput {
    __typename: String
    name: String
    id: ID
  }
  input ClientAuthInput {
    token: String
    role: Role
    name: String
  }
  input ClientProjectInput {
    id: ID!
    projectNumber: String!
    projectName: String!
  }
  input ClientPageInfoInput {
    id: ID!
    hasNextPage: Boolean!
  }
`;

const rememberMeStorage = new RememberMeStorage('__momo_storage', defaultStore);

cache.writeData({
  data: {
    ...rememberMeStorage.getEntry(),
    dataTables: [
      {
        __typename: 'DataTable',
        id: 'BILL',
        rows: [],
      },
      {
        __typename: 'DataTable',
        id: 'OFFER',
        rows: [],
      },
      {
        __typename: 'DataTable',
        id: 'ORDER',
        rows: [],
      },
      {
        __typename: 'DataTable',
        id: 'ORDER_MISSION',
        rows: [],
      },
      {
        __typename: 'DataTable',
        id: 'ORDER_MEASUREMENT',
        rows: [],
      },
      {
        __typename: 'DataTable',
        id: 'MISSION',
        rows: [],
      },
      {
        __typename: 'DataTable',
        id: 'MEASUREMENT',
        rows: [],
      },
      {
        __typename: 'DataTable',
        id: 'BILL_MISSION',
        rows: [],
      },
    ],
    'dataTable({"where":{"id":"OFFER"}})': {
      __typename: 'DataTable',
      id: 'OFFER',
      rows: [],
    },
    'dataTable({"where":{"id":"ORDER"}})': {
      __typename: 'DataTable',
      id: 'ORDER',
      rows: [],
    },
    'dataTable({"where":{"id":"ORDER_MEASUREMENT"}})': {
      __typename: 'DataTable',
      id: 'ORDER_MEASUREMENT',
      rows: [],
    },
    'dataTable({"where":{"id":"ORDER_MISSION"}})': {
      __typename: 'DataTable',
      id: 'ORDER_MISSION',
      rows: [],
    },
    'dataTable({"where":{"id":"MEASUREMENT"}})': {
      __typename: 'DataTable',
      id: 'MEASUREMENT',
      rows: [],
    },
    'dataTable({"where":{"id":"MISSION"}})': {
      __typename: 'DataTable',
      id: 'MISSION',
      rows: [],
    },
    'dataTable({"where":{"id":"BILL_MISSION"}})': {
      __typename: 'DataTable',
      id: 'BILL_MISSION',
      rows: [],
    },
    pageInfos: [],
  },
});

// "ejected" from apollo-boost because its badly documented migration  is based on
//  https://www.apollographql.com/docs/react/advanced/boost-migration.html

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      console.log('Query: ', operation.query);
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const GET_AUTH = gql`
  query GetAuth {
    auth @client(always: true) {
      token
      role
    }
  }
`;

export const GET_SIDEBAR_IS_OPEN = gql`
  query GetSidebarIsOpen {
    navigation @client {
      sidebarIsOpen
    }
  }
`;

export const GET_TABLE_SETTING = gql`
  query GetTableSettings {
    currentColumnsSetting @client {
      id
      tableSettings {
        id
        shownColumnIds
      }
    }
  }
`;

export const DATA_TABLE_FRAGMENT = gql`
  fragment fullDataTableFields on DataTable {
    id
    rows {
      id
      hidden
      data
      innerTableRows {
        id
        hidden
        data
      }
      containerRows {
        id
        hidden
        data
        innerTableRows {
          id
          hidden
          data
        }
        containerRows {
          id
          hidden
          data
          innerTableRows {
            id
            hidden
            data
          }
        }
      }
    }
  }
`;

export const GET_DATA_TABLES = gql`
  query GetDataTables {
    dataTables @client {
      ...fullDataTableFields
    }
  }
  ${DATA_TABLE_FRAGMENT}
`;

export const GET_DATA_TABLE = gql`
  query GetDataTable($id: TableType!) {
    dataTable(where: { id: $id }) @client {
      ...fullDataTableFields
    }
  }
  ${DATA_TABLE_FRAGMENT}
`;

export const GET_PAGE_INFOS = gql`
  query GetPageInfos {
    pageInfos @client {
      id
      hasNextPage
    }
  }
`;

export const SET_PAGE_INFO = gql`
  mutation SetPageInfo($data: ClientPageInfoInput!) {
    setPageInfo(data: $data) @client {
      id
      hasNextPage
    }
  }
`;

export const UPDATE_DATA_TABLE = gql`
  mutation UpdateDataTable($data: DataTableInput!, $where: DataTableWhereUniqueInput!) {
    updateDataTable(data: $data, where: $where) @client {
      id
      rows {
        id
      }
    }
  }
`;

export const ADD_ROW_TO_DATA_TABLE = gql`
  mutation AddRowToDataTable(
    $data: [AddRowToDataTableInput!]!
    $where: DataTableWhereUniqueInput!
  ) {
    addRowToDataTable(data: $data, where: $where) @client {
      id
      rows {
        id
      }
    }
  }
`;

export const UPDATE_DATA_TABLE_ROW = gql`
  mutation UpdateDataTableRow(
    $data: UpdateDataTableRowInput!
    $where: DataTableRowWhereUniqueInput!
  ) {
    updateDataTableRow(data: $data, where: $where) @client {
      id
    }
  }
`;

export const REMOVE_DATA_TABLE_ROW = gql`
  mutation RemoveDataTableRow($where: DataTableRowWhereUniqueInput!) {
    removeDataTableRow(where: $where) @client {
      id
    }
  }
`;

export const UPDATE_DATA_TABLE_ROWS = gql`
  mutation UpdateDataTableRows(
    $data: [UpdateDataTableRowsInput!]!
    $where: DataTableWhereUniqueInput!
  ) {
    updateDataTableRows(where: $where, data: $data) @client {
      id
    }
  }
`;

export const SIMPLE_DATA_TABLE_ROW_FRAGMENT = gql`
  fragment simpleDataTableRowFields on DataTableRow @client {
    id
    data
    hidden
  }
`;

export const CLEAR_DATA_TABLES = gql`
  mutation ClearDataTables {
    clearDataTables @client
  }
`;

const isValidToInclude = (row: { hidden: boolean | null }) => {
  return row.hidden !== true;
};

export const filterRows = (dataTable: GetDataTables_dataTables): GetDataTables_dataTables => {
  return {
    ...dataTable,
    rows: dataTable.rows.filter(isValidToInclude).map((row) => {
      row.innerTableRows = row.innerTableRows?.filter(isValidToInclude) ?? [];

      row.containerRows =
        row.containerRows?.filter(isValidToInclude).map((row) => {
          row.innerTableRows = row.innerTableRows?.filter(isValidToInclude) ?? [];
          row.containerRows =
            row.containerRows?.filter(isValidToInclude).map((row) => {
              row.innerTableRows = row.innerTableRows?.filter(isValidToInclude) ?? [];

              return row;
            }) ?? [];

          return row;
        }) ?? [];

      return row;
    }),
  };
};

const request = async (operation: Operation) => {
  const data = client.readQuery({ query: GET_AUTH });
  if (data.auth && data.auth.token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${data.auth.token}`,
      },
    });
  }
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then((operation) => request(operation))
        .then(() => {
          handle =
            forward &&
            forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    }),
);

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_PREFIX || '/api',
  credentials: 'same-origin',
});

interface IContext {
  cache: ApolloCache<any>;
}

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, requestLink, uploadLink]),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
  resolvers: {
    Query: {
      tableSetting(parent, args, { client }) {
        const response = client.readQuery({
          query: GET_TABLE_SETTING,
        });

        if (!response || !response.currentColumnsSetting.tableSettings) {
          return null;
        }

        const tableSetting = response.currentColumnsSetting.tableSettings.find(
          (tableSetting: any) => tableSetting.id === args.where.id,
        );

        return tableSetting;
      },
      dataTable(parent: undefined, { where: { id } }, { cache }: IContext) {
        const res = cache.readQuery<GetDataTables>({
          query: GET_DATA_TABLES,
        });

        if (!res) {
          return null;
        }

        const dataTable = res.dataTables.find((dataTable) => dataTable.id === id);

        if (!dataTable) {
          return null;
        }

        return dataTable;
      },
      dataTables(parent: undefined, argsany, { cache }: IContext) {
        const cacheResult = cache.readQuery<GetDataTables>({
          query: GET_DATA_TABLES,
        });

        if (!cacheResult) {
          return;
        }

        return cacheResult.dataTables;
      },
      dataTableRow(
        parent: undefined,
        { where }: { where: DataTableRowWhereUniqueInput },
        { cache }: IContext,
      ) {
        const cacheResult = cache.readQuery<GetDataTable, GetDataTableVariables>({
          query: GET_DATA_TABLE,
          variables: { id: where.tableType },
        });
        if (!cacheResult?.dataTable) {
          return;
        }

        const allRows = getAllRows(cacheResult.dataTable);

        return allRows.find((row) => row.id === where.id);
      },
      pageInfo(
        parent: undefined,
        { where: { id } }: { where: { id: string } },
        { cache }: IContext,
      ) {
        const res = cache.readQuery<GetPageInfos>({ query: GET_PAGE_INFOS });

        if (!res) {
          return null;
        }

        return res.pageInfos.find((pageInfo) => pageInfo.id === id);
      },
      pageInfos(parent: undefined, argsany, { cache }: IContext) {
        const pageInfos = cache.readQuery<GetPageInfos>({ query: GET_PAGE_INFOS });

        return pageInfos;
      },
    },
    Mutation: {
      setAuth(parent, args, { cache }) {
        const auth = {
          ...args.auth,
          isLoggedIn: Boolean(args.auth.token),
        };
        cache.writeData({ data: { auth } });

        rememberMeStorage.update({ auth }, args.remember);
      },
      async logout(parent, args, { client }) {
        rememberMeStorage.remove();
        await client.resetStore();
      },
      setSelectedSubsidiary(parent, args, { cache }) {
        cache.writeData({ data: { selectedSubsidiary: args.selectedSubsidiary } });
      },
      toggleSidebar(parent, args, { client }) {
        const data = client.readQuery({ query: GET_SIDEBAR_IS_OPEN });
        const navigation = { ...data.navigation, sidebarIsOpen: !data.navigation.sidebarIsOpen };
        client.writeQuery({
          data: { navigation },
          query: GET_SIDEBAR_IS_OPEN,
        });
        rememberMeStorage.update({ navigation });
      },
      setCurrentProject(parent, args, { cache }) {
        cache.writeData({ data: { currentProject: args.data } });
        rememberMeStorage.update({ currentProject: args.data });
      },
      setCurrentColumnsSetting(parent, args, { cache }) {
        cache.writeData({ data: { currentColumnsSetting: args.columnsSetting } });

        rememberMeStorage.update({ currentColumnsSetting: args.columnsSetting });
      },
      setPageInfo(parent: undefined, { data }: { data: ClientPageInfoInput }, { cache }: IContext) {
        const res = cache.readQuery<GetPageInfos>({ query: GET_PAGE_INFOS });

        if (!res) {
          return;
        }

        const pageInfos = deepCopy(res.pageInfos);

        const existingPageInfo = pageInfos.find((pageInfo) => pageInfo.id === data.id);

        if (existingPageInfo) {
          existingPageInfo.hasNextPage = data.hasNextPage;
        } else {
          pageInfos.push({ __typename: 'ClientPageInfo', ...data });
        }

        cache.writeQuery({
          query: GET_PAGE_INFOS,
          data: {
            pageInfos,
          },
        });

        return data;
      },
      updateDataTable(
        parent: undefined,
        { data, where }: { data: DataTableInput; where: DataTableWhereUniqueInput },
        { cache }: IContext,
      ) {
        const cacheResult = deepCopy(
          cache.readQuery<GetDataTable>({ query: GET_DATA_TABLE, variables: { id: where.id } }),
        );

        if (!cacheResult?.dataTable) {
          return;
        }

        const { dataTable: dataTableToUpdate } = cacheResult;

        dataTableToUpdate.rows = data.rows as typeof dataTableToUpdate.rows;

        cache.writeQuery({
          query: GET_DATA_TABLE,
          data: {
            dataTable: dataTableToUpdate,
          },
          variables: { id: where.id },
        });

        return dataTableToUpdate;
      },
      addRowToDataTable(
        parent: undefined,
        { data, where }: { data: AddRowToDataTableInput[]; where: DataTableWhereUniqueInput },
        { cache }: IContext,
      ) {
        const cacheRes = deepCopy(
          cache.readQuery<GetDataTable>({
            query: GET_DATA_TABLE,
            variables: {
              id: where.id,
            },
          }),
        );

        if (!cacheRes?.dataTable) {
          return;
        }

        const dataTableToAppendTo = cacheRes.dataTable;

        const allContainers = dataTableToAppendTo.rows.flatMap((row) => [
          row,
          ...(row.containerRows?.flatMap((containerRow) => [
            containerRow,
            ...(containerRow.containerRows ?? []),
          ]) ?? []),
        ]);

        for (const input of data) {
          if (!input.containerId) {
            dataTableToAppendTo.rows.push(input.row as GetDataTables_dataTables_rows);

            continue;
          }

          const containerToAppendTo = allContainers.find(
            (container) => container.id === input.containerId,
          ) as GetDataTable_dataTable_rows_containerRows;

          if (!containerToAppendTo) {
            continue;
          }

          const accessor = input.type === RowType.INNER ? 'innerTableRows' : 'containerRows';

          containerToAppendTo[accessor]?.push(
            input.row as GetDataTable_dataTable_rows_containerRows_containerRows,
          );
          allContainers.push(input.row as GetDataTable_dataTable_rows_containerRows_containerRows);
        }

        cache.writeQuery({
          query: GET_DATA_TABLE,
          variables: { id: where.id },
          data: {
            dataTable: dataTableToAppendTo,
          },
        });

        return dataTableToAppendTo;
      },
      updateDataTableRow(
        parent: undefined,
        { data, where }: { data: UpdateDataTableRowInput; where: DataTableRowWhereUniqueInput },
        { cache }: IContext,
      ) {
        const cacheRes = deepCopy(
          cache.readQuery<GetDataTable>({
            query: GET_DATA_TABLE,
            variables: {
              id: where.tableType,
            },
          }),
        );

        if (!cacheRes?.dataTable) {
          return;
        }

        const dataTableRows = getAllRows(cacheRes.dataTable);

        const dataTableRowToUpdate = dataTableRows.find((row) => row.id === where.id);
        if (!dataTableRowToUpdate) {
          return;
        }

        if (!isNil(data.hidden)) {
          dataTableRowToUpdate.hidden = data.hidden;
        }

        if (!isNil(data.data)) {
          if (data.partial) {
            dataTableRowToUpdate.data = JSON.stringify(
              Object.assign(JSON.parse(dataTableRowToUpdate.data), JSON.parse(data.data)),
            );
          } else {
            dataTableRowToUpdate.data = data.data;
          }
        }

        if (
          data.innerTableRows &&
          !isEmpty(data.innerTableRows) &&
          'innerTableRows' in dataTableRowToUpdate
        ) {
          dataTableRowToUpdate.innerTableRows =
            data.innerTableRows as GetDataTable_dataTable_rows_innerTableRows[];
        }

        cache.writeQuery({
          query: GET_DATA_TABLE,
          data: {
            dataTable: cacheRes.dataTable,
          },
          variables: {
            id: where.tableType,
          },
        });

        return dataTableRowToUpdate;
      },
      updateDataTableRows(
        parent: undefined,
        { where, data }: { where: DataTableWhereUniqueInput; data: UpdateDataTableRowsInput[] },
        { cache }: IContext,
      ) {
        const cacheRes = deepCopy(
          cache.readQuery<GetDataTable, GetDataTableVariables>({
            query: GET_DATA_TABLE,
            variables: {
              id: where.id,
            },
          }),
        );

        if (!cacheRes?.dataTable) {
          return;
        }

        const allRows = getAllRows(cacheRes.dataTable);

        data.forEach(({ where, data: { data, hidden, partial, innerTableRows } }) => {
          const rowToUpdate = allRows.find((row) => row.id === where.id);

          if (!rowToUpdate) {
            return;
          }

          if (data) {
            if (partial) {
              rowToUpdate.data = JSON.stringify(
                Object.assign(JSON.parse(rowToUpdate.data), JSON.parse(data)),
              );
            } else {
              rowToUpdate.data = data;
            }
          }

          if (!isNil(hidden)) {
            rowToUpdate.hidden = hidden;
          }

          if (innerTableRows && !isEmpty(innerTableRows) && 'innerTableRows' in rowToUpdate) {
            rowToUpdate.innerTableRows =
              innerTableRows as GetDataTable_dataTable_rows_innerTableRows[];
          }
        });

        cache.writeQuery({
          query: GET_DATA_TABLE,
          variables: { id: where.id },
          data: {
            dataTable: cacheRes.dataTable,
          },
        });

        return cacheRes.dataTable;
      },
      removeDataTableRow(
        parent: undefined,
        { where }: { where: DataTableRowWhereUniqueInput },
        { cache }: IContext,
      ) {
        const cacheRes = deepCopy(
          cache.readQuery<GetDataTable, GetDataTableVariables>({
            query: GET_DATA_TABLE,
            variables: { id: where.tableType },
          }),
        );

        if (!cacheRes?.dataTable) {
          return;
        }

        let isRowFound = false;

        const mapRow = (
          row:
            | GetDataTable_dataTable_rows_containerRows
            | GetDataTable_dataTable_rows_containerRows_innerTableRows,
        ): GetDataTable_dataTable_rows_containerRows | undefined => {
          if (isRowFound) {
            return {
              containerRows: [],
              innerTableRows: [],
              ...row,
            };
          }

          if (row.id === where.id) {
            isRowFound = true;

            return;
          }

          return {
            ...row,
            innerTableRows:
              'innerTableRows' in row ? row.innerTableRows?.map(mapRow).filter(isNotNil) ?? [] : [],
            containerRows:
              'containerRows' in row ? row.containerRows?.map(mapRow).filter(isNotNil) ?? [] : [],
          };
        };
        const filteredRows = cacheRes.dataTable.rows.map(mapRow).filter(isNotNil);

        cache.writeQuery({
          query: GET_DATA_TABLE,
          variables: { id: where.tableType },
          data: {
            dataTable: {
              ...cacheRes.dataTable,
              rows: filteredRows,
            },
          },
        });
      },
      clearDataTables(parent: undefined, argsany, { cache }: IContext) {
        ALL_TABLE_TYPES.forEach((tableTypeToClear) => {
          cache.writeData({
            id: `DataTable:${tableTypeToClear}`,
            data: {
              __typename: 'DataTable',
              id: tableTypeToClear,
              rows: [],
            },
          });
        });
      },
    },
  },
  typeDefs,
});

client.onResetStore(() => {
  return Promise.resolve(cache.writeData({ data: defaultStore }));
});

export default client;
