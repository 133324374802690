import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import { HotKeys } from 'react-hotkeys';
import CloseIconButton from './CloseIconButton';

export interface IDialogCloseableProps extends DialogProps {
  title: string;
  fullHeight?: boolean;
  onClose?: (...args: any[]) => void;
  contentClassName?: string;
  mobile?: boolean;
}

const useStyles = makeStyles(() => ({
  dialogPaperFullHeight: {
    height: '100%',
  },
  dialogTitleMobile: {
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}));

const DialogCloseable: React.FunctionComponent<IDialogCloseableProps> = ({
  children,
  title,
  fullHeight,
  onClose,
  contentClassName,
  mobile,
  ...dialogProps
}) => {
  const classes = useStyles();

  return (
    <Dialog
      {...dialogProps}
      classes={fullHeight ? { paper: classes.dialogPaperFullHeight } : undefined}
    >
      <div className={'popper-container'}>
        <HotKeys
          keyMap={{ closeDialog: 'ESC' }}
          handlers={{
            closeDialog: (e) => {
              if (e) {
                e.preventDefault();
              }
              if (onClose) {
                onClose();
              }
            },
          }}
        >
          <DialogTitle className={mobile ? classes.dialogTitleMobile : ''}>
            {title}
            <CloseIconButton onClose={onClose} mobile={mobile} />
          </DialogTitle>
          <DialogContent className={contentClassName}>{children}</DialogContent>
        </HotKeys>
      </div>
    </Dialog>
  );
};

/** A generic dialog with close button and ESC-key support */
export default DialogCloseable;
