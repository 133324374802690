import { IDataTableRow } from '../../../components/DataTable/types';
import { getLocationIdFromCompositeId } from '../../../components/DataTable/utils/container/selectContainerUtils';
import { flattenContainerRows } from '../../../utils/flattenRows/flattenRows';
import { IMissionLocationIdInput } from '../TabMissions/MissionSelector/paginationHelpers';

export default function getSelectedContainersToRefetch(
  selectedContainerIds: string[],
  tableData: IDataTableRow[],
): IMissionLocationIdInput[] {
  if (!(selectedContainerIds.length > 0)) {
    return [];
  }

  const flatLocations = flattenContainerRows(tableData);

  return flatLocations.reduce<IMissionLocationIdInput[]>((locations, location) => {
    if (selectedContainerIds.includes(location.id)) {
      const locationInput: IMissionLocationIdInput = {
        id: getLocationIdFromCompositeId(location.id),
      };

      if (location.data.parentLocation) {
        const parentLocationContainer = flatLocations.find((l) => {
          const id = getLocationIdFromCompositeId(l.id);
          return id === location.data.parentLocation.id;
        })!;
        const parentLocation = {
          id: getLocationIdFromCompositeId(parentLocationContainer.id),

          ...(parentLocationContainer.data.parentLocation && {
            parentLocation: { id: parentLocationContainer.data.parentLocation.id },
          }),
        };

        locationInput.parentLocation = parentLocation;
      }

      locations.push(locationInput);
    }

    return locations;
  }, []);
}
