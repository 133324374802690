export type GroupRow = [string, string | undefined, string | undefined];

export const numFieldsPerRow = 3;

/**
 * take a group chief and employees and group them in rows of 3
 * @param chief the group chief
 * @param employees the employees
 * @returns rows of up to 3 employees
 */
export const group = (chief: string, employees: readonly string[]): GroupRow[] => [
  [chief, ...employees.slice(0, 2)] as GroupRow,
  ...(employees.slice(2).reduce((acc: string[][], employee) => {
    let last = acc[acc.length - 1];
    if (!last || last.length === numFieldsPerRow) {
      last = [];
      acc.push(last);
    }
    last.push(employee);
    return acc;
  }, []) as GroupRow[]),
];

/**
 * The very first column in the employees subsection contains the group chief
 * @param row row to check
 * @param column column to check
 * @returns whether this is the chief column
 */
export const isChiefColumn = (row: number, column: number): boolean => row === 0 && column === 0;

/**
 * Convert a row/column pair to the employee array index.
 * Attention: the chief is part of the 3 person rows and is accounted for
 * @param row row to convert
 * @param column column to convert
 * @returns the index of the row/column in the employee array taking chief into account
 */
export const toEmployeeIdx = (row: number, column: number): number => row * 3 + column - 1;
