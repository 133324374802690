import React from 'react';
import { IDataTableColumn } from '../../components/DataTable/types';
import { colsUtil, TCol } from '../../utils/col';
import ColorCoder, { Color } from '../../components/ColorCoder';
import { formatDate } from '../../utils/format/date';
import { translateBool } from '../../utils/bool.translate.util';
import { capitalize } from '../../utils/capitalize';

const data: TCol[] = [
  ['id', 'Laufnummer'],
  [
    'project.colorCodings',
    'Projekt Farbcodierung',
    true,
    (colorCodings: Color[]) => colorCodings && <ColorCoder colorCodings={colorCodings} />,
  ],
  ['project.projectNumber', 'Projektnummer'],
  ['project.projectName', 'Projektbezeichnung'],
  ['project.status', 'Projekt Status', true],
  ['project.subsidiary.name', 'Filiale', true],
  ['project.createdAt', 'Eröffnet Datum', true, (val) => formatDate(val)],
  ['project.publisher.name', 'Eröffnet von', true],
  ['project.personResponsible.name', 'Verantwortlicher', true],
  ['project.projectAddress.street', 'Projektadr. Strasse', true],
  ['project.projectAddress.mailbox', 'Projektadr. Postfach', true],
  ['project.projectAddress.postCode', 'Projektadr. PLZ', true],
  ['project.projectAddress.city', 'Projektadr. Ort', true],
  ['project.projectAddress.country', 'Projektadr. Land', true],
  ['project.shippingAddress.street', 'Versandadr. Strasse', true],
  ['project.shippingAddress.mailbox', 'Versandadr. Postfach', true],
  ['project.shippingAddress.postCode', 'Versandadr. PLZ', true],
  ['project.shippingAddress.city', 'Versandadr. Ort', true],
  ['project.shippingAddress.country', 'Versandadr. Land', true],
  ['project.catalog.useName', 'Standardkatalog', true],
  ['project.staffTariff', 'Mitarbeitertarif', true],
  ['project.isSpecialProject', 'Spezialprojekt', true, translateBool],
  ['project.commentToBranchLeader', 'Kommentar an FL', true],
  ['project.commentToBackoffice', 'Kommentar an BO', true],
  ['project.commentToExecutives', 'Kommentar an GF', true],
  [
    'colorCodings',
    'Einsatz Materialart/Farbcodierung',
    false,
    (colorCodings: Color[]) => colorCodings && <ColorCoder colorCodings={colorCodings} />,
  ],
  ['name', 'Einsatzname'],
  ['description', 'Einsatzbeschreibung', true],
  ['projectAddress.street', 'Einsatzadr. Strasse', true],
  ['projectAddress.postCode', 'Einsatzadr. PLZ'],
  ['projectAddress.city', 'Einsatzadr. Ort'],
  ['projectAddress.country', 'Einsatzadr. Land', true],
  ['status.missionStatus', 'Einsatz Status'],
  ['executionDate', 'Ausführungsdatum', true, (date: string | null) => date && formatDate(date)],
  ['status.calendarWeek', 'KW'],
  ['status.date', 'Datum', false, (date: string | null) => date && formatDate(date)],
  ['status.untilDate', 'Bis Datum', false, (date: string | null) => date && formatDate(date)],
  ['isSaturday', 'Samstag', false, translateBool],
  ['isSunday', 'Sonntag', false, translateBool],
  ['isNight', 'Nacht', false, translateBool],
  ['isUrgent', 'Dringend', false, translateBool],
  ['workTypes', 'Arbeitstypen', false, (val) => val && val.map(capitalize)],
  [
    'surfaceType',
    'Belagsart/Untergrund',
    false,
    (types) => types && types.map(capitalize).join(','),
  ],
  ['externalServices', 'Mögliche Fremdleistungen'],
  ['expectedWorkingTime', 'Erwartete Arbeitszeit'],
  ['expectedEquipmentOps', 'Geschätzte Personenzahl EC'],
  ['expectedStaffNumber', 'Geschätzte Personenzahl MA'],
  ['callCustomer', 'Kunde vorgängig informieren', true],
  ['customerOnSite', 'Kunde vor Ort', true],
  ['weatherType', 'Ausmass Wetter', true],
  ['surfaceConditions', 'Ausmass Untergrund', true],
  ['temperature', 'Ausmass Temperatur', true],
  ['publisher.name', 'Erstellt von', true],
  ['createdAt', 'Erstellt Datum', true, (val) => formatDate(val)],
  ['updatedAt', 'Letzte Änderung Datum', true, (val) => val && formatDate(val)],
  ['commentBranchLeaderToBranchLeader', 'Kommentar an Dispo', true],
  ['commentToEquipmentOperator', 'Kommentar an EC', true],
  ['commentToBackoffice', 'Einsatz Kommentar an BO', true],
  ['commentEquipmentOperatorToBranchLeader', 'Kommentar EC an FL', true],
];

export const missionColumns: IDataTableColumn[] = colsUtil(data);
