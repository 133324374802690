import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ViewListIcon from '@material-ui/icons/ViewList';
import Today from '@material-ui/icons/Today';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';

import { tabIconViewBox, useTabStyles } from '../../utils/tabStyles';

/**
 * Accounting log tab buttons
 * @param history routing history
 * @param location routing location
 * @constructor
 */
const AccountingLogTabs: React.FC<RouteComponentProps> = ({ history, location }) => {
  const classes = useTabStyles();

  const { tabClasses, iconClasses } = useMemo(
    () => ({
      iconClasses: {},
      tabClasses: {
        scrollButtons: classes.scrollButtons,
      },
    }),
    [classes],
  );

  const navigate = useCallback(
    (e: React.ChangeEvent<any>, path: string) => history.push(path),
    [history],
  );
  const tabValue = /\/buchungsjournal\/rapport\/ubersicht/.test(location.pathname)
    ? '/buchungsjournal/rapport/ubersicht'
    : /\/buchungsjournal\/rapport/.test(location.pathname)
    ? '/buchungsjournal/rapport/erstellen'
    : /\/buchungsjournal\/kalender/.test(location.pathname)
    ? '/buchungsjournal/kalender'
    : /\/buchungsjournal\/manuell/.test(location.pathname)
    ? '/buchungsjournal/manuell'
    : '/buchungsjournal';

  return (
    <Tabs
      value={tabValue}
      onChange={navigate}
      variant="scrollable"
      scrollButtons="auto"
      classes={tabClasses}
    >
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <AssignmentTurnedInIcon classes={iconClasses} viewBox={tabIconViewBox} />
            Rapportieren
          </>
        }
        value={`/buchungsjournal/rapport/erstellen`}
      />
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <AssignmentIcon classes={iconClasses} viewBox={tabIconViewBox} />
            Rapportübersicht
          </>
        }
        value={`/buchungsjournal/rapport/ubersicht`}
      />
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <AssignmentLateIcon classes={iconClasses} viewBox={tabIconViewBox} />
            Händische Buchung
          </>
        }
        value={`/buchungsjournal/manuell`}
      />
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <ViewListIcon classes={iconClasses} viewBox={tabIconViewBox} />
            Journal
          </>
        }
        value={`/buchungsjournal`}
      />
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <Today classes={iconClasses} viewBox={tabIconViewBox} />
            Wochenübersicht
          </>
        }
        value={`/buchungsjournal/kalender`}
      />
    </Tabs>
  );
};

export default AccountingLogTabs;
