import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Checkbox, Theme } from '@material-ui/core';
import { IDataTableRow } from '../types';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Paper from '@material-ui/core/Paper';
import LevelToggleButton from '../LevelToggleButton';
import ContainerCell from './ContainerCell';
import { ActionType, ITableContext } from '..';
import { Draggable } from 'react-beautiful-dnd';
import createPreventAll from '../../../utils/createPreventEventDefault';
import { findRow } from '../../BillOfQuantity/BllOfQuantityTable/BillOfQuantityTable.utils';
import { fetchContainerTree, isContainerChecked } from '../utils/container/selectContainerUtils';
import findContainerTree from '../utils/container/flattenContainerRows';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    display: 'flex',
    backgroundColor: theme.palette.grey[300],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    lineHeight: '1rem',
  },
  activeRow: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  activeRowInPath: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.95),
  },
  actions: {
    marginLeft: 'auto',
    alignSelf: 'center',
    padding: theme.spacing(2),
  },
}));

interface IProps {
  row: IDataTableRow;
  context: ITableContext;
}

const HeaderRow: React.FC<IProps> = ({ row, context }) => {
  const { tableData, closedRowIds, activeRowId, dispatch, state } = context;
  const classes = useStyles();

  const setActiveRow = useCallback(() => {
    dispatch({ type: ActionType.SET_ACTIVE_ROW, payload: { rowId: row.id } });
  }, [row.id, dispatch]);

  const rowNavigation = tableData.navigation[row.id];
  const level = rowNavigation.path.length;
  const levelOptions = tableData.options.levels[level];
  const isLevelToggleButtonVisible = levelOptions.isLevelToggleButtonVisible
    ? levelOptions.isLevelToggleButtonVisible(row)
    : false;
  const isOpen = closedRowIds.indexOf(row.id) === -1;

  const handleDoubleClick = useMemo(
    () =>
      createPreventAll(() => {
        if (rowNavigation.children.length > 0 || !isOpen) {
          dispatch({ type: ActionType.TOGGLE_ROW, payload: { rowId: row.id } });
          if (window.getSelection()) {
            window.getSelection()!.removeAllRanges();
          }
        }
      }),
    [row, rowNavigation, dispatch, isOpen],
  );

  const isActiveRow = activeRowId === row.id;
  const isActiveRowInPath = activeRowId
    ? tableData.navigation[activeRowId].path.indexOf(row.id) > -1
    : false;

  const rowClassName = classNames(classes.root, levelOptions.className?.(row) ?? '', {
    [classes.activeRow]: isActiveRow,
    [classes.activeRowInPath]: isActiveRowInPath,
  });

  const draggableProps = levelOptions.setDraggableProps && levelOptions.setDraggableProps(row);
  const fixedWidthColumnsHeader = tableData.options.fixedWidthColumnsHeader !== false;

  const currentContainerRow = useMemo(
    () => findRow(row.id, tableData.containerRows ?? []),
    [row.id, tableData.containerRows],
  );

  const headerContent = useMemo(() => {
    const isChecked = isContainerChecked(state.selectedContainerIds, row.id);

    return (
      <>
        <LevelToggleButton
          rowNavigation={rowNavigation}
          isOpen={isOpen}
          isActive={isActiveRow || isActiveRowInPath}
          dispatch={dispatch}
          fixedWidth={fixedWidthColumnsHeader ? 36 : undefined}
          onLoadMore={levelOptions.onLoadMore}
          isLevelToggleButtonVisible={isLevelToggleButtonVisible}
          innerTableRows={currentContainerRow?.innerTableRows ?? []}
        />
        {levelOptions.columns.map((column) => (
          <ContainerCell
            key={column.id}
            row={row}
            column={column}
            searchWords={tableData.searchWords}
            width={fixedWidthColumnsHeader ? column.width ?? 150 : undefined}
          />
        ))}
        <div className={classes.actions}>
          {levelOptions.rowActions && levelOptions.rowActions({ row })}
          {tableData.options.isSelectableContainers && (
            <Checkbox
              color="primary"
              checked={isChecked}
              onChange={() => {
                if (!tableData.containerRows) {
                  return;
                }

                const selectedContainerTree = findContainerTree(tableData.containerRows, row.id);
                if (!selectedContainerTree) {
                  return;
                }

                const selectedTree = fetchContainerTree(
                  selectedContainerTree,
                  state.selectedContainerIds,
                  state.checkedRowIds,
                );

                dispatch({
                  type: ActionType.TOGGLE_SELECTED_CONTAINER,
                  payload: selectedTree.containerIds,
                });

                dispatch({
                  type: ActionType.TOGGLE_CHECKBOXES,
                  payload: { toggleEntries: selectedTree.itemIds },
                });
              }}
            />
          )}
        </div>
      </>
    );
  }, [
    classes.actions,
    currentContainerRow?.innerTableRows,
    dispatch,
    fixedWidthColumnsHeader,
    isActiveRow,
    isActiveRowInPath,
    isLevelToggleButtonVisible,
    isOpen,
    levelOptions,
    row,
    rowNavigation,
    state.checkedRowIds,
    state.selectedContainerIds,
    tableData.containerRows,
    tableData.options.isSelectableContainers,
    tableData.searchWords,
  ]);

  return levelOptions.isDragAndDropEnabled && draggableProps ? (
    <Draggable draggableId={draggableProps.id} type={draggableProps.type} index={row.data.index}>
      {(provided) => {
        return (
          <Paper
            className={rowClassName}
            onClick={setActiveRow}
            onDoubleClick={handleDoubleClick}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {headerContent}
          </Paper>
        );
      }}
    </Draggable>
  ) : (
    <Paper className={rowClassName} onClick={setActiveRow} onDoubleClick={handleDoubleClick}>
      {headerContent}
    </Paper>
  );
};

export default HeaderRow;
