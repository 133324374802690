import gql from 'graphql-tag';
import { TableType } from '../../../types/graphql';
import { useProjectNumber } from '../../../hooks/useProjectNumber';
import { useQuery } from 'react-apollo';
import {
  GetContainerSortMappingVariables,
  GetContainerSortMapping,
} from './types/GetContainerSortMapping';
import { IContainerSortMapping } from './dialog';

export const GET_CONTAINER_SORT_MAPPING = gql`
  query GetContainerSortMapping($tableType: TableType!, $projectNumber: String!) {
    containerSortMapping(tableType: $tableType, projectNumber: $projectNumber) @client {
      orderBy
      direction
    }
  }
`;

/**
 * used to fetch current container sort-mapping for given project and tableType
 */
export const useContainerSortMapping = <AllowedField>(tableType: TableType) => {
  const projectNumber = useProjectNumber();

  const { data } = useQuery<GetContainerSortMapping, GetContainerSortMappingVariables>(
    GET_CONTAINER_SORT_MAPPING,
    {
      variables: {
        projectNumber: projectNumber!,
        tableType,
      },
    },
  );

  // force-cast to IContainerSortMapping because it's direction is an enum and GetContainerSortMapping's direction is union type (not compatible)
  return data?.containerSortMapping as unknown as IContainerSortMapping<AllowedField>;
};
