import React, { useState, useCallback } from 'react';
import { Tooltip, IconButton, SvgIcon, Theme } from '@material-ui/core';
import { L8Icon } from '../../components/BillOfQuantity/Icons/l8.icon';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/styles';

interface IProps {
  disabled?: boolean;
  fontSize?: SvgIconProps['fontSize'];
  isMobile?: boolean;
  onToggle?: (showStatusL8: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  iconBtnMobile: {
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(1),
  },
  wrapperDiv: {
    display: 'inline-block',
  },
}));

export const useL8Filter = (props?: IProps) => {
  const [showStatusL8, setShowStatusL8] = useState(false);

  const classes = useStyles();

  const iconBtnClassName = (props && props.isMobile && classes.iconBtnMobile) || undefined;

  const toggleShowStatusL8 = useCallback(() => {
    setShowStatusL8((v) => {
      props?.onToggle?.(!v);

      return !v;
    });
  }, [props]);

  return {
    showStatusL8,
    L8Button: (
      <>
        <Tooltip title={showStatusL8 ? "Abgelehnte LV's ausblenden" : "Abgelehnte LV's einblenden"}>
          {/* without wrapper div the Tooltip throws an error if the button is disabled */}
          <div className={classes.wrapperDiv}>
            <IconButton
                onClick={toggleShowStatusL8}
                className={iconBtnClassName}
                disabled={props?.disabled}
            >
              <SvgIcon color="inherit" fontSize={props && props.fontSize}>
                <L8Icon crossed={showStatusL8} />
              </SvgIcon>
            </IconButton>
          </div>
        </Tooltip>
      </>
    ),
  };
};
