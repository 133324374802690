import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HomeIcon from '@material-ui/icons/Home';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EventIcon from '@material-ui/icons/Event';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { tabIconViewBox, useTabStyles } from '../../utils/tabStyles';
import { removeContainerSortMappingInProject } from '../../components/Actions/ContainerSort/cache';
import { useApolloClient } from 'react-apollo';
import { useUnmount } from 'react-use';

interface IRouterParams {
  id: string;
}

const SUB_PATH_REGEX = /(\/projekte\/[A-Za-z0-9]+\/[A-Za-z0-9]+)\/?.*/;

const ProjectTabs: React.FunctionComponent<RouteComponentProps<IRouterParams>> = ({
  match,
  history,
  location,
}) => {
  const classes = useTabStyles();
  const client = useApolloClient();

  const { id } = match.params;
  const navigate = (e: React.ChangeEvent<any>, path: string) => history.push(path);
  const tabValue = SUB_PATH_REGEX.exec(location.pathname)![1];

  useUnmount(() => {
    removeContainerSortMappingInProject(id, client);
  });

  return (
    <Tabs
      value={tabValue}
      onChange={navigate}
      variant="scrollable"
      scrollButtons="auto"
      classes={{ scrollButtons: classes.scrollButtons }}
    >
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <HomeIcon classes={{ root: classes.rootIcon }} viewBox={tabIconViewBox} />
            Projektdetail
          </>
        }
        value={`/projekte/${id}/details`}
      />
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <ListAltIcon classes={{ root: classes.rootIcon }} viewBox={tabIconViewBox} /> Offerten
          </>
        }
        value={`/projekte/${id}/offerten`}
      />
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <AssignmentIcon classes={{ root: classes.rootIcon }} viewBox={tabIconViewBox} />
            Aufträge
          </>
        }
        value={`/projekte/${id}/auftraege`}
      />
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <EventIcon classes={{ root: classes.rootIcon }} viewBox={tabIconViewBox} /> Einsätze
          </>
        }
        value={`/projekte/${id}/einsaetze`}
      />
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <FormatPaintIcon classes={{ root: classes.rootIcon }} viewBox={tabIconViewBox} /> Ausmaß
          </>
        }
        value={`/projekte/${id}/ausmass`}
      />
      <Tab
        className={classes.tabRoot}
        label={
          <>
            <MonetizationOnIcon classes={{ root: classes.rootIcon }} viewBox={tabIconViewBox} />{' '}
            Rechnungen
          </>
        }
        value={`/projekte/${id}/rechnungen`}
      />
    </Tabs>
  );
};

export default ProjectTabs;
