import { historyFields } from './types/historyFields';
import { IHistorizedFieldItem } from '../Form/HistorizedField';

export const mapHistoryItem = (historyItems: historyFields[]): IHistorizedFieldItem[] => {
  return historyItems.map((item) => {
    return {
      id: item.id,
      value: item.value,
      user: item.createdBy.name,
      date: item.createdAt,
      validFrom: item.validFrom,
    };
  });
};
