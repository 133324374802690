import * as React from 'react';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch';
import { FieldProps } from 'formik';
import { Omit } from './types';
import { FormControlLabel } from '@material-ui/core';

/** Inspired by formik-material-ui */

export interface ISwitchProps
  extends FieldProps,
    Omit<MuiSwitchProps, 'form' | 'name' | 'onChange' | 'value' | 'defaultChecked'> {
  label: string;
}

export const fieldToSwitch = ({
  field,
  form: { isSubmitting },
  disabled = false,
  ...props
}: Omit<ISwitchProps, 'label'>): MuiSwitchProps => {
  return {
    disabled: isSubmitting || disabled,
    ...props,
    ...field,
    value: field.name,
    checked: field.value,
  };
};

export const FormikSwitch: React.ComponentType<ISwitchProps> = ({
  label,
  ...props
}: ISwitchProps) =>
  label ? (
    <FormControlLabel control={<MuiSwitch {...fieldToSwitch(props)} />} label={label} />
  ) : (
    <MuiSwitch {...fieldToSwitch(props)} />
  );

FormikSwitch.displayName = 'FormikSwitch';
