import { useMutation } from 'react-apollo';
import { UPDATE_PROJECT_ADDRESS_MUTATION } from './query';
import { UpdateProjectAddress, UpdateProjectAddressVariables } from './types/UpdateProjectAddress';

export const useProjectAddressQuery = () => {
  const [executeUpdateProjectAddress, { loading, error }] = useMutation<
    UpdateProjectAddress,
    UpdateProjectAddressVariables
  >(UPDATE_PROJECT_ADDRESS_MUTATION);

  return { loading, error, executeUpdateProjectAddress };
};
