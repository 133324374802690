import React from 'react';
import { Query, QueryComponentOptions, OperationVariables, QueryResult } from 'react-apollo';
import AppProgress from '../Page/AppProgress';
import AppErrorMessage from '../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';

type WrappedQueryOptions<TData = any, TVariables = OperationVariables> = QueryComponentOptions<
  TData,
  TVariables
> & {
  placeholder?: JSX.Element;
  continueOnLoading?: boolean;
};

function WrappedQuery<TData = any, TVariables = Record<string, any>>({
  children,
  placeholder,
  continueOnLoading,
  ...rest
}: WrappedQueryOptions<TData, TVariables>): JSX.Element | null {
  return (
    <Query<TData, TVariables> {...(rest as any)}>
      {({ error, loading, ...rest }: QueryResult<TData, TVariables>) => {
        if (loading && !continueOnLoading) {
          return placeholder || <AppProgress />;
        }

        if (error) {
          console.error(error);
          return <AppErrorMessage message={errorPrefixRemover(error.message)} />;
        }

        return children(rest as any);
      }}
    </Query>
  );
}

/** Apollo query with loading and error handling */
export default WrappedQuery;
