import { GetAccountingActivityTypes_activityTypes } from '../AccountingLogger/types/GetAccountingActivityTypes';
import { ChargeType } from '../../../types/graphql';

declare const _chargeConstraint: unique symbol;

/**
 * Special type that encodes the charge constraint information in the string of the form
 * {activitytype number}-{from type}-{to type}
 */
export type ChargeConstraint = string & {
  [_chargeConstraint]: true;
};

export const chargeConstraints = new Set<ChargeConstraint>([
  '1001-1-1',
  '1002-1-1',
  '1003-1-1',

  '1004-1-5',
  '1005-1-5',
  '1006-1-5',
  '1007-1-5',
  '1008-1-5',

  '1009-1-2',
  '1009-1-3',
  '1009-1-5',

  '1010-1-5',

  '1011-5-1',
  '1012-5-1',

  '2001-1-5',

  '2002-1-1',
  '2002-1-5',

  '2003-1-1',
  '2003-1-5',

  '2004-1-1',
  '2004-1-5',

  '3001-1-5',
  '3002-1-5',
  '3003-1-5',
  '3004-1-5',
  '3005-1-5',
  '3006-1-5',
  '3007-1-5',
  '3008-1-5',
  '3009-1-5',

  '3101-1-5',
  '3102-1-5',
  '3103-1-5',
  '3104-1-5',
  '3105-1-5',

  '4001-1-5',
  '4002-1-5',
  '4003-1-5',
  '4004-1-5',
  '4005-1-5',
  '4006-1-5',

  '5001-3-1',
  '5002-3-1',

  '6001-2-1',

  '7001-4-1',
  '7001-4-5',

  '7002-5-4',

  '8001-5-1',

  '8002-5-2',
  '8002-5-3',
  '8002-5-5',

  '8003-5-1',

  '8004-5-2',
  '8004-5-3',
  '8004-5-5',
] as unknown as readonly ChargeConstraint[]);

export const isChargeConstraint = (value: any): value is ChargeConstraint =>
  chargeConstraints.has(value);

export function assertChargeConstraint(value: any): asserts value is ChargeConstraint {
  if (!isChargeConstraint(value)) {
    throw new TypeError('value is not a valid charge constraint ' + JSON.stringify(value));
  }
}

export enum CreditedTo {
  EMPLOYEE = 1,
  MACHINE,
  VEHICLE,
  MATERIAL,
  COLLECTIVEACCOUNT,
}

/**
 * Convert a charge type to a credited to type
 * @param type the charge type
 * @returns the credited to type
 */
export const creditedToFromChargeType = (type: ChargeType): CreditedTo => {
  if (type === ChargeType.MISSION) {
    throw new Error('can not convert charge type to a creditedTo type: ' + type);
  }
  return CreditedTo[type];
};

export enum ChargedTo {
  MISSION = 1,
  MACHINE,
  VEHICLE,
  MATERIAL,
  COLLECTIVEACCOUNT,
}

/**
 * Convert a charge type to a charged to type
 * @param type the charge type
 * @returns the charged to type
 */
export const chargedToFromChargeType = (type: ChargeType): ChargedTo => {
  if (type === ChargeType.EMPLOYEE) {
    throw new Error('can not convert charge type to a chargedTo type: ' + type);
  }
  return ChargedTo[type];
};

/**
 * @param activityType
 * @param creditedTo
 * @param chargedTo
 * @returns a new charge constraint
 */
export const testChargeConstraint = (
  activityType: GetAccountingActivityTypes_activityTypes | { number: number },
  creditedTo: CreditedTo,
  chargedTo: ChargedTo,
): boolean => {
  const value = `${activityType.number}-${creditedTo}-${chargedTo}`;
  return isChargeConstraint(value);
};

/**
 * @param activityType
 * @param creditedTo
 * @param chargedTo
 * @returns a new charge constraint
 */
export const chargeConstraint = (
  activityType: GetAccountingActivityTypes_activityTypes,
  creditedTo: CreditedTo,
  chargedTo: ChargedTo,
): ChargeConstraint => {
  const value = `${activityType.number}-${creditedTo}-${chargedTo}`;
  assertChargeConstraint(value);
  return value;
};

interface IParsedChargeConstraint {
  activityType: GetAccountingActivityTypes_activityTypes['number'];
  creditedTo: CreditedTo;
  chargedTo: ChargedTo;
}

/**
 * parse a charge constraint and return its parts
 * @param chargeConstraint the charge constraint to parse
 * @returns charge constraint parts
 * @see IParsedChargeConstraint
 */
export const parseChargeConstraint = (
  chargeConstraint: ChargeConstraint | string,
): IParsedChargeConstraint => {
  console.assert(chargeConstraint);
  const [activityType, creditedTo, chargedTo] = chargeConstraint.split('-');
  return {
    activityType: Number(activityType),
    creditedTo: Number(creditedTo),
    chargedTo: Number(chargedTo),
  };
};

/**
 * get all relevant charge constraints for the provided filter
 * @param filter a subset of parsed activity type fields
 * @returns array of all relevant charge constraints
 * @see GetAccountingActivityTypes_activityTypes
 * @see ChargeConstraint
 */
export const filterChargeConstraints = (
  filter: Partial<IParsedChargeConstraint>,
): ChargeConstraint[] => {
  const chargeConstraintArray = Array.from(chargeConstraints);
  if ((filter.activityType == null ?? filter.creditedTo ?? filter.chargedTo) == null) {
    // nothing to do
    return chargeConstraintArray;
  }
  return chargeConstraintArray.filter((chargeConstraint) => {
    const parsed = parseChargeConstraint(chargeConstraint);
    if (filter.activityType != null && parsed.activityType !== filter.activityType) {
      return false;
    }
    if (filter.creditedTo != null && parsed.creditedTo !== filter.creditedTo) {
      return false;
    }
    if (filter.chargedTo != null && parsed.chargedTo !== filter.chargedTo) {
      return false;
    }
    return true;
  });
};
