import { useMutation, useApolloClient } from 'react-apollo';
import React, { FC, useCallback, useMemo } from 'react';
import { DELETE_BILL_MUTATION } from './DeleteBillAction.queries';
import AppProgress from '../../../Page/AppProgress';
import AppErrorMessage from '../../../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../../utils/errorPrefixRemover';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IDataTableRow } from '../../../DataTable/types';
import { useProjectNumber } from '../../../../hooks/useProjectNumber';
import { Role } from '../../../../types/graphql';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import { useLoggedInUser } from '../../../../hooks/useLoggedInUser';
import { createDeleteBillFromCache } from './removeBillFromCache';

interface IPropsBill {
  id: string;
  number: string;
  canBeDeleted: boolean;
}

interface IProps {
  bill: IPropsBill;
  row: IDataTableRow;
}

export const DeleteBillAction: FC<IProps> = ({ bill, row }) => {
  const client = useApolloClient();
  const projectNumber = useProjectNumber();
  const user = useLoggedInUser();

  const isAllowedToDelete = useMemo(() => user?.role === Role.SUPER_ADMIN, [user]);
  const [deleteBill, { loading, error }] = useMutation(DELETE_BILL_MUTATION, {
    update: createDeleteBillFromCache(client, row, projectNumber),
  });
  const handleBillDeletion = useCallback(async () => {
    if (!projectNumber) {
      return;
    }

    await deleteBill({ variables: { where: { id: bill.id } } });
  }, [bill.id, deleteBill, projectNumber]);

  const { requestConfirmation, confirmationDialog } = useConfirmationDialog({
    message: `Willst du die Rechnung ${bill.number} wirklich löschen?`,
    title: 'Rechnung löschen',
    onConfirm: handleBillDeletion,
  });

  return (
    <>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      {confirmationDialog}
      <Tooltip title="Rechnung löschen">
        <span>
          <IconButton
            aria-label="Rechnung löschen"
            onClick={requestConfirmation}
            disabled={loading || !bill.canBeDeleted || !isAllowedToDelete}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
