import React from 'react';
import { Prompt } from 'react-router-dom';

interface IProps {
  dirty: boolean;
}

const UnsavedWarningPrompt: React.FunctionComponent<IProps> = ({ dirty }) => (
  <Prompt
    when={dirty}
    message="Die Änderungen sind noch nicht gespeichert. Wollen Sie wirklich die Seite wechseln?"
  />
);

/** Warns the user if there are unsaved form changes and the user changed the page.  */
export default UnsavedWarningPrompt;
