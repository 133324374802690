import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Field, useField, useFormikContext } from 'formik';
import FormikTextField from '../../../../../components/Form/FormikTextField';
import { default as Fab, FabProps } from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { group, GroupRow, isChiefColumn, toEmployeeIdx } from './Group.utils';
import { makeStyles } from '@material-ui/styles';
import { GetAccountingEmployees_employees } from '../../types/GetAccountingEmployees';
import { Autocomplete } from '../../../../../components/Autocomplete';
import { fuzzyMatch } from '../../../utils/fuzzyMatch';
import { searchableEmployee } from '../../../utils/searchable';
import { emptyPerson, IAccountLogggerFormValues } from '../../types';
import { cachedTabIndex } from '../../../utils/tabIndex';

const useStyles = makeStyles(() => ({
  fieldContainer: {
    paddingRight: '1em',
  },
}));

/**
 * a simple + button to add an employee row
 * @constructor
 */
const AddRowButton: React.FC<FabProps> = (props) => (
  <Fab color="primary" size="medium" {...props}>
    <AddIcon />
  </Fab>
);

/**
 * @returns employees mapped to their searchable strings
 * @param employees employee objects
 */
const mapSearchable = (employees: readonly GetAccountingEmployees_employees[]): string[] =>
  employees.map(searchableEmployee);

interface IProps {
  disabled?: boolean;
  employees: readonly GetAccountingEmployees_employees[];
}

/**
 * Setup section of the accounting logger. Specify the accounting day and the group.
 * @constructor
 */
export const Group: React.FC<IProps> = ({ disabled, employees }) => {
  const styles = useStyles();

  // no object identity on setValue of useField!
  const { setFieldValue } = useFormikContext();

  const [{ value: date }] = useField('date');
  const [{ value: chief }] = useField('chief');

  const name = 'employees';
  const [{ value: logEmployees = [] }] = useField<IAccountLogggerFormValues['employees']>(name);

  const data: GroupRow[] = useMemo(() => {
    if (chief == null) {
      return [];
    }
    return group(chief, logEmployees);
  }, [chief, logEmployees]);

  // the non chief suggestions only include non EC roles
  const allSuggestions = useMemo(() => mapSearchable(employees), [employees]);
  // filter out already selected employees
  const suggestions = useMemo(
    () =>
      allSuggestions.filter(
        (searchable) => chief !== searchable && !logEmployees.includes(searchable),
      ),
    [allSuggestions, chief, logEmployees],
  );
  const validateSuggestions = useCallback(
    (value) => {
      if (value === '') {
        return;
      }
      if (!allSuggestions.includes(value)) {
        return 'Mitarbeiter existiert nicht!';
      }
    },
    [allSuggestions],
  );

  const canAddRow = useMemo(
    () => !disabled && chief && logEmployees.every((person) => (person ?? '') !== ''),
    [chief, logEmployees, disabled],
  );

  const onAddRow = useCallback(() => {
    // an empty row consists of 3 slots and can only be added when the other slots are filled
    setFieldValue(name, [...logEmployees, emptyPerson(), emptyPerson(), emptyPerson()], false);
  }, [logEmployees, setFieldValue]);

  const employeesDisabled =
    disabled ||
    // quick smoke test to see whether date was filled out completely
    date.length !== 'YYYY-MM-DD'.length;

  // if the date is changed, change the date for not yet filled out impersonal tables as well
  const [{ value: impersonals }] = useField<IAccountLogggerFormValues['impersonal']>('impersonal');
  useEffect(
    () => {
      impersonals.forEach(({ mission, entries }, idx) => {
        if (mission.length === 0 && entries.length === 1) {
          setFieldValue(`impersonal[${idx}].date`, date, false);
        }
      });
    },
    // only a date change is relevant here, impersonals/setFieldValue don't impact the behaviour
    // eslint-disable-next-line
    [date],
  );
  return (
    <Grid container direction="row" alignItems="flex-end">
      <Grid item xs={11}>
        <Grid container direction="column">
          {data.map((groupRow: GroupRow, row) => (
            <Grid key={row} container direction="row">
              <Grid key="date" item xs={3} className={styles.fieldContainer}>
                {row === 0 && (
                  <Field
                    name="date"
                    label="Datum"
                    type="date"
                    component={FormikTextField}
                    disabled={disabled}
                    inputProps={cachedTabIndex(1)}
                  />
                )}
              </Grid>
              {groupRow.map((person: string | undefined, column) => {
                const isChief = isChiefColumn(row, column);
                const name = isChief ? 'chief' : `employees[${toEmployeeIdx(row, column)}]`;
                const next = `input[name='employees[${toEmployeeIdx(row, column) + 1}]']`;
                const label = name === 'chief' ? 'Equipenchef' : 'Mitarbeiter';
                return (
                  <Grid key={name} item xs={3} className={styles.fieldContainer}>
                    <Field
                      last={person ?? ''}
                      disabled={employeesDisabled}
                      name={name}
                      label={label}
                      type="text"
                      component={Autocomplete}
                      suggestions={suggestions}
                      matcher={fuzzyMatch}
                      minLengthToTrigger={0}
                      selectOnFocus
                      fillOnEnter
                      fillOnBlur
                      next={next}
                      // if we just pass the validate function it doesn't update correctly on change
                      validate={(value: any) => validateSuggestions(value)}
                      inputProps={cachedTabIndex(
                        // +2 to account for date field and starting value 1
                        2 + 3 * row + column,
                      )}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <Grid container alignItems="flex-end" justify="center">
          <AddRowButton disabled={!canAddRow} onClick={onAddRow} />
        </Grid>
      </Grid>
    </Grid>
  );
};
