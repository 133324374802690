import { ClickAwayListener, makeStyles, Popper } from '@material-ui/core';
import { PopperProps } from '@material-ui/core/Popper';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Noop } from '../../../types';
import { UpsertAddressBookItemType } from '../../../types/graphql';
import { AddressSearch } from './search';
import { MODAL_OPENER_CLASS } from './search/constants';

interface IProps {
  open: boolean;
  onClose: Noop;
  anchorEl: Exclude<PopperProps['anchorEl'], undefined>;
  projectNumber: string;
  type: UpsertAddressBookItemType;
}

const useStyles = makeStyles({
  popperClosed: {
    // "keepMounted" does not respect "open", therefore hack with height 0 when not open
    height: 0,
    overflow: 'hidden',
  },
  popper: {
    width: '80vw',
    zIndex: 100,
    backgroundColor: 'white',
    paddingTop: '12px',
  },
});

export const AddressSearchPopper: React.FC<IProps> = ({
  open,
  onClose,
  anchorEl,
  projectNumber,
  type,
}) => {
  const classes = useStyles({ open });
  const handleClickAway = useCallback(
    (e: React.MouseEvent<Document, MouseEvent>) => {
      const classList = Array.from((e.target as any).classList).concat(
        Array.from((e.target as any).parentElement.classList),
      );
      if (!classList.includes(MODAL_OPENER_CLASS)) {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <Popper
      keepMounted
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      className={classNames([classes.popper, !open && classes.popperClosed])}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <span>
          <AddressSearch
            isSurroundingModalOpen={open}
            projectNumber={projectNumber}
            onAddressSelect={onClose}
            type={type}
          />
        </span>
      </ClickAwayListener>
    </Popper>
  );
};
