import gql from 'graphql-tag';

export const MOBILE_ITEM_DETAILS_QUERY = gql`
  query MobileItemDetails($id: ID!) {
    item(id: $id) {
      id
      comment
      customerComment
      documents {
        id
        name
        path
        mimetype
      }
      nodeCoordinate
      startCoordinate
      endCoordinate
    }
  }
`;
