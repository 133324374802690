import gql from 'graphql-tag';

const offerConditionFieldsFragment = gql`
  fragment offerConditionFields on OfferCondition {
    id
    name
    default
    text
  }
`;

export const GET_OFFER_CONDITIONS_QUERY = gql`
  query GetOfferConditions {
    offerConditions {
      ...offerConditionFields
    }
  }
  ${offerConditionFieldsFragment}
`;

export const GET_OFFER_CONDITION_QUERY = gql`
  query GetOfferCondition($where: OfferConditionWhereUniqueInput!) {
    offerCondition(where: $where) {
      ...offerConditionFields
    }
  }
  ${offerConditionFieldsFragment}
`;

export const CREATE_OFFER_CONDITION_MUTATION = gql`
  mutation CreateOfferCondition($data: OfferConditionCreateInput!) {
    createOfferCondition(data: $data) {
      id
    }
  }
`;

export const UPDATE_OFFER_CONDITION_MUTATION = gql`
  mutation UpdateOfferCondition(
    $where: OfferConditionWhereUniqueInput!
    $data: OfferConditionUpdateInput!
  ) {
    updateOfferCondition(where: $where, data: $data) {
      id
    }
  }
`;

export const DELETE_OFFER_CONDITION_MUTATION = gql`
  mutation DeleteOfferCondition($where: OfferConditionWhereUniqueInput!) {
    deleteOfferCondition(where: $where) {
      id
    }
  }
`;
