import React from 'react';
import { Route, Redirect, withRouter, RouteProps, RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import { GET_IS_LOGGED_IN } from '../pages/Login';

/**
 * Like Route from react-router-dom but we redirect
 * to login if the user is not authenticated
 */
const PrivateRoute: React.FunctionComponent<RouteProps & RouteComponentProps> = (props) => {
  return (
    <Query<any, any> query={GET_IS_LOGGED_IN}>
      {({ data }) =>
        data.auth.isLoggedIn ? (
          <Route {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    </Query>
  );
};

export default withRouter(PrivateRoute);
