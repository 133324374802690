import React, { useState } from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import DialogCloseable from '../../Dialog/DialogCloseable';
import ChooseNewPositionStep from './ChooseNewPositionStep';
import ConfirmationStep from './ConfirmationStep';
import { IDataTableRow } from '../../DataTable/types';
import { IItemToReplace } from '.';

interface IProps extends DialogProps {
  itemToReplace: IItemToReplace;
  catalogId?: string;
  onClose?: (...args: any[]) => void;
  onReplace: (
    oldItemId: string,
    newItemId: string,
    isReplaceAllItems: boolean,
    price: number,
  ) => void;
}

const ReplaceItemsDialog: React.FunctionComponent<IProps> = ({
  itemToReplace,
  catalogId,
  onClose,
  onReplace,
  ...dialogProps
}) => {
  const [newProductNumber, setNewProductNumber] = useState('');
  const [newItemId, setNewItemId] = useState('');
  const [newProductBasePrice, setNewProductBasePrice] = useState<number>();
  const isChooseNewPositionStepActive = newProductNumber.length === 0;

  const closeHandler = () => {
    setNewProductNumber('');
    if (onClose) {
      onClose();
    }
  };

  return (
    <DialogCloseable
      {...dialogProps}
      maxWidth={false}
      fullWidth={isChooseNewPositionStepActive}
      fullHeight={isChooseNewPositionStepActive}
      title={'Position ' + itemToReplace.productNumber + ' ersetzen'}
      onClose={closeHandler}
    >
      {isChooseNewPositionStepActive ? (
        <ChooseNewPositionStep
          productNumber={itemToReplace.productNumber}
          onRowClick={(row: IDataTableRow) => {
            setNewProductNumber(row.data.productNumber);
            setNewItemId(row.id);
            setNewProductBasePrice(row.data.basePrice);
          }}
          catalogId={catalogId}
        />
      ) : (
        <ConfirmationStep
          oldProductNumber={itemToReplace.productNumber}
          newProductNumber={newProductNumber}
          newProductBasePrice={newProductBasePrice!}
          onClose={closeHandler}
          onReplace={(isReplaceAllItems, price) => {
            onReplace(itemToReplace.id, newItemId, isReplaceAllItems, price);
            closeHandler();
          }}
        />
      )}
    </DialogCloseable>
  );
};

/**
 * Dialog to replace items in two steps:
 * 1. Choose new item
 * 2. Confirma replacement
 */
export default ReplaceItemsDialog;
