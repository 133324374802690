import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { GetPageInfo, GetPageInfoVariables } from './types/GetPageInfo';

export const GET_PAGE_INFO = gql`
  query GetPageInfo($id: ID!) {
    pageInfo(where: { id: $id }) @client {
      id
      hasNextPage
    }
  }
`;

export const getPageInfo = async (id: string, client: ApolloClient<any>) => {
  const { data } = await client.query<GetPageInfo, GetPageInfoVariables>({
    query: GET_PAGE_INFO,
    variables: {
      id,
    },
  });

  return data.pageInfo;
};
