import React from 'react';
import { Field } from 'formik';
import FormikTextField from '../../Form/FormikTextField';
import UntilDateStrategy from './UntilDateStrategy';
import { IStrategyProps } from './MissionStatus';

const FromDatumStrategy: React.FC<IStrategyProps> = ({ disabled }) => (
  <>
    <Field
      name="status.date"
      label="Vom Datum"
      type="date"
      shrink
      component={FormikTextField}
      disabled={disabled}
    />
    <UntilDateStrategy disabled={disabled} />
  </>
);

export default FromDatumStrategy;
