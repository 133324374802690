import gql from 'graphql-tag';

export const GET_ALL_CATALOGS = gql`
  query getAllCatalogs {
    catalogs {
      id
      useName
      createdAt
    }
  }
`;
