import { Button, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { IDataTableRow, OnLoadMore } from '../types';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2, 2),
  },
}));

interface IProps {
  loadMoreButtonIsDisabled?: boolean;
  lastRow: IDataTableRow<any, any> | undefined;
  parentRowId: string | undefined;
  onLoadMore: OnLoadMore;
}

export const InnerTableFooter: React.FC<IProps> = ({
  loadMoreButtonIsDisabled = false,
  lastRow,
  parentRowId,
  onLoadMore,
}) => {
  const styles = useStyles();

  const onLoadMoreClick = useCallback(() => {
    if (!parentRowId) {
      console.warn("parentRowId mustn't be undefined when InnerTableFooter is visible");

      return;
    }

    onLoadMore('LOAD_MORE_ROWS', parentRowId, lastRow?.id);
  }, [parentRowId, lastRow, onLoadMore]);

  return (
    <div className={styles.footer}>
      <Button
        variant="contained"
        color="primary"
        disabled={loadMoreButtonIsDisabled}
        onClick={onLoadMoreClick}
      >
        Mehr laden
      </Button>
    </div>
  );
};
