import { isEmpty, isPlainObject } from 'lodash';

interface IGetChangedFormValuesArgs {
  initialValues: Record<string, any>;
  values: Record<string, any>;
  includeKeys?: string[];
  ignoreKeys?: string[];
}

/**
 * Returns all changed properties of values.
 * **Does not work with Objects in Arrays**
 * @param initialValues the initialValues given to formik
 * @param values the values coming from the form
 * @param includeKeys the keys which should be included as whole if one of it's props change
 * @param ignoreKeys the keys which should be included no matter what (used for documents)
 */
export const getChangedFormValues = ({
  initialValues,
  values,
  ignoreKeys,
  includeKeys,
}: IGetChangedFormValuesArgs): Record<string, any> =>
  Object.keys(values).reduce((acc: any, key: any) => {
    const isValuePlainObject = isPlainObject(values[key]);

    if (ignoreKeys?.includes(key)) {
      acc[key] = values[key];

      return acc;
    }

    // Check recursively if values changed, if value is object
    if (isValuePlainObject) {
      const changedValues = getChangedFormValues({
        initialValues: initialValues[key],
        values: values[key],
        ignoreKeys,
        includeKeys,
      });

      const value = includeKeys?.includes(key) ? values[key] : changedValues;

      acc[key] = !isEmpty(changedValues) ? value : undefined;
    }

    if (!isValuePlainObject && values[key] !== initialValues[key]) {
      acc[key] = values[key];
    }

    return acc;
  }, {});
