import React, { useMemo, useCallback } from 'react';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import { BillCreateInput, BillOfQuantityEntityType, RowType, TableType } from '../../types/graphql';
import { CreateBillMutation, CreateBillMutationVariables } from './types/CreateBillMutation';
import BillForm from '../../components/Bill/BillForm';
import DetailsFormPaper from '../../components/DetailsFormPaper';
import { BILL_OF_QUANTITIES_SELECT_QUERY } from './BillDetails';
import AppProgress from '../../components/Page/AppProgress';
import { BillOfQuantitySelect, BillOfQuantitySelectVariables } from './types/BillOfQuantitySelect';
import { billFields, billFieldsForBillView } from '../Projects/TabBills/BillSelector/bill.queries';
import { ADD_ROW_TO_DATA_TABLE } from '../../services/graphql-client';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../services/types/AddRowToDataTable';
import { mapToContainerRow } from '../Projects/TabBills/BillSelector/utils/pagination.helpers';
import { MutationUpdaterFn } from 'apollo-client';

interface IMatchParams {
  projectNumber: string;
}

const CREATE_BILL_MUTATION = gql`
  mutation CreateBillMutation($projectNumber: String!, $data: BillCreateInput!) {
    createBill(project: { projectNumber: $projectNumber }, data: $data) {
      ...billFields
      ...billFieldsForBillView
      defaultLocation {
        id
      }
    }
  }
  ${billFields}
  ${billFieldsForBillView}
`;

const CreateBill: React.FC<RouteComponentProps<IMatchParams>> = ({ match, history }) => {
  const { projectNumber } = match.params;
  const client = useApolloClient();

  const { data, loading, error } = useQuery<BillOfQuantitySelect, BillOfQuantitySelectVariables>(
    BILL_OF_QUANTITIES_SELECT_QUERY,
    {
      variables: { projectNumber, entityType: BillOfQuantityEntityType.ORDER },
    },
  );
  const createBillInCache = useCallback<MutationUpdaterFn<CreateBillMutation>>(
    async (cache, { data }) => {
      if (!data?.createBill) {
        return;
      }

      const { createBill: createdBill } = data;

      const row = mapToContainerRow(createdBill);

      await client.query<AddRowToDataTable, AddRowToDataTableVariables>({
        query: ADD_ROW_TO_DATA_TABLE,
        variables: {
          where: { id: TableType.BILL },
          data: [{ row, type: RowType.CONTAINER }],
        },
      });
    },
    [client],
  );

  const [createBill] = useMutation<CreateBillMutation, CreateBillMutationVariables>(
    CREATE_BILL_MUTATION,
    {
      update: createBillInCache,
    },
  );

  const handleClose = () => history.goBack();

  const handleSubmit = async (values: BillCreateInput) => {
    await createBill({ variables: { data: values, projectNumber } });
    handleClose();
  };

  const defaultBillOfQuantity = useMemo(
    () => (data?.billOfQuantities?.length === 1 ? data.billOfQuantities[0] : null),
    [data],
  );

  if (error) {
    console.log(error);
    return null;
  }

  if (loading) {
    return <AppProgress />;
  }

  if (!data) {
    return null;
  }

  return (
    <DetailsFormPaper onClose={handleClose}>
      <BillForm
        onSubmit={handleSubmit}
        initialValues={{ billOfQuantity: defaultBillOfQuantity }}
        billOfQuantities={data.billOfQuantities}
      />
    </DetailsFormPaper>
  );
};

export default CreateBill;
