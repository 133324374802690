import { IDataTableRow } from '../../components/DataTable/types';
import { DataTableRowInput } from '../../types/graphql';

export const marshalRow = <T>(row: IDataTableRow<any, any, any> & T): DataTableRowInput & T => {
  return {
    containerRows: [],
    innerTableRows: [],
    __typename: 'DataTableRow',
    ...row,
    id: row.id,
    data: JSON.stringify(row.data),
  };
};
