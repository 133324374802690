import { getAllInnerTableRows } from '../../../../utils/paginationHelpers/getAllInnerTableRows';
import { IDataTableRow } from '../../../DataTable/types';

const createIsItemInInnerTableRows =
  <T extends { id: string }>(itemRow: T) =>
  (innerTableRows: IDataTableRow[]): boolean => {
    return innerTableRows.some((innerTableRow) => innerTableRow.id === itemRow.id);
  };

export const findBillOfQuantityRowFromItemRow =
  (rows: IDataTableRow[]) =>
  <T extends { id: string }>(itemRow: T): IDataTableRow | null => {
    const isItemInInnerTableRows = createIsItemInInnerTableRows(itemRow);

    for (const row of rows) {
      const innerTableRows = getAllInnerTableRows(row);

      if (isItemInInnerTableRows(innerTableRows)) {
        return row;
      }
    }

    return null;
  };
