import { IconButton, Tooltip } from '@material-ui/core';
import React, { FC, useCallback, useMemo } from 'react';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import AppProgress from '../../../Page/AppProgress';
import { DELETE_PROJECT_MUTATION } from './DeleteProjectAction.queries';
import DeleteIcon from '@material-ui/icons/Delete';
import { Noop } from '../../../../types';
import AppErrorMessage from '../../../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../../utils/errorPrefixRemover';
import createPreventAll from '../../../../utils/createPreventEventDefault';
import { useLoggedInUser } from '../../../../hooks/useLoggedInUser';
import { Role } from '../../../../types/graphql';
import { useMutation } from 'react-apollo';

interface IPropsProject {
  id: string;
  projectName: string;
  projectNumber: string;
  canBeDeleted: boolean;
}

interface IProps {
  project: IPropsProject;
  onDeleted: Noop;
}

export const DeleteProjectAction: FC<IProps> = ({ project, onDeleted }) => {
  const user = useLoggedInUser();

  const [deleteProject, { loading, error }] = useMutation(DELETE_PROJECT_MUTATION);

  const isAllowedToDelete = useMemo(() => user?.role === Role.SUPER_ADMIN, [user]);
  const handleProjectDeletion = useCallback(async () => {
    await deleteProject({ variables: { where: { id: project.id } } });
    onDeleted();
  }, [project.id, deleteProject, onDeleted]);

  const { requestConfirmation, confirmationDialog } = useConfirmationDialog({
    message: `Willst du das Projekt ${project.projectName} - ${project.projectNumber} wirklich löschen?`,
    title: 'Projekt löschen',
    onConfirm: handleProjectDeletion,
  });

  return (
    <>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      {confirmationDialog}
      <Tooltip title="Projekt löschen">
        <span>
          <IconButton
            aria-label="Projekt löschen"
            onClick={createPreventAll(requestConfirmation)}
            disabled={loading || !project.canBeDeleted || !isAllowedToDelete}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
