import gql from 'graphql-tag';

export const UPDATE_MACHINE = gql`
  mutation UpdateMachine($data: MachineUpdateInput!, $where: MachineWhereUniqueInput!) {
    updateMachine(data: $data, where: $where) {
      id
    }
  }
`;

export const CREATE_MACHINE = gql`
  mutation CreateMachine($data: MachineCreateInput!) {
    createMachine(data: $data) {
      id
    }
  }
`;
