import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Formik } from 'formik';
import { IAccountLogggerFormValues } from './types';
import { AccountingLoggerForm } from './AccountingLoggerForm';
import { useQuery } from 'react-apollo';
import AppProgress from '../../../components/Page/AppProgress';
import {
  GET_ACCOUNTING_ACTIVITY_TYPES,
  GET_ACCOUNTING_EMPLOYEES,
  GET_ACCOUNTING_LOG,
  GET_ACCOUNTING_LOG_DATE,
  GET_ACCOUNTING_MACHINES,
  GET_ACCOUNTING_MATERIALS,
  GET_ACCOUNTING_MISSIONS,
  GET_ACCOUNTING_SUBSIDIARIES,
  GET_ACCOUNTING_VEHICLES,
} from './AccountingLogger.queries';
import {
  GetAccountingEmployees,
  GetAccountingEmployeesVariables,
} from './types/GetAccountingEmployees';
import {
  GetAccountingMissions,
  GetAccountingMissionsVariables,
} from './types/GetAccountingMissions';
import {
  GetAccountingMaterials,
  GetAccountingMaterialsVariables,
} from './types/GetAccountingMaterials';
import {
  GetAccountingVehicles,
  GetAccountingVehiclesVariables,
} from './types/GetAccountingVehicles';
import { someLoading } from '../utils/someLoading';
import { GetAccountingActivityTypes } from './types/GetAccountingActivityTypes';
import AppErrorMessage from '../../../components/Page/AppErrorMessage';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import {
  GetAccountingMachines,
  GetAccountingMachinesVariables,
} from './types/GetAccountingMachines';
import {
  GetAccountingSubsidiaries,
  GetAccountingSubsidiariesVariables,
} from './types/GetAccountingSubsidiaries';
import { GetAccountingLog, GetAccountingLogVariables } from './types/GetAccountingLog';
import { fromAccountingLog, IParsedAccountingLog } from './utils/accountingItems';
import { toDate } from '../../../utils/durations';
import { orEmpty } from './utils/orEmpty';
import { AccountingReportDate, AccountingReportDateVariables } from './types/AccountingReportDate';

const manual = () => console.error('implementation error: only manual submit supported atm');

/**
 * The accounting logger is an easy way for back office workers to log accounting items for an accounting day
 * and a group working on multiple missions
 * @constructor
 */
export const AccountingLogger: React.FC<RouteComponentProps<{ id?: string }>> = React.memo(
  ({ match }) => {
    // todo only fetch realistic values, e.g. only employees that are actually employeed on the accounting day
    const subsidiaries = useQuery<GetAccountingSubsidiaries, GetAccountingSubsidiariesVariables>(
      GET_ACCOUNTING_SUBSIDIARIES,
    );
    const employees = useQuery<GetAccountingEmployees, GetAccountingEmployeesVariables>(
      GET_ACCOUNTING_EMPLOYEES,
    );
    const missions = useQuery<GetAccountingMissions, GetAccountingMissionsVariables>(
      GET_ACCOUNTING_MISSIONS,
    );
    const materials = useQuery<GetAccountingMaterials, GetAccountingMaterialsVariables>(
      GET_ACCOUNTING_MATERIALS,
    );
    const vehicles = useQuery<GetAccountingVehicles, GetAccountingVehiclesVariables>(
      GET_ACCOUNTING_VEHICLES,
    );
    const machines = useQuery<GetAccountingMachines, GetAccountingMachinesVariables>(
      GET_ACCOUNTING_MACHINES,
    );
    const activityTypes = useQuery<GetAccountingActivityTypes>(GET_ACCOUNTING_ACTIVITY_TYPES);

    const accountingLogId = match.params?.id;

    const accountingLogDate = useQuery<AccountingReportDate, AccountingReportDateVariables>(
      GET_ACCOUNTING_LOG_DATE,
      {
        skip: accountingLogId == null,
        variables: {
          where: {
            id: accountingLogId,
          },
        },
      },
    );
    const accountingLogDateReportDate = accountingLogDate.data?.report?.reportDate;
    const accountingLog = useQuery<GetAccountingLog, GetAccountingLogVariables>(
      GET_ACCOUNTING_LOG,
      {
        skip: accountingLogId == null || accountingLogDateReportDate == null,
        // always use the most up to date version
        fetchPolicy: 'network-only',
        variables: {
          where: {
            id: accountingLogId,
          },
          day: accountingLogDateReportDate
            ? toDate(accountingLogDateReportDate, '12:00')
            : // can't really happen
              new Date(),
        },
      },
    );

    const { values: initialValues, calculatePreviewItems }: IParsedAccountingLog = useMemo(
      () => fromAccountingLog(activityTypes.data?.activityTypes, accountingLog.data?.report),
      [accountingLog.data, activityTypes],
    );

    const loading = someLoading(
      accountingLogDate,
      accountingLog,
      subsidiaries,
      employees,
      missions,
      materials,
      vehicles,
      machines,
      activityTypes,
    );

    return (
      <>
        {loading ? <AppProgress /> : null}

        {subsidiaries.error && (
          <AppErrorMessage message={errorPrefixRemover(subsidiaries.error.message)} />
        )}
        {employees.error && (
          <AppErrorMessage message={errorPrefixRemover(employees.error.message)} />
        )}
        {missions.error && <AppErrorMessage message={errorPrefixRemover(missions.error.message)} />}
        {materials.error && (
          <AppErrorMessage message={errorPrefixRemover(materials.error.message)} />
        )}
        {vehicles.error && <AppErrorMessage message={errorPrefixRemover(vehicles.error.message)} />}
        {machines.error && <AppErrorMessage message={errorPrefixRemover(machines.error.message)} />}
        {activityTypes.error && (
          <AppErrorMessage message={errorPrefixRemover(activityTypes.error.message)} />
        )}
        {accountingLog.error && (
          <AppErrorMessage message={errorPrefixRemover(accountingLog.error.message)} />
        )}
        {accountingLogDate.error && (
          <AppErrorMessage message={errorPrefixRemover(accountingLogDate.error.message)} />
        )}

        <Formik<IAccountLogggerFormValues>
          initialValues={initialValues}
          enableReinitialize
          onSubmit={manual}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
        >
          <AccountingLoggerForm
            accountingLogId={accountingLogId}
            onRefetchAccountingLog={accountingLog.refetch}
            subsidiaries={orEmpty(subsidiaries.data?.subsidiaries)}
            employees={orEmpty(employees.data?.employees)}
            missions={orEmpty(missions.data?.missions)}
            materials={orEmpty(materials.data?.activeMaterialCatalog?.materials)}
            vehicles={orEmpty(vehicles.data?.vehicles)}
            machines={orEmpty(machines.data?.machines)}
            activityTypes={orEmpty(activityTypes.data?.activityTypes)}
            calculatePreviewItems={calculatePreviewItems}
          />
        </Formik>
      </>
    );
  },
);
