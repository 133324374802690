import {
  mdiArchive,
  mdiFileExcel,
  mdiFileImage,
  mdiFilePdf,
  mdiFileWord,
  mdiMicrosoftOutlook,
} from '@mdi/js';

const acceptedMimeTypes = {
  'image/png': mdiFileImage,
  'image/jpeg': mdiFileImage,
  'application/msword': mdiFileWord,
  'application/vnd.ms-excel': mdiFileExcel,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': mdiFileWord,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': mdiFileExcel,
  'application/vnd.ms-outlook': mdiMicrosoftOutlook,
  'application/pdf': mdiFilePdf,
  'application/zip': mdiArchive,
  'application/x-7z-compressed': mdiArchive,
  'application/x-rar-compressed': mdiArchive,
};

export type AcceptedMimeType = keyof typeof acceptedMimeTypes;

export default acceptedMimeTypes;
