/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActivityTypeCategory {
  BEZABSENZ = "BEZABSENZ",
  FAHRZEUGE = "FAHRZEUGE",
  FREMD = "FREMD",
  MASCHINEN = "MASCHINEN",
  MATERIAL = "MATERIAL",
  PRODUKTIV = "PRODUKTIV",
  SPESEN = "SPESEN",
  UNBEZABSENZ = "UNBEZABSENZ",
  ZUSCHLAG = "ZUSCHLAG",
}

export enum BOQStatus {
  L1 = "L1",
  L2 = "L2",
  L3 = "L3",
  L4 = "L4",
  L5 = "L5",
  L6 = "L6",
  L7 = "L7",
  L8 = "L8",
}

export enum BOQTaskTypes {
  AUSSEN = "AUSSEN",
  DM = "DM",
  FGSO = "FGSO",
  GARAGE = "GARAGE",
  HALLE = "HALLE",
  INNEN = "INNEN",
  NACHMARKIERUNG = "NACHMARKIERUNG",
  NEUMARKIERUNG = "NEUMARKIERUNG",
  PARKPLATZ = "PARKPLATZ",
  SIGNALISATIONEN = "SIGNALISATIONEN",
  SPIELFELD = "SPIELFELD",
  TAKTIL = "TAKTIL",
}

export enum BOQType {
  AB = "AB",
  B_GDE = "B_GDE",
  B_KT = "B_KT",
  PRIVATE_MARKIERUNGEN = "PRIVATE_MARKIERUNGEN",
  SPA = "SPA",
  UH_GDE = "UH_GDE",
  UH_KT = "UH_KT",
}

export enum BillOfQuantityEntityType {
  OFFER = "OFFER",
  ORDER = "ORDER",
}

export enum BillStatus {
  FIXIERT = "FIXIERT",
  IN_ABKLAERUNG_INTERN = "IN_ABKLAERUNG_INTERN",
  KONTROLLRECHNUNG = "KONTROLLRECHNUNG",
  NEU = "NEU",
  STORNIERT = "STORNIERT",
}

export enum BillTitle {
  AKONTORECHNUNG = "AKONTORECHNUNG",
  AUSMASS = "AUSMASS",
  GUTSCHRIFT = "GUTSCHRIFT",
  RECHNUNG = "RECHNUNG",
  SCHLUSSRECHNUNG = "SCHLUSSRECHNUNG",
  TEILRECHNUNG = "TEILRECHNUNG",
}

export enum ChargeType {
  COLLECTIVEACCOUNT = "COLLECTIVEACCOUNT",
  EMPLOYEE = "EMPLOYEE",
  MACHINE = "MACHINE",
  MATERIAL = "MATERIAL",
  MISSION = "MISSION",
  VEHICLE = "VEHICLE",
}

export enum Color {
  BLACK = "BLACK",
  BROWN = "BROWN",
  DARK_BLUE = "DARK_BLUE",
  DARK_YELLOW = "DARK_YELLOW",
  GREEN = "GREEN",
  GREY = "GREY",
  LIGHT_BLUE = "LIGHT_BLUE",
  LIGHT_YELLOW = "LIGHT_YELLOW",
  RED = "RED",
  ROSE = "ROSE",
  VIOLET = "VIOLET",
  YELLOW = "YELLOW",
}

export enum EditableHistoryItemFieldType {
  EMPLOYEE_FUNCTION = "EMPLOYEE_FUNCTION",
  EMPLOYEE_SUBSIDIARY = "EMPLOYEE_SUBSIDIARY",
  MACHINE_DRIVER = "MACHINE_DRIVER",
  MACHINE_SUBSIDIARY = "MACHINE_SUBSIDIARY",
  VEHICLE_DRIVER = "VEHICLE_DRIVER",
  VEHICLE_SUBSIDIARY = "VEHICLE_SUBSIDIARY",
}

export enum EmployeeFunctionAcronym {
  BL = "BL",
  BO = "BO",
  DP = "DP",
  EC = "EC",
  FL = "FL",
  GF = "GF",
  HA = "HA",
  MA = "MA",
  TB = "TB",
  TM = "TM",
  VW = "VW",
}

export enum FolderCategory {
  AUFTRAG = "AUFTRAG",
  DOKUMENTE = "DOKUMENTE",
  FAKTURA = "FAKTURA",
  OFFERTE = "OFFERTE",
}

export enum ItemType {
  A = "A",
  E = "E",
  N = "N",
  PP = "PP",
}

export enum MachineCategory {
  DEMARKIERMASCHINE = "DEMARKIERMASCHINE",
  DIVERS = "DIVERS",
  GROSSMARKIERMASCHINE = "GROSSMARKIERMASCHINE",
  HANDGEFUEHRT = "HANDGEFUEHRT",
  KLEINGERAETE = "KLEINGERAETE",
  MK3 = "MK3",
  SPIELFELDMASCHINE = "SPIELFELDMASCHINE",
}

export enum MachineSkill {
  AUFGELEGT = "AUFGELEGT",
  BORDCOMPUTER = "BORDCOMPUTER",
  FARBE = "FARBE",
  GPS = "GPS",
  NineEightTwo = "NineEightTwo",
  ON_LINE = "ON_LINE",
  STRUKTUR = "STRUKTUR",
  TAKTIL = "TAKTIL",
}

export enum MaterialType {
  DIVERSES = "DIVERSES",
  FOLIE = "FOLIE",
  FRAESEN = "FRAESEN",
  HM = "HM",
  KP_A = "KP_A",
  KP_S = "KP_S",
  KP_T = "KP_T",
  KSP = "KSP",
  M66 = "M66",
  PERLEN = "PERLEN",
}

export enum MissionExtraInformation {
  D = "D",
  NA = "NA",
  SA = "SA",
  SO = "SO",
}

export enum MissionManualLockingStatus {
  BLOCKED = "BLOCKED",
  UN_BLOCKED = "UN_BLOCKED",
}

export enum MissionStatusType {
  BD = "BD",
  FT = "FT",
  KB = "KB",
  KW = "KW",
  PB = "PB",
  VD = "VD",
}

export enum MissionSurfaceType {
  ALTBELAG = "ALTBELAG",
  BETON = "BETON",
  FLUESTERBELAG = "FLUESTERBELAG",
  GUSSASPHALT = "GUSSASPHALT",
  GUSSASPHALT_GLAENZEND = "GUSSASPHALT_GLAENZEND",
  KOPFSTEINPFLASTER = "KOPFSTEINPFLASTER",
  KUNSTSTOFF = "KUNSTSTOFF",
  NEUBELAG = "NEUBELAG",
  SPEZIELL = "SPEZIELL",
  VERBUNDSTEIN = "VERBUNDSTEIN",
}

export enum MissionWorkTypes {
  AUSSEN = "AUSSEN",
  DEMARKIERUNG = "DEMARKIERUNG",
  GARAGE = "GARAGE",
  HALLE = "HALLE",
  INNEN = "INNEN",
  NACHMARKIERUNG = "NACHMARKIERUNG",
  NEUMARKIERUNG = "NEUMARKIERUNG",
}

export enum NotReceived {
  DIVERSES = "DIVERSES",
  KLEMMFIX = "KLEMMFIX",
  PUENTENER = "PUENTENER",
  ROBERIT_LABOSIM = "ROBERIT_LABOSIM",
  SIGNAL = "SIGNAL",
  TRAUFFER = "TRAUFFER",
  UNBEKANNT = "UNBEKANNT",
}

export enum PStatus {
  P0 = "P0",
  P1 = "P1",
  P2 = "P2",
  P3 = "P3",
  P4 = "P4",
  P5 = "P5",
  P6 = "P6",
}

export enum PdfPrintStoreIn {
  PROJECT = "PROJECT",
}

export enum PdfPrintType {
  BILL_OF_QUANTITY = "BILL_OF_QUANTITY",
  MISSION = "MISSION",
  RAPPORT = "RAPPORT",
}

export enum RejectedReason {
  DONT_EXECUTE = "DONT_EXECUTE",
  NOT_RECEIVED = "NOT_RECEIVED",
  REPLACED = "REPLACED",
}

export enum Role {
  ADMIN = "ADMIN",
  EQUIPENCHEF = "EQUIPENCHEF",
  SUPER_ADMIN = "SUPER_ADMIN",
  USER = "USER",
}

export enum RowType {
  CONTAINER = "CONTAINER",
  INNER = "INNER",
}

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}

export enum SurfaceCondition {
  FEUCHT = "FEUCHT",
  HEISS = "HEISS",
  KALT = "KALT",
  NASS = "NASS",
  SCHMUTZIG = "SCHMUTZIG",
  TROCKEN = "TROCKEN",
}

export enum TableType {
  BILL = "BILL",
  BILL_MISSION = "BILL_MISSION",
  MEASUREMENT = "MEASUREMENT",
  MISSION = "MISSION",
  OFFER = "OFFER",
  ORDER = "ORDER",
  ORDER_MEASUREMENT = "ORDER_MEASUREMENT",
  ORDER_MISSION = "ORDER_MISSION",
}

export enum UpsertAddressBookItemType {
  BILLING = "BILLING",
  SHIPPING = "SHIPPING",
}

export enum VehicleCategory {
  ANHAENGER = "ANHAENGER",
  BUS = "BUS",
  PW = "PW",
}

export enum WeatherType {
  BEDECKT = "BEDECKT",
  INNENBEREICH = "INNENBEREICH",
  REGEN = "REGEN",
  SCHNEE = "SCHNEE",
  SONNIG = "SONNIG",
  WECHSELHAFT = "WECHSELHAFT",
}

export interface AccountingEntityUpsertDataInput {
  id?: string | null;
  costPerUnit: number;
  type: ChargeType;
  subsidiary?: WhereIdInput | null;
  workload?: number | null;
  employee?: WhereIdInput | null;
  material?: WhereIdInput | null;
  machine?: WhereIdInput | null;
  vehicle?: WhereIdInput | null;
  collectiveAccount?: WhereIdInput | null;
  mission?: WhereIdIntInput | null;
}

export interface AccountingEntityUpsertInput {
  where?: AccountingEntityWhereUniqueInput | null;
  data: AccountingEntityUpsertDataInput;
}

export interface AccountingEntityWhereUniqueInput {
  id?: string | null;
}

export interface AccountingItemInput {
  chargeConstraint: string;
  activityDate: any;
  startTime?: string | null;
  endTime?: string | null;
  amount?: number | null;
  comment?: string | null;
  creditedTo: AccountingEntityUpsertInput;
  chargedTo: AccountingEntityUpsertInput;
}

export interface AccountingItemWhereFlatInput {
  id?: number | null;
  activityDate?: RangeQueryDate | null;
  createdAt?: RangeQueryDate | null;
  createdBy?: string | null;
  parent?: number | null;
  activityType?: number | null;
  whatNameOne?: string | null;
  whatNameTwo?: string | null;
  unit?: string | null;
  costPerUnit?: RangeQueryInt | null;
  creditedTo?: string | null;
  creditedToNameOne?: string | null;
  creditedToNameTwo?: string | null;
  creditedToSubsidiary?: string | null;
  chargedTo?: string | null;
  projectNumber?: string | null;
  chargedToNameOne?: string | null;
  chargedToNameTwo?: string | null;
  chargedToSubsidiary?: string | null;
  startTime?: RangeQueryString | null;
  endTime?: RangeQueryString | null;
  amount?: RangeQueryFloat | null;
  sum?: RangeQueryFloat | null;
  comment?: string | null;
}

export interface AccountingItemWhereUniqueInput {
  id: number;
}

export interface AccountingReportWhereUniqueInput {
  id?: string | null;
}

export interface AddRowToDataTableInput {
  containerId?: string | null;
  type: RowType;
  row: DataTableRowInput;
}

export interface AddressBookItemUpdateInput {
  lineOne?: string | null;
  lineTwo?: string | null;
  lineThree?: string | null;
  lineFour?: string | null;
  abacusAddress?: AddressWhereUniqueInput | null;
  contactPerson?: ContactPersonWhereUniqueInput | null;
}

export interface AddressBookItemWhereUniqueInput {
  id: number;
}

export interface AddressBookUpdateInput {
  shippingAddress?: AddressBookItemWhereUniqueInput | null;
  billingAddress?: AddressBookItemWhereUniqueInput | null;
  projectAddress?: string | null;
}

export interface AddressBookWhereUniqueInput {
  projectNumber: string;
}

export interface AddressSearchInput {
  shortName?: string | null;
  id?: string | null;
  name?: string | null;
  firstName?: string | null;
  postCode?: string | null;
  city?: string | null;
  streetAndNumber?: string | null;
  phone?: string | null;
  phoneTwo?: string | null;
  email?: string | null;
  department?: string | null;
}

export interface AddressUpdateInput {
  name?: string | null;
  department?: string | null;
  streetAndNumber?: string | null;
  postCode?: string | null;
  postbox?: string | null;
  city?: string | null;
  phone?: string | null;
  email?: string | null;
  additionalOne?: string | null;
  additionalTwo?: string | null;
  secondResponsible?: string | null;
  comment?: string | null;
  customerConditions?: string | null;
}

export interface AddressUpsertWhereInput {
  id?: number | null;
  addressBook: AddressBookWhereUniqueInput;
  addressBookItem?: AddressBookItemWhereUniqueInput | null;
  addressBookItemType: UpsertAddressBookItemType;
}

export interface AddressWhereUniqueInput {
  id: number;
}

export interface BillCreateInput {
  date: string;
  number: string;
  titleDropdown?: string | null;
  status?: BillStatus | null;
  billDate: string;
  billOfQuantity?: BillOfQuantityWhereUniqueInput | null;
  title?: string | null;
}

export interface BillItemWhereUniqueInput {
  id?: string | null;
}

export interface BillLocationWhereUniqueInput {
  id: string;
}

export interface BillOfQuantityOfferConditionAssignInput {
  overrideText?: string | null;
  active?: boolean | null;
}

export interface BillOfQuantityOfferConditionWhereUniqueInput {
  billOfQuantityId: string;
  offerConditionId: string;
}

export interface BillOfQuantityStatusUpdateInput {
  comment?: string | null;
  effektivSum?: string | null;
  notReceived?: NotReceived | null;
  reason?: RejectedReason | null;
  status: BOQStatus;
}

export interface BillOfQuantityUpdateInput {
  name?: string | null;
  taskTypes?: BOQTaskTypes[] | null;
  type?: BOQType | null;
  hasScaleDiscount?: boolean | null;
  hasScaleDiscountAccumulate?: boolean | null;
  statuses?: BillOfQuantityStatusUpdateInput | null;
  skontoDeadline?: number | null;
  paymentDeadline?: number | null;
  date?: any | null;
  freeTextOne?: string | null;
  freeTextTwo?: string | null;
  freeTextThree?: string | null;
  freeTextFour?: string | null;
  isBonusApplicable?: boolean | null;
}

export interface BillOfQuantityWhereInput {
  project?: ProjectWhereUniqueInput | null;
  id?: string | null;
  status?: StatusWhereInput | null;
  entityType?: BillOfQuantityEntityType | null;
}

export interface BillOfQuantityWhereUniqueInput {
  id?: string | null;
}

export interface BillWhereInput {
  project?: ProjectWhereInput | null;
}

export interface BillWhereUniqueInput {
  id?: string | null;
}

export interface CatalogUpdateInput {
  useName?: string | null;
}

export interface CatalogWhereUniqueInput {
  id: string;
}

export interface ClientAuthInput {
  token?: string | null;
  role?: Role | null;
  name?: string | null;
}

export interface ClientColumnsSettingInput {
  __typename?: string | null;
  name?: string | null;
  id?: string | null;
}

export interface ClientPageInfoInput {
  id: string;
  hasNextPage: boolean;
}

export interface ColumnsSettingCreateInput {
  name?: string | null;
}

export interface ColumnsSettingUpdateInput {
  name?: string | null;
  tableSetting?: TableSettingUpdateInput | null;
}

export interface ColumnsSettingWhereUniqueInput {
  id?: string | null;
}

export interface ContactPersonUpdateInput {
  salutationCode?: number | null;
  firstName?: string | null;
  name?: string | null;
  phone?: string | null;
  phoneTwo?: string | null;
  phoneFour?: string | null;
  email?: string | null;
}

export interface ContactPersonUpsertWhereInput {
  id?: number | null;
  address: AddressWhereUniqueInput;
  addressBookItem: AddressBookItemWhereUniqueInput;
}

export interface ContactPersonWhereUniqueInput {
  id: number;
}

export interface CopyMissionCreateInput {
  name: string;
  executionDate?: string | null;
  surfaceConditions?: SurfaceCondition[] | null;
  temperature?: number | null;
  weatherType?: WeatherType | null;
  withOpenVolumes?: boolean | null;
}

export interface CreateItemFromBoilerplateInput {
  itemBoilerplateId: string;
  locationId: string;
  volume: number;
  appliedPrice?: number | null;
  freeText?: string | null;
}

export interface CursorLikePagination {
  after?: string | null;
  take: number;
}

export interface DataTableInput {
  rows: DataTableRowInput[];
}

export interface DataTableRowInput {
  __typename?: string | null;
  id: string;
  hidden?: boolean | null;
  data: string;
  containerRows?: DataTableRowInput[] | null;
  innerTableRows?: DataTableRowInput[] | null;
}

export interface DataTableRowWhereUniqueInput {
  id: string;
  tableType: TableType;
}

export interface DataTableWhereUniqueInput {
  id: TableType;
}

export interface DateFilterInput {
  from?: any | null;
  to?: any | null;
}

export interface DocumentWhereUniqInput {
  id?: string | null;
}

export interface DocumentsUpdateInput {
  deletedFiles?: (string | null)[] | null;
  uploads?: (any | null)[] | null;
}

export interface EditableHistoryItemCreateInput {
  value: string;
  createdBy: UserWhereUniqueInput;
  validFrom: any;
}

export interface EditableHistoryItemUpdateInput {
  value?: string | null;
  createdBy?: UserWhereUniqueInput | null;
  validFrom?: any | null;
}

export interface EditableHistoryItemWhereInput {
  fieldType: EditableHistoryItemFieldType;
  entityId: string;
}

export interface EditableHistoryItemWhereUniqueInput {
  id: string;
}

export interface EmployeeCreateInput {
  employeeNumber: string;
  firstName: string;
  lastName: string;
  subsidiary: SubsidiaryUpdateInput;
  photo?: DocumentsUpdateInput | null;
  workload: number;
  supervisor?: EmployeeWhereUniqueInput | null;
  user?: UserWhereUniqueInput | null;
  entryDate: any;
  leaveDate?: any | null;
  function: EmployeeFunctionWhereUniqueInput;
}

export interface EmployeeFunctionWhereUniqueInput {
  id?: string | null;
  acronym?: EmployeeFunctionAcronym | null;
}

export interface EmployeeUpdateInput {
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  subsidiary?: SubsidiaryUpdateInput | null;
  photo?: DocumentsUpdateInput | null;
  workload?: number | null;
  supervisor?: EmployeeWhereUniqueInput | null;
  user?: UserWhereUniqueInput | null;
  entryDate?: any | null;
  leaveDate?: any | null;
  isDeactivated?: boolean | null;
  function?: EmployeeFunctionWhereUniqueInput | null;
}

export interface EmployeeWhereInput {
  id?: string | null;
  isDeactivated?: boolean | null;
  subsidiary?: SubsidiaryWhereUniqueInput | null;
}

export interface EmployeeWhereUniqueInput {
  id?: string | null;
  employeeNumber?: string | null;
}

export interface EmployeesCalendarWhereInput {
  employees: string[];
  date: any;
}

export interface ImportCatalogInput {
  catalog: any;
  useName: string;
  isOverride?: boolean | null;
  isProjectCatalog?: boolean | null;
}

export interface InvoiceConditionCreateInput {
  sequenceNumber: number;
  name: string;
  percentageAmount?: number | null;
  absoluteAmount?: number | null;
  reference?: number | null;
}

export interface InvoiceConditionUpdateInput {
  sequenceNumber?: number | null;
  name?: string | null;
  percentageAmount?: number | null;
  absoluteAmount?: number | null;
  reference?: number | null;
}

export interface InvoiceConditionWhereInput {
  billOfQuantity?: BillOfQuantityWhereUniqueInput | null;
  bill?: BillWhereUniqueInput | null;
}

export interface InvoiceConditionWhereUniqueInput {
  id: string;
}

export interface ItemBoilerplateGroupUpdateInput {
  name?: string | null;
  itemBoilerplates?: ItemBoilerplateUpdateManyWithoutGroupInput | null;
}

export interface ItemBoilerplateGroupWhereUniqueInput {
  id: string;
}

export interface ItemBoilerplateUpdateInput {
  acronym?: string | null;
  catalog?: string | null;
  catalogMainGroup?: string | null;
  catalogMainGroupDescription?: string | null;
  catalogSection?: string | null;
  catalogSectionDescription?: string | null;
  catalogSubsection?: string | null;
  catalogSubsectionDescription?: string | null;
  category?: string | null;
  color?: string | null;
  materialColor?: Color | null;
  descriptionOne?: string | null;
  descriptionTwo?: string | null;
  dimensionOne?: string | null;
  dimensionTwo?: string | null;
  freeText?: string | null;
  markingStyle?: string | null;
  material?: string | null;
  productNumber: string;
  reflexion?: string | null;
  targetConsumptionPerUnit?: number | null;
  timeRequired?: number | null;
  unit?: string | null;
  scaleDiscounts?: (ScaleDiscountUpdateInput | null)[] | null;
}

export interface ItemBoilerplateUpdateManyWithoutGroupInput {
  connect?: ItemBoilerplateWhereUniqueInput[] | null;
  disconnect?: ItemBoilerplateWhereUniqueInput[] | null;
}

export interface ItemBoilerplateWhereInput {
  id?: string | null;
  acronym?: string | null;
  acronym_contains?: string | null;
  catalog?: CatalogWhereUniqueInput | null;
  catalogMainGroup_contains?: string | null;
  catalogMainGroupDescription_contains?: string | null;
  catalogSection_contains?: string | null;
  catalogSectionDescription_contains?: string | null;
  catalogSubsection_contains?: string | null;
  catalogSubsectionDescription_contains?: string | null;
  category_contains?: string | null;
  color_contains?: string | null;
  descriptionOne_contains?: string | null;
  descriptionTwo_contains?: string | null;
  dimensionOne_contains?: string | null;
  dimensionTwo_contains?: string | null;
  markingStyle_contains?: string | null;
  material?: string | null;
  material_contains?: string | null;
  productNumber_contains?: string | null;
  reflexion_contains?: string | null;
  unit_contains?: string | null;
}

export interface ItemBoilerplateWhereUniqueInput {
  id?: string | null;
}

export interface ItemWhereInput {
  acronym?: string | null;
  catalogMainGroup?: string | null;
  catalogMainGroupDescription?: string | null;
  catalogSection?: string | null;
  catalogSectionDescription?: string | null;
  catalogSubsection?: string | null;
  catalogSubsectionDescription?: string | null;
  category?: string | null;
  color?: string | null;
  comment?: string | null;
  descriptionOne?: string | null;
  descriptionTwo?: string | null;
  freeText?: string | null;
  markingStyle?: string | null;
  material?: string | null;
  materialColor?: Color | null;
  productNumber?: string | null;
  reflexion?: string | null;
  targetConsumptionPerUnit?: number | null;
  timeRequired?: number | null;
  type?: ItemType | null;
  unit?: string | null;
  volume?: number | null;
  isFinished?: boolean | null;
}

export interface ItemWhereUniqueInput {
  id: string;
}

export interface ItemsToMissionInput {
  id: string;
  amount?: number | null;
}

export interface ItemsWhereInput {
  id_in: string[];
  billOfQuantity: BillOfQuantityWhereUniqueInput;
}

export interface LocationUpdateInput {
  name?: string | null;
  comment?: string | null;
  nodeCoordinate?: string | null;
  startCoordinate?: string | null;
  endCoordinate?: string | null;
  documents?: DocumentsUpdateInput | null;
}

export interface LocationWhereInput {
  id_in?: string[] | null;
}

export interface LocationWhereUniqueInput {
  id: string;
}

export interface MachineCreateInput {
  inventoryNumber: string;
  projectNumber?: string | null;
  subsidiary?: SubsidiaryUpdateInput | null;
  registrationNumber?: string | null;
  rootNumber?: string | null;
  chassisNumber?: string | null;
  internalHourlyWage: number;
  nameOne: string;
  nameTwo?: string | null;
  driver?: string | null;
  releaseDate?: any | null;
  weight?: number | null;
  hoursOfOperation?: number | null;
  emissionsTestDate?: any | null;
  trafficOfficeTestDate?: any | null;
  lastInternalTestDate?: any | null;
  category: MachineCategory;
  skills?: MachineSkill[] | null;
  note?: string | null;
  isDeactivated?: boolean | null;
  documents?: DocumentsUpdateInput | null;
}

export interface MachineUpdateInput {
  inventoryNumber?: string | null;
  projectNumber?: string | null;
  subsidiary?: SubsidiaryUpdateInput | null;
  registrationNumber?: string | null;
  rootNumber?: string | null;
  chassisNumber?: string | null;
  internalHourlyWage?: number | null;
  nameOne?: string | null;
  nameTwo?: string | null;
  driver?: string | null;
  releaseDate?: any | null;
  weight?: number | null;
  hoursOfOperation?: number | null;
  emissionsTestDate?: any | null;
  trafficOfficeTestDate?: any | null;
  lastInternalTestDate?: any | null;
  category?: MachineCategory | null;
  skills?: MachineSkill[] | null;
  note?: string | null;
  isDeactivated?: boolean | null;
  documents?: DocumentsUpdateInput | null;
}

export interface MachineWhereInput {
  subsidiary?: SubsidiaryWhereUniqueInput | null;
  showDeactivated?: boolean | null;
}

export interface MachineWhereUniqueInput {
  id?: string | null;
  inventoryNumber?: string | null;
}

export interface MissionCreateInput {
  name: string;
  description?: string | null;
  executionDate?: string | null;
  workTypes?: MissionWorkTypes[] | null;
  callCustomer?: string | null;
  customerOnSite?: string | null;
  expectedWorkingTime?: number | null;
  expectedEquipmentOps?: number | null;
  expectedStaffNumber?: number | null;
  weatherType?: string | null;
  surfaceConditions?: SurfaceCondition[] | null;
  temperature?: number | null;
  externalServices?: string | null;
  surfaceType?: MissionSurfaceType[] | null;
  status?: MissionStatusUpdateInput | null;
  commentBranchLeaderToBranchLeader?: string | null;
  commentToBackoffice?: string | null;
  commentToEquipmentOperator?: string | null;
  extraInformation?: (MissionExtraInformation | null)[] | null;
  isCustomerOnSite?: boolean | null;
  isTimeFixed?: boolean | null;
  ec?: UserWhereUniqueInput | null;
  personResponsible?: UserWhereUniqueInput | null;
  projectAddress?: string | null;
}

export interface MissionItemLocationWhereInput {
  id_in?: string[] | null;
  mission_id_in: number[];
}

export interface MissionItemUpdateInput {
  planVolume?: number | null;
  openVolume?: number | null;
  isFinished?: boolean | null;
  finishedVolume?: number | null;
  comment?: string | null;
  customerComment?: string | null;
}

export interface MissionItemWhereInput {
  isFinished?: boolean | null;
  hideFullyInvoiced?: boolean | null;
  isMeasured?: boolean | null;
}

export interface MissionItemWhereUniqueInput {
  id?: string | null;
}

export interface MissionItemsToBillInput {
  id: string;
  amountVolume: number;
}

export interface MissionStatusUpdateInput {
  missionStatus?: MissionStatusType | null;
  calendarWeek?: number | null;
  date?: string | null;
  time?: string | null;
  postponementOne?: string | null;
  postponementTwo?: string | null;
  fromDate?: string | null;
  untilDate?: string | null;
}

export interface MissionUpdateInput {
  name?: string | null;
  description?: string | null;
  executionDate?: string | null;
  workTypes?: MissionWorkTypes[] | null;
  callCustomer?: string | null;
  customerOnSite?: string | null;
  expectedWorkingTime?: number | null;
  expectedEquipmentOps?: number | null;
  expectedStaffNumber?: number | null;
  weatherType?: string | null;
  surfaceConditions?: SurfaceCondition[] | null;
  temperature?: number | null;
  externalServices?: string | null;
  surfaceType?: MissionSurfaceType[] | null;
  status?: MissionStatusUpdateInput | null;
  commentBranchLeaderToBranchLeader?: string | null;
  commentToBackoffice?: string | null;
  commentToEquipmentOperator?: string | null;
  extraInformation?: (MissionExtraInformation | null)[] | null;
  manualLockingStatus?: MissionManualLockingStatus | null;
  ec?: UserWhereUniqueInput | null;
  isCustomerOnSite?: boolean | null;
  isTimeFixed?: boolean | null;
}

export interface MissionWhereInput {
  nextTwoWeeks?: boolean | null;
  extraInformation?: (MissionExtraInformation | null)[] | null;
  colorCodings?: (Color | null)[] | null;
  isSignals?: boolean | null;
  workTypes?: MissionWorkTypes[] | null;
  project?: ProjectWhereInput | null;
  id?: number | null;
  id_in?: number[] | null;
}

export interface MissionWhereUniqueInput {
  id?: number | null;
}

export interface OfferConditionCreateInput {
  name: string;
  default?: boolean | null;
  text: string;
}

export interface OfferConditionUpdateInput {
  name?: string | null;
  default?: boolean | null;
  text?: string | null;
}

export interface OfferConditionWhereUniqueInput {
  id: string;
}

/**
 * format of field is field name suffixed by _ASC or _DESC
 */
export interface OrderByInput {
  fieldName: string;
  isComputed?: boolean | null;
}

export interface PageInput {
  pageNumber: number;
  amountPerPage: number;
}

export interface PdfPrintIncludeInput {
  locations?: boolean | null;
  price?: boolean | null;
  summary?: boolean | null;
  measurementAccepted?: boolean | null;
  daySum?: boolean | null;
  confirmation?: boolean | null;
  sortByLocations?: boolean | null;
  deliveryNote?: boolean | null;
}

export interface PdfPrintWhereInput {
  minDate?: any | null;
  maxDate?: any | null;
  id: string;
}

export interface ProjectUpdateInput {
  catalog?: CatalogWhereUniqueInput | null;
  commentToBackoffice?: string | null;
  commentToBranchLeader?: string | null;
  commentToExecutives?: string | null;
  contactPerson?: string | null;
  customerInput?: string | null;
  documents?: DocumentsUpdateInput | null;
  isSpecialProject?: boolean | null;
  parent?: ProjectWhereUniqueInput | null;
  personResponsible?: UserUpdateInput | null;
  projectName?: string | null;
  projectNumber?: string | null;
  staffTariff?: number | null;
  status?: PStatus | null;
  subsidiary?: SubsidiaryUpdateInput | null;
  hasSeparateFileStorage?: boolean | null;
}

export interface ProjectWhereInput {
  id?: string | null;
  projectNumber?: string | null;
  subsidiary?: SubsidiaryWhereUniqueInput | null;
  archived?: boolean | null;
  isRootProject?: boolean | null;
}

export interface ProjectWhereUniqueInput {
  id?: string | null;
  projectNumber?: string | null;
  subsidiary?: SubsidiaryWhereUniqueInput | null;
}

export interface RangeQueryDate {
  eq?: any | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
}

export interface RangeQueryFloat {
  eq?: number | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
}

export interface RangeQueryInt {
  eq?: number | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
}

export interface RangeQueryString {
  eq?: string | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
}

export interface ScaleDiscountUpdateInput {
  id?: string | null;
  pricePerUnit?: number | null;
  threshhold?: number | null;
}

export interface SearchInput {
  columns: string[];
  terms: string[];
}

export interface StatusWhereInput {
  status_not_in?: BOQStatus[] | null;
}

export interface SubsidiaryUpdateInput {
  name?: string | null;
}

export interface SubsidiaryWhereUniqueInput {
  id?: string | null;
  name?: string | null;
}

export interface TableSettingUpdateInput {
  tableName?: string | null;
  columns?: (string | null)[] | null;
}

export interface TableSettingWhereUniqueInput {
  id?: string | null;
}

export interface UpdateDataTableRowInput {
  data?: string | null;
  hidden?: boolean | null;
  partial?: boolean | null;
  innerTableRows?: DataTableRowInput[] | null;
}

export interface UpdateDataTableRowsInput {
  data: UpdateDataTableRowInput;
  where: DataTableRowWhereUniqueInput;
}

export interface UpdateDocumentDataInput {
  name?: string | null;
  category?: FolderCategory | null;
}

export interface UpdateDocumentsInput {
  data: UpdateDocumentDataInput;
  where: DocumentWhereUniqInput;
}

export interface UpdateItemInput {
  acronym?: string | null;
  appliedPrice?: number | null;
  applyScaleDiscount?: boolean | null;
  catalogMainGroup?: string | null;
  catalogMainGroupDescription?: string | null;
  catalogSection?: string | null;
  catalogSectionDescription?: string | null;
  catalogSubsection?: string | null;
  catalogSubsectionDescription?: string | null;
  category?: string | null;
  color?: string | null;
  comment?: string | null;
  customerComment?: string | null;
  documents?: DocumentsUpdateInput | null;
  descriptionOne?: string | null;
  descriptionTwo?: string | null;
  dimensionOne?: string | null;
  dimensionTwo?: string | null;
  freeText?: string | null;
  markingStyle?: string | null;
  material?: string | null;
  materialColor?: Color | null;
  productNumber?: string | null;
  reflexion?: string | null;
  targetConsumptionPerUnit?: number | null;
  timeRequired?: number | null;
  type?: ItemType | null;
  unit?: string | null;
  volume?: number | null;
  isFinished?: boolean | null;
  nodeCoordinate?: string | null;
  startCoordinate?: string | null;
  endCoordinate?: string | null;
}

export interface UploadToProjectRepoInput {
  project: ProjectWhereUniqueInput;
  folder: FolderCategory;
}

export interface UpsertAccountingItem {
  parent?: number | null;
  chargeConstraint: string;
  activityDate: any;
  startTime?: string | null;
  endTime?: string | null;
  amount?: number | null;
  comment?: string | null;
  creditedTo: AccountingEntityUpsertDataInput;
  chargedTo: AccountingEntityUpsertDataInput;
}

export interface UpsertAccountingReportInput {
  employees: EmployeeWhereUniqueInput[];
  reportDate: any;
  items?: AccountingItemInput[] | null;
}

export interface UserCreateInput {
  name?: string | null;
  role?: Role | null;
  email?: string | null;
  password?: string | null;
  signature?: DocumentsUpdateInput | null;
  subsidiaries?: string[] | null;
}

export interface UserUpdateInput {
  name?: string | null;
  role?: Role | null;
  email?: string | null;
  archived?: boolean | null;
  password?: string | null;
  signature?: DocumentsUpdateInput | null;
  subsidiaries?: string[] | null;
}

export interface UserWhereInput {
  archived?: boolean | null;
}

export interface UserWhereUniqueInput {
  id?: string | null;
  name?: string | null;
}

export interface VehicleCreateInput {
  inventoryNumber: string;
  projectNumber?: string | null;
  category: VehicleCategory;
  subsidiary?: SubsidiaryWhereUniqueInput | null;
  driver?: string | null;
  nameOne: string;
  nameTwo?: string | null;
  releaseDate?: any | null;
  payload?: number | null;
  weight?: number | null;
  registrationNumber?: string | null;
  kilometers?: number | null;
  internalHourlyWage: number;
  emissionsTestDate?: any | null;
  trafficOfficeDate?: any | null;
  note?: string | null;
  trailerLoad?: string | null;
  fuelCardOne?: string | null;
  fuelCardTwo?: string | null;
  pinOne?: string | null;
  pinTwo?: string | null;
  tcsAsof?: string | null;
  eVignette?: string | null;
  serialNumber?: string | null;
  chassisNumber?: string | null;
  lastInternalCheckAt?: any | null;
  documents?: DocumentsUpdateInput | null;
}

export interface VehicleUpdateInput {
  inventoryNumber?: string | null;
  projectNumber?: string | null;
  category?: VehicleCategory | null;
  subsidiary?: SubsidiaryWhereUniqueInput | null;
  driver?: string | null;
  nameOne?: string | null;
  nameTwo?: string | null;
  releaseDate?: any | null;
  payload?: number | null;
  weight?: number | null;
  registrationNumber?: string | null;
  kilometers?: number | null;
  internalHourlyWage?: number | null;
  emissionsTestDate?: any | null;
  trafficOfficeDate?: any | null;
  note?: string | null;
  isDeactivated?: boolean | null;
  trailerLoad?: string | null;
  fuelCardOne?: string | null;
  fuelCardTwo?: string | null;
  pinOne?: string | null;
  pinTwo?: string | null;
  tcsAsof?: string | null;
  eVignette?: string | null;
  serialNumber?: string | null;
  chassisNumber?: string | null;
  lastInternalCheckAt?: any | null;
  documents?: DocumentsUpdateInput | null;
}

export interface VehicleWhereInput {
  id?: string | null;
  isDeactivated?: boolean | null;
  subsidiary?: SubsidiaryWhereUniqueInput | null;
}

export interface VehicleWhereUniqueInput {
  id?: string | null;
}

export interface WhereIdInput {
  id: string;
}

export interface WhereIdIntInput {
  id: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
