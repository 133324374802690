import React, { useCallback } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { omit } from 'lodash';
import { BILL_TABLE_QUERY } from './billTable.queries';
import { BillTable as BillTableType, BillTableVariables } from './types/BillTable';
import { mapBills } from './billTable.mapper';
import { DataTableBody, DataTableHotKeysWrapper } from '../../DataTable';
import { billColumns } from '../../../pages/Projects/TabBills/BillSelector/BillSelector';
import { IDataTableColumn } from '../../DataTable/types';
import { SELECTED_SUBSIDIARY_QUERY } from '../../../pages/Missions/queries';
import { Subsidiary } from '../../../pages/Missions/types/Subsidiary';
import { useSearchState, Search } from '../../Search/Search';
import { usePaginationState, IPaginationState } from '../../Pagination/Pagination.utils';
import { Pagination } from '../../Pagination';
import { useCount } from '../../../hooks/useCount';
import { CountEntity } from '../../../hooks/useCount/useCount.queries';
import { DownloadCsvAction } from '../../Actions/Download/Csv';
import { formatDate } from '../../../utils/format/date';
import { isComputedField } from '../../../utils/order/isComputedField';
import { BILL_COMPUTED_FIELDS } from './bill.consts';
import { createOrderByFieldName } from '../../../utils/order/createOrderByFieldName';
import AppProgress from '../../Page/AppProgress';

const billTableColumns: IDataTableColumn[] = [
  ...billColumns,
  {
    id: 'project.projectNumber',
    label: 'Projektnummer',
  },
  {
    id: 'project.projectName',
    label: 'Projektbezeichnung',
  },
];

export const BillTable: React.FC = () => {
  const searchState = useSearchState();

  const {
    data: subsidData,
    loading: subsidLoading,
    error: subsidError,
  } = useQuery<Subsidiary>(SELECTED_SUBSIDIARY_QUERY);

  const { data, loading, error, refetch, variables } = useQuery<BillTableType, BillTableVariables>(
    BILL_TABLE_QUERY,
    {
      variables: {
        ...(subsidData &&
          subsidData.selectedSubsidiary !== 'all' && {
            subsidiaryId: subsidData.selectedSubsidiary,
          }),
        page: {
          pageNumber: 0,
          amountPerPage: 25,
        },
      },
    },
  );

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        page: {
          pageNumber: state.page,
          amountPerPage: state.rowsPerPage,
        },
      });
    },
    [refetch],
  );

  const count = useCount({
    entity: CountEntity.BILL,
    variables: omit(variables, 'page'),
  });

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  const history = useHistory();

  if (!data || subsidLoading || !subsidData) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  if (subsidError) {
    console.log(subsidError);
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <DataTableHotKeysWrapper
        innerTableRows={mapBills(data?.bills ?? [])}
        pagination={<Pagination paginationState={paginationState} />}
        search={
          <Search
            columns={[
              'title',
              'number',
              'date',
              'status',
              'project.projectNumber',
              'project.projectName',
            ]}
            searchState={searchState}
            onSubmit={(search) => refetch({ search })}
            loading={loading}
          />
        }
        options={{
          filterText: searchState.searchTerm,
          tableName: 'ALL_BILLS',
          isReordering: loading,
          levels: [
            {
              columns: billTableColumns,
              onRowClick: (row) =>
                history.push(`/projekte/${row.data.project.projectNumber}/rechnungen`),
            },
          ],
          onChangeSort: async (fieldName, order) => {
            await refetch({
              orderBy: {
                fieldName: createOrderByFieldName(fieldName, order),
                isComputed: isComputedField(fieldName, BILL_COMPUTED_FIELDS),
              },
            });
          },
        }}
      >
        {(context) => (
          <DataTableBody
            context={context}
            toolbarProps={{
              actions: (
                <DownloadCsvAction
                  entityType="BILL"
                  csvName={`Rechnungen_${formatDate()}`}
                  variables={{
                    where: variables.subsidiaryId
                      ? {
                          project: { subsidiary: { id: variables.subsidiaryId } },
                        }
                      : undefined,
                    search: variables.search,
                  }}
                />
              ),
            }}
          />
        )}
      </DataTableHotKeysWrapper>
    </>
  );
};
