import React, { KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, TextField, InputAdornment, RootRef } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import { ISearchColumn } from './SearchWithDropdown';

interface IProps {
  onInput?: TextFieldProps['onInput'];
  onChange?: TextFieldProps['onChange'];
  onTextChanged?: (txt: string) => void;
  columns: ISearchColumn[];
  activeColumn: string;
  delimiter: string;
  showColumns?: boolean; // used to show columns even when onBlur happened
  justify?: 'center' | 'flex-start' | 'flex-end';
  fullWidth?: boolean;
  keepFocus?: boolean;
}

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
});

export const SearchColumns: React.FC<IProps> = ({
  columns,
  activeColumn,
  onInput,
  delimiter,
  justify = 'center',
  onTextChanged,
  onChange,
  showColumns: propShowColumns,
  fullWidth,
  keepFocus,
}) => {
  const [showColumns, setShowColumns] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const ref = React.useRef<HTMLInputElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const classes = useStyles();

  const onShowColumns = useCallback(() => setShowColumns(true), [setShowColumns]);
  const onHideColumns = useCallback(() => setShowColumns(false), [setShowColumns]);
  const handleOnBlur = useCallback(() => {
    if (keepFocus) {
      inputRef.current?.focus();
    }

    onHideColumns();
  }, [onHideColumns, keepFocus]);

  const [overrideActiveColumn, setOverrideActiveColumn] = useState<ISearchColumn['name'] | null>(
    null,
  );
  // clear column override if the active column prop changes
  useEffect(() => {
    setOverrideActiveColumn(null);
  }, [activeColumn]);

  const onEnterColumn = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);
  const onLeaveColumn = useCallback(() => setIsHovered(false), [setIsHovered]);

  const onChangeHandler: TextFieldProps['onChange'] = useCallback(
    (evt) => {
      onTextChanged?.(evt.target.value);
      onChange?.(evt);
    },
    [onTextChanged, onChange],
  );

  const onColumnClick = useCallback(
    (index: number) => () => {
      if (!inputRef.current) {
        return;
      }
      const currentValues = inputRef.current.value.split(delimiter);
      if (index >= currentValues.length) {
        currentValues.push(...Array(index - currentValues.length + 1).fill(''));
      }
      inputRef.current.value = currentValues.join(delimiter);
      inputRef.current.focus();
      let last = 0;
      let column = 0;
      while (column <= index) {
        const next = inputRef.current.value.indexOf(',', last) + 1;
        if (next >= 0) {
          last = next;
        } else {
          break;
        }
        column += 1;
      }
      inputRef.current.setSelectionRange(last - 1, last - 1);
      setOverrideActiveColumn(columns[column - 1]?.name);
    },
    [columns, delimiter],
  );

  const findOverrideColumn = useCallback(() => {
    if (inputRef.current) {
      const columnId =
        inputRef.current.value.substring(0, inputRef.current.selectionStart ?? 0).split(delimiter)
          .length - 1;
      const newColumnOverride = columns[columnId]?.name;
      setOverrideActiveColumn(newColumnOverride);
    }
  }, [columns, delimiter]);

  const getNumColumns = useCallback(
    () => (inputRef.current?.value ?? '').split(delimiter).length,
    [delimiter],
  );
  const numColumns = getNumColumns();
  const onKeyUp: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (evt) => {
      if (evt.key.startsWith('Arrow')) {
        // arrow key trigger immediate search
        findOverrideColumn();
      } else {
        // other keys might change amount of columns
        const newNumColumns = getNumColumns();
        // if they do trigger search
        if (numColumns !== newNumColumns) {
          findOverrideColumn();
        }
      }
    },
    [findOverrideColumn, numColumns, getNumColumns],
  );

  const searchInputProps = useMemo(
    () => ({
      inputRef,
      endAdornment: (
        <InputAdornment position="start">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
      onClick: findOverrideColumn,
      onKeyUp,
    }),
    [findOverrideColumn, onKeyUp],
  );

  const columnItems = useMemo(
    () =>
      columns.map(({ label, name }, i) => {
        const isLast = i === columns.length - 1;

        return (
          <Grid item key={i}>
            <Typography
              variant="subtitle1"
              className={(overrideActiveColumn ?? activeColumn) === name ? classes.bold : ''}
              onClick={onColumnClick(i)}
              onMouseEnter={onEnterColumn}
              onMouseLeave={onLeaveColumn}
            >
              {label}
              {!isLast ? delimiter : ''}
            </Typography>
          </Grid>
        );
      }),
    [
      columns,
      overrideActiveColumn,
      activeColumn,
      delimiter,
      classes.bold,
      onColumnClick,
      onEnterColumn,
      onLeaveColumn,
    ],
  );

  return (
    <>
      {(propShowColumns || showColumns || isHovered) && (
        <Grid container spacing={2} justify={justify}>
          {columnItems}
        </Grid>
      )}
      <RootRef rootRef={ref}>
        <TextField
          type="search"
          placeholder="Suche"
          onBlur={handleOnBlur}
          onFocus={onShowColumns}
          onInput={onInput}
          onChange={onChangeHandler}
          InputProps={searchInputProps}
          fullWidth={fullWidth}
          autoFocus={keepFocus}
        />
      </RootRef>
    </>
  );
};
