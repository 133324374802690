import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Theme } from '@material-ui/core';
import { IRowNavigation } from '../types';
import LevelToggleButton from '../LevelToggleButton';
import range from 'lodash/range';
import { ITableContext } from '..';

const useStyles = makeStyles((theme: Theme) => ({
  childCell: {
    backgroundColor: theme.palette.background.default,
    paddingRight: 1,
    borderTop: 0,
    borderBottom: 0,
    borderLeftStyle: 'dashed',
    borderLeftColor: theme.palette.grey[400],
    borderLeftWidth: 2,
  },
  childCellWithBorderBottom: {
    borderBottom: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[300],
  },
  toggleCell: {
    borderColor: theme.palette.grey[300],
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
  },
  activeRow: {
    borderWidth: 1,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
}));

interface IProps {
  rowNavigation: IRowNavigation;
  isOpen: boolean;
  isActive: boolean;
  maxInnerTableLevel: number;
  innerLevel: number;
  context: ITableContext;
}

const LevelCells: React.FunctionComponent<IProps> = ({
  rowNavigation,
  isOpen,
  isActive,
  maxInnerTableLevel,
  innerLevel,
  context,
}) => {
  const { tableData, activeRowId, dispatch } = context;
  const classes = useStyles();

  /*  In material-ui table component all rows have a default bottom border.
      For the first level cells we don't show the bottom border to create an row-hierarchy-look.
      But when the next row has a lower level than the current row (an "uncle-row") we have to print the bottom border. */
  const nextRowLevel = rowNavigation.next
    ? tableData.navigation[rowNavigation.next].path.length
    : -1;
  const childCellsWithBorderBottom =
    rowNavigation.path.length > nextRowLevel ? rowNavigation.path.length - nextRowLevel : 0;

  return (
    <>
      {range(innerLevel).map((x) => (
        <React.Fragment key={x}>
          <TableCell
            className={classNames(classes.childCell, {
              [classes.childCellWithBorderBottom]: x >= innerLevel - childCellsWithBorderBottom,
            })}
          />
        </React.Fragment>
      ))}
      {maxInnerTableLevel - innerLevel > 0 && (
        <TableCell
          padding="none"
          colSpan={maxInnerTableLevel - innerLevel}
          className={classNames(classes.toggleCell, {
            [classes.activeRow]: activeRowId === rowNavigation.row.id,
          })}
        >
          <LevelToggleButton
            rowNavigation={rowNavigation}
            isOpen={isOpen}
            isActive={isActive}
            dispatch={dispatch}
          />
        </TableCell>
      )}
    </>
  );
};

export default LevelCells;
