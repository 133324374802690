import React, { useMemo } from 'react';
import noop from 'lodash/fp/noop';
import {
  IDataTableColumn,
  IDataTableOrder,
  IDataTableRow,
  ILevelOptions,
} from '../../components/DataTable/types';
import DataTable from '../../components/DataTable';
import { Pagination } from '../../components/Pagination';
import { IPaginationState, usePaginationState } from '../../components/Pagination/Pagination.utils';
import { GetAccountingLogJournal_accountingItemsWithStats_accountingItems } from './AccountingLogJournal/types/GetAccountingLogJournal';
import { parseChargeConstraint } from './utils/chargeConstraint';
import Dinero from 'dinero.js';
import { GetAccountingActivityTypes_activityTypes } from './AccountingLogger/types/GetAccountingActivityTypes';
import { createSwissCurrencyFormatter } from '../../utils/createCurrencyFormatter';
import { DownloadCsvAction } from '../../components/Actions/Download/Csv';
import { formatDate } from '../../utils/format/date';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

export const accountingLogJournalColumns: IDataTableColumn[] = [
  {
    id: 'id',
    label: 'Buchungs ID',
  },
  {
    id: 'activityDate',
    label: 'AR. Aufwandsdatum',
  },
  {
    id: 'createdAt',
    label: 'AR. Erfassungsdatum',
  },
  {
    id: 'createdBy',
    label: 'Erstellt von',
    hideOnDefault: true,
  },
  {
    id: 'parent',
    label: 'Mutterbuchung',
  },
  {
    id: 'activityType',
    label: 'Was (Leistungsart)',
  },
  {
    id: 'whatNameOne',
    label: 'Bezeichnung 1',
  },
  {
    id: 'whatNameTwo',
    label: 'Bezeichnung 2',
    hideOnDefault: true,
  },
  {
    id: 'unit',
    label: 'AR. Einheit',
    hideOnDefault: true,
  },
  {
    id: 'costPerUnit',
    label: 'AR. Ansatz',
    render: formatCurrency,
  },
  {
    id: 'creditedTo',
    label: 'Zugunsten',
  },
  {
    id: 'creditedToNameOne',
    label: 'Bezeichnung 1',
  },
  {
    id: 'creditedToNameTwo',
    label: 'Bezeichnung 2',
    hideOnDefault: true,
  },
  {
    id: 'creditedToSubsidiary',
    label: 'Filiale',
  },
  {
    id: 'chargedTo',
    label: 'Zulasten',
  },
  {
    id: 'projectNumber',
    label: 'Projektnummer',
  },
  {
    id: 'chargedToNameOne',
    label: 'Bezeichnung 1',
  },
  {
    id: 'chargedToNameTwo',
    label: 'Bezeichnung 2',
  },
  {
    id: 'chargedToSubsidiary',
    label: 'Filiale',
  },
  {
    id: 'startTime',
    label: 'AR. Startzeit',
  },
  {
    id: 'endTime',
    label: 'AR. Endzeit',
  },
  {
    id: 'amount',
    label: 'Menge',
  },
  {
    id: 'sum',
    label: 'Summe',
    render: formatCurrency,
  },
  {
    id: 'comment',
    label: 'AR. Bemerkungen',
  },
];

const entityColumns = (
  item: Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems>,
  direction: 'creditedTo' | 'chargedTo',
): {
  to: number | string | null;
  nameOne: string | null;
  nameTwo: string | null;
} => {
  const entity = item[direction];
  return entity.mission
    ? {
        to: entity.mission.id,
        nameOne: entity.mission.name,
        nameTwo: '',
      }
    : entity.collectiveAccount
    ? {
        to: 'Sammelkonto',
        nameOne: entity.collectiveAccount.nameOne,
        nameTwo: entity.collectiveAccount.nameTwo,
      }
    : entity.employee
    ? {
        to: entity.employee.employeeNumber,
        nameOne: entity.employee.firstName,
        nameTwo: entity.employee.lastName,
      }
    : entity.machine
    ? {
        to: entity.machine.inventoryNumber,
        nameOne: entity.machine.nameOne,
        nameTwo: entity.machine.nameTwo,
      }
    : entity.material
    ? {
        to: entity.material.materialNumber,
        nameOne: entity.material.nameOne,
        nameTwo: entity.material.nameTwo,
      }
    : entity.vehicle
    ? {
        to: entity.vehicle.inventoryNumber,
        nameOne: entity.vehicle.nameOne,
        nameTwo: entity.vehicle.nameTwo,
      }
    : {
        to: '',
        nameOne: '',
        nameTwo: '',
      };
};

/**
 * map accounting items to data table rows
 * @param indexedActivityTypes meta info for available activityTypes
 * @param items the accounting items to map
 * @returns data table rows
 * @see IDataTableRow
 */
const mapToDataTable = (
  indexedActivityTypes: Readonly<{
    [num: number]: Readonly<GetAccountingActivityTypes_activityTypes> | undefined;
  }>,
  items: ReadonlyArray<Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems>>,
): IDataTableRow[] => {
  return items.map((item) => {
    const activityTypeNr = parseChargeConstraint(item.chargeConstraint).activityType;
    const activityType = indexedActivityTypes[activityTypeNr];
    const mission = item.creditedTo.mission ?? item.chargedTo.mission;
    const creditedToColumns = entityColumns(item, 'creditedTo');
    const chargedToColumns = entityColumns(item, 'chargedTo');
    const costPerUnit = Dinero({ amount: item.creditedTo.costPerUnit });
    return {
      id: item.id.toString(),
      data: {
        id: item.id,
        activityDate: item.activityDate.split('-').reverse().join('/'),
        createdAt: item.createdAt.split('-').reverse().join('/'),
        createdBy: item.createdBy,
        parent: item.parent,
        activityType: activityTypeNr,
        whatNameOne: activityType?.nameOne,
        // empty for now
        whatNameTwo: '',
        unit:
          activityType?.unit?.acronym ??
          item.creditedTo.material?.unit.acronym ??
          item.chargedTo.material?.unit.acronym,
        costPerUnit,
        creditedTo: creditedToColumns.to,
        creditedToNameOne: creditedToColumns.nameOne,
        creditedToNameTwo: creditedToColumns.nameTwo,
        creditedToSubsidiary: item.creditedTo.subsidiary.name,
        chargedTo: chargedToColumns.to,
        projectNumber: mission?.project.projectNumber,
        chargedToNameOne: chargedToColumns.nameOne,
        chargedToNameTwo: chargedToColumns.nameTwo,
        chargedToSubsidiary: item.chargedTo.subsidiary.name,
        startTime: item.startTime,
        endTime: item.endTime,
        amount: item.amount,
        sum: costPerUnit.multiply(item.amount ?? 0),
        comment: item.comment,
        manual: item.manual,
        reportId: item.reportId,
        item,
      },
    };
  });
};

interface IAccountingLogJournalTablePropsBase {
  tableName: string;
  displayName?: string;
  activityTypes: ReadonlyArray<Readonly<GetAccountingActivityTypes_activityTypes>>;
  accountingItems: ReadonlyArray<
    Readonly<GetAccountingLogJournal_accountingItemsWithStats_accountingItems>
  >;
  totalNumberRows: number;
  csvDownload?: boolean;
  onPaginationStateChange?: (state: IPaginationState) => void;
  rowActions?: ILevelOptions['rowActions'];
  search?: React.ReactNode;
  onChangeSort?: (columnId: string, order: IDataTableOrder) => void;
}

/**
 * Component that displays a simple table of accounting items
 * @constructor
 */
export const AccountingLogJournalTable: React.FC<IAccountingLogJournalTablePropsBase> = React.memo(
  ({
    tableName,
    displayName,
    onPaginationStateChange = noop,
    activityTypes,
    accountingItems,
    totalNumberRows,
    csvDownload = false,
    rowActions,
    search,
    onChangeSort,
  }) => {
    const paginate = onPaginationStateChange !== noop;
    const paginationState = usePaginationState({
      totalNumberOfRows: totalNumberRows,
      scrollOnPaginationAction: true,
      onChangePage: onPaginationStateChange,
      onSetRowsPerPage: onPaginationStateChange,
    });

    const options = useMemo(
      () => ({
        tableName,
        displayName,
        activeRowId: '',
        onChangeSort,
        levels: [
          {
            columns: accountingLogJournalColumns,
            rowActions,
            actions: csvDownload
              ? () => (
                  <DownloadCsvAction
                    variables={undefined}
                    entityType="ACCOUNTING_JOURNAL"
                    csvName={`Buchungsjournal_${formatDate()}`}
                  />
                )
              : undefined,
          },
        ],
      }),
      [onChangeSort, csvDownload, rowActions, tableName, displayName],
    );

    const indexedActivityTypes = useMemo(
      () =>
        Object.fromEntries(
          activityTypes.map((activityType) => [activityType.number, activityType]),
        ),
      [activityTypes],
    );

    const innerTableRows = useMemo(
      () => mapToDataTable(indexedActivityTypes, accountingItems),
      [indexedActivityTypes, accountingItems],
    );

    const pagination = paginate ? <Pagination paginationState={paginationState} /> : undefined;
    return (
      <DataTable
        search={search}
        pagination={pagination}
        innerTableRows={innerTableRows}
        options={options}
      />
    );
  },
);
