import gql from 'graphql-tag';

export const locationCompositeIdFields = gql`
  fragment locationCompositeIdFields on Location {
    billOfQuantity {
      id
    }
    parentLocation {
      id
    }
  }
`;

export const BILL_OF_QUANTITY_FRAGMENT = gql`
  fragment billOfQuantityFields on BillOfQuantity {
    id
    name
    date
    createdAt
    colorCodings
    hasScaleDiscount
    hasScaleDiscountAccumulate
    taskTypes
    type
    freeTextOne
    freeTextTwo
    freeTextThree
    freeTextFour
    canBeDeleted
    isBonusApplicable
    entityType
    isOfferBlocked
    project {
      id
      projectName # fetch projectName to get pdf filename from row.data.project.projectName
    }
    statuses {
      id
      status
    }
    sumNet
    sumGross
  }
`;

export const BOQ_STRUCTURE_FRAGMENT = gql`
  fragment boqStructureFields on BillOfQuantity {
    defaultLocation {
      id
      hasItemsOrLocations
      locations {
        id
        hasItemsOrLocations
        name
        canBeDeleted
        billOfQuantity {
          id
        }
        parentLocation {
          id
        }
        locations {
          id
          hasItemsOrLocations
          name
          canBeDeleted
          billOfQuantity {
            id
          }
          parentLocation {
            id
          }
        }
      }
    }
  }
`;

export const STRUCTURE_QUERY = gql`
  query BillOfQuantityStructureQuery(
    $projectNumber: String!
    $entityType: BillOfQuantityEntityType!
  ) {
    billOfQuantities(
      where: { project: { projectNumber: $projectNumber }, entityType: $entityType }
    ) {
      ...billOfQuantityFields
      ...boqStructureFields
    }
  }
  ${BILL_OF_QUANTITY_FRAGMENT}
  ${BOQ_STRUCTURE_FRAGMENT}
`;

export const FULL_BOQ_STRUCTURE_FRAGMENT = gql`
  fragment fullBoqStructureFields on BillOfQuantity {
    ...billOfQuantityFields
    ...boqStructureFields
  }
  ${BILL_OF_QUANTITY_FRAGMENT}
  ${BOQ_STRUCTURE_FRAGMENT}
`;

export const ITEM_FRAGMENT = gql`
  fragment itemFields on Item {
    acronym
    appliedPrice
    applyScaleDiscount
    catalogMainGroup
    catalogMainGroupDescription
    catalogSection
    catalogSectionDescription
    catalogSubsection
    catalogSubsectionDescription
    category
    color
    comment
    customerComment
    descriptionOne
    descriptionTwo
    dimensionOne
    dimensionTwo
    freeText
    hasFiles
    id
    isInvoiced
    netOfferSum
    grossOfferSum
    itemFromStandardCatalog
    markingStyle
    material
    materialColor
    netPrice
    productNumber
    reflexion
    targetConsumptionPerUnit
    timeRequired
    type
    unit
    volume
    allocatedVolume
    allocatedSum
    openVolume
    openSum
    canBeDeleted
    chargedVolume
    doneVolume
    location {
      id
      billOfQuantity {
        id
      }
      parentLocation {
        id
        parentLocation {
          id
        }
      }
    }
  }
`;

export const GET_MORE_ITEMS = gql`
  query GetMoreBillOfQuantityItems(
    $where: LocationWhereUniqueInput!
    $itemsWhere: ItemWhereInput!
    $search: SearchInput
    $after: ID
    $take: Int! = 10
  ) {
    location(where: $where) {
      id
      ...locationCompositeIdFields
      itemsConnection(
        pagination: { after: $after, take: $take }
        where: $itemsWhere
        search: $search
      ) {
        pageInfo {
          hasNextPage
        }
        nodes {
          ...itemFields
        }
      }
    }
  }
  ${locationCompositeIdFields}
  ${ITEM_FRAGMENT}
`;

export const REFETCH_ITEMS_IN_CONTAINER = gql`
  query RefetchItemsInContainer(
    $where: LocationWhereInput!
    $take: Int!
    $itemsWhere: ItemWhereInput!
    $search: SearchInput
  ) {
    locations(where: $where) {
      id
      ...locationCompositeIdFields
      billOfQuantity {
        ...billOfQuantityFields
      }
      itemsConnection(pagination: { take: $take }, where: $itemsWhere, search: $search) {
        pageInfo {
          hasNextPage
        }
        nodes {
          ...itemFields
        }
      }
    }
  }
  ${ITEM_FRAGMENT}
  ${locationCompositeIdFields}
  ${BILL_OF_QUANTITY_FRAGMENT}
`;

export const SEARCH_BILL_OF_QUANTITIES_QUERY = gql`
  query SearchBillOfQuantities(
    $search: SearchInput!
    $take: Int!
    $where: LocationWhereInput!
    $itemsWhere: ItemWhereInput!
  ) {
    locations(where: $where) {
      id
      itemsConnection(pagination: { take: $take }, search: $search, where: $itemsWhere) {
        pageInfo {
          hasNextPage
        }
        nodes {
          ...itemFields
        }
      }
    }
  }
  ${ITEM_FRAGMENT}
`;

export const GET_BILL_OF_QUANTITY_AFTER_LOCATION_DELETE = gql`
  query GetBillOfQuanityAfterLocationDelete($where: BillOfQuantityWhereInput!) {
    billOfQuantity(where: $where) {
      id
      colorCodings
      sumNet
      sumGross
      defaultLocation {
        id
      }
    }
  }
`;

export const GET_BILL_OF_QUANTITY_COMPUTED_FIELDS = gql`
  query GetBillOfQuantityComputedFields($where: BillOfQuantityWhereInput!) {
    billOfQuantity(where: $where) {
      id
      colorCodings
      sumNet
      sumGross
      canBeDeleted
    }
  }
`;

export const BILL_OF_QUANTITY_L8_QUERY = gql`
  query BillOfQuantityL8($where: BillOfQuantityWhereInput!) {
    billOfQuantities(where: $where) {
      id
      defaultLocation {
        id
      }
    }
  }
`;

export const REFETCH_ITEMS_COMPUTED_FIELDS_QUERY = gql`
  query RefetchItemsComputedFields($where: ItemsWhereInput!) {
    items(where: $where) {
      id
      appliedPrice
      netOfferSum
      grossOfferSum
      netPrice
      allocatedVolume
      allocatedSum
      openVolume
      openSum
      chargedVolume
      doneVolume
    }
  }
`;
