import gql from 'graphql-tag';

export const GET_PROJECT_REPOSITORY_QUERY = gql`
  query GetProjectStorageRepository($projectNumber: String!) {
    projectStorageRepository(projectNumber: $projectNumber) {
      id
      folders {
        category
        documents {
          id
          mimetype
          name
          nameHash
          path
          createdAt
          uploadedBy {
            id
            name
          }
          updatedAt
          updatedBy {
            id
            name
          }
        }
      }
    }
  }
`;

export const UPLOAD_FILES_TO_PROJECT_REPO = gql`
  mutation UploadFilesToProjectRepo($repo: UploadToProjectRepoInput!, $files: [Upload!]!) {
    uploadFilesToProjectRepo(repo: $repo, files: $files) {
      id
    }
  }
`;

export const DELETE_DOCUMENT_MUTATION = gql`
  mutation DeleteDocuments($documentIds: [String!]!) {
    deleteDocuments(documentIds: $documentIds) {
      id
    }
  }
`;

export const UPDATE_MANY_DOCUMENTS_MUTATION = gql`
  mutation updateManyDocuments($documents: [UpdateDocumentsInput!]!) {
    updateManyDocuments(documents: $documents) {
      id
    }
  }
`;
