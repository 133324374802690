import React from 'react';
import DataTable from '../../../DataTable';
import { IDataTableColumn, IDataTableRow } from '../../../DataTable/types';
import CatalogMaterialQuery, { ITEM_BOILERPLATE_COMPUTED_FIELDS } from './CatalogMaterialQuery';
import { Pagination } from '../../../Pagination';
import { Search } from '../../../Search';
import { useSearchState } from '../../../Search/Search';
import { createOrderByFieldName } from '../../../../utils/order/createOrderByFieldName';
import { isComputedField } from '../../../../utils/order/isComputedField';

export const columns: IDataTableColumn[] = [
  {
    id: 'productNumber',
    label: 'Produktnummer',
  },
  {
    id: 'descriptionOne',
    label: 'Beschreibung 1',
  },
  {
    id: 'unit',
    label: 'Einheit',
  },
  {
    id: 'catalog.useName',
    label: 'Katalogname',
  },
  {
    id: 'markingStyle',
    label: 'Typ',
  },
  {
    id: 'color',
    label: 'Farbe',
  },
  {
    id: 'dimensionOne',
    label: 'Dimension 1',
  },
  {
    id: 'catalogMainGroupDescription',
    label: 'Hierarchie 2',
  },

  {
    id: 'freeText',
    label: 'Freitext',
  },
  {
    id: 'type',
    label: 'Positionsart',
  },
];

const mapTableData = (itemBoilerplates: any[]): IDataTableRow[] => {
  return itemBoilerplates.map((i: any) => ({
    id: i.id,
    data: {
      ...i,
      'catalog.useName': i.catalog.useName,
    },
  }));
};

interface IProps {
  onRowClick: (row: IDataTableRow) => any;
  initCatalogId?: string;
}

const ItemBoilerplateSearchSingle: React.FunctionComponent<IProps> = ({
  onRowClick,
  initCatalogId,
}) => {
  const searchState = useSearchState();

  return (
    <CatalogMaterialQuery
      initiallyOpen={!initCatalogId}
      hasMaterialSelector
      initCatalogId={initCatalogId}
      catalogSelectorLabel="Katalog"
    >
      {({ data, paginationState, refetch, loading }) => {
        const innerTableRows = mapTableData(data.itemBoilerplates ?? []);

        return (
          <DataTable
            innerTableRows={innerTableRows}
            pagination={<Pagination paginationState={paginationState} />}
            search={
              <Search
                searchState={searchState}
                onSubmit={(search) => refetch({ search })}
                loading={loading}
                columns={[
                  'productNumber',
                  'descriptionOne',
                  'unit',
                  'type',
                  'color',
                  'dimensionOne',
                  'catalogMainGroupDescription',
                  'freeText',
                ]}
                autoFocus
              />
            }
            options={{
              filterText: searchState.searchTerm,
              hideShowAdditionalColumnsBtn: true,
              levels: [
                {
                  columns,
                  onRowClick,
                },
              ],
              onChangeSort: async (fieldName, order) => {
                await refetch({
                  orderBy: {
                    fieldName: createOrderByFieldName(fieldName, order),
                    isComputed: isComputedField(fieldName, ITEM_BOILERPLATE_COMPUTED_FIELDS),
                  },
                });
              },
            }}
          />
        );
      }}
    </CatalogMaterialQuery>
  );
};

/** Table to select a single boilerplate item */
export default ItemBoilerplateSearchSingle;
