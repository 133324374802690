import { Field, Form, Formik } from 'formik';
import { Box, Grid, Input, MenuItem } from '@material-ui/core';
import SubsidiarySelect from '../../../pages/Projects/TabDetails/SubsidiarySelect';
import FormikTextField from '../../Form/FormikTextField';
import React, { useMemo } from 'react';
import { isNil, omit } from 'lodash';
import * as Yup from 'yup';
import CancelSaveButtons from '../../Form/CancelSaveButtons';
import FormikSelect from '../../Form/FormikSelect';
import { Machine_machine } from './types/Machine';
import {
  EditableHistoryItemFieldType,
  MachineCategory,
  MachineSkill,
  Role,
} from '../../../types/graphql';
import HistorizedField from '../../Form/HistorizedField';
import createCurrencyFormatter from '../../../utils/createCurrencyFormatter';
import { getChangedFormValues } from '../../../utils/form/getChanged';
import { FormikSwitch } from '../../Form/FormikSwitch';
import { formatVolume } from '../../../utils/format/volume';
import { GET_MACHINE } from './MachineForm.queries';
import Dropezone from '../../Form/UploadField/Dropzone';
import { useLoggedInUser } from '../../../hooks/useLoggedInUser';

interface IProps {
  machine: Partial<Omit<Machine_machine, 'id' | '__typename'>>;
  onSubmit: (data: any) => void;
}

const formatCurrency = createCurrencyFormatter({ format: '0.00' });

const VALIDATION_SCHEMA = Yup.object({
  subsidiary: Yup.string().required('Pflichtfeld!'),
  inventoryNumber: Yup.string().required('Pflichtfeld!'),
  internalHourlyWage: Yup.number()
    .typeError('Muss eine Zahl sein!')
    .positive('Muss positiv sein!')
    .required('Pflichtfeld!'),
  nameOne: Yup.string().required('Pflichtfeld!'),
  category: Yup.string().required('Pflichtfeld!'),
});

export const machineSkillMap: Record<MachineSkill, string> = {
  NineEightTwo: '98/2',
  AUFGELEGT: 'Aufgelegt',
  BORDCOMPUTER: 'Bordcomputer',
  FARBE: 'Farbe',
  GPS: 'Gps',
  ON_LINE: 'On_Line',
  STRUKTUR: 'Struktur',
  TAKTIL: 'Taktil',
};

export const machineCategoryMap: Record<MachineCategory, string> = {
  DEMARKIERMASCHINE: 'Demarkiermaschine',
  DIVERS: 'Divers',
  GROSSMARKIERMASCHINE: 'Grossmarkiermaschine',
  HANDGEFUEHRT: 'Handgeführt',
  KLEINGERAETE: 'Kleingeräte',
  MK3: 'MK3',
  SPIELFELDMASCHINE: 'Spielfeldmaschine',
};

export const MachineForm: React.FC<IProps> = ({ machine, onSubmit }) => {
  const user = useLoggedInUser();

  const initialValues = useMemo((): any => {
    const cleanedMachine = omit(machine, [
      'subsidiaryHistory',
      'hoursOfOperationHistory',
      'driverHistory',
    ]);

    return {
      ...cleanedMachine,
      subsidiary: machine.subsidiary?.name,
      ...(machine.internalHourlyWage && {
        internalHourlyWage: formatCurrency(machine.internalHourlyWage),
      }),
      documents: {},
    };
  }, [machine]);

  const handleSubmit = ({ hoursOfOperationHistory, subsidiaryHistory, ...data }: any) => {
    const mappedData = {
      ...data,
    };
    if (!isNil(data.subsidiary)) {
      mappedData.subsidiary = {
        name: data.subsidiary,
      };
    }

    const changedValues = getChangedFormValues({
      initialValues: {
        ...initialValues,
        subsidiary: initialValues.subsidiary ?? {},
      },
      values: mappedData,
    });

    return onSubmit(changedValues);
  };

  const isDisabled = user?.role !== Role.SUPER_ADMIN;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={VALIDATION_SCHEMA}
    >
      {({ isSubmitting, dirty }) => {
        return (
          <Form>
            <Grid container spacing={5}>
              <Grid item md={6} lg={3}>
                <SubsidiarySelect
                  disabled={isDisabled}
                  fieldType={EditableHistoryItemFieldType.MACHINE_SUBSIDIARY}
                  entityId={initialValues?.id}
                  name="subsidiary"
                  isEditable={!!initialValues}
                  history={
                    machine.subsidiaryHistory?.map((historyItem) => ({
                      id: historyItem.id,
                      date: historyItem.createdAt,
                      value: historyItem.value,
                      user: historyItem.createdBy.name,
                      validFrom: historyItem.validFrom,
                    })) ?? []
                  }
                  refetchQueries={[
                    {
                      query: GET_MACHINE,
                      variables: { inventoryNumber: initialValues.inventoryNumber },
                    },
                  ]}
                />
                <Field
                  disabled={isDisabled}
                  name="inventoryNumber"
                  label="Inventarnummer *"
                  component={FormikTextField}
                />
                <Field
                  disabled={isDisabled}
                  name="projectNumber"
                  label="Projektnummer (Abacus)"
                  component={FormikTextField}
                />
                <Field
                  disabled={isDisabled}
                  name="registrationNumber"
                  label="Kennzeichen"
                  component={FormikTextField}
                />
                <Field
                  name="rootNumber"
                  disabled={isDisabled}
                  label="Stammnummer"
                  component={FormikTextField}
                />
                <Field
                  name="chassisNumber"
                  disabled={isDisabled}
                  label="Fahrgestellnummer"
                  component={FormikTextField}
                />
              </Grid>
              <Grid item md={6} lg={3}>
                <Field
                  name="internalHourlyWage"
                  disabled={isDisabled}
                  label="Stundensatz intern (CHF) *"
                  component={FormikTextField}
                  type="number"
                />
                <Field
                  disabled={isDisabled}
                  name="nameOne"
                  label="Bezeichnung 1 *"
                  component={FormikTextField}
                />
                <Field
                  disabled={isDisabled}
                  name="nameTwo"
                  label="Bezeichnung 2"
                  component={FormikTextField}
                />
                <HistorizedField
                  disabled={isDisabled}
                  name="driver"
                  label="Fahrer"
                  component={FormikTextField}
                  fieldType={EditableHistoryItemFieldType.MACHINE_DRIVER}
                  entityId={initialValues?.id}
                  isEditable={!!initialValues}
                  refetchQueries={[
                    {
                      query: GET_MACHINE,
                      variables: { inventoryNumber: initialValues.inventoryNumber },
                    },
                  ]}
                  history={
                    machine.driverHistory?.map(
                      ({ id, createdAt, value, createdBy, validFrom }) => ({
                        id,
                        value,
                        validFrom,
                        date: createdAt,
                        user: createdBy.name,
                      }),
                    ) ?? []
                  }
                />
                <Field
                  disabled={isDisabled}
                  name="releaseDate"
                  label="Jahrgang"
                  component={FormikTextField}
                  type="date"
                  shrink
                />
              </Grid>
              <Grid item md={6} lg={3}>
                <Field
                  name="weight"
                  disabled={isDisabled}
                  label="Gewicht (kg)"
                  component={FormikTextField}
                  type="number"
                />
                <HistorizedField
                  name="hoursOfOperation"
                  label="Betriebsstunden"
                  component={FormikTextField}
                  type="number"
                  disabled={isDisabled}
                  history={
                    machine.hoursOfOperationHistory?.map((historyItem) => ({
                      id: historyItem.id,
                      date: historyItem.createdAt,
                      value: formatVolume(parseInt(historyItem.value, 10)),
                      user: historyItem.createdBy.name,
                      validFrom: historyItem.validFrom,
                    })) ?? []
                  }
                />
                <Field
                  name="emissionsTestDate"
                  label="Abgasuntersuchung"
                  component={FormikTextField}
                  type="date"
                  disabled={isDisabled}
                  shrink
                />
                <Field
                  name="trafficOfficeTestDate"
                  label="STVA Termin"
                  type="date"
                  component={FormikTextField}
                  shrink
                  disabled={isDisabled}
                />
                <Field
                  name="lastInternalTestDate"
                  label="Letzte Prüfung intern"
                  component={FormikTextField}
                  type="date"
                  disabled={isDisabled}
                  shrink
                />
              </Grid>
              <Grid item md={6} lg={3}>
                <Field
                  disabled={isDisabled}
                  name="note"
                  label="Bemerkung"
                  component={FormikTextField}
                />
                <Field
                  disabled={isDisabled}
                  component={FormikSelect}
                  name="category"
                  label="Kategorie *"
                  input={<Input id="category" />}
                >
                  {Object.entries(MachineCategory).map(([type, value]) => (
                    <MenuItem key={type} value={type}>
                      {machineCategoryMap[value]}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  disabled={isDisabled}
                  label="Fähigkeit"
                  component={FormikSelect}
                  name="skills"
                  multiple
                  required
                  input={<Input id="skills" />}
                >
                  {Object.entries(MachineSkill).map(([type, value]) => (
                    <MenuItem value={type} key={type}>
                      {machineSkillMap[value]}
                    </MenuItem>
                  ))}
                </Field>
                <Box marginTop={2}>
                  <Field
                    disabled={isDisabled}
                    name="isDeactivated"
                    color="primary"
                    component={FormikSwitch}
                    label="Inaktiv"
                  />
                </Box>
                <Box marginTop={2}>
                  <Grid item md={6}>
                    <Field
                      disabled={isDisabled}
                      name="documents"
                      label="Dokumente"
                      initialFiles={machine?.documents ?? []}
                      component={Dropezone}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid item container justify="flex-end" md={12}>
                <CancelSaveButtons isDisabled={isSubmitting || !dirty || isDisabled} />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
