import { Switch } from '@material-ui/core';
import isNil from 'lodash/fp/isNil';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { UpsertAddressBookItemType } from '../../../types/graphql';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import AppErrorMessage from '../../Page/AppErrorMessage';
import AppProgress from '../../Page/AppProgress';
import { useInheritButtonQuery } from './fetch';
import { Maybe } from 'graphql/jsutils/Maybe';

interface IProps {
  shippingAddressBookItemId: Maybe<string>;
  billingAddressBookItemId: Maybe<string>;
  projectNumber: string;
  isShippingDifferent?: Maybe<boolean>;
}

const isAlreadyInheriting = (
  shippingAddressBookItemId: Maybe<string>,
  billingAddressBookItemId: Maybe<string>,
) =>
  !isNil(shippingAddressBookItemId) &&
  !isNil(billingAddressBookItemId) &&
  shippingAddressBookItemId === billingAddressBookItemId;

export const InheritButton: FC<IProps> = ({
  shippingAddressBookItemId,
  billingAddressBookItemId,
  projectNumber,
  isShippingDifferent,
}) => {
  const [fieldValue, setFieldValue] = useState(() =>
    isAlreadyInheriting(shippingAddressBookItemId, billingAddressBookItemId),
  );
  const { loading, error, executeInherit, executeDisconnect, executeCreateEmptyAddressBookItem } =
    useInheritButtonQuery();

  useEffect(() => {
    setFieldValue(isAlreadyInheriting(shippingAddressBookItemId, billingAddressBookItemId));
  }, [billingAddressBookItemId, shippingAddressBookItemId]);

  const handleChange = useCallback(() => {
    const newFieldValue = !fieldValue;
    setFieldValue(newFieldValue);

    if (isShippingDifferent !== undefined) {
      // user wants to use same billing address
      if (isShippingDifferent) {
        return executeDisconnect({ variables: { projectNumber } });
      } else {
        // user wants to use a different shipping address
        return executeCreateEmptyAddressBookItem({
          variables: { projectNumber, type: UpsertAddressBookItemType.SHIPPING },
        });
      }
    }

    if (newFieldValue) {
      executeInherit({ variables: { projectNumber } });
    } else {
      executeDisconnect({ variables: { projectNumber } });
    }
  }, [
    executeInherit,
    executeDisconnect,
    projectNumber,
    fieldValue,
    executeCreateEmptyAddressBookItem,
    isShippingDifferent,
  ]);

  return (
    <>
      {loading && <AppProgress />}
      {error && <AppErrorMessage message={errorPrefixRemover(error.message)} />}
      <Switch
        checked={isShippingDifferent ?? fieldValue}
        disabled={loading || !billingAddressBookItemId}
        onChange={handleChange}
        color="primary"
      />
    </>
  );
};
