import React from 'react';
import { HotKeys } from 'react-hotkeys';

const HotKeysWrapper: React.FC<{
  children: React.ReactNode;
  itemVolumeInputRef: any;
  tableRef: any;
}> = ({ children, itemVolumeInputRef, tableRef }) => {
  const upDownHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (tableRef.current) {
      tableRef.current.focus();
    }
  };

  return (
    <HotKeys
      keyMap={{
        ENTER: 'enter',
        DOWN: 'down',
        UP: 'up',
      }}
      handlers={{
        ENTER: (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          if (itemVolumeInputRef.current) {
            itemVolumeInputRef.current.querySelector('input')!.focus();
          }
        },
        DOWN: upDownHandler,
        UP: upDownHandler,
      }}
    >
      {children}
    </HotKeys>
  );
};

export default HotKeysWrapper;
