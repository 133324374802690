import {
  GetAccountingLogJournal_accountingItemsWithStats_accountingItems,
  GetAccountingLogJournal_accountingItemsWithStats_accountingItems_chargedTo,
  GetAccountingLogJournal_accountingItemsWithStats_accountingItems_creditedTo,
} from '../../AccountingLogJournal/types/GetAccountingLogJournal';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';
import { cleanupPreviewItem } from './cleanupPreviewItem';
import { UpsertAccountingItem } from '../../../../types/graphql';

/**
 * extract the non null/object keys from an entity, i.e. the relations
 * @param entity the entity to extract from
 * @returns a set of relation keys in this entity
 */
const keysOfAccountingEntity = (
  entity:
    | GetAccountingLogJournal_accountingItemsWithStats_accountingItems_creditedTo
    | GetAccountingLogJournal_accountingItemsWithStats_accountingItems_chargedTo,
): Set<keyof GetAccountingLogJournal_accountingItemsWithStats_accountingItems_creditedTo> => {
  return new Set(
    Object.keys(
      omitBy(
        isNil,
        omitBy((value) => typeof value !== 'object', entity),
      ),
    ),
  ) as Set<keyof GetAccountingLogJournal_accountingItemsWithStats_accountingItems_creditedTo>;
};

interface IMovedKeys {
  creditedTo: Set<
    keyof GetAccountingLogJournal_accountingItemsWithStats_accountingItems_creditedTo
  >;
  chargedTo: Set<keyof GetAccountingLogJournal_accountingItemsWithStats_accountingItems_creditedTo>;
}

/**
 * get entity connection keys that have moved in an edit. in order to disconnect the old one and add the new one
 * a `null` value must be provided.
 * For example if an entity moves from a mission to a collectiveAccount the mission is to be disconnected via null
 *
 * @param currentItem the current, edited item
 * @param prevItem the previous, unedited item
 * @returns the set of moved keys per entity
 */
const getMovedKeys = (
  currentItem: GetAccountingLogJournal_accountingItemsWithStats_accountingItems,
  prevItem: GetAccountingLogJournal_accountingItemsWithStats_accountingItems,
): IMovedKeys => {
  const retVal: IMovedKeys = {
    creditedTo: new Set(),
    chargedTo: new Set(),
  };
  for (const direction of ['creditedTo', 'chargedTo']) {
    const currentCreditedToKeySet = keysOfAccountingEntity(
      currentItem[direction as keyof typeof currentItem],
    );
    const prevCreditedToKeySet = keysOfAccountingEntity(
      prevItem[direction as keyof typeof prevItem],
    );
    for (const key of Array.from(prevCreditedToKeySet)) {
      if (!currentCreditedToKeySet.has(key)) {
        retVal[direction as keyof typeof retVal].add(key);
      }
    }
  }
  return retVal;
};

/**
 * prepare an edited accounting item for upsert.
 * @param currentItem the current, edited item
 * @param prevItem the previous, unedited item
 * @returns the prepared accounting item
 */
export const prepareUpsertItem = (
  currentItem: GetAccountingLogJournal_accountingItemsWithStats_accountingItems,
  prevItem: GetAccountingLogJournal_accountingItemsWithStats_accountingItems,
): UpsertAccountingItem => {
  const movedKeys = getMovedKeys(currentItem, prevItem);
  const cleanedItem = cleanupPreviewItem(currentItem);
  // add the disconnect keys explicitly
  Object.keys(movedKeys).forEach((direction) => {
    Array.from(movedKeys[direction as keyof typeof movedKeys]).forEach((key) => {
      cleanedItem[direction as keyof typeof cleanedItem][key] = null;
    });
  });
  return cleanedItem;
};
