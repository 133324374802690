import React, { useState, useMemo, useCallback } from 'react';
import gql from 'graphql-tag';
import { FormControl, InputLabel, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CatalogSelect from './CatalogSelect';
import MaterialSelect from './MaterialSelect';
import { ApolloQueryResult } from 'apollo-client';
import { useQuery } from 'react-apollo';
import { ItemBoilerplates, ItemBoilerplatesVariables } from './types/ItemBoilerplates';
import {
  usePaginationState,
  IPaginationState,
  IUsePaginationStateResult,
} from '../../../Pagination/Pagination.utils';
import { useCount } from '../../../../hooks/useCount';
import { CountEntity } from '../../../../hooks/useCount/useCount.queries';

const ITEM_BOILERPLATE_QUERY = gql`
  query ItemBoilerplates(
    $catalogId: ID!
    $material: String
    $search: SearchInput
    $page: PageInput
    $orderBy: OrderByInput
  ) {
    itemBoilerplates(
      where: { material: $material, catalog: { id: $catalogId } }
      search: $search
      page: $page
      orderBy: $orderBy
    ) {
      id
      productNumber
      descriptionOne
      descriptionTwo
      unit
      catalog {
        id
        useName
      }
      markingStyle
      category
      color
      dimensionOne
      dimensionTwo
      reflexion
      catalogSection
      catalogSubsection
      catalogMainGroup
      catalogSectionDescription
      catalogMainGroupDescription
      catalogSubsectionDescription
      freeText
      acronym
      type
      material
      targetConsumptionPerUnit
      timeRequired
      predefinedVolume
      predefinedPricePerUnit
      basePrice
    }
  }
`;

export const ITEM_BOILERPLATE_COMPUTED_FIELDS = ['catalog.useName', 'basePrice'];

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

interface IChildrenProps {
  data: any;
  refetch: (variables: any) => Promise<ApolloQueryResult<any>>;
  loading: boolean;
  paginationState: IUsePaginationStateResult;
  selectedCatalogId: string;
}

interface IProps {
  initCatalogId?: string;
  query?: any;
  hasMaterialSelector?: boolean;
  catalogSelectorLabel?: string;
  catalogSelectorClassName?: string;
  children: (props: IChildrenProps) => JSX.Element | null;
  initiallyOpen?: boolean;
}

const CatalogMaterialQuery: React.FC<IProps> = ({
  initCatalogId,
  children,
  query,
  hasMaterialSelector,
  catalogSelectorLabel,
  catalogSelectorClassName,
  initiallyOpen = false,
}) => {
  const classes = useStyles();
  const [selectedCatalogId, setSelectedCatalogId] = useState(initCatalogId || '');
  const [selectedMaterial, setSelectedMaterial] = useState('all');

  const onCatalogChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => setSelectedCatalogId(event.target.value as string);
  const onMaterialSelect = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => setSelectedMaterial(event.target.value as string);

  const queryVariables = useMemo(
    () => ({
      catalogId: selectedCatalogId,
      ...(hasMaterialSelector && {
        material: selectedMaterial === 'all' ? undefined : selectedMaterial,
      }),
    }),
    [selectedCatalogId, hasMaterialSelector, selectedMaterial],
  );

  const { data, loading, error, refetch, variables } = useQuery<
    ItemBoilerplates,
    ItemBoilerplatesVariables
  >(query ? query : ITEM_BOILERPLATE_QUERY, {
    skip: !selectedCatalogId,
    variables: {
      ...queryVariables,
      page: {
        pageNumber: 0,
        amountPerPage: 25,
      },
    },
  });

  const count = useCount({
    entity: CountEntity.ITEMBOILERPLATE,
    variables: {
      where: {
        catalog: { id: variables.catalogId },
        ...(hasMaterialSelector && {
          material: selectedMaterial === 'all' ? undefined : selectedMaterial,
        }),
      },
      search: variables.search,
    },
  });

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) =>
      refetch({
        ...queryVariables,
        page: {
          pageNumber: state.page,
          amountPerPage: state.rowsPerPage,
        },
      }),
    [queryVariables, refetch],
  );

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  const [catalogSelectOpen, setCatalogSelectOpen] = useState<boolean>(initiallyOpen);
  const closeCatalogSelect = useCallback(() => setCatalogSelectOpen(false), [setCatalogSelectOpen]);
  const openCatalogSelect = useCallback(() => setCatalogSelectOpen(true), [setCatalogSelectOpen]);

  if (error) {
    console.log(error);
    return null;
  }

  if (!data && selectedCatalogId) {
    return null;
  }

  return (
    <div>
      <FormControl className={`${classes.formControl} ${catalogSelectorClassName}`}>
        {catalogSelectorLabel && <InputLabel>{catalogSelectorLabel}</InputLabel>}
        <CatalogSelect
          onChange={onCatalogChange}
          value={selectedCatalogId}
          prefill
          open={catalogSelectOpen}
          onClose={closeCatalogSelect}
          onOpen={openCatalogSelect}
        />
      </FormControl>
      {selectedCatalogId && hasMaterialSelector && (
        <FormControl className={classes.formControl}>
          <InputLabel>Material</InputLabel>
          <MaterialSelect
            catalogId={selectedCatalogId}
            onChange={onMaterialSelect}
            value={selectedMaterial}
          />
        </FormControl>
      )}
      {selectedCatalogId &&
        children({
          data,
          loading,
          paginationState,
          selectedCatalogId,
          refetch: (variables: any) =>
            refetch({
              ...variables,
              catalogId: selectedCatalogId,
              ...(hasMaterialSelector && {
                material: selectedMaterial === 'all' ? undefined : selectedMaterial,
              }),
            }),
        })}
    </div>
  );
};

export default CatalogMaterialQuery;
