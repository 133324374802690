import React, { useState, useCallback, useMemo } from 'react';
import PaperWrapper from '../../components/PageWrapper';
import AppProgress from '../../components/Page/AppProgress';
import { makeStyles, Typography } from '@material-ui/core';
import Calendar, { LABEL_WIDTH } from '../../components/Calendar/Calendar';
import CalendarToolbar from '../../components/Calendar/CalendarToolbar';
import { useQuery } from 'react-apollo';
import { EMPLOYEES_CALENDAR_QUERY } from './employeeCalendar.query';
import {
  GetEmployeesCalendars,
  GetEmployeesCalendarsVariables,
} from './types/GetEmployeesCalendars';
import { IEmployeeSuggestions, mapEvents, EmployeeMap } from './employeeCalendar.utils';
import { EmployeeTimeOverview } from '../../components/EmployeeTimeOverview';
import { GET_ALL_EMPLOYES } from '../../components/Employee/EmployeeForm.queries';
import { getAllEmployees } from '../../components/Employee/types/getAllEmployees';
import { isNil } from 'lodash';

const HEADER = { title: 'Kalender' };

const labelFlex = 0.7;
const flexConfig = [labelFlex];
const useStyles = makeStyles((theme) => ({
  overviewContainer: {
    paddingTop: '2.5em',
    paddingBottom: '1em',
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    padding: '1em 0',
  },
  calendarContainer: {
    // account for label row width
    paddingLeft: `calc((100% / (7 + ${labelFlex})) * ${labelFlex} - ${LABEL_WIDTH}px)`,
  },
  nameContainer: {
    padding: 'padding: 2.4em 0',
  },
  activityTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingLeft: '18%',
  },
  timeContainer: {
    paddingRight: '5%',
  },
}));

const EmployeeCalendar = () => {
  const classes = useStyles();
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [employees, setEmployees] = useState<string[]>([]);

  const { data, error, loading } = useQuery<GetEmployeesCalendars, GetEmployeesCalendarsVariables>(
    EMPLOYEES_CALENDAR_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: { where: { date, employees } },
      skip: isNil(date) || employees.length === 0,
    },
  );

  const employeeCalendars = data?.getEmployeesCalendars;
  const calendars = useMemo(() => {
    if (!data || !employeeCalendars || loading) {
      return [];
    }

    return employeeCalendars.map((calender) => {
      return {
        ...calender,
        events: mapEvents(calender.events),
      };
    });
  }, [employeeCalendars, loading, data]);

  const {
    data: employeeData,
    error: errorEmployee,
    loading: loadingEmployee,
  } = useQuery<getAllEmployees, null>(GET_ALL_EMPLOYES);

  const allEmployees = employeeData?.employees;
  const employeeSuggestions = useMemo<IEmployeeSuggestions>(() => {
    const employeesSuggestionsMemo = {
      employeeMap: [] as EmployeeMap,
    };
    const employeeSuggestions =
      allEmployees?.reduce((acc, employee) => {
        const fullName = `${employee.firstName} ${employee.lastName}`;
        acc.employeeMap.push({ id: employee.id, fullName });
        return acc;
      }, employeesSuggestionsMemo) ?? employeesSuggestionsMemo;
    employeeSuggestions.employeeMap.sort((e1, e2) => e1.fullName.localeCompare(e2.fullName));
    return employeeSuggestions;
  }, [allEmployees]);

  const mapEmployeeNamesToIds = useCallback(
    (employees: string[]) => {
      return employees.map((employeeName) => {
        return employeeSuggestions.employeeMap.find((em) => em.fullName === employeeName)!.id;
      });
    },
    [employeeSuggestions],
  );

  const mapEmployeeIdToName = useCallback(
    (employeeId: string) => {
      return employeeSuggestions.employeeMap.find((em) => em.id === employeeId)!.fullName;
    },
    [employeeSuggestions],
  );

  const onChangeDate = useCallback(
    async (newDate: Date) => {
      setDate(newDate);
    },
    [setDate],
  );

  const onChange = useCallback(
    async ({ employees }) => {
      setEmployees(mapEmployeeNamesToIds(employees));
    },
    [mapEmployeeNamesToIds, setEmployees],
  );

  if (error || errorEmployee) {
    console.log(error ?? errorEmployee);

    return null;
  }

  return (
    <PaperWrapper header={HEADER}>
      {(loadingEmployee || loading) && <AppProgress />}
      <CalendarToolbar
        loading={loading}
        onChange={onChange}
        onChangeDate={onChangeDate}
        date={date}
        employeeSuggestions={employeeSuggestions}
      />
      {date &&
        calendars?.flatMap((calendar) => {
          return [
            <div key={calendar.id + 'employeeName'} className={classes.nameContainer}>
              <Typography variant="h6">{mapEmployeeIdToName(calendar.id)}</Typography>
            </div>,
            <div key={calendar.id} className={classes.calendarContainer}>
              <Calendar initialDate={date} events={calendar.events} date={date} />
            </div>,
            <div key={calendar.id + 'overview'} className={classes.overviewContainer}>
              <EmployeeTimeOverview
                flex={flexConfig}
                date={date}
                detailedDays={calendar.detailedDays}
              />
            </div>,
          ];
        })}
    </PaperWrapper>
  );
};

export default EmployeeCalendar;
