import React, { useCallback, useMemo, useReducer, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { GET_LOCATIONS } from './BillOfQuantityOverview.queries';
import Container from '../../components/Container/Container';
import {
  ActionType,
  boqReducer,
  IAction,
  IState,
  SelectedType,
} from './billOfQuantityOverview.reducer';
import { hasChildren } from './BillOfQuantityOverview.utils';
import { SpeedDial } from '../../components/SpeedDial';
import { ISpeedDialActionProps } from '../../components/SpeedDialAction/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/Filter2';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ItemsList, { ISubmittedItem } from '../../components/Item/ItemsList';
import { UPDATE_ITEM_MUTATION } from '../../../components/Item/ItemDetails';
import { UpdateItem, UpdateItemVariables } from '../../../components/Item/types/UpdateItem';
import {
  DeleteLocation,
  DeleteLocationVariables,
} from '../../../components/BillOfQuantity/types/DeleteLocation';
import {
  DeleteItem,
  DeleteItemVariables,
} from '../../../components/BillOfQuantity/types/DeleteItem';
import { GetLocations, GetLocationsVariables } from './types/GetLocations';
import AppProgress from '../../../components/Page/AppProgress';
import { Search, useSearchState } from '../../../components/Search/Search';
import { Box } from '@material-ui/core';
import {
  DELETE_ITEM_MUTATION,
  DELETE_LOCATION_MUTATION,
} from '../../../components/BillOfQuantity/BllOfQuantityTable/BillOfQuantityTable.queries';
import { PasteLocationIcon } from '../../components/Icons/PasteLocationIcon';
import { COPY_LOCATION_MUTATION } from '../../../components/BillOfQuantity/CopyLocationDialog/CopyLocationDialog.queries';

const EDIT_ICON = <EditIcon color="action" />;
const DELETE_ICON = <DeleteIcon fill="white" />;
const ADD_LOCATION = <AddLocationIcon color="action" />;
const ADD_ITEM = <AddCircleIcon color="action" />;
const COPY_LOCATION = <CopyIcon color="action" />;
const PASTE_LOCATION = <PasteLocationIcon />;
const PASTE_DISABLED_LOCATION = <PasteLocationIcon disabled />;

const searchColumns = ['name', 'material', 'descriptionOne', 'unit', 'productNumber'];

interface ICopyMode {
  id: string;
  type: SelectedType.L1 | SelectedType.L2;
  hasChildren?: boolean;
}

export const BillOfQuantityOverview: React.FC = () => {
  const { boqId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [state, dispatch] = useReducer<React.Reducer<IState, IAction>>(boqReducer, {
    selectedId: '',
    selectedType: null,
    isSelectedDeletable: false,
  });

  const [copyMode, setCopyMode] = useState<ICopyMode | undefined>(undefined);

  const searchState = useSearchState();

  const refetchQueries = [{ query: GET_LOCATIONS, variables: { id: boqId } }];

  const [updateItem] = useMutation<UpdateItem, UpdateItemVariables>(UPDATE_ITEM_MUTATION, {
    refetchQueries,
  });

  const [copyLocation] = useMutation(COPY_LOCATION_MUTATION, {
    refetchQueries,
  });

  const [deleteLocation] = useMutation<DeleteLocation, DeleteLocationVariables>(
    DELETE_LOCATION_MUTATION,
    {
      refetchQueries,
    },
  );

  const [deleteItem] = useMutation<DeleteItem, DeleteItemVariables>(DELETE_ITEM_MUTATION, {
    refetchQueries,
  });

  const { data, loading, error, refetch } = useQuery<GetLocations, GetLocationsVariables>(
    GET_LOCATIONS,
    {
      variables: { id: boqId },
    },
  );

  const onSelect = (
    selectedId: string,
    selectedType: SelectedType,
    isSelectedDeletable: boolean,
  ): void => {
    dispatch({
      type: ActionType.SET_SELECTED_ID,
      payload: { selectedId },
    });
    dispatch({ type: ActionType.SET_SELECTED_TYPE, payload: { selectedType } });
    dispatch({ type: ActionType.SET_SELECTED_DELETABLE, payload: { isSelectedDeletable } });
  };

  const defaultLocation = data?.billOfQuantity?.defaultLocation;
  const defaultLocationId = defaultLocation?.id;
  const onSelectBoq = useCallback(
    () => defaultLocationId != null && onSelect(defaultLocationId, SelectedType.BOQ, false),
    [defaultLocationId],
  );

  const locationActions = useMemo(
    () => ({
      addItemAction: {
        icon: ADD_ITEM,
        onClick: () =>
          history.push({
            pathname: `/mobile/örtlichkeiten/${state.selectedId}/positionen/erstellen`,
            state: { boqId }, // pass boqId to be able to refetch GET_LOCATIONS with boqId in other component!!!
          }),
      },
      deleteAction: {
        icon: DELETE_ICON,
        color: 'error',
        onClick: () => deleteLocation({ variables: { id: state.selectedId } }),
        disabled: !state.isSelectedDeletable,
      },
      addLocationAction: {
        icon: ADD_LOCATION,
        onClick: () =>
          history.push({
            pathname: `/mobile/örtlichkeiten/${state.selectedId}/örtlichkeiten/erstellen`,
            state: { boqId },
          }),
      },
      editAction: {
        icon: EDIT_ICON,
        onClick: () => history.push(`${url}/örtlichkeiten/${state.selectedId}/editieren`),
      },
      copyAction: {
        icon: COPY_LOCATION,
        onClick: () => {
          if (!defaultLocation) {
            // data hasn't loaded yet
            return setCopyMode(undefined);
          }
          // L1 locations can be copied to the BOQ and, if they don't have children, to other L1s, see the `disabled` states
          if (state.selectedType === SelectedType.L1) {
            const l1HasChildren = !!defaultLocation.locations?.find(
              ({ id }) => id === state.selectedId,
            )?.locations?.length;
            if (l1HasChildren) {
              // there's only one place to copy L1s with children to, i.e. the bill of quantity
              // for convenience select and scroll to the boq
              onSelectBoq();
              // scroll to top, there's only one bill of quantity in this view
              window.scrollTo(0, 0);
            }
            return setCopyMode({
              id: state.selectedId,
              type: state.selectedType,
              hasChildren: l1HasChildren,
            });
          }
          // L2 locations can be copied to L1s and the BOQ, see the `disabled` states
          if (state.selectedType === SelectedType.L2) {
            setCopyMode({ id: state.selectedId, type: state.selectedType });
          }
        },
      },
    }),
    [
      defaultLocation,
      state.isSelectedDeletable,
      state.selectedId,
      state.selectedType,
      history,
      boqId,
      deleteLocation,
      url,
      onSelectBoq,
    ],
  );

  const speedDialActions: { [key in SelectedType]: ISpeedDialActionProps[] } = useMemo(
    () => ({
      [SelectedType.L1]: [
        locationActions.editAction,
        locationActions.addItemAction,
        locationActions.addLocationAction,
        locationActions.copyAction,
        locationActions.deleteAction,
      ],

      [SelectedType.L2]: [
        locationActions.editAction,
        locationActions.addItemAction,
        locationActions.copyAction,
        locationActions.deleteAction,
      ],

      [SelectedType.BOQ]: [locationActions.addItemAction, locationActions.addLocationAction],

      [SelectedType.ITEM]: [
        {
          icon: EDIT_ICON,
          onClick: () => history.push(`/mobile/positionen/${state.selectedId}/details/editieren`),
        },
        {
          icon: DELETE_ICON,
          color: 'error',
          onClick: () => deleteItem({ variables: { id: state.selectedId } }),
          disabled: !state.isSelectedDeletable,
        },
      ],
    }),
    [locationActions, deleteItem, history, state.selectedId, state.isSelectedDeletable],
  );

  const dismissibleActions = useMemo(
    () =>
      !copyMode
        ? undefined
        : [
            {
              disabled: copyMode.id === state.selectedId,
              icon: copyMode.id === state.selectedId ? PASTE_DISABLED_LOCATION : PASTE_LOCATION,
              onClick: async () => {
                const {
                  data: {
                    copyLocation: { id },
                  },
                } = await copyLocation({
                  variables: {
                    targetId: state.selectedId,
                    sourceId: copyMode.id,
                    targetBillOfQuantityId: boqId,
                  },
                });
                history.push(`${url}${url.endsWith('/') ? '' : '/'}örtlichkeiten/${id}/editieren`);
              },
            },
          ],
    [copyLocation, history, url, copyMode, boqId, state.selectedId],
  );

  const leaveCopyMode = useCallback(() => {
    setCopyMode(undefined);
  }, [setCopyMode]);

  const handleSubmit = useCallback(
    ({ id, volume }: ISubmittedItem) => {
      return updateItem({ variables: { id, data: { volume } } });
    },
    [updateItem],
  );

  const onSearch = useCallback((search) => refetch({ search, id: boqId }), [boqId, refetch]);

  if (error) {
    console.log(error);
    return null;
  }

  if (!data) {
    return null;
  }

  const { billOfQuantity } = data;

  const isSelected = (id: string) => state.selectedId === id;

  return (
    <>
      {loading && <AppProgress />}
      <Search
        columns={searchColumns}
        searchState={searchState}
        onSubmit={onSearch}
        loading={loading}
      />
      <Box marginBottom={12.5}>
        <Container
          name={billOfQuantity?.name ?? ''}
          onSelect={onSelectBoq}
          isSelected={!!defaultLocation?.id && isSelected(defaultLocation?.id)}
          hasChildren={hasChildren(defaultLocation?.items ?? [], defaultLocation?.locations ?? [])}
        >
          {defaultLocation?.items && (
            <ItemsList
              disabled={!!copyMode}
              filterText={searchState.searchTerm}
              items={defaultLocation.items}
              selectedItemId={state.selectedId}
              onClick={(item: any) => onSelect(item.id, SelectedType.ITEM, item.canBeDeleted)}
              onSubmit={handleSubmit}
            />
          )}

          {defaultLocation?.locations?.map((location) => (
            <Container
              disabled={copyMode?.type === SelectedType.L1 && copyMode?.hasChildren}
              key={location.id}
              name={location.name}
              isDefaultClosed
              onSelect={() => onSelect(location.id, SelectedType.L1, location.canBeDeleted)}
              isSelected={isSelected(location.id)}
              hasChildren={hasChildren(location.items, location.locations)}
            >
              {location.items.length > 0 && (
                <ItemsList
                  disabled={!!copyMode}
                  filterText={searchState.searchTerm}
                  items={location.items}
                  selectedItemId={state.selectedId}
                  onClick={(item: any) => onSelect(item.id, SelectedType.ITEM, item.canBeDeleted)}
                  onSubmit={handleSubmit}
                />
              )}

              {location.locations?.map((subLocation) => (
                <Container
                  disabled={!!copyMode}
                  key={subLocation.id}
                  name={subLocation.name}
                  isDefaultClosed
                  onSelect={() =>
                    onSelect(subLocation.id, SelectedType.L2, subLocation.canBeDeleted)
                  }
                  isSelected={isSelected(subLocation.id)}
                  hasChildren={hasChildren(subLocation.items)}
                >
                  {subLocation.items.length > 0 && (
                    <ItemsList
                      disabled={!!copyMode}
                      filterText={searchState.searchTerm}
                      items={subLocation.items}
                      selectedItemId={state.selectedId}
                      onClick={(item: any) =>
                        onSelect(item.id, SelectedType.ITEM, item.canBeDeleted)
                      }
                      onSubmit={handleSubmit}
                    />
                  )}
                </Container>
              ))}
            </Container>
          ))}
        </Container>
      </Box>

      <SpeedDial
        disabled={!state.selectedType && !state.selectedId}
        actions={state.selectedType ? speedDialActions[state.selectedType] : undefined}
        dismissibleActions={dismissibleActions}
        onDismiss={leaveCopyMode}
      />
    </>
  );
};
