import gql from 'graphql-tag';

export const MOBILE_BILL_OF_QUANTITIES_QUERY = gql`
  query MobileBillOfQuantites($projectNumber: String!, $status: StatusWhereInput) {
    billOfQuantities(where: { project: { projectNumber: $projectNumber }, status: $status }) {
      id
      name
      createdAt
      colorCodings
      status {
        id
        status
      }
      taskTypes
      type
      sumNet
      sumGross
      hasScaleDiscount
      hasScaleDiscountAccumulate
    }
  }
`;
