import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  TextField,
} from '@material-ui/core';
import React, { useCallback } from 'react';

interface Props {
  files: File[];
  setAcceptedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  open: boolean;
  folderName: string;
  onSubmit: () => void;
}

const UploadDialog: React.FC<Props> = ({ files, open, setAcceptedFiles, folderName, onSubmit }) => {
  const handleClose = useCallback(() => {
    setAcceptedFiles([]);
  }, [setAcceptedFiles]);

  const onChange = (index: number, newName: string) => {
    const file = new File([files[index]], newName, { type: files[index].type });

    const filesCopy = [...files];

    filesCopy.splice(index, 1, file);

    setAcceptedFiles(filesCopy);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle id="alert-dialog-title">{`folgende Dateien in den Ordner ${folderName} hochladen`}</DialogTitle>
      <DialogContent>
        <List>
          {files.map((file, index) => {
            return (
              <ListItem key={file.name}>
                <TextField
                  fullWidth
                  defaultValue={file.name}
                  onBlur={(event) => onChange(index, event.target.value)}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={onSubmit} color="primary" autoFocus>
          Hochladen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
