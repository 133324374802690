import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useState } from 'react';
import ConfirmationDialog from '../../../ConfirmationDialog';

interface IProps {
  onDelete: () => void;
  isDisabled: boolean;
}

export const DeleteHistoryItemAction: React.FC<IProps> = ({ onDelete, isDisabled }) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = useCallback(() => setShowDialog(true), [setShowDialog]);

  const handleOnConfirm = useCallback(() => onDelete(), [onDelete]);

  const handleOnClose = useCallback(() => setShowDialog(false), [setShowDialog]);

  return (
    <>
      <ConfirmationDialog
        open={showDialog}
        title="Historischen eintrag löschen"
        message="Möchtest du den historischen Eintrag löschen?"
        onConfirm={handleOnConfirm}
        onClose={handleOnClose}
      />
      <Tooltip title="Löschen">
        <span>
          <IconButton aria-label="Löschen" onClick={handleClick} disabled={isDisabled}>
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
